Onkho.OnkhoPanel = function ()
{
    var highestZindex = 10000;

    var Init = function ()
    {
        CreatePanelsWrapper();

        $('body').on('click', '.o-panel .collapse-button, .o-panel .collapsed', function ()
        {
            var panel = $(this).closest('.o-panel');
            if (panel.hasClass('expanded'))
            {
                Collapse($(this).closest('.o-panel'));
            }
            else
            {
                Expand($(this).closest('.o-panel'));
            }
        });

        $('body').on('click', '.o-panel > .expanded > .content-wrapper > .header > .close', function ()
        {
            Hide($(this).closest('.o-panel'));
        });

        // Setup resize buttons
        $('body').on('click', '.o-panel > .expanded > .content-wrapper > .header > .resize-default[data-id="resize-default"]', function ()
        {
            Resize($(this).closest('.o-panel'), 'default');
        });
        $('body').on('click', '.o-panel > .expanded > .content-wrapper > .header > .resize-third[data-id="resize-third"]', function ()
        {
            Resize($(this).closest('.o-panel'), 'third');
        });
        $('body').on('click', '.o-panel > .expanded > .content-wrapper > .header > .resize-half[data-id="resize-half"]', function ()
        {
            Resize($(this).closest('.o-panel'), 'half');
        });
        $('body').on('click', '.o-panel > .expanded > .content-wrapper > .header > .resize-full[data-id="resize-full"]', function ()
        {
            Resize($(this).closest('.o-panel'), 'full');
        });
    };

    var CreatePanelsWrapper = function ()
    {
        if ($('.o-panel-wrapper').length == 0)
        {
            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/panel/getWrapper',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            $('body').append(data.responseJSON.html);
                            $('body').trigger('onkho:panel[wrapper].added');
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
        }
    };

    var RegenerateNonce = function ()
    {
        if ($('.o-panel-wrapper').length > 0)
        {
            $('.o-panel-wrapper').data('nonce', Math.floor(Math.random() * 10000 + 1));
        }
    };

    var CheckNonce = function (nonce)
    {
        return parseInt($('.o-panel-wrapper').data('nonce')) === parseInt(nonce);
    };

    var GetCurrentNonce = function ()
    {
        if ($('.o-panel-wrapper').length > 0)
        {
            return $('.o-panel-wrapper').data('nonce');
        }
        else
        {
            return 0;
        }
    };

    var Add = function (key, data, callback)
    {
        var callbackArguments = Array.prototype.slice.call(arguments, 2);
        RegenerateNonce();
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = GetCurrentNonce();
        formData.key = key;
        formData.data = data;

        $.ajax(
        {
            type: 'POST',
            url: Onkho.Variables.BaseURL + '/panel/getPanel',
            data: formData,
            dataType: 'json',
            complete: function (data)
                  {
                      switch (data.status)
                      {
                          case 200:
                              if (CheckNonce(data.responseJSON.nonce))
                              {
                                  var panel = $(data.responseJSON.html);
                                  $('.o-panel-container').append(panel);
                                  panel.css('z-index', highestZindex--);
                                  var panelSections = panel.find('.panel-section');
                                  panel.addClass('o-panel-' + (panelSections.length % 2 == 0 ? 'even' : 'odd') + '-sections');
                                  panel.trigger('onkho:panel[' + panel.data('panel-key') + '].added');

                                  Onkho.Functions.ExecuteCallback.apply(null, callbackArguments.concat(panel));
                              }
                              break;

                          default:
                              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                              break;
                      }
                  }
        });
    };

    var Remove = function (panel, callback)
    {
        var key = panel.data('panel-key');
        Hide(panel);
        panel.remove();

        $('.o-panel-container').trigger('onkho:panel[' + key + '].removed');
        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1));
    };

    var RemoveAll = function (callback)
    {
        $('.o-panel-container .o-panel').each(function (index, panel) {
            Remove($(panel))
        });

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 0));
    };

    var Show = function (panel, callback)
    {
        ShowBackdrop();

        if (panel.hasClass('o-panel') && panel.hasClass('hidden'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].shown');
            panel.removeClass('hidden').addClass('shown');

            RefreshLastFlag();

            $('[rel="tooltip"], [data-toggle="tooltip"]').tooltip('hide');

            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
        }
    };

    var Hide = function (panel, callback)
    {
        if (panel.hasClass('o-panel') && panel.hasClass('shown') && panel.hasClass('last'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].hidden');
            panel.removeClass('shown').addClass('hidden');

            RefreshLastFlag();
            Expand($('.o-panel.last'));

            HideBackdrop.apply(null, Array.prototype.slice.call(arguments, 1));
        }
    };

    var Resize = function (panel, size)
    {
        panel.removeClass('o-panel-third o-panel-half o-panel-full');

        switch (size) {
            case 'third':
                panel.addClass('o-panel-third');
                panel.find('> .expanded > .content-wrapper > .header > .resize-default[data-id="resize-default"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-third[data-id="resize-third"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-half[data-id="resize-half"]').show();
                panel.find('> .expanded > .content-wrapper > .header > .resize-full[data-id="resize-full"]').hide();
                break;

            case 'half':
                panel.addClass('o-panel-half');
                panel.find('> .expanded > .content-wrapper > .header > .resize-default[data-id="resize-default"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-third[data-id="resize-third"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-half[data-id="resize-half"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-full[data-id="resize-full"]').show();
                break;

            case 'full':
                panel.addClass('o-panel-full');
                panel.find('> .expanded > .content-wrapper > .header > .resize-default[data-id="resize-default"]').show();
                panel.find('> .expanded > .content-wrapper > .header > .resize-third[data-id="resize-third"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-half[data-id="resize-half"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-full[data-id="resize-full"]').hide();
                break;

            default:
                panel.find('> .expanded > .content-wrapper > .header > .resize-default[data-id="resize-default"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-third[data-id="resize-third"]').show();
                panel.find('> .expanded > .content-wrapper > .header > .resize-half[data-id="resize-half"]').hide();
                panel.find('> .expanded > .content-wrapper > .header > .resize-full[data-id="resize-full"]').hide();
        }
    };

    var RefreshLastFlag = function ()
    {
        $('.o-panel').removeClass('last');
        $('.o-panel.shown').last().addClass('last');
    };

    var ShowBackdrop = function (callback)
    {
        var wrapper = $('.o-panel-wrapper');
        if (wrapper.hasClass('hidden'))
        {
            wrapper.trigger('onkho:panel[wrapper].shown');
            wrapper.removeClass('hidden').addClass('shown');
            $('body').addClass('o-panel-noscroll');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var HideBackdrop = function (callback)
    {
        var wrapper = $('.o-panel-wrapper');
        if (wrapper.hasClass('shown') && $('.o-panel.shown').length == 0)
        {
            wrapper.trigger('onkho:panel[wrapper].hidden');
            wrapper.removeClass('shown').addClass('hidden');
            $('body').removeClass('o-panel-noscroll');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var Collapse = function (panel, callback)
    {
        if (!panel.hasClass('last') && panel.hasClass('expanded'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].collapsed');
            panel.removeClass('expanded').addClass('collapsed');
            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
        }
    };

    var Expand = function (panel, callback)
    {
        if (panel.hasClass('collapsed'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].expanded');
            panel.removeClass('collapsed').addClass('expanded');
            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
        }
    };

    var Enable = function (panel, callback)
    {
        if (panel.hasClass('o-panel-disabled'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].enabled');
            panel.removeClass('o-panel-disabled').addClass('o-panel-enabled');

            panel.find('.primary-buttons button').prop('disabled', false).show();
            panel.find('.secondary-buttons button').prop('disabled', false).show();

            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
        }
    };

    var Disable = function (panel, callback)
    {
        if (panel.hasClass('o-panel-enabled'))
        {
            panel.trigger('onkho:panel[' + panel.data('panel-key') + '].disabled');
            panel.removeClass('o-panel-enabled').addClass('o-panel-disabled');

            panel.find('.primary-buttons button').prop('disabled', true).hide();
            panel.find('.secondary-buttons button').prop('disabled', true).hide();

            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
        }
    };

    var EnableButtons = function (panel, callback)
    {
        panel.find('.primary-buttons button').prop('disabled', false).show();
        panel.find('.secondary-buttons button').prop('disabled', false).show();

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
    };

    var DisableButtons = function (panel, callback)
    {
        panel.find('.primary-buttons button').prop('disabled', true).hide();
        panel.find('.secondary-buttons button').prop('disabled', true).hide();

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(panel));
    };

    var EnablePanelOpener = function (panel, panelOpener, callback)
    {
        if (typeof panelOpener == 'undefined')
        {
            panelOpener = panel.find('.panel-opener button.panel-opener-content-wrapper');
        }

        panelOpener.prop('disabled', false);
        panelOpener.prop('disabled', false);

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 2).concat(panel));
    };

    var DisablePanelOpener = function (panel, panelOpener, callback)
    {
        if (typeof panelOpener == 'undefined')
        {
            panelOpener = panel.find('.panel-opener button.panel-opener-content-wrapper');
        }

        panelOpener.prop('disabled', true);
        panelOpener.prop('disabled', true);

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 2).concat(panel));
    };

    var SetTitle = function (panel, title)
    {
        panel.find('> .expanded > .content-wrapper > .header > .title').html(title);
    };

    var SetButtonLabel = function (panel, buttonId, label)
    {
        panel.find('.footer button[data-id="' + buttonId + '"]').html(label);
    };

    var SetCollapsedContent = function (panel, content)
    {
        panel.find('> .collapsed > .vertical-text').html(content);
    };

    var GetPanelOpenerLabel = function (panel, panelOpenerId)
    {
        return panel.find('.panel-opener[data-id="' + panelOpenerId + '"] > button > .label').text();
    };

    var SetPanelOpenerLabel = function (panel, panelOpenerId, label)
    {
        panel.find('.panel-opener[data-id="' + panelOpenerId + '"] > button > .label').html(label);
    };

    return {
        Init: Init,
        Add: Add,
        Remove: Remove,
        RemoveAll: RemoveAll,
        Show: Show,
        Hide: Hide,
        Collapse: Collapse,
        Expand: Expand,
        Enable: Enable,
        Disable: Disable,
        EnableButtons: EnableButtons,
        DisableButtons: DisableButtons,
        EnablePanelOpener: EnablePanelOpener,
        DisablePanelOpener: DisablePanelOpener,
        SetTitle: SetTitle,
        SetButtonLabel: SetButtonLabel,
        SetCollapsedContent: SetCollapsedContent,
        GetPanelOpenerLabel: GetPanelOpenerLabel,
        SetPanelOpenerLabel: SetPanelOpenerLabel
    };
}();
