Onkho.AddSMSBoxPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    $('body').on('change', '.o-panel[data-panel-key="add_smsbox"] form [name="type"]', function ()
    {
      ShowAppropriateSections($(this).val())
    });

    // Prevent ENTER from refreshing the page in the add SMS box panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_smsbox"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var ShowAppropriateSections = function (type)
  {
    var panel = $('.o-panel[data-panel-key="add_smsbox"]')

    panel.find('.panel-section').removeClass('hidden');

    var panelSection = panel.find('.panel-section[data-id="phone-number-type"]')
    panelSection.removeClass('hidden')

    if (type === 'ALPHANUMERIC_ID') {
      panelSection.find('fieldset[data-id="alphanumeric-id"]').removeClass('hidden')
      panelSection.find('fieldset[data-id="phone-number"]').addClass('hidden')
    } else {
      panelSection.find('fieldset[data-id="phone-number"]').removeClass('hidden')
      panelSection.find('fieldset[data-id="alphanumeric-id"]').addClass('hidden')
    }
  };

  var LoadAddSMSBoxPanel = function ()
  {
    var additionalData = {
      'partyId': Onkho.Variables.PartyId
    };

    Onkho.OnkhoPanel.Add('add_smsbox', additionalData, AddSMSBoxPanelLoaded)
  };

  var AddSMSBoxPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    panel.find('select[name="type"]').select2();

    var phoneNumberPicker = panel.find('[name="phone_number"]');
    phoneNumberPicker.select2({
      placeholder: 'Choose your phone number',
      minimumInputLength: 0,
      minimumResultsForSearch: -1,
      ajax: {
        url: Onkho.Variables.BaseURL + '/smsbox/getPhoneNumbers',
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatAjaxError: FormatPhoneNumberFailure,
      formatResult: FormatPhoneNumberResult,
      formatSelection: FormatPhoneNumberResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    });

    if (phoneNumberPicker.data('initial-value')) {
      phoneNumberPicker.select2('data', {'id': phoneNumberPicker.data('initial-value'), 'name': phoneNumberPicker.data('initial-value')});
    }
  };

  var FormatPhoneNumberFailure = function (data)
  {
    Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to retrieve the list of available phone numbers', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
    return 'We were not able to retrieve the list of available phone numbers';
  }

  var FormatPhoneNumberResult = function (phoneNumber)
  {
    return phoneNumber.name;
  }

  var FormatPhoneNumberResultSelection = function (phoneNumber)
  {
    return phoneNumber.name;
  }

  var RegisterAddSMSBoxPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddSMSBox();
    });
  };

  var AddSMSBox = function ()
  {
    var panel = $('.o-panel[data-panel-key="add_smsbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'))

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();

    panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      formData[$(input).attr('name')] = $(input).val()
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/addSMSBox',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

          switch (data.status)
          {
            case 200:
              Onkho.OnkhoPanel.RemoveAll();

              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.OnkhoList.Refresh($('.o-list[data-id="smsboxes"]'));
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to add your phone number', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
              break;
          }
        }
      });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_smsbox].added', '.o-panel-container', function ()
    {
      var addSMSBoxPanel = $('.o-panel[data-panel-key="add_smsbox"]');
      RegisterAddSMSBoxPanelBindings(addSMSBoxPanel);
    });
  };



  return {
    Init: Init,
    LoadAddSMSBoxPanel: LoadAddSMSBoxPanel
  };
}();
