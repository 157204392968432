/**
 * [jQuery-lazyload-any]{@link https://github.com/emn178/jquery-lazyload-any}
 *
 * @version 0.3.1
 * @author Yi-Cyuan Chen [emn178@gmail.com]
 * @copyright Yi-Cyuan Chen 2014-2016
 * @license MIT
 */
(function(d,k,l){function m(){var a=d(this),c;if(c=a.is(":visible")){c=a[0].getBoundingClientRect();var b=-a.data("jquery-lazyload-any").threshold,e=n-b,f=p-b;c=(c.top>=b&&c.top<=e||c.bottom>=b&&c.bottom<=e)&&(c.left>=b&&c.left<=f||c.right>=b&&c.right<=f)}c&&a.trigger("appear")}function q(){n=k.innerHeight||l.documentElement.clientHeight;p=k.innerWidth||l.documentElement.clientWidth;g()}function g(){h=h.filter(":jquery-lazyload-any-appear");1==this.nodeType?d(this).find(":jquery-lazyload-any-appear").each(m):
h.each(m)}function v(){var a=d(this),c=a.data("jquery-lazyload-any"),b=a.data("lazyload");b||(b=a.children().filter('script[type="text/lazyload"]').get(0),b=d(b).html());b||(b=(b=a.contents().filter(function(){return 8===this.nodeType}).get(0))&&d.trim(b.data));b=w.html(b).contents();a.replaceWith(b);d.isFunction(c.load)&&c.load.call(b,b)}function r(){var a=d(this),c;a.data("jquery-lazyload-any-scroller")?c=!1:(c=a.css("overflow"),"scroll"!=c&&"auto"!=c?c=!1:(a.data("jquery-lazyload-any-scroller",
1),a.bind("scroll",g),c=!0));var b;a.data("jquery-lazyload-any-display")?b=void 0:"none"!=a.css("display")?b=void 0:(a.data("jquery-lazyload-any-display",1),a._bindShow(g),b=!0);c|b&&!a.data("jquery-lazyload-any-watch")&&(a.data("jquery-lazyload-any-watch",1),a.bind("appear",t))}function t(){var a=d(this);0===a.find(":jquery-lazyload-any-appear").length&&(a.removeData("jquery-lazyload-any-scroller").removeData("jquery-lazyload-any-display").removeData("jquery-lazyload-any-watch"),a.unbind("scroll",
g).unbind("appear",t)._unbindShow(g))}var w=d("<div/>"),n,p,u=!1,h=d();d.expr[":"]["jquery-lazyload-any-appear"]=function(a){return void 0!==d(a).data("jquery-lazyload-any-appear")};d.fn.lazyload=function(a){var c={threshold:0,trigger:"appear"};d.extend(c,a);a=c.trigger.split(" ");this.data("jquery-lazyload-any-appear",-1!=d.inArray("appear",a)).data("jquery-lazyload-any",c);this.bind(c.trigger,v);this.each(m);this.parents().each(r);this.each(function(){h=h.add(this)});u||(u=!0,q(),d(l).ready(function(){d(k).bind("resize",
q).bind("scroll",g)}));return this};d.lazyload={check:g,refresh:function(a){(void 0===a?h:d(a)).each(function(){var a=d(this);a.is(":jquery-lazyload-any-appear")&&a.parents().each(r)})}};(function(){function a(){var a=d(this),b="none"!=a.css("display");a.data("jquery-lazyload-any-show")!=b&&(a.data("jquery-lazyload-any-show",b),b&&a.trigger("show"))}function c(){f=f.filter(":jquery-lazyload-any-show");f.each(a);0===f.length&&(e=clearInterval(e))}var b=50,e,f=d();d.expr[":"]["jquery-lazyload-any-show"]=
function(a){return void 0!==d(a).data("jquery-lazyload-any-show")};d.fn._bindShow=function(a){this.bind("show",a);this.data("jquery-lazyload-any-show","none"!=this.css("display"));f=f.add(this);b&&!e&&(e=setInterval(c,b))};d.fn._unbindShow=function(a){this.unbind("show",a);this.removeData("jquery-lazyload-any-show")};d.lazyload.setInterval=function(a){a==b||!d.isNumeric(a)||0>a||(b=a,e=clearInterval(e),0<b&&(e=setInterval(c,b)))}})()})(jQuery,window,document);
