Onkho.InsightsActionActivityFeedItemPanel = function ()
{
  var Init = function ()
  {
    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="insights_action_activity_feed_item"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadInsightsActionActivityFeedItemPanel = function (buttonId, activityFeedItemType, resource)
  {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="' + buttonId + '"]'));

    var additionalData = {
      'buttonId': buttonId,
      'activityFeedItemType': activityFeedItemType,
      'resource': resource,
      'selectedCount': Onkho.InsightGridPageTools.GetSelection().length
    };

    Onkho.OnkhoPanel.Add('insights_action_activity_feed_item', additionalData, LoadInsightsActionActivityFeedItemPanelLoaded)
  };

  var LoadInsightsActionActivityFeedItemPanelLoaded = function (panel)
  {
    var buttonId = panel.data('button-id');
    var activityFeedItemType = panel.data('activity-feed-item-type');

    Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="' + buttonId + '"]'));

    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide');

    Onkho.ActivityFeed.GetCreateFormWithoutContextAndRecipients(panel, activityFeedItemType);
  };



  return {
    Init: Init,
    LoadInsightsActionActivityFeedItemPanel: LoadInsightsActionActivityFeedItemPanel
  };
}();
