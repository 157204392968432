Onkho.OnkhoPayloadActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();

        RegisterEventListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        //
    };

    var RegisterEventListeners = function ()
    {
        //
    };



    return {
        Init: Init
    };
}();
