Onkho.Formatter = function ()
{
    var Price = function (price)
    {
        price = parseFloat(price);
        if (price < 0)
        {
            price = Math.abs(price);
            return '-£' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }

        return '£' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    var PriceNoSymbol = function (price)
    {
        price = parseFloat(price);
        if (price < 0)
        {
            price = Math.abs(price);
            return '-' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }

        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    var MakePartyActiveElement = function (id, label) {
        return '<a href="javascript:void(0);" class="o-active-element-trigger" data-type="party" data-id="' + id + '">' + label + '</a>'
    }

    var MakeServiceActiveElement = function (id, label) {
        return '<a href="javascript:void(0);" class="o-active-element-trigger" data-type="service" data-id="' + id + '">' + label + '</a>'
    }

    var Duration = function (seconds, hoursOnly)
    {
        if (typeof hoursOnly === 'undefined') {
            hoursOnly = true;
        }

        var formattedString = '<span class="label label-normal-size label-inset"><i class="fa fa-fw fa-hourglass-half"></i>';
        var negative = false;
        if (seconds < 0) {
            negative = true;
            seconds = Math.abs(seconds);
        }

        if (hoursOnly) {
            if (seconds < 60) {
                formattedString += '0h';
            } else {
                var hours = Math.round((seconds / 3600 + Number.EPSILON) * 100) / 100;

                if (hours > 0) {
                    formattedString += hours + 'h';
                }

                formattedString = formattedString.trim();

                if (negative) {
                    formattedString = '- ' + formattedString;
                }
            }
        } else {
            if (seconds < 60) {
                formattedString += '--d --h --m';
            } else {

                var days = Math.floor(seconds / 86400);
                var hours = Math.floor((seconds - (days * 86400)) / 3600);
                var minutes = Math.floor((seconds - (days * 86400) - (hours * 3600)) / 60);

                if (days > 0) {
                    formattedString += days + 'd ';
                }

                if (hours > 0) {
                    formattedString += hours + 'h ';
                }

                if (minutes > 0) {
                    formattedString += minutes + 'm ';
                }

                formattedString = formattedString.trim();

                if (negative) {
                    formattedString = '- ' + formattedString;
                }
            }
        }

        formattedString += '</span>';

        return formattedString;
    }

    var MakeJobActiveElement = function (id, label)
    {
        return '<a href="javascript:void(0);" class="o-active-element-trigger" data-type="job" data-id="' + id + '">' + label + '</a>';
    }



    return {
        Price: Price,
        PriceNoSymbol: PriceNoSymbol,
        MakePartyActiveElement: MakePartyActiveElement,
        MakeServiceActiveElement: MakeServiceActiveElement,
        Duration: Duration,
        MakeJobActiveElement: MakeJobActiveElement
    };
}();
