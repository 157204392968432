Onkho.OnkhoPartyActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        // --- Featured actions ---
        // Note
        $('body').on('click', '.o-active-element[data-type="party"] .featured-action[data-id="note"]', function (event)
        {
            Onkho.LoadingTools.ShowLoading($(this), true);
            Onkho.ActivityFeed.LoadActivityFeedItemBuilderPanel( 'NOTE', $(this).closest('.o-active-element').data('id'));
        });

        // Outgoing email
        $('body').on('click', '.o-active-element[data-type="party"] .featured-action[data-id="outgoing_email"]', function (event)
        {
            Onkho.LoadingTools.ShowLoading($(this), true);
            Onkho.ActivityFeed.LoadActivityFeedItemBuilderPanel('OUTGOING_EMAIL', $(this).closest('.o-active-element').data('id'));
        });

        // Outgoing SMS
        $('body').on('click', '.o-active-element[data-type="party"] .featured-action[data-id="outgoing_sms"]', function (event)
        {
            Onkho.LoadingTools.ShowLoading($(this), true);
            Onkho.ActivityFeed.LoadActivityFeedItemBuilderPanel('OUTGOING_SMS', $(this).closest('.o-active-element').data('id'));
        });

        // Request
        $('body').on('click', '.o-active-element[data-type="party"] .featured-action[data-id="request"]', function (event)
        {
            Onkho.LoadingTools.ShowLoading($(this), true);
            Onkho.ActivityFeed.LoadActivityFeedItemBuilderPanel( 'REQUEST', $(this).closest('.o-active-element').data('id'));
        });

        // View
        $('body').on('click', '.o-active-element[data-type="party"] .featured-action[data-id="view"]', function (event)
        {
            ViewParty($(this).closest('.o-active-element').data('id'));
        });
    };

    var ViewParty = function (partyId)
    {
        var fakeHref = document.createElement('a');
        fakeHref.href = Onkho.Variables.BaseURL + '/party/' + partyId;
        fakeHref.target = '_blank';
        document.body.appendChild(fakeHref);
        fakeHref.click();
        fakeHref.remove();
    };



    return {
        Init: Init
    };
}();
