Onkho.EditServiceInstancePanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="edit_service_instance"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadEditServiceInstancePanel = function (clientId, serviceInstanceId)
  {
    var additionalData = {
      'clientId': clientId,
      'serviceInstanceId': serviceInstanceId
    };

    Onkho.OnkhoPanel.Add('edit_service_instance', additionalData, LoadEditServiceInstancePanelLoaded)
  };

  var LoadEditServiceInstancePanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide')

    panel.find('.panel-section[data-id="assignee"]').on('onkho:configuration.changed', function () {
      UpdateAssigneeSection(panel)
    })

    panel.find('.panel-section[data-id="communications"]').on('onkho:configuration.changed', function () {
      UpdateCommunicationsSection(panel)
    })

    panel.find('.panel-section[data-id="service"] .preconditions-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HidePreconditions(panel)
      } else {
        ShowPreconditions(panel)
      }
    })

    panel.find('.panel-section[data-id="service"] .checklist-items-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HideChecklistItems(panel)
      } else {
        ShowChecklistItems(panel)
      }
    })

    // Set initial values
    SetServiceInstance(panel, panel.data('serviceInstanceConfiguration'))
    SetMatterSection(panel, panel.data('initialMatterConfiguration'))
    SetAssigneeSectionConfiguration(panel, panel.data('initialAssigneeConfiguration'))
    SetCommunicationsSectionConfiguration(panel, panel.data('initialCommunicationsConfiguration'))
  };

  var HidePreconditions = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .preconditions-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    panel.find('.panel-section[data-id="service"] .preconditions-container .preconditions').slideUp(150)
  }

  var ShowPreconditions = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .preconditions-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    panel.find('.panel-section[data-id="service"] .preconditions-container .preconditions').slideDown(150)
  }

  var HideChecklistItems = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .checklist-items-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    panel.find('.panel-section[data-id="service"] .checklist-items-container .checklist-items').slideUp(150)
  }

  var ShowChecklistItems = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .checklist-items-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    panel.find('.panel-section[data-id="service"] .checklist-items-container .checklist-items').slideDown(150)
  }

  var SetServiceInstance = function (panel, service) {
    var serviceElement = panel.find('.panel-section[data-id="service"] .service')
    var name = serviceElement.find('.name')
    var description = serviceElement.find('.description')
    var preconditionsContainer = serviceElement.find('.preconditions-container')
    var preconditions = preconditionsContainer.find('.preconditions')
    var checklistItemsContainer = serviceElement.find('.checklist-items-container')
    var checklistItems = checklistItemsContainer.find('.checklist-items')

    if (service != null) {
      name.html(Onkho.Formatter.MakeServiceActiveElement(service.id, service.name))

      if (service.description) {
        description.html(service.description)
      } else {
        description.html(Onkho.RegisterServicePageTools.DescribeServiceScheduleConfiguration(service.configuration))
      }

      if (service.preconditions) {
        var preconditionsHtml = '';
        $(service.preconditions).each(function (index, precondition) {
          preconditionsHtml += RenderPrecondition(precondition)
        })

        preconditions.html(preconditionsHtml)

        if (preconditionsHtml) {
          preconditionsContainer.slideDown(150)
        } else {
          preconditionsContainer.slideUp(150)
        }
      }

      if (service.checklistItems) {
        var checklistItemsHtml = '';
        $(service.checklistItems).each(function (index, checklistItem) {
          checklistItemsHtml += RenderChecklistItem(checklistItem)
        })

        checklistItems.html(checklistItemsHtml)

        if (checklistItemsHtml) {
          checklistItemsContainer.slideDown(150)
        } else {
          checklistItemsContainer.slideUp(150)
        }
      }

      serviceElement.slideDown(150)
      serviceElement.find('[rel="tooltip"]').tooltip({
        placement: 'top',
        container: 'body'
      })
    } else {
      description.html('')
      preconditions.html('')
      preconditionsContainer.slideUp(150)
      checklistItems.html('')
      checklistItemsContainer.slideUp(150)
      serviceElement.slideUp(150)
    }

    HidePreconditions(panel)
    HideChecklistItems(panel)
  }

  var RenderPrecondition = function (precondition) {
    var notificationHtml = ''
    var notificationTooltip = ''
    if (precondition.notification_time_unit_count) {
      notificationTooltip = 'Notification sent ' + precondition.notification_time_unit_count + ' ' + precondition.notification_time_unit.toLowerCase() + (precondition.notification_time_unit_count == 1 ? '' : 's') + ' before due date'
      if (precondition.reminder_time_unit_count) {
        notificationTooltip += ' with reminders sent every ' + precondition.reminder_time_unit_count + ' ' + precondition.reminder_time_unit.toLowerCase() + (precondition.reminder_time_unit_count == 1 ? '' : 's')
      }

      notificationHtml = '<div class="notification" rel="tooltip" title="" data-original-title="' + notificationTooltip + '"><i class="fa fa-fw fa-envelope"></i></div>'
    }

    return '' +
      '<div class="precondition">' +
      '<div class="name">' + precondition.name + '</div>' +
      notificationHtml +
      '</div>'
  }

  var RenderChecklistItem = function (checklistItem) {
    var notificationHtml = ''
    var notificationTooltip = ''
    if (checklistItem.generates_activity_feed_item_on_completion) {
      notificationTooltip = 'Notification sent on completion'
      notificationHtml = '<div class="notification" rel="tooltip" title="" data-original-title="' + notificationTooltip + '"><i class="fa fa-fw fa-envelope"></i></div>'
    }

    return '' +
      '<div class="checklist-item">' +
      '<div class="name">' + checklistItem.name + '</div>' +
      notificationHtml +
      '</div>'
  }

  var GetMatter = function (panel) {
    return panel.find('.panel-section[data-id="matter"] [name="matter"]').val()
  }

  var SetMatterSection = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="matter"]')
    var matter = section.find('[name="matter"]')
    matter.val(configuration.matter)
    matter.attr('placeholder', configuration.matter_placeholder)
    section.slideDown(150)
  }

  var GetAssigneeSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="assignee"]').data('configuration')
  }

  var SetAssigneeSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="assignee"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateAssigneeSection = function (panel) {
    var section = panel.find('.panel-section[data-id="assignee"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration && configuration.service_manager_id) {
      summary.html('Jobs will be assigned to ' + Onkho.Formatter.MakePartyActiveElement(configuration.service_manager_id, configuration.service_manager_name) + '.')
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change service manager')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add service manager')
    }
  }

  var GetCommunicationsSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="communications"]').data('configuration')
  }

  var SetCommunicationsSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="communications"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateCommunicationsSection = function (panel) {
    var section = panel.find('.panel-section[data-id="communications"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration && configuration.sends_notifications) {
      summary.html('Client will receive automated notifications whenever a job is started or completed.')
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change notifications')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add notifications')
    }
  }

  var EnableLoading = function (panel, showPrimaryButtons) {
    panel.addClass('loading')
    if (showPrimaryButtons) {
      panel.addClass('show-primary-buttons')
    }
    panel.find('[name="service_id"]').select2('enable', false)
    panel.find('.panel-opener button').attr('disabled', true)
    Onkho.LoadingTools.ShowLoading(panel.find('[data-id="save"]'))
  }

  var DisableLoading = function (panel) {
    panel.removeClass('loading')
    panel.removeClass('show-save-button')
    panel.find('[name="service_id"]').select2('enable', true)
    panel.find('.panel-opener button').attr('disabled', false)
    Onkho.LoadingTools.HideLoading(panel.find('[data-id="save"]'))
  }

  var RegisterEditServiceInstancePanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('.panel-opener[data-id="assignee"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.EditServiceInstanceAssigneeSubpanel.LoadEditServiceInstanceAssigneeSubpanel(GetAssigneeSectionConfiguration(panel));
    });

    panel.find('.panel-opener[data-id="communications"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.EditServiceInstanceCommunicationsSubpanel.LoadEditServiceInstanceCommunicationsSubpanel(GetCommunicationsSectionConfiguration(panel));
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      EditServiceInstance(panel);
    });
  };

  var EditServiceInstance = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      EnableLoading(panel, true)

      var matter = GetMatter(panel)
      var assigneeConfiguration = GetAssigneeSectionConfiguration(panel)
      var communicationsConfiguration = GetCommunicationsSectionConfiguration(panel)

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF()

      formData.id = panel.data('serviceInstanceId')

      formData.matter = matter

      formData.service_start_from_mailbox_id = communicationsConfiguration.start_notification_activity_feed_item_configuration.from_mailbox_id;
      formData.service_start_address = communicationsConfiguration.start_notification_activity_feed_item_configuration.from_mailbox_address;
      formData.service_start_practice_name = communicationsConfiguration.start_notification_activity_feed_item_configuration.from_mailbox_practice_name;
      formData.service_start_subject = communicationsConfiguration.start_notification_activity_feed_item_configuration.subject;
      formData.service_start_content = communicationsConfiguration.start_notification_activity_feed_item_configuration.content;
      formData.service_complete_from_mailbox_id = communicationsConfiguration.complete_notification_activity_feed_item_configuration.from_mailbox_id;
      formData.service_complete_address = communicationsConfiguration.complete_notification_activity_feed_item_configuration.from_mailbox_address;
      formData.service_complete_practice_name = communicationsConfiguration.complete_notification_activity_feed_item_configuration.from_mailbox_practice_name;
      formData.service_complete_subject = communicationsConfiguration.complete_notification_activity_feed_item_configuration.subject;
      formData.service_complete_content = communicationsConfiguration.complete_notification_activity_feed_item_configuration.content;
      formData.sends_notifications = !!communicationsConfiguration.sends_notifications;

      formData.service_manager_id = assigneeConfiguration.service_manager_id;

      $.ajax(
        {
          type: 'PUT',
          url: Onkho.Variables.BaseURL + '/serviceInstance/' + panel.data('serviceInstanceId'),
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            DisableLoading(panel)

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.Remove(panel)
                $('.services-list .refresh').click()

                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break;
            }
          }
        });
    }
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[edit_service_instance].added', '.o-panel-container', function ()
    {
      var editServiceInstancePanel = $('.o-panel[data-panel-key="edit_service_instance"]');
      RegisterEditServiceInstancePanelBindings(editServiceInstancePanel);
    });
  };



  return {
    Init: Init,
    LoadEditServiceInstancePanel: LoadEditServiceInstancePanel,
    SetAssigneeSectionConfiguration: SetAssigneeSectionConfiguration,
    SetCommunicationsSectionConfiguration: SetCommunicationsSectionConfiguration
  };
}();
