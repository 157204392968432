Onkho.OnkhoServiceActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();

        RegisterEventListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        // --- Featured actions ---
        // Edit
        $('body').on('click', '.o-active-element[data-type="service"] .featured-action[data-id="edit"]', function (event)
        {
            EditService($(this).closest('.o-active-element').data('id'));
        });
    };

    var EditService = function (serviceId)
    {
        Onkho.OnkhoActiveElement.RemoveAll();
        Onkho.AddEditServicePanel.LoadAddEditServicePanel(serviceId)
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('onkho:activeElement[service].added', '.o-active-element', function ()
        {
            LoadServiceScheduleDescription($(this));
        });
    };

    var LoadServiceScheduleDescription = function (activeElement)
    {
        // Compute the friendly service schedule description
        activeElement.find('.detail[data-id="service_schedule"]').each(function (index, element)
        {
            var serviceDescriptionLoader = $(element).find('.service-description-loader');
            serviceDescriptionLoader.replaceWith(Onkho.RegisterServicePageTools.DescribeServiceScheduleConfiguration(serviceDescriptionLoader.data('service-configuration'), true));
        });
    };



    return {
        Init: Init
    };
}();
