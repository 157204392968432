// Module containing tools to be used when displaying a mailboxes list
Onkho.MailboxesListTools = function ()
{
    var mailboxesContainer;

    var Init = function ()
    {
        mailboxesContainer = $('#mailboxes');

        Onkho.OnkhoList.Init(mailboxesContainer.find('.o-list'), 'mailboxes', {
            'practiceId': Onkho.Variables.PartyId
        });

        RegisterListBindings();
        RegisterPanelBindings();

        Onkho.ViewMailboxPanel.Init();
        Onkho.AddMailboxPanel.Init();
        Onkho.EditMailboxPanel.Init();
        Onkho.AddEditMailboxSecondaryPanel.Init();
        Onkho.AddEditMailboxAdditionalPanel.Init();
        Onkho.AddEditMailboxScanningConfigurationPanel.Init();
        Onkho.AddEditMailboxScanningConfigurationNylasPanel.Init();
    };

    var RegisterListBindings = function ()
    {
        mailboxesContainer.on('click', '.mailboxes-list-item .title > a', function ()
        {
            Onkho.ViewMailboxPanel.LoadViewMailboxPanel($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="delete"]', function ()
        {
            ConfirmDeleteMailbox($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.items-actions [data-id="delete"]', function ()
        {
            ConfirmDeleteMailboxes();
        });

        mailboxesContainer.on('click', '.list-actions [data-id="add_mailbox"]', function ()
        {
            Onkho.AddMailboxPanel.LoadAddMailboxPanel();
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="edit"]', function ()
        {
            Onkho.EditMailboxPanel.LoadEditMailboxPanel($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="activate"]', function ()
        {
            ActivateMailbox($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="test"]', function ()
        {
            TestMailbox($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="scan"]', function ()
        {
            ScanMailbox($(this).closest('.mailboxes-list-item').data('id'));
        });

        mailboxesContainer.on('click', '.mailboxes-list-item .actions [data-id="make-default"]', function ()
        {
            MakeMailboxDefault($(this).closest('.mailboxes-list-item').data('id'));
        });

        // Placeholder buttons
        mailboxesContainer.on('click', '.content-placeholder [data-id="add_mailbox"]', function ()
        {
            mailboxesContainer.find('.list-actions [data-id="add_mailbox"]').click();
        });
    };

    var RegisterPanelBindings = function ()
    {

    };

    var ActivateMailbox = function (mailboxId)
    {
      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();
      formData.mailbox_id = mailboxId;

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/mailbox/activateMailbox',
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            switch (data.status)
            {
              case 200:
                window.location.href = data.responseJSON.activationUrl;
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                break;
            }
          }
        });
    };

    var TestMailbox = function (mailboxId)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.mailbox_id = mailboxId;

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/mailbox/testMailbox',
              data: formData,
              dataType: 'json',
              complete: function (data)
              {
                  switch (data.status)
                  {
                      case 200:
                          if (data.responseJSON.testResult) {
                              Onkho.Alert.SmallBox('success', data.responseJSON.message);
                          } else {
                              Onkho.Alert.BigBox('warning', data.responseJSON.message);
                          }

                          Onkho.OnkhoList.Refresh(mailboxesContainer.find('.o-list'));
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };

    var ScanMailbox = function (mailboxId)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.mailbox_id = mailboxId;

        Onkho.Alert.SmallBox('info', 'We are now scanning your mailbox. This may take a few minutes.');

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/mailbox/scanMailbox',
              data: formData,
              dataType: 'json',
              complete: function (data)
              {
                  switch (data.status)
                  {
                      case 200:
                          if (data.responseJSON.scanResult == 'OK') {
                            Onkho.Alert.SmallBox('success', data.responseJSON.message);
                          } else {
                            Onkho.Alert.SmallBox('error', data.responseJSON.message);
                          }

                          Onkho.OnkhoList.Refresh(mailboxesContainer.find('.o-list'));
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };

    var MakeMailboxDefault = function (mailboxId)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.mailbox_id = mailboxId;

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/mailbox/makeMailboxDefault',
              data: formData,
              dataType: 'json',
              complete: function (data)
              {
                  switch (data.status)
                  {
                      case 200:
                          Onkho.Alert.SmallBox('success', data.responseJSON.message);

                          Onkho.OnkhoList.Refresh(mailboxesContainer.find('.o-list'));
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };

    var ConfirmDeleteMailboxes = function () {
        $.SmartMessageBox(
          {
              title: 'Delete mailboxes',
              content: 'Are you sure you want to delete the selected mailboxes? The emails that have already been retrieved from these mailboxes will not be deleted.',
              buttons: '[No][Yes]'
          }, function (ButtonPress) {
              if (ButtonPress == 'Yes') {
                  DeleteMailboxes()
              }
          }
        )
    }

    var DeleteMailboxes = function () {
        var list = mailboxesContainer.find('.o-list');

        var formData = {}
        formData._token = Onkho.Functions.GetCSRF()
        formData.mailboxIds = []
        $(Onkho.OnkhoList.GetSelected(list)).each(function (index, listItem) {
            formData.mailboxIds.push(listItem.data('id'))
        })

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/mailbox/deleteMailboxes',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                  switch (data.status) {
                      case 200:
                          Onkho.Alert.SmallBox('success', data.responseJSON.message)
                          Onkho.OnkhoList.Refresh(list)
                          break

                      default:
                          Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                          break
                  }
              }
          })
    }

    var ConfirmDeleteMailbox = function (mailboxId) {
        $.SmartMessageBox(
          {
              title: 'Delete mailbox',
              content: 'Are you sure you want to delete this mailbox? The emails that have already been retrieved from the mailbox will not be deleted.',
              buttons: '[No][Yes]'
          }, function (ButtonPress) {
              if (ButtonPress == 'Yes') {
                  DeleteMailbox(mailboxId)
              }
          }
        )
    }

    var DeleteMailbox = function (mailboxId) {
        var list = mailboxesContainer.find('.o-list');

        var formData = {}
        formData._token = Onkho.Functions.GetCSRF()
        formData.mailboxId = mailboxId

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/mailbox/deleteMailbox',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                  switch (data.status) {
                      case 200:
                          Onkho.Alert.SmallBox('success', data.responseJSON.message)
                          Onkho.OnkhoList.Refresh(list)
                          break

                      default:
                          Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                          break
                  }
              }
          })
    }



    return {
        Init: Init,
    };
}();
