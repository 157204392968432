// Module containing tools to be used for managing practice preferences
Onkho.PracticePreferencesTools = function () {
  var Init = function () {
    $('#preferences .practice-preferences-container .save').on('click', function () {
      SavePracticePreferences($(this).closest('.practice-preferences-container'));
    });
  };

  var SavePracticePreferences = function (container, callback)
  {
    var callbackArguments = Array.prototype.slice.call(arguments, 1);
    Onkho.LoadingTools.ShowLoading(container.find('.save'))

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.preferences = [];

    container.find('select').each(function (index, preference) {
      preference = $(preference)
      formData.preferences.push({
        'preference': preference.attr('name'),
        'value': preference.val(),
        'context': preference.data('type') == 'local' ? preference.data('context') : null
      })
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/practicePreference',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(container.find('.save'))

          switch (data.status) {
            case 200:
              // Set new initial values
              container.find('select').each(function (index, preference) {
                preference = $(preference)
                preference.find('option').attr('data-initial-selected', '0')
                if (preference.data('type') == 'global') {
                  preference.find(
                    'option[value="' + data.responseJSON.preferences.global[preference.attr('name')] + '"]'
                  ).attr('data-initial-selected', '1')
                } else if (preference.data('type') == 'local') {
                  preference.find(
                    'option[value="' + data.responseJSON.preferences.local[preference.attr('data-context')][preference.attr('name')] + '"]'
                  ).attr('data-initial-selected', '1')
                }
              })

              Onkho.Functions.ExecuteCallback.apply(null, callbackArguments.concat(data.responseJSON.preferences));
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  };


  return {
    Init: Init,
    SavePracticePreferences: SavePracticePreferences
  }
}()
