// Module containing tools to be used on the inbox page
Onkho.InboxPageTools = function () {
  var Init = function () {
    $('.inbox-container  .saved-insights .saved-activity-feed-group-insights-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HideActivityFeedGroupInsights()
      } else {
        ShowActivityFeedGroupInsights()
      }
    })

    $('.inbox-container  .saved-insights .saved-activity-feed-item-insights-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HideActivityFeedItemInsights()
      } else {
        ShowActivityFeedItemInsights()
      }
    })
  }

  var HideActivityFeedGroupInsights = function (panel) {
    var button = $('.inbox-container  .saved-insights .saved-activity-feed-group-insights-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    $('.inbox-container .saved-insights .saved-activity-feed-group-insights-container .saved-activity-feed-group-insights').slideUp(150)
  }

  var ShowActivityFeedGroupInsights = function (panel) {
    var button = $('.inbox-container  .saved-insights .saved-activity-feed-group-insights-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    $('.inbox-container .saved-insights .saved-activity-feed-group-insights-container .saved-activity-feed-group-insights').slideDown(150)
  }

  var HideActivityFeedItemInsights = function (panel) {
    var button = $('.inbox-container  .saved-insights .saved-activity-feed-item-insights-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    $('.inbox-container .saved-insights .saved-activity-feed-item-insights-container .saved-activity-feed-item-insights').slideUp(150)
  }

  var ShowActivityFeedItemInsights = function (panel) {
    var button = $('.inbox-container  .saved-insights .saved-activity-feed-item-insights-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    $('.inbox-container .saved-insights .saved-activity-feed-item-insights-container .saved-activity-feed-item-insights').slideDown(150)
  }



  return {
    Init: Init
  }
}()



// Module that refreshes the counts for unread and unmatched messages
Onkho.InboxLiveCount = function () {
  var Init = function () {
    if (Onkho.Variables.CanUsePaidFeatures) {
      RefreshCounts();

      // Refresh counts
      setInterval(function () {
        RefreshCounts();
      }, 10000);
    }
  }

  var RegenerateNonce = function () {
    $('#left-panel nav [data-page-title="inbox"]').data('nonce', Math.floor(Math.random() * 10000 + 1));
  }

  var CheckNonce = function (nonce) {
    return parseInt($('#left-panel nav [data-page-title="inbox"]').data('nonce')) === parseInt(nonce);
  }

  var GetCurrentNonce = function () {
    return $('#left-panel nav [data-page-title="inbox"]').data('nonce')
  }

  var SetUnreadItemsCount = function (count) {
    // Update count in the navbar
    var navCount = $('#left-panel nav [data-page-title="inbox"] .unread-count');
    navCount.html(count);

    if (count > 0) {
      navCount.removeClass('hidden');
    } else {
      navCount.addClass('hidden');
    }

    // Update count in the system folders of the inbox page
    if (Onkho.Variables.PageName == 'inbox') {
      var systemFolderCount = $('.inbox-container .system-folder[data-id="inbox"] .unread-count');
      systemFolderCount.html(count + ' unread');

      if (count > 0) {
        systemFolderCount.removeClass('hidden');
      } else {
        systemFolderCount.addClass('hidden');
      }
    }
  }

  var SetUnhandledErrorItemsCount = function (count) {
    // Update count in the navbar
    var navCount = $('#left-panel nav [data-page-title="inbox"] .unhandled-errors-count');
    navCount.html(count);

    if (count > 0) {
      navCount.removeClass('hidden');
    } else {
      navCount.addClass('hidden');
    }
  }

  var SetInvalidMailboxesCount = function (count) {
    if (count > 0) {
      if ($('#main > .notifications-container .alert[data-id="invalid_mailbox"]').length == 0) {
        var alert = '' +
          '<div class="alert alert-warning fade in" data-id="invalid_mailbox">' +
          '        <i class="fa-fw fa fa-warning"></i>' +
          '        <strong>Warning</strong> ' + count + ' of your mailboxes needs your attention. Please check ' + (count == 1 ? 'its' : 'their') + ' configuration and use the test feature to clear this warning.' +
          '    </div>';

        $('#main > .notifications-container').append(alert);
      }
    } else {
      $('#main > .notifications-container .alert[data-id="invalid_mailbox"]').remove();
    }
  }

  var SetInactiveMailboxesCount = function (count) {
    if (count > 0) {
      if ($('#main > .notifications-container .alert[data-id="inactive_mailbox"]').length == 0) {
        var alert = '' +
          '<div class="alert alert-warning fade in" data-id="inactive_mailbox">' +
          '        <i class="fa-fw fa fa-warning"></i>' +
          '        <strong>Warning</strong> ' + count + ' of your mailboxes is inactive. Please activate ' + (count == 1 ? 'it' : 'them') + ' to continue using ' + (count == 1 ? 'it' : 'them') + '.' +
          '    </div>';

        $('#main > .notifications-container').append(alert);
      }
    } else {
      $('#main > .notifications-container .alert[data-id="inactive_mailbox"]').remove();
    }
  }

  var RefreshCounts = function () {
    RegenerateNonce();

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.nonce = GetCurrentNonce();

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/inbox/getCounts',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              if (CheckNonce(data.responseJSON.nonce)) {
                SetUnreadItemsCount(data.responseJSON.unreadItemsCount);
                SetUnhandledErrorItemsCount(data.responseJSON.unhandledErrorItemsCount);
                SetInvalidMailboxesCount(data.responseJSON.invalidMailboxesCount);
                SetInactiveMailboxesCount(data.responseJSON.inactiveMailboxesCount);
              }
              break
            default:
              // Failed
              break
          }
        }
      })
  }

  return {
    Init: Init,
    RefreshCounts: RefreshCounts
  }
}()

