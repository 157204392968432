Onkho.ActivityFeedGroupPane = function ()
{
    var Init = function ()
    {
        RegisterPaneBindings();

        RegisterEventListeners();
    };

    var RegisterEventListeners = function ()
    {
    };

    var RegisterPaneBindings = function ()
    {
        $('body').on('onkho:pane[activity_feed_group].added', '.o-pane-container', function ()
        {
            var activityFeedGroupPane = $('.o-pane[data-pane-key="activity_feed_group"]');
            RegisterActivityFeedGroupPaneBindings(activityFeedGroupPane);
        });
    };

    var RegisterActivityFeedGroupPaneBindings = function (pane)
    {
        pane.find('> .content-wrapper > .header .close[data-id="close"]').on('click', function ()
        {
            Onkho.OnkhoPane.Remove(pane);
        });
    };

    var LoadActivityFeedGroupPane = function (activityFeedGroupId, scrollToItemId)
    {
        var additionalData = {
            'activityFeedGroupId': activityFeedGroupId
        };

        if (typeof scrollToItemId !== 'undefined') {
            additionalData.scrollToItemId = scrollToItemId
        }

        Onkho.OnkhoPane.Add('activity_feed_group', additionalData, ActivityFeedGroupPaneLoadError, ActivityFeedGroupPaneLoaded);
    };

    var ActivityFeedGroupPaneLoadError = function ()
    {
        Onkho.Alert.BigBox('warning', 'Missing item', 'That item does not exist anymore. Someone may have deleted it.');
    };

    var ActivityFeedGroupPaneLoaded = function (pane)
    {
        Onkho.OnkhoPane.Show(pane);

        // Load the activity feed group
        Onkho.ActivityFeed.LoadGroup(pane.find('.activity-feed-group-loader'))
    };

    var ScrollToItemId = function (pane, itemId)
    {
        pane.removeAttr('data-scroll-to-item-id')
        pane.removeData('scrollToItemId')

        var itemsContainer = pane.find('.activity-feed-group > .activity-feed-items')
        var item = itemsContainer.find('.activity-feed-item[data-id="' + itemId + '"]')

        if (item.length > 0) {
            item.addClass('highlighted')
            item[0].scrollIntoView({
                behavior: 'smooth'
            })
        }
    };



    return {
        Init: Init,
        LoadActivityFeedGroupPane: LoadActivityFeedGroupPane,
        ScrollToItemId: ScrollToItemId
    };
}();
