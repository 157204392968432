Onkho.EnhancedMultiselect = function ()
{
    var Init = function ()
    {
        $('body').on('click', '.o-enhanced-multiselect .selection', function ()
        {
            OpenModal($(this).closest('.o-enhanced-multiselect'));
        });

        $('body').on('click', '.o-enhanced-multiselect > .content-wrapper > .toolbar .select-all', function ()
        {
            SelectAll($(this).closest('.o-enhanced-multiselect'));
        });

        $('body').on('click', '.o-enhanced-multiselect > .content-wrapper > .toolbar .deselect-all', function ()
        {
            DeselectAll($(this).closest('.o-enhanced-multiselect'));
        });

        $('body').on('click', '.o-enhanced-multiselect .modal .select-all', function ()
        {
            SelectAllModal($(this).closest('.modal'));
        });

        $('body').on('click', '.o-enhanced-multiselect .modal .deselect-all', function ()
        {
            DeselectAllModal($(this).closest('.modal'));
        });

        $('body').on('click', '.o-enhanced-multiselect .save', function ()
        {
            SaveSelection($(this).closest('.o-enhanced-multiselect'));
        });

        $('body').on('change', '.o-enhanced-multiselect .modal .list-wrapper .option input[type="checkbox"]', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectModalOption($(this).closest('.modal'), $(this).closest('.option').data('value'));
            }
            else
            {
                DeselectModalOption($(this).closest('.modal'), $(this).closest('.option').data('value'));
            }
        });

        $('body').on('change', '.o-enhanced-multiselect .modal .list-wrapper .category > .checkbox > input[type="checkbox"]', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectModalCategory($(this).closest('.modal'), $(this).closest('.category'));
            }
            else
            {
                DeselectModalCategory($(this).closest('.modal'), $(this).closest('.category'));
            }
        });

        $('body').on('click', '.o-enhanced-multiselect .modal .selected-list-wrapper .selected-option .delete', function ()
        {
            DeselectModalOption($(this).closest('.modal'), $(this).closest('.selected-option').data('value'));
        });

        // Refresh the labels of all enhanced multiselects
        $('.o-enhanced-multiselect').each(function ()
        {
            ComputeSelectionLabel($(this));
        });
    };

    var OpenModal = function (multiselect)
    {
        var modal = multiselect.find('.modal');

        var selected = GetSelection(multiselect);

        DeselectAllModal(modal);

        $(selected).each(function (index, value)
        {
            SelectModalOption(modal, value);
        });

        modal.modal('show');
    };

    var DeselectAllModal = function (modal)
    {
        modal.find('.list-wrapper .category .option').each(function ()
        {
            DeselectModalOption(modal, $(this).data('value'));
        })
    };

    var SelectAllModal = function (modal)
    {
        DeselectAllModal(modal);

        modal.find('.list-wrapper .category .option').each(function ()
        {
            SelectModalOption(modal, $(this).data('value'));
        })
    };

    var SelectModalCategory = function (modal, category)
    {
        category.find('.option').each(function ()
        {
            SelectModalOption(modal, $(this).data('value'));
        });
    };

    var DeselectModalCategory = function (modal, category)
    {
        category.find('.option').each(function ()
        {
            DeselectModalOption(modal, $(this).data('value'));
        });
    };

    var SelectModalOption = function (modal, value)
    {
        var escapedValue = String(value).replace(/\\/g, "\\\\");
        var listWrapper = modal.find('.list-wrapper');
        var selectedListWrapper = modal.find('.selected-list-wrapper');

        var option = listWrapper.find('.option[data-value="' + escapedValue + '"]');

        if (option.length == 0)
        {
            // Option not found, nothing to do
            return;
        }

        var label = option.find('div.label').clone();
        var description = option.find('div.description').clone();

        option.find('input[type="checkbox"]').prop('checked', true);

        if (selectedListWrapper.find('.selected-option[data-value="' + escapedValue + '"]').length == 0)
        {
            var selectedOption = $('<div class="selected-option"></div>');
            selectedOption.attr('data-value', String(value));
            selectedOption.append(label);
            selectedOption.append(description);
            selectedOption.append('<a href="javascript:void(0);" class="delete">×</a>');

            selectedListWrapper.append(selectedOption);
        }

        var allChecked = true;
        option.closest('.category').find('.option').each(function ()
        {
            if (!$(this).find('input[type="checkbox"]').prop('checked'))
            {
                allChecked = false;
                return false;
            }
        });

        if (allChecked)
        {
            option.closest('.category').find('> .checkbox > input[type="checkbox"]').prop('checked', true);
        }

        modal.find('.toolbar .deselect-all').show();
        if (selectedListWrapper.find('.selected-option').length == listWrapper.find('.option').length)
        {
            modal.find('.toolbar .select-all').hide();
        }
        else
        {
            modal.find('.toolbar .select-all').show();
        }
    }

    var DeselectModalOption = function (modal, value)
    {
        var escapedValue = String(value).replace(/\\/g, "\\\\");
        var listWrapper = modal.find('.list-wrapper');
        var selectedListWrapper = modal.find('.selected-list-wrapper');

        var option = listWrapper.find('.option[data-value="' + escapedValue + '"]');
        option.closest('.category').find('> .checkbox > input[type="checkbox"]').prop('checked', false);

        if (option.length == 0)
        {
            // Option not found, nothing to do
            return;
        }

        option.find('input[type="checkbox"]').prop('checked', false);

        selectedListWrapper.find('.selected-option[data-value="' + escapedValue + '"]').remove();

        modal.find('.toolbar .select-all').show();
        if (selectedListWrapper.find('.selected-option').length == 0)
        {
            modal.find('.toolbar .deselect-all').hide();
        }
        else
        {
            modal.find('.toolbar .deselect-all').show();
        }
    };

    var SaveSelection = function (multiselect)
    {
        var modal = multiselect.find('.modal');

        SetSelection(multiselect, GetModalSelection(modal));

        modal.modal('hide');

        ResetModal(multiselect);
    };

    var ResetModal = function (multiselect)
    {
        var modal = multiselect.find('.modal');

        var searchField = modal.find('.search-box-wrapper input[name="search"]');
        searchField.val('');
        searchField.trigger('keyup');
    };

    var GetModalSelection = function (modal)
    {
        var selected = [];

        modal.find('.selected-list-wrapper .selected-option').each(function ()
        {
            selected.push(String($(this).data('value')));
        });

        return selected;
    };

    var GetSelection = function (multiselect)
    {
        var output = [];

        multiselect.find('select option:selected').each(function ()
        {
            output.push(String($(this).val()));
        });

        return output;
    };

    var SetSelection = function (multiselect, selected)
    {
        var select = multiselect.find('select');

        select.find('option').each(function ()
        {
            $(this).prop('selected', $.inArray(String($(this).val()), selected) != -1);
        });

        select.trigger('change');

        ComputeSelectionLabel(multiselect);
    };

    var SelectAll = function (multiselect)
    {
        var selected = [];
        var select = multiselect.find('select');

        select.find('option').each(function ()
        {
            selected.push(String($(this).val()));
        });

        SetSelection(multiselect, selected);
    };

    var DeselectAll = function (multiselect)
    {
        SetSelection(multiselect, []);
    };

    var ComputeSelectionLabel = function (multiselect)
    {
        var selected = GetSelection(multiselect);
        var select = multiselect.find('select');
        var options = select.find('option');

        var label = '';

        if (selected.length == 0)
        {
            label = 'None selected';
        }
        else
        if (selected.length == 1)
        {
            select.find('option').each(function ()
            {
                if ($.inArray(String($(this).val()), selected) != -1)
                {
                    label += $(this).text() + ', ';
                }
            });
        }
        else
        if (selected.length == options.length)
        {
            label = 'All selected';
        }
        else
        {
            label = selected.length + ' selected';
        }

        multiselect.find('.selection .label').text(label.replace(/[, ]*$/, ''));
    };



    return {
        Init: Init,
        GetSelection: GetSelection,
        SetSelection: SetSelection,
        SelectAll: SelectAll,
        DeselectAll: DeselectAll,
        ComputeSelectionLabel: ComputeSelectionLabel,
        OpenModal: OpenModal
    };
}();

