// Module containing tools to be used when displaying a jobs list
Onkho.JobsListTools = function () {

    var jobsList = null;

    var Init = function () {

        Onkho.EditJobPageTools.Init();

        Onkho.EditJobPageTools.Config({
            callback: function() {
                ResetJobsList();
                LoadMore();
            }
        });

        Onkho.RegisterJobTools.Config({
            callback: function() {
                ResetJobsList();
                LoadMore();
            }
        });

        jobsList = $('.jobs-list');

        // Refresh jobs list when the tab is switched
        $('a[data-toggle="tab"][href="#jobs"]').on('show.bs.tab', function (event) {
            ResetJobsList();
            LoadMore(true);
        });

        // Load jobs list initially, if the jobs list is visible
        LoadMore();

        jobsList.find('.add-job').on('click', function () {
            var selectedPartyId = jobsList.find('[name="partyId"]').val();
            Onkho.RegisterJobTools.Show(selectedPartyId);
        });

        // Job action buttons
        jobsList.on('mouseenter', '.job', function () {
            var buttons = $(this).find('.job-action-buttons');
            buttons.finish();
            buttons.fadeIn(150);
        });

        jobsList.on('mouseleave', '.job', function () {
            var buttons = $(this).find('.job-action-buttons');
            buttons.finish();
            buttons.fadeOut(150);
        });

        // Select all
        $('input[name="select_all"]').on('change', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectAll();
            }
            else
            {
                DeselectAll();
            }
        });

        // Selected jobs counter
        jobsList.on('change', 'input[type="checkbox"]', function ()
        {
            UpdateTotalSelected();
        });

        // Sort
        jobsList.find('.sort-control .dropdown li a').on('click', function ()
        {
            var current = jobsList.find('.sort-control .dropdown .current');
            current.html($(this).html());
            current.data('value', $(this).data('value'));

            ResetJobsList();
            LoadMore();
        });

        // Filter
        jobsList.find('.filter-control .dropdown li a').on('click', function ()
        {
            var current = jobsList.find('.filter-control .dropdown .current');
            current.html($(this).html());
            current.data('value', $(this).data('value'));

            ResetJobsList();
            LoadMore();
        });

        // Search
        jobsList.find('.search-control [name="search"]').on('change', function ()
        {
            ResetJobsList();
            LoadMore();
        });

        // Change status
        $('body').on('click', '.jobs-list .job .job-action-buttons .start', function ()
        {
            var jobs = $(this).closest('.job');
            SetJobsStatus(jobs, 'STARTED');
        });

        $('body').on('click', '.jobs-list .job .job-action-buttons .pause', function ()
        {
            var jobs = $(this).closest('.job');
            SetJobsStatus(jobs, 'PAUSED');
        });

        $('body').on('click', '.jobs-list .job .job-action-buttons .complete', function ()
        {
            var jobs = $(this).closest('.job');
            SetJobsStatus(jobs, 'COMPLETED');
        });

        $('body').on('click', '.jobs-list .job .job-action-buttons .stop', function ()
        {
            var jobs = $(this).closest('.job');
            SetJobsStatus(jobs, 'NOT STARTED');
        });

        $('body').on('click', '.jobs-list .job .job-action-buttons .delete', function ()
        {
            var button = $(this);
            $.SmartMessageBox(
                {
                    title : "Deleting Job",
                    content : "Are you sure you want to delete this job?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress)
                {
                    if (ButtonPress == 'Yes')
                    {
                        var jobs = button.closest('.job');
                        Cancel(jobs);
                    }
                }
            );
        });

        var Cancel = function (jobs)
        {

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            formData.mode = 'JOBS';
            formData.ids = [];
            $(jobs).each(function ()
            {
                formData.ids.push($(this).data('id'));
                HideControls($(this));
            });
            HideControls($('.jobs-list .global-actions'));

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/job/cancel',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                    {
                        $(jobs).each(function ()
                        {
                            ShowControls($(this));
                        });

                        ShowControls($('.jobs-list .global-actions'));

                        switch (data.status)
                        {
                            case 200:
                                Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                ResetJobsList();
                                LoadMore();
                                break;

                            default:
                                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                break;
                        }
                    }
                });


        }

        // Global actions
        jobsList.find('.global-actions').on('click', '.start', function ()
        {
            SetJobsStatus(GetSelected(), 'STARTED');
        });

        jobsList.find('.global-actions').on('click', '.pause', function ()
        {
            SetJobsStatus(GetSelected(), 'PAUSED');
        });

        jobsList.find('.global-actions').on('click', '.complete', function ()
        {
            SetJobsStatus(GetSelected(), 'COMPLETED');
        });

        jobsList.find('.global-actions').on('click', '.stop', function ()
        {
            SetJobsStatus(GetSelected(), 'NOT STARTED');
        });

        jobsList.find('.global-actions').on('click', '.delete', function ()
        {
            var selectedJobs = GetSelected();
            $.SmartMessageBox(
                {
                    title : "Deleting Job",
                    content : "Are you sure you want to delete " + selectedJobs.length + " " + ((selectedJobs.length === 1) ? "job" : "jobs") + "?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress)
                {
                    if (ButtonPress == 'Yes')
                    {
                        Cancel(selectedJobs);
                    }
                }
            );
        });


        // Infinity scroll

        $('body').on('click', '.jobs-list .btn.load-more', function ()
        {
            if (!$(this).hasClass('disabled'))
            {
                LoadMore();
            }
        });

        $(window).on('scroll', function()
        {
            if ($(window).scrollTop() > ($(document).height() - $(window).height() - 200))
            {
                if (jobsList.find('.btn.load-more').length && !jobsList.find('.btn.load-more').hasClass('disabled'))
                {
                    LoadMore();
                }
            }
        }).scroll();

        // Refresh
        jobsList.find('.refresh').on('click', function ()
        {
            if (!jobsList.find('.btn.load-more').hasClass('disabled'))
            {
                ResetJobsList();
                LoadMore();
            }
        });
    };

    var GetSelected = function ()
    {
        var jobs = [];
        jobsList.find('.job input[type="checkbox"]:checked').each(function ()
        {
            jobs.push($(this).closest('.job'));
        });

        return jobs;
    };

    var SelectAll = function ()
    {
        jobsList.find('.job input[type="checkbox"]').prop('checked', true);
        Onkho.Variables.SelectAllJobs = true;
    };

    var DeselectAll = function ()
    {
        jobsList.find('.job input[type="checkbox"]').prop('checked', false);
        Onkho.Variables.SelectAllJobs = false;
    };

    var UpdateTotalSelected = function ()
    {
        var globalActions = jobsList.find('.global-actions');

        var total = parseInt(jobsList.find('.job input[type="checkbox"]:checked').length);
        globalActions.find('label').html('Selected ' + total + ' job' + (total != 1 ? 's' : ''));

        if (total > 0)
        {
            globalActions.finish();
            globalActions.fadeIn(150).css('display', 'inline-block');
        }
        else
        {
            globalActions.finish();
            globalActions.fadeOut(150);
        }
    };

    var HideControls = function (container)
    {
        container.find('.job-action-buttons').html('<i class="fa fa-spin fa-spinner"></i>');
    };

    var ShowControls = function (container)
    {
        container.find('.job-action-buttons').html(
            '<a href="javascript:void(0);" class="start">Start</a>' +
            '<a href="javascript:void(0);" class="pause">Pause</a>' +
            '<a href="javascript:void(0);" class="complete">Complete</a>' +
            '<a href="javascript:void(0);" class="stop">Stop</a>' +
            '<a href="javascript:void(0);" class="delete">Delete</a>'
        );
    };

    var UpdateStatus = function (job, className, status)
    {
        var jobStatus = job.find('.job-status');
        jobStatus.removeClass().addClass('label job-status job-status-' + className);
        jobStatus.html(status);
    };

    var SetJobsStatus = function (jobs, status)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        formData.jobs = [];
        $(jobs).each(function ()
        {
            formData.jobs.push($(this).data('id'));
            HideControls($(this));
        });

        formData.status = status;

        HideControls(jobsList.find('.global-actions'));

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/setMultiStatus',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    ShowControls(jobsList.find('.global-actions'));

                    switch (data.status)
                    {
                        case 200:
                            Onkho.Alert.SmallBox('success', data.responseJSON.message);

                            $(Object.keys(data.responseJSON.jobs)).each(function (key, jobId)
                            {
                                var job = $('.job[data-id="' + jobId + '"]');
                                ShowControls(job);
                                UpdateStatus(job, data.responseJSON.jobs[jobId].className, data.responseJSON.jobs[jobId].status);
                            });

                            break;

                        default:
                            jobsList.find('.job').each(function ()
                            {
                                ShowControls($(this));
                            });

                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var ResetJobsList = function ()
    {
        jobsList.find('table tbody').empty();
        jobsList.removeData('offset');
        jobsList.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
    };

    var LoadMore = function (force)
    {
        force = typeof(force) == 'undefined' ? false : force;

        if (!force && !$('#jobs').is(':visible'))
        {
            return;
        }

        var button = jobsList.find('.btn.load-more');
        button.html('<i class="fa fa-spin fa-spinner"></i>');
        button.addClass('disabled');

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.partyId = $('[name="partyId"]').attr('value');
        formData.offset = jobsList.data('offset');

        formData.sort = jobsList.find('.sort-control .current').data('value');
        formData.filter = jobsList.find('.filter-control .current').data('value');
        formData.search = jobsList.find('.search-control [name="search"]').val();

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/loadMore',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            if (data.responseJSON.html.length > 0)
                            {
                                jobsList.find('table tbody').append(data.responseJSON.html);
                                jobsList.data('offset', data.responseJSON.offset);
                                button.html('Load more');
                                button.removeClass('disabled');
                            }
                            else
                            {
                                jobsList.find('table tbody').append('<tr><td colspan="2" class="no-more">No more jobs.</td></tr>');
                                jobsList.find('.btn.load-more').remove();
                            }
                            break;
                        default:
                            // Failed
                            jobsList.find('table tbody').append('<tr><td colspan="2" class="no-more">Failed to load jobs.</td></tr>');
                            jobsList.find('.btn.load-more').remove();
                            if (data.responseJSON.message) {
                                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            }
                            break;
                    }
                }
            });

    };

    var LoadJobForEdit = function (job) {
        var jobId = job.data('id');
        Onkho.EditJobPageTools.LoadJobForEdit(jobId);
    };

    var Refresh = function ()
    {
        ResetJobsList();
        LoadMore();
    };

    return {
        Init: Init,
        Refresh: Refresh,
        UpdateStatus: UpdateStatus
    };
}();
