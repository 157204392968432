Onkho.OnkhoPane = function ()
{
    var Init = function ()
    {
        CreatePanesWrapper();

        // Setup close button
        $('body').on('click', '.o-pane > .content-wrapper > .header .close[data-id="close"]', function ()
        {
            Hide($(this).closest('.o-pane'));
        });

        // Setup resize buttons
        $('body').on('click', '.o-pane > .content-wrapper > .header .resize-default[data-id="resize-default"]', function ()
        {
            Resize($(this).closest('.o-pane'), 'default');
        });
        $('body').on('click', '.o-pane > .content-wrapper > .header .resize-third[data-id="resize-third"]', function ()
        {
            Resize($(this).closest('.o-pane'), 'third');
        });
        $('body').on('click', '.o-pane > .content-wrapper > .header .resize-half[data-id="resize-half"]', function ()
        {
            Resize($(this).closest('.o-pane'), 'half');
        });
        $('body').on('click', '.o-pane > .content-wrapper > .header .resize-full[data-id="resize-full"]', function ()
        {
            Resize($(this).closest('.o-pane'), 'full');
        });
    };

    var CreatePanesWrapper = function ()
    {
        if ($('.o-pane-wrapper').length == 0)
        {
            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/pane/getWrapper',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            $('body').append(data.responseJSON.html);
                            $('body').trigger('onkho:pane[wrapper].added');
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
        }
    };

    var RegenerateNonce = function ()
    {
        if ($('.o-pane-wrapper').length > 0)
        {
            $('.o-pane-wrapper').data('nonce', Math.floor(Math.random() * 10000 + 1));
        }
    };

    var CheckNonce = function (nonce)
    {
        return parseInt($('.o-pane-wrapper').data('nonce')) === parseInt(nonce);
    };

    var GetCurrentNonce = function ()
    {
        if ($('.o-pane-wrapper').length > 0)
        {
            return $('.o-pane-wrapper').data('nonce');
        }
        else
        {
            return 0;
        }
    };

    var Add = function (key, data, errorCallback, successCallback)
    {
        var successCallbackArguments = Array.prototype.slice.call(arguments, 3);
        RegenerateNonce();
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = GetCurrentNonce();
        formData.key = key;
        formData.data = data;

        $.ajax(
        {
            type: 'POST',
            url: Onkho.Variables.BaseURL + '/pane/getPane',
            data: formData,
            dataType: 'json',
            complete: function (data)
                  {
                      switch (data.status)
                      {
                          case 200:
                              if (CheckNonce(data.responseJSON.nonce))
                              {
                                  var pane = $(data.responseJSON.html);
                                  $('.o-pane-container').append(pane);

                                  pane.trigger('onkho:pane[' + pane.data('pane-key') + '].added');

                                  Onkho.Functions.ExecuteCallback.apply(null, successCallbackArguments.concat(pane));
                              }
                              break;

                          default:
                              if (typeof errorCallback !== 'undefined') {
                                  Onkho.Functions.ExecuteCallback.apply(null, [errorCallback]);
                              } else {
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                              }
                              break;
                      }
                  }
        });
    };

    var Remove = function (pane, callback)
    {
        var key = pane.data('pane-key');
        Hide(pane);

        pane.find('input, select').select2('destroy');
        pane.remove();

        $('.o-pane-container').trigger('onkho:pane[' + key + '].removed');
        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1));
    };

    var RemoveAll = function (callback)
    {
        $('.o-pane-container .o-pane').each(function (index, pane) {
            Remove($(pane))
        });

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 0));
    };

    var Show = function (pane, callback)
    {
        ShowBackdrop();

        if (pane.hasClass('o-pane') && pane.hasClass('hidden'))
        {
            pane.trigger('onkho:pane[' + pane.data('pane-key') + '].shown');
            pane.removeClass('hidden').addClass('shown');

            $('[rel="tooltip"], [data-toggle="tooltip"]').tooltip('hide');

            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(pane));
        }
    };

    var Hide = function (pane, callback)
    {
        if (pane.hasClass('o-pane') && pane.hasClass('shown'))
        {
            pane.trigger('onkho:pane[' + pane.data('pane-key') + '].hidden');
            pane.removeClass('shown').addClass('hidden');

            HideBackdrop.apply(null, Array.prototype.slice.call(arguments, 1));
        }
    };

    var Resize = function (pane, size)
    {
        pane.removeClass('o-pane-third o-pane-half o-pane-full');

        switch (size) {
            case 'third':
                pane.addClass('o-pane-third');
                pane.find('> .content-wrapper > .header .resize-default[data-id="resize-default"]').hide();
                pane.find('> .content-wrapper > .header .resize-third[data-id="resize-third"]').hide();
                pane.find('> .content-wrapper > .header .resize-half[data-id="resize-half"]').show();
                pane.find('> .content-wrapper > .header .resize-full[data-id="resize-full"]').hide();
                break;

            case 'half':
                pane.addClass('o-pane-half');
                pane.find('> .content-wrapper > .header .resize-default[data-id="resize-default"]').hide();
                pane.find('> .content-wrapper > .header .resize-third[data-id="resize-third"]').hide();
                pane.find('> .content-wrapper > .header .resize-half[data-id="resize-half"]').hide();
                pane.find('> .content-wrapper > .header .resize-full[data-id="resize-full"]').show();
                break;

            case 'full':
                pane.addClass('o-pane-full');
                pane.find('> .content-wrapper > .header .resize-default[data-id="resize-default"]').show();
                pane.find('> .content-wrapper > .header .resize-third[data-id="resize-third"]').hide();
                pane.find('> .content-wrapper > .header .resize-half[data-id="resize-half"]').hide();
                pane.find('> .content-wrapper > .header .resize-full[data-id="resize-full"]').hide();
                break;

            default:
                pane.find('> .content-wrapper > .header .resize-default[data-id="resize-default"]').hide();
                pane.find('> .content-wrapper > .header .resize-third[data-id="resize-third"]').show();
                pane.find('> .content-wrapper > .header .resize-half[data-id="resize-half"]').hide();
                pane.find('> .content-wrapper > .header .resize-full[data-id="resize-full"]').hide();
        }
    };

    var ShowBackdrop = function (callback)
    {
        var wrapper = $('.o-pane-wrapper');
        if (wrapper.hasClass('hidden'))
        {
            wrapper.trigger('onkho:pane[wrapper].shown');
            wrapper.removeClass('hidden').addClass('shown');
            $('body').addClass('o-pane-noscroll');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var HideBackdrop = function (callback)
    {
        var wrapper = $('.o-pane-wrapper');
        if (wrapper.hasClass('shown') && $('.o-pane.shown').length == 0)
        {
            wrapper.trigger('onkho:pane[wrapper].hidden');
            wrapper.removeClass('shown').addClass('hidden');
            $('body').removeClass('o-pane-noscroll');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var SetTitle = function (pane, title)
    {
        pane.find('> .content-wrapper > .header > .title').html(title);
    };

    return {
        Init: Init,
        Add: Add,
        Remove: Remove,
        RemoveAll: RemoveAll,
        Show: Show,
        Hide: Hide,
        SetTitle: SetTitle,
    };
}();
