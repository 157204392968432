Onkho.AddEditServiceChecklistItemsSubpanel = function ()
{
  var defaultInput = null
  var step5Fields = null
  var step5UserInput = null

  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var LegacyInit = function (panel)
  {
    // Reference modal once.
    var modal = panel;

    var activityFeedItemBuilder = modal.find('.activity-feed-item-builder');

    // Fields
    step5Fields = {
      step_name: modal.find('input[type="text"][name="step_name"]'),
      step_complete_activity_feed_item: modal.find('input[type="checkbox"][name="step_complete_activity_feed_item"]'),
      step_complete_activity_feed_item_from_mailbox_id : modal.find('input[name="from_mailbox_id"]'),
      step_complete_activity_feed_item_subject: modal.find('.summernote.subject-area'),
      step_complete_activity_feed_item_content: modal.find('.summernote.content-area')
    };

    step5UserInput = Object.assign({}, defaultInput);

    Onkho.ActivityFeed.InitializeRecipientsPicker(activityFeedItemBuilder, 'OUTGOING_EMAIL');

    var clearStepName =
      function() {
        step5Fields.step_name.val(step5UserInput.step_name).change();
        Onkho.Validator.ResetValidation([step5Fields.step_name]);
      };

    var clearStepCompleteActivityFeedItem =
      function() {
        step5Fields.step_complete_activity_feed_item.prop('checked', step5UserInput.step_complete_activity_feed_item).change();
        Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item]);
      };

    var clearStepCompleteActivityFeedItemFromMailboxId =
      function() {
        if (step5UserInput.step_complete_activity_feed_item_from_mailbox_id) {
          step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('data', {
            id: step5UserInput.step_complete_activity_feed_item_from_mailbox_id,
            address: step5UserInput.step_complete_activity_feed_item_from_mailbox_address,
            practice_name: step5UserInput.step_complete_activity_feed_item_from_mailbox_practice_name
          });
        } else {
          step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('val', '');
        }
        Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_from_mailbox_id]);
      };

    var clearStepCompleteActivityFeedItemSubject =
      function() {
        Onkho.Summernote.DestroySummernote(
          step5Fields.step_complete_activity_feed_item_subject
        );

        Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_subject]);
      };

    var clearStepCompleteActivityFeedItemContent =
      function() {
        Onkho.Summernote.DestroySummernote(
          step5Fields.step_complete_activity_feed_item_content
        );

        Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_content]);
      };

    // This event is responsible for clearing all input.
    modal.on('clearInput',
      function() {
        clearStepName();
        clearStepCompleteActivityFeedItem();
        clearStepCompleteActivityFeedItemFromMailboxId();
        clearStepCompleteActivityFeedItemSubject();
        clearStepCompleteActivityFeedItemContent();
      }
    );

    // This event is responsible for initialising the summernote WYSIWYG editor.
    modal.on('initSummernote',
      function() {
        if (step5UserInput.step_complete_activity_feed_item) {
          Onkho.Summernote.EnableSummernote(
            step5Fields.step_complete_activity_feed_item_subject,
            'JOB',
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            'Subject'
          );
          Onkho.Summernote.UpdateSummernote(
            step5Fields.step_complete_activity_feed_item_subject, step5UserInput.step_complete_activity_feed_item_subject, false
          );

          Onkho.Summernote.EnableSummernote(
            step5Fields.step_complete_activity_feed_item_content,
            'JOB'
          );
          Onkho.Summernote.UpdateSummernote(
            step5Fields.step_complete_activity_feed_item_content, step5UserInput.step_complete_activity_feed_item_content, false
          );
        } else {
          Onkho.Summernote.DestroySummernote(
            step5Fields.step_complete_activity_feed_item_subject
          );
          Onkho.Summernote.DestroySummernote(
            step5Fields.step_complete_activity_feed_item_content
          );
        }
      }
    );

    // Show the message input if a user opts to send a notification with this step.
    step5Fields.step_complete_activity_feed_item.on('change',
      function() {
        var self = $(this);
        var isChecked  = self.is(':checked');

        if (isChecked) {
          Onkho.Summernote.EnableSummernote(
            step5Fields.step_complete_activity_feed_item_subject,
            'JOB',
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            'Subject'
          );

          Onkho.Summernote.UpdateSummernote(
            step5Fields.step_complete_activity_feed_item_subject, step5UserInput.step_complete_activity_feed_item_subject, false
          );

          Onkho.Summernote.EnableSummernote(
            step5Fields.step_complete_activity_feed_item_content, 'JOB'
          );

          Onkho.Summernote.UpdateSummernote(
            step5Fields.step_complete_activity_feed_item_content, step5UserInput.step_complete_activity_feed_item_content, false
          );

          Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_content]);

          activityFeedItemBuilder.slideDown(function() {
            step5Fields.step_complete_activity_feed_item_content.summernote('focus');
          });
        } else {
          activityFeedItemBuilder.slideUp(function() {
            Onkho.Summernote.DestroySummernote(step5Fields.step_complete_activity_feed_item_content);
          });
        }
      }
    );

    // Manually trigger the 'clearInput' & 'initSummernote' events the first time
    modal.trigger('clearInput').trigger('initSummernote');
    step5UserInput = Object.assign({}, defaultInput);
  }

  var updateStep5UserInput = function(step5UserInput, defaultInput) {
    // The values pertaining to the name of the step.
    var stepName = step5Fields.step_name.val();

    // The values pertaining to the notification of the step.
    var stepNotification = step5Fields.step_complete_activity_feed_item.is(':checked');

    // The values pertaining to the message of the step.
    var fromMailboxIdValue = step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('data');
    var stepCompleteActivityFeedItemFromMailboxId = fromMailboxIdValue === null ? null : fromMailboxIdValue.id;
    var stepCompleteActivityFeedItemFromMailboxAddress = fromMailboxIdValue === null ? null : fromMailboxIdValue.address;
    var stepCompleteActivityFeedItemFromMailboxPracticeName = fromMailboxIdValue === null ? null : fromMailboxIdValue.practice_name;
    var stepCompleteActivityFeedItemSubject = Onkho.Summernote.GetSummernoteValue(step5Fields.step_complete_activity_feed_item_subject);
    var stepCompleteActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step5Fields.step_complete_activity_feed_item_content);

    step5UserInput.step_name = stepName;
    step5UserInput.step_complete_activity_feed_item = stepNotification;
    step5UserInput.step_complete_activity_feed_item_from_mailbox_id = stepCompleteActivityFeedItemFromMailboxId;
    step5UserInput.step_complete_activity_feed_item_from_mailbox_address = stepCompleteActivityFeedItemFromMailboxAddress;
    step5UserInput.step_complete_activity_feed_item_from_mailbox_practice_name = stepCompleteActivityFeedItemFromMailboxPracticeName;
    step5UserInput.step_complete_activity_feed_item_subject = stepCompleteActivityFeedItemSubject;
    step5UserInput.step_complete_activity_feed_item_content = stepCompleteActivityFeedItemContent;
    step5UserInput.ordinal = defaultInput.ordinal;
  };

  var toBoolean = function(val) {
    if (typeof val === 'boolean') {
      return val;
    }
    if (typeof val === 'string') {
      return val === 'true';
    }
    return Boolean(val);
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_service_checklist_items"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditServiceChecklistItemsSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    };

    Onkho.OnkhoPanel.Add('add_edit_service_checklist_items', additionalData, LoadAddEditServiceChecklistItemsSubpanelLoaded)
  };

  var LoadAddEditServiceChecklistItemsSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)

    $('[rel=tooltip]').tooltip('hide')

    // Load configuration
    defaultInput = panel.data('configuration')
    defaultInput.step_complete_activity_feed_item = toBoolean(defaultInput.step_complete_activity_feed_item)

    // Init method formerly used by Onkho-RegisterServicePageTools
    LegacyInit(panel)
  };

  var RegisterAddEditServiceChecklistItemsSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_service"]'))
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveChecklistItems(panel);
    });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_service_checklist_items].added', '.o-panel-container', function ()
    {
      var AddEditServicesChecklistItemsSubpanel = $('.o-panel[data-panel-key="add_edit_service_checklist_items"]');
      RegisterAddEditServiceChecklistItemsSubpanelBindings(AddEditServicesChecklistItemsSubpanel);
    });
  };

  var SaveChecklistItems = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      updateStep5UserInput(step5UserInput, defaultInput);
      var AddEditServicesPanel = $('.o-panel[data-panel-key="add_edit_service"]')
      var configuration = GatherConfiguration(panel)
      Onkho.AddEditServicePanel.SetChecklistItemConfiguration(AddEditServicesPanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = step5UserInput
    return configuration
  }



  return {
    Init: Init,
    LoadAddEditServiceChecklistItemsSubpanel: LoadAddEditServiceChecklistItemsSubpanel
  };
}();
