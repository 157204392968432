Onkho.LiveSearch = function ()
{
    /*
        The expected HTML structure is:
        container-class
            searchbox
            category-class
                item-class
                    item-label-class
                item-class
                    item-label-class
                [..]
            category-class
                item-class
                    item-label-class
                [..]
            [..]
     */

    var Init = function (searchInput, container, categoryClass, itemClass, itemLabelClass)
    {
        $('body').on('keyup', 'input.o-livesearch', function ()
        {
            FilterResults($(this).val(), $(this).closest('.' + $(this).data('o-livesearch-container-class')), $(this).data('o-livesearch-category-class'), $(this).data('o-livesearch-item-class'), $(this).data('o-livesearch-item-label-class'));
        });
    };

    var FilterResults = function (searchTerm, container, categoryClass, itemClass, itemLabelClass)
    {
        $(container).find('.' + categoryClass + ' .' + itemClass).each(function ()
        {
            var item = $(this);
            var itemValue = item.find('.' + itemLabelClass).text();

            if (itemValue.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1)
            {
                // Item should be visible
                ShowItem(item, categoryClass);
            }
            else
            {
                // Item should be hidden
                HideItem(item, categoryClass, itemClass);
            }
        });
    };

    var ShowItem = function (item, categoryClass)
    {
        var category = item.closest('.' + categoryClass);

        // Category should be visible
        if (!category.is(':visible'))
        {
            category.show();
        }

        item.show();
    };

    var HideItem = function (item, categoryClass, itemClass)
    {
        var category = item.closest('.' + categoryClass);
        var items = category.find('.' + itemClass);

        item.hide();

        // Category should only visible if there are other visible items inside it
        var categoryVisible = false;
        items.each(function ()
        {
            if ($(this).is(':visible'))
            {
                categoryVisible = true;
                return false;
            }
        });

        if (categoryVisible)
        {
            category.show();
        }
        else
        {
            category.hide();
        }
    };



    return {
        Init: Init
    };
}();
