// Module containing tools to be used for managing user preferences
Onkho.UserPreferencesTools = function () {
  var Init = function () {
    //
  };

  var ShowUserPreferencesModal = function (modal) {
    ResetUserPreferencesModal(modal)
    modal.modal('show')
  }

  var ResetUserPreferencesModal = function (modal) {
    var preferences = modal.find('.preference')
    preferences.each(function (index, preference) {
      preference = $(preference)

      var global = preference.find('.global')
      if (global) {
        // Global preference
        global.find('select').val(global.find('option[data-initial-selected="1"]').val())
      }

      var local = preference.find('.local')
      if (local) {
        // Local preference
        local.find('select').val(local.find('option[data-initial-selected="1"]').val())
      }
    })
  }

  var SaveUserPreferencesModal = function (modal, callback)
  {
    var callbackArguments = Array.prototype.slice.call(arguments, 1);
    Onkho.LoadingTools.ShowLoading(modal.find('.save'))

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.preferences = [];

    modal.find('select').each(function (index, preference) {
      preference = $(preference)
      formData.preferences.push({
        'preference': preference.attr('name'),
        'value': preference.val(),
        'context': preference.data('type') == 'local' ? preference.data('context') : null
      })
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/preference',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(modal.find('.save'))

          switch (data.status) {
            case 200:
              modal.modal('hide')

              // Set new initial values
              modal.find('select').each(function (index, preference) {
                preference = $(preference)
                preference.find('option').attr('data-initial-selected', '0')
                if (preference.data('type') == 'global') {
                  preference.find(
                    'option[value="' + data.responseJSON.preferences.global[preference.attr('name')] + '"]'
                  ).attr('data-initial-selected', '1')
                } else if (preference.data('type') == 'local') {
                  preference.find(
                    'option[value="' + data.responseJSON.preferences.local[preference.attr('data-context')][preference.attr('name')] + '"]'
                  ).attr('data-initial-selected', '1')
                }
              })

              Onkho.Functions.ExecuteCallback.apply(null, callbackArguments.concat(data.responseJSON.preferences));
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  };


  return {
    Init: Init,
    ShowUserPreferencesModal: ShowUserPreferencesModal,
    ResetUserPreferencesModal: ResetUserPreferencesModal,
    SaveUserPreferencesModal: SaveUserPreferencesModal
  }
}()
