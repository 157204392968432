Onkho.OnkhoWideList = function () {
  var Init = function (list, key, data, term, filters) {
    LoadList(list, key, data, term, filters)
  }

  var LoadList = function (list, key, data, term, filters, callback) {
    var callbackArguments = Array.prototype.slice.call(arguments, 5)

    if (list.hasClass('o-wide-list') && list.data('loaded') !== true) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = key
      formData.data = data

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/wideList/getList',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                var loadedList = $(data.responseJSON.html)
                list.replaceWith(loadedList)
                loadedList.trigger('onkho:wide-list[' + key + '].initialized')
                loadedList.data('loaded', true)
                loadedList.data('additional-data', JSON.stringify(formData.data))

                SetupList(loadedList, term, filters)
                LoadItems(loadedList)

                Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                break

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var StoreData = function (list, key, value) {
    if (typeof Onkho.Variables.WideList === typeof undefined) {
      Onkho.Variables.WideList = {}
    }

    if (typeof Onkho.Variables.WideList[list.data('key')] === typeof undefined) {
      Onkho.Variables.WideList[list.data('key')] = {}
    }

    Onkho.Variables.WideList[list.data('key')][key] = value
  }

  var GetData = function (list, key) {
    if (typeof Onkho.Variables.WideList === typeof undefined) {
      Onkho.Variables.WideList = {}
    }

    if (typeof Onkho.Variables.WideList[list.data('key')] === typeof undefined) {
      Onkho.Variables.WideList[list.data('key')] = {}
    }

    return Onkho.Variables.WideList[list.data('key')][key]
  }

  var SetupList = function (list, term, filters) {
    if (list.data('select-enabled')) {
      list.find('input[name="select_all"]').on('change', function () {
        if ($(this).is(':checked')) {
          SelectAll(list)
        } else {
          DeselectAll(list)
        }
      })

      list.on('change', 'input[type="checkbox"]', function () {
        UpdateItemSelectionStatus($(this).closest('.o-wide-list-item'), $(this).is(':checked'))
        UpdateTotalSelected(list)
      })

      $(window).on('keyup keydown', function (event) {
        document.onselectstart = function () {
          return !(event.key == "Shift" && event.shiftKey);
        }
      });

      list.on('click', 'input[type="checkbox"]', function (event) {
        var item = $(this).closest('.o-wide-list-item')

        if (event.shiftKey) {
          // Shift was pressed, so do a range select
          var start = GetData(list, 'LastSelectedItem')
          if (typeof start !== typeof undefined) {
            var end = item
            SelectRange(list, start, end)
          }
        }

        StoreData(list, 'LastSelectedItem', item)
      })

      UpdateTotalSelected(list)
    }

    if (list.data('search-enabled')) {
      list.on('keyup', '.toolbar .main-row .search .search-control .search-control-form input[name="search"]', function () {
        Onkho.Functions.DelayInterval('OnkhoWideListSearch' + list.data('key').replace(/[^a-zA-Z]+/g, ''), function () {
          Refresh(list)

          if (list.find('.toolbar .main-row .search .search-control .search-control-form input[name="search"]').val().length > 0) {
            list.find('.search-control-clear').fadeIn(150)
            list.find('> .toolbar .orders').fadeOut(150)
          } else {
            list.find('.search-control-clear').fadeOut(150)
            list.find('> .toolbar .orders').fadeIn(150)
          }
        }, 500)
      })

      list.on('click', '.search-control-clear', function () {
        $(this).fadeOut(150)
        list.find('> .toolbar .orders').fadeIn(150)
        var searchInput = list.find('.search-control input[name="search"]')
        if (searchInput.val().length > 0) {
          list.find('.search-control input[name="search"]').val('')

          Refresh(list)
        }
      })

      // Set default search term
      SetDefaultTerm(list, term);
    }

    if (list.data('order-enabled')) {
      list.find('.order-control .dropdown li a').on('click', function () {
        SetOrder(list, $(this))
      })
    }

    if (list.data('filters-enabled')) {
      list.find('.filter-control:not(.meta-filter) .dropdown li[data-type="dropdown"] select').each(function (index, element) {
        $(element).select2({
          placeholder: $(element).closest('li').data('label')
        })
      })

      list.find('.filter-control:not(.meta-filter) .dropdown li[data-type="dropdown"]').on('click', function (event) {
        event.stopPropagation()
      })

      list.find('.filter-control:not(.meta-filter) .dropdown li[data-type="option"] a').on('click', function () {
        SetFilter(list, $(this).closest('.filter-control'), $(this))
      })

      list.find('.filter-control:not(.meta-filter) .dropdown li[data-type="dropdown"] select').on('change', function () {
        SetFilter(list, $(this).closest('.filter-control'), $(this))
      })

      list.find('.filter-control[data-type="multiselect"]:not(.meta-filter) .modal .save').on('click', function () {
        SetFilter(list, $(this).closest('.filter-control'), $(this).closest('.o-enhanced-multiselect'))
      })

      list.find('> .toolbar .main-row .filters-toggle .show-filters').on('click', function () {
        ShowFilters(list)
      })

      list.find('> .toolbar .main-row .filters-toggle .hide-filters').on('click', function () {
        HideFilters(list)
      })

      // Set default filters
      SetDefaultFilters(list, filters);
    }

    if (list.data('refresh-enabled')) {
      list.find('.refresh').on('click', function () {
        if (!list.find('.load-more').hasClass('disabled')) {
          Refresh(list)
        }
      })
    }

    if (list.data('user-preferences-enabled')) {
      list.find('> .toolbar .user-preferences-trigger').on('click', function () {
        ShowUserPreferencesModal(list)
      })

      list.find('> .modal.user-preferences-modal .save').on('click', function () {
        SaveUserPreferencesModal(list)
      })
    }

    list.on('mouseenter', '.o-wide-list-item:not(.selected)', function () {
      ShowItemActions($(this));
    })

    list.on('mouseleave', '.o-wide-list-item:not(.selected)', function () {
      HideItemActions($(this));
    })

    // Infinity scroll
    list.on('click', '.load-more', function () {
      if (!$(this).hasClass('disabled')) {
        LoadItems(list)
      }
    })

    list.find('> .content-wrapper').on('scroll', function () {
      if (NearingBottom(list)) {
        LoadItems(list)
      }
    })

    list.on('onkho:wide-list[' + list.data('key') + '].loaded', function () {
      if (NearingBottom(list) && !HasNoMoreItems(list)) {
        LoadItems(list)
      }
    })

    // Tooltips
    list.find('[rel=tooltip]').tooltip({
      html: true,
      container: list
    });
  }

  var SetDefaultTerm = function (list, term) {
    list.find('.search-control input[name="search"]').val(term);
  }

  var SetDefaultFilters = function (list, filters) {
    if (filters) {
      list.find('> .toolbar .filters-row .filter-control').each(function (index, filterControl) {
        if (filters[$(filterControl).data('id')]) {
          if ($(filterControl).data('type') == 'compact') {
            // Try to use an option
            var option = $(filterControl).find('[data-type="option"] [data-id="' + filters[$(filterControl).data('id')] + '"]');
            if (option.length) {
              SetFilter(list, $(filterControl), option, true);
            } else {
              // Try to use an inner choice of a dropdown-type of option
              var choice = $(filterControl).find('[data-type="dropdown"] option[value="' + filters[$(filterControl).data('id')] + '"]');
              if (choice.length) {
                choice.closest('select').val(filters[$(filterControl).data('id')]).change();
              }
            }
          } else if ($(filterControl).data('type') == 'multiselect') {
            Onkho.EnhancedMultiselect.SetSelection($(filterControl).find('.o-enhanced-multiselect'), filters[$(filterControl).data('id')]);
          }
        }
      })
    }
  }

  var ShowItemActions = function (item) {
    var actionsWrapper = item.find('> .row-wrapper > .content > .actions-wrapper');
    if (actionsWrapper.length) {
      item.find('> .row-wrapper > .content .details-wrapper').hide()
      item.find('> .row-wrapper > .content .statuses-wrapper').hide()
      actionsWrapper.show()
    }
  }

  var HideItemActions = function (item) {
    var actionsWrapper = item.find('> .row-wrapper > .content > .actions-wrapper');
    if (actionsWrapper.length) {
      actionsWrapper.hide()
      item.find('> .row-wrapper > .content .details-wrapper').show()
      item.find('> .row-wrapper > .content .statuses-wrapper').show()
    }
  }

  var LoadItems = function (list, callback) {
    if (list.length > 0 && !IsLoading(list)) {
      var callbackArguments = Array.prototype.slice.call(arguments, 1)

      ShowLoading(list)

      RegenerateNonce(list)

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = GetKey(list)
      formData.data = list.data('additional-data') ? JSON.parse(list.data('additional-data')) : []
      formData.nonce = GetCurrentNonce(list)
      formData.offset = GetOffset(list)

      if (list.data('search-enabled')) {
        formData.searchTerm = GetSearchTerm(list)
      }

      if (list.data('order-enabled')) {
        formData.order = GetOrder(list)
      }

      if (list.data('filters-enabled')) {
        formData.filters = GetFilters(list)
      }

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/wideList/getItems',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                if (CheckNonce(list, data.responseJSON.nonce)) {
                  SetTotalItems(list, data.responseJSON.total)
                  HideLoading(list)

                  if (data.responseJSON.html.length > 0) {
                    HideContentPlaceholder(list)
                    HideNoMoreItems(list)

                    AppendItems(list, data.responseJSON.html)

                    SetOffset(list, data.responseJSON.offset)
                  } else {
                    if (GetTotalItems(list) == 0 && ((list.data('search-enabled') && formData.searchTerm.length == 0) || !list.data('search-enabled')) && !list.data('dirty')) {
                      ShowContentPlaceholder(list)
                    } else {
                      HideContentPlaceholder(list)
                    }

                    ShowNoMoreItems(list)
                  }

                  list.trigger('onkho:wide-list[' + list.data('key') + '].loaded', [data.responseJSON])

                  // Initialize tooltips, if any
                  list.find('[rel=tooltip]').tooltip({
                    html: true,
                    container: list
                  });

                  Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                }
                break
              default:
                ShowFailed(list)
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var AppendItems = function (list, itemsHtml) {
    list.find('> .content-wrapper > .content').append(itemsHtml)
  }

  var ShowContentPlaceholder = function (list) {
    if (list.find('.o-wide-list-item').length == 0) {
      list.find('> .content-wrapper').fadeOut(150)
      list.find('> .content-placeholder').fadeIn(150)
    }
  }

  var HideContentPlaceholder = function (list) {
    list.find('> .content-placeholder').fadeOut(150)
    list.find('> .content-wrapper').fadeIn(150)
  }

  var ShowUserPreferencesModal = function (list) {
    Onkho.UserPreferencesTools.ShowUserPreferencesModal(list.find('> .modal.user-preferences-modal'))
  }

  var SaveUserPreferencesModal = function (list) {
    Onkho.UserPreferencesTools.SaveUserPreferencesModal(list.find('> .modal.user-preferences-modal'), function (list, preferences) {
      list.trigger('onkho:wide-list[' + list.data('key') + '].user_preferences_saved', preferences)
    }, list)
  }

  var SetTotalItems = function (list, total) {
    list.data('total', total)
  }

  var GetTotalItems = function (list) {
    return typeof list.data('total') === 'undefined' ? 0 : parseInt(list.data('total'))
  }

  var NearingBottom = function (list) {
    var contentWrapper = list.find('> .content-wrapper');

    var scrolledTo = contentWrapper.scrollTop() + contentWrapper.height();
    var contentWrapperScrollHeight = contentWrapper[0].scrollHeight;

    return contentWrapperScrollHeight - scrolledTo < 200;
  }

  var GetKey = function (list) {
    return list.data('key')
  }

  var GetSearchTerm = function (list) {
    return list.find('.toolbar input[name="search"]').val()
  }

  var SetSearchTerm = function (list, searchTerm) {
    list.find('.toolbar input[name="search"]').val(searchTerm).trigger('keyup')
  }

  var GetOrder = function (list) {
    var current = list.find('.order-control .dropdown .current')
    return current.data('id')
  }

  var SetOrder = function (list, order, dontRefresh) {
    var current = list.find('.order-control .dropdown .current')
    current.html(order.html())
    current.data('id', order.data('id'))

    if (dontRefresh !== true) {
      Refresh(list)
    }
  }

  var GetFilter = function (list, filterId) {
    var current = list.find('.filter-control[data-id="' + filterId + '"] .dropdown .current')
    return current.data('id')
  }

  var GetFilters = function (list) {
    var filters = {}
    list.find('> .toolbar .filters-row .filter-control').each(function (index, filterControl) {
      if ($(filterControl).data('type') == 'compact') {
        filters[$(filterControl).data('id')] = $(filterControl).find('.dropdown .current').data('id')
      } else if ($(filterControl).data('type') == 'multiselect') {
        filters[$(filterControl).data('id')] = Onkho.EnhancedMultiselect.GetSelection($(filterControl).find('.o-enhanced-multiselect'))
      }
    })

    return filters
  }

  var SetFilter = function (list, filter, filterOption, dontRefresh) {
    list.data('dirty', true)

    if (filter.data('type') == 'multiselect') {
      // The enhanced multiselect filter sets its own value
      if (dontRefresh !== true) {
        // Make sure the refresh happens after the filter set its own value
        setTimeout(function () {
          Refresh(list)
        }, 100);
      }
    } else {
      var filterId = filter.data('id');
      var current = list.find('.filter-control[data-id="' + filterId + '"] .dropdown .current')
      var li = filterOption.closest('li')
      if (li.data('type') === 'option') {
        current.html(filterOption.html())
        current.data('id', filterOption.data('id'))

        // Clear all other dropdown options
        list.find('.filter-control[data-id="' + filterId + '"] .dropdown li[data-type="dropdown"] select').select2('val', '')
      } else if (li.data('type') === 'dropdown') {
        current.html(filterOption.select2('data').text)
        current.data('id', filterOption.select2('data').id)

        // Close dropdown
        list.find('.filter-control[data-id="' + filterId + '"] .dropdown.open [data-toggle="dropdown"]').click()

        // Clear all other dropdown options
        list.find('.filter-control[data-id="' + filterId + '"] .dropdown li[data-type="dropdown"]:not([data-id="' + li.data('id') + '"]) select').select2('val', '')
      }

      if (dontRefresh !== true) {
        Refresh(list)
      }
    }
  }

  var RegenerateNonce = function (list) {
    list.data('nonce', Math.floor(Math.random() * 10000 + 1))
  }

  var CheckNonce = function (list, nonce) {
    return parseInt(list.data('nonce')) === parseInt(nonce)
  }

  var GetCurrentNonce = function (list) {
    return list.data('nonce')
  }

  var GetOffset = function (list) {
    return list.data('offset')
  }

  var SetOffset = function (list, offset) {
    return list.data('offset', offset)
  }

  var ShowLoading = function (list) {
    var button = list.find('.load-more')
    button.html('<i class="fa fa-spin fa-spinner"></i>')
    button.addClass('disabled')
  }

  var IsLoading = function (list) {
    var button = list.find('.load-more')
    return button.hasClass('disabled')
  }

  var HideLoading = function (list) {
    var button = list.find('.load-more')
    button.html('Load more')
    button.removeClass('disabled')
  }

  var ShowNoMoreItems = function (list) {
    var itemsLabel = list.data('items-label')
    HideNoMoreItems(list)
    list.find('> .content-wrapper > .content').append('<div class="no-more">No more ' + itemsLabel + '.</div>')
  }

  var HideNoMoreItems = function (list) {
    list.find('> .content-wrapper > .content > .no-more').remove()
  }

  var HasNoMoreItems = function (list) {
    return list.find('> .content-wrapper > .content > .no-more').length > 0
  }

  var ShowFailed = function (list) {
    var itemsLabel = list.data('items-label')
    list.find('> .content-wrapper > .content > .no-more').remove()
    list.find('> .content-wrapper > .content').append('<div class="no-more">Failed to load ' + itemsLabel + '.</div>')
  }

  var GetSelected = function (list) {
    var items = []
    list.find('.o-wide-list-item input[type="checkbox"]:checked').each(function () {
      items.push($(this).closest('.o-wide-list-item'))
    })

    return items
  }

  var UpdateItemSelectionStatus = function (item, selectionStatus) {
    if (selectionStatus) {
      item.addClass('selected')
      HideItemActions(item)
    } else {
      item.removeClass('selected')
    }
  }

  var UpdateTotalSelected = function (list) {
    if (list.data('select-enabled')) {
      var totalDeselected = list.find('.o-wide-list-item input[type="checkbox"]:not(:checked)').length

      var itemLabel = list.data('item-label')
      var itemsLabel = list.data('items-label')
      var itemsActions = list.find('> .items-actions')
      var itemsSelectedCount = itemsActions.find('.items-selected-count')
      var totalAvailable = list.find('.o-wide-list-item input[type="checkbox"]').length
      var totalSelected = list.find('.o-wide-list-item input[type="checkbox"]:checked').length

      itemsSelectedCount.html(totalSelected + ' ' + (totalSelected !== 1 ? itemsLabel : itemLabel) + ' selected')

      var toolbar = list.find('> .toolbar');
      if (totalSelected > 0) {
        if (toolbar.is(':visible')) {
          toolbar.finish().slideUp(100)
          itemsActions.finish().hide().slideDown(100)
        }
      } else {
        if (!toolbar.is(':visible')) {
          itemsActions.finish().slideUp(100)
          toolbar.finish().hide().slideDown(100)
        }
      }
    }
  }

  var SelectRange = function (list, start, end) {
    var value = start.find('input[type="checkbox"]').prop('checked')

    if (start.isAfter(end))
    {
      var temp = start
      start = end
      end = temp
    }

    var current = start
    do {
      current.find('input[type="checkbox"]').prop('checked', value)
      UpdateItemSelectionStatus(current, value)
      current = current.next()
    } while (current.hasClass('o-wide-list-item') && current.data('id') !== end.data('id'))
  }

  var SelectAll = function (list) {
    list.find('.o-wide-list-item input[type="checkbox"]').prop('checked', true).trigger('change')
  }

  var DeselectAll = function (list) {
    list.find('.o-wide-list-item input[type="checkbox"]').prop('checked', false).trigger('change')
  }

  var HideControls = function (container) {
    if (container.hasClass('o-wide-list-item')) {
      Onkho.LoadingTools.ShowLoading(container.find('.actions'))
    }

    if (container.hasClass('o-wide-list')) {
      Onkho.LoadingTools.ShowLoading(container.find('.items-actions'))
    }
  }

  var ShowControls = function (container) {
    if (container.hasClass('o-wide-list-item')) {
      Onkho.LoadingTools.HideLoading(container.find('.actions'))
    }

    if (container.hasClass('o-wide-list')) {
      Onkho.LoadingTools.HideLoading(container.find('.items-actions'))
    }
  }

  var ShowFilters = function (list) {
    var filtersRow = list.find('> .toolbar .filters-row')

    if (!filtersRow.is(':visible')) {
      filtersRow.slideDown(200, function () {
        list.find('> .toolbar .main-row .filters-toggle .show-filters').hide()
        list.find('> .toolbar .main-row .filters-toggle .hide-filters').show()
        list.addClass('showing-filters')
      })
    }
  }

  var HideFilters = function (list) {
    var filtersRow = list.find('> .toolbar .filters-row')

    if (filtersRow.is(':visible')) {
      filtersRow.slideUp(200, function () {
        list.find('> .toolbar .main-row .filters-toggle .show-filters').show()
        list.find('> .toolbar .main-row .filters-toggle .hide-filters').hide()
        list.removeClass('showing-filters')
      })
    }
  }

  var Reset = function (list) {
    DeselectAll(list)
    list.find('[rel=tooltip]').tooltip('destroy');
    list.find('> .content-wrapper > .content').empty()
    list.data('offset', 0)
  }

  var Refresh = function (list) {
    Reset(list)
    LoadItems(list)
  }

  return {
    Init: Init,
    Refresh: Refresh,
    GetSelected: GetSelected,
    SetFilter: SetFilter,
    SetOrder: SetOrder,
    SetSearchTerm: SetSearchTerm,
    ShowFilters: ShowFilters,
    HideFilters: HideFilters,
    ShowContentPlaceholder: ShowContentPlaceholder,
    HideContentPlaceHolder: HideContentPlaceholder,
    ShowLoading: ShowLoading,
    HideLoading: HideLoading
  }
}()
