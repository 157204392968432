Onkho.OnkhoFeed = function () {
  var Init = function (feed, key, data) {
    LoadFeed(feed, key, data)
  }

  var LoadFeed = function (feed, key, data, callback) {
    var callbackArguments = Array.prototype.slice.call(arguments, 3)

    if (feed.hasClass('o-feed') && feed.data('loaded') !== true) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = key
      formData.data = data

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/feed/getFeed',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                var loadedFeed = $(data.responseJSON.html)
                loadedFeed.data('dirty', feed.data('dirty'))
                feed.replaceWith(loadedFeed)
                loadedFeed.trigger('onkho:feed[' + key + '].initialized')
                loadedFeed.data('loaded', true)
                loadedFeed.data('additional-data', JSON.stringify(formData.data))

                SetupFeed(loadedFeed)
                loadedFeed.trigger('onkho:feed[' + key + '].setup')
                LoadItems(loadedFeed)

                Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                break

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var SetupFeed = function (feed) {
    if (feed.data('search-enabled')) {
      feed.on('keyup', 'input[name="search"]', function () {
        Onkho.Functions.DelayInterval('OnkhoFeedSearch' + feed.data('key').replace(/[^a-zA-Z]+/g, ''), function () {
          Refresh(feed)

          if (feed.find('input[name="search"]').val().length > 0) {
            feed.find('.search-control-clear').fadeIn(150)
            feed.find('> .toolbar .orders').fadeOut(150)
          } else {
            feed.find('.search-control-clear').fadeOut(150)
            feed.find('> .toolbar .orders').fadeIn(150)
          }
        }, 500)
      })

      feed.on('click', '.search-control-clear', function () {
        $(this).fadeOut(150)
        feed.find('> .toolbar .orders').fadeIn(150)
        var searchInput = feed.find('.search-control input[name="search"]')
        if (searchInput.val().length > 0) {
          feed.find('.search-control input[name="search"]').val('')

          Refresh(feed)
        }
      })
    }

    if (feed.data('order-enabled')) {
      feed.find('.order-control .dropdown li a').on('click', function () {
        SetOrder(feed, $(this))
      })
    }

    if (feed.data('filters-enabled')) {
      feed.find('.filter-control:not(.meta-filter):not([data-type="enhanced_multiselect"]) .dropdown li a').on('click', function () {
        SetFilter(feed, $(this).closest('.filter-control'), $(this))
      })

      feed.find('.filter-control[data-type="enhanced_multiselect"]:not(.meta-filter) .modal .save').on('click', function () {
        SetFilter(feed, $(this).closest('.filter-control'), $(this).closest('.o-enhanced-multiselect'))
      })

      feed.find('> .toolbar .main-row .filters-toggle .show-filters').on('click', function () {
        ShowFilters(feed)
      })

      feed.find('> .toolbar .main-row .filters-toggle .hide-filters').on('click', function () {
        HideFilters(feed)
      })
    }

    if (feed.data('refresh-enabled')) {
      feed.find('.refresh').on('click', function () {
        if (!feed.find('.load-more').hasClass('disabled')) {
          Refresh(feed)
        }
      })
    }

    // Infinity scroll
    feed.on('click', '.load-more', function () {
      if (!$(this).hasClass('disabled')) {
        LoadItems(feed)
      }
    })

    feed.find('> .content-wrapper').on('scroll', function () {
      if (NearingBottom(feed)) {
        LoadItems(feed)
      }
    })

    feed.on('onkho:feed[' + feed.data('key') + '].loaded', function () {
      if (NearingBottom(feed) && !HasNoMoreItems(feed)) {
        LoadItems(feed)
      }
    })

    // Tooltips
    feed.find('[rel=tooltip]').tooltip({
      html: true,
      container: feed
    });
  }

  var LoadItems = function (feed, callback) {
    if (feed.length > 0 && !IsLoading(feed)) {
      var callbackArguments = Array.prototype.slice.call(arguments, 1)

      ShowLoading(feed)

      RegenerateNonce(feed)

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = GetKey(feed)
      formData.data = feed.data('additional-data') ? JSON.parse(feed.data('additional-data')) : []
      formData.nonce = GetCurrentNonce(feed)
      formData.offset = GetOffset(feed)

      if (feed.data('search-enabled')) {
        formData.searchTerm = GetSearchTerm(feed)
      }

      if (feed.data('order-enabled')) {
        formData.order = GetOrder(feed)
      }

      if (feed.data('filters-enabled')) {
        formData.filters = GetFilters(feed)
      }

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/feed/getItems',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                if (CheckNonce(feed, data.responseJSON.nonce)) {
                  SetTotalItems(feed, data.responseJSON.total)
                  HideLoading(feed)

                  if (data.responseJSON.html.length > 0) {
                    HideContentPlaceholder(feed)
                    HideNoMoreItems(feed)

                    AppendItems(feed, data.responseJSON.html)

                    SetOffset(feed, data.responseJSON.offset)
                  } else {
                    if (GetTotalItems(feed) == 0 && ((feed.data('search-enabled') && formData.searchTerm.length == 0) || !feed.data('search-enabled')) && !feed.data('dirty')) {
                      ShowContentPlaceholder(feed)
                    } else {
                      HideContentPlaceholder(feed)
                    }

                    ShowNoMoreItems(feed)
                  }

                  feed.trigger('onkho:feed[' + feed.data('key') + '].loaded', [data.responseJSON])

                  // Initialize tooltips, if any
                  feed.find('[rel=tooltip]').tooltip({
                    html: true,
                    container: feed
                  });

                  Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                }
                break
              default:
                ShowFailed(feed)
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var AppendItems = function (feed, itemsHtml) {
    feed.find('> .content-wrapper > .content').append(itemsHtml)
  }

  var ShowContentPlaceholder = function (feed) {
    if (feed.find('.o-feed-item').length == 0) {
      feed.find('> .content-wrapper').fadeOut(150)
      feed.find('> .content-placeholder').fadeIn(150)
    }
  }

  var HideContentPlaceholder = function (feed) {
    feed.find('> .content-placeholder').fadeOut(150)
    feed.find('> .content-wrapper').fadeIn(150)
  }

  var SetTotalItems = function (feed, total) {
    feed.data('total', total)
  }

  var GetTotalItems = function (feed) {
    return typeof feed.data('total') === 'undefined' ? 0 : parseInt(feed.data('total'))
  }

  var NearingBottom = function (feed) {
    var contentWrapper = feed.find('> .content-wrapper');

    var scrolledTo = contentWrapper.scrollTop() + contentWrapper.height();
    var contentWrapperScrollHeight = contentWrapper[0].scrollHeight;

    return contentWrapperScrollHeight - scrolledTo < 200;
  }

  var GetKey = function (feed) {
    return feed.data('key')
  }

  var GetSearchTerm = function (feed) {
    return feed.find('.toolbar input[name="search"]').val()
  }

  var SetSearchTerm = function (feed, searchTerm) {
    feed.find('.toolbar input[name="search"]').val(searchTerm).trigger('keyup')
  }

  var GetOrder = function (feed) {
    var current = feed.find('.order-control .dropdown .current')
    return current.data('id')
  }

  var SetOrder = function (feed, order) {
    var current = feed.find('.order-control .dropdown .current')
    current.html(order.html())
    current.data('id', order.data('id'))

    Refresh(feed)
  }

  var GetFilter = function (feed, filterId) {
    var current = feed.find('.filter-control[data-id="' + filterId + '"] .dropdown .current')
    return current.data('id')
  }

  var GetFilters = function (feed) {
    var filters = {}
    feed.find('> .toolbar .filters-row .filter-control').each(function (index, filterControl) {
      if (['compact', 'full'].indexOf($(filterControl).data('type')) !== -1) {
        filters[$(filterControl).data('id')] = $(filterControl).find('.dropdown .current').data('id')
      } else if ($(filterControl).data('type') === 'enhanced_multiselect') {
        filters[$(filterControl).data('id')] = Onkho.EnhancedMultiselect.GetSelection($(filterControl).find('.o-enhanced-multiselect'))
      }
    })

    return filters
  }

  var SetFilter = function (feed, filter, filterOption, dontRefresh) {
    feed.data('dirty', true)

    if (filter.data('type') !== 'enhanced_multiselect') {
      var current = feed.find('.filter-control[data-id="' + filter.data('id') + '"] .dropdown .current')
      current.html(filterOption.html())
      current.data('id', filterOption.data('id'))

      if (dontRefresh !== true) {
        Refresh(feed)
      }
    } else {
      // The enhanced multiselect filter sets its own value
      if (dontRefresh !== true) {
        // Make sure the refresh happens after the filter set its own value
        setTimeout(function () {
          Refresh(feed)
        }, 100);
      }
    }
  }

  var RegenerateNonce = function (feed) {
    feed.data('nonce', Math.floor(Math.random() * 10000 + 1))
  }

  var CheckNonce = function (feed, nonce) {
    return parseInt(feed.data('nonce')) === parseInt(nonce)
  }

  var GetCurrentNonce = function (feed) {
    return feed.data('nonce')
  }

  var GetOffset = function (feed) {
    return feed.data('offset')
  }

  var SetOffset = function (feed, offset) {
    return feed.data('offset', offset)
  }

  var ShowLoading = function (feed) {
    var button = feed.find('.load-more')
    button.html('<i class="fa fa-spin fa-spinner"></i>')
    button.addClass('disabled')
  }

  var IsLoading = function (feed) {
    var button = feed.find('.load-more')
    return button.hasClass('disabled')
  }

  var HideLoading = function (feed) {
    var button = feed.find('.load-more')
    button.html('Load more')
    button.removeClass('disabled')
  }

  var ShowNoMoreItems = function (feed) {
    var itemsLabel = feed.data('items-label')
    HideNoMoreItems(feed)
    feed.find('> .content-wrapper > .content').append('<div class="no-more">No more ' + itemsLabel + '.</div>')
  }

  var HideNoMoreItems = function (feed) {
    feed.find('> .content-wrapper > .content > .no-more').remove()
  }

  var HasNoMoreItems = function (feed) {
    return feed.find('> .content-wrapper > .content > .no-more').length > 0
  }

  var ShowFailed = function (feed) {
    var itemsLabel = feed.data('items-label')
    feed.find('> .content-wrapper > .content > .no-more').remove()
    feed.find('> .content-wrapper > .content').append('<div class="no-more">Failed to load ' + itemsLabel + '.</div>')
  }

  var ShowFilters = function (feed) {
    var filtersRow = feed.find('> .toolbar .filters-row')

    if (!filtersRow.is(':visible')) {
      filtersRow.slideDown(200, function () {
        feed.find('> .toolbar .main-row .filters-toggle .show-filters').hide()
        feed.find('> .toolbar .main-row .filters-toggle .hide-filters').show()
        feed.addClass('showing-filters')
      })
    }
  }

  var HideFilters = function (feed) {
    var filtersRow = feed.find('> .toolbar .filters-row')

    if (filtersRow.is(':visible')) {
      filtersRow.slideUp(200, function () {
        feed.find('> .toolbar .main-row .filters-toggle .show-filters').show()
        feed.find('> .toolbar .main-row .filters-toggle .hide-filters').hide()
        feed.removeClass('showing-filters')
      })
    }
  }

  var Reset = function (feed) {
    feed.find('[rel=tooltip]').tooltip('destroy');
    feed.find('> .content-wrapper > .content').empty()
    feed.data('offset', 0)
  }

  var Refresh = function (feed) {
    Reset(feed)
    LoadItems(feed)
  }

  return {
    Init: Init,
    Refresh: Refresh,
    SetFilter: SetFilter,
    SetSearchTerm: SetSearchTerm,
    ShowFilters: ShowFilters,
    HideFilters: HideFilters,
    ShowContentPlaceholder: ShowContentPlaceholder,
    HideContentPlaceHolder: HideContentPlaceholder,
    ShowLoading: ShowLoading,
    HideLoading: HideLoading
  }
}()
