Onkho.OnkhoExternalEntityActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();

        RegisterEventListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        // --- Featured actions ---
        // View
        $('body').on('click', '.o-active-element[data-type="external_entity"] .featured-action[data-id="view"]', function (event)
        {
            ViewExternalEntity($(this).closest('.o-active-element'));
        });

        // Group
        $('body').on('click', '.o-active-element[data-type="external_entity"] .featured-action[data-id="group"]', function (event)
        {
            Onkho.OnkhoActiveElement.RemoveAll();
            Onkho.OnkhoPane.RemoveAll();
            Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane($(this).closest('.o-active-element').data('activity-feed-group-id'));
        });
    };

    var ViewExternalEntity = function (activeElementElement)
    {
        activeElementElement.find('.top-section .content .title a')[0].click();
    };

    var RegisterEventListeners = function ()
    {
        //
    };



    return {
        Init: Init
    };
}();
