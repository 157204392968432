Onkho.OnkhoEditJobPanelContextAwareness = function ()
{
    var Init = function ()
    {
        if (Onkho.Variables.PageName === 'accountant_dashboard')
        {
            AccountantDashboard();
        }
        else
        if (Onkho.Variables.PageName === 'party')
        {
            Party();
        }
        else
        if (Onkho.Variables.PageName === 'workflow')
        {
            Workflow();
        }
        else
        if (Onkho.Variables.PageName.includes('_insights'))
        {
            Insights();
        }
    };

    var Party = function ()
    {
        $('body').on('onkho:panel[edit_job].saved', '.o-panel-container', function (event, id)
        {
            Onkho.JobsListTools.Refresh();
        });

        $('body').on('onkho:panel[edit_job].deleted', '.o-panel-container', function (event, id)
        {
            Onkho.JobsListTools.Refresh();
        });
    };

    var Workflow = function ()
    {
        $('body').on('onkho:panel[edit_job].saved', '.o-panel-container', function (event, id)
        {
            Onkho.WorkflowTools.SaveEditedJob();
        });

        $('body').on('onkho:panel[edit_job].deleted', '.o-panel-container', function (event, id)
        {
            Onkho.WorkflowTools.SaveEditedJob();
        });
    };

    var Insights = function ()
    {
        $('body').on('onkho:panel[edit_job].saved', '.o-panel-container', function (event, id)
        {
            Onkho.InsightGridPageTools.ReloadRecords();
        });

        $('body').on('onkho:panel[edit_job].deleted', '.o-panel-container', function (event, id)
        {
            Onkho.InsightGridPageTools.ReloadRecords();
        });
    };

    var AccountantDashboard = function ()
    {
        $('body').on('onkho:panel[edit_job].saved', '.o-panel-container', function (event, id)
        {
            $('.dashboard-widget-grid .jarviswidget .compact-content-wrapper .o-active-element-trigger[data-type="job"][data-id="' + id + '"]').each(function ()
            {
                Onkho.AccountantDashboardPageTools.LoadWidget($(this).closest('.jarviswidget'));
            });
        });

        $('body').on('onkho:panel[edit_job].deleted', '.o-panel-container', function (event, id)
        {
            $('.dashboard-widget-grid .jarviswidget .compact-content-wrapper .o-active-element-trigger[data-type="job"][data-id="' + id + '"]').each(function ()
            {
                Onkho.AccountantDashboardPageTools.LoadWidget($(this).closest('.jarviswidget'));
            });
        });
    };



    return {
        Init: Init
    };
}();
