Onkho.RelationshipTools = function () {
  var Init = function () {
    // Button listeners for adding and editing relationships
    $('.add-relationship').on('click',function () {
      AddRelationships()
    })

    // Show relationship management modal when any relationship is clicked
    $('.edit-relationship').on('click',function () {
      EditRelationships($(this).data('id'))
    })

    $('.modal.manage-relationship .btn.save').on('click', function () {
      SaveRelationshipsChanges()
    })

    $('.modal.manage-relationship form').on('submit', function (event) {
      event.preventDefault()
      return false
    })

    // Scroll to the relationships section whenever the more button is clicked
    $('.more-relationships').on('click', function (event) {
      Onkho.Functions.ScrollTo($('#connections'))
    })

    // Initialize the relationship management modal
    Onkho.Validator.ValidateForm($('.modal.manage-relationship .relationship-registration-form'))
    $('.modal.manage-relationship select[name="to_party_id"]').select2({
      placeholder: 'Who do you want to connect to?',
      sortResults: Onkho.Functions.Select2Sort
    })

    $('.modal.manage-relationship select[name="to_party_id"]').on('change', function () {
      GetExistingRelationships($(this).val())
    })

    var formattedRegisterableRelationships = [];
    $(Onkho.Variables.RegisterableRelationships).each(function (index, value) {
      formattedRegisterableRelationships.push({
        id: value,
        text: value
      })
    })

    $('.modal.manage-relationship input[name="relationship_type"]').select2({
      placeholder: 'How are they connected?',
      createSearchChoice: function (term, data) {
        if ($(data).filter(function (index, value) {
          return value.toString().toLowerCase() !== term.toString().toLowerCase()
        }).length === 0) {
          return {
            id: term,
            text: term
          }
        }
      },
      tokenSeparators: [','],
      tags: formattedRegisterableRelationships
    })
  }

  var GetExistingRelationships = function (toPartyId) {
    if (toPartyId != null) {
      var toPartySelect = $('.modal.manage-relationship select[name="to_party_id"]')
      var relationshipTypeSection = $('.modal.manage-relationship section.relationship-type')
      toPartySelect.attr('disabled', true);
      relationshipTypeSection.slideUp(150)

      var fromPartyId = $('.modal.manage-relationship input[name="from_party_id"]').val()

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.from_party_id = fromPartyId
      formData.to_party_id = toPartyId

      $.ajax({
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/relationship/getRelationships',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          var existingRelationships = []

          $.each(data.responseJSON.data, function (index, value) {
            existingRelationships.push({
              id: value,
              relationship_id: index,
              text: value
            })
          })

          $('.modal.manage-relationship input[name="relationship_type"]').select2('data', existingRelationships, true)

          if (!toPartySelect.hasClass('keep-disabled')) {
            toPartySelect.attr('disabled', false)
          }

          relationshipTypeSection.slideDown(150)
        }
      })
    }
  }

  var AddRelationships = function () {
    var manageRelationshipModal = $('.modal.manage-relationship');

    // Reset modal
    manageRelationshipModal.find('input, select').filter(':not([type="hidden"])').val('').change()

    // Reset validation
    Onkho.Validator.ResetChildrenValidation(manageRelationshipModal)

    // Set title
    var partyName = manageRelationshipModal.find('.modal-title').attr('data-name')
    manageRelationshipModal.find('.modal-title').text('Connect ' + partyName + ' to')

    manageRelationshipModal.find('select[name="to_party_id"]').attr('disabled', false).removeClass('keep-disabled')

    var relationshipTypeSection = manageRelationshipModal.find('section.relationship-type')
    relationshipTypeSection.hide();

    manageRelationshipModal.modal('show')
  }

  var EditRelationships = function (toPartyId) {
    var manageRelationshipModal = $('.modal.manage-relationship')

    // Reset modal
    manageRelationshipModal.find('input, select').filter(':not([type="hidden"])').val('').change()

    // Reset validation
    Onkho.Validator.ResetChildrenValidation(manageRelationshipModal)

    // Set title
    var partyName = manageRelationshipModal.find('.modal-title').attr('data-name')
    manageRelationshipModal.find('.modal-title').text(partyName + ' is connected to')

    manageRelationshipModal.find('select[name="to_party_id"]').select2('val', toPartyId, true)
    manageRelationshipModal.find('select[name="to_party_id"]').attr('disabled', true).addClass('keep-disabled')
    manageRelationshipModal.find('form').append('<input name="to_party_id" type="hidden" value="' + toPartyId + '">')

    manageRelationshipModal.modal('show')
  }

  var SaveRelationshipsChanges = function () {
    var modal = $('.modal.manage-relationship')
    var form = modal.find('form')

    if (Onkho.Validator.ValidateChildren(form)) {
      Onkho.LoadingTools.ShowLoading(modal.find('.btn.save'))

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF()
      formData.from_party_id = form.find('[name="from_party_id"]').val()
      formData.to_party_id = form.find('[name="to_party_id"]').val()
      formData.relationships = form.find('[name="relationship_type"]').select2('data')

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/relationship/saveRelationshipsChanges',
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            switch (data.status)
            {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                location.reload()
                break;
              default:
                Onkho.LoadingTools.HideLoading(modal.find('.btn.save'))
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break;
            }
          }
        });
    }
  }



  return {
    Init: Init
  }
}()
