Onkho.AddEditServiceAssigneeSubpanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_service_assignee"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditServiceAssigneeSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    };

    Onkho.OnkhoPanel.Add('add_edit_service_assignee', additionalData, LoadAddEditServiceAssigneeSubpanelLoaded)
  };

  var LoadAddEditServiceAssigneeSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)

    $('[rel=tooltip]').tooltip('hide')

    panel.find('[name="service_manager_id"]').select2()
  };

  var RegisterAddEditServiceAssigneeSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_service"]'))
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveAssignee(panel);
    });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_service_assignee].added', '.o-panel-container', function ()
    {
      var AddEditServicesAssigneeSubpanel = $('.o-panel[data-panel-key="add_edit_service_assignee"]');
      RegisterAddEditServiceAssigneeSubpanelBindings(AddEditServicesAssigneeSubpanel);
    });
  };

  var SaveAssignee = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      var AddEditServicesPanel = $('.o-panel[data-panel-key="add_edit_service"]')
      var configuration = GatherConfiguration(panel)
      Onkho.AddEditServicePanel.SetAssigneeSectionConfiguration(AddEditServicesPanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = {}

    panel.find('input:not(.select2-focusser):not([type="checkbox"]):visible, input[type="checkbox"]:checked, select:visible').each(function (index, input) {
      configuration[$(input).attr('name')] = $(input).val()
    })

    configuration.service_manager_name = panel.find('[name="service_manager_id"]').select2('data').text

    return configuration
  }



  return {
    Init: Init,
    LoadAddEditServiceAssigneeSubpanel: LoadAddEditServiceAssigneeSubpanel
  };
}();
