// Module containing tools to be used for notifications
Onkho.Notifications = function () {
  var Init = function () {
    Onkho.Variables.LastNotificationId = 0

    // Notifications pane
    $('span[data-name="notifications-button"]').on('click', function () {
      // Only load notifications if the pane is opening
      if ($(this).hasClass('active')) {
        var url = $(this).data('url')
        var container = $('.ajax-notifications')

        loadURL(url, container, function () {
          container.find('[rel=tooltip]').tooltip({
            html: true
          });
        })
        RefreshUnreadCount()
      }
    })

    $('body').on('click', '.ajax-notifications .notification .link', function () {
      window.location.href = $(this).data('href')
    })

    // Refresh unread count
    setInterval(function () {
      RefreshUnreadCount()
    }, 15000)
  }

  var RefreshUnreadCount = function () {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/notification/getUnreadCount',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              SetUnreadCount(data.responseJSON.count, data.responseJSON.lastNotificationId)
              break
            default:
              // Failed
              break
          }
        }
      })
  }

  var SetUnreadCount = function (count, lastNotificationId) {
    $('.unread-notifications').html(count)

    if (count > 0) {
      if (Onkho.Variables.LastNotificationId != lastNotificationId) {
        Onkho.Variables.LastNotificationId = lastNotificationId
        $('.unread-notifications').effect('highlight')
      }
    }
  }

  return {
    Init: Init,
    RefreshUnreadCount: RefreshUnreadCount
  }
}()
