// Module containing tools to be used when displaying a documents list
Onkho.DocumentsListTools = function ()
{
    var documentsList = null;
    var dropzone = null;

    var Init = function ()
    {
        Onkho.Variables.DocumentsPath = '/';
        Onkho.Variables.DocumentsSearchTerm = '';

        documentsList = $('.documents-list');

        // Refresh documents list when the tab is switched
        $('a[data-toggle="tab"][href="#documents"]').on('show.bs.tab', function (event)
        {
            ResetDocumentsList();
            LoadMore(true);
        });

        // Upload functionality
        documentsList.find('.open-upload-documents-modal').on('click', function ()
        {
            $('.modal.upload-documents').modal('show');
        });

        // Initialise the drop-zone, which effectively encompasses the form and related logic for submitting import files to the server.
        dropzone = $('.upload-documents-dropzone');
        dropzone.addClass('dropzone');
        dropzone.dropzone({
            url: Onkho.Variables.BaseURL + '/storage/upload',
            method: 'post',
            paramName: 'documents',
            parallelUploads: 20,
            uploadMultiple: true,
            addRemoveLinks: true,
            autoProcessQueue: false,
            maxFiles: 20,
            maxFilesize: 30, //MB
            init: function() {
                var self = this;

                // Submit the files to the server
                $('.modal.upload-documents .btn.upload').on('click', function() {
                    if (self.getQueuedFiles().length > 0) {
                        self.processQueue();
                        Onkho.LoadingTools.ShowLoading($(this));
                    }
                });

                // Clear the dropzone of all files when the remove files button is clicked
                $('.modal.upload-documents .btn-remove-documents').on('click', function() {
                    self.removeAllFiles();
                    $(this).addClass('hidden');
                });

                // Clear the dropzone of all files when the cancel button is clicked
                $('.modal.upload-documents .btn.cancel').on('click', function() {
                    self.removeAllFiles();
                    $('.modal.upload-documents .btn-remove-documents').addClass('hidden');
                });

                // When a file is added (accepted) to the dropzone, add a button to remove all files
                self.on('addedfile', function(file) {
                    $('.modal.upload-documents .btn-remove-documents').removeClass('hidden');
                });

                // Prior to submitting the files to the server we shall add the other form data required.
                self.on('sendingmultiple', function(file, request, formData) {
                    formData.append('_token', Onkho.Functions.GetCSRF());
                    formData.append('path', Onkho.Variables.DocumentsPath);
                    formData.append('partyId', documentsList.find('input[name="partyId"]').val());
                });

                // If the server has accepted our upload - display a success message
                self.on('successmultiple', function(file, response) {
                    Onkho.LoadingTools.HideLoading($('.modal.upload-documents .btn.upload'));
                    self.removeAllFiles();

                    $('.modal.upload-documents').modal('hide');
                    $('.modal.upload-documents .btn-remove-documents').addClass('hidden');

                    ResetDocumentsList();
                    LoadMore();

                    Onkho.Alert.SmallBox('success', response.message);
                });

                // If the server (or dropzone) has rejected our upload, display an error message - this is triggered once for every file with an error
                self.on('error', function(file, errorMessage, failure) {
                    Onkho.LoadingTools.HideLoading($('.modal.upload-documents .btn.upload'));

                    if (errorMessage)
                    {
                        if (typeof errorMessage === 'string')
                        {
                            if (errorMessage.indexOf('<html>') !== -1)
                            {
                                $(file.previewElement).find('.dz-error-message').html('We were unable to upload this file.');
                            }
                        }
                        else
                        {
                            $(file.previewElement).find('.dz-error-message').html('We were unable to upload this file.');
                        }
                    }

                    // Avoid spamming the user by showing a single alert
                    Delay('DropzoneErrorAlert', function ()
                    {
                        if (errorMessage)
                        {
                            if (typeof errorMessage === 'string')
                            {
                                if (typeof failure === 'object')
                                {
                                    // Webserver error
                                    switch (failure.status)
                                    {
                                        case 413:
                                            Onkho.Alert.BigBox('danger', 'Unexpected error', 'One or more of the files you tried to upload exceeded the maximum file size.');
                                            break;

                                        default:
                                            Onkho.Alert.BigBox('danger', 'Unexpected error', 'We were not able to upload your files due to an unexpected error. Please try again.');
                                    }
                                }
                                else
                                {
                                    // Dropzone / frontend error
                                    Onkho.Alert.BigBox('danger', 'Invalid file', errorMessage);
                                }
                            }
                            else
                            {
                                // Backend error
                                Onkho.Alert.BigBox('danger', 'Upload failed', errorMessage.message);
                            }
                        }
                    }, 250);
                });

                // If we have reached the maximum number of files that can be uploaded, show an alert
                self.on('maxfilesreached', function(file, response) {
                    Delay('DropzoneMaxfilesreachedAlert', function ()
                    {
                        Onkho.Alert.BigBox('warning', 'Maximum number of files reached', 'You have reached the maximum number of files that you can upload in one go.');
                    }, 250);
                });

                // If we have exceeded the maximum number of files that can be uploaded, show an alert
                self.on('maxfilesexceeded', function(file, response) {
                    Delay('DropzoneMaxfilesexceededAlert', function ()
                    {
                        Onkho.Alert.BigBox('error', 'Maximum number of files exceeded', 'You have exceeded the maximum number of files that you can upload in one go.');
                    }, 250);
                });
            }
        });

        // Create directory functionality
        documentsList.find('.open-create-directory-modal').on('click', function ()
        {
            $('.modal.create-directory').modal('show');
        })

        documentsList.find('.modal.create-directory').on('shown.bs.modal', function ()
        {
            $(this).find('input[name="directory_name"]').focus();
        });

        $('.modal.create-directory .create').on('click', function ()
        {
            CreateDirectory();
        });

        $('.modal.create-directory input').keypress(function (event)
        {
            var key = event.which;
            if (key == 13)
            {
                $(this).closest('.modal').find('.create').click();
                event.preventDefault();
            }
        });

        // Select all
        $('input[name="select_all"]').on('change', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectAll();
            }
            else
            {
                DeselectAll();
            }
        });

        // Selected documents counter
        documentsList.on('change', 'input[type="checkbox"]', function ()
        {
            UpdateTotalSelected();
        });

        // Sort
        documentsList.find('.sort-control .dropdown li a').on('click', function ()
        {
            var current = documentsList.find('.sort-control .dropdown .current');
            current.html($(this).html());
            current.data('value', $(this).data('value'));

            ResetDocumentsList();
            LoadMore();
        });

        // Search
        documentsList.on('keyup', 'input[name="documents_search"]', function ()
        {
            Delay('DocumentsPortalSearch', function ()
            {
                if (documentsList.find('.search-control input[name="documents_search"]').val().length > 0)
                {
                    documentsList.find('.search-control .clear-search').show();
                }
                else
                {
                    documentsList.find('.search-control .clear-search').hide();
                }

                ResetDocumentsList();
                LoadMore();
            }, 500);
        });

        documentsList.on('click', '.clear-search', function ()
        {
            documentsList.find('.search-control input[name="documents_search"]').val('');
            $(this).hide();

            ResetDocumentsList();
            LoadMore();
        });

        // Document actions
        documentsList.on('click', '.document .document-action-buttons .delete', function ()
        {
            var button = $(this);
            $.SmartMessageBox(
                {
                    title : "Deleting Document",
                    content : "Are you sure you want to delete this document?",
                    buttons : "[No][Yes]"
                }, function (ButtonPress)
                {
                    if (ButtonPress == 'Yes')
                    {
                        var documents = button.closest('.document');
                        Delete(documents);
                    }
                }
            );
        });

        documentsList.on('click', '.document .document-action-buttons .download', function ()
        {
            var documents = $(this).closest('.document');
            Download(documents);
        });

        documentsList.on('click', '.document .document-action-buttons .rename', function ()
        {
            var document = $(this).closest('.document');

            PrepareRenameModal(document);
        });

        documentsList.on('click', '.modal .rename', function ()
        {
            Rename();
        });

        // Global actions
        documentsList.find('.global-actions').on('click', '.delete', function ()
        {
            var selectedDocuments = GetSelected();
            $.SmartMessageBox(
                {
                    title : "Deleting " + ((selectedDocuments.selectedDocuments === 1) ? "Document" : "Documents"),
                    content : "Are you sure you want to delete " + selectedDocuments.length + " " + ((selectedDocuments.selectedDocuments === 1) ? "document" : "documents") + "?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress)
                {
                    if (ButtonPress == 'Yes')
                    {
                        Delete(selectedDocuments);
                    }
                }
            );
        });

        documentsList.find('.global-actions').on('click', '.download', function ()
        {
            var selectedDocuments = GetSelected();
            Download(selectedDocuments);
        });

        // Infinity scroll
        documentsList.on('click', '.btn.load-more', function ()
        {
            if (!$(this).hasClass('disabled'))
            {
                LoadMore();
            }
        });

        $(window).on('scroll', function()
        {
            if ($(window).scrollTop() > ($(document).height() - $(window).height() - 200))
            {
                if (documentsList.find('.btn.load-more').length && !documentsList.find('.btn.load-more').hasClass('disabled'))
                {
                    LoadMore();
                }
            }
        }).scroll();

        // Refresh
        documentsList.find('.refresh').on('click', function ()
        {
            if (!documentsList.find('.btn.load-more').hasClass('disabled'))
            {
                ResetDocumentsList();
                LoadMore();
            }
        });

        // Path
        documentsList.on('click', '.path-dropdown .dropdown-menu .path-bit', function ()
        {
            SetPath($(this).data('path'));
        });

        documentsList.on('click', '.document.directory .filename', function ()
        {
            SetPath($(this).data('path'));
        });

        SetPath('/');
    };

    var Delay = function(timer, callback, delay)
    {
        clearTimeout(Onkho.Variables[timer]);
        Onkho.Variables[timer] = setTimeout(callback, delay);
    };

    var Delete = function (documents)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        formData.ids = [];
        $(documents).each(function ()
        {
            formData.ids.push($(this).data('id'));
            HideControls($(this));
        });
        HideControls($('.documents-list .global-actions'));

        $.ajax(
            {
                type: 'DELETE',
                url: Onkho.Variables.BaseURL + '/storage/delete',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    $(documents).each(function ()
                    {
                        ShowControls($(this));
                    });

                    ShowControls($('.documents-list .global-actions'));

                    switch (data.status)
                    {
                        case 200:
                            Onkho.Alert.SmallBox('success', data.responseJSON.message);
                            ResetDocumentsList();
                            LoadMore();
                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var Download = function (documents)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        formData.ids = [];
        $(documents).each(function ()
        {
            formData.ids.push($(this).data('id'));
            HideControls($(this));
        });
        HideControls($('.documents-list .global-actions'));

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/storage/makeDownloadLink',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    $(documents).each(function ()
                    {
                        ShowControls($(this));
                    });

                    ShowControls($('.documents-list .global-actions'));

                    switch (data.status)
                    {
                        case 200:
                            var fakeHref      = document.createElement('a');
                            fakeHref.href     = Onkho.Variables.BaseURL + '/storage/download/' + data.responseJSON.filename;
                            fakeHref.target   = '_blank';

                            fakeHref.download = data.responseJSON.filename;
                            document.body.appendChild(fakeHref);
                            fakeHref.click();
                            fakeHref.remove();

                            Onkho.Alert.SmallBox('success', data.responseJSON.message);
                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var PrepareRenameModal = function (document)
    {
        var modal = $('.modal.rename-document');

        modal.find('input[name="storage_entry_id"]').val(document.data('id'));
        modal.find('.modal-title').html('Rename ' + document.data('filename'));
        modal.find('input[name="name"]').val(document.data('filename'));

        modal.modal('show');
    };

    var Rename = function ()
    {
        var modal = $('.modal.rename-document');

        if (Onkho.Validator.ValidateChildren(modal))
        {
            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            formData.storageEntryId = modal.find('input[name="storage_entry_id"]').val();
            formData.newName = modal.find('input[name="name"]').val();

            HideControls($('.document[data-id="' + formData.id + '"]'));
            HideControls($('.documents-list .global-actions'));

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/storage/rename',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                    {
                        ShowControls($('.document[data-id="' + formData.id + '"]'));
                        ShowControls($('.documents-list .global-actions'));

                        switch (data.status)
                        {
                            case 200:
                                modal.modal('hide');
                                Onkho.Validator.ResetChildrenValidation(modal);

                                modal.find('input[name="storage_entry_id"]').val('');
                                modal.find('.modal-title').html('');
                                modal.find('input[name="name"]').val('');

                                Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                ResetDocumentsList();
                                LoadMore();
                                break;

                            default:
                                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                break;
                        }
                    }
                });
        }
    };

    var GetSelected = function ()
    {
        var documents = [];
        documentsList.find('.document input[type="checkbox"]:checked').each(function ()
        {
            documents.push($(this).closest('.document'));
        });

        return documents;
    };

    var SelectAll = function ()
    {
        documentsList.find('.document input[type="checkbox"]').prop('checked', true);
        Onkho.Variables.SelectAllDocuments = true;
    };

    var DeselectAll = function ()
    {
        documentsList.find('.document input[type="checkbox"]').prop('checked', false);
        Onkho.Variables.SelectAllDocuments = false;
    };

    var UpdateTotalSelected = function ()
    {
        var globalActions = documentsList.find('.global-actions');
        var totalAvailable = documentsList.find('.document .checkbox-col input[type="checkbox"]');
        var totalSelected = totalAvailable.filter(':checked');

        documentsList.find('.checkbox-col input[name="select_all"]').prop('checked', (totalAvailable.length === totalSelected.length && totalAvailable.length != 0))
        globalActions.find('label').html(totalSelected.length + ' ' + ((totalSelected.length !== 1 ? 'documents' : 'document')) + ' selected');

        if (totalSelected.length > 0)
        {
            documentsList.find('.document .document-details-wrapper .document-action-buttons').hide();
            globalActions.finish().hide().removeClass('soft-hide').fadeIn(150).css('display', 'inline-block');
        }
        else
        {
            documentsList.find('.document .document-details-wrapper .document-action-buttons').show();
            globalActions.finish().fadeOut();
        }
    };

    var HideControls = function (container)
    {
        Onkho.LoadingTools.ShowLoading(container.find('.document-action-buttons'));
    };

    var ShowControls = function (container)
    {
        Onkho.LoadingTools.HideLoading(container.find('.document-action-buttons'));
    };

    var ResetDocumentsList = function ()
    {
        documentsList.find('input[name="select_all"]').prop('checked', false).trigger('change');
        documentsList.find('table tbody').empty();
        documentsList.removeData('offset');
        documentsList.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
    };

    var LoadMore = function (force)
    {
        force = typeof(force) == 'undefined' ? false : force;

        if (!force && !$('#documents').is(':visible'))
        {
            return;
        }

        var button = documentsList.find('.btn.load-more');
        button.html('<i class="fa fa-spin fa-spinner"></i>');
        button.addClass('disabled');

        // Regenerate nonce
        documentsList.data('nonce', Math.floor(Math.random() * 10000 + 1));

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.partyId = $('[name="partyId"]').attr('value');
        formData.nonce = documentsList.data('nonce');

        var path = GetPath();
        if (Onkho.Variables.DocumentsPath != path)
        {
            // The path has changed
            formData.offset = 0;
            Onkho.Variables.DocumentsPath = path;
        }
        else
        {
            // The path is the same, keep loading files
            formData.offset = documentsList.data('offset');
        }

        formData.path = path;

        var searchTerm = documentsList.find('input[name="documents_search"]').val();
        if (Onkho.Variables.DocumentsSearchTerm != searchTerm)
        {
            // The path has changed
            formData.offset = 0;
            Onkho.Variables.DocumentsSearchTerm = searchTerm;
        }
        else
        {
            // The path is the same, keep loading files
            formData.offset = documentsList.data('offset');
        }

        formData.searchTerm = searchTerm;

        formData.sort = documentsList.find('.sort-control .current').data('value');

        $.ajax(
            {
                type: 'GET',
                url: Onkho.Variables.BaseURL + '/storage/loadMore',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            if (data.responseJSON.nonce == documentsList.data('nonce'))
                            {
                                if (data.responseJSON.html.length > 0)
                                {
                                    documentsList.find('table tbody').append(data.responseJSON.html);
                                    documentsList.data('offset', data.responseJSON.offset);
                                    button.html('Load more');
                                    button.removeClass('disabled');
                                }
                                else
                                {
                                    documentsList.find('table tbody').append('<tr><td colspan="2" class="no-more">No more documents.</td></tr>');
                                    documentsList.find('.btn.load-more').remove();
                                }

                                UpdateStorageSpaceInformation(data.responseJSON.storageSpaceInformation);
                            }
                            break;
                        default:
                            // Failed
                            documentsList.find('table tbody').append('<tr><td colspan="2" class="no-more">Failed to load documents.</td></tr>');
                            documentsList.find('.btn.load-more').remove();
                            if (data.responseJSON.message)
                            {
                                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            }
                            break;
                    }
                }
            });

    };

    var UpdateStorageSpaceInformation = function (storageSpaceInformation)
    {
        documentsList.find('.storage-space-summary .used').html(storageSpaceInformation.usedBytesFormatted);
        documentsList.find('.storage-space-summary .max').html(storageSpaceInformation.maxBytesFormatted);

        documentsList.find('.storage-space-container .progress-bar').css('width', Math.min(100, storageSpaceInformation.usedPercentage) + '%');
        documentsList.find('.storage-space-container .progress-bar').html(Math.min(100, storageSpaceInformation.usedPercentage) + '%');
    };

    var SetPath = function (path)
    {
        var pathWrapper = documentsList.find('.path-dropdown .dropdown-menu');
        pathWrapper.empty();

        var pathBits = path.split('/');
        var currentPath = '/';

        pathWrapper.append('\n' +
                           '<li class="path-bit-wrapper">' +
                               '<a href="javascript:void(0);" class="path-bit" data-path="' + currentPath + '"><i class="fa fa-fw fa-home"></i> Home</a>' +
                           '</li>');

        $(pathBits).each(function (index, pathBit)
        {
            if (pathBit.length != 0)
            {
                if (currentPath.length == 0)
                {
                    currentPath = pathBit;
                }
                else
                {
                    currentPath += '/' + pathBit;
                }

                pathWrapper.append('\n' +
                    '<li class="path-bit-wrapper">' +
                        '<a href="javascript:void(0);" class="path-bit" data-path="' + currentPath + '"><i class="fa fa-fw fa-folder-o"></i> ' + pathBit + '</a>' +
                    '</li>');
            }
        });

        var currentDirectory = pathWrapper.find('.path-bit-wrapper:last-of-type');
        documentsList.find('.current-directory').html(currentDirectory.find('a').html());
        documentsList.find('.current-directory').data('path', currentDirectory.find('a').data('path'));
        currentDirectory.remove();

        if (pathWrapper.find('.path-bit-wrapper').length == 0)
        {
            // Root directory
            documentsList.find('.path-dropdown').addClass('soft-hide');
            documentsList.find('.current-directory').addClass('soft-hide');
        }
        else
        {
            documentsList.find('.path-dropdown').removeClass('soft-hide');
            documentsList.find('.current-directory').removeClass('soft-hide');
        }

        ResetDocumentsList();
        LoadMore();
    };

    var GetPath = function ()
    {
        return documentsList.find('.current-directory').data('path');
    };

    var CreateDirectory = function ()
    {
        var modal = $('.modal.create-directory');

        if (Onkho.Validator.ValidateChildren(modal))
        {
            Onkho.LoadingTools.ShowLoading(modal.find('.btn.create'));

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.partyId = $('[name="partyId"]').attr('value');
            formData.name = modal.find('input[name="directory_name"]').val();
            formData.path = GetPath();

            $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/storage/createDirectory',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          Onkho.LoadingTools.HideLoading(modal.find('.btn.create'));

                          switch (data.status)
                          {
                              case 200:
                                  documentsList.find('.search-control input[name="documents_search"]').val('');
                                  documentsList.find('.search-control .clear-search').hide();

                                  ResetDocumentsList();
                                  LoadMore();

                                  modal.modal('hide');

                                  modal.find('input[name="directory_name"]').val('');
                                  Onkho.Validator.ResetChildrenValidation(modal);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                  break;
                          }
                      }
            });
        }
    };

    return {
        Init: Init,
    };
}();
