Onkho.AddEditMailboxAdditionalPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add edit mailbox additional panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_mailbox_additional"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditMailboxAdditionalPanel = function (signature, disclaimer)
  {
    var additionalData = {
      'partyId': Onkho.Variables.PartyId,
      'signature': signature,
      'disclaimer': disclaimer
    };

    Onkho.OnkhoPanel.Add('add_edit_mailbox_additional', additionalData, AddEditMailboxAdditionalPanelLoaded)
  };

  var AddEditMailboxAdditionalPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    Onkho.Summernote.EnableSummernote(panel.find('.summernote[name="signature"]'))
    Onkho.Summernote.EnableSummernote(panel.find('.summernote[name="disclaimer"]'))
  };

  var RegisterAddEditMailboxAdditionalPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      Onkho.Summernote.DestroySummernote(panel.find('.summernote[name="signature"]'))
      Onkho.Summernote.DestroySummernote(panel.find('.summernote[name="disclaimer"]'))
      Onkho.OnkhoPanel.Remove(panel);

      var mainMailboxPanel = $('.o-panel[data-panel-key="add_mailbox"]');
      if (mainMailboxPanel.length == 0) {
        mainMailboxPanel = $('.o-panel[data-panel-key="edit_mailbox"]');
      }
      Onkho.OnkhoPanel.Enable(mainMailboxPanel)
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveAdditionalDetails();
    });
  };

  var SaveAdditionalDetails = function ()
  {
    var panel = $('.o-panel[data-panel-key="add_edit_mailbox_additional"]')

    if (Onkho.Validator.ValidateChildren(panel))
    {
      Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'))

      var signature = Onkho.Summernote.GetSummernoteValue(panel.find('[name="signature"]'))
      var disclaimer = Onkho.Summernote.GetSummernoteValue(panel.find('[name="disclaimer"]'))


      var mainMailboxPanel = $('.o-panel[data-panel-key="add_mailbox"]');
      if (mainMailboxPanel.length == 0) {
        mainMailboxPanel = $('.o-panel[data-panel-key="edit_mailbox"]');
        Onkho.EditMailboxPanel.SetSignature(signature)
        Onkho.EditMailboxPanel.SetDisclaimer(disclaimer)
      } else {
        Onkho.AddMailboxPanel.SetSignature(signature)
        Onkho.AddMailboxPanel.SetDisclaimer(disclaimer)
      }

      Onkho.OnkhoPanel.Remove(panel)
      Onkho.OnkhoPanel.Enable(mainMailboxPanel)
    }
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_mailbox_additional].added', '.o-panel-container', function ()
    {
      var addEditMailboxAdditionalPanel = $('.o-panel[data-panel-key="add_edit_mailbox_additional"]');
      RegisterAddEditMailboxAdditionalPanelBindings(addEditMailboxAdditionalPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditMailboxAdditionalPanel: LoadAddEditMailboxAdditionalPanel
  };
}();
