Onkho.RegisterMessageTemplatePageTools = function () {

    // Hidden input field that is present when editing an existing template.
    var existingTemplateForm      = null;
    var existingTemplate          = null;

    // Step 1 - Template Name
    var addTemplatePanelStep1     = null;
    var addTemplatePanelFormStep1 = null;
    var step1Fields               = null;
    var step1UserInput            = null;

    // Step 2 - Compose Template
    var addTemplatePanelStep2     = null;
    var addTemplatePanelFormStep2 = null;
    var step2Fields               = null;
    var step2UserInput            = null;

    // Step 3 - Share Template
    var addTemplatePanelStep3     = null;
    var addTemplatePanelFormStep3 = null;
    var step3Fields               = null;
    var step3UserInput            = null;

    var initExistingTemplate = function() {
        existingTemplateForm = $('div#content').find('form[name="existing-template-form"]');

        var existingTemplateFields = {
            template_id:   existingTemplateForm.find('input[type="hidden"][name="template_id"]'),
            template_name: existingTemplateForm.find('input[type="hidden"][name="template_name"]'),
            content:       existingTemplateForm.find('input[type="hidden"][name="content"]'),
            is_public:     existingTemplateForm.find('input[type="hidden"][name="is_public"]')
        };

        // The form will not exist if we are defining a new template.
        if (existingTemplateForm.length === 0) {
            existingTemplate = false;
            return;
        }

        existingTemplate = {
            template_id:   existingTemplateFields.template_id.val(),
            template_name: existingTemplateFields.template_name.val(),
            content:       existingTemplateFields.content.val(),
            is_public:     existingTemplateFields.is_public.val()
        };
    };

    var initStep1 = function() {

        // Reference each fieldset once.
        var fieldsetNewTemplateName = addTemplatePanelFormStep1.find('fieldset.template-name');

        // Fields
        step1Fields = {
            template_name: fieldsetNewTemplateName.find('input[type="text"][name="template_name"]'),
        };

        var defaultInput = {
            template_name: ''
        };

        // Update default input if we're editing an existing template.
        if (existingTemplate) {
            defaultInput.template_name = existingTemplate.template_name;
        }

        step1UserInput = Object.assign({}, defaultInput);

        var clearTemplateName =
            function() {
                step1Fields.template_name.val(step1UserInput.template_name).change();
                Onkho.Validator.ResetValidation([step1Fields.template_name]);
            };

        // This event is responsible for clearing all input.
        fieldsetNewTemplateName.on(
            'clearInput',
            function() {
                clearTemplateName();
            }
        );

        // Validate and update the user input model when the name is changed.
        step1Fields.template_name.on(
            'blur',
            function() {
                if (Onkho.Validator.ValidateChildren(fieldsetNewTemplateName)) {
                    updateStep1UserInput(defaultInput);
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        fieldsetNewTemplateName.trigger('clearInput');
    };

    var initStep2 = function() {

        // Reference each fieldset once.
        var fieldsetTemplateContent = addTemplatePanelFormStep2.find('fieldset.template-content');

        // Fields
        step2Fields = {
            content: fieldsetTemplateContent.find('div.summernote.template-content')
        };

        var defaultInput = {
            content: ''
        };

        // Update default input if we're editing an existing template.
        if (existingTemplate) {
            defaultInput.content = existingTemplate.content;
        }

        step2UserInput = Object.assign({}, defaultInput);

        var clearContent =
            function() {
                Onkho.Summernote.UpdateSummernote(step2Fields.content, step2UserInput.content);
                Onkho.Validator.ResetValidation([step2Fields.content]);
            };

        // This event is responsible for clearing all input.
        fieldsetTemplateContent.on(
            'clearInput',
            function() {
                clearContent();
            }
        );

        // Validate and update the user input model when the name is changed.
        step2Fields.content.on(
            'summernote.blur',
            function() {
                if (Onkho.Validator.ValidateChildren(fieldsetTemplateContent)) {
                    updateStep2UserInput(defaultInput);
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        Onkho.Summernote.EnableSummernote(step2Fields.content, 'JOB');
        fieldsetTemplateContent.trigger('clearInput');
    };

    var initStep3 = function() {

        // Reference each fieldset once.
        var fieldsetTemplateShare = addTemplatePanelFormStep3.find('fieldset.template-share');

        // Fields
        step3Fields = {
            is_public: fieldsetTemplateShare.find('input[type="checkbox"][name="is_public"]')
        };

        var defaultInput = {
            is_public: ''
        };

        // Update default input if we're editing an existing template.
        if (existingTemplate) {
            defaultInput.is_public = existingTemplate.is_public;
        }

        step3UserInput = Object.assign({}, defaultInput);

        var clearContent =
            function() {
                step3Fields.is_public.prop('checked', step3UserInput.is_public).change();
                Onkho.Validator.ResetValidation([step3Fields.is_public]);
            };

        // This event is responsible for clearing all input.
        fieldsetTemplateShare.on(
            'clearInput',
            function() {
                clearContent();
            }
        );

        // Validate and update the user input model when the name is changed.
        step3Fields.is_public.on(
            'change',
            function() {
                if (Onkho.Validator.ValidateChildren(fieldsetTemplateShare)) {
                    updateStep3UserInput(defaultInput);
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        fieldsetTemplateShare.trigger('clearInput');
    };

    var initSaveTemplate = function() {

        // This function gathers all the persisted data and returns the resulting object to be posted to the server.
        var buildRequest = function() {
            var request = {
                _token: Onkho.Functions.GetCSRF(),
                data: {
                }
            };

            Object.assign(request.data, step1UserInput);
            Object.assign(request.data, step2UserInput);
            Object.assign(request.data, step3UserInput);

            return request;
        };

        $('button[type="submit"][name="save"]').on('click',
            function() {
                var self = $(this);
                Onkho.LoadingTools.ShowLoading(self);

                var success =
                    function(data) {
                        Onkho.DynamicContentTools.LoadModal('message_template_created');
                    }
                ;

                var fail =
                    function(jqXHR) {
                        var data = jqXHR.responseJSON;
                        switch(jqXHR.status) {
                            case 400 : {
                                Onkho.Alert.SmallBox('warning', data.message);
                                break;
                            }

                            case 422 : {
                                Onkho.Alert.BigBox('danger', 'Failed to save template', 'Please check your input and try again.');
                                var firstInvalidField = null;
                                var field = null;
                                if (data['data.template_name']) {
                                    field = step1Fields.template_name;
                                    Onkho.Validator.StyleInvalid(field, data['data.template_name'][0]);
                                    firstInvalidField = firstInvalidField || field;
                                }

                                if (data['data.content']) {
                                    field = step2Fields.content;
                                    Onkho.Validator.StyleInvalid(field, data['data.content'][0]);
                                    firstInvalidField = firstInvalidField || field;
                                }

                                Onkho.Validator.ScrollTo(firstInvalidField);
                                break;
                            }

                            default : {
                                Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to save template', data['message'] ? data['message'] : 'We were unable to process your request.');
                                break;
                            }
                        }
                    }
                ;

                if (existingTemplate) {
                    $.ajax({
                        type: 'POST',
                        url: Onkho.Variables.BaseURL + '/template/' + existingTemplate.template_id,
                        data: buildRequest(),
                        dataType: 'json',
                        success: success,
                        error: fail
                    }).always(
                        function() {
                            Onkho.LoadingTools.HideLoading(self);
                        }
                    );
                } else {
                    $.ajax({
                        type: 'PUT',
                        url: Onkho.Variables.BaseURL + '/template/create',
                        data: buildRequest(),
                        dataType: 'json',
                        success: success,
                        error: fail
                    }).always(
                        function() {
                            Onkho.LoadingTools.HideLoading(self);
                        }
                    );
                }
            }
        );
    };

    var updateStep1UserInput = function(defaultInput) {
        var template = step1Fields.template_name.val();

        step1UserInput.template_name = template;
    };

    var updateStep2UserInput = function(defaultInput) {
        var content = Onkho.Summernote.GetSummernoteValue(step2Fields.content);

        step2UserInput.content = content;
    };

    var updateStep3UserInput = function(defaultInput) {
        var isPublic = step3Fields.is_public.is(':checked');

        step3UserInput.is_public = isPublic;
    };

    var Init = function() {

        addTemplatePanelStep1     = $('div.add-template-panel.step-1');
        addTemplatePanelFormStep1 = addTemplatePanelStep1.find('form[name="add-template-form"]');

        addTemplatePanelStep2     = $('div.add-template-panel.step-2');
        addTemplatePanelFormStep2 = addTemplatePanelStep2.find('form[name="add-template-form"]');

        addTemplatePanelStep3     = $('div.add-template-panel.step-3');
        addTemplatePanelFormStep3 = addTemplatePanelStep3.find('form[name="add-template-form"]');

        initExistingTemplate();

        initStep1();
        initStep2();
        initStep3();

        initSaveTemplate();

        // Display the steps that should be initially active once everything is initialised.
        $('[data-ready]').fadeIn(function() {
            $(window).scrollTop = 0;
        });
    };

    return {
        Init: Init,
    };
}();
