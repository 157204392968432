Onkho.Multiselect = function ()
{
    var Init = function ()
    {
        $('body').on('click', '.o-multiselect .selection', function ()
        {
            OpenModal($(this).closest('.o-multiselect'));
        });

        $('body').on('click', '.o-multiselect .select-all', function ()
        {
            SelectAll($(this).closest('.o-multiselect'));
        });

        $('body').on('click', '.o-multiselect .deselect-all', function ()
        {
            DeselectAll($(this).closest('.o-multiselect'));
        });

        $('body').on('click', '.o-multiselect .save', function ()
        {
            SaveSelection($(this).closest('.o-multiselect'));
        })
    };

    var OpenModal = function (multiselect)
    {
        var modal = multiselect.find('.modal');

        var selected = GetSelection(multiselect);

        modal.find('.option').each(function ()
        {
            $(this).find('input[type="checkbox"]').prop('checked', $.inArray($(this).data('value'), selected) != -1);
        })

        modal.modal('show');
    }

    var SaveSelection = function (multiselect)
    {
        var modal = multiselect.find('.modal');
        var selected = [];

        modal.find('input[type="checkbox"]:checked').each(function ()
        {
            selected.push($(this).closest('.option').data('value'));
        })

        SetSelection(multiselect, selected);

        modal.modal('hide');
    };

    var GetSelection = function (multiselect)
    {
        var output = [];
        multiselect.find('select option:selected').each(function ()
        {
            output.push($(this).val());
        });

        return output;
    };

    var SetSelection = function (multiselect, selected)
    {
        var select = multiselect.find('select');

        select.find('option').each(function ()
        {
            $(this).prop('selected', $.inArray($(this).val(), selected) != -1);
        });

        select.trigger('change');

        ComputeSelectionLabel(multiselect);
    };

    var SelectAll = function (multiselect)
    {
        var selected = [];
        var select = multiselect.find('select');

        select.find('option').each(function ()
        {
            selected.push($(this).val());
        });

        SetSelection(multiselect, selected);
    };

    var DeselectAll = function (multiselect)
    {
        SetSelection(multiselect, []);
    };

    var ComputeSelectionLabel = function (multiselect)
    {
        var selected = GetSelection(multiselect);
        var select = multiselect.find('select');

        var label = '';

        if (selected.length == 0)
        {
            label = 'None selected';
        }
        else
        {
            select.find('option').each(function ()
            {
                if ($.inArray($(this).val(), selected) != -1)
                {
                    label += $(this).text() + ', ';
                }
            });
        }

        multiselect.find('.selection .label').text(label.replace(/[, ]*$/, ''));
    };



    return {
        Init: Init,
        GetSelection: GetSelection,
        SetSelection: SetSelection,
        SelectAll: SelectAll,
        DeselectAll: DeselectAll
    };
}();

