Onkho.AddEditServiceScheduleSubpanel = function ()
{
  var addServicePanelStep3 = null
  var step3Fields = null
  var defaultInput = null
  var step3UserInput = null
  var currentScheduleDescriptionWrapper = null

  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var getDescription = function(step3UserInput, genericPhrasing) {
    step3UserInput.service_repeats = toBoolean(step3UserInput.service_repeats)
    step3UserInput.DAY.excluding_weekends = toBoolean(step3UserInput.DAY.excluding_weekends)
    step3UserInput.WEEK.MON = toBoolean(step3UserInput.WEEK.MON)
    step3UserInput.WEEK.TUE = toBoolean(step3UserInput.WEEK.TUE)
    step3UserInput.WEEK.WED = toBoolean(step3UserInput.WEEK.WED)
    step3UserInput.WEEK.THU = toBoolean(step3UserInput.WEEK.THU)
    step3UserInput.WEEK.FRI = toBoolean(step3UserInput.WEEK.FRI)
    step3UserInput.WEEK.SAT = toBoolean(step3UserInput.WEEK.SAT)
    step3UserInput.WEEK.SUN = toBoolean(step3UserInput.WEEK.SUN)

    genericPhrasing = typeof genericPhrasing == 'undefined' ? false : genericPhrasing;

    var calendarReferenceToString = function(calendarReference) {
      switch (calendarReference) {
        case 'ON'      :
          return 'on';

        case 'BEFORE'  :
          return 'before';

        case 'AFTER'   :
          return 'after';

        case 'DAY'     :
          return 'day';

        case 'WEEKDAY' :
          return 'weekday';

        case 'WEEK'    :
          return 'week';

        case 'MONTH'   :
          return 'month';

        case 'YEAR'    :
          return 'year';

        case 'MON'     :
          return 'Monday';

        case 'TUE'     :
          return 'Tuesday';

        case 'WED'     :
          return 'Wednesday';

        case 'THU'     :
          return 'Thursday';

        case 'FRI'     :
          return 'Friday';

        case 'SAT'     :
          return 'Saturday';

        case 'SUN'     :
          return 'Sunday';

        case 'JAN'     :
          return 'January';

        case 'FEB'     :
          return 'February';

        case 'MAR'     :
          return 'March';

        case 'APR'     :
          return 'April';

        case 'MAY'     :
          return 'May';

        case 'JUN'     :
          return 'June';

        case 'JUL'     :
          return 'July';

        case 'AUG'     :
          return 'August';

        case 'SEP'     :
          return 'September';

        case 'OCT'     :
          return 'October';

        case 'NOV'     :
          return 'November';

        case 'DEC'     :
          return 'December';

        case '1'       :
        case '21'      :
        case '31'      :
        case 'FIRST'   :
          return '1st';

        case '2'       :
        case '22'      :
        case 'SECOND'  :
          return '2nd';

        case '3'       :
        case '23'      :
        case 'THIRD'   :
          return '3rd';

        case '4'       :
        case '24'      :
        case 'FOURTH'  :
          return '4th';

        default :
          return (isNaN(calendarReference)) ? calendarReference.toLowerCase() : calendarReference + 'th';
      }
    };

    var buildStartingReferenceDescription = function(step3UserInput, genericPhrasing) {
      var startingDescription = '';
      if (step3UserInput.service_repeats) {
        startingDescription += (genericPhrasing ? 'The' : 'Your') + ' service will start';
      } else {
        startingDescription += (genericPhrasing ? 'The' : 'Your') + ' job will be due';
      }


      switch(step3UserInput.starting_reference) {
        case 'ON' :
          if (step3UserInput.reference_date_attribute === '0') {
            startingDescription += ' <strong>on a date you choose when you add this service to ' + (genericPhrasing ? 'the' : 'your') + ' client</strong>'
          } else {
            startingDescription += ' <strong>on</strong> ' + (genericPhrasing ? 'the' : 'your') + ' client\'s <strong>' + step3UserInput.reference_date_attribute_label.toLowerCase() + '</strong>';
          }
          break;

        default :
          startingDescription += ' <strong>' + step3UserInput.starting_offset + ' ' + calendarReferenceToString(step3UserInput.starting_time_unit) + ((step3UserInput.starting_offset === '1') ? '' : 's') + ' ' + calendarReferenceToString(step3UserInput.starting_reference) + '</strong>';
          if (step3UserInput.reference_date_attribute === '0') {
            startingDescription += ' <strong>the date you choose when you add this service to ' + (genericPhrasing ? 'the' : 'your') + ' client</strong>';
          } else {
            startingDescription += ' ' + (genericPhrasing ? 'the' : 'your') + ' client\'s <strong>' + step3UserInput.reference_date_attribute_label.toLowerCase() + '</strong>';
          }
      }

      return startingDescription;
    };

    var buildRepeatDescription = function() {
      var repeatDescription = '';

      if (step3UserInput.frequency === '1') {
        switch (step3UserInput.time_unit) {
          case 'DAY'   :
            repeatDescription += ' <strong>daily</strong>';
            break;

          case 'WEEK'  :
            repeatDescription += ' <strong>weekly</strong>';
            break;

          case 'MONTH' :
            repeatDescription += ' <strong>monthly</strong>';
            break;

          case 'YEAR'  :
            repeatDescription += ' <strong>yearly</strong>';
            break;
        }
      } else  {
        switch (step3UserInput.time_unit) {
          case 'DAY'   :
            repeatDescription += ' every <strong>' + step3UserInput.frequency + ' days</strong>';
            break;

          case 'WEEK'  :
            repeatDescription += ' every <strong>' + step3UserInput.frequency + ' weeks</strong>';
            break;

          case 'MONTH' :
            repeatDescription += ' every <strong>' + step3UserInput.frequency + ' months</strong>';
            break;

          case 'YEAR'  :
            repeatDescription += ' every <strong>' + step3UserInput.frequency + ' years</strong>';
            break;
        }
      }

      switch (step3UserInput.time_unit) {
        case 'DAY'   :
          if (step3UserInput.DAY.excluding_weekends) {
            repeatDescription += ', excluding weekends';
          }
          break;

        case 'WEEK'  :
          var daysOfWeek = '';
          if (step3UserInput.WEEK.MON) {
            daysOfWeek += 'Monday';
          }

          if (step3UserInput.WEEK.TUE) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Tuesday';
          }

          if (step3UserInput.WEEK.WED) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Wednesday';
          }

          if (step3UserInput.WEEK.THU) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Thursday';
          }

          if (step3UserInput.WEEK.FRI) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Friday';
          }

          if (step3UserInput.WEEK.SAT) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Saturday';
          }

          if (step3UserInput.WEEK.SUN) {
            if (daysOfWeek) {
              daysOfWeek += ', ';
            }
            daysOfWeek += 'Sunday';
          }

          var lastSeparator = daysOfWeek.lastIndexOf(', ');
          if (lastSeparator > 0) {
            daysOfWeek = daysOfWeek.substring(0, lastSeparator) + '</strong> and <strong> ' + daysOfWeek.substring(lastSeparator + 2);
          }

          if (daysOfWeek.length > 0) {
            repeatDescription += ' on <strong>' + daysOfWeek + '</strong>';
          }

          break;

        case 'MONTH' :
          switch (step3UserInput.MONTH.ordinal) {
            case 'SAME' :
              break;

            case 'LAST' :
              repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + ' ' + calendarReferenceToString(step3UserInput.MONTH.time_unit) + '</strong> of the month';
              break;

            default :
              if (step3UserInput.MONTH.time_unit === 'DAY') {
                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + '</strong>';
              } else {
                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + ' ' + calendarReferenceToString(step3UserInput.MONTH.time_unit) + '</strong> of the month';
              }

              break;
          }

          break;

        case 'YEAR' :
          switch (step3UserInput.YEAR.ordinal) {
            case 'SAME' :
              break;

            case 'LAST' :
              repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + ' ' + calendarReferenceToString(step3UserInput.YEAR.time_unit) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
              break;

            default :
              if (step3UserInput.YEAR.time_unit === 'DAY') {
                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
              } else {
                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + ' ' + calendarReferenceToString(step3UserInput.YEAR.time_unit) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
              }
              break;
          }

          break;
      }

      repeatDescription += '.';
      return repeatDescription;
    };

    if (step3UserInput.service_repeats) {
      return buildStartingReferenceDescription(step3UserInput, genericPhrasing) + ', and ' + (genericPhrasing ? 'the' : 'your') +' jobs will be due ' + buildRepeatDescription();
    } else {
      return buildStartingReferenceDescription(step3UserInput, genericPhrasing) + '.';
    }
  };

  var LegacyInit = function (panel)
  {
    addServicePanelStep3 = panel

    // Reference modal once.
    var modal = panel;
    var inProgressScheduleDescriptionWrapper = addServicePanelStep3.find('fieldset.in-progress-schedule section.service-schedule span.service-schedule-description')

    // Reference each fieldset once.
    var fieldsetReferenceDate = modal.find('fieldset.reference-date')
    var fieldsetFrequency = modal.find('fieldset.frequency')
    var fieldsetFrequencyDaily = modal.find('fieldset.frequency-daily')
    var fieldsetFrequencyWeekly = modal.find('fieldset.frequency-weekly')
    var fieldsetFrequencyMonthly = modal.find('fieldset.frequency-monthly')
    var fieldsetFrequencyYearly = modal.find('fieldset.frequency-yearly')

    // Fields
    step3Fields = {
      starting_reference: fieldsetReferenceDate.find('select[name="starting_reference"]'),
      starting_offset: fieldsetReferenceDate.find('input[type="number"][name="starting_offset"]'),
      starting_time_unit: fieldsetReferenceDate.find('select[name="starting_time_unit"]'),
      reference_date_attribute: fieldsetReferenceDate.find('select[name="reference_date_attribute"]'),
      service_repeats: fieldsetFrequency.find('input[type="checkbox"][name="service_repeats"]'),
      frequency: fieldsetFrequency.find('input[type="number"][name="frequency"]'),
      time_unit: fieldsetFrequency.find('select[name="time_unit"]'),
      DAY: {
        excluding_weekends: fieldsetFrequencyDaily.find('input[type="checkbox"][name="excluding_weekends"]')
      },
      WEEK: {
        MON: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="MON"]'),
        TUE: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="TUE"]'),
        WED: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="WED"]'),
        THU: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="THU"]'),
        FRI: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="FRI"]'),
        SAT: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="SAT"]'),
        SUN: fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="SUN"]'),
      },
      MONTH: {
        ordinal: fieldsetFrequencyMonthly.find('select[name="ordinal"]'),
        time_unit: fieldsetFrequencyMonthly.find('select[name="time_unit"]')
      },
      YEAR: {
        ordinal: fieldsetFrequencyYearly.find('select[name="ordinal"]'),
        time_unit: fieldsetFrequencyYearly.find('select[name="time_unit"]'),
        month: fieldsetFrequencyYearly.find('select[name="month"]')
      }
    }

    step3UserInput = Object.assign({}, defaultInput)

    var resetDateAttributes = function(select, selectedValue) {
      // The user will always be able to select at least one option, regardless of whether the call to obtain all
      // attributes fails.

      var options = '';
      options += '<optgroup label="Manually">';
      options += '<option value="0">Date I choose when I add this service to my client</option>';
      options += '</optgroup>';

      $.get(
        Onkho.Variables.BaseURL + '/service/getDateAttributes',
        function(data) {
          select.empty().append(
            function() {
              options += '<optgroup label="Using my client\'s ...">';
              $.each(data,
                function(key, data) {
                  options += '<option value="' + data.id + '">' + data.label + '</option>';
                }
              );
              options += '</optgroup>';
              return options;
            }).val(selectedValue).change();
        }, 'json'
      ).fail(
        function() {
          select.empty().append(options).val('0').change();
        }
      );
    };

    var clearFieldsetReferenceDate =
      function () {
        step3Fields.starting_reference.val(step3UserInput.starting_reference).change()
        step3Fields.starting_time_unit.val(step3UserInput.starting_time_unit).change()
        step3Fields.starting_offset.val(step3UserInput.starting_offset).change()
        resetDateAttributes(step3Fields.reference_date_attribute, step3UserInput.reference_date_attribute)
        Onkho.Validator.ResetValidation([step3Fields.starting_reference, step3Fields.starting_time_unit, step3Fields.starting_offset, step3Fields.reference_date_attribute])
      }

    var clearFieldsetFrequency =
      function () {
        step3Fields.service_repeats.prop('checked', step3UserInput.service_repeats).change()
        step3Fields.frequency.val(step3UserInput.frequency).change()
        step3Fields.time_unit.val(step3UserInput.time_unit).change()
        Onkho.Validator.ResetValidation([step3Fields.service_repeats, step3Fields.frequency, step3Fields.time_unit])
      }

    var clearFieldsetFrequencyDaily =
      function () {
        step3Fields.DAY.excluding_weekends.prop('checked', step3UserInput.DAY.excluding_weekends).change()
        Onkho.Validator.ResetValidation([step3Fields.DAY.excluding_weekends])
      }

    var clearFieldsetFrequencyWeekly =
      function () {
        step3Fields.WEEK.MON.prop('checked', step3UserInput.WEEK.MON).change()
        step3Fields.WEEK.TUE.prop('checked', step3UserInput.WEEK.TUE).change()
        step3Fields.WEEK.WED.prop('checked', step3UserInput.WEEK.WED).change()
        step3Fields.WEEK.THU.prop('checked', step3UserInput.WEEK.THU).change()
        step3Fields.WEEK.FRI.prop('checked', step3UserInput.WEEK.FRI).change()
        step3Fields.WEEK.SAT.prop('checked', step3UserInput.WEEK.SAT).change()
        step3Fields.WEEK.SUN.prop('checked', step3UserInput.WEEK.SUN).change()
        Onkho.Validator.ResetValidation([step3Fields.WEEK.MON, step3Fields.WEEK.TUE, step3Fields.WEEK.WED, step3Fields.WEEK.THU, step3Fields.WEEK.FRI, step3Fields.WEEK.SAT, step3Fields.WEEK.SUN])
      }

    var clearFieldsetFrequencyMonthly =
      function () {
        step3Fields.MONTH.ordinal.val(step3UserInput.MONTH.ordinal).change()
        step3Fields.MONTH.time_unit.val(step3UserInput.MONTH.time_unit).change()
        Onkho.Validator.ResetValidation([step3Fields.MONTH.ordinal, step3Fields.MONTH.time_unit])
      }

    var clearFieldsetFrequencyYearly =
      function () {
        step3Fields.YEAR.ordinal.val(step3UserInput.YEAR.ordinal).change()
        step3Fields.YEAR.time_unit.val(step3UserInput.YEAR.time_unit).change()
        step3Fields.YEAR.month.val(step3UserInput.YEAR.month).change()
      }

    // This event is responsible for clearing all input.
    modal.on('clearInput',
      function () {
        clearFieldsetReferenceDate()
        clearFieldsetFrequency()
        clearFieldsetFrequencyDaily()
        clearFieldsetFrequencyWeekly()
        clearFieldsetFrequencyMonthly()
        clearFieldsetFrequencyYearly()
      }
    )

    // Only display the before/after options if the user selects a respective starting reference.
    step3Fields.starting_reference.on('change',
      function () {
        var self = $(this)
        var value = self.val()
        var beforeAfter = fieldsetReferenceDate.find('section.before-after span.before-after')

        switch (value) {
          case 'ON' :
            beforeAfter.hide()
            break
          default :
            beforeAfter.show()
            break
        }
      }
    )

    // Update the display text of each time unit.
    step3Fields.starting_offset.on('change',
      function () {
        var self = $(this)
        var offsetValue = self.val()

        step3Fields.starting_time_unit.find('option').each(function () {
          var option = $(this)
          var optionValue = option.val()
          switch (optionValue) {
            case 'DAY'   :
              option.html((offsetValue === '1') ? 'day' : 'days')
              break

            case 'WEEK'  :
              option.html((offsetValue === '1') ? 'week' : 'weeks')
              break

            case 'MONTH' :
              option.html((offsetValue === '1') ? 'month' : 'months')
              break

            case 'YEAR'  :
              option.html((offsetValue === '1') ? 'year' : 'years')
              break
          }
        })
      }
    )

    // Only display the repeat schedule if the user opts to repeat this service.
    step3Fields.service_repeats.on('change',
      function () {
        var self = $(this)
        var shouldRepeat = self.is(':checked')
        var sectionRepeatSchedule = fieldsetFrequency.find('section.service-repeat-schedule')

        if (shouldRepeat) {
          sectionRepeatSchedule.show()
        } else {
          sectionRepeatSchedule.hide()
        }

        step3Fields.time_unit.change()
      }
    )

    // Update the display text of each time unit.
    step3Fields.frequency.on('change',
      function () {
        var self = $(this)
        var frequencyValue = self.val()

        step3Fields.time_unit.find('option').each(function () {
          var option = $(this)
          var optionValue = option.val()
          switch (optionValue) {
            case 'DAY'   :
              option.html((frequencyValue === '1') ? 'day' : 'days')
              break

            case 'WEEK'  :
              option.html((frequencyValue === '1') ? 'week' : 'weeks')
              break

            case 'MONTH' :
              option.html((frequencyValue === '1') ? 'month' : 'months')
              break

            case 'YEAR'  :
              option.html((frequencyValue === '1') ? 'year' : 'years')
              break
          }
        })
      }
    )

    // Display the correct repeating options depending on the time unit selected.
    step3Fields.time_unit.on('change',
      function () {
        var self = $(this)
        var timeUnitValue = self.val()

        addServicePanelStep3.find('div.col-time-unit').hide()

        switch (timeUnitValue) {
          case 'DAY'   :
            if (step3Fields.service_repeats.is(':checked')) {
              addServicePanelStep3.find('div.col-time-unit.col-frequency-daily').show().removeClass('hidden')
            }
            break

          case 'WEEK'  :
            if (step3Fields.service_repeats.is(':checked')) {
              addServicePanelStep3.find('div.col-time-unit.col-frequency-weekly').show().removeClass('hidden')
            }
            break

          case 'MONTH' :
            if (step3Fields.service_repeats.is(':checked')) {
              addServicePanelStep3.find('div.col-time-unit.col-frequency-monthly').show().removeClass('hidden')
            }
            break

          case 'YEAR'  :
            if (step3Fields.service_repeats.is(':checked')) {
              addServicePanelStep3.find('div.col-time-unit.col-frequency-yearly').show().removeClass('hidden')
            }
            break
        }
      }
    )

    step3Fields.MONTH.time_unit.on('change',
      function () {
        var self = $(this)
        var timeUnitValue = self.val()
        var currentOrdinalValue = step3UserInput.MONTH.ordinal

        switch (timeUnitValue) {
          case 'DAY'   :
            step3Fields.MONTH.ordinal.empty().append(
              '<option value="SAME">same</option>' +
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="5">5th</option>' +
              '<option value="6">6th</option>' +
              '<option value="7">7th</option>' +
              '<option value="8">8th</option>' +
              '<option value="9">9th</option>' +
              '<option value="10">10th</option>' +
              '<option value="11">11th</option>' +
              '<option value="12">12th</option>' +
              '<option value="13">13th</option>' +
              '<option value="14">14th</option>' +
              '<option value="15">15th</option>' +
              '<option value="16">16th</option>' +
              '<option value="17">17th</option>' +
              '<option value="18">18th</option>' +
              '<option value="19">19th</option>' +
              '<option value="20">20th</option>' +
              '<option value="21">21st</option>' +
              '<option value="22">22nd</option>' +
              '<option value="23">23rd</option>' +
              '<option value="24">24th</option>' +
              '<option value="25">25th</option>' +
              '<option value="26">26th</option>' +
              '<option value="27">27th</option>' +
              '<option value="28">28th</option>' +
              '<option value="29">29th</option>' +
              '<option value="30">30th</option>' +
              '<option value="31">31st</option>' +
              '<option value="LAST">last</option>'
            )
            break

          case 'WEEKDAY' :
            step3Fields.MONTH.ordinal.empty().append(
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="LAST">last</option>'
            )
            break

          default :
            step3Fields.MONTH.ordinal.empty().append(
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="LAST">last</option>'
            )
            break
        }

        // Only change the current selected option if it is no longer available.
        if (step3Fields.MONTH.ordinal.find('option[value="' + currentOrdinalValue + '"]').length === 0) {
          var firstOption = step3Fields.MONTH.ordinal.find('option').first()
          step3Fields.MONTH.ordinal.val(firstOption.attr('value')).change()
        } else {
          step3Fields.MONTH.ordinal.val(currentOrdinalValue)
        }
      }
    )

    step3Fields.YEAR.time_unit.on('change',
      function () {
        var self = $(this)
        var timeUnitValue = self.val()
        var currentOrdinalValue = step3UserInput.YEAR.ordinal

        switch (timeUnitValue) {
          case 'DAY'   :
            step3Fields.YEAR.ordinal.empty().append(
              '<option value="SAME">same</option>' +
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="5">5th</option>' +
              '<option value="6">6th</option>' +
              '<option value="7">7th</option>' +
              '<option value="8">8th</option>' +
              '<option value="9">9th</option>' +
              '<option value="10">10th</option>' +
              '<option value="11">11th</option>' +
              '<option value="12">12th</option>' +
              '<option value="13">13th</option>' +
              '<option value="14">14th</option>' +
              '<option value="15">15th</option>' +
              '<option value="16">16th</option>' +
              '<option value="17">17th</option>' +
              '<option value="18">18th</option>' +
              '<option value="19">19th</option>' +
              '<option value="20">20th</option>' +
              '<option value="21">21st</option>' +
              '<option value="22">22nd</option>' +
              '<option value="23">23rd</option>' +
              '<option value="24">24th</option>' +
              '<option value="25">25th</option>' +
              '<option value="26">26th</option>' +
              '<option value="27">27th</option>' +
              '<option value="28">28th</option>' +
              '<option value="29">29th</option>' +
              '<option value="30">30th</option>' +
              '<option value="31">31st</option>' +
              '<option value="LAST">last</option>'
            )
            break

          case 'WEEKDAY' :
            step3Fields.YEAR.ordinal.empty().append(
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="LAST">last</option>'
            )
            break

          default :
            step3Fields.YEAR.ordinal.empty().append(
              '<option value="FIRST">1st</option>' +
              '<option value="SECOND">2nd</option>' +
              '<option value="THIRD">3rd</option>' +
              '<option value="FOURTH">4th</option>' +
              '<option value="LAST">last</option>'
            )
            break
        }

        // Only change the current selected option if it is no longer available.
        if (step3Fields.YEAR.ordinal.find('option[value="' + currentOrdinalValue + '"]').length === 0) {
          var firstOption = step3Fields.YEAR.ordinal.find('option').first()
          step3Fields.YEAR.ordinal.val(firstOption.attr('value')).change()
        } else {
          step3Fields.YEAR.ordinal.val(currentOrdinalValue)
        }
      }
    )

    step3Fields.YEAR.ordinal.on('change',
      function () {
        var self = $(this)
        var ordinalValue = self.val()

        if (ordinalValue === 'SAME') {
          fieldsetFrequencyYearly.find('label.label-in-month').children().first().html('of the year')
          fieldsetFrequencyYearly.find('label.select.month').hide()
        } else {
          fieldsetFrequencyYearly.find('label.label-in-month').children().first().html('in')
          fieldsetFrequencyYearly.find('label.select.month').show()
        }
      }
    )

    step3Fields.reference_date_attribute.select2({
      width: '385px',
      placeholder: 'Select a reference date',
      sortResults: Onkho.Functions.Select2Sort
    })

    // Update the WIP description of the schedule being built.
    modal.on('change onkho:validation.valid onkho:validation.pending onkho:validation.invalid',
      function (event) {
        if (Onkho.Validator.IsValid(modal)) {
          var tmpUserInput = Object.assign({}, step3UserInput)
          updateStep3UserInput(tmpUserInput, defaultInput)
          updateStep3Description(tmpUserInput, inProgressScheduleDescriptionWrapper)
        } else {
          inProgressScheduleDescriptionWrapper.html('Fix any validation errors to see a description of your schedule here.')
        }
        event.stopPropagation()
      }
    )

    // Manually trigger the 'clearInput' event the first time.
    modal.trigger('clearInput')
    updateStep3Description(step3UserInput, currentScheduleDescriptionWrapper);
  };

  var updateStep3UserInput = function(step3UserInput, defaultInput) {
    // The values pertaining to the starting reference date.
    var startingOffset           = step3Fields.starting_offset.val();
    var startingTimeUnit         = step3Fields.starting_time_unit.val();
    var startingRef              = step3Fields.starting_reference.val();

    // The ID of the attribute to use,
    // or empty if the user has opted to select a due date when assigning the service to a client.
    var referenceDateAttr        = step3Fields.reference_date_attribute.val();
    var referenceDateAttrLabel   = step3Fields.reference_date_attribute.find('option[value="' + referenceDateAttr + '"]').html();
    referenceDateAttr            = (referenceDateAttr) ? referenceDateAttr : '';
    referenceDateAttrLabel       = (referenceDateAttrLabel) ? referenceDateAttrLabel : '';

    // The values pertaining to the repeatability of the service.
    var serviceRepeats           = step3Fields.service_repeats.is(':checked');
    var serviceFrequency         = step3Fields.frequency.val();
    var serviceTimeUnit          = step3Fields.time_unit.val();

    // Parameters associated with daily occurrence.
    var frequencyDailyExcludingWeekends = step3Fields.DAY.excluding_weekends.is(':checked');

    // Parameters associated with weekly occurrence.
    var frequencyWeeklyOnMon     = step3Fields.WEEK.MON.is(':checked');
    var frequencyWeeklyOnTue     = step3Fields.WEEK.TUE.is(':checked');
    var frequencyWeeklyOnWed     = step3Fields.WEEK.WED.is(':checked');
    var frequencyWeeklyOnThu     = step3Fields.WEEK.THU.is(':checked');
    var frequencyWeeklyOnFri     = step3Fields.WEEK.FRI.is(':checked');
    var frequencyWeeklyOnSat     = step3Fields.WEEK.SAT.is(':checked');
    var frequencyWeeklyOnSun     = step3Fields.WEEK.SUN.is(':checked');

    // Parameters associated with monthly occurrence.
    var frequencyMonthlyOrdinal  = step3Fields.MONTH.ordinal.val();
    var frequencyMonthlyTimeUnit = step3Fields.MONTH.time_unit.val();

    // Parameters associated with yearly occurrence.
    var frequencyYearlyOrdinal   = step3Fields.YEAR.ordinal.val();
    var frequencyYearlyTimeUnit  = step3Fields.YEAR.time_unit.val();
    var frequencyYearlyMonth     = step3Fields.YEAR.month.val();

    // Initially reset the repeatability parameters of the service, and only overwrite the active time unit, if applicable.
    step3UserInput.DAY   = Object.assign({}, defaultInput.DAY);
    step3UserInput.WEEK  = Object.assign({}, defaultInput.WEEK);
    step3UserInput.MONTH = Object.assign({}, defaultInput.MONTH);
    step3UserInput.YEAR  = Object.assign({}, defaultInput.YEAR);

    if (serviceRepeats) {
      switch (serviceTimeUnit) {
        case 'DAY'   :
          step3UserInput.DAY.excluding_weekends = frequencyDailyExcludingWeekends;
          break;

        case 'WEEK'  :
          step3UserInput.WEEK.MON         = frequencyWeeklyOnMon;
          step3UserInput.WEEK.TUE         = frequencyWeeklyOnTue;
          step3UserInput.WEEK.WED         = frequencyWeeklyOnWed;
          step3UserInput.WEEK.THU         = frequencyWeeklyOnThu;
          step3UserInput.WEEK.FRI         = frequencyWeeklyOnFri;
          step3UserInput.WEEK.SAT         = frequencyWeeklyOnSat;
          step3UserInput.WEEK.SUN         = frequencyWeeklyOnSun;
          break;

        case 'MONTH' : {
          step3UserInput.MONTH.ordinal    = frequencyMonthlyOrdinal;
          step3UserInput.MONTH.time_unit  = frequencyMonthlyTimeUnit;
          break;
        }

        case 'YEAR' : {
          step3UserInput.YEAR.ordinal    = frequencyYearlyOrdinal;
          step3UserInput.YEAR.time_unit  = frequencyYearlyTimeUnit;
          step3UserInput.YEAR.month      = frequencyYearlyMonth;
          break;
        }
      }
    }

    step3UserInput.starting_reference             = startingRef;
    step3UserInput.starting_offset                = startingOffset;
    step3UserInput.starting_time_unit             = startingTimeUnit;
    step3UserInput.reference_date_attribute       = referenceDateAttr;
    step3UserInput.reference_date_attribute_label = referenceDateAttrLabel;
    step3UserInput.service_repeats                = serviceRepeats;
    step3UserInput.frequency                      = serviceFrequency;
    step3UserInput.time_unit                      = serviceTimeUnit;
  };

  var updateStep3Description = function(step3UserInput, descriptionWrapper) {
    descriptionWrapper.html(getDescription(step3UserInput));
  }

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_service_schedule"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditServiceScheduleSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    };

    Onkho.OnkhoPanel.Add('add_edit_service_schedule', additionalData, LoadAddEditServiceScheduleSubpanelLoaded)
  };

  var toBoolean = function(val) {
    if (typeof val === 'boolean') {
      return val;
    }
    if (typeof val === 'string') {
      return val === 'true';
    }
    return Boolean(val);
  };

  var LoadAddEditServiceScheduleSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)

    $('[rel=tooltip]').tooltip('hide')

    // Load configuration
    defaultInput = panel.data('configuration')
    defaultInput.service_repeats = toBoolean(defaultInput.service_repeats)
    defaultInput.DAY.excluding_weekends = toBoolean(defaultInput.DAY.excluding_weekends)
    defaultInput.WEEK.MON = toBoolean(defaultInput.WEEK.MON)
    defaultInput.WEEK.TUE = toBoolean(defaultInput.WEEK.TUE)
    defaultInput.WEEK.WED = toBoolean(defaultInput.WEEK.WED)
    defaultInput.WEEK.THU = toBoolean(defaultInput.WEEK.THU)
    defaultInput.WEEK.FRI = toBoolean(defaultInput.WEEK.FRI)
    defaultInput.WEEK.SAT = toBoolean(defaultInput.WEEK.SAT)
    defaultInput.WEEK.SUN = toBoolean(defaultInput.WEEK.SUN)

    currentScheduleDescriptionWrapper = panel.find('section.service-schedule span.service-schedule-description')

    // Init method formerly used by Onkho-RegisterServicePageTools
    LegacyInit(panel)
  };

  var RegisterAddEditServiceScheduleSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_service"]'))
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveSchedule(panel);
    });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_service_schedule].added', '.o-panel-container', function ()
    {
      var AddEditServicesScheduleSubpanel = $('.o-panel[data-panel-key="add_edit_service_schedule"]');
      RegisterAddEditServiceScheduleSubpanelBindings(AddEditServicesScheduleSubpanel);
    });
  };

  var SaveSchedule = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      updateStep3UserInput(step3UserInput, defaultInput);
      updateStep3Description(step3UserInput, currentScheduleDescriptionWrapper);
      var AddEditServicesPanel = $('.o-panel[data-panel-key="add_edit_service"]')
      var configuration = GatherConfiguration(panel)
      Onkho.AddEditServicePanel.SetScheduleSectionConfiguration(AddEditServicesPanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = step3UserInput
    configuration.description = getDescription(step3UserInput)

    return configuration
  }



  return {
    Init: Init,
    LoadAddEditServiceScheduleSubpanel: LoadAddEditServiceScheduleSubpanel,
    getDescription: getDescription
  };
}();
