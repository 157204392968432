// Module containing tools to be used on the edit job page
Onkho.EditJobPageTools = function () {

    // An optional callback to use when saving a job.
    var callback = null;

    // References the add-job modal.
    var modal = null;
    var form  = null;

    // The model representing the current persisted state
    var defaultModel = null;

    // The form fields.
    var id                   = null;
    var name                 = null;
    var dueDate              = null;
    var plannedDeadline      = null;
    var matter               = null;
    var description          = null;
    var reason               = null;
    var status               = null;
    var selectAssignedParty  = null;

    // Sections
    var sectionDescription   = null;
    var sectionPreconditions = null;
    var sectionChecklist     = null;

    var initEditJobModal = function() {

        selectAssignedParty.select2({
            placeholder: 'No one',
            sortResults: Onkho.Functions.Select2Sort
        });

        var buttonUpdateJobDescription = sectionDescription.find('button[name="update-job-description"]');
        var buttonCancelJobDescription = sectionDescription.find('button[name="cancel-job-description"]');

        var cancelJobDescription = function() {

            // Description message
            var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
            var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
            var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

            Onkho.Summernote.DestroySummernote(descriptionSummernote);
            descriptionPreviewWrapper.find('span.job-description')
                .html(decodeURIComponent(description.val()));

            descriptionPreviewWrapper.show();
            descriptionInputWrapper.hide();

            buttonUpdateJobDescription.show();
            buttonCancelJobDescription.hide();
        };

        var commitJobDescription = function(callback) {

            // Description message
            var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
            var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
            var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

            // Wait for request to return...
            Promise.all([
                new Promise(function(resolve, reject) {
                    if (Onkho.Summernote.IsSummernoteEnabled(descriptionSummernote)) {
                        Onkho.Functions.Purify(Onkho.Summernote.GetSummernoteValue(descriptionSummernote),
                            function(data) {
                                Onkho.Summernote.DestroySummernote(descriptionSummernote);
                                description.val(encodeURIComponent(data));
                                descriptionPreviewWrapper.find('span.job-description').html(data);
                                resolve();
                            }
                        );
                    } else {
                        resolve();
                    }
                })
            ]).then(
                function(values) {
                    if (callback && typeof callback === 'function') {
                        callback();
                    }
                }
            );

            descriptionPreviewWrapper.show();
            descriptionInputWrapper.hide();

            buttonUpdateJobDescription.show();
            buttonCancelJobDescription.hide();
        };

        buttonUpdateJobDescription.on('click',
            function() {
                var self = $(this);

                // Description message
                var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
                var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
                var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

                Onkho.Summernote.UpdateSummernote(
                    descriptionSummernote,
                    decodeURIComponent(description.val())
                );

                descriptionPreviewWrapper.hide();
                descriptionInputWrapper.show();

                buttonUpdateJobDescription.hide();
                buttonCancelJobDescription.show();
            }
        );

        buttonCancelJobDescription.on('click',
            function() {
                cancelJobDescription();
            }
        );

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                Onkho.Validator.ResetValidation(
                    [dueDate, plannedDeadline, matter, description, reason, status, selectAssignedParty]
                );

                name
                    .text(defaultModel.name);

                id
                    .val(defaultModel.id)
                    .change();

                dueDate
                    .val(defaultModel.dueDate)
                    .change();

                plannedDeadline
                    .val(defaultModel.plannedDeadline)
                    .change();

                matter
                    .val(defaultModel.matter)
                    .change();

                description
                    .val(defaultModel.description)
                    .change();

                reason
                    .val(defaultModel.reason)
                    .change();

                status
                    .prop('checked', false)
                    .closest('.btn').removeClass('active focus')
                    .change()
                ;

                status
                    .filter('[value="' + defaultModel.status + '"]').prop('checked', true).closest('.btn').button('toggle')
                    .change();

                selectAssignedParty
                    .val(defaultModel.selectAssignedParty)
                    .change();

                var checklistItems = sectionChecklist.find('div.checklist_items').empty();
                if (defaultModel.checklistItems && defaultModel.checklistItems.length > 0) {
                    $(defaultModel.checklistItems).each(
                        function(index, checkListItem) {
                            checklistItems.append(
                                '<div class="checklist-item" data-id="' + checkListItem.id + '">' +
                                '   <h5>' + checkListItem.content + '</h5>' +
                                '   <label class="checkbox">' +
                                '        <input type="checkbox" name="complete" value="1" ' + ((checkListItem.date_complete) ? 'checked' : '') + '>' +
                                '   <i></i>Complete? </label>' +
                                '</div>'
                            );
                        }
                    );
                    sectionChecklist.show().removeClass('soft-hide');
                } else {
                    sectionChecklist.hide();
                }

                var preconditionItems = sectionPreconditions.find('div.precondition_items').empty();
                if (defaultModel.preconditionItems && defaultModel.preconditionItems.length > 0) {
                    $(defaultModel.preconditionItems).each(
                        function(index, preconditionItem) {
                            preconditionItems.append(
                                '<div class="precondition" data-id="' + preconditionItem.id + '">' +
                                '   <h5>' + preconditionItem.name + '</h5>' +
                                '   <label class="checkbox">' +
                                '        <input type="checkbox" name="complete" value="1" ' + ((preconditionItem.status === 'COMPLETED') ? 'checked' : '') + '>' +
                                '   <i></i>Complete? </label>' +
                                '</div>'
                            );
                        }
                    );
                    sectionPreconditions.show().removeClass('soft-hide');
                } else {
                    sectionPreconditions.hide();
                }

                cancelJobDescription();
            }
        );

        // Reset and hide the modal.
        var cancelButton = modal.find('div.modal-content div.modal-footer button[name="cancel"]');
        cancelButton.click(
            function() {
                defaultModel.id                  = '';
                defaultModel.name                = '';
                defaultModel.dueDate             = '';
                defaultModel.plannedDeadline     = '';
                defaultModel.matter              = '';
                defaultModel.description         = '';
                defaultModel.reason              = '';
                defaultModel.status              = '';
                defaultModel.selectAssignedParty = '';
                defaultModel.checklistItems      = [];
                defaultModel.preconditionItems   = [];
                modal.trigger('clearInput').modal('hide');
            }
        );

        // Save the job.
        var submitButton = modal.find('div.modal-content div.modal-footer button[name="save"]');
        submitButton.click(
            function() {
                commitJobDescription(
                    function() {
                        Save();
                    }
                );
            }
        );
    };

    var Init = function () {
        defaultModel = {
            id:                  '',
            dueDate:             '',
            plannedDeadline:     '',
            matter:              '',
            description:         '',
            reason:              '',
            status:              '',
            selectAssignedParty: '',
            checklistItems:     [],
            preconditionItems:  []
        };

        modal = $('.modal.edit-job');

        form                 = modal.find('form');
        name                 = modal.find('.name-container');
        id                   = form.find('[name="id"]');
        dueDate              = form.find('[name="due_date"]');
        plannedDeadline      = form.find('[name="planned_deadline"]');
        matter               = form.find('[name="matter"]');
        description          = form.find('[name="description"]');
        reason               = form.find('[name="reason"]');
        status               = form.find('[name="status"]');
        selectAssignedParty  = form.find('[name="assigned_to_party_id"]');

        sectionDescription   = form.find('section.description');
        sectionPreconditions = form.find('section.preconditions');
        sectionChecklist     = form.find('section.checklist');

        initEditJobModal();
    };

    var Config = function (config) {
        if (config) {
            callback = config.callback;
        }
    };

    var LoadJobForEdit = function(jobId) {
        var updateDefaultModel = function(job) {
            defaultModel.id                  = job.job_id;
            defaultModel.name                = job.job_name;
            defaultModel.dueDate             = moment(job.due_date).format('DD/MM/YYYY');
            defaultModel.plannedDeadline     = ((job.planned_deadline) ? moment(job.planned_deadline).format('DD/MM/YYYY') : '');
            defaultModel.matter              = job.job_matter;
            defaultModel.description         = ((job.description) ? encodeURIComponent(job.description) : '');
            defaultModel.reason              = job.reason;
            defaultModel.status              = job.status;
            defaultModel.selectAssignedParty = job.assignedTo_id;
            defaultModel.checklistItems      = job.checklist_items;
            defaultModel.preconditionItems   = job.preconditions;
        };

        $.get(
            Onkho.Variables.BaseURL + '/job/' + jobId + '/ajax',
            function(data) {
                updateDefaultModel(data.jobData);
                modal.trigger('clearInput').modal('show');
            }, 'json'
        ).fail(
            function(exception) {
                Onkho.Alert.BigBox('danger', 'Update Job', 'It is not possible to update this job at the moment. Please try again later.');
            }
        );
    };

    var Save = function () {
        var valid = Onkho.Validator.ValidateChildren(form);
        if (valid) {
            var submitButton = modal.find('div.modal-content div.modal-footer button[name="save"]');
            Onkho.LoadingTools.ShowLoading(submitButton);

            var formData = {};
            formData._token               = Onkho.Functions.GetCSRF();
            formData.id                   = id.val();
            formData.due_date             = dueDate.val();
            formData.planned_deadline     = plannedDeadline.val();
            formData.matter               = matter.val();
            formData.description          = decodeURIComponent(description.val());
            formData.reason               = reason.val();
            formData.status               = status.filter(':checked').val();
            formData.assigned_to_party_id = selectAssignedParty.val();

            formData.preconditions = [];
            sectionPreconditions.find('div.precondition').each(
                function () {
                    var self   = $(this);
                    var id     = self.data('id');
                    var status = self.find('input[type="checkbox"][name="complete"]').is(':checked') ? 1 : 0;

                    formData.preconditions.push({
                        id: id,
                        status: status
                    });
                }
            );

            formData.checklist = [];
            sectionChecklist.find('div.checklist-item').each(
                function () {
                    var self   = $(this);
                    var id     = self.data('id');
                    var status = self.find('input[type="checkbox"][name="complete"]').is(':checked') ? 1 : 0;

                    formData.checklist.push({
                        id: id,
                        status: status
                    });
                }
            );

            var onSuccess = function(data) {
                defaultModel.id                  = '';
                defaultModel.name                = '';
                defaultModel.dueDate             = '';
                defaultModel.plannedDeadline     = '';
                defaultModel.matter              = '';
                defaultModel.description         = '';
                defaultModel.reason              = '';
                defaultModel.status              = '';
                defaultModel.selectAssignedParty = '';
                defaultModel.checklistItems      = [];
                defaultModel.preconditionItems   = [];
                modal.trigger('clearInput').modal('hide');
            };

            $.ajax({
                type: 'PUT',
                url: Onkho.Variables.BaseURL + '/job/' + formData.id,
                data: formData,
                dataType: 'json'
            }).done(
                function(data) {
                    Onkho.Alert.SmallBox('success', data.message);
                    onSuccess(data);
                    if (typeof callback && typeof callback === 'function') {
                        callback(data);
                    }
                }
            ).fail(
                function(jqXHR, textStatus, errorThrown) {
                    var data = jqXHR.responseJSON;
                    switch(jqXHR.status) {
                        case 400 : {
                            Onkho.Alert.SmallBox('warning', data.message);
                            break;
                        }

                        case 422 : {
                            Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
                            if (data['due_date']) {
                                Onkho.Validator.StyleInvalid(
                                    dueDate, data['due_date'][0]
                                );
                            }

                            if (data['planned_deadline']) {
                                Onkho.Validator.StyleInvalid(
                                    plannedDeadline, data['planned_deadline'][0]
                                );
                            }

                            if (data['matter']) {
                                Onkho.Validator.StyleInvalid(
                                    matter, data['matter'][0]
                                );
                            }

                            if (data['description']) {
                                Onkho.Validator.StyleInvalid(
                                    description, data['description'][0]
                                );
                            }

                            if (data['status']) {
                                Onkho.Validator.StyleInvalid(
                                    dueDate, data['status'][0]
                                );
                            }

                            if (data['reason']) {
                                Onkho.Validator.StyleInvalid(
                                    plannedDeadline, data['reason'][0]
                                );
                            }

                            if (data['assigned_to_party_id']) {
                                Onkho.Validator.StyleInvalid(
                                    selectAssignedParty, data['assigned_to_party_id'][0]
                                );
                            }

                            break;
                        }

                        default : {
                            Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to save job', data['message'] ? data['message'] : 'We were unable to process your request.');
                            break;
                        }
                    }
                }
            ).always(
                function() {
                    Onkho.LoadingTools.HideLoading(submitButton);
                }
            );
        } else {
            Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
        }
    };

    return {
        Init: Init,
        Config: Config,
        LoadJobForEdit: LoadJobForEdit
    };
}();
