Onkho.AddEditMailboxScanningConfigurationPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add mailbox secondary panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  };

  var LoadAddEditMailboxScanningConfigurationPanel = function (mailboxId)
  {
    var additionalData = {
      'partyId': Onkho.Variables.PartyId
    };

    if (mailboxId) {
      additionalData.mailboxId = mailboxId;
    }

    Onkho.OnkhoPanel.Add('add_edit_mailbox_scanning_configuration', additionalData, AddEditMailboxScanningConfigurationPanelLoaded)
  };

  var AddEditMailboxScanningConfigurationPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    var sentItemsFolderPicker = panel.find('[name="sent_items_folder"]');
    sentItemsFolderPicker.select2({
      placeholder: 'Choose your Sent items folder',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/mailbox/getMailboxFolderSearchResults',
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            configuration: GatherMailboxConfiguration(),
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatAjaxError: FormatMailboxFolderSearchFailure,
      formatResult: FormatMailboxFolderSearchResult,
      formatSelection: FormatMailboxFolderSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    });

    if (sentItemsFolderPicker.data('initial-value')) {
      sentItemsFolderPicker.select2('data', {'id': sentItemsFolderPicker.data('initial-value'), 'name': sentItemsFolderPicker.data('initial-value')});
    }

    $('[name="scan_sent_items"]').on('change', function () {
      if ($(this).is(':checked')) {
        if ($(this).val() == 1) {
          sentItemsFolderPicker.select2('enable', true);
        } else {
          sentItemsFolderPicker.select2('enable', false);
        }
      }
    });
    $('[name="scan_sent_items"]').trigger('change');

    var archivedFolderPicker = panel.find('[name="archived_folder"]');
    archivedFolderPicker.select2({
      placeholder: 'Choose your Archived folder',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/mailbox/getMailboxFolderSearchResults',
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            configuration: GatherMailboxConfiguration(),
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatAjaxError: FormatMailboxFolderSearchFailure,
      formatResult: FormatMailboxFolderSearchResult,
      formatSelection: FormatMailboxFolderSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    });

    if (archivedFolderPicker.data('initial-value')) {
      archivedFolderPicker.select2('data', {'id': archivedFolderPicker.data('initial-value'), 'name': archivedFolderPicker.data('initial-value')});
    }
  };

  var FormatMailboxFolderSearchFailure = function (data)
  {
    Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to connect to your mailbox', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
    return 'Could not connect to your mailbox';
  }

  var FormatMailboxFolderSearchResult = function (mailbox)
  {
    return mailbox.name;
  }

  var FormatMailboxFolderSearchResultSelection = function (mailbox)
  {
    return mailbox.name;
  }

  var GatherMailboxConfiguration = function ()
  {
    var configuration = {};

    var panel;
    if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
      panel = $('.o-panel[data-panel-key="add_mailbox"]');
    } else {
      panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    }

    var secondaryPanel = $('.o-panel[data-panel-key="add_edit_mailbox_secondary"]');

    panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      configuration[$(input).attr('name')] = $(input).val()
    })

    secondaryPanel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      configuration[$(input).attr('name')] = $(input).val()
    })

    var scanningConfigurationPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration"]');
    if (scanningConfigurationPanel.data('mailbox-id')) {
      configuration.mailbox_id = scanningConfigurationPanel.data('mailbox-id');
    }

    return configuration;
  }

  var RegisterAddEditMailboxScanningConfigurationPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_mailbox_secondary"]'));
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditMailbox();
    });
  };

  var AddEditMailbox = function ()
  {
    var panel;
    if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
      panel = $('.o-panel[data-panel-key="add_mailbox"]');
    } else {
      panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    }

    var scanningConfigurationPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration"]');

    if (Onkho.Validator.ValidateChildren(scanningConfigurationPanel))
    {
      Onkho.LoadingTools.ShowLoading(scanningConfigurationPanel.find('button[data-id="save"]'));

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();

      var url;
      if (panel.data('panel-key') === 'add_mailbox') {
        url = Onkho.Variables.BaseURL + '/mailbox/addMailbox';
      } else {
        url = Onkho.Variables.BaseURL + '/mailbox/updateMailbox';
      }

      formData = Object.assign(formData, GatherMailboxConfiguration());

      scanningConfigurationPanel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="radio"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      formData.signature = panel.find('[data-name="signature"]').html()
      formData.disclaimer = panel.find('[data-name="disclaimer"]').html()

      $.ajax(
        {
          type: 'POST',
          url: url,
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            Onkho.LoadingTools.HideLoading(scanningConfigurationPanel.find('button[data-id="save"]'));

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.RemoveAll();

                Onkho.Alert.SmallBox('success', data.responseJSON.message);

                Onkho.OnkhoList.Refresh($('.o-list[data-id="mailboxes"]'));
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your mailbox', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
                break;
            }
          }
        });
    }
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_mailbox_scanning_configuration].added', '.o-panel-container', function ()
    {
      var addEditMailboxScanningConfigurationPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration"]');
      RegisterAddEditMailboxScanningConfigurationPanelBindings(addEditMailboxScanningConfigurationPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditMailboxScanningConfigurationPanel: LoadAddEditMailboxScanningConfigurationPanel
  };
}();
