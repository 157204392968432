Onkho.AddWidgetPanel = function ()
{
    var Init = function ()
    {
        RegisterPanelBindings();

        RegisterEventListeners();
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('click', '.add-widget-panel-trigger', function () {
            LoadAddWidgetPanel()
        })
    };

    var LoadAddWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_widget', {}, AddWidgetPanelLoaded)
    };

    var LoadAddAllWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_all_widget', {}, AddAllWidgetPanelLoaded)
    };

    var LoadAddPopularWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_popular_widget', {}, AddPopularWidgetPanelLoaded)
    };

    var LoadAddInsightsWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_insights_widget', {}, AddInsightsWidgetPanelLoaded)
    };

    var LoadAddPrebuiltWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_prebuilt_widget', {}, AddPrebuiltWidgetPanelLoaded)
    };

    var LoadAddMultimediaWidgetPanel = function ()
    {
        Onkho.OnkhoPanel.Add('add_multimedia_widget', {}, AddMultimediaWidgetPanelLoaded)
    };

    var RegisterPanelBindings = function ()
    {
        // Add widget
        $('body').on('onkho:panel[add_widget].added', '.o-panel-container', function ()
        {
            var addWidgetPanel = $('.o-panel[data-panel-key="add_widget"]');
            RegisterAddWidgetPanelBindings(addWidgetPanel);
        });

        // Add all widget
        $('body').on('onkho:panel[add_all_widget].added', '.o-panel-container', function ()
        {
            var addAllWidgetPanel = $('.o-panel[data-panel-key="add_all_widget"]');
            RegisterAddAllWidgetPanelBindings(addAllWidgetPanel);
        });

        // Add popular widget
        $('body').on('onkho:panel[add_popular_widget].added', '.o-panel-container', function ()
        {
            var addPopularWidgetPanel = $('.o-panel[data-panel-key="add_popular_widget"]');
            RegisterAddPopularWidgetPanelBindings(addPopularWidgetPanel);
        });

        // Add insights widget
        $('body').on('onkho:panel[add_insights_widget].added', '.o-panel-container', function ()
        {
            var addInsightsWidgetPanel = $('.o-panel[data-panel-key="add_insights_widget"]');
            RegisterAddInsightsWidgetPanelBindings(addInsightsWidgetPanel);
        });

        // Add prebuilt widget
        $('body').on('onkho:panel[add_prebuilt_widget].added', '.o-panel-container', function ()
        {
            var addPrebuiltWidgetPanel = $('.o-panel[data-panel-key="add_prebuilt_widget"]');
            RegisterAddPrebuiltWidgetPanelBindings(addPrebuiltWidgetPanel);
        });

        // Add multimedia widget
        $('body').on('onkho:panel[add_multimedia_widget].added', '.o-panel-container', function ()
        {
            var addMultimediaWidgetPanel = $('.o-panel[data-panel-key="add_multimedia_widget"]');
            RegisterAddMultimediaWidgetPanelBindings(addMultimediaWidgetPanel);
        });
    };

    var RegisterAddWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('.panel-opener[data-id="all"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddAllWidgetPanel();
        });

        panel.find('.panel-opener[data-id="popular"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddPopularWidgetPanel();
        });

        panel.find('.panel-opener[data-id="insights"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddInsightsWidgetPanel();
        });

        panel.find('.panel-opener[data-id="prebuilt"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddPrebuiltWidgetPanel();
        });

        panel.find('.panel-opener[data-id="multimedia"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddMultimediaWidgetPanel();
        });
    };

    var CloseAndEnableAddWidget = function (panel)
    {
        Onkho.OnkhoPanel.Remove(panel);

        var addWidgetPanel = $('.o-panel[data-panel-key="add_widget"]');
        Onkho.OnkhoPanel.EnablePanelOpener(addWidgetPanel);
        Onkho.OnkhoPanel.Enable(addWidgetPanel);
        Onkho.OnkhoPanel.EnableButtons(addWidgetPanel);
    };

    var RegisterAddAllWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            CloseAndEnableAddWidget(panel);
        });
    };

    var RegisterAddPopularWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            CloseAndEnableAddWidget(panel);
        });
    };

    var RegisterAddInsightsWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            CloseAndEnableAddWidget(panel);
        });
    };

    var RegisterAddPrebuiltWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            CloseAndEnableAddWidget(panel);
        });
    };

    var RegisterAddMultimediaWidgetPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            CloseAndEnableAddWidget(panel);
        });
    };

    var AddWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };

    var AddAllWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };

    var AddPopularWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };

    var AddInsightsWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };

    var AddPrebuiltWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };

    var AddMultimediaWidgetPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        panel.find('[rel="tooltip"]').tooltip();
    };



    return {
        Init: Init,
        LoadAddWidgetPanel: LoadAddWidgetPanel
    };
}();
