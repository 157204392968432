// Module containing tools to be used when displaying the template list
Onkho.DynamicContentTools = function () {

    var onkhoDynamicModal = null;

    var Init =
        function() {
            onkhoDynamicModal = $('#onkho-dynamic-modal');
        }
    ;

    var LoadModal =
        function(name, onLoaded, onHidden) {

            if (onLoaded) {
                onkhoDynamicModal.on(
                    'onkho:modal[' + name + '].loaded', onLoaded
                );
            }

            if (onHidden) {
                onkhoDynamicModal.on(
                    'onkho:modal[' + name + '].hidden', onHidden
                );
            }

            var promise = new Promise(
                function(resolve, reject) {
                    $.get(
                        Onkho.Variables.BaseURL + '/content/modal/' + name,
                        function(data) {
                            resolve(data);
                        }, 'json'
                    ).fail(
                        function(jqXHR) {
                            reject(jqXHR);
                        }
                    );
                }
            );

            promise.then(
                function(data) {
                    var html = $(data.html);

                    var modalDialog = onkhoDynamicModal.find('div.modal-dialog');
                    modalDialog.html(html);

                    // Trigger the loaded event for the respective content and show the modal.
                    onkhoDynamicModal.trigger(
                        'onkho:modal[' + name + '].loaded'
                    ).modal('show');

                    // Trigger a custom hidden event for the respective content
                    onkhoDynamicModal.on(
                        'hidden.bs.modal',
                        function() {
                            onkhoDynamicModal.trigger('onkho:modal[' + name + '].hidden');
                            onkhoDynamicModal.off();
                        }
                    );
                },
                function(jqXHR) {
                    Onkho.Alert.BigBox('danger', 'Failed to load content', 'Please try again later.');
                }
            )

        }
    ;

    var HideModal =
        function() {
            onkhoDynamicModal.modal('hide');
        }
    ;

    return {
        Init: Init,
        LoadModal: LoadModal,
        HideModal: HideModal
    };
}();
