// Module containing tools to be used on the insights page
Onkho.InsightPageTools = function () {
  var Init = function () {
    Onkho.OnkhoWideList.Init($('.saved-insight-wide-list'), 'saved-insight', {
      'resource': Onkho.Variables.InsightResource
    })

    RegisterListBindings()
    RegisterEventListeners()
  }

  var RegisterEventListeners = function () {
    $('body').on('onkho:wide-list[saved-insight].loaded', '.o-wide-list[data-key="saved-insight"]', function (event, responseJSON) {
      UpdateResultsCount(responseJSON.total)
    })
  }

  var RegisterListBindings = function () {
    $('body').on('click', '.saved-insight-wide-list-item .action[data-id="delete"]', function () {
      Delete($(this).closest('.saved-insight-wide-list-item').data('id'), 'Deleting saved insight', 'Are you sure you want to delete this saved insight? Note that if you are sharing this insight with your team, they will no longer be able to use it.')
    })

    $('body').on('click', '.saved-insight-wide-list .items-actions [data-id="delete"]', function () {
      var selection = Onkho.OnkhoWideList.GetSelected($('.saved-insight-wide-list'))
      var insightsIds = []
      $(selection).each(function (index, insight) {
        insightsIds.push(insight.data('id'))
      })
      Delete(insightsIds, 'Deleting saved insights', 'Are you sure you want to delete these saved insights? Note that if you are sharing some of these insights with your team, they will no longer be able to use them.')
    })

    $('body').on('click', '.saved-insight-wide-list-item .action[data-id="rename"]', function () {
      var name = $(this).closest('.saved-insight-wide-list-item').find('.subtitle a').text();
      var insights = $(this).closest('.saved-insight-wide-list-item').data('id')
      ShowRenameModal(insights, name)
    })

    $('body').on('click', '.saved-insight-wide-list-item .action[data-id="share"]', function () {
      Share($(this).closest('.saved-insight-wide-list-item').data('id'))
    })

    $('body').on('click', '.saved-insight-wide-list-item .action[data-id="unshare"]', function () {
      Unshare($(this).closest('.saved-insight-wide-list-item').data('id'))
    })

    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '#content *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  }

  var UpdateResultsCount = function (count) {
    $('.results-count').html(count)
  }

  var ShowRenameModal = function (insights, name) {
    var modal = $('.rename-saved-insight-modal')

    modal.modal('show')
    modal.find('form [name="name"]').val(name)

    modal.find('.btn.save').off().on('click', function () {
      Rename(insights)
    })
  }

  var Rename = function (insights) {
    var modal = $('.rename-saved-insight-modal')

    if (Onkho.Validator.ValidateChildren(modal)) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.name = $(modal).find('[name="name"]').val()

      formData.ids = typeof insights === 'object' ? insights : [insights]

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/insight/rename',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')
                modal.find('form')[0].reset()
                Onkho.Validator.ResetChildrenValidation(modal.find('form'))

                Onkho.OnkhoWideList.Refresh($('.saved-insight-wide-list'))
                break

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var Delete = function (insights, title, content) {
    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {

          var formData = {}
          formData._token = Onkho.Functions.GetCSRF()

          formData.ids = typeof insights === 'object' ? insights : [insights]

          $.ajax(
            {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/insight/delete',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                switch (data.status) {
                  case 200:
                    Onkho.Alert.SmallBox('success', data.responseJSON.message)

                    Onkho.OnkhoWideList.Refresh($('.saved-insight-wide-list'))
                    break

                  default:
                    Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                    break
                }
              }
            })
        }
      }
    )
  }

  var Share = function (insight) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    formData.id = insight

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/share',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', 'Insight shared!')

              Onkho.OnkhoWideList.Refresh($('.saved-insight-wide-list'))
              break
            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var Unshare = function (insight) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    formData.id = insight

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/unshare',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', 'Stopped sharing insight with team!')

              Onkho.OnkhoWideList.Refresh($('.saved-insight-wide-list'))
              break
            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  return {
    Init: Init
  }
}()

// Module containing tools to be used on the insights grid page
Onkho.InsightGridPageTools = function () {
  var table = undefined
  var tableElement = undefined
  var filtersPanel = undefined

  var columns = undefined
  var formattedColumns = undefined
  var exposedColumns = undefined

  var gridHtml = undefined
  var controlsHtml = undefined
  var selectionControlsHtml = undefined
  var defaultOrdering = undefined
  var searchWait = undefined
  var searchWaitInterval = undefined

  var Init = function () {
    $('body').on('click', '.insights-toolbar .actions [data-id="save_as"]', function () {
      OpenSaveInsightModal()
    })

    $('.save-insight-modal form').on('submit', function () {
      return false
    })

    $('body').on('click', '.kpi-wrapper .toggle.hiding', function () {
      ShowKPIContainer()
    })

    $('body').on('click', '.kpi-wrapper .toggle.showing', function () {
      HideKPIContainer()
    })

    $('.save-insight-modal .save').on('click', function () {
      SaveInsight()
    })

    $('body').on('click', '.insights-toolbar .actions [data-id="update"]', function () {
      UpdateSavedInsight()
    })

    $('body').on('click', '.insights-toolbar .actions [data-id="share"]', function () {
      ShareInsight()
    })

    $('body').on('click', '.insights-toolbar .actions [data-id="unshare"]', function () {
      UnshareInsight()
    })

    $('body').on('click', '.insights-toolbar .actions [data-id="rename"]', function () {
      OpenRenameInsightModal()
    })

    $('.rename-insight-modal form').on('submit', function () {
      return false
    })

    $('.rename-insight-modal .save').on('click', function () {
      RenameInsight()
    })

    $('body').on('click', '.insights-toolbar .actions [data-id="delete"]', function () {
      DeleteInsight()
    })

    $('body').on('click', '.grid-top-toolbar .refresh', function () {
      RefreshRecords()
    })

    // Filters
    $('body').on('click', '.o-panel[data-panel-key="insights_filters"] .filters-toolbar .add-filter', function () {
      AddFilter($(this))
    })

    $('body').on('click', '.o-panel[data-panel-key="insights_filters"] .filters-wrapper .insights-filter:not(:first-of-type) .operator', function () {
      ToggleOperator($(this))
    })

    $('body').on('change', '.o-panel[data-panel-key="insights_filters"] .insights-filter select[name="condition"]', function () {
      if (!Onkho.Variables.RestoringSavedInsight) {
        ChangeFilterForm($(this).closest('.insights-filter'))
      }
    })
    $('body').on('change', '.o-panel[data-panel-key="insights_filters"] .context-filter select[name="condition"]', function () {
      ChangeFilterForm($(this).closest('.context-filter'))
    })

    $('body').on('change', '.o-panel[data-panel-key="insights_filters"] .insights-filter select[name="negation"]', function () {
      RecomputeAvailableConditions($(this).closest('.insights-filter'))
    })

    $('body').on('click', '.o-panel[data-panel-key="insights_filters"] .insights-filter > .delete', function () {
      DeleteFilter($(this).closest('.insights-filter'))
    })

    $('body').on('change', '.o-panel[data-panel-key="insights_filters"] .insights-filter select[name="filter"]', function () {
      if (!Onkho.Variables.RestoringSavedInsight) {
        ChangeFilter($(this).closest('.insights-filter'))
      }
    })

    $('body').on('click', '.o-panel[data-panel-key="insights_filters"] [data-id="apply-filters"]', function () {
      ApplyFilters($(this))
      HideFiltersPanel()
    })

    $('body').on('click', '.o-panel[data-panel-key="insights_filters"] [data-id="reset-filters"]', function () {
      ResetFilters()
      HideFiltersPanel()
    })

    RecomputeGridActionsVisibility()

    if (Onkho.Variables.RestoringSavedInsight === true) {
      Onkho.Variables.FiltersToRestoreCount = Onkho.Variables.SavedGridFilters.length
      ShowPageLoader()

      Onkho.Variables.InitializeGridInterval = setInterval(function () {
        if (Onkho.Variables.FiltersToRestoreCount <= 0) {
          clearInterval(Onkho.Variables.InitializeGridInterval)
          Onkho.Variables.RestoringSavedInsight = false

          HidePageLoader()

          InitializeGrid()
        }
      }, 100);
    }

    ClearGrid()
    SetupGrid(GetInsightResource())

    // Focus first field when showing a modal
    $('body').on('shown.bs.modal', '#content > .modal', function () {
      $(this).find('input:visible:enabled:first').focus()
    })

    // Selection actions
    $('.insights-grid').on('click', '.selection-toolbar [data-id="edit"]', function () {
      ShowEditSelectionPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="add_services"]', function () {
      ShowActionAddServicesPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="remove_services"]', function () {
      ShowActionRemoveServicesPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="change_service_manager"]', function () {
      ShowActionChangeServiceManagerPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="email"]', function () {
      ShowActionEmailPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="sms"]', function () {
      ShowActionSMSPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="note"]', function () {
      ShowActionNotePanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="request"]', function () {
      ShowActionRequestPanel()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="delete"]', function () {
      if (Onkho.Variables.InsightGridResource === 'clients' || Onkho.Variables.InsightGridResource === 'contacts') {
        ConfirmDeleteContacts()
      } else if (Onkho.Variables.InsightGridResource === 'jobs') {
        ConfirmDeleteJobs()
      } else if (Onkho.Variables.InsightGridResource === 'steps') {
        ConfirmDeleteJobsFromSteps()
      } else if (Onkho.Variables.InsightGridResource === 'activityFeedItems') {
        ConfirmDeleteActivityFeedItems()
      } else if (Onkho.Variables.InsightGridResource === 'timeEntries') {
        ConfirmDeleteTimeEntries()
      } else if (Onkho.Variables.InsightGridResource === 'uploadLinks') {
        ConfirmDeleteUploadLinks()
      }
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="mark_as_read"]', function () {
      ConfirmMarkActivityFeedItemsAsRead()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="complete_preconditions"]', function () {
      ConfirmCompletePreconditions()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="uncomplete_preconditions"]', function () {
      ConfirmUncompletePreconditions()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="complete_checklist_items"]', function () {
      ConfirmCompleteChecklistItems()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="uncomplete_checklist_items"]', function () {
      ConfirmUncompleteChecklistItems()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="start_timetracking"]', function () {
      ConfirmStartTimetracking()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="stop_timetracking"]', function () {
      ConfirmStopTimetracking()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="downgrade_to_contact"]', function () {
      ConfirmDowngradeToContact()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="enable_automated_messages"]', function () {
      ConfirmEnableAutomatedMessages()
    })

    $('.insights-grid').on('click', '.selection-toolbar [data-id="disable_automated_messages"]', function () {
      ConfirmDisableAutomatedMessages()
    })

    // Row actions
    $('.insights-grid').on('click', '.insights-grid-wrapper td .label.toggle-timetracking', function () {
      if ($(this).hasClass('label-success')) {
        StopTimetrackingSingleJob($(this), $(this).closest('tr').attr('id'));
      } else {
        StartTimetrackingSingleJob($(this), $(this).closest('tr').attr('id'));
      }
    })

    Onkho.InsightsActionActivityFeedItemPanel.Init()

    Onkho.InsightsActionAddServicesPanel.Init()
    Onkho.InsightsActionAddServicesAssigneeSubpanel.Init()
    Onkho.InsightsActionAddServicesAutoplanSubpanel.Init()
    Onkho.InsightsActionAddServicesCommunicationsSubpanel.Init()
    Onkho.InsightsActionRemoveServicesPanel.Init()
    Onkho.InsightsActionChangeServiceManagerPanel.Init()

    Onkho.KPI.Init()
    Onkho.AddEditKPIPanel.Init()
    Onkho.AddEditKPIFormattingPanel.Init()
  }

  var RecomputeGridActionsVisibility = function () {
    var actionsContainer = $('.insights-toolbar .actions')
    actionsContainer.find('li').hide()

    if (Onkho.Variables.CanUsePaidFeatures === true) {
      if (Onkho.Variables.SavedInsightId) {
        if (Onkho.Variables.IsAuthorViewing === 1) {
          actionsContainer.find('[data-id="update"]').closest('li').show()
          actionsContainer.find('[data-id="rename"]').closest('li').show()
          actionsContainer.find('.delete-separator').show()
          actionsContainer.find('[data-id="delete"]').closest('li').show()

          if (Onkho.Variables.IsSavedInsightShared === 1) {
            actionsContainer.find('[data-id="unshare"]').closest('li').show()
          } else {
            actionsContainer.find('[data-id="share"]').closest('li').show()
          }
        }

        actionsContainer.find('[data-id="save_as"]').closest('li').show()
      } else {
        actionsContainer.find('[data-id="save_as"]').closest('li').show()
      }
    } else {
      actionsContainer.hide()
    }
  }

  var AddFilter = function (button, callback) {
    Onkho.LoadingTools.ShowLoading(button)

    var filter = $('<div class="insights-filter soft-hide" data-applied="false"><div class="operator"><input name="operator" value="and"><span>and</span></div><a href="javascript:void(0);" class="delete" data-toggle="tooltip" data-original-title="Remove filter"><i class="fa fa-fw fa-trash"></i></a></div>')
    var container = filtersPanel.find('.filters-wrapper')
    container.append(filter)
    FixOperatorVisibility()

    var formData = {}
    formData._token = $('[name="csrf_token"]').attr('content')

    $.ajax(
      {
        type: 'GET',
        url: Onkho.Variables.BaseURL + '/insight/' + GetInsightResource() + '/getGridFilters',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(button)
          switch (data.status) {
            case 200:
              var select = $('<label class="select"><select name="filter"></select></label>')

              select.find('select').append('<option disabled selected></option>')
              $(data.responseJSON.filters).each(function () {
                select.find('select').append('<option value="' + $(this)[0].value + '">' + $(this)[0].label + '</option>')
              })

              filter.append(select)

              ShowShowAllRecordsButton()

              container.find('select[name="filter"]').select2({
                placeholder: 'Select filter',
                sortResults: Onkho.Functions.Select2Sort
              })

              container.find('[data-toggle="tooltip"]').tooltip(
                {
                  placement: 'top',
                  container: 'body'
                })

              filter.slideDown(150)
              UpdateFiltersCount()

              if (typeof callback === 'function') {
                callback(filter)
              }

              break

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var ToggleOperator = function (button) {
    var operator = button.find('[name="operator"]');
    if (operator.val() === 'and') {
      operator.val('or');
      button.find('span').text('or');
    } else {
      operator.val('and');
      button.find('span').text('and');
    }
  }

  var SetAndOperator = function (button) {
    button.find('[name="operator"]').val('and');
    button.find('span').text('and');
  }

  var SetOrOperator = function (button) {
    button.find('[name="operator"]').val('or');
    button.find('span').text('or');
  }

  var ShowShowAllRecordsButton = function () {
    $('.insights-grid-wrapper .show-all').show()
  }

  var HideShowAllRecordsButton = function () {
    $('.insights-grid-wrapper .show-all').hide()
  }

  var ChangeFilterForm = function (filter) {
    var condition = filter.find('select[name="condition"]').val()

    if (filter.find('.filter-form:not(.filter-form-hidden)').length != 0) {
      var currentFilterForm = filter.find('.filter-form:not(.filter-form-hidden)').addClass('filter-form-hidden')
      var newFilterForm = filter.find('.filter-form[data-condition="' + condition + '"]')

      // Set class on new filter form
      newFilterForm.finish().removeClass('filter-form-hidden')

      // Hide current filter form
      currentFilterForm.finish().slideUp(150, function () {
        // Show new filter form
        newFilterForm.show()
      })
    } else {
      // Show new filter form
      filter.find('.filter-form[data-condition="' + condition + '"]').removeClass('filter-form-hidden').show()
    }
  }

  var RecomputeAvailableConditions = function (filter) {
    var negation = filter.find('select[name="negation"] option:selected').val()
    negation = negation.replace('_', '-')

    filter.find('select[name="condition"] option').attr('disabled', true)
    filter.find('select[name="condition"] option[data-negation-' + negation + '="true"]').attr('disabled', false)

    if (filter.find('select[name="condition"] option:selected').is(':disabled')) {
      filter.find('select[name="condition"] option').attr('selected', false)

      filter.find('select[name="condition"] option').each(function () {
        if (!$(this).is(':disabled')) {
          $(this).attr('selected', true)
          filter.find('select[name="condition"]').trigger('change')
          return false
        }
      })
    }
  }

  var FixOperatorVisibility = function () {
    filtersPanel.find('.filters-wrapper .insights-filter .operator span').css('opacity', 1)
    var firstVisibleFilter = filtersPanel.find('.filters-wrapper .insights-filter:not(.deleted):first')
    firstVisibleFilter.find('.operator span').css('opacity', 0)
  }

  var DeleteFilter = function (filter) {
    filter.find('.delete').tooltip('hide')
    filter.addClass('deleted')
    filter.slideUp(150, function () {
      UpdateFiltersCount()

      var filters = filtersPanel.find('.filters-wrapper .insights-filter');
      if (filters.length == 0) {
        HideShowAllRecordsButton()
      }

      FixOperatorVisibility()
    })
  }

  var UndeleteFilter = function (filter) {
    filter.removeClass('deleted')
    FixOperatorVisibility()
    filter.slideDown(150, function () {
      UpdateFiltersCount()

      ShowShowAllRecordsButton()
    })
  }

  var ChangeFilter = function (insightsFilter, callback) {
    // Remove old filter
    insightsFilter.find('.filter-content').remove()

    // Add loader
    insightsFilter.append('<i class="loader fa fa-fw fa-spin fa-spinner"></i>')

    var filter = insightsFilter.find('select[name="filter"] option:selected').val()

    var nonce = Math.floor(Math.random() * 10000 + 1)
    insightsFilter.data('nonce', nonce)

    var formData = {}
    formData._token = $('[name="csrf_token"]').attr('content')
    formData.nonce = nonce

    $.ajax(
      {
        type: 'GET',
        url: Onkho.Variables.BaseURL + '/insight/' + GetInsightResource() + '/getFilterData/' + filter,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              if (data.responseJSON.nonce == insightsFilter.data('nonce')) {
                insightsFilter.find('.loader').remove()
                insightsFilter.append(data.responseJSON.html)

                insightsFilter.find('select[name="condition"]').trigger('change')

                Onkho.FormEnhancements.EnhanceDates()

                insightsFilter.find('input.duration-picker').each(function (index, durationPicker) {
                  Onkho.OnkhoDurationPicker.Init($(durationPicker));
                });

                insightsFilter.find('.ajax-picker').each(function (index, ajaxPicker) {
                  InitializeAjaxPicker($(ajaxPicker));
                });

                if (typeof callback === 'function') {
                  callback()
                }
              }
              break

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })

  }

  var InitializeAjaxPicker = function (ajaxPicker)
  {
    var input = ajaxPicker.find('input');
    var filterName = input.attr('name');
    switch (filterName) {
      case 'job_name':
        InitializeJobPicker(input);
        break;

      case 'steps_completed':
      case 'steps_not_completed':
        InitializeChecklistTemplateItemPicker(input);
        break;

      case 'preconditions_completed':
      case 'preconditions_not_completed':
        InitializePreconditionTemplatePicker(input);
        break;

      default:
        //
    }
  }

  var InitializeJobPicker = function (input)
  {
    input.select2(
      {
        placeholder: 'Find a job',
        minimumInputLength: 0,
        ajax: {
          url: Onkho.Variables.BaseURL + '/job/getJobSearchResults',
          dataType: 'json',
          quietMillis: 700,
          data: function (term, page) {
            return {
              allowClear: true,
              searchTerm: term,
              page: page
            };
          },
          results: function (data, page) {
            var more = (page * 30) < data.totalCount;
            return {results: data.items, more: more};
          }
        },
        formatResult: function (job) {
          return job.html
        },
        formatSelection: function (job) {
          return job.name
        },
        dropdownCssClass: 'bigdrop',
        escapeMarkup: function (m) { return m; }
      });
  }

  var InitializeChecklistTemplateItemPicker = function (input)
  {
    input.select2(
      {
        placeholder: 'Find a step',
        minimumInputLength: 0,
        ajax: {
          url: Onkho.Variables.BaseURL + '/service/getChecklistTemplateItemSearchResults',
          dataType: 'json',
          quietMillis: 700,
          data: function (term, page) {
            return {
              searchTerm: term,
              page: page
            };
          },
          results: function (data, page) {
            var more = (page * 30) < data.totalCount;
            return {results: data.items, more: more};
          }
        },
        formatResult: function (checklistTemplateItem) {
          return checklistTemplateItem.html
        },
        formatSelection: function (checklistTemplateItem) {
          return checklistTemplateItem.name
        },
        dropdownCssClass: 'bigdrop',
        escapeMarkup: function (m) { return m; }
      });
  }

  var InitializePreconditionTemplatePicker = function (input)
  {
    input.select2(
      {
        placeholder: 'Find a requirement',
        minimumInputLength: 0,
        ajax: {
          url: Onkho.Variables.BaseURL + '/service/getPreconditionTemplateSearchResults',
          dataType: 'json',
          quietMillis: 700,
          data: function (term, page) {
            return {
              searchTerm: term,
              page: page
            };
          },
          results: function (data, page) {
            var more = (page * 30) < data.totalCount;
            return {results: data.items, more: more};
          }
        },
        formatResult: function (preconditionTemplate) {
          return preconditionTemplate.html
        },
        formatSelection: function (preconditionTemplate) {
          return preconditionTemplate.name
        },
        dropdownCssClass: 'bigdrop',
        escapeMarkup: function (m) { return m; }
      });
  }

  var ClearGrid = function () {
    $('.insights-grid-wrapper').empty()
    $('.insights-grid-controls').empty()
  }

  var ClearFilters = function () {
    filtersPanel.find('.insights-filter').each(function () {
      DeleteFilter($(this))
    })

    UpdateKPIFilters()
  }

  var UpdateKPIFilters = function ()
  {
    var filters = GatherFilters()
    $('.kpi-container .kpi').each(function (index, kpiElement) {
      Onkho.KPI.UpdateFilters($(kpiElement), filters)
    })
  }

  var ResetContextFilters = function () {
    filtersPanel.find('.context-filters .context-filter').each(function () {
      $(this).find('input[name="enabled"]').prop('checked', false)

      var filterName = $(this).find('input[name="filter"]').val()

      switch (filterName) {
        case 'clients':
          $(this).find('select[name="condition"]').val('clients')
          $(this).find('.filter-form .o-enhanced-multiselect').each(function () {
            Onkho.EnhancedMultiselect.DeselectAll($(this))
          })
          break

        default:
        //
      }
    })
  }

  var SetupGrid = function (resource) {
    var formData = {}
    formData._token = $('[name="csrf_token"]').attr('content')
    formData.restoringSavedInsight = Onkho.Variables.RestoringSavedInsight
    formData.isAuthorViewing = Onkho.Variables.IsAuthorViewing
    formData.isSavedInsightShared = Onkho.Variables.IsSavedInsightShared

    $.ajax(
      {
        type: 'GET',
        url: Onkho.Variables.BaseURL + '/insight/' + resource + '/getGrid',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              ClearGrid()
              gridHtml = data.responseJSON.gridHtml
              controlsHtml = data.responseJSON.controlsHtml
              selectionControlsHtml = data.responseJSON.selectionControlsHtml
              columns = data.responseJSON.columnDefinitions
              defaultOrdering = data.responseJSON.defaultOrdering

              LoadFiltersPanel(function () {
                ConfigureGrid(data.responseJSON.contextFiltersHtml);
              })
              break

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var UpdateFiltersCount = function () {
    var filtersCount = filtersPanel.find('.insights-filter:not(".deleted")').length
    var filtersCountBadge = $('.insights-filters-count')
    var filtersButton = filtersCountBadge.closest('.btn')

    if (filtersCount > 0) {
      filtersCountBadge.html(filtersCount)
      filtersCountBadge.show()
      filtersButton.removeClass('btn-default').addClass('btn-success')
    } else {
      filtersCountBadge.html('')
      filtersCountBadge.hide()
      filtersButton.removeClass('btn-success').addClass('btn-default')
    }
  }

  var LoadFiltersPanel = function (callback) {
    var additionalData = {
      resource: Onkho.Variables.InsightGridResource
    }

    if (Onkho.Variables.SavedInsightId) {
      additionalData.savedInsightName = Onkho.Variables.SavedInsightName
    }

    Onkho.OnkhoPanel.Add('insights_filters', additionalData, callback)
  }

  var ShowFiltersPanel = function () {
    Onkho.OnkhoPanel.Show(filtersPanel)
  }

  var HideFiltersPanel = function () {
    Onkho.OnkhoPanel.Hide(filtersPanel)
  }

  var ShowColumnsSelector = function () {
    Onkho.EnhancedMultiselect.OpenModal($('[name="insights_grid_columns"]').closest('.o-enhanced-multiselect'))
  }

  var InitializeGrid = function () {
    // The loading of the saved insight has finished at this point
    Onkho.Variables.RestoringSavedInsight = false

    // Setup grid
    tableElement = $('table')
    table = tableElement.DataTable(
      {
        'dom': '<\'grid-top-toolbar\'<\'refresh-wrapper\'<\'refresh\'>><\'filter-wrapper\'f><\'page-length-wrapper\'l><\'buttons-wrapper\'B>>' +
          '<\'selection-toolbar\'>' +
          '<\'table-wrapper\'tr>' +
        '<\'grid-bottom-toolbar\'<\'information-wrapper\'i><\'pagination-wrapper\'p><\'help-bar-spacer\'>>',
        'iDisplayLength': Onkho.Config.DataTablesDefaultPageLength,
        'lengthMenu': Onkho.Config.DataTablesPageLengthMenu,
        'processing': true,
        'serverSide': true,
        'ajax': {
          'url': Onkho.Variables.BaseURL + '/insight/' + tableElement.data('insight-resource') + '/getGridData',
          'type': 'POST',
          'data': function (data) {
            data._token = $('[name="csrf_token"]').attr('content')
            data.filters = GatherFilters()
            data.contextFilters = GatherContextFilters()
            data.visibleColumns = GatherVisibleColumns()
          }
        },
        'order': defaultOrdering,
        'buttons': [
          {
            'text': 'Filters <span class="insights-filters-count label"></span>',
            'action': function (e, dt, node, config) {
              ShowFiltersPanel()
            },
            'fade': true
          },
          {
            'text': 'Clear all filters',
            'className': 'show-all',
            'action': function (e, dt, node, config) {
              ShowAllRecords()
            },
            'fade': true
          },
          {
            'text': 'Columns',
            'action': function (e, dt, node, config) {
              ShowColumnsSelector()
            },
            'fade': true
          },
          {
            'text': 'Create KPI',
            'action': function (e, dt, node, config) {
              Onkho.AddEditKPIPanel.LoadAddEditKPIPanel()
            },
            'fade': true
          },
          {
            'extend': 'collection',
            'text': 'Export',
            'buttons': [
              {
                'extend': 'copy',
                'exportOptions': {
                  'columns': ':visible'
                }
              },
              {
                'extend': 'csv',
                'exportOptions': {
                  'columns': ':visible'
                }
              },
              {
                'extend': 'excel',
                'exportOptions': {
                  'columns': ':visible'
                }
              },
              {
                'extend': 'pdf',
                'orientation': 'landscape',
                'exportOptions': {
                  'columns': ':visible'
                }
              },
              {
                'extend': 'print',
                'exportOptions': {
                  'columns': ':visible'
                }
              }
            ],
            'fade': true
          }
        ],
        'columns': formattedColumns,
        'rowId': function (data) {
          return data.id
        },
        'select': {
          'style': 'multi+shift',
          'selector': 'td:first-child',
          'className': 'row-selected'
        },
        'colReorder': {
          'fixedColumnsLeft': 1
        },
        'scrollX': true,
        'scrollY': 'calc(100vh - 300px)',
        'scrollCollapse': true,
        'language': {
          'search': '',
          'processing': '<i class="fa fa-fw fa-spin fa-spinner"></i>'
        },
        'drawCallback': function (settings) {
          if (typeof table !== 'undefined' && typeof table.page.info() !== 'undefined') {
            var info = table.page.info()
            var maxPage = info.pages - 1
            var currentPage = info.page

            if (currentPage > maxPage && maxPage >= 0) {
              // Switch to last page and reload after a short delay

              // The delay is presumably needed because DataTables
              // hasn't actually finished drawing when this function
              // is called and an instant redraw will have no effect
              setTimeout(function () {
                table.page('last').draw('page')
              }, 100)
            }
          }
        },
        'initComplete': function (settings, json) {
          // Move selection controls to selection toolbar
          var selectionControls = $('.insights-grid > .selection-controls')
          selectionControls.children().appendTo($('.insights-grid-wrapper .selection-toolbar'))

          // Add delay to search input
          var searchInput = $('.dataTables_wrapper .filter-wrapper .dataTables_filter input')
          searchInput.attr('placeholder', 'Search')

          var api = this.api()
          searchInput.unbind().bind('input', function (event) {
            var item = $(this)
            searchWait = 0;
            if (!searchWaitInterval) {
                searchWaitInterval = setInterval(function () {
                if (searchWait >= 3) {
                  clearInterval(searchWaitInterval)
                  searchWaitInterval = ''
                  api.search($(item).val()).draw()
                  searchWait = 0
                }
                searchWait++
              }, 300)
            }
          })
        },
        'headerCallback': function (thead, data, start, end, display) {
          RedrawDraggableIcons($(thead))
        },
        'stateSave': true,
        'stateDuration': 0,
        'deferLoading': 0
      }
    )

    $(table.table().container()).removeClass('form-inline')

    table.on('draw', function () {
      RedrawTooltips()
      RedrawDraggableIcons(table.table().header())
    })

    table.on('column-reorder', function () {
      RedrawTooltips()
      RedrawDraggableIcons(table.table().header())
    })

    table.on('select', function (e, dt, type, indexes) {
      $(indexes).each(function (index, rowIndex) {
        MarkRowSelected($(table.table().row(rowIndex).node()))
      })

      RecomputeSelectAll()
      RecomputeSelectionToolbarVisibility()
    })

    table.on('deselect', function (e, dt, type, indexes) {
      $(indexes).each(function (index, rowIndex) {
        MarkRowDeselected($(table.table().row(rowIndex).node()))
      })

      RecomputeSelectAll()
      RecomputeSelectionToolbarVisibility()
    })

    $('.insights-grid-wrapper').on('click', '.dataTables_scrollHead thead th:first-child', function () {
      ToggleSelectAll()
    })

    // Restore saved insight grid columns if requested
    SetSavedGridColumns()

    if (typeof filtersPanel !== 'undefined') {
      UpdateFiltersCount()
    }
  }

  var ConfigureGrid = function (contextFiltersHtml) {
    // Build grid
    $('.insights-grid-wrapper').append(gridHtml)

    // Build grid controls
    $('.insights-grid-controls').append(controlsHtml)

    // Build selection controls
    $('.insights-grid > .selection-controls').append(selectionControlsHtml)

    // Format columns
    formattedColumns = [
      {
        data: 'selector',
        name: 'selector',
        bSearchable: false,
        bSortable: false,
        visible: true,
        type: 'html',
        width: '1px',
        render: function (cellData, type, row) {
          return '' +
            '<div class="smart-form selector">' +
              '<label class="checkbox">' +
                '<input type="checkbox" name="select" value="1">' +
                '<i></i>' +
              '</label>' +
            '</div>'
        }
      }
    ]
    exposedColumns = []
    $(columns).each(function () {
      formattedColumns.push({
        data: $(this)[0].name,
        name: $(this)[0].name,
        bSearchable: false,
        bSortable: $(this)[0].sortable,
        visible: $(this)[0].visible,
        type: $(this)[0].type,
        render: function (cellData, type, row) {
          if (type === 'display' || type === 'filter') {
            // Format unformatted dates for display and filtering purposes
            if (cellData != null && typeof cellData != 'undefined') {
              // Format dates YYYY-MM-DD
              if (cellData.length == 10) {
                var unformattedDate = moment(cellData + '', 'YYYY-MM-DD', true)
                if (unformattedDate != null && typeof unformattedDate != 'undefined' && unformattedDate.isValid() && unformattedDate.format('YYYY-MM-DD') == cellData) {
                  return unformattedDate.format('DD/MM/YYYY')
                }
              } else
              // Format dates YYYY-MM-DD HH:mm:ss
              if (cellData.length == 19) {
                var unformattedDate = moment(cellData + '', 'YYYY-MM-DD HH:mm:ss', true)
                if (unformattedDate != null && typeof unformattedDate != 'undefined' && unformattedDate.isValid() && unformattedDate.format('YYYY-MM-DD HH:mm:ss') == cellData) {
                  return unformattedDate.format('DD/MM/YYYY HH:mm:ss')
                }
              }
            }
          }

          // Fallback on existent data for ordering and type detection purposes
          return cellData;
        }
      })

      if ($(this)[0].exposed) {
        exposedColumns.push({
          name: $(this)[0].name
        })
      }
    })

    // Restore saved insight grid filters if requested
    filtersPanel = $('.o-panel[data-panel-key="insights_filters"]')
    SetSavedGridFilters()

    // Restore saved insight grid context filters if requested
    filtersPanel.find('.context-filters').html(contextFiltersHtml)
    SetSavedGridContextFilters()

    // Restore saved insight grid state if requested
    SetSavedGridState()

    // Restore saved KPIs if requested
    SetSavedKPIs()

    // Setup grid controls
    var columnsVisibilityControl = $('[name="insights_grid_columns"]').closest('.o-enhanced-multiselect')
    columnsVisibilityControl.find('select[name="insights_grid_columns"]').on('change', function () {
      $(this).find('option').each(function () {
        if ($(this).is(':selected')) {
          table.column($(this).val() + ':name').visible(true, false)
        } else {
          table.column($(this).val() + ':name').visible(false, false)
        }
      })

      table.columns.adjust().draw(false)
    })

    if (Onkho.Variables.RestoringSavedInsight !== true) {
      HidePageLoader()
      InitializeGrid()
    }
  }

  var MarkRowSelected = function (rowElement) {
    rowElement.find('td .selector [name="select"]').prop('checked', true)
  }

  var MarkRowDeselected = function (rowElement) {
    rowElement.find('td .selector [name="select"]').prop('checked', false)
  }

  var SelectRow = function (rowElement) {
    table.table().row(rowElement).select()
  }

  var DeselectRow = function (rowElement) {
    table.table().row(rowElement).deselect()
  }

  var SelectAll = function () {
    table.table().rows().select()
  }

  var DeselectAll = function () {
    table.table().rows().deselect()
  }

  var ToggleSelectAll = function () {
    var selectAllCheckbox = $('.insights-grid-wrapper .dataTables_scrollHead thead th .selector [name="select_all"]')
    if (selectAllCheckbox.is(':checked')) {
      DeselectAll()
    } else {
      SelectAll()
    }
  }

  var RecomputeSelectAll = function () {
    var selectedCount = table.table().rows({ selected: true }).count()
    var totalCount = table.table().rows().count()
    var selectAllCheckbox = $('.insights-grid-wrapper .dataTables_scrollHead thead th .selector [name="select_all"]')

    RecomputeSelectAllWarningVisibility()

    if (selectedCount === totalCount) {
      // All are selected
      selectAllCheckbox.prop('checked', true)
    } else if (selectedCount > 0) {
      // Some are selected
      selectAllCheckbox.prop('checked', false)
    } else {
      // None are selected
      selectAllCheckbox.prop('checked', false)
    }
  }

  var RecomputeSelectAllWarningVisibility = function () {
    var selectedCount = table.table().rows({ selected: true }).count()
    var totalCount = table.table().rows().count()
    var selectAllWarning = $('.insights-grid-wrapper .select-all-warning')

    if (selectedCount === totalCount && table.table().page.info().pages > 1) {
      selectAllWarning.show().css('display', 'inline-block')
    } else {
      selectAllWarning.hide()
    }
  }

  var RecomputeSelectionToolbarVisibility = function () {
    var selectedCount = table.table().rows({ selected: true }).count()
    var selectionToolbar = $('.insights-grid-wrapper .selection-toolbar')
    var gridTopToolbar = $('.insights-grid-wrapper .grid-top-toolbar')

    if (selectedCount > 0) {
      if (!selectionToolbar.is(':visible')) {
        selectionToolbar.finish().slideDown(100)
        gridTopToolbar.finish().slideUp(100)
      }
    } else {
      if (selectionToolbar.is(':visible')) {
        selectionToolbar.finish().slideUp(100)
        gridTopToolbar.finish().slideDown(100)
      }
    }
  }

  var ApplyFilters = function (button) {
    Onkho.LoadingTools.ShowLoading(button)
    CleanFilters()

    table.draw()

    Onkho.LoadingTools.HideLoading(button)

    UpdateKPIFilters()
  }

  var CleanFilters = function () {
    // Remove all .insights-filter that don't have a .filter-content
    // (i.e. filters for which the filter wasn't chosen)
    // and all deleted filters
    filtersPanel.find('.insights-filter').each(function () {
      if ($(this).find('.filter-content').length == 0 || $(this).hasClass('deleted')) {
        $(this).remove()
        UpdateFiltersCount()

        if (filtersPanel.find('.filters-wrapper .insights-filter').length == 0) {
          HideShowAllRecordsButton()
        }
      }
    })
  }

  var ShowAllRecords = function () {
    ClearFilters()
    ResetContextFilters()
    ApplyFilters(filtersPanel.find('[data-id="apply-filters"]'))
  }

  var RefreshRecords = function () {
    table.draw()
  }

  var ReloadRecords = function () {
    table.draw('page')
  }

  var ResetFilters = function () {
    filtersPanel.find('.insights-filter.deleted').each(function () {
      UndeleteFilter($(this))
    })

    filtersPanel.find('.insights-filter[data-applied="false"]').each(function () {
      $(this).remove()
      UpdateFiltersCount()

      if (filtersPanel.find('.filters-wrapper .insights-filter').length == 0) {
        HideShowAllRecordsButton()
      }
    })
  }

  var GatherFilters = function () {
    var container = filtersPanel.find('.filters-wrapper')

    var filters = []

    container.find('.insights-filter').each(function () {
      var filter = []
      $(this).find('input, select, textarea').each(function () {
        var parentForm = $(this).closest('.filter-form')
        if (parentForm.length == 0 || !parentForm.hasClass('filter-form-hidden')) {
          if ($(this).is('input[type="radio"]')) {
            if ($(this).is(':checked')) {
              filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
            }
          } else if ($(this).is('input[type="checkbox"]')) {
            if ($(this).is(':checked')) {
              filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
            }
          } else if ($(this).is('input.ajax-picker')) {
            var valueName = '';
            try {
              valueName = $(this).select2('data').name;
            } catch (error) {
              // Fall back on empty string
            }

            filter.push({'name': 'valueName', 'value': valueName})
            filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
          } else {
            filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
          }
        }
      })

      filters.push(filter)

      $(this).attr('data-applied', 'true')
    })

    var formattedFilters = []

    $(filters).each(function (index, filter) {
      var formattedFilter = {}

      // First pass, gather fixed properties
      $(filter).each(function (innerIndex, filterProperty) {
        if (['filter', 'negation', 'condition', 'operator', 'valueName'].indexOf(filterProperty.name) != -1) {
          formattedFilter[filterProperty.name] = filterProperty.value
        }
      })

      // Second pass gather filter values
      formattedFilter.values = []

      $(filter).each(function (innerIndex, filterProperty) {
        if (filterProperty.name == formattedFilter.filter) {
          if (filterProperty.value instanceof Array) {
            $(filterProperty.value).each(function (index, value) {
              formattedFilter.values.push(value)
            })
          } else {
            formattedFilter.values.push(filterProperty.value)
          }
        }
      })

      formattedFilters.push(formattedFilter)
    })

    if (formattedFilters.length > 0) {
      // First filter is always an AND
      formattedFilters[0].operator = 'and';
    }

    return formattedFilters
  }

  var GatherContextFilters = function () {
    var container = filtersPanel.find('.context-filters')

    var contextFilters = []

    container.find('.context-filter').each(function () {
      if ($(this).find('input[name="enabled"]').is(':checked')) {
        var filter = []
        $(this).find('input, select, textarea').each(function () {
          var parentForm = $(this).closest('.filter-form')
          if (parentForm.length == 0 || !parentForm.hasClass('filter-form-hidden')) {
            if ($(this).is('input[type="radio"]')) {
              if ($(this).is(':checked')) {
                filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
              }
            } else if ($(this).is('input[type="checkbox"]')) {
              if ($(this).is(':checked')) {
                filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
              }
            } else {
              filter.push({'name': $(this).attr('name'), 'value': $(this).val()})
            }
          }
        })

        contextFilters.push(filter)

        $(this).attr('data-applied', 'true')
      }
    })

    var formattedContextFilters = []

    $(contextFilters).each(function (index, filter) {
      var formattedFilter = {}

      // First pass, gather fixed properties
      $(filter).each(function (innerIndex, filterProperty) {
        if (['filter', 'negation', 'condition'].indexOf(filterProperty.name) != -1) {
          formattedFilter[filterProperty.name] = filterProperty.value
        }
      })

      // Second pass gather filter values
      formattedFilter.values = []

      $(filter).each(function (innerIndex, filterProperty) {
        if (filterProperty.name == formattedFilter.filter) {
          if (filterProperty.value instanceof Array) {
            $(filterProperty.value).each(function (index, value) {
              formattedFilter.values.push(value)
            })
          } else {
            formattedFilter.values.push(filterProperty.value)
          }
        }
      })

      formattedContextFilters.push(formattedFilter)
    })

    return formattedContextFilters
  }

  var GatherVisibleColumns = function () {
    var visible = [];
    $(table.table().settings().init().columns).each(function (index, column) {
      if (index > 0) { // Skip selector column
        if (table.column(columns[index - 1].name + ':name').visible()) {
          visible.push(columns[index - 1].name)
        }
      }
    })

    return visible
  }

  var RedrawTooltips = function () {
    $('.insights-wrapper').find('[data-toggle="tooltip"], [rel="tooltip"]').tooltip(
      {
        placement: 'top',
        container: 'body'
      })
  }

  var RedrawDraggableIcons = function (container) {
    $(container).find('th').each(function () {
      if ($(this).find('i').length == 0) {
        $(this).prepend('<i class="fa fa-fw fa-bars"></i>')
      }
    })
  }

  var OpenSaveInsightModal = function () {
    $('.save-insight-modal').modal('show')
    $('.save-insight-modal [name="name"]').focus()
  }

  var SaveInsight = function (update) {
    Onkho.LoadingTools.ShowLoading($('.save-insight-modal .save'))

    if (Onkho.Validator.ValidateChildren($('.save-insight-modal'))) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.resource = tableElement.data('insight-resource')
      formData.name = $('.save-insight-modal [name="name"]').val()
      formData.filters = GetGridFilters()
      formData.contextFilters = GetGridContextFilters()
      formData.state = GetGridState()
      formData.columns = GetGridColumns()
      formData.kpis = GetGridKPIs()

      if (update == true) {
        formData.id = Onkho.Variables.SavedInsightId
      }

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/insight/save',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading($('.save-insight-modal .save'))

            switch (data.status) {
              case 200:
                if (update) {
                  Onkho.Alert.SmallBox('success', 'Insight updated!')
                } else {
                  Onkho.Alert.SmallBox('success', 'Insight saved!')
                  window.location.href = data.responseJSON.url
                }

                $('.save-insight-modal').modal('hide')
                Onkho.Validator.ResetChildrenValidation($('.save-insight-modal'))

                // Reset modal form
                $('.save-insight-modal form').trigger('reset')
                break
              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    } else {
      Onkho.LoadingTools.HideLoading($('.save-insight-modal .save'))
    }
  }

  var ShareInsight = function () {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.id = Onkho.Variables.SavedInsightId

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/share',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', 'Insight shared!')
              $('.insights-toolbar .actions [data-id="share"]').closest('li').hide()
              $('.insights-toolbar .actions [data-id="unshare"]').closest('li').show()
              $('.insights-toolbar .shared-chip').fadeIn(150).css('display', 'inline')
              break
            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var UnshareInsight = function () {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.id = Onkho.Variables.SavedInsightId

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/unshare',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', 'Stopped sharing insight with team!')
              $('.insights-toolbar .actions [data-id="unshare"]').closest('li').hide()
              $('.insights-toolbar .actions [data-id="share"]').closest('li').show()
              $('.insights-toolbar .shared-chip').fadeOut(150)
              break
            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var OpenRenameInsightModal = function () {
    $('.rename-insight-modal').modal('show')
    $('.rename-insight-modal [name="name"]').focus()
  }

  var RenameInsight = function () {
    Onkho.LoadingTools.ShowLoading($('.rename-insight-modal .save'))

    if (Onkho.Validator.ValidateChildren($('.rename-insight-modal'))) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.name = $('.rename-insight-modal [name="name"]').val()

      formData.ids = [Onkho.Variables.SavedInsightId]

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/insight/rename',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading($('.rename-insight-modal .save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', 'Insight renamed!')
                $('.insights-toolbar .title').html(formData.name)
                document.title = formData.name

                $('.rename-insight-modal').modal('hide')
                Onkho.Validator.ResetChildrenValidation($('.rename-insight-modal'))

                // Reset modal form
                $('.rename-insight-modal form').trigger('reset')
                break
              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    } else {
      Onkho.LoadingTools.HideLoading($('.rename-insight-modal .save'))
    }
  }

  var DeleteInsight = function () {
    $.SmartMessageBox(
      {
        title: 'Deleting insight',
        content: 'Are you sure you want to delete this saved insight?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {

          var formData = {}
          formData._token = Onkho.Functions.GetCSRF()

          formData.ids = [Onkho.Variables.SavedInsightId]

          $.ajax(
            {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/insight/delete',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                switch (data.status) {
                  case 200:
                    Onkho.Alert.SmallBox('success', 'Insight deleted!')
                    window.location.href = Onkho.Variables.BaseURL + '/insight/' + GetInsightResource()
                    break

                  default:
                    Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                    break
                }
              }
            })
        }
      }
    )
  }

  var GetGridState = function () {
    return table.state()
  }

  var GetGridFilters = function () {
    return GatherFilters()
  }

  var GetGridContextFilters = function () {
    return GatherContextFilters()
  }

  var GetGridColumns = function () {
    return Onkho.EnhancedMultiselect.GetSelection($('[name="insights_grid_columns"]').closest('.o-enhanced-multiselect'))
  }

  var GetGridKPIs = function () {
    var kpis = []
    $('.kpi-container .kpi').each(function (index, kpiElement) {
      kpis.push($(kpiElement).data('configuration'))
    })

    return kpis
  }

  var SetSavedGridState = function () {
    ClearDatatablesLocalstorage()

    if (typeof Onkho.Variables.SavedGridState != 'undefined') {
      localStorage.setItem('DataTables_DataTables_Table_0_/insight/saved/' + Onkho.Variables.SavedInsightId, Onkho.Variables.SavedGridState)
    }
  }

  var ClearDatatablesLocalstorage = function () {
    $.each(localStorage, function (key, value) {
      if (key.indexOf('DataTables_DataTables_Table_') == 0) {
        localStorage.removeItem(key)
      }
    })
  }

  var SetSavedGridFilters = function () {
    if (typeof Onkho.Variables.SavedGridFilters != 'undefined' && Onkho.Variables.SavedGridFilters.length > 0) {
      // Render each saved filter
      $(Onkho.Variables.SavedGridFilters).each(function (index, filterData) {
        AddFilter(filtersPanel.find('.filters-toolbar .add-filter'), function (filter) {
          var filterSelect = filter.find('select[name="filter"]')
          filterSelect.select2().val(filterData.filter).trigger('change')

          ChangeFilter(filter, function () {
            if ('operator' in filterData) {
              if (filterData.operator === 'or') {
                SetOrOperator(filter.find('> .operator'))
              } else {
                SetAndOperator(filter.find('> .operator'))
              }
            }

            if ('negation' in filterData) {
              filter.find('select[name="negation"]').val(filterData.negation)
            }

            if ('condition' in filterData) {
              filter.find('select[name="condition"]').val(filterData.condition)
            }

            var valuesContainer = filter
            if (filter.find('.filter-forms-wrapper').length > 0) {
              // If it is a filter with multiple forms, show the
              // right one and make sure it is the one that is
              // going to be populated
              ChangeFilterForm(filter)

              valuesContainer = filter.find('.filter-forms-wrapper .filter-form:not(.filter-form-hidden)').first()
            }

            // Set values
            if (valuesContainer.find('.o-enhanced-multiselect').length > 0) {
              // Multiple choice
              Onkho.EnhancedMultiselect.SetSelection(valuesContainer.find('.o-enhanced-multiselect'), filterData.values)
            } else if (valuesContainer.find('.date-picker').length > 0) {
              // Date picker
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('input[name="' + filterData.filter + '"]:eq(' + index + ')').val(value)
              })
            } else if (valuesContainer.find('.relative-date-picker').length > 0) {
              // Relative date picker
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('[name="' + filterData.filter + '"]:eq(' + index + ')').val(value)
              })
            } else if (valuesContainer.find('.relative-time-unit-picker').length > 0) {
              // Relative time unit picker
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('[name="' + filterData.filter + '"]:eq(' + index + ')').val(value)
              })
            } else if (valuesContainer.find('.number-picker').length > 0) {
              // Number picker
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('input[name="' + filterData.filter + '"]:eq(' + index + ')').val(value)
              })
            } else if (valuesContainer.find('.textbox').length > 0) {
              // Textbox
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('input[name="' + filterData.filter + '"]:eq(' + index + ')').val(value)
              })
            } else if (valuesContainer.find('.radio-buttons-wrapper').length > 0) {
              // Single choice
              var value = filterData.values[0]
              valuesContainer.find('.radio-buttons-wrapper [name="' + filterData.filter + '"][value="' + value + '"]').prop('checked', true)
            } else if (valuesContainer.find('.ajax-picker').length > 0) {
              // Ajax picker
              var value = filterData.values[0]
              var valueName = filterData.valueName
              valuesContainer.find('.ajax-picker [name="' + filterData.filter + '"]').select2('data', { id: value, name: valueName })
            } else if (valuesContainer.find('.checkbox').length > 0) {
              // Checkboxes
              $(filterData.values).each(function (index, value) {
                valuesContainer.find('.radio-buttons-wrapper [name="' + filterData.filter + '"][value="' + value + '"]').prop('checked', true)
              })
            }

            Onkho.Variables.FiltersToRestoreCount--
          })
        })
      })
    }
  }

  var SetSavedGridContextFilters = function () {
    if (typeof Onkho.Variables.SavedGridContextFilters != 'undefined' && Onkho.Variables.SavedGridContextFilters.length > 0) {
      // Render each saved context filter
      filtersPanel.find('.context-filters .context-filter').each(function () {
        var contextFilter = $(this)
        var contextFilterName = contextFilter.find('input[name="filter"]').val()

        var savedContextFilterData = false
        $(Onkho.Variables.SavedGridContextFilters).each(function (index, contextFilterData) {
          if (contextFilterData.filter == contextFilterName) {
            savedContextFilterData = contextFilterData
            return false
          }
        })

        if (savedContextFilterData !== false) {
          switch (contextFilterName) {
            case 'clients':
              contextFilter.find('input[name="enabled"]').prop('checked', true)
              contextFilter.find('select[name="condition"]').val(savedContextFilterData.condition).trigger('change')

              if ('values' in savedContextFilterData) {
                var enhancedMultiselect = contextFilter.find('.filter-form[data-condition="' + savedContextFilterData.condition + '"] .o-enhanced-multiselect')

                if (enhancedMultiselect.length > 0) {
                  Onkho.EnhancedMultiselect.SetSelection(enhancedMultiselect, savedContextFilterData.values)
                }
              }
              break

            default:
            //
          }
        }
      })
    }
  }

  var SetSavedGridColumns = function () {
    var columnsVisibilityControl = $('[name="insights_grid_columns"]').closest('.o-enhanced-multiselect')
    var visibleColumns = []

    if (typeof Onkho.Variables.SavedGridColumns != 'undefined') {
      visibleColumns = Onkho.Variables.SavedGridColumns
    } else {
      $(formattedColumns).each(function (index, column) {
        if (column.visible) {
          visibleColumns.push(column.name)
        }
      })
    }

    Onkho.EnhancedMultiselect.SetSelection(columnsVisibilityControl, visibleColumns)

    var redrawNeeded = false
    columnsVisibilityControl.find('select[name="insights_grid_columns"] option').each(function () {
      if ($(this).is(':selected')) {
        if (!table.column($(this).val() + ':name').visible()) {
          table.column($(this).val() + ':name').visible(true, false)
          redrawNeeded = true
        }
      } else {
        if (table.column($(this).val() + ':name').visible()) {
          table.column($(this).val() + ':name').visible(false, false)
          redrawNeeded = true
        }
      }
    })

    if (redrawNeeded) {
      table.columns.adjust().draw(false)
    }
  }

  var SetSavedKPIs = function () {
    if (typeof Onkho.Variables.SavedGridKPIs != 'undefined') {
      $(Onkho.Variables.SavedGridKPIs).each(function (index, kpiConfiguration) {
        Onkho.KPI.CreateUpdateKPI(null, kpiConfiguration)
      })
      HideKPIContainer()
    }
  }

  var ShowPageLoader = function () {
    $('.page-loader').show()
    $('.insights-grid-wrapper').hide()
  }

  var HidePageLoader = function () {
    $('.page-loader').slideUp(150)
    $('.insights-grid-wrapper').slideDown(150)
  }

  var UpdateSavedInsight = function () {
    SaveInsight(true)
  }

  var ShowEditSelectionPanel = function () {
    Onkho.EditInsightsSelectionPanel.LoadEditInsightsSelectionPanel(GetInsightResource())
  }

  var ShowActionAddServicesPanel = function () {
    Onkho.InsightsActionAddServicesPanel.LoadInsightsActionAddServicesPanel('add_services', GetInsightResource())
  }

  var ShowActionRemoveServicesPanel = function () {
    Onkho.InsightsActionRemoveServicesPanel.LoadInsightsActionRemoveServicesPanel('remove_services', GetInsightResource())
  }

  var ShowActionChangeServiceManagerPanel = function () {
    Onkho.InsightsActionChangeServiceManagerPanel.LoadInsightsActionChangeServiceManagerPanel('change_service_manager', GetInsightResource())
  }

  var ShowActionEmailPanel = function () {
    Onkho.InsightsActionActivityFeedItemPanel.LoadInsightsActionActivityFeedItemPanel('email', 'OUTGOING_EMAIL', GetInsightResource())
  }

  var ShowActionSMSPanel = function () {
    Onkho.InsightsActionActivityFeedItemPanel.LoadInsightsActionActivityFeedItemPanel('sms', 'OUTGOING_SMS', GetInsightResource())
  }

  var ShowActionNotePanel = function () {
    Onkho.InsightsActionActivityFeedItemPanel.LoadInsightsActionActivityFeedItemPanel('note', 'NOTE', GetInsightResource())
  }

  var ShowActionRequestPanel = function () {
    Onkho.InsightsActionActivityFeedItemPanel.LoadInsightsActionActivityFeedItemPanel('request', 'REQUEST', GetInsightResource())
  }

  var GetSelection = function () {
    return table.table().rows({ selected: true }).data()
  }

  var GetInsightResource = function () {
    return Onkho.Variables.InsightGridResource
  }

  var ConfirmDowngradeToContact = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Downgrading 1 client'
      content = 'Are you sure you want to downgrade this client to a contact?<br/><strong>Note that when downgrading a client, any existing services will be automatically terminated and any jobs that are due in the future and have not yet been started will be deleted.</strong>'
    } else {
      title = 'Downgrading ' + count + ' clients'
      content = 'Are you sure you want to downgrade these ' + count + ' clients?<br/><strong>Note that when downgrading a client, any existing services will be automatically terminated and any jobs that are due in the future and have not yet been started will be deleted.</strong>'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DowngradeToContact()
        }
      }
    )
  }

  var DowngradeToContact = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="downgrade_to_contact"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    formData.action = 'downgrade_to_contact'

    formData.client_ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.client_ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/' + Onkho.InsightGridPageTools.GetInsightResource() + '/executeAction',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="downgrade_to_contact"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmEnableAutomatedMessages = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Enabling automated messages for 1 client'
      content = 'Are you sure you want to allow automated messages to be received by this client?'
    } else {
      title = 'Enabling automated messages for ' + count + ' clients'
      content = 'Are you sure you want to allow automated messages to be received by these ' + count + ' clients?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          EnableAutomatedMessages()
        }
      }
    )
  }

  var EnableAutomatedMessages = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="enable_automated_messages"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    formData.action = 'enable_automated_messages'

    formData.client_ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.client_ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/' + Onkho.InsightGridPageTools.GetInsightResource() + '/executeAction',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="enable_automated_messages"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDisableAutomatedMessages = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Disabling automated messages for 1 client'
      content = 'Are you sure you want to prevent automated messages from being received by this client?'
    } else {
      title = 'Disabling automated messages for ' + count + ' clients'
      content = 'Are you sure you want to prevent automated messages from being received by these ' + count + ' clients?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DisableAutomatedMessages()
        }
      }
    )
  }

  var DisableAutomatedMessages = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="disable_automated_messages"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    formData.action = 'disable_automated_messages'

    formData.client_ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.client_ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/' + Onkho.InsightGridPageTools.GetInsightResource() + '/executeAction',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="disable_automated_messages"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteContacts = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 contact'
      content = 'Are you sure you want to delete this contact from your contact list?<br/><strong>Note that when deleting a client, any existing services will be automatically terminated and any jobs that are due in the future and have not yet been started will be deleted.</strong>'
    } else {
      title = 'Deleting ' + count + ' contacts'
      content = 'Are you sure you want to delete these ' + count + ' contacts from your contact list?<br/><strong>Note that when deleting a client, any existing services will be automatically terminated and any jobs that are due in the future and have not yet been started will be deleted.</strong>'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteContacts()
        }
      }
    )
  }

  var DeleteContacts = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.mode = 'PARTIES'
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/contact/delete',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmMarkActivityFeedItemsAsRead = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Marking 1 conversation item as read'
      content = 'Are you sure you want to mark this conversation item as read?'
    } else {
      title = 'Marking ' + count + ' conversation items as read'
      content = 'Are you sure you want to mark these conversation items as read?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          MarkActivityFeedItemsAsRead()
        }
      }
    )
  }

  var MarkActivityFeedItemsAsRead = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="mark_as_read"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/readItems',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="mark_as_read"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteActivityFeedItems = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 conversation item'
      content = 'Are you sure you want to delete this conversation item?'
    } else {
      title = 'Deleting ' + count + ' conversation items'
      content = 'Are you sure you want to delete these conversation items?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteActivityFeedItems()
        }
      }
    )
  }

  var DeleteActivityFeedItems = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/deleteItems',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteUploadLinks = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 upload link'
      content = 'Are you sure you want to delete this upload link?'
    } else {
      title = 'Deleting ' + count + ' upload links'
      content = 'Are you sure you want to delete these upload links?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteUploadLinks()
        }
      }
    )
  }

  var DeleteUploadLinks = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/uploadLink/delete',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteTimeEntries = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 time entry'
      content = 'Are you sure you want to delete this time entry?'
    } else {
      title = 'Deleting ' + count + ' time entries'
      content = 'Are you sure you want to delete these time entries?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteTimeEntries()
        }
      }
    )
  }

  var DeleteTimeEntries = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/timeEntry/delete',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteJobs = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 job'
      content = 'Are you sure you want to delete this job?'
    } else {
      title = 'Deleting ' + count + ' jobs'
      content = 'Are you sure you want to delete these jobs?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteJobs()
        }
      }
    )
  }

  var DeleteJobs = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/cancel',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmDeleteJobsFromSteps = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (count === 1) {
      title = 'Deleting 1 job'
      content = 'Are you sure you want to delete the job corresponding to this step?'
    } else {
      title = 'Deleting ' + count + ' jobs'
      content = 'Are you sure you want to delete the jobs corresponding to these steps?'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteJobsFromSteps()
        }
      }
    )
  }

  var DeleteJobsFromSteps = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.job_id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/cancel',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="delete"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmCompletePreconditions = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length
    var resourcePlural = Onkho.Variables.InsightGridResource
    var resourceSingular = Onkho.Variables.InsightGridResource.slice(0, -1)

    if (count === 1) {
      title = 'Changing 1 ' + resourceSingular
      content = 'Are you sure you want to mark all requirements as complete? This will prevent any automated reminders from going out as well.'
    } else {
      title = 'Changing ' + count + ' ' + resourcePlural
      content = 'Are you sure you want to mark all requirements as complete? This will prevent any automated reminders from going out as well.'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          CompletePreconditions()
        }
      }
    )
  }

  var CompletePreconditions = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="complete_preconditions"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/completePreconditionsForJobs',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="complete_preconditions"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmUncompletePreconditions = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length
    var resourcePlural = Onkho.Variables.InsightGridResource
    var resourceSingular = Onkho.Variables.InsightGridResource.slice(0, -1)

    if (count === 1) {
      title = 'Changing 1 ' + resourceSingular
      content = 'Are you sure you want to mark all requirements as incomplete? Note that, provided the requirements are configured to send reminders, the reminders schedule will resume.'
    } else {
      title = 'Changing ' + count + ' ' + resourcePlural
      content = 'Are you sure you want to mark all requirements as incomplete? Note that, provided the requirements are configured to send reminders, the reminders schedule will resume.'
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          UncompletePreconditions()
        }
      }
    )
  }

  var UncompletePreconditions = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="uncomplete_preconditions"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/uncompletePreconditionsForJobs',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="uncomplete_preconditions"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmCompleteChecklistItems = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (Onkho.Variables.InsightGridResource === 'jobs') {
      if (count === 1) {
        title = 'Changing 1 job'
        content = 'Are you sure you want to mark all steps as complete?'
      } else {
        title = 'Changing ' + count + ' jobs'
        content = 'Are you sure you want to mark all steps as complete?'
      }
    } else {
      if (count === 1) {
        title = 'Changing 1 step'
        content = 'Are you sure you want to mark the selected step as complete?'
      } else {
        title = 'Changing ' + count + ' steps'
        content = 'Are you sure you want to mark the selected steps as complete?'
      }
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          CompleteChecklistItems()
        }
      }
    )
  }

  var CompleteChecklistItems = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="complete_checklist_items"]'));

    var url = '/job/completeChecklistItems'
    if (Onkho.Variables.InsightGridResource === 'jobs') {
      url = '/job/completeChecklistItemsForJobs'
    }

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + url,
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="complete_checklist_items"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmUncompleteChecklistItems = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (Onkho.Variables.InsightGridResource === 'jobs') {
      if (count === 1) {
        title = 'Changing 1 job'
        content = 'Are you sure you want to mark all steps as incomplete?'
      } else {
        title = 'Changing ' + count + ' jobs'
        content = 'Are you sure you want to mark all steps as incomplete?'
      }
    } else {
      if (count === 1) {
        title = 'Changing 1 step'
        content = 'Are you sure you want to mark the selected step as incomplete?'
      } else {
        title = 'Changing ' + count + ' steps'
        content = 'Are you sure you want to mark the selected steps as incomplete?'
      }
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          UncompleteChecklistItems()
        }
      }
    )
  }

  var UncompleteChecklistItems = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="uncomplete_checklist_items"]'));

    var url = '/job/uncompleteChecklistItems'
    if (Onkho.Variables.InsightGridResource === 'jobs') {
      url = '/job/uncompleteChecklistItemsForJobs'
    }

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + url,
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="uncomplete_checklist_items"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmStartTimetracking = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (Onkho.Variables.InsightGridResource === 'jobs') {
      if (count === 1) {
        title = 'Changing 1 job'
        content = 'Are you sure you want to start timetracking these jobs?'
      } else {
        title = 'Changing ' + count + ' jobs'
        content = 'Are you sure you want to start timetracking these jobs?'
      }
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          StartTimetracking()
        }
      }
    )
  }

  var StartTimetracking = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="start_timetracking"]'));

    var url = ''
    if (Onkho.Variables.InsightGridResource === 'jobs') {
      url = '/job/startTimetracking'
    }

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + url,
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="start_timetracking"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ConfirmStopTimetracking = function () {
    var title = ''
    var content = ''
    var count = GetSelection().length

    if (Onkho.Variables.InsightGridResource === 'jobs') {
      if (count === 1) {
        title = 'Changing 1 job'
        content = 'Are you sure you want to stop timetracking these jobs?'
      } else {
        title = 'Changing ' + count + ' jobs'
        content = 'Are you sure you want to stop timetracking these jobs?'
      }
    }

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          StopTimetracking()
        }
      }
    )
  }

  var StopTimetracking = function () {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="stop_timetracking"]'));

    var url = ''
    if (Onkho.Variables.InsightGridResource === 'jobs') {
      url = '/job/stopTimetracking'
    }

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = []
    $(GetSelection()).each(function (index, selectedRowData) {
      formData.ids.push(selectedRowData.id)
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + url,
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="stop_timetracking"]'));

          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.InsightGridPageTools.DeselectAll()
              Onkho.InsightGridPageTools.ReloadRecords()
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }

  var ShowKPIContainer = function ()
  {
    var kpiContainer = $('.kpi-wrapper .kpi-container')
    var kpiToggle = $('.kpi-wrapper .toggle')

    kpiContainer.slideDown(150)
    kpiToggle.html('<i class="fa fa-fw fa-chevron-up"></i>')
    kpiToggle.addClass('showing').removeClass('hiding')
  }

  var HideKPIContainer = function ()
  {
    var kpiContainer = $('.kpi-wrapper .kpi-container')
    var kpiToggle = $('.kpi-wrapper .toggle')
    var kpiCount = $('.kpi-wrapper .kpi-container .kpi').length

    kpiContainer.slideUp(150)
    if (kpiCount == 1) {
      kpiToggle.html('One KPI hidden. Click to reveal')
    } else if (kpiCount == 0) {
      kpiToggle.html('')
    } else {
      kpiToggle.html(kpiCount + ' KPIs hidden. Click to reveal')
    }

    kpiToggle.addClass('hiding').removeClass('showing')
  }

  var StartTimetrackingSingleJob = function (button, jobId)
  {
    Onkho.LoadingTools.ShowLoading(button, true);

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.ids = [jobId];

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/startTimetracking',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(button, true);

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              button.html('<i class="fa fa-fw fa-check-square-o"></i> Yes');
              button.removeClass('label-danger').addClass('label-success');
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  };

  var StopTimetrackingSingleJob = function (button, jobId)
  {
    Onkho.LoadingTools.ShowLoading(button, true);

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.ids = [jobId];

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/job/stopTimetracking',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(button, true);

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              button.html('<i class="fa fa-fw fa-square-o"></i> No');
              button.removeClass('label-success').addClass('label-danger');
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  };



  return {
    Init: Init,
    ReloadRecords: ReloadRecords,
    GetSelection: GetSelection,
    GetInsightResource: GetInsightResource,
    DeselectAll: DeselectAll,
    GatherFilters: GatherFilters,
    GatherContextFilters: GatherContextFilters,
    ShowKPIContainer: ShowKPIContainer,
    HideKPIContainer: HideKPIContainer
  }
}()
