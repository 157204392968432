Onkho.OnkhoActiveElement = function ()
{
    var activeElementContainer;
    var mouseX;
    var mouseY;

    var Init = function ()
    {
        CreateActiveElementContainer();

        RegisterTriggerListeners();
    };

    var CreateActiveElementContainer = function ()
    {
        if ($('.o-active-element-wrapper').length == 0)
        {
            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/activeElement/getWrapper',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                          {
                              switch (data.status)
                              {
                                  case 200:
                                      $('body').append(data.responseJSON.html);
                                      $('body').trigger('onkho:activeElement[wrapper].added');

                                      activeElementContainer = $('.o-active-element-container');
                                      break;

                                  default:
                                      Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                      break;
                              }
                          }
                });
        }
    };

    var RegenerateNonce = function ()
    {
        if ($('.o-active-element-wrapper').length > 0)
        {
            $('.o-active-element-wrapper').data('nonce', Math.floor(Math.random() * 10000 + 1));
        }
    };

    var CheckNonce = function (nonce)
    {
        return parseInt($('.o-active-element-wrapper').data('nonce')) === parseInt(nonce);
    };

    var GetCurrentNonce = function ()
    {
        if ($('.o-active-element-wrapper').length > 0)
        {
            return $('.o-active-element-wrapper').data('nonce');
        }
        else
        {
            return 0;
        }
    };

    var RegisterTriggerListeners = function ()
    {
        // All types
        $('body').on('click', '.o-active-element-trigger', function (event)
        {
            // Cache mouse position that will be used to appropriately position
            // the active element popup
            mouseX = event.clientX;
            mouseY = event.clientY;

            // Stop further event propagation
            event.stopPropagation();

            Add($(this));
        });

        $('body').on('click', '.o-active-element .featured-action.section:not(.active)', function (event)
        {
            // Stop further event propagation
            event.stopPropagation();

            ShowSection($(this).closest('.o-active-element'), $(this).data('id'));
        });

        $('body').on('click', '.o-active-element .featured-action.section.active', function (event)
        {
            // Stop further event propagation
            event.stopPropagation();

            HideSection($(this).closest('.o-active-element'), $(this).data('id'));
        });

        // Dismissals
        $('body').on('click', '.o-active-element .close-wrapper', function ()
        {
            Remove($(this).closest('.o-active-element'));
        });

        $('body').on('click', '.o-active-element-container', function (event)
        {
            if (event.target == this)
            {
                Remove(activeElementContainer.find('.o-active-element.shown'));
            }
        });

        $(window).on('scroll', function (event)
        {
            // Clear active elements only on page-wide scrolls
            if (event.target === document)
            {
                RemoveAll();
            }
        });
    };

    var Add = function (trigger, callback)
    {
        var callbackArguments = Array.prototype.slice.call(arguments, 1);
        RegenerateNonce();
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = GetCurrentNonce();
        formData.type = trigger.data('type');
        if (formData.type === 'payload') {
            formData.data = trigger.data('data')
        } else {
            formData.id = trigger.data('id');
        }

        ShowLoading();
        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/activeElement/getActiveElement',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          HideLoading();
                          switch (data.status)
                          {
                              case 200:
                                  var activeElement = $(data.responseJSON.html);
                                  activeElementContainer.append(activeElement);
                                  activeElement.trigger('onkho:activeElement[' + activeElement.data('type') + '].added');

                                  activeElement.find('[data-toggle="tooltip"]').tooltip(
                                  {
                                      placement: 'top',
                                      container: 'body'
                                  });

                                  Show.apply(null, [activeElement].concat(callbackArguments.concat(activeElement)));

                                  activeElement.draggable({
                                      'containment': '.o-active-element-container',
                                      'stack': '.o-active-element',
                                      'cursor': 'grabbing',
                                      'distance': 5,
                                      'snapMode': 'inner',
                                      'snap': '.o-active-element-container'
                                  });

                                  BringToFront(activeElement);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var Remove = function (activeElement, callback)
    {
        var activeElementType = activeElement.data('type');

        activeElement.find('[data-toggle="tooltip"]').tooltip('destroy');

        Hide(activeElement);
        activeElement.remove();

        activeElementContainer.trigger('onkho:activeElement[' + activeElementType + '].removed');
        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1));
    };

    var ShowSection = function (activeElement, section, callback)
    {
        var callbackArguments = Array.prototype.slice.call(arguments, 1);
        RegenerateNonce();

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = GetCurrentNonce();
        formData.type = activeElement.data('type');
        formData.id = activeElement.data('id');
        formData.section = section;

        var button = activeElement.find('.featured-action.section[data-id="' + section + '"]');
        Onkho.LoadingTools.ShowLoading(button, true);
        ShowLoading();

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/activeElement/getActiveElementSection',
              data: formData,
              dataType: 'json',
              complete: function (data)
              {
                  Onkho.LoadingTools.HideLoading(button);
                  HideLoading();
                  button.addClass('active');

                  switch (data.status) {
                      case 200:
                          activeElement.find('.sections').append($(data.responseJSON.html))
                          var sectionElement = activeElement.find('.sections .section[data-id="' + section + '"]');
                          sectionElement.slideDown(150);

                          activeElement.trigger('onkho:activeElement[' + activeElement.data('type') + '].section.' + section + '.shown')

                          activeElement.find('.sections .section[data-id="' + section + '"]').find('input').on('keypress', function (event) {
                              if (event.keyCode === 13) {
                                  event.preventDefault();
                              }
                          });

                          Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 2))
                          break

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };

    var HideSection = function (activeElement, section)
    {
        var button = activeElement.find('.featured-action.section[data-id="' + section + '"]');
        Onkho.LoadingTools.ShowLoading(button, true);
        ShowLoading();

        button.removeClass('active');
        var sectionElement = activeElement.find('.sections .section[data-id="' + section + '"]');
        sectionElement.slideUp(150, function () {
            Onkho.LoadingTools.HideLoading(button);
            HideLoading();

            sectionElement.remove();

            activeElement.trigger('onkho:activeElement[' + activeElement.data('type') + '].section.' + section + '.hidden');
        });
    };

    var BringToFront = function (activeElement)
    {
        var maxZindex = 0;
        $('.o-active-element').each(function (index, activeElement)
        {
            maxZindex = Math.max(maxZindex, parseInt($(activeElement).css('z-index')));
        });

        activeElement.css('z-index', maxZindex + 1);
    };

    var Reposition = function (activeElement)
    {
        var x = mouseX + 15;
        var y = mouseY + 20;

        if (y + activeElement.height() > $(window).height())
        {
            // Part of the popup will be outside of the viewport, move up a bit
            y -= y + activeElement.height() - $(window).height() + 20;
        }

        if (x + activeElement.width() > $(window).width())
        {
            // Part of the popup will be outside of the viewport, move left a bit
            x -= x + activeElement.width() - $(window).width() + 20;
        }

        activeElement.css('left', x + 'px');
        activeElement.css('top', y + 'px');
    };

    var ShowLoading = function ()
    {
        $('html').css('cursor', 'wait');
    };

    var HideLoading = function ()
    {
        $('html').css('cursor', 'initial');
    };

    var Show = function (activeElement, callback)
    {
        // Hide all tooltips because the active element is opening
        $('.tooltip').tooltip('hide');

        ShowBackdrop();

        if (activeElement.hasClass('o-active-element') && activeElement.hasClass('hidden'))
        {
            activeElement.trigger('onkho:activeElement[' + activeElement.data('type') + '].shown');
            activeElement.removeClass('hidden').addClass('shown');

            Reposition(activeElement);

            Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1).concat(activeElement));
        }
    };

    var Hide = function (activeElement, callback)
    {
        if (activeElement.hasClass('o-active-element') && activeElement.hasClass('shown'))
        {
            activeElement.trigger('onkho:activeElement[' + activeElement.data('type') + '].hidden');
            activeElement.removeClass('shown').addClass('hidden');

            HideBackdrop.apply(null, Array.prototype.slice.call(arguments, 1));
        }
    };

    var ShowBackdrop = function (callback)
    {
        var wrapper = $('.o-active-element-wrapper');
        if (wrapper.hasClass('hidden'))
        {
            wrapper.trigger('onkho:activeElement[wrapper].shown');
            wrapper.removeClass('hidden').addClass('shown');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var HideBackdrop = function (callback)
    {
        var wrapper = $('.o-active-element-wrapper');
        if (wrapper.hasClass('shown') && $('.o-active-element.shown').length == 0)
        {
            wrapper.trigger('onkho:activeElement[wrapper].hidden');
            wrapper.removeClass('shown').addClass('hidden');

            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
        else
        {
            Onkho.Functions.ExecuteCallback.apply(null, arguments);
        }
    };

    var RemoveAll = function (callback)
    {
        $('.o-active-element').each(function (index, activeElement)
        {
            Remove($(activeElement));
        });

        Onkho.Functions.ExecuteCallback.apply(null, Array.prototype.slice.call(arguments, 1));
    };



    return {
        Init: Init,
        RemoveAll: RemoveAll
    };
}();
