// Module containing tools to be used to handle user interactions
Onkho.Interaction = function () {
  var Init = function () {
    RegisterEventListeners()
  }

  var RegisterEventListeners = function () {
    // Click on the tutorials button
    $('body').on('click', '.help-tool.tutorials-button', function () {
      HandleInteraction('click_on_tutorials_button')
    });

    // Click on the call us button
    $('body').on('click', '.help-tool.call-us-button', function () {
      HandleInteraction('click_on_call_us_button')
    });

    // Click on the email us button
    $('body').on('click', '.help-tool.email-us-button', function () {
      HandleInteraction('click_on_email_us_button')
    });

    // Click on the chat with us button
    $('body').on('click', '.help-tool.chat-with-us-button', function () {
      HandleInteraction('click_on_chat_with_us_button')
    });
  }

  var HandleInteraction = function (interaction) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.interaction = interaction

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/interaction/handle',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          // Nothing to do
        }
      })
  }

  return {
    Init: Init
  }
}()

