Onkho.OnkhoStatus = function ()
{
    var Init = function (statusElement)
    {
        // Button handlers
        $(statusElement).on('click', '.status-button', function ()
        {
            SetStatus(statusElement, $(this).data('id'));
        });

        // Enable tooltips
        statusElement.find('[data-toggle="tooltip"]').tooltip('destroy').tooltip(
        {
            placement: 'top',
            container: 'body'
        });

        // Set initial status
        SetStatus(statusElement, statusElement.data('value'));
    };

    var SetStatus = function (statusElement, status)
    {
        // Set status data attribute
        statusElement.data('value', status);

        // Enable all status buttons
        $(statusElement).find('.status-button').removeClass('disabled');

        // Change the background of the ribbon to match the background
        // of the button corresponding to the chosen status
        $(statusElement).find('.status-button').each(function (index, button)
        {
            if ($(button).data('id') == status)
            {
                statusElement.find('.ribbon').css('background-color', $(button).css('background-color'));

                // Disable status button corresponding to the current status
                $(button).addClass('disabled');
            }
        });
    };

    var GetStatus = function (statusElement)
    {
        return statusElement.data('value');
    };



    return {
        Init: Init,
        GetStatus: GetStatus
    };
}();