// Configuration options
Onkho.Config = {
    'DataTablesDefaultPageLength': 25,
    'DataTablesPageLengthMenu': [[10, 25, 50, 100, 250, 500, 1000, -1], [10, 25, 50, 100, 250, 500, 1000, "All"]]
};



// Namespaced variables container for general use
Onkho.Variables = {
    SuccessColor:   '#298529',
    WarningColor:   '#BD7A1B',
    DangerColor:    '#A6201C',
    InfoColor:      '#288DAB',
    AlertsTimeout:   4000,
    DefaultClientId: null
};
