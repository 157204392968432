// Module containing tools to be used when displaying an upload link attachments page
Onkho.UploadLinkAttachments = function () {
  var Init = function () {
    InitializeAttachmentsTable();

    $('.upload-link-attachments-page .back').on('click', function () {
      history.back();
    });

    $('.upload-link-attachments-page .activity-feed-item-link').on('click', function () {
      Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane($(this).data('group-id'), $(this).data('id'));
    });

    $('.upload-link-attachments-page [rel="tooltip"]').tooltip();

    $('.upload-link-attachments-page .upload-link-actions [data-id="delete"]').on('click', function () {
      ConfirmDeleteUploadLink();
    });
  }

  var InitializeAttachmentsTable = function ()
  {
    $('.attachments-wrapper .table').DataTable({
      'dom': '<\'grid-top-toolbar\'<\'filter-wrapper\'f><\'page-length-wrapper\'l>>' +
        '<\'table-wrapper\'tr>' +
        '<\'grid-bottom-toolbar\'<\'information-wrapper\'i><\'pagination-wrapper\'p><\'help-bar-spacer\'>>',
      'iDisplayLength': 10,
      'lengthMenu': Onkho.Config.DataTablesPageLengthMenu,
      'processing': true,
      'serverSide': false,
      'language': {
        'search': '',
        'processing': '<i class="fa fa-fw fa-spin fa-spinner"></i>',
        'emptyTable': 'No files have been uploaded.'
      },
      'initComplete': function (settings, json) {
        var searchInput = $('.dataTables_wrapper .filter-wrapper .dataTables_filter input')
        searchInput.attr('placeholder', 'Search')
      },
    });
  };

  var ConfirmDeleteUploadLink = function () {
    var title = 'Deleting upload link'
    var content = 'Are you sure you want to delete this upload link?'

    $.SmartMessageBox(
      {
        title: title,
        content: content,
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteUploadLink()
        }
      }
    )
  }

  var DeleteUploadLink = function () {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.ids = [Onkho.Variables.UploadLinkId]

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/uploadLink/delete',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);
              setTimeout(function () {
                window.location.href = Onkho.Variables.BaseURL + '/dashboard';
              }, 1000);
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break;
          }
        }
      });
  }



  return {
    Init: Init,
  }
}()
