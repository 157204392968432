Onkho.AddEditMailboxSecondaryPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add edit mailbox secondary panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_mailbox_secondary"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  };

  var LoadAddEditMailboxSecondaryPanel = function (mailboxId, type, isScannedForIncomingMessages, isUsedForOutgoingMessages)
  {
    var additionalData = {
      'partyId': Onkho.Variables.PartyId,
      'type': type,
      'isScannedForIncomingMessages': isScannedForIncomingMessages,
      'isUsedForOutgoingMessages': isUsedForOutgoingMessages
    };

    if (mailboxId) {
      additionalData.mailboxId = mailboxId;
    }

    Onkho.OnkhoPanel.Add('add_edit_mailbox_secondary', additionalData, AddEditMailboxSecondaryPanelLoaded)
  };

  var AddEditMailboxSecondaryPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    panel.find('select').select2();

    var outgoingProtocolPicker = panel.find('[name="outgoing_protocol"]')
    if (outgoingProtocolPicker.length > 0) {
      outgoingProtocolPicker.on('change', function () {
        if ($(this).val() === 'SMTP') {
          panel.find('[name="outgoing_port"]').val('587')
        } else if ($(this).val() === 'SMTP_SSL') {
          panel.find('[name="outgoing_port"]').val('465')
        } else if ($(this).val() === 'SMTP_TLS') {
          panel.find('[name="outgoing_port"]').val('587')
        }
      })

      if (!outgoingProtocolPicker.val()) {
        outgoingProtocolPicker.val('SMTP_TLS').trigger('change')
      }
    }

    var incomingProtocolPicker = panel.find('[name="incoming_protocol"]')
    if (incomingProtocolPicker.length > 0) {
      incomingProtocolPicker.on('change', function () {
        if ($(this).val() === 'IMAP') {
          panel.find('[name="incoming_port"]').val('143')
        } else if ($(this).val() === 'IMAP_SSL') {
          panel.find('[name="incoming_port"]').val('993')
        } else if ($(this).val() === 'IMAP_TLS') {
          panel.find('[name="incoming_port"]').val('993')
        }
      })

      if (!incomingProtocolPicker.val()) {
        incomingProtocolPicker.val('IMAP_SSL').trigger('change')
      }
    }

    ShowAppropriateSaveButtonLabel(panel);
  };

  var RegisterAddEditMailboxSecondaryPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
        Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_mailbox"]'));
      } else {
        Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="edit_mailbox"]'));
      }
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditMailbox();
    });
  };

  var ShowAppropriateSaveButtonLabel = function (panel)
  {
    var saveButton = panel.find('button[data-id="save"]');

    saveButton.removeClass('hidden');

    if (parseInt(panel.data('is-scanned-for-incoming-messages')) === 1) {
      saveButton.html('Next')
    } else {
      saveButton.html('Save')
    }
  };

  var AddEditMailbox = function ()
  {
    var panel = $('.o-panel[data-panel-key="add_edit_mailbox_secondary"]');

    if (Onkho.Validator.ValidateChildren(panel))
    {
      var isScannedForIncomingMessages = parseInt(panel.data('is-scanned-for-incoming-messages'))
      if (!isScannedForIncomingMessages) {
        // This mailbox is not configured to be scanned for incoming messages
        PersistMailbox()
      } else {
        Onkho.OnkhoPanel.Disable(panel)

        if (panel.data('mailbox-id')) {
          Onkho.AddEditMailboxScanningConfigurationPanel.LoadAddEditMailboxScanningConfigurationPanel(panel.data('mailbox-id'))
        } else {
          Onkho.AddEditMailboxScanningConfigurationPanel.LoadAddEditMailboxScanningConfigurationPanel()
        }
      }
    }
  };

  var PersistMailbox = function ()
  {
    var panel;
    if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
      panel = $('.o-panel[data-panel-key="add_mailbox"]');
    } else {
      panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    }

    var secondaryPanel = $('.o-panel[data-panel-key="add_edit_mailbox_secondary"]');

    if (Onkho.Validator.ValidateChildren(secondaryPanel))
    {
      Onkho.LoadingTools.ShowLoading(secondaryPanel.find('button[data-id="save"]'));

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();

      var url;
      if (panel.data('panel-key') === 'add_mailbox') {
        url = Onkho.Variables.BaseURL + '/mailbox/addMailbox';
      } else {
        formData.mailbox_id = panel.data('mailbox-id');
        url = Onkho.Variables.BaseURL + '/mailbox/updateMailbox';
      }

      panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      secondaryPanel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      formData.signature = panel.find('[data-name="signature"]').html()
      formData.disclaimer = panel.find('[data-name="disclaimer"]').html()

      $.ajax(
        {
          type: 'POST',
          url: url,
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            Onkho.LoadingTools.HideLoading(secondaryPanel.find('button[data-id="save"]'));

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.RemoveAll();

                Onkho.Alert.SmallBox('success', data.responseJSON.message);

                Onkho.OnkhoList.Refresh($('.o-list[data-id="mailboxes"]'));
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your mailbox', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
                break;
            }
          }
        });
    }
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_mailbox_secondary].added', '.o-panel-container', function ()
    {
      var addEditMailboxSecondaryPanel = $('.o-panel[data-panel-key="add_edit_mailbox_secondary"]');
      RegisterAddEditMailboxSecondaryPanelBindings(addEditMailboxSecondaryPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditMailboxSecondaryPanel: LoadAddEditMailboxSecondaryPanel
  };
}();
