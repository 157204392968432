// Module containing tools to be used to handle temporary storage operations
Onkho.TemporaryStorage = function () {
  var Download = function (token, filename) {
    var fakeHref = document.createElement('a')
    fakeHref.href = Onkho.Variables.BaseURL + '/download/' + token
    fakeHref.target = '_blank'

    fakeHref.download = filename
    document.body.appendChild(fakeHref)
    fakeHref.click()
    fakeHref.remove()
  }



  return {
    Download: Download
  }
}()

