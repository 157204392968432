Onkho.OnkhoJobActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();

        RegisterEventListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        // --- Featured actions ---
        // Start
        $('body').on('click', '.o-active-element[data-type="job"] .featured-action[data-id="start"]:not(.disabled)', function (event)
        {
            Start($(this).closest('.o-active-element'));
        });

        // Pause
        $('body').on('click', '.o-active-element[data-type="job"] .featured-action[data-id="pause"]:not(.disabled)', function (event)
        {
            Pause($(this).closest('.o-active-element'));
        });

        // Complete
        $('body').on('click', '.o-active-element[data-type="job"] .featured-action[data-id="complete"]:not(.disabled)', function (event)
        {
            Complete($(this).closest('.o-active-element'));
        });

        // Group
        $('body').on('click', '.o-active-element[data-type="job"] .featured-action[data-id="group"]', function (event)
        {
            Onkho.OnkhoActiveElement.RemoveAll();
            Onkho.OnkhoPane.RemoveAll();
            Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane($(this).closest('.o-active-element').data('activity-feed-group-id'));
        });

        // Edit
        $('body').on('click', '.o-active-element[data-type="job"] .featured-action[data-id="edit"]', function (event)
        {
            Onkho.OnkhoActiveElement.RemoveAll();
            Onkho.EditJobPanel.LoadEditJobPanel($(this).closest('.o-active-element').data('id'));
        });

        // Timetracking
        $('body').on('click', '.o-active-element[data-type="job"] .sections .section[data-id="TIMETRACKING"] button.add-time', function (event)
        {
            AddTime($(this).closest('.o-active-element'));
        });

        $('body').on('click', '.o-active-element[data-type="job"] .sections .section[data-id="TIMETRACKING"] button.start-timetracking', function (event)
        {
            StartTimetracking($(this).closest('.o-active-element'));
        });

        $('body').on('click', '.o-active-element[data-type="job"] .sections .section[data-id="TIMETRACKING"] button.stop-timetracking', function (event)
        {
            StopTimetracking($(this).closest('.o-active-element'));
        });

        $('body').on('click', '.o-active-element[data-type="job"] .sections .section[data-id="TIMETRACKING"] button.create-time-entry', function (event)
        {
            Onkho.OnkhoActiveElement.RemoveAll();
            Onkho.AddEditTimeEntryPanel.LoadAddEditTimeEntryPanel(undefined, $(this).closest('.o-active-element').data('id'));
        });

        // --- Details actions ---
        // Complete/Uncomplete checklist item
        $('body').on('click', '.o-active-element[data-type="job"] .checklist-item-small input[type="checkbox"]', function (event)
        {
            SetChecklistItemStatus($(this).closest('.job-active-element'), $(this));
        });

        // Complete/Uncomplete precondition
        $('body').on('click', '.o-active-element[data-type="job"] .precondition-small input[type="checkbox"]', function (event)
        {
            SetPreconditionStatus($(this).closest('.job-active-element'), $(this));
        });
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('onkho:activeElement[job].added', '.o-active-element', function ()
        {
            DisableStatusButton($(this));
        });

        $('body').on('onkho:activeElement[job].section.TIMETRACKING.shown', '.o-active-element', function ()
        {
            PrepareTimetrackingSection($(this));
        });
    };

    var DisableStatusButton = function (activeElement)
    {
        if (activeElement.hasClass('started')) { activeElement.find('.featured-action[data-id="start"]').addClass('disabled'); }
        if (activeElement.hasClass('paused')) { activeElement.find('.featured-action[data-id="pause"]').addClass('disabled'); }
        if (activeElement.hasClass('completed')) { activeElement.find('.featured-action[data-id="complete"]').addClass('disabled'); }
    };

    var Start = function (activeElement)
    {
        SetStatus(activeElement, 'start', 'STARTED');
    };

    var Pause = function (activeElement)
    {
        SetStatus(activeElement, 'pause', 'PAUSED');
    };

    var Complete = function (activeElement)
    {
        SetStatus(activeElement, 'complete', 'COMPLETED');
    };

    var SetStatus = function (activeElement, buttonId, status)
    {
        var button = activeElement.find('.featured-action[data-id="' + buttonId + '"]');
        Onkho.LoadingTools.ShowLoading(button, true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.id = activeElement.data('id');
        formData.status = status;

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/setStatus',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          Onkho.LoadingTools.HideLoading(button);

                          switch (data.status)
                          {
                              case 200:
                                  ApplyStatus(activeElement, data.responseJSON.job_status, data.responseJSON.status_class);
                                  RefreshGroupAction(activeElement, data.responseJSON.activity_feed_group_id);

                                  activeElement.trigger('onkho:activeElement[job].job_status_changed', [formData.id, data.responseJSON.status_class, data.responseJSON.job_status, data.responseJSON.is_overdue]);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var ApplyStatus = function (activeElement, status, statusClass)
    {
        var buttonId = 'none';

        if (status == 'STARTED') { buttonId = 'start'; }
        if (status == 'PAUSED') { buttonId = 'pause'; }
        if (status == 'COMPLETED') { buttonId = 'complete'; }

        var button = activeElement.find('.featured-action[data-id="' + buttonId + '"]');

        // Set appropriate class
        activeElement.removeClass('not-started started paused completed').addClass(statusClass);

        // Hide overdue warning if the job is complete
        if (status == 'COMPLETED')
        {
            activeElement.find('.bottom-section .detail[data-id="overdue"]').slideUp();
        }
        else
        {
            activeElement.find('.bottom-section .detail[data-id="overdue"]').slideDown();
        }

        // Enable all status changing buttons
        activeElement.find('.featured-action').removeClass('disabled');

        // Disable status changing button corresponding to current status
        button.addClass('disabled');
    };

    var RefreshGroupAction = function (activeElement, activityFeedGroupId)
    {
        activeElement.attr('data-activity-feed-group-id', activityFeedGroupId);

        if (activityFeedGroupId) {
            activeElement.find('.featured-action[data-id="group"]').fadeIn(200);
        } else {
            activeElement.find('.featured-action[data-id="group"]').fadeOut(200);
        }
    };

    var RefreshPreconditionsProgress = function (activeElement, completed, total)
    {
        var preconditionsProgress = activeElement.find('.detail[data-id="progress"] .value .preconditions-progress');

        if (completed == total)
        {
            preconditionsProgress.removeClass('label-danger label-warning').addClass('label-success');
        }
        else
        if (completed > 0)
        {
            preconditionsProgress.removeClass('label-danger label-success').addClass('label-warning');
        }
        else
        {
            preconditionsProgress.removeClass('label-warning label-success').addClass('label-danger');
        }

        preconditionsProgress.find('span:first-of-type').html(completed);
        preconditionsProgress.find('span:last-of-type').html(total);
    };

    var RefreshChecklistItemsProgress = function (activeElement, completed, total)
    {
        var checklistItemsProgress = activeElement.find('.detail[data-id="progress"] .value .checklist-items-progress');

        if (completed == total)
        {
            checklistItemsProgress.removeClass('label-danger label-warning').addClass('label-success');
        }
        else
        if (completed > 0)
        {
            checklistItemsProgress.removeClass('label-danger label-success').addClass('label-warning');
        }
        else
        {
            checklistItemsProgress.removeClass('label-warning label-success').addClass('label-danger');
        }

        checklistItemsProgress.find('span:first-of-type').html(completed);
        checklistItemsProgress.find('span:last-of-type').html(total);
    };

    var SetChecklistItemStatus = function (activeElement, checklistItemCheckboxElement)
    {
        var checklistItemElement = checklistItemCheckboxElement.closest('.checklist-item-small');
        checklistItemCheckboxElement.prop('disabled', true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.jobId = activeElement.data('id');

        var status = checklistItemCheckboxElement.is(':checked') ? 1 : 0;
        formData.checklist = [{
            id: checklistItemCheckboxElement.attr('name'),
            status: status
        }];

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/setChecklistItemsStatus',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          checklistItemCheckboxElement.prop('disabled', false);

                          switch (data.status)
                          {
                              case 200:
                                  ApplyStatus(activeElement, data.responseJSON.job_status, data.responseJSON.status_class);

                                  activeElement.trigger('onkho:activeElement[job].job_status_changed', [formData.jobId, data.responseJSON.status_class, data.responseJSON.job_status, data.responseJSON.is_overdue]);

                                  RefreshChecklistItemsProgress(activeElement, data.responseJSON.completed_checklist_items_count, data.responseJSON.total_checklist_items_count);

                                  if (checklistItemElement.hasClass('complete') && status == 0)
                                  {
                                      checklistItemElement.removeClass('complete');
                                  }
                                  else
                                  if (!checklistItemElement.hasClass('complete') && status == 1)
                                  {
                                      checklistItemElement.addClass('complete');
                                  }
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var SetPreconditionStatus = function (activeElement, preconditionCheckboxElement)
    {
        var preconditionElement = preconditionCheckboxElement.closest('.precondition-small');
        preconditionCheckboxElement.prop('disabled', true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.jobId = activeElement.data('id');

        var status = preconditionCheckboxElement.is(':checked') ? 1 : 0;
        formData.preconditions = [{
            id: preconditionCheckboxElement.attr('name'),
            status: status
        }];

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/setPreconditionsStatus',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          preconditionCheckboxElement.prop('disabled', false);

                          switch (data.status)
                          {
                              case 200:
                                  ApplyStatus(activeElement, data.responseJSON.job_status, data.responseJSON.status_class);

                                  activeElement.trigger('onkho:activeElement[job].job_status_changed', [formData.jobId, data.responseJSON.status_class, data.responseJSON.job_status, data.responseJSON.is_overdue]);

                                  RefreshPreconditionsProgress(activeElement, data.responseJSON.completed_preconditions_count, data.responseJSON.total_preconditions_count);

                                  if (preconditionElement.hasClass('complete') && status == 0)
                                  {
                                      preconditionElement.removeClass('complete');
                                  }
                                  else
                                  if (!preconditionElement.hasClass('complete') && status == 1)
                                  {
                                      preconditionElement.addClass('complete');
                                  }
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var PrepareTimetrackingSection = function (activeElement)
    {
        var section = activeElement.find('.sections .section[data-id="TIMETRACKING"]');
        Onkho.OnkhoDurationPicker.Init(section.find('[name="add-time"]'));
        section.find('table td').each(function (index, element) {
            if ($(element).data('id')) {
                $(element).html(Onkho.Formatter.Duration($(element).data('value')));
            }
        });
    };

    var AddTime = function (activeElement)
    {
        var startTimetrackingButton = activeElement.find('.start-timetracking');
        var stopTimetrackingButton = activeElement.find('.stop-timetracking');
        var form = activeElement.find('.section[data-id="TIMETRACKING"] form');

        if (Onkho.Validator.ValidateChildren(form)) {
            Onkho.LoadingTools.ShowLoading(activeElement.find('.section[data-id="TIMETRACKING"] button.add-time'), true);

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.job_id = activeElement.data('id');

            var addTimeInput = form.find('[name="add-time"]');
            formData.seconds = Math.floor(parseFloat(addTimeInput.val()));

            $.ajax(
              {
                  type: 'POST',
                  url: Onkho.Variables.BaseURL + '/timeEntry/quickAdd',
                  data: formData,
                  dataType: 'json',
                  complete: function (data) {
                      Onkho.LoadingTools.HideLoading(activeElement.find('.section[data-id="TIMETRACKING"] button.add-time'), true);

                      switch (data.status) {
                          case 200:
                              Onkho.Alert.SmallBox('success', data.responseJSON.message);

                              startTimetrackingButton.hide();
                              stopTimetrackingButton.show();

                              UpdateTimeWorked(
                                activeElement,
                                data.responseJSON.meSeconds,
                                data.responseJSON.meThisWeekSeconds,
                                data.responseJSON.totalSeconds,
                                data.responseJSON.totalThisWeekSeconds
                              );

                              Onkho.OnkhoDurationPicker.Set(addTimeInput, 0, true, true);
                              break;

                          default:
                              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                              break;
                      }
                  }
              });
        }
    };

    var UpdateTimeWorked = function (activeElement, meSeconds, meThisWeekSeconds, totalSeconds, totalThisWeekSeconds)
    {
        var meSecondsTd = activeElement.find('.section[data-id="TIMETRACKING"] td[data-id="me-seconds"]');
        var meThisWeekSecondsTd = activeElement.find('.section[data-id="TIMETRACKING"] td[data-id="me-this-week-seconds"]');
        var totalSecondsTd = activeElement.find('.section[data-id="TIMETRACKING"] td[data-id="total-seconds"]');
        var totalThisWeekSecondsTd = activeElement.find('.section[data-id="TIMETRACKING"] td[data-id="total-this-week-seconds"]');

        meSecondsTd.data('value', meSeconds);
        meSecondsTd.html(Onkho.Formatter.Duration(meSeconds));
        Onkho.Animations.Flash(meSecondsTd);

        meThisWeekSecondsTd.data('value', meThisWeekSeconds);
        meThisWeekSecondsTd.html(Onkho.Formatter.Duration(meThisWeekSeconds));
        Onkho.Animations.Flash(meThisWeekSecondsTd);

        totalSecondsTd.data('value', totalSeconds);
        totalSecondsTd.html(Onkho.Formatter.Duration(totalSeconds));
        Onkho.Animations.Flash(totalSecondsTd);

        totalThisWeekSecondsTd.data('value', totalThisWeekSeconds);
        totalThisWeekSecondsTd.html(Onkho.Formatter.Duration(totalThisWeekSeconds));
        Onkho.Animations.Flash(totalThisWeekSecondsTd);
    };

    var StartTimetracking = function (activeElement)
    {
        var startTimetrackingButton = activeElement.find('.start-timetracking');
        var stopTimetrackingButton = activeElement.find('.stop-timetracking');

        Onkho.LoadingTools.ShowLoading(startTimetrackingButton, true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.ids = [activeElement.data('id')];

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/job/startTimetracking',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                  Onkho.LoadingTools.HideLoading(startTimetrackingButton, true);

                  switch (data.status) {
                      case 200:
                          startTimetrackingButton.hide();
                          stopTimetrackingButton.show();

                          Onkho.Alert.SmallBox('success', data.responseJSON.message);
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };

    var StopTimetracking = function (activeElement)
    {
        var startTimetrackingButton = activeElement.find('.start-timetracking');
        var stopTimetrackingButton = activeElement.find('.stop-timetracking');

        Onkho.LoadingTools.ShowLoading(stopTimetrackingButton, true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.ids = [activeElement.data('id')];

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/job/stopTimetracking',
              data: formData,
              dataType: 'json',
              complete: function (data) {
                  Onkho.LoadingTools.HideLoading(stopTimetrackingButton, true);

                  switch (data.status) {
                      case 200:
                          startTimetrackingButton.show();
                          stopTimetrackingButton.hide();

                          Onkho.Alert.SmallBox('success', data.responseJSON.message);
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                          break;
                  }
              }
          });
    };




    return {
        Init: Init
    };
}();
