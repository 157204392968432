Onkho.AddEditServicePreconditionsSubpanel = function ()
{
  var defaultInput = null
  var step4Fields = null
  var step4UserInput = null

  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var LegacyInit = function (panel)
  {
    // Reference modal once.
    var modal = panel

    var activityFeedItemBuilder = modal.find('.activity-feed-item-builder');
    var clockPicker = modal.find('input.time');

    // Fields
    step4Fields = {
      precondition_name: modal.find('input[type="text"][name="precondition_name"]'),
      precondition_notification_offset: modal.find('input[type="number"][name="precondition_notification_offset"]'),
      precondition_notification_time_unit: modal.find('select[name="precondition_notification_time_unit"]'),
      precondition_notification_time: modal.find('input[type="text"][name="precondition_notification_time"]'),
      precondition_reminder: modal.find('input[type="checkbox"][name="precondition_reminder"]'),
      precondition_reminder_frequency: modal.find('input[type="number"][name="precondition_reminder_frequency"]'),
      precondition_reminder_time_unit: modal.find('select[name="precondition_reminder_time_unit"]'),
      precondition_reminder_time: modal.find('input[type="text"][name="precondition_reminder_time"]'),
      precondition_notification_activity_feed_item_from_mailbox_id : modal.find('input[name="from_mailbox_id"]'),
      precondition_notification_activity_feed_item_subject: modal.find('.summernote.subject-area'),
      precondition_notification_activity_feed_item_content: modal.find('.summernote.content-area')
    }

    step4UserInput = Object.assign({}, defaultInput);

    Onkho.ActivityFeed.InitializeRecipientsPicker(activityFeedItemBuilder, 'OUTGOING_EMAIL');

    var clearPreconditionName =
      function() {
        step4Fields.precondition_name.val(step4UserInput.precondition_name).change();
        Onkho.Validator.ResetValidation([step4Fields.precondition_name]);
      };

    var clearPreconditionNotification =
      function() {
        step4Fields.precondition_notification_time_unit.val(step4UserInput.precondition_notification_time_unit).change();
        step4Fields.precondition_notification_offset.val(step4UserInput.precondition_notification_offset).change();
        step4Fields.precondition_notification_time.val(step4UserInput.precondition_notification_time).change();
        Onkho.Validator.ResetValidation([step4Fields.precondition_notification_time_unit, step4Fields.precondition_notification_offset, step4Fields.precondition_notification_time]);
      };

    var clearPreconditionReminder =
      function() {
        step4Fields.precondition_reminder.prop('checked', step4UserInput.precondition_reminder).change();
        step4Fields.precondition_reminder_time_unit.val(step4UserInput.precondition_reminder_time_unit).change();
        step4Fields.precondition_reminder_frequency.val(step4UserInput.precondition_reminder_frequency).change();
        step4Fields.precondition_reminder_time.val(step4UserInput.precondition_reminder_time).change();
        Onkho.Validator.ResetValidation([step4Fields.precondition_reminder, step4Fields.precondition_reminder_time_unit, step4Fields.precondition_reminder_frequency, step4Fields.precondition_reminder_time]);
      };

    var clearPreconditionNotificationActivityFeedItemFromMailboxId =
      function() {
        if (step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id) {
          step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('data', {
            id: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id,
            address: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_address,
            practice_name: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_practice_name
          });
        } else {
          step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('val', '');
        }
        Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_from_mailbox_id]);
      };

    var clearPreconditionNotificationActivityFeedItemSubject =
      function() {
        Onkho.Summernote.DestroySummernote(
          step4Fields.precondition_notification_activity_feed_item_subject
        );

        Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_subject]);
      };

    var clearPreconditionNotificationActivityFeedItemContent =
      function() {
        Onkho.Summernote.DestroySummernote(
          step4Fields.precondition_notification_activity_feed_item_content
        );

        Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_content]);
      };

    // This event is responsible for clearing all input.
    modal.on('clearInput',
      function() {
        clearPreconditionName();
        clearPreconditionNotification();
        clearPreconditionReminder();
        clearPreconditionNotificationActivityFeedItemFromMailboxId();
        clearPreconditionNotificationActivityFeedItemSubject();
        clearPreconditionNotificationActivityFeedItemContent();
      }
    );

    // This event is responsible for initialising the summernote WYSIWYG editor.
    modal.on('initSummernote',
      function() {
        Onkho.Summernote.EnableSummernote(
          step4Fields.precondition_notification_activity_feed_item_subject,
          'JOB',
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          'Subject'
        );
        Onkho.Summernote.UpdateSummernote(
          step4Fields.precondition_notification_activity_feed_item_subject, step4UserInput.precondition_notification_activity_feed_item_subject, false
        );

        Onkho.Summernote.EnableSummernote(
          step4Fields.precondition_notification_activity_feed_item_content,
          'JOB'
        );
        Onkho.Summernote.UpdateSummernote(
          step4Fields.precondition_notification_activity_feed_item_content, step4UserInput.precondition_notification_activity_feed_item_content, false
        );
      }
    );

    // Update the display text of each time unit.
    step4Fields.precondition_notification_offset.on('change',
      function() {
        var self = $(this);
        var offsetValue = self.val();

        step4Fields.precondition_notification_time_unit.find('option').each(function() {
          var option = $(this);
          var optionValue = option.val();
          switch (optionValue) {
            case 'DAY'   :
              option.html((offsetValue === '1') ? 'day'   : 'days');
              break;

            case 'WEEK'  :
              option.html((offsetValue === '1') ? 'week'  : 'weeks');
              break;

            case 'MONTH' :
              option.html((offsetValue === '1') ? 'month' : 'months');
              break;

            case 'YEAR'  :
              option.html((offsetValue === '1') ? 'year'  : 'years');
              break;
          }
        });

        updateAvailableReminders();
      }
    );

    // Update the valid range of reminder time units allowed depending on the selected time unit of the initial offset.
    step4Fields.precondition_notification_time_unit.on('change',
      function() {
        var self = $(this);
        var timeUnitValue = self.val();

        switch (timeUnitValue) {
          case 'DAY'   :
            step4Fields.precondition_reminder_time_unit.empty().append(
              '<option value="DAY">day</option>'
            ).val('DAY').change();
            break;

          case 'WEEK'  :
            step4Fields.precondition_reminder_time_unit.empty().append(
              '<option value="DAY">day</option>'
            ).val('DAY').change();
            break;

          case 'MONTH' :
            step4Fields.precondition_reminder_time_unit.empty().append(
              '<option value="DAY">day</option>'     +
              '<option value="WEEK">week</option>'
            ).val('WEEK').change();
            break;

          case 'YEAR'  :
            step4Fields.precondition_reminder_time_unit.empty().append(
              '<option value="DAY">day</option>'     +
              '<option value="WEEK">week</option>'   +
              '<option value="MONTH">month</option>'
            ).val('MONTH').change();
            break;
        }

        updateAvailableReminders();
      }
    );

    // Only display the reminder schedule if the user opts for a recurring reminder.
    step4Fields.precondition_reminder.on('change',
      function() {
        var self = $(this);
        var shouldRemind = self.is(':checked');

        if (shouldRemind) {
          modal.find('section.precondition-reminder').show();
        } else {
          modal.find('section.precondition-reminder').hide();
        }
      }
    );

    // Update the display text of each time unit.
    step4Fields.precondition_reminder_frequency.on('change',
      function() {
        var self = $(this);
        var offsetValue = self.val();

        step4Fields.precondition_reminder_time_unit.find('option').each(function() {
          var option = $(this);
          var optionValue = option.val();
          switch (optionValue) {
            case 'DAY'   :
              option.html((offsetValue === '1') ? 'day'   : 'days');
              break;

            case 'WEEK'  :
              option.html((offsetValue === '1') ? 'week'  : 'weeks');
              break;

            case 'MONTH' :
              option.html((offsetValue === '1') ? 'month' : 'months');
              break;

            case 'YEAR'  :
              option.html((offsetValue === '1') ? 'year'  : 'years');
              break;
          }
        });
      }
    );

    // Update the valid frequency range allowed depending on the time unit selected.
    step4Fields.precondition_reminder_time_unit.on('change',
      function() {
        updateAvailableReminders();
      }
    );

    var updateAvailableReminders = function() {
      // The current notification offset and time-unit.
      var notificationTimeUnit = step4Fields.precondition_notification_time_unit.val();
      var notificationOffset   = step4Fields.precondition_notification_offset.val();

      // The current reminder offset and time-unit.
      var reminderTimeUnit     = step4Fields.precondition_reminder_time_unit.val();
      var reminderFrequency    = step4Fields.precondition_reminder_frequency.val();

      var max = 1;
      var min = 1;

      var maxDays   = 1;
      var maxWeeks  = 1;
      var maxMonths = 1;
      var maxYears  = 1;

      if ($.isNumeric(notificationOffset)) {
        notificationOffset = parseInt(notificationOffset);
        switch (notificationTimeUnit) {
          case 'DAY'   :
            maxDays   = Math.max(min, (notificationOffset -   1));
            break;

          case 'WEEK'  :
            maxDays   = Math.max(min, (notificationOffset *   7) - 1);
            maxWeeks  = Math.max(min, (notificationOffset -   1));
            break;

          case 'MONTH' :
            maxDays   = Math.max(min, (notificationOffset *  30) - 1);
            maxWeeks  = Math.max(min, (notificationOffset *   4) - 1);
            break;

          case 'YEAR'  :
            maxDays   = Math.max(min, (notificationOffset * 365) - 1);
            maxWeeks  = Math.max(min, (notificationOffset *  52) - 1);
            maxMonths = Math.max(min, (notificationOffset *  12) - 1);
            maxYears  = Math.max(min, (notificationOffset -   1));
            break;
        }
      }

      switch (reminderTimeUnit) {
        case 'DAY'   :
          step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxDays));
          break;

        case 'WEEK'  :
          step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxWeeks));
          break;

        case 'MONTH' :
          step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxMonths));
          break;

        case 'YEAR'  :
          step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxYears));
          break;
      }

      if ($.isNumeric(reminderFrequency) && parseInt(reminderFrequency) > max) {
        step4Fields.precondition_reminder_frequency.val(max).change();
      }

      if ($.isNumeric(reminderFrequency) && parseInt(reminderFrequency) < min) {
        step4Fields.precondition_reminder_frequency.val(min).change();
      }
    };

    clockPicker.clockpicker({
      placement: 'bottom',
      align:     'left',
      autoclose: true
    });

    // Manually trigger the 'clearInput' & 'initSummernote' events the first time
    modal.trigger('clearInput').trigger('initSummernote');
    step4UserInput = Object.assign({}, defaultInput);
  }

  var updateStep4UserInput = function(step4UserInput, defaultInput) {
    // The values pertaining to the name of the requirement.
    var requirementName               = step4Fields.precondition_name.val();

    // The values pertaining to the notification of the requirement.
    var notificationTimeUnit          = step4Fields.precondition_notification_time_unit.val();
    var notificationOffset            = step4Fields.precondition_notification_offset.val();
    var notificationTime              = step4Fields.precondition_notification_time.val();

    // The values pertaining to the reminder of the requirement.
    var notificationReminder          = step4Fields.precondition_reminder.is(':checked');
    var notificationReminderTimeUnit  = step4Fields.precondition_reminder_time_unit.val();
    var notificationReminderFrequency = step4Fields.precondition_reminder_frequency.val();
    var notificationReminderTime      = step4Fields.precondition_reminder_time.val();

    // The values pertaining to the message of the requirement.
    var fromMailboxIdValue = step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('data');
    var notificationActivityFeedItemFromMailboxId = fromMailboxIdValue === null ? null : fromMailboxIdValue.id;
    var notificationActivityFeedItemFromMailboxAddress = fromMailboxIdValue === null ? null : fromMailboxIdValue.address;
    var notificationActivityFeedItemFromMailboxPracticeName = fromMailboxIdValue === null ? null : fromMailboxIdValue.practice_name;
    var notificationActivityFeedItemSubject = Onkho.Summernote.GetSummernoteValue(step4Fields.precondition_notification_activity_feed_item_subject);
    var notificationActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step4Fields.precondition_notification_activity_feed_item_content);

    step4UserInput.precondition_name = requirementName
    step4UserInput.precondition_notification_offset = notificationOffset
    step4UserInput.precondition_notification_time_unit = notificationTimeUnit
    step4UserInput.precondition_notification_time = notificationTime
    step4UserInput.precondition_reminder = notificationReminder
    step4UserInput.precondition_reminder_frequency = notificationReminderFrequency
    step4UserInput.precondition_reminder_time_unit = notificationReminderTimeUnit
    step4UserInput.precondition_reminder_time = notificationReminderTime
    step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id = notificationActivityFeedItemFromMailboxId
    step4UserInput.precondition_notification_activity_feed_item_from_mailbox_address = notificationActivityFeedItemFromMailboxAddress
    step4UserInput.precondition_notification_activity_feed_item_from_mailbox_practice_name = notificationActivityFeedItemFromMailboxPracticeName
    step4UserInput.precondition_notification_activity_feed_item_subject = notificationActivityFeedItemSubject
    step4UserInput.precondition_notification_activity_feed_item_content = notificationActivityFeedItemContent
    step4UserInput.ordinal = defaultInput.ordinal;
  };

  var toBoolean = function(val) {
    if (typeof val === 'boolean') {
      return val;
    }
    if (typeof val === 'string') {
      return val === 'true';
    }
    return Boolean(val);
  };

  var trimSeconds = function(val) {
    try {
      var newVal = moment('2000-01-01 ' + val);
      return newVal.format('HH:mm');
    } catch (e) {
      console.log(e);
      return val;
    }
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_service_preconditions"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditServicePreconditionsSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    };

    Onkho.OnkhoPanel.Add('add_edit_service_preconditions', additionalData, LoadAddEditServicePreconditionsSubpanelLoaded)
  };

  var LoadAddEditServicePreconditionsSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)

    $('[rel=tooltip]').tooltip('hide')

    // Load configuration
    defaultInput = panel.data('configuration')
    defaultInput.precondition_reminder = toBoolean(defaultInput.precondition_reminder)
    defaultInput.precondition_notification_time = trimSeconds(defaultInput.precondition_notification_time)
    defaultInput.precondition_reminder_time = trimSeconds(defaultInput.precondition_reminder_time)

    // Init method formerly used by Onkho-RegisterServicePageTools
    LegacyInit(panel)
  };

  var RegisterAddEditServicePreconditionsSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_service"]'))
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SavePreconditions(panel);
    });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_service_preconditions].added', '.o-panel-container', function ()
    {
      var AddEditServicesPreconditionsSubpanel = $('.o-panel[data-panel-key="add_edit_service_preconditions"]');
      RegisterAddEditServicePreconditionsSubpanelBindings(AddEditServicesPreconditionsSubpanel);
    });
  };

  var SavePreconditions = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      updateStep4UserInput(step4UserInput, defaultInput);
      var AddEditServicesPanel = $('.o-panel[data-panel-key="add_edit_service"]')
      var configuration = GatherConfiguration(panel)
      Onkho.AddEditServicePanel.SetPreconditionConfiguration(AddEditServicesPanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = step4UserInput
    return configuration
  }



  return {
    Init: Init,
    LoadAddEditServicePreconditionsSubpanel: LoadAddEditServicePreconditionsSubpanel
  };
}();
