Onkho.TagTools = function () {

    var magicSuggestMap;

    var Init = function () {
        magicSuggestMap = {};
    };

    var ShowModalForContactAndTag =
        function(modal, saveCallback) {
            var magicSelectId    = 'contactSelectTag';
            var allowFreeEntries = true;
            var entity           = 'Party';
            var tagType          = 'TAG';
            var selectCurrent    = [];
            ShowTagModal(modal, magicSelectId, allowFreeEntries, entity, tagType, selectCurrent, saveCallback);
        }
    ;

    var ShowModalForServiceAndTag =
        function(modal, saveCallback) {
            var magicSelectId    = 'serviceSelectTag';
            var allowFreeEntries = true;
            var entity           = 'Service';
            var tagType          = 'TAG';
            var selectCurrent    = [];
            ShowTagModal(modal, magicSelectId, allowFreeEntries, entity, tagType, selectCurrent, saveCallback);
        }
    ;

    var ShowModalForServiceAndClientType =
        function(modal, services, saveCallback) {
            var magicSelectId    = 'serviceSelectClientType';
            var allowFreeEntries = false;
            var entity           = 'Service';
            var tagType          = 'CLIENT_TYPE';
            var selectCurrent    = [];

            if (services.length === 1) {
                var serviceId = services.data('service-id');
                GetTagsAssigned(entity, serviceId, tagType, function(tags) {
                    selectCurrent = tags;
                    ShowTagModal(modal, magicSelectId, allowFreeEntries, entity, tagType, selectCurrent, saveCallback);
                });
            } else {
                ShowTagModal(modal, magicSelectId, allowFreeEntries, entity, tagType, selectCurrent, saveCallback);
            }
        }
    ;

    var ShowTagModal = function (modal, magicSelectId, allowFreeEntries, entity, tagType, selectCurrent, saveCallback) {
        var tagMagicSuggest = magicSuggestMap[magicSelectId];
        if (tagMagicSuggest === undefined) {
            tagMagicSuggest = magicSuggestMap[magicSelectId] = modal.find('.magicsuggest').magicSuggest({
                id: magicSelectId,
                allowFreeEntries: allowFreeEntries
            });
        }

        Onkho.Variables.tms = tagMagicSuggest;

        modal.find('.btn.save').off().on('click', function () {
            var freeInputTag = tagMagicSuggest.getRawValue().trim();
            if (freeInputTag.length > 0) {
                if (allowFreeEntries) {
                    tagMagicSuggest.addToSelection({id: freeInputTag, name: freeInputTag});
                } else {
                    $.each(tagMagicSuggest.getData(), function(idx, obj) {
                        if (obj.id === freeInputTag) {
                            tagMagicSuggest.addToSelection({id: freeInputTag, name: freeInputTag});
                        }
                    });
                }
            }
            saveCallback(tagType, tagMagicSuggest.getValue());
        });


        GetTagSuggestions(entity, tagType, function(tags) {
            tagMagicSuggest.clear();
            tagMagicSuggest.setData(tags);
            tagMagicSuggest.addToSelection(selectCurrent);
            modal.modal('show');
        });
    };

    var GetTagSuggestions = function (entity, tagType, callback) {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.get(
            Onkho.Variables.BaseURL + '/tags/' + tagType + '/' + entity,
            function(data) {
                callback(data.tags);
            }, 'json'
        ).fail(
            function(jqXHR, textStatus, errorThrown) {
                var data = jqXHR.responseJSON;
                if (data && data.message) {
                    Onkho.Alert.BigBox('danger', 'Error', data.message);
                }
            }
        );
    };

    var GetTagsAssigned = function (entity, entityId, tagType, success) {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.get(
            Onkho.Variables.BaseURL + '/assignedTags/' + tagType + '/' + entity + '/' + entityId,
            function(data) {
                success(data.tags);
            }, 'json'
        ).fail(
            function(jqXHR, textStatus, errorThrown) {
                var data = jqXHR.responseJSON;
                if (data && data.message) {
                    Onkho.Alert.BigBox('danger', 'Error', data.message);
                }
            }
        );
    };

    var UpdateAssigned = function (entity, tagType, tagMagicSuggest, always) {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.get(
            Onkho.Variables.BaseURL + '/assignedTags/' + tagType + '/' + entity + '/' + entityId,
            function(data) {
                tagMagicSuggest.addToSelection(data.tags);
            }, 'json'
        ).fail(
            function(jqXHR, textStatus, errorThrown) {
                var data = jqXHR.responseJSON;
                if (data && data.message) {
                    Onkho.Alert.BigBox('danger', 'Error', data.message);
                }
            }
        ).always(
            always()
        );
    };

    return {
        Init: Init,
        ShowModalForContactAndTag: ShowModalForContactAndTag,
        ShowModalForServiceAndTag: ShowModalForServiceAndTag,
        ShowModalForServiceAndClientType: ShowModalForServiceAndClientType
    };
}();
