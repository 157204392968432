// Module containing tools to be used by the register job modal
Onkho.RegisterJobTools = function () {

    // An optional callback to use when saving a job.
    var callback = null;

    // An optional reference to a client for which to add a job.
    var clientId            = null;

    var quickActions        = null;
    var addJobButton        = null;
    var quickActionsModalsWrapper = null;

    // References the add-job modal.
    var modal = null;
    var form  = null;

    // The form fields.
    var selectClient        = null;
    var selectService       = null;
    var matter              = null;
    var description         = null;
    var dueDate             = null;
    var plannedDeadline     = null;
    var selectAssignedParty = null;

    // Sections
    var sectionDescription  = null;

    var initRegisterJobModal = function() {

        selectClient.select2({
            placeholder: 'Select client',
            sortResults: Onkho.Functions.Select2Sort
        });

        selectService.select2({
            placeholder: 'Select service',
            sortResults: Onkho.Functions.Select2Sort
        });

        selectAssignedParty.select2({
            placeholder: 'No one',
            sortResults: Onkho.Functions.Select2Sort
        });

        var buttonUpdateJobDescription = sectionDescription.find('button[name="update-job-description"]');
        var buttonCancelJobDescription = sectionDescription.find('button[name="cancel-job-description"]');

        var cancelJobDescription = function() {

            // Description message
            var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
            var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
            var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

            Onkho.Summernote.DestroySummernote(descriptionSummernote);
            descriptionPreviewWrapper.find('span.job-description')
                .html(decodeURIComponent(description.val()));

            descriptionPreviewWrapper.show();
            descriptionInputWrapper.hide();

            buttonUpdateJobDescription.show();
            buttonCancelJobDescription.hide();
        };

        var commitJobDescription = function(callback) {

            // Description message
            var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
            var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
            var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

            // Wait for request to return...
            Promise.all([
                new Promise(function(resolve, reject) {
                    if (Onkho.Summernote.IsSummernoteEnabled(descriptionSummernote)) {
                        Onkho.Functions.Purify(Onkho.Summernote.GetSummernoteValue(descriptionSummernote),
                            function(data) {
                                Onkho.Summernote.DestroySummernote(descriptionSummernote);
                                description.val(encodeURIComponent(data));
                                descriptionPreviewWrapper.find('span.job-description').html(data);
                                resolve();
                            }
                        );
                    } else {
                        resolve();
                    }
                })
            ]).then(
                function(values) {
                    if (callback && typeof callback === 'function') {
                        callback();
                    }
                }
            );

            descriptionPreviewWrapper.show();
            descriptionInputWrapper.hide();

            buttonUpdateJobDescription.show();
            buttonCancelJobDescription.hide();
        };

        buttonUpdateJobDescription.on('click',
            function() {
                var self = $(this);

                // Description message
                var descriptionPreviewWrapper    = sectionDescription.find('div.job-description-preview');
                var descriptionInputWrapper      = sectionDescription.find('div.job-description-input');
                var descriptionSummernote        = descriptionInputWrapper.find('div.summernote.job-description');

                Onkho.Summernote.EnableSummernote(descriptionSummernote, 'JOB');
                Onkho.Summernote.UpdateSummernote(
                    descriptionSummernote,
                    decodeURIComponent(description.val())
                );

                descriptionPreviewWrapper.hide();
                descriptionInputWrapper.show();

                buttonUpdateJobDescription.hide();
                buttonCancelJobDescription.show();
            }
        );

        buttonCancelJobDescription.on('click',
            function() {
                cancelJobDescription();
            }
        );

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                Onkho.Validator.ResetValidation(
                    [selectClient, selectService, matter, description, dueDate, plannedDeadline, selectAssignedParty]
                );

                selectClient
                    .val(clientId).change();

                selectService
                    .val('').change();

                selectAssignedParty
                    .val('').change();

                matter
                    .val('');

                description
                    .val('');

                dueDate
                    .val('');

                plannedDeadline
                    .val('');

                cancelJobDescription();
            }
        );

        // Show the modal.
        addJobButton.on('click', function () {
            Show(Onkho.Variables.DefaultClientId);
        });

        // Reset and hide the modal.
        var cancelButton = modal.find('div.modal-content div.modal-footer button[name="cancel"]');
        cancelButton.click(
            function() {
                modal.trigger('clearInput').modal('hide');
            }
        );

        // Save the job.
        var submitButton = modal.find('div.modal-content div.modal-footer button[name="save"]');
        submitButton.click(
            function() {
                commitJobDescription(
                    function() {
                        Save();
                    }
                );
            }
        );

        selectClient.on('change',
            function() {
                var self = $(this);
                var selectedClient = self.val();
                var jobConfigurationWrapper = modal.find('div.job-configuration-wrapper');

                RefreshClientServices(selectedClient);
                cancelJobDescription();

                matter.val('');
                description.val('');
                dueDate.val('');
                plannedDeadline.val('');
                Onkho.Validator.ResetValidation([matter, description, dueDate, plannedDeadline, selectAssignedParty]);

                if (self.val() && selectService.val()) {
                    jobConfigurationWrapper.filter(':hidden').slideDown();
                } else {
                    jobConfigurationWrapper.slideUp();
                }
            }
        );

        selectService.on('change',
            function() {
                var self = $(this);
                var jobConfigurationWrapper = modal.find('div.job-configuration-wrapper');

                matter.val('').attr('placeholder', self.find('option:selected').text());
                description.val('');
                dueDate.val('');
                plannedDeadline.val('');
                Onkho.Validator.ResetValidation([matter, description, dueDate, plannedDeadline, selectAssignedParty]);

                if (self.val() && selectClient.val()) {
                    jobConfigurationWrapper.filter(':hidden').slideDown();
                } else {
                    jobConfigurationWrapper.slideUp();
                }
            }
        );
    };

    var Init = function () {
        clientId            = '';

        quickActions        = $('.quickactions');
        addJobButton        = quickActions.find('.add-job');
        quickActionsModalsWrapper = $('.quickactions-modals');
        modal               = quickActionsModalsWrapper.find('.modal.add-job');

        form                = modal.find('form');
        selectClient        = form.find('[name="client_id"]');
        selectService       = form.find('[name="service_instance_id"]');
        dueDate             = form.find('[name="due_date"]');
        plannedDeadline     = form.find('[name="planned_deadline"]');
        matter              = form.find('[name="matter"]');
        description         = form.find('[name="description"]');
        selectAssignedParty = form.find('[name="assigned_to_party_id"]');

        sectionDescription  = form.find('section.description');

        initRegisterJobModal();
    };

    var Config = function (config) {
        if (config) {
            callback = config.callback;
        }
    };

    var RefreshClientServices = function (clientId) {
        var formData = {};
        formData._token    = Onkho.Functions.GetCSRF();
        formData.client_id = clientId;

        var populateClientServices = function(data) {
            var currentServiceName = selectService.find('option:selected').text();

            selectService.empty().append(
                '<option></option>'
            );

            $(Object.keys(data.clientServices)).each(function (key, serviceInstanceId) {
                selectService.append(
                    '<option value="' + serviceInstanceId + '">' + data.clientServices[serviceInstanceId] + '</option>');
            });

            // Only change the current selected option if it is no longer available.
            var proposedOption = selectService.find('option').filter(function() { return $(this).text() === currentServiceName });
            if (proposedOption.length > 0) {
                selectService.val(proposedOption.first().val()).change();
            } else {
                selectService.val('').change();
            }
        };

        if (clientId) {
            selectService.select2({
                placeholder: 'Loading services. Please wait...'
            });

            $.post(
                Onkho.Variables.BaseURL + '/subscription/getClientServices',
                formData,
                function(data) {
                    populateClientServices(data);
                }, 'json'
            ).fail(
                function(jqXHR, textStatus, errorThrown) {
                    selectService.empty();
                    Onkho.Alert.BigBox('danger', 'Error', 'Failed to find services eligible for your client. Please try again.');
                }
            ).always(
                function() {
                    if (selectService.find('option').filter('[value]').length > 0) {
                        selectService.select2({
                            placeholder: 'Select service'
                        });
                    } else {
                        selectService.select2({
                            placeholder: 'No services found for this client.'
                        });
                    }
                }
            );
        } else {
            selectService.select2({
                placeholder: 'Select client'
            });
        }
    };

    var Show = function(optionalClientId) {
        clientId = ((optionalClientId) ? optionalClientId : '');
        modal.trigger('clearInput').modal('show');
    };

    var Save = function () {
        var valid = Onkho.Validator.ValidateChildren(form);
        if (valid) {
            var submitButton = modal.find('div.modal-content div.modal-footer button[name="save"]');
            Onkho.LoadingTools.ShowLoading(submitButton);

            var formData = {};
            formData._token               = Onkho.Functions.GetCSRF();
            formData.client_id            = selectClient.val();
            formData.service_instance_id  = selectService.val();
            formData.matter               = matter.val();
            formData.description          = decodeURIComponent(description.val());
            formData.due_date             = dueDate.val();
            formData.planned_deadline     = plannedDeadline.val();
            formData.assigned_to_party_id = selectAssignedParty.val();

            $.post(
                Onkho.Variables.BaseURL + '/job',
                formData,
                function(data) {
                    Onkho.Alert.SmallBox('success', data.message);
                    modal.trigger('clearInput').modal('hide');
                    if (typeof callback && typeof callback === 'function') {
                        callback(data);
                    }
                }, 'json'
            ).fail(
                function(jqXHR, textStatus, errorThrown) {
                    var data = jqXHR.responseJSON;
                    switch(jqXHR.status) {
                        case 400 : {
                            Onkho.Alert.SmallBox('warning', data.message);
                            break;
                        }

                        case 422 : {
                            Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
                            if (data['service_instance_id']) {
                                Onkho.Validator.StyleInvalid(
                                    selectService, data['service_instance_id'][0]
                                );
                            }

                            if (data['client_id']) {
                                Onkho.Validator.StyleInvalid(
                                    selectClient, data['client_id'][0]
                                );
                            }

                            if (data['matter']) {
                                Onkho.Validator.StyleInvalid(
                                    matter, data['matter'][0]
                                );
                            }

                            if (data['description']) {
                                Onkho.Validator.StyleInvalid(
                                    description, data['description'][0]
                                );
                            }

                            if (data['due_date']) {
                                Onkho.Validator.StyleInvalid(
                                    dueDate, data['due_date'][0]
                                );
                            }

                            if (data['planned_deadline']) {
                                Onkho.Validator.StyleInvalid(
                                    plannedDeadline, data['planned_deadline'][0]
                                );
                            }

                            if (data['assigned_to_party_id']) {
                                Onkho.Validator.StyleInvalid(
                                    selectAssignedParty, data['assigned_to_party_id'][0]
                                );
                            }

                            break;
                        }

                        default : {
                            Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to add job', data['message'] ? data['message'] : 'We were unable to process your request.');
                            break;
                        }
                    }
                }
            ).always(
                function() {
                    Onkho.LoadingTools.HideLoading(submitButton);
                }
            );
        } else {
            Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
            if (selectClient.val() === '') {
                Onkho.Validator.StyleInvalid(selectClient,
                    'Select a client to which you would like to add a service.'
                );
            } else if (selectService.val() === '') {
                Onkho.Validator.StyleInvalid(selectService,
                    'Select a service to add to your client.'
                );
            }
        }
    };

    return {
        Init:   Init,
        Config: Config,
        Show:   Show
    };
}();
