Onkho.AddEditTimeEntryPanel = function ()
{
    var Init = function ()
    {
        RegisterPanelBindings();

        RegisterEventListeners();
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('click', '.add-time-entry-panel-trigger', function () {
            LoadAddEditTimeEntryPanel($(this).data('time-entry-id'), $(this).data('job-id'))
        })
    };

    var LoadAddEditTimeEntryPanel = function (timeEntryId, jobId)
    {
        var additionalData = {};

        if (timeEntryId) {
            additionalData.timeEntryId = timeEntryId;
        }

        if (jobId) {
            additionalData.jobId = jobId;
        }

        Onkho.OnkhoPanel.Add('add_edit_time_entry', additionalData, AddEditTimeEntryPanelLoaded)
    };

    var LoadAddEditTimeEntryNotesPanel = function (timeEntryId, jobId)
    {
        var additionalData = {};

        if (timeEntryId) {
            additionalData.timeEntryId = timeEntryId;
        }

        if (jobId) {
            additionalData.jobId = jobId;
        }

        var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');
        Onkho.OnkhoPanel.DisablePanelOpener(addEditTimeEntryPanel);
        Onkho.OnkhoPanel.DisableButtons(addEditTimeEntryPanel);

        Onkho.OnkhoPanel.Add('add_edit_time_entry_notes', additionalData, AddEditTimeEntryNotesPanelLoaded)
    };

    var RegisterPanelBindings = function ()
    {
        // Add/edit time entry
        $('body').on('onkho:panel[add_edit_time_entry].added', '.o-panel-container', function ()
        {
            var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');
            RegisterAddEditTimeEntryPanelBindings(addEditTimeEntryPanel);
        });

        // Add/edit time entry notes
        $('body').on('onkho:panel[add_edit_time_entry_notes].added', '.o-panel-container', function ()
        {
            var addEditTimeEntryNotesPanel = $('.o-panel[data-panel-key="add_edit_time_entry_notes"]');
            RegisterAddEditTimeEntryNotesPanelBindings(addEditTimeEntryNotesPanel);
        });
    };

    var PrepareRemoval = function (panel)
    {
        Onkho.Summernote.DestroySummernote(panel.find('[name="notes"].summernote'));
    };

    var RegisterAddEditTimeEntryPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveTimeEntry();
        });

        panel.find('button[data-id="delete"]:not(:disabled)').on('click', function ()
        {
            ConfirmDeleteTimeEntry();
        });

        panel.find('.panel-opener[data-id="notes"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadAddEditTimeEntryNotesPanel(panel.data('time-entry-id'), panel.data('job-id'));
        });
    };

    var RegisterAddEditTimeEntryNotesPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            PrepareRemoval(panel);
            Onkho.OnkhoPanel.Remove(panel);

            var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');
            Onkho.OnkhoPanel.EnablePanelOpener(addEditTimeEntryPanel);
            Onkho.OnkhoPanel.Enable(addEditTimeEntryPanel);
            Onkho.OnkhoPanel.EnableButtons(addEditTimeEntryPanel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveNotes();
        });
    };

    var AddEditTimeEntryPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.Validator.AddChildren(panel);

        panel.find('.select[name="team_member_party_id"]').select2(
          {
              sortResults: Onkho.Functions.Select2Sort
          });

        panel.find('input[name="client_party_id"]').select2(
          {
              placeholder: 'Find a client',
              minimumInputLength: 0,
              ajax: {
                  url: Onkho.Variables.BaseURL + '/contact/getContactSearchResults',
                  dataType: 'json',
                  quietMillis: 700,
                  data: function (term, page) {
                      return {
                          allowClear: true,
                          searchTerm: term,
                          page: page
                      };
                  },
                  results: function (data, page) {
                      var more = (page * 30) < data.totalCount;
                      return {results: data.items, more: more};
                  }
              },
              formatResult: FormatContactSearchResult,
              formatSelection: FormatContactSearchResultSelection,
              dropdownCssClass: 'bigdrop',
              escapeMarkup: function (m) { return m; }
          });

        panel.find('input[name="client_party_id"]').on('change', function () {
            panel.find('input[name="service_id"]').select2('val', '').trigger('change');
            panel.find('input[name="job_id"]').select2('val', '').trigger('change');
        });

        if (panel.data('client-party-id')) {
            SetClientPicker(panel, panel.data('client-party-id'), panel.data('client-name'));
        }

        panel.find('input[name="service_id"]').select2(
          {
              placeholder: 'Find a service',
              minimumInputLength: 0,
              ajax: {
                  url: Onkho.Variables.BaseURL + '/service/getServiceSearchResults',
                  dataType: 'json',
                  quietMillis: 700,
                  data: function (term, page) {
                      return {
                          allowClear: true,
                          searchTerm: term,
                          contactId: panel.find('input[name="client_party_id"]').val(),
                          servicePicker: 1,
                          page: page
                      };
                  },
                  results: function (data, page) {
                      var more = (page * 30) < data.totalCount;
                      return {results: data.items, more: more};
                  }
              },
              formatResult: FormatServiceSearchResult,
              formatSelection: FormatServiceSearchResultSelection,
              dropdownCssClass: 'bigdrop',
              escapeMarkup: function (m) { return m; }
          });

        panel.find('input[name="service_id"]').on('change', function () {
            panel.find('input[name="job_id"]').select2('val', '').trigger('change');
        });

        if (panel.data('service-id')) {
            SetServicePicker(panel, panel.data('service-id'), panel.data('service-name'));
        }

        panel.find('input[name="job_id"]').select2(
          {
              placeholder: 'Find a job',
              minimumInputLength: 0,
              ajax: {
                  url: Onkho.Variables.BaseURL + '/job/getJobSearchResults',
                  dataType: 'json',
                  quietMillis: 700,
                  data: function (term, page) {
                      return {
                          allowClear: true,
                          searchTerm: term,
                          contactId: panel.find('input[name="client_party_id"]').val(),
                          serviceId: panel.find('input[name="service_id"]').val(),
                          page: page
                      };
                  },
                  results: function (data, page) {
                      var more = (page * 30) < data.totalCount;
                      return {results: data.items, more: more};
                  }
              },
              formatResult: FormatJobSearchResult,
              formatSelection: FormatJobSearchResultSelection,
              dropdownCssClass: 'bigdrop',
              escapeMarkup: function (m) { return m; }
          });

        if (panel.data('job-id')) {
            SetJobPicker(panel, panel.data('job-id'), panel.data('job-name'));
        }

        panel.find('input[name="job_id"]').on('change', function (event) {
            if (event.added) {
                SetClientPicker(panel, event.added.client_id, event.added.client_name);
                SetServicePicker(panel, event.added.service_id, event.added.service_name);
            }
        });

        Onkho.OnkhoDurationPicker.Init(panel.find('input[name="seconds"]'));

        Onkho.FormEnhancements.EnhanceDates();
    };

    var FormatContactSearchResult = function (contact) {
        return contact.html
    }

    var FormatContactSearchResultSelection = function (contact) {
        return contact.name
    }

    var FormatServiceSearchResult = function (service) {
        return service.html
    }

    var FormatServiceSearchResultSelection = function (service) {
        return service.name
    }

    var FormatJobSearchResult = function (job) {
        return job.html
    }

    var FormatJobSearchResultSelection = function (job) {
        return job.name
    }

    var SetClientPicker = function (panel, id, clientName) {
        panel.find('input[name="client_party_id"]').select2('data', {
            id: id,
            name: clientName,
            html: ''
        });
    };

    var SetServicePicker = function (panel, id, serviceName) {
        panel.find('input[name="service_id"]').select2('data', {
            id: id,
            name: serviceName,
            html: ''
        });
    };

    var SetJobPicker = function (panel, id, jobName) {
        panel.find('input[name="job_id"]').select2('data', {
            id: id,
            name: jobName,
            html: ''
        });
    };

    var AddEditTimeEntryNotesPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.Validator.AddChildren(panel);

        SetupAddEditTimeEntryNotesPanel(panel);
    };

    var SetupAddEditTimeEntryNotesPanel = function (panel)
    {
        var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');

        var notes = addEditTimeEntryPanel.find('textarea[name="notes"]').val();

        panel.find('textarea.summernote[name="notes"]').val(notes);

        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="notes"]'));
    };

    var SaveTimeEntry = function ()
    {
        var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');

        if (Onkho.Validator.ValidateChildren(addEditTimeEntryPanel))
        {
            Onkho.LoadingTools.ShowLoading(addEditTimeEntryPanel.find('button[data-id="save"]'), true);

            if (addEditTimeEntryPanel.data('time-entry-id'))
            {
                // Editing time entry

                var formData = {};
                formData._token = Onkho.Functions.GetCSRF();
                formData.timeEntryId = addEditTimeEntryPanel.data('time-entry-id');
                formData.team_member_party_id = addEditTimeEntryPanel.find('[name="team_member_party_id"]').val();
                formData.client_party_id = addEditTimeEntryPanel.find('[name="client_party_id"]').val();
                formData.service_id = addEditTimeEntryPanel.find('[name="service_id"]').val();
                formData.job_id = addEditTimeEntryPanel.find('[name="job_id"]').val();
                formData.is_billable = addEditTimeEntryPanel.find('[name="is_billable"]').val();
                formData.seconds = addEditTimeEntryPanel.find('[name="seconds"]').val();
                formData.date = addEditTimeEntryPanel.find('[name="date"]').val();
                formData.notes = addEditTimeEntryPanel.find('[name="notes"]').val();

                $.ajax(
                  {
                      type: 'POST',
                      url: Onkho.Variables.BaseURL + '/timeEntry/' + formData.timeEntryId,
                      data: formData,
                      dataType: 'json',
                      complete: function (data) {
                          Onkho.LoadingTools.HideLoading(addEditTimeEntryPanel.find('button[data-id="save"]'));

                          switch (data.status) {
                              case 200:
                                  $('.o-panel-container').trigger('onkho:panel[add_edit_time_entry].saved', [formData.timeEntryId]);

                                  Onkho.OnkhoPanel.Remove(addEditTimeEntryPanel);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
                  });
            } else {
                // Adding new time entry

                var formData = {};
                formData._token = Onkho.Functions.GetCSRF();
                formData.team_member_party_id = addEditTimeEntryPanel.find('[name="team_member_party_id"]').val();
                formData.client_party_id = addEditTimeEntryPanel.find('[name="client_party_id"]').val();
                formData.service_id = addEditTimeEntryPanel.find('[name="service_id"]').val();
                formData.job_id = addEditTimeEntryPanel.find('[name="job_id"]').val();
                formData.is_billable = addEditTimeEntryPanel.find('[name="is_billable"]').val();
                formData.seconds = addEditTimeEntryPanel.find('[name="seconds"]').val();
                formData.date = addEditTimeEntryPanel.find('[name="date"]').val();
                formData.notes = addEditTimeEntryPanel.find('[name="notes"]').val();

                $.ajax(
                  {
                      type: 'POST',
                      url: Onkho.Variables.BaseURL + '/timeEntry',
                      data: formData,
                      dataType: 'json',
                      complete: function (data) {
                          Onkho.LoadingTools.HideLoading(addEditTimeEntryPanel.find('button[data-id="save"]'));

                          switch (data.status) {
                              case 200:
                                  $('.o-panel-container').trigger('onkho:panel[add_edit_time_entry].saved');

                                  Onkho.OnkhoPanel.Remove(addEditTimeEntryPanel);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
                  });
            }
        }
    };

    var SaveNotes = function ()
    {
        var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');
        var addEditTimeEntryNotesPanel = $('.o-panel[data-panel-key="add_edit_time_entry_notes"]');

        if (Onkho.Validator.ValidateChildren(addEditTimeEntryNotesPanel))
        {
            Onkho.LoadingTools.ShowLoading(addEditTimeEntryNotesPanel.find('button[data-id="save"]'), true);

            var notes = Onkho.Summernote.GetSummernoteValue(addEditTimeEntryNotesPanel.find('[name="notes"].summernote'));
            addEditTimeEntryPanel.find('.panel-section .notes-section .content').html(notes);
            addEditTimeEntryPanel.find('.panel-section .notes-section textarea[name="notes"]').val(notes);
            if (notes.length > 0)
            {
                addEditTimeEntryPanel.find('.panel-section .notes-section').show();
            }
            else
            {
                addEditTimeEntryPanel.find('.panel-section .notes-section').hide();
            }

            Onkho.OnkhoPanel.EnablePanelOpener(addEditTimeEntryPanel);
            Onkho.OnkhoPanel.Enable(addEditTimeEntryPanel);
            Onkho.OnkhoPanel.EnableButtons(addEditTimeEntryPanel);

            PrepareRemoval(addEditTimeEntryNotesPanel);
            Onkho.OnkhoPanel.Remove(addEditTimeEntryNotesPanel);
        }
    };

    var ConfirmDeleteTimeEntry = function ()
    {
        $.SmartMessageBox(
            {
                title : "Deleting time entry",
                content : "Are you sure you want to delete this time entry?",
                buttons : "[No][Yes]"
            }, function(ButtonPress)
            {
                if (ButtonPress == 'Yes')
                {
                    DeleteTimeEntry();
                }
            }
        );
    };

    var DeleteTimeEntry = function ()
    {
        var addEditTimeEntryPanel = $('.o-panel[data-panel-key="add_edit_time_entry"]');
        Onkho.LoadingTools.ShowLoading(addEditTimeEntryPanel.find('button[data-id="delete"]'), true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.ids = [addEditTimeEntryPanel.data('time-entry-id')];

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/timeEntry/delete',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          Onkho.LoadingTools.HideLoading(addEditTimeEntryPanel.find('button[data-id="save"]'));

                          switch (data.status)
                          {
                              case 200:
                                  $('.o-panel-container').trigger('onkho:panel[add_edit_time_entry].deleted', [formData.ids[0]]);

                                  Onkho.OnkhoPanel.Remove(addEditTimeEntryPanel);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };



    return {
        Init: Init,
        LoadAddEditTimeEntryPanel: LoadAddEditTimeEntryPanel
    };
}();
