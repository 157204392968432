// Module containing tools to be used on the workflow page
Onkho.WorkflowTools = function ()
{
    var Init = function ()
    {
        Onkho.EditJobPageTools.Init();

        Onkho.EditJobPageTools.Config({
            callback: SaveEditedJob
        });

        // Reference date
        $('.due-wrapper .reference-datepicker').on('click', function ()
        {
            $('.due-wrapper input[name="reference-date"]').datepicker('show');
        });

        $('.due-wrapper input[name="reference-date"]').on('change', function ()
        {
            $('.due-wrapper input[name="start-date"]').val(moment($(this).val(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
            RefreshDue();
            RefreshIntervalString($('.due-wrapper'));
        });

        $('.planned-wrapper .reference-datepicker').on('click', function ()
        {
            $('.planned-wrapper input[name="reference-date"]').datepicker('show');
        });

        $('.planned-wrapper input[name="reference-date"]').on('change', function ()
        {
            $('.planned-wrapper input[name="start-date"]').val(moment($(this).val(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
            RefreshPlanned();
            RefreshIntervalString($('.planned-wrapper'));
        });

        $('.assigned-wrapper .reference-datepicker').on('click', function ()
        {
            $('.assigned-wrapper input[name="reference-date"]').datepicker('show');
        });

        $('.assigned-wrapper input[name="reference-date"]').on('change', function ()
        {
            $('.assigned-wrapper input[name="start-date"]').val(moment($(this).val(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
            RefreshAssignments();
            RefreshIntervalString($('.assigned-wrapper'));
        });

        $('.clients-wrapper .reference-datepicker').on('click', function ()
        {
            $('.clients-wrapper input[name="reference-date"]').datepicker('show');
        });

        $('.clients-wrapper input[name="reference-date"]').on('change', function ()
        {
            $('.clients-wrapper input[name="start-date"]').val(moment($(this).val(), 'DD/MM/YYYY').format('YYYY-MM-DD'));
            RefreshClients();
            RefreshIntervalString($('.clients-wrapper'));
        });

        // When filters change, refresh jobs
        $('.due-wrapper select').on('change', function ()
        {
            RefreshDue();
            RefreshIntervalString($('.due-wrapper'));
        });

        $('.planned-wrapper select').on('change', function ()
        {
            RefreshPlanned();
            RefreshIntervalString($('.planned-wrapper'));
        });

        $('.assigned-wrapper select').on('change', function ()
        {
            RefreshAssignments();
            RefreshIntervalString($('.assigned-wrapper'));
        });

        $('.clients-wrapper select').on('change', function ()
        {
            RefreshClients();
            RefreshIntervalString($('.clients-wrapper'));
        });

        // Prev/Next buttons
        $('.due-wrapper a.prev').on('click', function ()
        {
            PrevDueInterval();
        });

        $('.due-wrapper a.next').on('click', function ()
        {
            NextDueInterval();
        });

        $('.planned-wrapper a.prev').on('click', function ()
        {
            PrevPlannedInterval();
        });

        $('.planned-wrapper a.next').on('click', function ()
        {
            NextPlannedInterval();
        });

        $('.assigned-wrapper a.prev').on('click', function ()
        {
            PrevAssignmentsInterval();
        });

        $('.assigned-wrapper a.next').on('click', function ()
        {
            NextAssignmentsInterval();
        });

        $('.clients-wrapper a.prev').on('click', function ()
        {
            PrevClientsInterval();
        });

        $('.clients-wrapper a.next').on('click', function ()
        {
            NextClientsInterval();
        });



        // Initial load
        Refresh();
        RefreshIntervalString($('.due-wrapper'));
        RefreshIntervalString($('.planned-wrapper'));
        RefreshIntervalString($('.assigned-wrapper'));
        RefreshIntervalString($('.clients-wrapper'));

        // Modal
        var planningModal = $('.planning-modal');
        planningModal.modal(
            {
                show: false,
                keyboard: true
            });

        planningModal.find('.select[name="assigned_to_party_id"]').select2({
            placeholder: 'No One',
            sortResults: Onkho.Functions.Select2Sort
        });

        planningModal.find('button.save').on('click', function ()
        {
            SaveJob();
        });

        $('body').on('click', '#panels-layout-edit-modal .save', function (event)
        {
            event.preventDefault();
            EditLayout();
        });

        var EditLayout = function()
        {
            var topPanelSelector = $('#panels-layout-edit-modal select[name="top_panel_item"]').val();
            var bottomPanelSelector = $('#panels-layout-edit-modal select[name="bottom_panel_item"]').val();
            var error = false;
            var errorMessage = '';

            if (topPanelSelector == '' && bottomPanelSelector == '') {
                error = true;
                errorMessage = 'You need to choose at least one panel.'
            } else if (topPanelSelector == bottomPanelSelector) {
                error = true;
                errorMessage = 'You need to choose different panels.'
            }

            if (error) {
                $('#panels-layout-edit-modal select[name="top_panel_item"]').addClass('invalid');
                $('#panels-layout-edit-modal select[name="top_panel_item"]').parent().addClass('state-error');
                $('#panels-layout-edit-modal em[for="top_panel_item"].invalid').text(errorMessage);
                return;
            }
            else {
                $('#panels-layout-edit-modal select[name="top_panel_item"]').removeClass('invalid');
                $('#panels-layout-edit-modal select[name="top_panel_item"]').parent().removeClass('state-error');
                $('#panels-layout-edit-modal em[for="top_panel_item"].invalid').text('');
            }

            var topPanel = $(topPanelSelector);
            var bottomPanel = $(bottomPanelSelector);

            $('#widget-grid').append($('.planned-layout-panel .jarviswidget-layout-panel'));

            $('.top-planned-layout-panel').append($(topPanel));
            $('.bottom-planned-layout-panel').append($(bottomPanel));

            Refresh();//refresh data for new panels
            $('#panels-layout-edit-modal').modal('hide');
            ResizeWidgets();

            try
            {
                topLayoutPanel = JSON.parse(localStorage.getItem('top_layout_panel'));
            }
            catch (error)
            {
                topLayoutPanel = {};
            }

            if (topLayoutPanel === null || topLayoutPanel === undefined)
            {
                topLayoutPanel = {};
            }

            topLayoutPanel[Onkho.Variables.LoggedInAsId] = topPanelSelector;
            localStorage.setItem('top_layout_panel', JSON.stringify(topLayoutPanel));

            try
            {
                bottomLayoutPanel = JSON.parse(localStorage.getItem('bottom_layout_panel'));
            }
            catch (error)
            {
                bottomLayoutPanel = {};
            }

            if (bottomLayoutPanel === null || bottomLayoutPanel === undefined)
            {
                bottomLayoutPanel = {};
            }

            bottomLayoutPanel[Onkho.Variables.LoggedInAsId] = bottomPanelSelector;
            localStorage.setItem('bottom_layout_panel', JSON.stringify(bottomLayoutPanel));
        };

        var InitPanels = function()
        {
            // Attempt to retrieve saved layout
            var topSelector = null;
            try
            {
                topSelector = JSON.parse(localStorage.getItem('top_layout_panel'))[Onkho.Variables.LoggedInAsId];
            }
            catch (error)
            {
                //
            }

            if (topSelector == null || topSelector == undefined)
            {
                // Use default
                topSelector = '#due-jobs-widget';
            }

            var bottomSelector = null;
            try
            {
                bottomSelector = JSON.parse(localStorage.getItem('bottom_layout_panel'))[Onkho.Variables.LoggedInAsId];
            }
            catch (error)
            {
                //
            }

            if (bottomSelector == null || bottomSelector == undefined)
            {
                // Use default
                bottomSelector = '#planned-jobs-widget';
            }

            $('#panels-layout-edit-modal select[name="top_panel_item"]').val(topSelector);
            $('#panels-layout-edit-modal select[name="bottom_panel_item"]').val(bottomSelector);
            EditLayout();
        };
        InitPanels();





        // Planning
        $('body').on('click', '.due-wrapper .plan, .planned-wrapper .plan, .clients-wrapper .plan, .assigned-wrapper .plan', function ()
        {
            // Set flag to false because this isn't a drag operation
            Onkho.Variables.DraggedJob = false;

            // Show modal
            PlanDeadline($(this));
        });

        planningModal.on('hide.bs.modal', function (event)
        {
            // Clear suggested deadline
            Onkho.Variables.DraggedJobSuggestedDeadline = undefined;

            // Refresh all units containing the job
            var jobId = planningModal.find('[name="id"]').val();
            var assignedPartyId = planningModal.find('select[name="assigned_to_party_id"]').val();

            $('.due-wrapper .job[data-job-id="' + jobId + '"]').each(function ()
            {
                RefreshDueUnit($(this).closest('.unit'));
            });



            $('.assigned-wrapper .unit[data-asignee-id="' + assignedPartyId + '"]').each(function ()
            {
                RefreshAssignments();
            });

            $('.clients-wrapper .job[data-job-id="' + jobId + '"]').each(function(){
                RefreshClients();
            });


            //collect units
            var UnitsToUpdate = $('.planned-wrapper .job[data-job-id="' + jobId + '"]').closest('.unit');
            var plannedDeadline = moment(planningModal.find('[name="planned_deadline"]').val());
            if (plannedDeadline !== null)
            {
                var unit = GetPlannedUnitForPlannedDeadline(plannedDeadline);
                UnitsToUpdate = UnitsToUpdate.add(unit);
            }
            UnitsToUpdate.each(function ()
            {
                RefreshPlannedUnit($(this));
            });
        });

        // Drag functionality
        $('body').on('mouseover', '.job', function()
        {
            var job = $(this);

            $(this).draggable(
                {
                    helper: 'clone',
                    snap: '.unit .body',
                    snapMode: 'inner',
                    cursor: 'grabbing',
                    distance: 5,
                    zIndex: 10,
                    start: function (event, draggable)
                    {
                        draggable.helper.prevObject.addClass('ui-state-inactive');
                        Onkho.Variables.DraggedJobWrapper = job.closest('.wrapper');
                        Onkho.Variables.DraggedJobSourceUnit = job.closest('.unit');
                        Onkho.Variables.DraggedJob = job;
                        Onkho.Variables.DraggedJobWidth = job.css('width');
                        Onkho.Variables.DraggedJobHeight = job.css('height');
                    },
                    drag: function (event, draggable)
                    {
                        draggable.helper.css('width', Onkho.Variables.DraggedJobWidth);
                        draggable.helper.css('height', Onkho.Variables.DraggedJobHeight);
                    },
                    stop: function (event, draggable)
                    {
                        draggable.helper.prevObject.removeClass('ui-state-inactive');
                    },
                    revert : function (event)
                    {
                        return !event;
                    }
                });
        });

        // Edit job
        $('body').on('click', '.job-details-container a.job-name', function (event)
        {
            event.preventDefault();
            LoadJobForEdit($(this).closest('.job-details '));
        });

        // Resize widgets
        $('#main').css('padding-bottom', '0px');

        ResizeWidgets();

        $(window).on('resize', function()
        {
            ResizeWidgets();
        });

        $('.jarviswidget > header > .jarviswidget-ctrls > .jarviswidget-toggle-btn').on('click', function ()
        {
            setTimeout(function () { ResizeWidgets(); }, 500);
        });


        // JOBS DUE PANEL
        // All Jobs toggle
        $('input[name="show_all_jobs"]').on('change', function ()
        {
            $('.show-all-due-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.due-wrapper').removeClass('show-unplanned');
                $('.due-wrapper').removeClass('show-unassigned');
                $('.due-wrapper').removeClass('show-cant-start');
                $('#show_unplanned_jobs').prop('checked',false);
                $('#show_unassigned_jobs').prop('checked',false);
                $('#show_jobs_cant_start').prop('checked',false);
            }
            else
            {
                $('.due-wrapper').addClass('show-unplanned');
                $('.due-wrapper').addClass('show-unassigned');
                $('.due-wrapper').addClass('show-cant-start');
                $('#show_unplanned_jobs').prop('checked',true);
                $('#show_unassigned_jobs').prop('checked',true);
                $('#show_jobs_cant_start').prop('checked',true);
            }
        });

        // Unplanned Jobs toggle
        $('input[name="show_unplanned_jobs"]').on('change', function ()
        {
            $('.show-all-due-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.due-wrapper').addClass('show-unplanned');
                $('#show_all_jobs').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start').is(":checked") && !$('#show_unassigned_jobs').is(":checked")) {
                    $('#show_all_jobs').prop('checked', true);
                }

                $('.due-wrapper').removeClass('show-unplanned');
            }
        });

        // Unassigned Jobs toggle
        $('input[name="show_unassigned_jobs"]').on('change', function ()
        {
            $('.show-all-due-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.due-wrapper').addClass('show-unassigned');
                $('#show_all_jobs').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start').is(":checked") && !$('#show_unplanned_jobs').is(":checked")) {
                    $('#show_all_jobs').prop('checked',true);
                }

                $('.due-wrapper').removeClass('show-unassigned');
            }
        });

        // Jobs can't start toggle
        $('input[name="show_jobs_cant_start"]').on('change', function ()
        {
            $('.show-all-due-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.due-wrapper').addClass('show-cant-start');
                $('#show_all_jobs').prop('checked',false);
            }
            else
            {
                if (!$('#show_unassigned_jobs').is(":checked") && !$('#show_unplanned_jobs').is(":checked")) {
                    $('#show_all_jobs').prop('checked',true);
                }

                $('.due-wrapper').removeClass('show-cant-start');
            }
        });

        // JOBS PLANNED PANEL
        // All Jobs toggle
        $('input[name="show_all_jobs_for_planned"]').on('change', function ()
        {
            $('.show-all-planned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.planned-wrapper').removeClass('show-unplanned');
                $('.planned-wrapper').removeClass('show-unassigned');
                $('.planned-wrapper').removeClass('show-cant-start');
                $('#show_unplanned_jobs_for_job_planned').prop('checked',false);
                $('#show_unassigned_jobs_for_job_planned').prop('checked',false);
                $('#show_jobs_cant_start_for_job_planned').prop('checked',false);
            }
            else
            {
                $('.planned-wrapper').addClass('show-unplanned');
                $('.planned-wrapper').addClass('show-unassigned');
                $('.planned-wrapper').addClass('show-cant-start');
                $('#show_unplanned_jobs_for_job_planned').prop('checked',true);
                $('#show_unassigned_jobs_for_job_planned').prop('checked',true);
                $('#show_jobs_cant_start_for_job_planned').prop('checked',true);
            }
        });

        // Unplanned Jobs toggle
        $('input[name="show_unplanned_jobs_for_job_planned"]').on('change', function ()
        {
            $('.show-all-planned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.planned-wrapper').addClass('show-unplanned');
                $('#show_all_jobs_for_planned').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_for_job_planned').is(":checked") && !$('#show_unassigned_jobs_for_job_planned').is(":checked")) {
                    $('#show_all_jobs_for_planned').prop('checked',true);
                }

                $('.planned-wrapper').removeClass('show-unplanned');
            }
        });

        // Unassigned Jobs toggle
        $('input[name="show_unassigned_jobs_for_job_planned"]').on('change', function ()
        {
            $('.show-all-planned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.planned-wrapper').addClass('show-unassigned');
                $('#show_all_jobs_for_planned').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_for_job_planned').is(":checked") && !$('#show_unplanned_jobs_for_job_planned').is(":checked")) {
                    $('#show_all_jobs_for_planned').prop('checked',true);
                }

                $('.planned-wrapper').removeClass('show-unassigned');
            }
        });

        // Jobs can't start toggle
        $('input[name="show_jobs_cant_start_for_job_planned"]').on('change', function ()
        {
            $('.show-all-planned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.planned-wrapper').addClass('show-cant-start');
                $('#show_all_jobs_for_planned').prop('checked',false);
            }
            else
            {
                if (!$('#show_unassigned_jobs_for_job_planned').is(":checked") && !$('#show_unplanned_jobs_for_job_planned').is(":checked")) {
                    $('#show_all_jobs_for_planned').prop('checked',true);
                }

                $('.planned-wrapper').removeClass('show-cant-start');
            }
        });

        // TEAM ALLOCATIONS PANEL
        // Show All Jobs toggle
        $('input[name="show_all_jobs_for_unassigned"]').on('change', function ()
        {
            $('.show-all-unassigned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.assigned-wrapper').removeClass('show-unplanned');
                $('.assigned-wrapper').removeClass('show-unassigned');
                $('.assigned-wrapper').removeClass('show-cant-start');
                $('#show_unplanned_jobs_for_job_unassigned').prop('checked',false);
                $('#show_unassigned_jobs_for_job_unassigned').prop('checked',false);
                $('#show_jobs_cant_start_for_job_unassigned').prop('checked',false);
            }
            else
            {
                $('.assigned-wrapper').addClass('show-unplanned');
                $('.assigned-wrapper').addClass('show-unassigned');
                $('.assigned-wrapper').addClass('show-cant-start');
                $('#show_unplanned_jobs_for_job_unassigned').prop('checked',true);
                $('#show_unassigned_jobs_for_job_unassigned').prop('checked',true);
                $('#show_jobs_cant_start_for_job_unassigned').prop('checked',true);
            }
        });

        // Unplanned Jobs toggle
        $('input[name="show_unplanned_jobs_for_job_unassigned"]').on('change', function ()
        {
            $('.show-all-unassigned-jobs .dropdown').addClass('open')
            if ($(this).is(':checked'))
            {
                $('.assigned-wrapper').addClass('show-unplanned');
                $('#show_all_jobs_for_unassigned').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_for_job_unassigned').is(":checked") && !$('#show_unassigned_jobs_for_job_unassigned').is(":checked")) {
                    $('#show_all_jobs_for_unassigned').prop('checked',true);
                }

                $('.assigned-wrapper').removeClass('show-unplanned');
            }
        });

        // Unassigned Jobs toggle
        $('input[name="show_unassigned_jobs_for_job_unassigned"]').on('change', function ()
        {
            $('.show-all-unassigned-jobs .dropdown').addClass('open')
            if ($(this).is(':checked'))
            {
                $('.assigned-wrapper').addClass('show-unassigned');
                $('#show_all_jobs_for_unassigned').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_for_job_unassigned').is(":checked") && !$('#show_unplanned_jobs_for_job_unassigned').is(":checked")) {
                    $('#show_all_jobs_for_unassigned').prop('checked',true);
                }

                $('.assigned-wrapper').removeClass('show-unassigned');
            }
        });

        // Jobs can't start toggle
        $('input[name="show_jobs_cant_start_for_job_unassigned"]').on('change', function ()
        {
            $('.show-all-unassigned-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.assigned-wrapper').addClass('show-cant-start');
                $('#show_all_jobs_for_unassigned').prop('checked',false);
            }
            else
            {
                if (!$('#show_unassigned_jobs_for_job_unassigned').is(":checked") && !$('#show_unplanned_jobs_for_job_unassigned').is(":checked")) {
                    $('#show_all_jobs_for_unassigned').prop('checked',true);
                }

                $('.assigned-wrapper').removeClass('show-cant-start');
            }
        });

        // CLIENTS PANEL
        // Show All Jobs toggle
        $('input[name="show_all_jobs_for_jobs_cant_start"]').on('change', function ()
        {
            $('.show-all-cant-start-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.clients-wrapper').removeClass('show-unplanned');
                $('.clients-wrapper').removeClass('show-unassigned');
                $('.clients-wrapper').removeClass('show-cant-start');
                $('#show_unplanned_jobs_for_jobs_cant_start').prop('checked',false);
                $('#show_unassigned_jobs_for_jobs_cant_start').prop('checked',false);
                $('#show_jobs_cant_start_actual').prop('checked',false);
            }
            else
            {
                $('.clients-wrapper').addClass('show-unplanned');
                $('.clients-wrapper').addClass('show-unassigned');
                $('.clients-wrapper').addClass('show-cant-start');
                $('#show_unplanned_jobs_for_jobs_cant_start').prop('checked',true);
                $('#show_unassigned_jobs_for_jobs_cant_start').prop('checked',true);
                $('#show_jobs_cant_start_actual').prop('checked',true);
            }
        });

        // Unplanned Jobs toggle
        $('input[name="show_unplanned_jobs_for_jobs_cant_start"]').on('change', function ()
        {
            $('.show-all-cant-start-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.clients-wrapper').addClass('show-unplanned');
                $('#show_all_jobs_for_jobs_cant_start').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_actual').is(":checked") && !$('#show_unassigned_jobs_for_jobs_cant_start').is(":checked")) {
                    $('#show_all_jobs_for_jobs_cant_start').prop('checked',true);
                }

                $('.clients-wrapper').removeClass('show-unplanned');
            }
        });

        // Unassigned Jobs toggle
        $('input[name="show_unassigned_jobs_for_jobs_cant_start"]').on('change', function ()
        {
            $('.show-all-cant-start-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.clients-wrapper').addClass('show-unassigned');
                $('#show_all_jobs_for_jobs_cant_start').prop('checked',false);
            }
            else
            {
                if (!$('#show_jobs_cant_start_actual').is(":checked") && !$('#show_unplanned_jobs_for_jobs_cant_start').is(":checked")) {
                    $('#show_all_jobs_for_jobs_cant_start').prop('checked',true);
                }

                $('.clients-wrapper').removeClass('show-unassigned');
            }
        });

        // Jobs can't start toggle
        $('input[name="show_jobs_cant_start_actual"]').on('change', function ()
        {
            $('.show-all-cant-start-jobs .dropdown').addClass('open');
            if ($(this).is(':checked'))
            {
                $('.clients-wrapper').addClass('show-cant-start');
                $('#show_all_jobs_for_jobs_cant_start').prop('checked',false);
            }
            else
            {
                if (!$('#show_unassigned_jobs_for_jobs_cant_start').is(":checked") && !$('#show_unplanned_jobs_for_jobs_cant_start').is(":checked")) {
                    $('#show_all_jobs_for_jobs_cant_start').prop('checked',true);
                }

                $('.clients-wrapper').removeClass('show-cant-start');
            }
        });



        // Job counts
        RefreshOverdueJobCount();
        RefreshStartedJobCount();
    };

    var RefreshOverdueJobCount = function ()
    {
        $('#sparks .overdue-job-count').html('<i class="fa fa-spinner fa-spin"></i>');

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/overdueJobCount',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            $('#sparks .overdue-job-count').html(data.responseJSON.jobs);
                            break;

                        default:
                            $('#sparks .overdue-job-count').html('?');
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var RefreshStartedJobCount = function ()
    {
        $('#sparks .started-job-count').html('<i class="fa fa-spinner fa-spin"></i>');

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/startedJobCount',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            $('#sparks .started-job-count').html(data.responseJSON.jobs);
                            break;

                        default:
                            $('#sparks .started-job-count').html('?');
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var SetJobStatus = function (button, status)
    {
        var JobDetailsContainer = $(button).closest('.job-details-container');
        var jobDetails          = JobDetailsContainer.find('.job-details');

        Onkho.LoadingTools.ShowLoading(button, true);

        var buttonBar = $(button).closest('.buttons-bar');
        Onkho.Variables.Temp = buttonBar.html();

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.id     = jobDetails.data('job-id');
        formData.status = status;

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/setStatus',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            Onkho.Alert.SmallBox('success', data.responseJSON.message);

                            Onkho.LoadingTools.HideLoading(button);
                            UpdateJobDetailsStatus(jobDetails, data.responseJSON.job_status, data.responseJSON.is_overdue);

                            var classes = data.responseJSON.status_class;
                            if (data.responseJSON.status_class !== 'completed')
                            {
                                classes += data.responseJSON.is_overdue ? ' overdue' : '';
                            }

                            $('.job.jarviswidget[data-job-id="' + formData.id + '"]').each(function ()
                            {
                                UpdateJobStatus($(this), classes);
                            });

                            RefreshOverdueJobCount();
                            RefreshStartedJobCount();

                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var UpdateJobStatus = function (job, classes)
    {
        var notStartedJobCount = parseInt(job.closest('.unit').find('.not-started-job-count').html().trim());
        var startedJobCount    = parseInt(job.closest('.unit').find('.started-job-count').html().trim());
        var pausedJobCount     = parseInt(job.closest('.unit').find('.paused-job-count').html().trim());
        var completedJobCount  = parseInt(job.closest('.unit').find('.completed-job-count').html().trim());
        var overdueJobCount    = parseInt(job.closest('.unit').find('.overdue-job-count').html().trim());

        // Decrement old status
        if (job.attr('class').search('not-started') != -1)
        {
            notStartedJobCount--;
        }
        else
        if (job.attr('class').search('started') != -1)
        {
            startedJobCount--;
        }
        else
        if (job.attr('class').search('paused') != -1)
        {
            pausedJobCount--;
        }
        else
        if (job.attr('class').search('completed') != -1)
        {
            completedJobCount--;
        }

        if (job.attr('class').search('overdue') != -1)
        {
            overdueJobCount--;
        }

        // Increment new status
        if (classes.search('not-started') != -1)
        {
            notStartedJobCount++;
        }
        else
        if (classes.search('started') != -1)
        {
            startedJobCount++;
        }
        else
        if (classes.search('paused') != -1)
        {
            pausedJobCount++;
        }
        else
        if (classes.search('completed') != -1)
        {
            completedJobCount++;
        }

        if (classes.search('overdue') != -1)
        {
            overdueJobCount++;
        }

        job.find('.widget-body .job-status').html(job.data('status'));
        job.removeClass('not-started started paused completed overdue').addClass(classes);

        // Refresh counts
        job.closest('.unit').find('.not-started-job-count').html(notStartedJobCount);
        job.closest('.unit').find('.started-job-count').html(startedJobCount);
        job.closest('.unit').find('.paused-job-count').html(pausedJobCount);
        job.closest('.unit').find('.completed-job-count').html(completedJobCount);
        job.closest('.unit').find('.overdue-job-count').html(overdueJobCount);

        RefreshOverdueJobCount();
        RefreshStartedJobCount();
    };

    var UpdateJobDetailsStatus =
        function(jobDetails, status, isOverdue) {
            if (status === 'STARTED') {
                jobDetails.find('li[role="content"]')
                    .css({'background-color': ((isOverdue) ? '#f2dede' : '#d2dee9')});
                jobDetails.find('.buttons-bar .action-button').prop('disabled', false)
                    .filter('.start')
                    .prop('disabled', true);
            } else if (status === 'PAUSED') {
                jobDetails.find('li[role="content"]')
                    .css({'background-color': ((isOverdue) ? '#f2dede' : '#fcf8e3')});
                jobDetails.find('.buttons-bar .action-button').prop('disabled', false)
                    .filter('.pause')
                    .prop('disabled', true);
            } else if (status === 'COMPLETED') {
                jobDetails.find('li[role="content"]')
                    .css({'background-color': '#dff0d8'});
                jobDetails.find('.buttons-bar .action-button').prop('disabled', false)
                    .filter('.complete')
                    .prop('disabled', true);
            } else {
                jobDetails.find('li[role="content"]')
                    .css({'background-color': '#f2f2f2'});
                jobDetails.find('.buttons-bar .action-button')
                    .prop('disabled', false);
            }

            jobDetails.find('.buttons-bar .action-button').each(
                function() {
                    var button = $(this);

                    if (button.is(':disabled')) {
                        button.find('.fa').hide();

                        if (button.hasClass('start')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('Started');
                        }
                        if (button.hasClass('pause')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('Paused');
                        }
                        if (button.hasClass('complete')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('Completed');
                        }
                    } else {
                        button.find('.fa').show();

                        if (button.hasClass('start')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('&nbsp;Start');
                        }
                        if (button.hasClass('pause')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('&nbsp;Pause');
                        }
                        if (button.hasClass('complete')) {
                            button.contents().filter(
                                function() {
                                    return this.nodeType === 3; //Node.TEXT_NODE
                                }
                            ).last().replaceWith('&nbsp;Complete');
                        }

                    }

                }
            );
        };

    var MakeUnitsDroppable = function ()
    {
        $('.planned-wrapper .unit').each(function ()
        {
            $(this).droppable(
                {
                    accept: function (draggable)
                    {
                        // Only accept jobs with a due date past the beginning of the unit
                        if (draggable.hasClass('job'))
                        {
                            var dueDate       = moment(draggable.data('due-date'));
                            var unitStartDate = moment($(this).data('date'));
                            var unitEndDate   = moment($(this).data('end-date'));

                            Onkho.Variables.DraggedJobSuggestedDeadline = unitEndDate;

                            return true;
                        }
                    },
                    hoverClass: 'ui-state-active',
                    drop: function (event, ui)
                    {
                        Onkho.Variables.DraggedJobTargetUnit = $(this);

                        // Show modal
                        PlanDeadline(Onkho.Variables.DraggedJob);

                        // Set planned deadline
                        $('.planning-modal input[name="planned_deadline"]').val(Onkho.Variables.DraggedJobSuggestedDeadline.format('DD/MM/YYYY'));

                        if (Onkho.Variables.DraggedJobWrapper.hasClass('due-wrapper'))
                        {
                            // This was a job that was dragged from the due jobs
                            $(this).find('.body').append($(ui.draggable).clone());
                        }
                        else
                        {
                            // This was a job that was dragged from the planned jobs

                            // Reset position
                            $(ui.draggable).css('left', '');
                            $(ui.draggable).css('top', '');
                            $(ui.draggable).css('width', '');
                            $(ui.draggable).css('height', '');

                            $(this).find('.body').append($(ui.draggable).clone());
                        }
                    }
                });
        });
    };

    var PlanDeadline = function(button)
    {
        var job = $(button).closest('.job');

        // TODO: Turning off async is deprecated, needs refactoring.
        LoadJob(job, false, false);

        var jobId = job.data('job-id');
        var serviceInstanceId = job.data('service-instance-id');

        var jobName = job.data('job-name');
        var jobMatter = job.data('job-matter');
        var jobReason = job.data('job-reason');
        var clientName = job.data('client-name');
        var clientId = job.data('client-id');
        var serviceName = job.data('service-name');
        var serviceId = job.data('service-id');
        var status = job.data('status');
        var assignedToName = job.data('assignedto-name');
        var assignedToId = job.data('assignedto-id');
        var dueDate = job.data('due-date');
        var plannedDeadline = job.data('planned-deadline');

        var planningModal = $('.planning-modal');

        planningModal.find('[name="id"]').val(jobId);
        planningModal.find('[name="matter"]').val(jobMatter);
        planningModal.find('[name="reason"]').val(jobReason);
        planningModal.find('[name="service_instance_id"]').val(serviceInstanceId);
        planningModal.find('[name="due_date"]').val(dueDate);
        planningModal.find('[name="old_planned_deadline"]').val(plannedDeadline);
        planningModal.find('[name="status"][value="'+status+'"]').click();

        planningModal.find('.job-name').html(jobName);
        planningModal.find('.service-name').html(serviceName);
        planningModal.find('.client-name').html('<a href="/party/' + clientId + '" target="_blank"><strong>' + clientName + '</strong></a>');
        planningModal.find('.job-matter').html(jobMatter);

        planningModal.find('[name="due_date"]').val(moment(dueDate).format('DD/MM/YYYY'));
        planningModal.find('[name="planned_deadline"]').val(((plannedDeadline) ? moment(plannedDeadline).format('DD/MM/YYYY') : ''));
        planningModal.find('[name="assigned_to_party_id"]').select2('val', assignedToId);

        planningModal.modal('show');
    };

    var LoadJobForEdit = function (job)
    {
        var jobId = job.data('job-id');
        Onkho.EditJobPageTools.LoadJobForEdit(jobId);
    };

    var SaveEditedJob = function () {
        RefreshOverdueJobCount();
        RefreshStartedJobCount();
        Refresh();
    };

    var SaveJob = function ()
    {
        var planningModal = $('.planning-modal');
        var jobId = planningModal.find('[name="id"]').val();
        if (jobId === null || jobId === undefined || jobId.length === 0)
        {
            jobId = 0;
        }

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.service_instance_id = planningModal.find('[name="service_instance_id"]').val();
        formData.due_date = planningModal.find('[name="due_date"]').val();
        formData.planned_deadline = planningModal.find('[name="planned_deadline"]').val();
        formData.assigned_to_party_id = planningModal.find('[name="assigned_to_party_id"]').select2('val');
        formData.reason = planningModal.find('[name="reason"]').val();
        formData.matter = planningModal.find('[name="matter"]').val();
        formData.status = planningModal.find('.active [name="status"]').val();

        $.ajax(
            {
                type: 'PUT',
                url: Onkho.Variables.BaseURL + '/job/' + jobId,
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            var message = 'Planned job';
                            if (formData.planned_deadline){
                                message += (" for " + moment(formData.planned_deadline, 'DD/MM/YYYY').format('Do MMM YYYY'));
                            }
                            if (formData.assigned_to_party_id) {
                                var assigneeName =
                                    $('.planning-modal select[name="assigned_to_party_id"] option[value="'+formData.assigned_to_party_id+'"]').text();
                                message += (" for " + assigneeName);
                            }
                            Onkho.Alert.SmallBox('success', message);
                            planningModal.modal('hide');

                            RefreshOverdueJobCount();
                            RefreshStartedJobCount();
                            Refresh();
                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            planningModal.find('[name="id"]').val(data.responseJSON.id);
                            break;
                    }
                }
            });
    };

    var Refresh = function ()
    {
        RefreshDue();
        RefreshPlanned();
        RefreshAssignments();
        RefreshClients();
    };

    var SavePlannerView = function (plannerKey)
    {
        if (!Onkho.Variables.WorkflowClientView)
        {
            var wrapper = $('#' + plannerKey + '-widget');

            if (wrapper.length == 0)
            {
                // Invalid plannerKey
                return;
            }

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            formData.plannerKey = plannerKey;

            formData.startDate = $(wrapper).find('input[name="start-date"]').val();
            formData.endDate = $(wrapper).find('input[name="end-date"]').val();

            formData.periodValue = $(wrapper).find('.filters select[name="interval"] option:selected').val();
            formData.periodType = $(wrapper).find('.filters select[name="interval"] option:selected').data('type');

            formData.clientTypes = $(wrapper).find('.filters select[name="clientTypes"]').val();
            formData.allClientTypes = formData.clientTypes.length == $(wrapper).find('.filters select[name="clientTypes"] option').length ? 1 : 0;

            formData.clients = $(wrapper).find('.filters select[name="clients"]').val();
            formData.allClients = formData.clients.length == $(wrapper).find('.filters select[name="clients"] option').length ? 1 : 0;

            formData.services = $(wrapper).find('.filters select[name="services"]').val();
            formData.allServices = formData.services.length == $(wrapper).find('.filters select[name="services"] option').length ? 1 : 0;

            formData.assignedTo = $(wrapper).find('.filters select[name="employees"]').val();
            formData.allAssignedTo = formData.assignedTo.length == $(wrapper).find('.filters select[name="employees"] option').length ? 1 : 0;

            formData.statuses = $(wrapper).find('.filters select[name="statuses"]').val();

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/workflow/savePlannerView',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                          {
                              switch (data.status)
                              {
                                  case 200:
                                      break;

                                  default:
                                      Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                      break;
                              }
                          }
                });
        }
    };

    var RefreshDue = function ()
    {
        if ($('.due-wrapper:visible').length == 0) { return; } // Don't update if the panel isn't visible

        var due = $('.due-wrapper');

        // Clear
        var dueUnitsWrapper = due.find('.units-wrapper');
        dueUnitsWrapper.empty();

        // Get filters
        var interval = due.find('select[name="interval"]').val();
        var intervalType = due.find('select[name="interval"] option:selected').data('type');

        var startDateInput = due.find('input[name="start-date"]');
        var endDateInput = due.find('input[name="end-date"]');

        var period = GetIntervalPeriod(startDateInput.val(), interval, intervalType);
        startDateInput.val(period.startDate.format('YYYY-MM-DD'));
        endDateInput.val(period.endDate.format('YYYY-MM-DD'));

        // Save filters
        SavePlannerView('due-jobs');

        // Regenerate nonce
        var nonceInput = $('.due-wrapper input[name="nonce"]');
        nonceInput.val(Math.floor(Math.random() * 10000 + 1));

        var currentDate = period.startDate;
        // Populate
        for (var i = 0; i < interval; i++)
        {
            AddDueUnit(currentDate, intervalType);
            currentDate.add(1, intervalType);
        }
    };

    var RefreshPlanned = function ()
    {
        if ($('.planned-wrapper:visible').length == 0) { return; } // Don't update if the panel isn't visible

        var planned = $('.planned-wrapper');

        // Clear
        var plannedUnitsWrapper = planned.find('.units-wrapper');
        plannedUnitsWrapper.empty();

        // Get filters
        var interval = planned.find('select[name="interval"]').val();
        var intervalType = planned.find('select[name="interval"] option:selected').data('type');

        var startDateInput = planned.find('input[name="start-date"]');
        var endDateInput = planned.find('input[name="end-date"]');

        var period = GetIntervalPeriod(startDateInput.val(), interval, intervalType);
        startDateInput.val(period.startDate.format('YYYY-MM-DD'));
        endDateInput.val(period.endDate.format('YYYY-MM-DD'));

        // Save filters
        SavePlannerView('planned-jobs');

        // Regenerate nonce
        var nonceInput = $('.planned-wrapper input[name="nonce"]');
        nonceInput.val(Math.floor(Math.random() * 10000 + 1));

        var currentDate = period.startDate;
        // Populate
        for (var i = 0; i < interval; i++)
        {
            AddPlannedUnit(currentDate, intervalType);
            currentDate.add(1, intervalType);
        }

        MakeUnitsDroppable();
    };

    var RefreshClients = function()
    {
        if ($('.clients-wrapper:visible').length == 0) { return; } // Don't update if the panel isn't visible

        var container = $('.clients-wrapper');
        $('.clients-wrapper .unit .body').empty();

        var interval = container.find('select[name="interval"]').val();
        var intervalType = container.find('select[name="interval"] option:selected').data('type');
        var startDateInput = container.find('input[name="start-date"]');
        var endDateInput = container.find('input[name="end-date"]');

        var period = GetIntervalPeriod(startDateInput.val(), interval, intervalType);
        startDateInput.val(period.startDate.format('YYYY-MM-DD'));
        endDateInput.val(period.endDate.format('YYYY-MM-DD'));

        // Save filters
        SavePlannerView('clients-jobs');

        var nonceInput = $(container).find('input[name="nonce"]');
        nonceInput.val(Math.floor(Math.random() * 10000 + 1));

        GetClientsJobs(container, period.startDate, period.endDate).done(function(response)
        {
            if (response.nonce == nonceInput.val())
            {
                response.jobs.forEach(function(job)
                {
                    AddJobToClient(job);
                });
                $(container).find('[data-toggle=tooltip]').tooltip({});

                var selectedClientTypes = $(container).find('.filters select[name="clientTypes"]').val();
                var selectedClients = $(container).find('.filters select[name="clients"]').val();
                $(container).find('.unit[data-client-type]').each(function(idx, el)
                {
                    var clientType = $(el).data('client-type');
                    var clientId = $(el).data('client-id');

                    var notStartedByClient = response.notStartedJobCount[clientId];
                    var startedByClient    = response.startedJobCount[clientId];
                    var pausedByClient     = response.pausedJobCount[clientId];
                    var completedByClient  = response.completedJobCount[clientId];
                    var overdueByClient    = response.overdueJobCount[clientId];
                    var totalByClient      = response.totalJobCount[clientId];

                    $(el).find('.not-started-job-count').html(typeof notStartedByClient == 'undefined' ? 0 : notStartedByClient);
                    $(el).find('.started-job-count').html(typeof startedByClient == 'undefined' ? 0 : startedByClient);
                    $(el).find('.paused-job-count').html(typeof pausedByClient == 'undefined' ? 0 : pausedByClient);
                    $(el).find('.completed-job-count').html(typeof completedByClient == 'undefined' ? 0 : completedByClient);
                    $(el).find('.overdue-job-count').html(typeof overdueByClient == 'undefined' ? 0 : overdueByClient);
                    $(el).find('.total-job-count').html((typeof totalByClient == 'undefined' ? 0 + ' jobs' : totalByClient + ((totalByClient == 1) ? ' job' : ' jobs')));

                    if (selectedClientTypes.indexOf(clientType + '') != -1 && selectedClients.indexOf(clientId + '') != -1)
                    {
                        $(this).show();
                    }
                    else
                    {
                        $(this).hide();
                    }
                });
            }
        });
    };

    var RefreshAssignments = function ()
    {
        if ($('.assigned-wrapper:visible').length == 0) { return; } // Don't update if the panel isn't visible

        var assignedContainer = $('.assigned-wrapper');
        $('.assigned-wrapper .unit .body').empty();

        var interval = assignedContainer.find('select[name="interval"]').val();
        var intervalType = assignedContainer.find('select[name="interval"] option:selected').data('type');
        var startDateInput = assignedContainer.find('input[name="start-date"]');
        var endDateInput = assignedContainer.find('input[name="end-date"]');

        var period = GetIntervalPeriod(startDateInput.val(), interval, intervalType);
        startDateInput.val(period.startDate.format('YYYY-MM-DD'));
        endDateInput.val(period.endDate.format('YYYY-MM-DD'));

        // Save filters
        SavePlannerView('assigned-jobs');

        var nonceInput = $(assignedContainer).find('input[name="nonce"]');
        nonceInput.val(Math.floor(Math.random() * 10000 + 1));

        GetAssignedJobs(assignedContainer, period.startDate, period.endDate).done(function(response)
        {
            if (response.nonce == nonceInput.val())
            {
                response.jobs.forEach(function(job)
                {
                    AddJobToAssignee(job);
                });
                MakeAssigneeUnitsDroppable();

                var selectedEmployees = $(assignedContainer).find('.filters select[name="employees"]').val();
                $(assignedContainer).find('.unit[data-asignee-id]').each(function(idx, el)
                {
                    var assigneeId = $(el).data('asignee-id');

                    var notStartedByAssignee = response.notStartedJobCount[assigneeId];
                    var startedByAssignee    = response.startedJobCount[assigneeId];
                    var pausedByAssignee     = response.pausedJobCount[assigneeId];
                    var completedByAssignee  = response.completedJobCount[assigneeId];
                    var overdueByAssignee    = response.overdueJobCount[assigneeId];
                    var totalByAssignee      = response.totalJobCount[assigneeId];

                    $(el).find('.not-started-job-count').html(typeof notStartedByAssignee == 'undefined' ? 0 : notStartedByAssignee);
                    $(el).find('.started-job-count').html(typeof startedByAssignee == 'undefined' ? 0 : startedByAssignee);
                    $(el).find('.paused-job-count').html(typeof pausedByAssignee == 'undefined' ? 0 : pausedByAssignee);
                    $(el).find('.completed-job-count').html(typeof completedByAssignee == 'undefined' ? 0 : completedByAssignee);
                    $(el).find('.overdue-job-count').html(typeof overdueByAssignee == 'undefined' ? 0 : overdueByAssignee);
                    $(el).find('.total-job-count').html((typeof totalByAssignee == 'undefined' ? 0 + ' jobs' : totalByAssignee + ((totalByAssignee == 1) ? ' job' : ' jobs')));

                    if (selectedEmployees.indexOf(assigneeId + '') === -1)
                    {
                        $(this).hide();
                    }
                    else
                    {
                        $(this).show();
                    }
                });
            }
        });
    };

    var GetJobTemplate = function(job, withAvatar)
    {
        var classes = GetJobStatusClass(job);

        var jobUnit =
            '<div class="jarviswidget job ' + classes + '" data-widget-collapsed="true" role="widget"'+
            ' data-loaded="false"' +
            ' data-job-name="' + job.job_name + '"' +
            ' data-job-clean-name="' + job.job_clean_name + '"' +
            ' data-job-matter="' + job.job_matter + '"' +
            ' data-job-id="' + job.job_id + '"' +
            ' data-service-instance-id="' + job.service_instance_id + '"' +
            ' data-client-name="' + job.client_name + '"' +
            ' data-client-id="' + job.client_id + '"' +
            ' data-service-name="' + job.service_name + '"' +
            ' data-service-id="' + job.service_id + '"' +
            ' data-status="' + job.status + '"' +
            ' data-assignedto-name="' + job.assignedTo_name + '"' +
            ' data-assignedto-id="' + job.assignedTo_id + '"' +
            ' data-due-date="' + job.due_date + '"' +
            ' data-planned-deadline="' + job.planned_deadline + '">' +
            '<header role="heading">' +
            (withAvatar && job.assignedTo_initials ? '<div class="assigned-avatar" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Assigned to '+job.assignedTo_name+'">' + job.assignedTo_initials + '</div>' : '') +

            '<div class="jarviswidget-ctrls" role="menu" style="visibility: visible">' +
            '    <div id="lastcolumn">\n' +
            '        <div class="workflow-toggle">\n' +
            '            <div class="dropdown">' +
            '                <a href="javascript:void(0);" class="btn btn-sm button-icon o-active-element-trigger" data-type="job" data-id="' + job.job_id + '"><i class="fa fa-ellipsis-h"></i></a>'+
            '            </div>\n' +
            '        </div>\n' +
            '        <h2 class="font-md overflow" style="display: block; text-overflow: ellipsis">' + job.job_name + '&nbsp;[' + job.client_name + ']' + '</h2>' +
            '    </div>' +
            '</div>' +


            '</header>' +


            '</div>';

        return jobUnit;
    };

    var AddJobToClient = function(job)
    {
        if (!job.client_id) { return; }

        var clientId = job.client_id;
        var jobUnit = GetJobTemplate(job, true);

        $('div.unit[data-client-id="'+clientId+'"]').find('.body').append(jobUnit);
    };


    var AddJobToAssignee = function(job)
    {
        if (!job.assignedTo_id) { return; }

        var assigneeId = job.assignedTo_id;
        var jobUnit = GetJobTemplate(job, false);

        $('div.unit[data-asignee-id="'+assigneeId+'"]').find('.body').append(jobUnit);
    }

    var MakeAssigneeUnitsDroppable = function()
    {
        $('.assigned-wrapper .unit').each(function ()
        {
            $(this).droppable(
                {
                    accept: function (draggable)
                    {
                        if (draggable.hasClass('job'))
                        {
                            var dragAssignee = $(draggable).data('assignedto-id');
                            var dropAssignee = $(this).data('asignee-id');

                            if (dragAssignee != dropAssignee) { // not to same employee
                                return true;
                            }
                        }
                    },
                    hoverClass: 'ui-state-active',
                    drop: function (event, ui)
                    {
                        var job = ui.draggable;
                        var assignee = $(this).data('asignee-id');

                        // Show modal
                        PlanDeadline(job);

                        $('.planning-modal select[name="assigned_to_party_id"]').select2("val", assignee);
                        var periodEndDate = moment($('.assigned-wrapper input[name="end-date"]').val());
                        var jobEndDate = moment($(job).data('due-date'));

                        if ($('.planning-modal input[name="planned_deadline"]').val() == "") {
                            if (jobEndDate >= periodEndDate) {
                                $('.planning-modal input[name="planned_deadline"]').val(periodEndDate.format('DD/MM/YYYY'));
                            } else {
                                $('.planning-modal input[name="planned_deadline"]').val(jobEndDate.format('DD/MM/YYYY'));
                            }
                        }
                    }
                });
        });
    }

    var GetIntervalPeriod = function(startDate, interval, intervalType)
    {
        // Refresh start and end date
        startDate = moment(startDate);
//        endDate = moment(endDate);
        var endDate = moment(startDate).add(interval - 1, intervalType);

        switch (intervalType)
        {
            case 'days':
                startDate = startDate;
                endDate = endDate;
                break;

            case 'weeks':
                startDate = startDate.isoWeekday(1);
                endDate = endDate.isoWeekday(7);
                break;

            case 'months':
                startDate = startDate.startOf('month');
                endDate = endDate.endOf('month');
                break;

            default:
            //
        }
        return {startDate: startDate, endDate: endDate};
    }

    var RefreshIntervalString = function (wrapper)
    {
        var interval = parseInt($(wrapper).find('select[name="interval"]').val());
        var intervalType = $(wrapper).find('select[name="interval"] option:selected').data('type');

        var startDateInput = $(wrapper).find('input[name="start-date"]');
        var startDate = moment(startDateInput.val());

        var endDateInput = $(wrapper).find('input[name="end-date"]');
        var endDate = moment(endDateInput.val());

        var intervalString;

        switch (intervalType)
        {
            case 'days':
                if (interval == 1)
                {
                    intervalString = startDate.format('dddd, Do MMMM YYYY');
                }
                else
                {
                    intervalString = startDate.format('dddd, Do MMMM YYYY') + ' - ' + endDate.format('dddd, Do MMMM YYYY');
                }
                break;

            case 'weeks':
                intervalString = startDate.format('dddd, Do MMMM YYYY') + ' - ' + endDate.isoWeekday(7).format('dddd, Do MMMM YYYY');
                break;

            case 'months':
                if (interval == 1)
                {
                    intervalString = startDate.format('MMMM YYYY');
                }
                else
                {
                    intervalString = startDate.format('MMMM YYYY') + ' - ' + endDate.format('MMMM YYYY');
                }
                break;

            default:
            //
        }

        $(wrapper).find('.interval-selector .interval').html(intervalString);
    };

    var AddDueUnit = function (date, intervalType)
    {
        var startDate = date;
        var endDate = date;
        var header = '';
        var footer = '';

        switch (intervalType)
        {
            case 'days':
                startDate = moment(date);
                endDate = moment(date);

                header = startDate.format('ddd');
                break;

            case 'weeks':
                startDate = moment(date).isoWeekday(1);
                endDate = moment(date).isoWeekday(7);

                header = startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY');
                break;

            case 'months':
                startDate = moment(date).startOf('month');
                endDate = moment(date).endOf('month');

                header = startDate.format('MMM YYYY');
                break;

            default:
            //
        }

        var today = moment();

        var classNames = '';
        if (startDate <= today && today <= endDate)
        {
            classNames += 'today';
        }

        var unit = '' +
            '<div class="unit ' + classNames + '" data-date="' + startDate.format('YYYY-MM-DD') + '" data-end-date="' + endDate.format('YYYY-MM-DD') + '">' +
            '<div class="header">' +
            '<div class="name">' + header + '</div>' +
            '<div class="total-job-count-wrapper"><span class="total-job-count">?</span></div>' +
            '</div>' +

            '<div class="job-counts-wrapper">' +
            '<div class="not-started-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Not started">?</div>' +
            '<div class="started-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Started">?</div>' +
            '<div class="paused-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Paused">?</div>' +
            '<div class="completed-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Completed">?</div>' +
            '<div class="overdue-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Overdue">?</div>' +
            '</div>' +

            '<div class="body"></div>' +

            '<div class="footer">' +
            '<small>' + footer + '</small>' +
            '</div>' +
            '</div>' +
            '';

        $('.due-wrapper .units-wrapper').append(unit);

        GetDueJobs($('.due-wrapper'), moment(startDate), moment(endDate)).done(function(response)
        {
            if (response.nonce == $('.due-wrapper input[name="nonce"]').val())
            {
                var unit = GetDueUnitForDueDate(startDate);
                unit.find('.not-started-job-count').html(response.notStartedJobCount);
                unit.find('.started-job-count').html(response.startedJobCount);
                unit.find('.paused-job-count').html(response.pausedJobCount);
                unit.find('.completed-job-count').html(response.completedJobCount);
                unit.find('.overdue-job-count').html(response.overdueJobCount);
                unit.find('.total-job-count').html((response.totalJobCount + ((response.totalJobCount == 1) ? ' job' : ' jobs')));

                AddDueJobs(response.jobs);
                $('.due-wrapper').find('[data-toggle=tooltip]').tooltip({});
            }
        });
    };

    var RefreshDueUnit = function (unit)
    {
        if ($('.due-wrapper:visible').length == 0) { return; } //not update if no jobs
        // Clear old jobs
        unit.find('.body').empty();

        var startDate = moment(unit.data('date'));
        var endDate = moment(unit.data('end-date'));

        // Populate jobs
        GetDueJobs($('.due-wrapper'), moment(startDate), moment(endDate)).done(function(response){
            if (response.nonce == $('.due-wrapper input[name="nonce"]').val())
            {
                AddDueJobs(response.jobs);
                $('.due-wrapper').find('[data-toggle=tooltip]').tooltip({});
            }
        });
    };

    var AddDueJobs = function (jobs) {
        jobs.forEach(function (job){
            AddDueJob(job);
        });
    }

    var GetJobStatusClass = function(jobData) {
        var classes = jobData.status_class;
        if (jobData.status_class != 'completed')
        {
            classes += jobData.is_overdue ? ' overdue' : '';
        }
        classes += jobData.is_unplanned ? ' unplanned' : '';
        classes += jobData.is_unassigned ? ' unassigned' : '';
        classes += jobData.cant_start ? ' cant-start' : '';

        return classes;
    }

    var AddDueJob = function (jobData)
    {
        var job = GetJobTemplate(jobData, true);

        var unit = GetDueUnitForDueDate(jobData.due_date);
        unit.find('.body').append(job);
    };

    var GetDueUnitForDueDate = function(dueDate)
    {
        dueDate = moment(dueDate);
        var unit = undefined;
        $('.due-wrapper .unit').each(function ()
        {
            var startDate = moment($(this).data('date'));
            var endDate = moment($(this).data('end-date'));

            if (startDate <= dueDate && dueDate <= endDate)
            {
                unit = $(this);
                return false;
            }
        });

        return unit;
    };

    var LoadJob = function (job, async)
    {
        Onkho.LoadingTools.ShowLoading(job.find('.show-details'));

        if (typeof(async) === 'undefined')
        {
            async = true;
        }

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = $('.due-wrapper input[name="nonce"]').val();

        $.ajax({
            type: 'GET',
            url: Onkho.Variables.BaseURL + '/job/' + job.data('job-id') + '/ajax',
            data: formData,
            async: async,
            dataType: 'json',
            complete: function (data)
            {
                Onkho.LoadingTools.HideLoading(job.find('.show-details'));

                switch (data.status)
                {
                    case 200:
                        var jobs    = $('.job[data-job-id="' + job.data('job-id') + '"]');
                        var jobData = data.responseJSON.jobData;
                        var html    = $(data.responseJSON.html);

                        jobs.each(function ()
                        {
                            $(this).data('loaded', true);

                            $(this).data('job-name', jobData.job_name);
                            $(this).data('job-matter', jobData.job_matter);
                            $(this).data('job-reason', jobData.reason);
                            $(this).data('job-id', jobData.job_id);
                            $(this).data('service-instance-id', jobData.service_instance_id);
                            $(this).data('client-name', jobData.client_name);
                            $(this).data('client-id', jobData.client_id);
                            $(this).data('client-reference', jobData.client_reference);
                            $(this).data('service-name', jobData.service_name);
                            $(this).data('service-id', jobData.service_id);
                            $(this).data('status', jobData.status);
                            $(this).data('assignedto-name', jobData.assignedTo_name);
                            $(this).data('assignedto-id', jobData.assignedTo_id);
                            $(this).data('due-date', moment(jobData.due_date).format('YYYY-MM-DD'));
                            $(this).data('planned-deadline', jobData.planned_deadline == null ? '' : moment(jobData.planned_deadline).format('YYYY-MM-DD'));
                        });

                        break;

                    default:
                        Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                        break;
                }
            }
        });
    };

    var collectDueFormData = function(wrapper)
    {
        var formData = {};

        formData.clientTypes = $(wrapper).find('.filters select[name="clientTypes"]').val();
        formData.clients = $(wrapper).find('.filters select[name="clients"]').val();
        formData.services = $(wrapper).find('.filters select[name="services"]').val();
        formData.statuses = $(wrapper).find('.filters select[name="statuses"]').val();
        formData.nonce = $(wrapper).find('input[name="nonce"]').val();

        var employeesInput = $(wrapper).find('.filters select[name="employees"]');

        var employees = $(employeesInput).val();

        formData.filter_employees = true;
        formData.employees = employees;

        return formData;
    }

    var GetAssignedJobs = function(wrapper, startDate, endDate)
    {
        var formData = collectDueFormData(wrapper);
        formData._token = Onkho.Functions.GetCSRF();
        formData.startDate = startDate.format('YYYY-MM-DD');
        formData.endDate = endDate.format('YYYY-MM-DD');

        return $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/assignedJobs',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          switch (data.status)
                          {
                              case 200:
                                  break;

                              default:
                                  Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                  break;
                          }
                      }
            });
    }

    var GetClientsJobs = function(wrapper, startDate, endDate)
    {
        var formData = collectDueFormData(wrapper);
        formData._token = Onkho.Functions.GetCSRF();
        formData.startDate = startDate.format('YYYY-MM-DD');
        formData.endDate = endDate.format('YYYY-MM-DD');

        return $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/clientsJobs',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          switch (data.status)
                          {
                              case 200:
                                  break;

                              default:
                                  Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                  break;
                          }
                      }
            });
    }

    var GetDueJobs = function (wrapper, startDate, endDate)
    {
        var formData = collectDueFormData(wrapper);
        formData._token = Onkho.Functions.GetCSRF();
        formData.startDate = startDate.format('YYYY-MM-DD');
        formData.endDate = endDate.format('YYYY-MM-DD');

        return $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/dueJobs',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
//                            if (data.responseJSON.nonce == $('.due-wrapper input[name="nonce"]').val())
//                            {
//                                var jobs = data.responseJSON.jobs;
//                                return jobs;//
//                            }
                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var ChangeInterval = function(wrapper, diff) {
        var interval = parseInt($(wrapper).find('select[name="interval"]').val());
        var intervalType = $(wrapper).find('select[name="interval"] option:selected').data('type');
        var startDateInput = $(wrapper).find('input[name="start-date"]');
        var startDate = moment(startDateInput.val());

        var endDateInput = $(wrapper).find('input[name="end-date"]');
        var endDate = moment(endDateInput.val());

        startDate.add(diff, intervalType);
        startDateInput.val(startDate.format('YYYY-MM-DD'));

        endDate = moment(startDate).add(interval - 1, intervalType);
        endDateInput.val(endDate.format('YYYY-MM-DD'));
    }

    var PrevDueInterval = function ()
    {
        ChangeInterval($('.due-wrapper'), -1);

        RefreshDue();
        RefreshIntervalString($('.due-wrapper'));
    };

    var NextDueInterval = function ()
    {
        ChangeInterval($('.due-wrapper'), 1);

        RefreshDue();
        RefreshIntervalString($('.due-wrapper'));
    };

    var PrevAssignmentsInterval = function ()
    {
        ChangeInterval($('.assigned-wrapper'), -1);

        RefreshAssignments();
        RefreshIntervalString($('.assigned-wrapper'));
    };

    var NextAssignmentsInterval = function ()
    {
        ChangeInterval($('.assigned-wrapper'), 1);

        RefreshAssignments();
        RefreshIntervalString($('.assigned-wrapper'));
    };

    var PrevClientsInterval = function ()
    {
        ChangeInterval($('.clients-wrapper'), -1);

        RefreshClients();
        RefreshIntervalString($('.clients-wrapper'));
    };

    var NextClientsInterval = function ()
    {
        ChangeInterval($('.clients-wrapper'), 1);

        RefreshClients();
        RefreshIntervalString($('.clients-wrapper'));
    };

    var AddPlannedUnit = function (date, intervalType)
    {
        var startDate = date;
        var endDate = date;
        var header = '';
        var footer = '';

        switch (intervalType)
        {
            case 'days':
                startDate = moment(date);
                endDate = moment(date);

                header = startDate.format('ddd');
                break;

            case 'weeks':
                startDate = moment(date).isoWeekday(1);
                endDate = moment(date).isoWeekday(7);

                header = startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY');
                break;

            case 'months':
                startDate = moment(date).startOf('month');
                endDate = moment(date).endOf('month');

                header = startDate.format('MMM YYYY');
                break;

            default:
            //
        }

        var today = moment();

        var classNames = '';
        if (startDate <= today && today <= endDate)
        {
            classNames += 'today';
        }

        var unit = '' +
            '<div class="unit ' + classNames + '" data-date="' + startDate.format('YYYY-MM-DD') + '" data-end-date="' + endDate.format('YYYY-MM-DD') + '">' +
            '<div class="header">' +
            '<div class="name">' + header + '</div>' +
            '<div class="total-job-count-wrapper"><span class="total-job-count">?</span></div>' +
            '</div>' +

            '<div class="job-counts-wrapper">' +
            '<div class="not-started-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Not started">?</div>' +
            '<div class="started-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Started">?</div>' +
            '<div class="paused-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Paused">?</div>' +
            '<div class="completed-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Completed">?</div>' +
            '<div class="overdue-job-count" rel="tooltip" data-toggle="tooltip" title="" data-placement="top" data-original-title="Overdue">?</div>' +
            '</div>' +

            '<div class="body"></div>' +

            '<div class="footer">' +
            '<small>' + footer + '</small>' +
            '</div>' +
            '</div>' +
            '';

        $('.planned-wrapper .units-wrapper').append(unit);

        GetPlannedJobs(moment(startDate), moment(endDate));
    };

    var RefreshPlannedUnit = function (unit)
    {
        if ($('.planned-wrapper:visible').length == 0) { return; } //not update if no jobs
        //
        // Clear old jobs
        $(unit).find('.body').empty();

        var startDate = moment(unit.data('date'));
        var endDate = moment(unit.data('end-date'));

        // Populate jobs
        GetPlannedJobs(moment(startDate), moment(endDate));
    };

    var AddPlannedJob = function (jobData)
    {
        var job = GetJobTemplate(jobData, true);

        var unit = GetPlannedUnitForPlannedDeadline(jobData.planned_deadline);
        unit.find('.body').append(job);
    };

    var GetPlannedUnitForPlannedDeadline = function(plannedDeadline)
    {
        plannedDeadline = moment(plannedDeadline);
        var unit = undefined;
        $('.planned-wrapper .unit').each(function ()
        {
            var startDate = moment($(this).data('date'));
            var endDate = moment($(this).data('end-date'));

            if (startDate <= plannedDeadline && plannedDeadline <= endDate)
            {
                unit = $(this);
                return false;
            }
        });

        return unit;
    };

    var GetPlannedJobs = function (startDate, endDate)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.nonce = $('.planned-wrapper input[name="nonce"]').val();
        formData.startDate = startDate.format('YYYY-MM-DD');
        formData.endDate = endDate.format('YYYY-MM-DD');
        formData.clientTypes = $('.planned-wrapper .filters select[name="clientTypes"]').val();
        formData.clients = $('.planned-wrapper .filters select[name="clients"]').val();
        formData.services = $('.planned-wrapper .filters select[name="services"]').val();
        formData.statuses = $('.planned-wrapper .filters select[name="statuses"]').val();

        var employeesInput = $('.planned-wrapper .filters select[name="employees"]');

        var employees = $(employeesInput).val();

        formData.filter_employees = true;
        formData.employees = employees;

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + Onkho.Variables.WorkflowURL + '/plannedJobs',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            if (data.responseJSON.nonce == $('.planned-wrapper input[name="nonce"]').val())
                            {
                                var unit = GetPlannedUnitForPlannedDeadline(startDate);
                                unit.find('.not-started-job-count').html(data.responseJSON.notStartedJobCount);
                                unit.find('.started-job-count').html(data.responseJSON.startedJobCount);
                                unit.find('.paused-job-count').html(data.responseJSON.pausedJobCount);
                                unit.find('.completed-job-count').html(data.responseJSON.completedJobCount);
                                unit.find('.overdue-job-count').html(data.responseJSON.overdueJobCount);
                                unit.find('.total-job-count').html((data.responseJSON.totalJobCount + ((data.responseJSON.totalJobCount == 1) ? ' job' : ' jobs')));

                                var jobs = data.responseJSON.jobs;
                                jobs.forEach(function (job)
                                {
                                    AddPlannedJob(job);
                                });
                                $('.planned-wrapper').find('[data-toggle=tooltip]').tooltip({});
                            }
                            break;

                        default:
                            Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var PrevPlannedInterval = function ()
    {
        ChangeInterval($('.planned-wrapper'), -1);

        RefreshIntervalString($('.planned-wrapper'));
        RefreshPlanned();
    };

    var NextPlannedInterval = function ()
    {
        ChangeInterval($('.planned-wrapper'), 1);

        RefreshIntervalString($('.planned-wrapper'));
        RefreshPlanned();
    };

    var ResizeWidgets = function ()
    {
        var topPanel = $('.top-planned-layout-panel');
        var bottomPanel = $('.bottom-planned-layout-panel');

        if ($(topPanel).children().length == 0 || $(topPanel).find('.jarviswidget-collapsed').length > 0)
        {
            Maximize($(bottomPanel));
        }
        else if ($(bottomPanel).children().length == 0 || $(bottomPanel).find('.jarviswidget-collapsed').length > 0)
        {
            Maximize($(topPanel));
        }
        else
        {
            EqualSizes($(topPanel), $(bottomPanel));
        }

        // Max 6 units per row for Clients & Team Allocations panels
        var minWidth = $('.jarviswidget#clients-jobs-widget .clients-wrapper').width() / 6;
        $('.jarviswidget#clients-jobs-widget .clients-wrapper .unit').css('min-width', minWidth + 'px');

        minWidth = $('.jarviswidget#assigned-jobs-widget .clients-wrapper').width() / 6;
        $('.jarviswidget#clients-jobs-widget .assigned-wrapper .unit').css('min-width', minWidth + 'px');
    };

    var Maximize = function(container)
    {
        var heightAvailable = $(window).height() - 380;

        $('.planned-layout-panel .jarviswidget-layout-panel').height(0);
        $(container).find('.jarviswidget-layout-panel').height(heightAvailable);
    };

    var EqualSizes = function (topContainer, bottomContainer)
    {
        var heightAvailable = $(window).height() - 380;

        $(topContainer).find('.jarviswidget-layout-panel').height(heightAvailable * 0.5);
        $(bottomContainer).find('.jarviswidget-layout-panel').height(heightAvailable * 0.5);
    };

    return {
        Init: Init,
        Refresh: Refresh,
        AddDueUnit: AddDueUnit,
        RefreshDueUnit: RefreshDueUnit,
        AddDueJob: AddDueJob,
        GetDueJobs: GetDueJobs,
        NextDueInterval: NextDueInterval,
        PrevDueInterval: PrevDueInterval,
        AddPlannedUnit: AddPlannedUnit,
        RefreshPlannedUnit: RefreshPlannedUnit,
        AddPlannedJob: AddPlannedJob,
        GetPlannedJobs: GetPlannedJobs,
        NextPlannedInterval: NextPlannedInterval,
        PrevPlannedInterval: PrevPlannedInterval,
        UpdateJobStatus: UpdateJobStatus,
        SaveEditedJob: SaveEditedJob
    };
}();