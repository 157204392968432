// Module containing tools to be used for Summernote boxes
Onkho.Summernote = function () {

    var Init =
        function() {
        }
    ;

    var EnableSummernote = function (div, context, customButtons, height, onKeyupCallback, plainTextToolbar, inlineEditor, placeholder) {
        var summernote;
        if (typeof div === 'undefined') {
            summernote = $('div.summernote');
        } else {
            summernote = $(div);
        }

        if (typeof context === 'undefined') {
            context = ['CONTACT', 'PRACTICE', 'USER', 'GENERAL'];
        } else if (Array.isArray(context)) {
            context = ['CONTACT', 'PRACTICE', 'USER', 'GENERAL'].concat(context);
        } else {
            context = ['CONTACT', 'PRACTICE', 'USER', 'GENERAL'].concat([context]);
        }

        // Is Summernote already initialised?
        if (IsSummernoteEnabled(summernote)) {
            return summernote;
        }

        var chooseTemplateButton = function (summernoteContext) {
            var ui = $.summernote.ui;

            var button = ui.button({
                contents: 'Choose Template',
                click: function () {
                    var modalName = 'select_message_template';

                    Onkho.DynamicContentTools.LoadModal(modalName,
                        function() {
                            var form = $(this).find('.add-message-template-form');
                            var modal = form.closest('.modal');

                            var selectTemplate = form.find('select[name="template"]');
                            var currentContent = null;

                            selectTemplate.select2({
                                placeholder: 'Template...',
                                sortResults: Onkho.Functions.Select2Sort
                            });

                            var updateTemplates =
                                function() {
                                    $.ajax({
                                        url: Onkho.Variables.BaseURL + "/template/getTemplateNames",
                                        type: "GET",
                                        dataType: "json"
                                    }).done(
                                        function(data) {
                                            var optionText = "";
                                            for (var i = 0; i < data.templates.length; i++) {
                                                var template = data.templates[i];
                                                optionText += '<option value="' + template.id + '"">' + template.name + '</option>';
                                            }
                                            selectTemplate.html(optionText).change();
                                        }
                                    ).fail(
                                        function(jqXHR) {
                                            var data = jqXHR.responseJSON;
                                            switch (jqXHR.status) {
                                                case 400 : {
                                                    Onkho.Alert.SmallBox('warning', data.message);
                                                    break;
                                                }

                                                default : {
                                                    Onkho.Alert.BigBox('danger', 'Failed to load templates', 'Please try again later.');
                                                    break;
                                                }
                                            }
                                        }
                                    );
                                }
                            ;

                            selectTemplate.on(
                                'change',
                                function() {
                                    var templateId = $(this).val();
                                    var templatePreview = form.find('.template-preview');

                                    templatePreview.empty();

                                    if (templateId == null || templateId == 0) {
                                        templatePreview.html('<p>Choose a template to preview.</p>');
                                        currentContent = null;
                                        return;
                                    }

                                    Onkho.LoadingTools.ShowLoading(templatePreview, true);

                                    $.ajax({
                                        url: Onkho.Variables.BaseURL + "/template/" + templateId,
                                        type: "GET",
                                        dataType: "json"
                                    }).done(
                                        function(data) {
                                            currentContent = $(data.template.content + '&nbsp;');
                                            Onkho.LoadingTools.HideLoading(templatePreview);
                                            templatePreview.html(currentContent);
                                        }
                                    ).fail(
                                        function() {
                                            Onkho.LoadingTools.HideLoading(templatePreview);
                                            templatePreview.html('<p>Failed to load template preview.</p>');
                                        }
                                    );
                                }
                            );

                            modal.find('button[data-submit]').on(
                                'click',
                                function() {
                                    if (currentContent != null) {
                                        summernoteContext.invoke('code', currentContent.add(summernoteContext.code()));
                                        summernoteContext.invoke('saveRange');
                                        summernoteContext.invoke('restoreRange');
                                        Onkho.DynamicContentTools.HideModal();
                                    }

                                    return false;
                                }
                            );

                            updateTemplates();
                        }
                    );
                }
            });

            return button.render();   // return button as jquery object
        };

        var personaliseButton = function (summernoteContext) {
            var ui = $.summernote.ui;

            var button = ui.button({
                contents: 'Personalise',
                click: function () {
                    summernoteContext.invoke('saveRange');

                    var modalName = 'add_token';

                    Onkho.DynamicContentTools.LoadModal(modalName,
                        function() {
                            var form = $(this).find('.add-token-form');
                            var modal = form.closest('.modal');

                            var selectContext     = form.find('select[name="context"]');
                            var selectToken       = form.find('select[name="token"]');
                            var inputDefaultValue = form.find('input[name="default_value"]');

                            selectContext.select2({
                                placeholder: 'Category...',
                                sortResults: Onkho.Functions.Select2Sort
                            });

                            selectToken.select2({
                                placeholder: 'Token...',
                                sortResults: Onkho.Functions.Select2Sort
                            });

                            selectContext.children('option').each(
                                function() {
                                    var option = $(this);
                                    if (context.indexOf(option.val()) === -1) {
                                        option.remove();
                                    }
                                }
                            );

                            selectContext.on(
                              'change',
                              function() {
                                var context = $(this).val();
                                $.ajax({
                                  url: Onkho.Variables.BaseURL + "/tokens/" + context,
                                  type: "GET",
                                  dataType: "json"
                                }).done(
                                  function (data) {
                                    var optionText = "";
                                    for (var i = 0; i < data.tokens.length; i++) {
                                      var token = data.tokens[i];
                                      optionText += '<option value="' + token.token + '" data-token-context="' + token.context + '" data-token-field="' + token.token + '">' + token.name + '</option>';
                                    }
                                    selectToken.html(optionText);
                                    selectToken.change();
                                  }
                                ).fail(
                                  function (jqXHR) {
                                    var data = jqXHR.responseJSON;
                                    switch (jqXHR.status) {
                                      case 400 : {
                                        Onkho.Alert.SmallBox('warning', data.message);
                                        break;
                                      }

                                      default : {
                                        Onkho.Alert.BigBox('danger', 'Failed to load tokens', 'Please try again later.');
                                        break;
                                      }
                                    }
                                  }
                                );
                              }
                            ).change();

                            selectToken.on('change', function() {
                                if ($(this).val() === 'upload_link') {
                                  form.find('.default-value-title').text('What do you want to call this upload link?');
                                  form.find('.default-value-description').html('You may optionally specify a name to help you identify files uploaded via this upload link later. <br> <b>This will be shown to the recipient.</b>');
                                } else {
                                  form.find('.default-value-title').text('What should we display if a value does not exist?');
                                  form.find('.default-value-description').text('You may optionally specify a default value to display if a value does not exist for your selected token.');
                                }
                            });

                            modal.find('button[data-submit]').on(
                                'click',
                                function() {
                                    var token        = $(selectToken.children('option').filter('[value="' + selectToken.val() + '"]'));
                                    var defaultValue = inputDefaultValue.val().replace(/[^0-9a-zA-Z_\- ]/gi, '');

                                    var tokenHtml = '';
                                    tokenHtml += '<span class="token" contenteditable="false" readonly="readonly" data-context="' + token.data('token-context') + '" data-token-field="' + token.data('token-field') + '" data-token-default-value="' + defaultValue + '" data-token-additional-data="">';
                                    tokenHtml += token.text();

                                    if (token.data('token-field') === 'upload_link' && defaultValue) {
                                        tokenHtml += ' [' + defaultValue + ']';
                                    }

                                    tokenHtml += '</span>';

                                    summernoteContext.invoke('restoreRange');
                                    summernoteContext.invoke('editor.pasteHTML', tokenHtml + '&nbsp;');

                                    Onkho.DynamicContentTools.HideModal();
                                    return false;
                                }
                            );
                        }
                    );
                }
            });

            return button.render();
        };

        customButtons = typeof customButtons == 'undefined' ? [] : customButtons;

        var toolbar = [
            ['font-style', ['fontname', 'fontsize', 'bold', 'italic', 'underline', 'color', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['insert', ['link', 'picture', 'table', 'hr']],
            ['para', ['style', 'ol', 'ul', 'paragraph', 'height']],
            ['misc', ['fullscreen', 'undo', 'redo']],
            ['custom', ['choose_template', 'personalise']]
        ];

        if (plainTextToolbar === true) {
          toolbar  = [
            ['misc', ['fullscreen', 'undo', 'redo']],
            ['custom', ['choose_template', 'personalise']]
          ];
        }

        if (inlineEditor === true) {
          toolbar = [
            ['custom', ['personalise']]
          ];
        }

        var buttons = {
            choose_template: chooseTemplateButton,
            personalise: personaliseButton,
        };

        $.each(customButtons, function (key, definition)
        {
            toolbar.push([key, [key]]);
            buttons[key] = definition;
        });

        var height = typeof height == 'undefined' ? 100 : height;
        var placeholder = typeof placeholder == 'undefined' ? '' : placeholder;

        summernote.summernote({
            placeholder: placeholder,
            disableDragAndDrop: true,
            toolbar: toolbar,
            height: height,
            dialogsInBody: true,
            callbacks: {
                onInit: function () {
                  // Ridiculous fix to compensate for Summernote rendering differently in some environments
                  $('.dropdown-fontname > li i, .dropdown-fontsize > li i, .dropdown-line-height > li i').each(function (index, i) {
                    $(i).children().appendTo($(i).parent());
                  });

                  $('.dropdown-fontname > li > .note-icon-menu-check, .dropdown-fontsize > li > .note-icon-menu-check, .dropdown-line-height > li > .note-icon-menu-check').remove();

                  $('.note-current-fontname, .note-current-fontsize').each(function (index, span) {
                    if ($(span).parent().find('> .note-icon-caret').length === 0) {
                      $(span).parent().append(' <span class="note-icon-caret"></span>');
                    }
                  });

                  $('.dropdown-fontsize > li > a > .note-icon-menu-check, .dropdown-line-height > li > a > .note-icon-menu-check').each(function (index, i) {
                    if ($(i).text().length > 0) {
                      $(i).parent().html('<i class="note-icon-menu-check"></i> ' + $(i).text())
                    }
                  });

                  var noteTable = $('.note-table');
                  var dimensionPicker = noteTable.find('> .note-dimension-picker');
                  dimensionPicker.find('.note-dimension-display').appendTo(noteTable);
                  dimensionPicker.find('.note-dimension-picker-highlighted').appendTo(dimensionPicker);
                  dimensionPicker.find('.note-dimension-picker-unhighlighted').appendTo(dimensionPicker);

                  $('.note-popover.note-image-popover > .arrow, .note-popover.note-table-popover > .arrow, .note-popover.note-link-popover > .arrow').each(function (index, arrow) {
                    $(arrow).children().appendTo($(arrow).parent());
                  });
                },
                onBlur: function (e) {
                    var thisNote = $(this);
                    setTimeout(function () {
                        var input = GetSummernoteValue(thisNote);
                        thisNote.siblings('textarea').html(input);
                    }, 10);
                },
                onPaste: inlineEditor !== true ? function (e) {
                  var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                  e.preventDefault();
                  document.execCommand('insertText', false, bufferText);
                } : function (e) {
                  e.preventDefault();
                },
                onImageUpload: function (data) {
                  data.pop();
                },
                onKeyup: onKeyupCallback,
                onChange: inlineEditor !== true ? undefined : function (contents) {
                  ProcessInline(summernote, contents);
                },
                onKeydown: inlineEditor !== true ? undefined : function (e) {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }
            },
            buttons: buttons,
            // Table styling
            tableClassName: function () {
              $(this).removeClass();
              $(this).css('border', '1px solid #ddd');
              $(this).css('border', '1px solid #ddd');
              var td = $(this).find('td');
              td.css('border', '1px solid #ddd');
              td.css('border-left-width', '0px');
              td.css('border-bottom-width', '0px');
              td.css('padding', '8px 10px');
              td.css('line-height', '1.428571429');
              td.css('vertical-align', 'top');
            }
        });

        return summernote;
    };

    var ProcessInline = function (summernote, contents) {
      var processedContents = contents;
      processedContents = processedContents.replace('<p></p>', '');
      processedContents = processedContents.replace('<br>', '');

      if (processedContents !== contents) {
        summernote.summernote('code', processedContents);
      }
    };

  var UpdateSummernote = function (div, content, append) {
    if (typeof(append) === 'undefined') {
      append = false;
    }

    var summernote = EnableSummernote(div);

    if (!content) {
      summernote.summernote('code', '');
    } else if ($($.parseHTML(content)).text().trim() !== content.trim()) {
      if (append) {
        summernote.summernote('code', GetSummernoteValue(summernote) + content);
      } else {
        summernote.summernote('code', content);
      }
    } else {
      if (append) {
        summernote.summernote('code', GetSummernoteValue(summernote) + '<p>' + content + '</p>');
      } else {
        summernote.summernote('code', '<p>' + content + '</p>');
      }
    }

    return summernote;
  };

    var DestroySummernote = function (div) {
        var summernote;
        if (typeof(div) === 'undefined') {
            summernote = $('div.summernote');
        } else {
            summernote = $(div);
        }

        // Is Summernote already initialised?
        if (IsSummernoteEnabled(summernote)) {
            summernote.summernote('code', '');
            summernote.summernote('destroy');
        }
    };

    var GetSummernoteValue = function (div, textOnly) {
        if (typeof(textOnly) === 'undefined') {
            textOnly = false;
        }

        var summernote = EnableSummernote(div);

        var value = summernote.summernote('code').trim();
        var result = '';

        if (IsSummernoteEmpty(summernote)) {
            return result;
        }

        if (textOnly) {
            result = $($.parseHTML(value)).text().trim();
        } else {
            if (typeof $($.parseHTML(value)[0]).prop('tagName') == 'undefined') {
              // Content is plain text, surround with <p>
              result = '<p>' + value + '</p>';
            } else {
              result = value;
            }
        }

        return result;
    };

    var IsSummernoteEnabled = function (div) {
        return $(div).next('div.note-editor').length > 0;
    };

    var IsSummernoteEmpty = function (div) {
        var summernote = EnableSummernote(div);
        return summernote.summernote('isEmpty') || $($.parseHTML(summernote.summernote('code'))).text().trim().length === 0;
    };

    var GetTokenDefinition = function (context, token, callback)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.context = context;
        formData.token = token;

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/tokens/getDefinition',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          switch (data.status)
                          {
                              case 200:
                                  callback(data.responseJSON.token);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var GetCustomButtonForTokenFunction = function (label, context, token)
    {
        var customButtonFunction = function (summernoteContext)
        {
            var ui = $.summernote.ui;

            var button = ui.button({
                contents: label,
                click: function ()
                {
                    summernoteContext.invoke('saveRange');

                    if (['fee_plan'].indexOf(token) == -1)
                    {
                        GetTokenDefinition(context, token, function (token)
                        {
                            var tokenHtml = '';
                            tokenHtml += '<span class="token" contenteditable="false" readonly="readonly" data-context="' + token.context + '" data-token-field="' + token.token + '" data-token-default-value="" data-token-additional-data="">';
                            tokenHtml += token.name;
                            tokenHtml += '</span>';

                            summernoteContext.invoke('restoreRange');
                            summernoteContext.invoke('editor.pasteHTML', tokenHtml + '&nbsp;');
                        });
                    }
                    else
                    {
                        GatherAdditionalDataAndGetTokenDefinition(summernoteContext, context, token);
                    }
                }
            });

            return button.render();
        };

        return customButtonFunction;
    };

    var GatherAdditionalDataAndGetTokenDefinition = function (summernoteContext, context, token)
    {
        if (token == 'fee_plan')
        {
            Onkho.DynamicContentTools.LoadModal('fee_plan_picker', function ()
            {

                var formData = {};
                formData._token = Onkho.Functions.GetCSRF();
                formData.clientId = $('input[name="partyId"]').val();

                $.ajax(
                    {
                        type: 'POST',
                        url: Onkho.Variables.BaseURL + '/term/feePlan/getAll',
                        data: formData,
                        dataType: 'json',
                        complete: function (data)
                              {
                                  switch (data.status)
                                  {
                                      case 200:
                                          var select = $('.onkho-dynamic-modal select[name="fee_plan"]');

                                          $(data.responseJSON.feePlans).each(function (index, feePlan)
                                          {
                                              var option = $('<option></option>');
                                              option.attr('value', feePlan.id);
                                              option.html(feePlan.name);

                                              select.append(option);
                                          });

                                          select.select2({
                                              placeholder: 'Choose fee plan',
                                              sortResults: Onkho.Functions.Select2Sort
                                          });
                                          break;

                                      default:
                                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                          break;
                                  }
                              }
                    });

                $('.onkho-dynamic-modal .choose').on('click', function ()
                {
                    if (Onkho.Validator.ValidateChildren($('.onkho-dynamic-modal')))
                    {
                        var additionalData = $('.onkho-dynamic-modal select').val();

                        Onkho.DynamicContentTools.HideModal();

                        GetTokenDefinition(context, token, function (token)
                        {
                            var tokenHtml = '';
                            tokenHtml += '<span class="token" contenteditable="false" readonly="readonly" data-context="' + token.context + '" data-token-field="' + token.token + '" data-token-default-value="" data-token-additional-data="' + additionalData + '">';
                            tokenHtml += token.name;
                            tokenHtml += '</span>';

                            summernoteContext.invoke('restoreRange');
                            summernoteContext.invoke('editor.pasteHTML', tokenHtml + '&nbsp;');
                        });
                    }
                });
            });
        }
    };

    return {
        Init:                Init,
        EnableSummernote:    EnableSummernote,
        UpdateSummernote:    UpdateSummernote,
        DestroySummernote:   DestroySummernote,
        GetSummernoteValue:  GetSummernoteValue,
        IsSummernoteEmpty:   IsSummernoteEmpty,
        IsSummernoteEnabled: IsSummernoteEnabled,
        GetCustomButtonForTokenFunction: GetCustomButtonForTokenFunction
    };
}();
