Onkho.EditSMSBoxPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the edit SMS box panel
    $('body').on('keypress', '.o-panel[data-panel-key="edit_smsbox"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  };

  var LoadEditSMSBoxPanel = function (smsboxId)
  {
    var additionalData = {
      'smsboxId': smsboxId
    };

    Onkho.OnkhoPanel.Add('edit_smsbox', additionalData, EditSMSBoxPanelLoaded)
  };

  var EditSMSBoxPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    panel.find('select').select2();
  };

  var RegisterEditSMSBoxPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      UpdateSMSBox();
    });
  };

  var UpdateSMSBox = function ()
  {
    var panel = $('.o-panel[data-panel-key="edit_smsbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'));

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.smsbox_id = panel.data('smsbox-id');

    panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      formData[$(input).attr('name')] = $(input).val()
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/updateSMSBox',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

          switch (data.status)
          {
            case 200:
              Onkho.OnkhoPanel.RemoveAll();

              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.OnkhoList.Refresh($('.o-list[data-id="smsboxes"]'));
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your SMS box', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
              break;
          }
        }
      });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[edit_smsbox].added', '.o-panel-container', function ()
    {
      var editSMSBoxPanel = $('.o-panel[data-panel-key="edit_smsbox"]');
      RegisterEditSMSBoxPanelBindings(editSMSBoxPanel);
    });
  };



  return {
    Init: Init,
    LoadEditSMSBoxPanel: LoadEditSMSBoxPanel
  };
}();
