Onkho.ViewUploadLinkPanel = function ()
{
    var Init = function ()
    {
        RegisterPanelBindings();

        RegisterEventListeners();
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('click', '.view-upload-link-panel-trigger', function () {
            LoadViewUploadLinkPanel($(this).data('upload-link-id'))
        })
    };

    var LoadViewUploadLinkPanel = function (uploadLinkId)
    {
        var additionalData = {
            uploadLinkId: uploadLinkId
        };

        Onkho.OnkhoPanel.Add('view_upload_link', additionalData, ViewUploadLinkLoaded)
    };

    var RegisterPanelBindings = function ()
    {
        // Add/edit time entry
        $('body').on('onkho:panel[view_upload_link].added', '.o-panel-container', function ()
        {
            var viewUploadLinkPanel = $('.o-panel[data-panel-key="view_upload_link"]');
            RegisterViewUploadLinkPanelBindings(viewUploadLinkPanel);
        });
    };

    var RegisterViewUploadLinkPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);

            // Ensure panels appear over panes
            $('.o-panel-wrapper').css('z-index', 1050);
        });

        panel.find('.featured-action[data-id="delete"]:not(:disabled)').on('click', function ()
        {
            ConfirmDeleteUploadLink(panel);
        });
    };

    var ViewUploadLinkLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);

        // Ensure this panel appears under panes
        $('.o-panel-wrapper').css('z-index', 1025);

        InitializeAttachmentsTable();

        panel.find('[rel="tooltip"]').tooltip();
    };

    var InitializeAttachmentsTable = function ()
    {
        $('.attachments-wrapper .table').DataTable({
            'dom': '<\'grid-top-toolbar\'<\'filter-wrapper\'f><\'page-length-wrapper\'l>>' +
              '<\'table-wrapper\'tr>' +
              '<\'grid-bottom-toolbar\'<\'information-wrapper\'i><\'pagination-wrapper\'p><\'help-bar-spacer\'>>',
            'iDisplayLength': 10,
            'lengthMenu': Onkho.Config.DataTablesPageLengthMenu,
            'processing': true,
            'serverSide': false,
            'scrollX': false,
            'scrollY': 'calc(100vh - 390px)',
            'scrollCollapse': true,
            'language': {
                'search': '',
                'processing': '<i class="fa fa-fw fa-spin fa-spinner"></i>',
                'emptyTable': 'No files have been uploaded.'
            },
            'initComplete': function (settings, json) {
                var searchInput = $('.dataTables_wrapper .filter-wrapper .dataTables_filter input');
                searchInput.attr('placeholder', 'Search');
            },
        });
    };

    var ConfirmDeleteUploadLink = function (panel) {
        var title = 'Deleting upload link'
        var content = 'Are you sure you want to delete this upload link?'

        $.SmartMessageBox(
          {
              title: title,
              content: content,
              buttons: '[No][Yes]'
          }, function (ButtonPress) {
              if (ButtonPress == 'Yes') {
                  DeleteUploadLink(panel);
              }
          }
        );
    }

    var DeleteUploadLink = function (panel) {
        var formData = {}
        formData._token = Onkho.Functions.GetCSRF();
        formData.ids = [panel.data('upload-link-id')];

        $.ajax(
          {
              type: 'POST',
              url: Onkho.Variables.BaseURL + '/uploadLink/delete',
              data: formData,
              dataType: 'json',
              complete: function (data)
              {
                  switch (data.status)
                  {
                      case 200:
                          Onkho.Alert.SmallBox('success', data.responseJSON.message);
                          $('.o-panel-container').trigger('onkho:panel[view_upload_link].deleted', formData.ids);
                          Onkho.OnkhoPanel.Remove(panel);
                          break;

                      default:
                          Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                          break;
                  }
              }
          });
    }



    return {
        Init: Init,
        LoadViewUploadLinkPanel: LoadViewUploadLinkPanel
    };
}();
