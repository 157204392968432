Onkho.EditInsightsSelectionPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    $('body').on('change', '.o-panel[data-panel-key="edit_insights_selection"] select[name="attribute_selector"]', function () {
      RecomputeAttributeFormVisibility($(this).closest('.o-panel'))
    })

    $('body').on('click', '.o-panel[data-panel-key="edit_insights_selection"] .stage-change', function () {
      StageChange($(this).closest('.o-panel'))
    })

    $('body').on('click', '.o-panel[data-panel-key="edit_insights_selection"] .staged-change .delete', function () {
      RemoveStagedChange($(this).closest('.o-panel'), $(this).closest('.staged-change'))
    })

    // Prevent ENTER from refreshing the page in the add edit mailbox additional panel
    $('body').on('keypress', '.o-panel[data-panel-key="edit_insights_selection"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadEditInsightsSelectionPanel = function (resource)
  {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="edit"]'));

    var additionalData = {
      'resource': resource,
      'selectedCount': Onkho.InsightGridPageTools.GetSelection().length
    };

    Onkho.OnkhoPanel.Add('edit_insights_selection', additionalData, LoadEditInsightsSelectionPanelLoaded)
  };

  var LoadEditInsightsSelectionPanelLoaded = function (panel)
  {
    Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="edit"]'));

    panel.find('select[name="attribute_selector"]').select2({
      placeholder: 'Pick something to change',
      sortResults: Onkho.Functions.Select2Sort
    })

    panel.find('select:not([name="attribute_selector"])').select2()

    Onkho.FormEnhancements.EnhanceDates()
    Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote'));

    RecomputeAttributeFormVisibility(panel)

    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);
  };

  var PrepareRemoval = function (panel)
  {
    Onkho.Summernote.DestroySummernote(panel.find('.summernote'));
  };

  var RecomputeAttributeFormVisibility = function (panel)
  {
    panel.find('.attribute-form').slideUp(100)
    var selectedAttribute = panel.find('select[name="attribute_selector"]').val()

    if (!selectedAttribute) {
      panel.find('.stage-change-wrapper').slideUp(100)
    } else {
      panel.find('.attribute-form[data-id="' + selectedAttribute + '"]').slideDown(100)
      panel.find('.stage-change-wrapper').slideDown(100)
    }
  };

  var RegisterEditInsightsSelectionPanelBindings = function (panel)
  {
    panel.find('button[data-id="cancel"], .close[data-id="close"]').on('click', function ()
    {
      PrepareRemoval(panel);
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      ConfirmSaveChanges();
    });
  };

  var ConfirmSaveChanges = function () {
    var selectedCount = Onkho.InsightGridPageTools.GetSelection().length
    if (selectedCount <= 1) {
      SaveChanges()
      return
    }

    let resource = Onkho.Variables.InsightGridResource;
    if (resource == 'team') {
      resource = 'team members';
    } else if (resource == 'activityFeedGroups') {
      resource = 'conversations';
    }

    $.SmartMessageBox(
      {
        title: 'Changing ' + selectedCount + ' ' + resource,
        content: 'Are you sure you want to apply these changes to the ' + selectedCount + ' selected ' + resource + '?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          SaveChanges()
        }
      }
    )
  }

  var SaveChanges = function ()
  {
    var panel = $('.o-panel[data-panel-key="edit_insights_selection"]')

    if (Onkho.Validator.ValidateChildren(panel))
    {
      Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'))

      var selection = [];
      $(Onkho.InsightGridPageTools.GetSelection()).each(function (index, selectedRowData) {
        selection.push(selectedRowData.id)
      });

      var resource = Onkho.InsightGridPageTools.GetInsightResource()
      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();
      formData.selection = selection;
      formData.changes = GatherChanges(panel)

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/insight/' + resource + '/editSelection',
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

            switch (data.status)
            {
              case 200:
                PrepareRemoval(panel);
                Onkho.OnkhoPanel.Remove(panel);

                Onkho.Alert.SmallBox('success', data.responseJSON.message);

                Onkho.InsightGridPageTools.DeselectAll()
                Onkho.InsightGridPageTools.ReloadRecords()
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your changes', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
                break;
            }
          }
        });
    }
  };

  var GatherChanges = function (panel)
  {
    var changes = [];

    panel.find('.staged-change').each(function (index, stagedChange) {
      changes.push({
        'attribute': $(stagedChange).data('attribute'),
        'value': decodeURIComponent($(stagedChange).data('value'))
      })
    })

    return changes;
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[edit_insights_selection].added', '.o-panel-container', function ()
    {
      var editInsightsSelectionPanel = $('.o-panel[data-panel-key="edit_insights_selection"]');
      RegisterEditInsightsSelectionPanelBindings(editInsightsSelectionPanel);
    });
  };

  var StageChange = function (panel)
  {
    if (Onkho.Validator.ValidateChildren(panel)) {
      var stagedChangesContainer = panel.find('.staged-changes')
      var selectedAttribute = panel.find('select[name="attribute_selector"]').val()
      var selectedAttributeForm = panel.find('.attribute-form[data-id="' + selectedAttribute + '"]')
      var selectedAttributeFormElement = selectedAttributeForm.find('[name="' + selectedAttribute + '"]')

      var value;
      if (selectedAttributeFormElement.hasClass('summernote')) {
        value = Onkho.Summernote.GetSummernoteValue(selectedAttributeFormElement)
      } else {
        value = selectedAttributeFormElement.val()
      }

      var label = Onkho.TextTools.ExtractText(value)
      if (selectedAttributeFormElement.is('select')) {
        label = selectedAttributeFormElement.find('option:selected').text()
      }
      var existingStagedChange = panel.find('.staged-change[data-attribute="' + selectedAttribute + '"]')

      existingStagedChange.remove()

      panel.find('.no-changes').slideUp()

      var stagedChange;
      if (value) {
        stagedChange = $('' +
          '<div class="staged-change" data-attribute="' + selectedAttribute + '" data-value="' + encodeURIComponent(value) + '">' +
            '<div class="attribute">' + selectedAttributeForm.data('label') + '</div> ' +
            '<div class="changed-to"> to be changed to </div> ' +
            '<div class="value">' + label + '</div> ' +
            '<a href="javascript:void(0);" class="delete" data-toggle="tooltip" data-original-title="Remove pending change">×</a>' +
          '</div>');
      } else {
        stagedChange = $('' +
          '<div class="staged-change" data-attribute="' + selectedAttribute + '" data-value="' + encodeURIComponent(value) + '">' +
            '<div class="attribute">' + selectedAttributeForm.data('label') + '</div> ' +
            '<div class="changed-to"> to be cleared</div> ' +
            '<a href="javascript:void(0);" class="delete" data-toggle="tooltip" data-original-title="Remove pending change">×</a>' +
          '</div>');
      }
      stagedChangesContainer.append(stagedChange);

      ResetAttributeSelector(panel);
    }
  };

  var ResetAttributeSelector = function (panel) {
    panel.find('[name="attribute_selector"]').select2('data', '')

    RecomputeAttributeFormVisibility(panel)
  }

  var RemoveStagedChange = function (panel, stagedChange)
  {
    stagedChange.remove()

    if (panel.find('.staged-change').length === 0) {
      panel.find('.no-changes').slideDown(100)
    }
  };



  return {
    Init: Init,
    LoadEditInsightsSelectionPanel: LoadEditInsightsSelectionPanel
  };
}();
