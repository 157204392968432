Onkho.ViewMailboxPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    var panel = $('.o-panel[data-panel-key="view_mailbox"]');

    panel.find('.close[data-id="close"]').on('click', function ()
    {
      Onkho.OnkhoPanel.Remove(panel);
    });

    // Prevent ENTER from refreshing the page in the view mailbox panel
    $('body').on('keypress', '.o-panel[data-panel-key="view_mailbox"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadViewMailboxPanel = function (mailboxId)
  {
    var additionalData = {
      'mailboxId': mailboxId
    };

    Onkho.OnkhoPanel.Add('view_mailbox', additionalData, ViewMailboxPanelLoaded)
  };

  var ViewMailboxPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
  };

  var RegisterViewMailboxPanelBindings = function (panel)
  {
    panel.find('button[data-id="edit"]').on('click', function ()
    {
      Onkho.EditMailboxPanel.LoadEditMailboxPanel(panel.data('mailbox-id'));
      Onkho.OnkhoPanel.RemoveAll();
    });

    panel.find('button[data-id="test"]').on('click', function ()
    {
      TestMailbox();
    });

    panel.find('button[data-id="scan"]').on('click', function ()
    {
      ScanMailbox();
    });

    panel.find('button[data-id="delete"]').on('click', function ()
    {
      ConfirmDeleteMailbox();
    });
  };

  var TestMailbox = function ()
  {
    var panel = $('.o-panel[data-panel-key="view_mailbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="test"]'));

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.mailbox_id = panel.data('mailbox-id');

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/mailbox/testMailbox',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              if (data.responseJSON.testResult) {
                Onkho.Alert.SmallBox('success', data.responseJSON.message);
              } else {
                Onkho.Alert.BigBox('warning', data.responseJSON.message);
              }

              // Refresh mailboxes list, if one exists
              var mailboxesList = $('.mailboxes-list');
              if (mailboxesList.length > 0) {
                Onkho.OnkhoList.Refresh(mailboxesList);
              }

              // Reopen panel
              Onkho.OnkhoPanel.RemoveAll();
              LoadViewMailboxPanel(formData.mailbox_id);
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  };

  var ScanMailbox = function (mailboxId)
  {
    var panel = $('.o-panel[data-panel-key="view_mailbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="scan"]'));

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.mailbox_id = panel.data('mailbox-id');

    Onkho.Alert.SmallBox('info', 'We are now scanning your mailbox. This may take a few minutes.');

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/mailbox/scanMailbox',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              if (data.responseJSON.scanResult == 'OK') {
                Onkho.Alert.SmallBox('success', data.responseJSON.message);
              } else {
                Onkho.Alert.SmallBox('error', data.responseJSON.message);
              }

              // Refresh mailboxes list, if one exists
              var mailboxesList = $('.mailboxes-list');
              if (mailboxesList.length > 0) {
                Onkho.OnkhoList.Refresh(mailboxesList);
              }

              // Reopen panel
              Onkho.OnkhoPanel.RemoveAll();
              LoadViewMailboxPanel(formData.mailbox_id);
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  };

  var ConfirmDeleteMailbox = function ()
  {
    $.SmartMessageBox(
      {
        title: 'Delete mailbox',
        content: 'Are you sure you want to delete this mailbox? The emails that have already been retrieved from the mailbox will not be deleted.',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteMailbox()
        }
      }
    )
  }

  var DeleteMailbox = function ()
  {
    var panel = $('.o-panel[data-panel-key="view_mailbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.mailboxId = panel.data('mailbox-id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/mailbox/deleteMailbox',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              // Refresh mailboxes list, if one exists
              var mailboxesList = $('.mailboxes-list');
              if (mailboxesList.length > 0) {
                Onkho.OnkhoList.Refresh(mailboxesList);
              }

              // Close panel
              Onkho.OnkhoPanel.RemoveAll();
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[view_mailbox].added', '.o-panel-container', function ()
    {
      var viewMailboxPanel = $('.o-panel[data-panel-key="view_mailbox"]');
      RegisterViewMailboxPanelBindings(viewMailboxPanel);
    });
  };



  return {
    Init: Init,
    LoadViewMailboxPanel: LoadViewMailboxPanel
  };
}();
