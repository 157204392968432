// Module containing tools to be used to handle insights KPIs
Onkho.KPI = function () {
  var Init = function () {
    $('body').on('click', '.kpi .refresh', function () {
      RunKPI($(this).closest('.kpi'))
    })

    $('body').on('click', '.kpi .edit', function () {
      EditKPI($(this).closest('.kpi'))
    })

    $('body').on('click', '.kpi .delete', function () {
      DeleteKPI($(this).closest('.kpi'))
    })
  }

  var CreateUpdateKPI = function (id, data)
  {
    if (id === null) {
      // This is a new KPI
      CreateKPI(data)
    } else {
      // This is an update to an existing KPi
      UpdateKPI(id, data)
    }
  }

  var CreateKPI = function (data)
  {
    var container = $('.kpi-container')

    var kpi = $('<div class="kpi"></div>')
    kpi.data('configuration', data)
    kpi.attr('data-kpi-id', parseInt(Math.random() * 10000))

    container.append(kpi)

    RenderKPI(kpi)
  }

  var UpdateKPI = function (id, data)
  {
    var container = $('.kpi-container')

    var kpi = container.find('.kpi[data-kpi-id="' + id + '"]')
    kpi.data('configuration', data)

    RenderKPI(kpi)
  }

  var RenderKPI = function (kpiElement)
  {
    var formData = {};
    formData._token = Onkho.Functions.GetCSRF()
    formData.configuration = kpiElement.data('configuration')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/renderKPI',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              var renderedKPI = $(data.responseJSON.html)
              renderedKPI.data('configuration', formData.configuration)
              renderedKPI.find('[rel="tooltip"]').tooltip()
              renderedKPI.data('loaded', false)
              renderedKPI.find('.last-updated').html('Refresh to update')
              if (kpiElement.attr('data-kpi-id')) {
                renderedKPI.attr('data-kpi-id', kpiElement.attr('data-kpi-id'))
              }
              kpiElement.replaceWith(renderedKPI)
              break;

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break;
          }
        }
      });
  }

  var RunKPI = function (kpiElement)
  {
    if (kpiElement.hasClass('loading')) {
      return
    }

    kpiElement.addClass('loading')

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF()
    formData.configuration = kpiElement.data('configuration')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/insight/' + formData.configuration.resource + '/runKPI',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          kpiElement.removeClass('loading')

          switch (data.status)
          {
            case 200:
              if (data.responseJSON.result.result == null) {
                kpiElement.find('.value').html('None')
              } else {
                kpiElement.find('.value').html(data.responseJSON.result.result)
              }

              kpiElement.data('loaded', true)
              SetLastUpdated(kpiElement, moment())
              $(data.responseJSON.result.CSS).each(function (index, CSS) {
                ApplyCSS(kpiElement, CSS)
              })
              break;

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break;
          }
        }
      });
  }

  var UpdateFilters = function (kpiElement, filters)
  {
    var configuration = kpiElement.data('configuration')
    configuration['filters'] = filters
    kpiElement.data('configuration', configuration)
    FiltersChanged(kpiElement)
  }

  var FiltersChanged = function (kpiElement)
  {
    if (kpiElement.data('last-updated-interval')) {
      clearInterval(kpiElement.data('last-updated-interval'))
    }

    if (kpiElement.data('loaded')) {
      kpiElement.find('.last-updated').html('Filters changed')
      kpiElement.find('.last-updated').css('color', '#A90329')
    }
  }

  var SetLastUpdated = function (kpiElement, datetime)
  {
    if (kpiElement.data('last-updated-interval')) {
      clearInterval(kpiElement.data('last-updated-interval'))
    }

    var lastUpdatedElement = kpiElement.find('.last-updated')
    kpiElement.data('last-updated', datetime)
    var updateLastUpdatedLabel = function () {
      lastUpdatedElement.html(datetime.fromNow())

      if (moment().diff(datetime) > 5 * 60 * 1000) {
        // Data might be stale at this point
        lastUpdatedElement.css('color', '#A90329')
        lastUpdatedElement.tooltip({
          title: 'This KPI may be out of date'
        })
      } else {
        lastUpdatedElement.css('color', '#000000')
        lastUpdatedElement.tooltip('destroy')
      }
    }
    var interval = setInterval(updateLastUpdatedLabel, 5000);
    kpiElement.data('last-updated-interval', interval)
    updateLastUpdatedLabel()
  }

  var ApplyCSS = function (kpiElement, CSS)
  {
    var type = CSS.type
    var target = CSS.target

    if (CSS.target === '.kpi') {
      target = kpiElement
    } else {
      target = kpiElement.find(target)
    }

    if (type === 'inline') {
      $.each(CSS.styles, function (key, value) {
        if (key === 'animation' && target.css('animation') !== 'none 0s ease 0s 1 normal none running') {
          // Append animations instead of replacing them
          target.css('animation', target.css('animation') + ', ' + value)
        } else {
          target.css(key, value)
        }
      })
    } else if (type === 'class') {
      target.addClass(CSS.class)
    }
  }

  var DeleteKPI = function (kpiElement)
  {
    $.SmartMessageBox(
      {
        title: 'Are you sure you want to delete this KPI?',
        content: '',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          if (kpiElement.data('last-updated-interval')) {
            clearInterval(kpiElement.data('last-updated-interval'))
          }

          kpiElement.find('[data-toggle="tooltip"]').tooltip('hide').tooltip('destroy')

          var container = kpiElement.closest('.kpi-container')

          kpiElement.remove()
          Onkho.Alert.SmallBox('success', 'KPI deleted!')

          if (container.length && container.find('.kpi').length === 0) {
            // If we're in a container and this was the last KPI, hide the container
            Onkho.InsightGridPageTools.HideKPIContainer()
          }
        }
      }
    )
  }

  var EditKPI = function (kpiElement)
  {
    var configuration = kpiElement.data('configuration')
    configuration.kpiId = kpiElement.data('kpi-id')
    Onkho.AddEditKPIPanel.LoadAddEditKPIPanel(configuration)
  }



  return {
    Init: Init,
    CreateUpdateKPI: CreateUpdateKPI,
    RenderKPI: RenderKPI,
    RunKPI: RunKPI,
    UpdateFilters: UpdateFilters
  }
}()

