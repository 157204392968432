// Module containing tools to be used on the service registration page
Onkho.RegisterServicePageTools = function () {

    // Hidden input field that is present when editing an existing service.
    var existingServiceForm      = null;
    var existingService          = null;

    // Step 1 - Service Name
    var addServicePanelStep1     = null;
    var addServicePanelFormStep1 = null;
    var step1Fields              = null;
    var step1UserInput           = null;

    // Step 2 - Service Template
    var addServicePanelStep2     = null;
    var addServicePanelFormStep2 = null;
    var step2Fields              = null;
    var step2UserInput           = null;

    // Step 3 - Scheduling
    var addServicePanelStep3     = null;
    var addServicePanelFormStep3 = null;
    var step3Fields              = null;
    var step3UserInput           = null;

    // Step 4 - Pre-requisites
    var addServicePanelStep4     = null;
    var addServicePanelFormStep4 = null;
    var step4Fields              = null;
    var step4UserInput           = null;
    var step4Requirements        = null;
    var step4Ordinal             = null;

    // Step 5 - Checklist
    var addServicePanelStep5     = null;
    var addServicePanelFormStep5 = null;
    var step5Fields              = null;
    var step5UserInput           = null;
    var step5Steps               = null;
    var step5Ordinal             = null;

    // Step 6 - Messages
    var addServicePanelStep6     = null;
    var addServicePanelFormStep6 = null;
    var step6Fields              = null;
    var step6UserInput           = null;

    // Step 7 - Messages
    var addServicePanelStep7     = null;
    var addServicePanelFormStep7 = null;
    var step7Fields              = null;
    var step7UserInput           = null;

    // Step 8 - Autoplanning
    var addServicePanelStep8     = null;
    var addServicePanelFormStep8 = null;
    var step8Fields              = null;
    var step8UserInput           = null;

    var initExistingService = function() {
        existingServiceForm = $('div#content').find('form[name="existing-service-form"]');

        var existingServiceFields = {
            service_id: existingServiceForm.find('input[type="hidden"][name="service_id"]'),
            service_name: existingServiceForm.find('input[type="hidden"][name="service_name"]'),
            service_schedule: existingServiceForm.find('input[type="hidden"][name="service_schedule"]'),
            service_requirements: existingServiceForm.find('input[type="hidden"][name="precondition"]'),
            service_steps: existingServiceForm.find('input[type="hidden"][name="step"]'),
            start_notification_activity_feed_item_configuration: existingServiceForm.find('input[type="hidden"][name="start_notification_activity_feed_item_configuration"]'),
            complete_notification_activity_feed_item_configuration: existingServiceForm.find('input[type="hidden"][name="complete_notification_activity_feed_item_configuration"]'),
            sends_notifications: existingServiceForm.find('input[type="hidden"][name="sends_notifications"]'),
            service_manager_id: existingServiceForm.find('input[type="hidden"][name="service_manager_id"]'),
            autoplanning_configuration: existingServiceForm.find('input[type="hidden"][name="autoplanning"]')
        }

        // The form will not exist if we are defining a new service.
        if (existingServiceForm.length === 0) {
            existingService = false;
            return;
        }

        existingService = {
            service_id: existingServiceFields.service_id.val(),
            service_definition_id: existingServiceFields.service_id.data('service-definition-id'),
            service_type: existingServiceFields.service_id.data('service-type'),
            service_name: existingServiceFields.service_name.val(),
            service_schedule: existingServiceFields.service_schedule.val(),
            service_requirements: {},
            service_steps: {},
            start_notification_activity_feed_item_configuration: {
                'from_mailbox_id': existingServiceFields.start_notification_activity_feed_item_configuration.data('configuration').from_mailbox_id,
                'from_mailbox_address': existingServiceFields.start_notification_activity_feed_item_configuration.data('configuration').address,
                'from_mailbox_practice_name': existingServiceFields.start_notification_activity_feed_item_configuration.data('configuration').practice_name,

                'subject': existingServiceFields.start_notification_activity_feed_item_configuration.data('configuration').subject,
                'content': existingServiceFields.start_notification_activity_feed_item_configuration.data('configuration').content,
            },
            complete_notification_activity_feed_item_configuration: {
                'from_mailbox_id': existingServiceFields.complete_notification_activity_feed_item_configuration.data('configuration').from_mailbox_id,
                'from_mailbox_address': existingServiceFields.complete_notification_activity_feed_item_configuration.data('configuration').address,
                'from_mailbox_practice_name': existingServiceFields.complete_notification_activity_feed_item_configuration.data('configuration').practice_name,

                'subject': existingServiceFields.complete_notification_activity_feed_item_configuration.data('configuration').subject,
                'content': existingServiceFields.complete_notification_activity_feed_item_configuration.data('configuration').content,
            },
            sends_notifications: existingServiceFields.sends_notifications.val(),
            service_manager_id: existingServiceFields.service_manager_id.val(),
            autoplan_jobs: existingServiceFields.autoplanning_configuration.data('configuration').autoplanning_time_unit_count && existingServiceFields.autoplanning_configuration.data('configuration').autoplanning_time_unit,
            autoplanning_time_unit_count: existingServiceFields.autoplanning_configuration.data('configuration').autoplanning_time_unit_count,
            autoplanning_time_unit: existingServiceFields.autoplanning_configuration.data('configuration').autoplanning_time_unit
        };

        existingServiceFields.service_requirements.each(function() {
            var self = $(this);
            var precondition_name = self.data('precondition-name')
            var precondition_ordinal = self.data('precondition-ordinal')
            var precondition_notification_offset = self.data('precondition-notification-offset')
            var precondition_notification_time_unit = self.data('precondition-notification-time-unit')
            var precondition_notification_time = self.data('precondition-notification-time')
            var precondition_reminder = self.data('precondition-reminder-frequency') > 0
            var precondition_reminder_frequency = self.data('precondition-reminder-frequency')
            var precondition_reminder_time_unit = self.data('precondition-reminder-time-unit')
            var precondition_reminder_time = self.data('precondition-reminder-time')

            var precondition_notification_activity_feed_item_from_mailbox_id = null;
            var precondition_notification_activity_feed_item_from_mailbox_address = null;
            var precondition_notification_activity_feed_item_from_mailbox_practice_name = null;
            var precondition_notification_activity_feed_item_subject = null;
            var precondition_notification_activity_feed_item_content = null;

            var activityFeedItemConfiguration = self.data('precondition-notification-activity-feed-item-configuration');
            if (activityFeedItemConfiguration != null) {
                precondition_notification_activity_feed_item_from_mailbox_id = activityFeedItemConfiguration.from_mailbox_id;
                precondition_notification_activity_feed_item_from_mailbox_address = activityFeedItemConfiguration.address;
                precondition_notification_activity_feed_item_from_mailbox_practice_name = activityFeedItemConfiguration.practice_name;
                precondition_notification_activity_feed_item_subject = activityFeedItemConfiguration.subject;
                precondition_notification_activity_feed_item_content = activityFeedItemConfiguration.content;
            }

            var precondition = {
                precondition_name: precondition_name,
                ordinal: precondition_ordinal,
                precondition_notification_offset: precondition_notification_offset,
                precondition_notification_time_unit: precondition_notification_time_unit,
                precondition_notification_time: precondition_notification_time.substring(0, 5),
                precondition_reminder: precondition_reminder,
                precondition_reminder_frequency: precondition_reminder_frequency,
                precondition_reminder_time_unit: precondition_reminder_time_unit,
                precondition_reminder_time: precondition_reminder_time.substring(0, 5),
                precondition_notification_activity_feed_item_from_mailbox_id: precondition_notification_activity_feed_item_from_mailbox_id,
                precondition_notification_activity_feed_item_from_mailbox_address: precondition_notification_activity_feed_item_from_mailbox_address,
                precondition_notification_activity_feed_item_from_mailbox_practice_name: precondition_notification_activity_feed_item_from_mailbox_practice_name,
                precondition_notification_activity_feed_item_subject: precondition_notification_activity_feed_item_subject,
                precondition_notification_activity_feed_item_content: precondition_notification_activity_feed_item_content
            };

            precondition.description = getStep4Description(precondition);
            existingService.service_requirements[precondition_name] = precondition;
        });

        existingServiceFields.service_steps.each(function() {
            var self = $(this);
            var step_name = self.data('step-name');
            var step_ordinal = self.data('step-ordinal');
            var step_complete_activity_feed_item = toBoolean(self.data('step-generates-activity-feed-item-on-completion'));

            var step_complete_activity_feed_item_from_mailbox_id = null;
            var step_complete_activity_feed_item_from_mailbox_address = null;
            var step_complete_activity_feed_item_from_mailbox_practice_name = null;
            var step_complete_activity_feed_item_subject = null;
            var step_complete_activity_feed_item_content = null;

            var activityFeedItemConfiguration = self.data('step-complete-activity-feed-item-configuration');
            if (activityFeedItemConfiguration != null) {
                step_complete_activity_feed_item_from_mailbox_id = activityFeedItemConfiguration.from_mailbox_id;
                step_complete_activity_feed_item_from_mailbox_address = activityFeedItemConfiguration.address;
                step_complete_activity_feed_item_from_mailbox_practice_name = activityFeedItemConfiguration.practice_name;
                step_complete_activity_feed_item_subject = activityFeedItemConfiguration.subject;
                step_complete_activity_feed_item_content = activityFeedItemConfiguration.content;
            }

            var step = {
                step_name: step_name,
                ordinal: step_ordinal,
                step_complete_activity_feed_item: step_complete_activity_feed_item,
                step_complete_activity_feed_item_from_mailbox_id: step_complete_activity_feed_item_from_mailbox_id,
                step_complete_activity_feed_item_from_mailbox_address: step_complete_activity_feed_item_from_mailbox_address,
                step_complete_activity_feed_item_from_mailbox_practice_name: step_complete_activity_feed_item_from_mailbox_practice_name,
                step_complete_activity_feed_item_subject: step_complete_activity_feed_item_subject,
                step_complete_activity_feed_item_content: step_complete_activity_feed_item_content,
            }

            step.description = getStep5Description(step);
            existingService.service_steps[step_name] = step;
        });
    };

    var initStep1 = function() {
        // Reference each fieldset once.
        var fieldsetNewServiceName = addServicePanelFormStep1.find('fieldset.new-service-name');

        // Fields
        step1Fields = {
            service_name: fieldsetNewServiceName.find('input[type="text"][name="service_name"]'),
        };

        var defaultInput = {
            service_name: ''
        };

        // Update default input if we're editing an existing service.
        if (existingService) {
            defaultInput.service_name = existingService.service_name;
        }

        step1UserInput = Object.assign({}, defaultInput);

        var clearServiceName =
            function() {
                step1Fields.service_name.val(step1UserInput.service_name).change();
                Onkho.Validator.ResetValidation([step1Fields.service_name]);
            };

        // This event is responsible for clearing all input.
        fieldsetNewServiceName.on('clearInput',
            function() {
                clearServiceName();
            }
        );

        // Validate and update the user input model when the name is changed.
        step1Fields.service_name.on('change',
            function() {
                if (Onkho.Validator.ValidateChildren(fieldsetNewServiceName)) {
                    updateStep1UserInput(defaultInput);
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        fieldsetNewServiceName.trigger('clearInput');
    };

    var initStep2 = function() {
        // Reference each fieldset once.
        var fieldsetServiceTemplate = addServicePanelFormStep2.find('fieldset.service-template');

        // The wrapper in which service descriptions will be displayed.
        var serviceDescriptions     = fieldsetServiceTemplate.find('section.complex-service-description');

        // Fields
        step2Fields = {
            existing_service: fieldsetServiceTemplate.find('input[type="radio"][name="existing_service"]'),
            service_template: fieldsetServiceTemplate.find('select[name="service_definition"]')
        };

        var defaultInput = {
            existing_service: '0',
            service_template: '',
            service_type:     ''
        };

        // Update default input if we're editing an existing service.
        if (existingService) {
            defaultInput.existing_service = '1';
            defaultInput.service_template = existingService.service_definition_id;
            defaultInput.service_type     = existingService.service_type;
        }

        step2UserInput = Object.assign({}, defaultInput);

        var clearSelectedService =
            function() {
                step2Fields.existing_service.prop('checked', false).filter('[value="' + step2UserInput.existing_service + '"]').prop('checked', true).change();
                step2Fields.service_template.val(step2UserInput.service_template).change();
                Onkho.Validator.ResetValidation([step2Fields.existing_service, step2Fields.service_template]);
            };

        // This event is responsible for clearing all input.
        fieldsetServiceTemplate.on('clearInput',
            function() {
                clearSelectedService();
            }
        );

        // Enable or disable the service selection.
        step2Fields.existing_service.on('change',
            function() {
                var self = $(this);

                switch (self.val()) {
                    case '0' :
                        addServicePanelStep3.fadeIn();
                        step2Fields.service_template.val('').change();
                        step2Fields.service_template.prop('disabled', true);
                        break;
                    case '1' :
                        addServicePanelStep3.fadeOut();
                        step2Fields.service_template.prop('disabled', false);
                        step2Fields.service_template.val(Onkho.Functions.Select2Sort(step2Fields.service_template.find('option[value]')).first().attr('value')).change();
                        break;
                }

                updateStep2UserInput(defaultInput);
            }
        );

        // Update the selected service description.
        step2Fields.service_template.on('change',
            function() {
                if (Onkho.Validator.Validate(step2Fields.service_template) === 0) {
                    updateStep2UserInput(defaultInput);
                }

                var selectedServiceId          = step2Fields.service_template.val();
                var selectedServiceDescription = serviceDescriptions.filter('[data-service-definition-id="' + selectedServiceId + '"]');
                serviceDescriptions.filter(':visible').hide();
                selectedServiceDescription.show();
            }
        );

        step2Fields.service_template.select2({
            placeholder: 'Select a service template',
            sortResults: Onkho.Functions.Select2Sort
        });

        // Step 3 depends on this step, so only initially show the panel if we are editing a simple service.
        if (existingService && existingService.service_type === 'SIMPLE') {
            addServicePanelStep3.show();
        } else {
            addServicePanelStep3.hide();
        }

        // Manually trigger the 'clearInput' event the first time.
        fieldsetServiceTemplate.trigger('clearInput');
    };

    var initStep3 = function() {
        // Reference modal once.
        var modal = addServicePanelStep3.find('div#schedule-service-dialog');
        var currentScheduleDescriptionWrapper    = addServicePanelStep3.find('section.service-schedule span.service-schedule-description');
        var inProgressScheduleDescriptionWrapper = addServicePanelStep3.find('fieldset.in-progress-schedule section.service-schedule span.service-schedule-description');

        // Reference each fieldset once.
        var fieldsetReferenceDate    = modal.find('fieldset.reference-date');
        var fieldsetFrequency        = modal.find('fieldset.frequency');
        var fieldsetFrequencyDaily   = modal.find('fieldset.frequency-daily');
        var fieldsetFrequencyWeekly  = modal.find('fieldset.frequency-weekly');
        var fieldsetFrequencyMonthly = modal.find('fieldset.frequency-monthly');
        var fieldsetFrequencyYearly  = modal.find('fieldset.frequency-yearly');

        // Fields
        step3Fields = {
            starting_reference       : fieldsetReferenceDate.find('select[name="starting_reference"]'),
            starting_offset          : fieldsetReferenceDate.find('input[type="number"][name="starting_offset"]'),
            starting_time_unit       : fieldsetReferenceDate.find('select[name="starting_time_unit"]'),
            reference_date_attribute : fieldsetReferenceDate.find('select[name="reference_date_attribute"]'),
            service_repeats          : fieldsetFrequency.find('input[type="checkbox"][name="service_repeats"]'),
            frequency                : fieldsetFrequency.find('input[type="number"][name="frequency"]'),
            time_unit                : fieldsetFrequency.find('select[name="time_unit"]'),
            DAY                      : {
                excluding_weekends : fieldsetFrequencyDaily.find('input[type="checkbox"][name="excluding_weekends"]')
            },
            WEEK                     : {
                MON                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="MON"]'),
                TUE                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="TUE"]'),
                WED                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="WED"]'),
                THU                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="THU"]'),
                FRI                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="FRI"]'),
                SAT                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="SAT"]'),
                SUN                 : fieldsetFrequencyWeekly.find('input[type="checkbox"][name="day_of_week"][value="SUN"]'),
            },
            MONTH                    : {
                ordinal             : fieldsetFrequencyMonthly.find('select[name="ordinal"]'),
                time_unit           : fieldsetFrequencyMonthly.find('select[name="time_unit"]')
            },
            YEAR                     : {
                ordinal             : fieldsetFrequencyYearly.find('select[name="ordinal"]'),
                time_unit           : fieldsetFrequencyYearly.find('select[name="time_unit"]'),
                month               : fieldsetFrequencyYearly.find('select[name="month"]')
            }
        };

        var defaultInput = {
            starting_reference             : 'ON',
            starting_offset                : 1,
            starting_time_unit             : 'MONTH',
            reference_date_attribute       : '0',
            reference_date_attribute_label : '',
            service_repeats                : false,
            frequency                      : 1,
            time_unit                      : 'MONTH',
            DAY                            : {
                excluding_weekends : false
            },
            WEEK                     : {
                MON                : false,
                TUE                : false,
                WED                : false,
                THU                : false,
                FRI                : false,
                SAT                : false,
                SUN                : false
            },
            MONTH                    : {
                ordinal            : 'SAME',
                time_unit          : 'DAY'
            },
            YEAR                     : {
                ordinal            : 'SAME',
                time_unit          : 'DAY',
                month              : 'JAN'
            }
        };

        // Update default input if we're editing an existing service.
        if (existingService && existingService.service_type === 'SIMPLE') {
            defaultInput = Object.assign(defaultInput, JSON.parse(decodeURIComponent(existingService.service_schedule)));
            defaultInput.service_repeats        = toBoolean(defaultInput.service_repeats);
            defaultInput.DAY.excluding_weekends = toBoolean(defaultInput.DAY.excluding_weekends);
            defaultInput.WEEK.MON               = toBoolean(defaultInput.WEEK.MON);
            defaultInput.WEEK.TUE               = toBoolean(defaultInput.WEEK.TUE);
            defaultInput.WEEK.WED               = toBoolean(defaultInput.WEEK.WED);
            defaultInput.WEEK.THU               = toBoolean(defaultInput.WEEK.THU);
            defaultInput.WEEK.FRI               = toBoolean(defaultInput.WEEK.FRI);
            defaultInput.WEEK.SAT               = toBoolean(defaultInput.WEEK.SAT);
            defaultInput.WEEK.SUN               = toBoolean(defaultInput.WEEK.SUN);
        }

        step3UserInput = Object.assign({}, defaultInput);

        var clearFieldsetReferenceDate =
            function() {
                step3Fields.starting_reference.val(step3UserInput.starting_reference).change();
                step3Fields.starting_time_unit.val(step3UserInput.starting_time_unit).change();
                step3Fields.starting_offset.val(step3UserInput.starting_offset).change();
                resetDateAttributes(step3Fields.reference_date_attribute, step3UserInput.reference_date_attribute);
                Onkho.Validator.ResetValidation([step3Fields.starting_reference, step3Fields.starting_time_unit, step3Fields.starting_offset, step3Fields.reference_date_attribute]);
            };

        var clearFieldsetFrequency =
            function() {
                step3Fields.service_repeats.prop('checked', step3UserInput.service_repeats).change();
                step3Fields.frequency.val(step3UserInput.frequency).change();
                step3Fields.time_unit.val(step3UserInput.time_unit).change();
                Onkho.Validator.ResetValidation([step3Fields.service_repeats, step3Fields.frequency, step3Fields.time_unit]);
            };

        var clearFieldsetFrequencyDaily =
            function() {
                step3Fields.DAY.excluding_weekends.prop('checked', step3UserInput.DAY.excluding_weekends).change();
                Onkho.Validator.ResetValidation([step3Fields.DAY.excluding_weekends]);
            };

        var clearFieldsetFrequencyWeekly =
            function() {
                step3Fields.WEEK.MON.prop('checked', step3UserInput.WEEK.MON).change();
                step3Fields.WEEK.TUE.prop('checked', step3UserInput.WEEK.TUE).change();
                step3Fields.WEEK.WED.prop('checked', step3UserInput.WEEK.WED).change();
                step3Fields.WEEK.THU.prop('checked', step3UserInput.WEEK.THU).change();
                step3Fields.WEEK.FRI.prop('checked', step3UserInput.WEEK.FRI).change();
                step3Fields.WEEK.SAT.prop('checked', step3UserInput.WEEK.SAT).change();
                step3Fields.WEEK.SUN.prop('checked', step3UserInput.WEEK.SUN).change();
                Onkho.Validator.ResetValidation([step3Fields.WEEK.MON, step3Fields.WEEK.TUE, step3Fields.WEEK.WED, step3Fields.WEEK.THU, step3Fields.WEEK.FRI, step3Fields.WEEK.SAT, step3Fields.WEEK.SUN]);
            };

        var clearFieldsetFrequencyMonthly =
            function() {
                step3Fields.MONTH.ordinal.val(step3UserInput.MONTH.ordinal).change();
                step3Fields.MONTH.time_unit.val(step3UserInput.MONTH.time_unit).change();
                Onkho.Validator.ResetValidation([step3Fields.MONTH.ordinal, step3Fields.MONTH.time_unit]);
            };

        var clearFieldsetFrequencyYearly =
            function() {
                step3Fields.YEAR.ordinal.val(step3UserInput.YEAR.ordinal).change();
                step3Fields.YEAR.time_unit.val(step3UserInput.YEAR.time_unit).change();
                step3Fields.YEAR.month.val(step3UserInput.YEAR.month).change();
            };

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                clearFieldsetReferenceDate();
                clearFieldsetFrequency();
                clearFieldsetFrequencyDaily();
                clearFieldsetFrequencyWeekly();
                clearFieldsetFrequencyMonthly();
                clearFieldsetFrequencyYearly();
            }
        );

        // Show the dialog.
        addServicePanelFormStep3.find('button[name=add-service-schedule]').on('click',
            function() {
                modal.modal('show').trigger('clearInput');
            }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
            function() {
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Reset
        modal.find('div.modal-footer [name="reset"]').on('click',
            function() {
                step3UserInput = Object.assign({}, defaultInput);
                updateStep3Description(step3UserInput, currentScheduleDescriptionWrapper);
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
            function() {
                if (Onkho.Validator.ValidateChildren(modal)) {
                    updateStep3UserInput(step3UserInput, defaultInput);
                    updateStep3Description(step3UserInput, currentScheduleDescriptionWrapper);
                    modal.modal('hide').trigger('clearInput');
                }
            }
        );

        // Only display the before/after options if the user selects a respective starting reference.
        step3Fields.starting_reference.on('change',
            function() {
                var self  = $(this);
                var value = self.val();
                var beforeAfter = fieldsetReferenceDate.find('section.before-after span.before-after');

                switch (value) {
                    case 'ON' :
                        beforeAfter.hide();
                        break;
                    default :
                        beforeAfter.show();
                        break;
                }
            }
        );

        // Update the display text of each time unit.
        step3Fields.starting_offset.on('change',
            function() {
                var self = $(this);
                var offsetValue = self.val();

                step3Fields.starting_time_unit.find('option').each(function() {
                    var option = $(this);
                    var optionValue = option.val();
                    switch (optionValue) {
                        case 'DAY'   :
                            option.html((offsetValue === '1') ? 'day'   : 'days');
                            break;

                        case 'WEEK'  :
                            option.html((offsetValue === '1') ? 'week'  : 'weeks');
                            break;

                        case 'MONTH' :
                            option.html((offsetValue === '1') ? 'month' : 'months');
                            break;

                        case 'YEAR'  :
                            option.html((offsetValue === '1') ? 'year'  : 'years');
                            break;
                    }
                });
            }
        );

        // Only display the repeat schedule if the user opts to repeat this service.
        step3Fields.service_repeats.on('change',
            function() {
                var self = $(this);
                var shouldRepeat = self.is(':checked');
                var sectionRepeatSchedule = fieldsetFrequency.find('section.service-repeat-schedule');

                if (shouldRepeat) {
                    sectionRepeatSchedule.show();
                } else {
                    sectionRepeatSchedule.hide();
                }

                step3Fields.time_unit.change();
            }
        );

        // Update the display text of each time unit.
        step3Fields.frequency.on('change',
            function() {
                var self = $(this);
                var frequencyValue = self.val();

                step3Fields.time_unit.find('option').each(function() {
                    var option = $(this);
                    var optionValue = option.val();
                    switch (optionValue) {
                        case 'DAY'   :
                            option.html((frequencyValue === '1') ? 'day'   : 'days');
                            break;

                        case 'WEEK'  :
                            option.html((frequencyValue === '1') ? 'week'  : 'weeks');
                            break;

                        case 'MONTH' :
                            option.html((frequencyValue === '1') ? 'month' : 'months');
                            break;

                        case 'YEAR'  :
                            option.html((frequencyValue === '1') ? 'year'  : 'years');
                            break;
                    }
                });
            }
        );

        // Display the correct repeating options depending on the time unit selected.
        step3Fields.time_unit.on('change',
            function() {
                var self = $(this);
                var timeUnitValue = self.val();

                addServicePanelStep3.find('div.col-time-unit').hide();

                switch (timeUnitValue) {
                    case 'DAY'   :
                        if (step3Fields.service_repeats.is(':checked')) {
                            addServicePanelStep3.find('div.col-time-unit.col-frequency-daily').show().removeClass('hidden');
                        }
                        break;

                    case 'WEEK'  :
                        if (step3Fields.service_repeats.is(':checked')) {
                            addServicePanelStep3.find('div.col-time-unit.col-frequency-weekly').show().removeClass('hidden');
                        }
                        break;

                    case 'MONTH' :
                        if (step3Fields.service_repeats.is(':checked')) {
                            addServicePanelStep3.find('div.col-time-unit.col-frequency-monthly').show().removeClass('hidden');
                        }
                        break;

                    case 'YEAR'  :
                        if (step3Fields.service_repeats.is(':checked')) {
                            addServicePanelStep3.find('div.col-time-unit.col-frequency-yearly').show().removeClass('hidden');
                        }
                        break;
                }
            }
        );

        step3Fields.MONTH.time_unit.on('change',
            function() {
                var self = $(this);
                var timeUnitValue = self.val();
                var currentOrdinalValue = step3Fields.MONTH.ordinal.val();

                switch (timeUnitValue) {
                    case 'DAY'   :
                        step3Fields.MONTH.ordinal.empty().append(
                            '<option value="SAME">same</option>'  +
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="5">5th</option>'      +
                            '<option value="6">6th</option>'      +
                            '<option value="7">7th</option>'      +
                            '<option value="8">8th</option>'      +
                            '<option value="9">9th</option>'      +
                            '<option value="10">10th</option>'    +
                            '<option value="11">11th</option>'    +
                            '<option value="12">12th</option>'    +
                            '<option value="13">13th</option>'    +
                            '<option value="14">14th</option>'    +
                            '<option value="15">15th</option>'    +
                            '<option value="16">16th</option>'    +
                            '<option value="17">17th</option>'    +
                            '<option value="18">18th</option>'    +
                            '<option value="19">19th</option>'    +
                            '<option value="20">20th</option>'    +
                            '<option value="21">21st</option>'    +
                            '<option value="22">22nd</option>'    +
                            '<option value="23">23rd</option>'    +
                            '<option value="24">24th</option>'    +
                            '<option value="25">25th</option>'    +
                            '<option value="26">26th</option>'    +
                            '<option value="27">27th</option>'    +
                            '<option value="28">28th</option>'    +
                            '<option value="29">29th</option>'    +
                            '<option value="30">30th</option>'    +
                            '<option value="31">31st</option>'    +
                            '<option value="LAST">last</option>'
                        );
                        break;

                    case 'WEEKDAY' :
                        step3Fields.MONTH.ordinal.empty().append(
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="LAST">last</option>'
                        );
                        break;

                    default :
                        step3Fields.MONTH.ordinal.empty().append(
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="LAST">last</option>'
                        );
                        break;
                }

                // Only change the current selected option if it is no longer available.
                if (step3Fields.MONTH.ordinal.find('option[value="' + currentOrdinalValue + '"]').length === 0) {
                    var firstOption = step3Fields.MONTH.ordinal.find('option').first();
                    step3Fields.MONTH.ordinal.val(firstOption.attr('value')).change();
                } else {
                    step3Fields.MONTH.ordinal.val(currentOrdinalValue);
                }
            }
        );

        step3Fields.YEAR.time_unit.on('change',
            function() {
                var self = $(this);
                var timeUnitValue = self.val();
                var currentOrdinalValue = step3Fields.YEAR.ordinal.val();

                switch (timeUnitValue) {
                    case 'DAY'   :
                        step3Fields.YEAR.ordinal.empty().append(
                            '<option value="SAME">same</option>'  +
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="5">5th</option>'      +
                            '<option value="6">6th</option>'      +
                            '<option value="7">7th</option>'      +
                            '<option value="8">8th</option>'      +
                            '<option value="9">9th</option>'      +
                            '<option value="10">10th</option>'    +
                            '<option value="11">11th</option>'    +
                            '<option value="12">12th</option>'    +
                            '<option value="13">13th</option>'    +
                            '<option value="14">14th</option>'    +
                            '<option value="15">15th</option>'    +
                            '<option value="16">16th</option>'    +
                            '<option value="17">17th</option>'    +
                            '<option value="18">18th</option>'    +
                            '<option value="19">19th</option>'    +
                            '<option value="20">20th</option>'    +
                            '<option value="21">21st</option>'    +
                            '<option value="22">22nd</option>'    +
                            '<option value="23">23rd</option>'    +
                            '<option value="24">24th</option>'    +
                            '<option value="25">25th</option>'    +
                            '<option value="26">26th</option>'    +
                            '<option value="27">27th</option>'    +
                            '<option value="28">28th</option>'    +
                            '<option value="29">29th</option>'    +
                            '<option value="30">30th</option>'    +
                            '<option value="31">31st</option>'    +
                            '<option value="LAST">last</option>'
                        );
                        break;

                    case 'WEEKDAY' :
                        step3Fields.YEAR.ordinal.empty().append(
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="LAST">last</option>'
                        );
                        break;

                    default :
                        step3Fields.YEAR.ordinal.empty().append(
                            '<option value="FIRST">1st</option>'  +
                            '<option value="SECOND">2nd</option>' +
                            '<option value="THIRD">3rd</option>'  +
                            '<option value="FOURTH">4th</option>' +
                            '<option value="LAST">last</option>'
                        );
                        break;
                }

                // Only change the current selected option if it is no longer available.
                if (step3Fields.YEAR.ordinal.find('option[value="' + currentOrdinalValue + '"]').length === 0) {
                    var firstOption = step3Fields.YEAR.ordinal.find('option').first();
                    step3Fields.YEAR.ordinal.val(firstOption.attr('value')).change();
                } else {
                    step3Fields.YEAR.ordinal.val(currentOrdinalValue);
                }
            }
        );

        step3Fields.YEAR.ordinal.on('change',
            function() {
                var self = $(this);
                var ordinalValue = self.val();

                if (ordinalValue === 'SAME') {
                    fieldsetFrequencyYearly.find('label.label-in-month').children().first().html('of the year');
                    fieldsetFrequencyYearly.find('label.select.month').hide();
                } else {
                    fieldsetFrequencyYearly.find('label.label-in-month').children().first().html('in');
                    fieldsetFrequencyYearly.find('label.select.month').show();
                }
            }
        );

        step3Fields.reference_date_attribute.select2({
            width:       '385px',
            placeholder: 'Select a reference date',
            sortResults: Onkho.Functions.Select2Sort
        });

        // Update the WIP description of the schedule being built.
        modal.on('change onkho:validation.valid onkho:validation.pending onkho:validation.invalid',
            function(event) {
                if (Onkho.Validator.IsValid(modal)) {
                    var tmpUserInput = Object.assign({}, step3UserInput);
                    updateStep3UserInput(tmpUserInput, defaultInput);
                    updateStep3Description(tmpUserInput, inProgressScheduleDescriptionWrapper);
                } else {
                    inProgressScheduleDescriptionWrapper.html('Fix any validation errors to see a description of your schedule here.');
                }
                event.stopPropagation();
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        modal.trigger('clearInput');
        updateStep3Description(step3UserInput, currentScheduleDescriptionWrapper);
    };

    var initStep4 = function() {
        // Reference modal once.
        var modal = addServicePanelStep4.find('div#add-precondition-dialog')
        var preconditionsWrapper = addServicePanelFormStep4.find('fieldset.service-requirements section.service-requirements');

        var activityFeedItemBuilder = modal.find('.activity-feed-item-builder');
        var clockPicker = modal.find('input.time');

        // Fields
        step4Fields = {
            precondition_name: modal.find('input[type="text"][name="precondition_name"]'),
            precondition_notification_offset: modal.find('input[type="number"][name="precondition_notification_offset"]'),
            precondition_notification_time_unit: modal.find('select[name="precondition_notification_time_unit"]'),
            precondition_notification_time: modal.find('input[type="text"][name="precondition_notification_time"]'),
            precondition_reminder: modal.find('input[type="checkbox"][name="precondition_reminder"]'),
            precondition_reminder_frequency: modal.find('input[type="number"][name="precondition_reminder_frequency"]'),
            precondition_reminder_time_unit: modal.find('select[name="precondition_reminder_time_unit"]'),
            precondition_reminder_time: modal.find('input[type="text"][name="precondition_reminder_time"]'),
            precondition_notification_activity_feed_item_from_mailbox_id : modal.find('input[name="from_mailbox_id"]'),
            precondition_notification_activity_feed_item_subject: modal.find('input[name="subject"]'),
            precondition_notification_activity_feed_item_content: modal.find('.summernote.content-area')
        }

        var defaultInput = {
            precondition_name: '',
            precondition_notification_offset: 1,
            precondition_notification_time_unit: 'WEEK',
            precondition_notification_time: '09:00',
            precondition_reminder: false,
            precondition_reminder_frequency: 1,
            precondition_reminder_time_unit: 'DAY',
            precondition_reminder_time: '09:00',
            precondition_notification_activity_feed_item_from_mailbox_id: null,
            precondition_notification_activity_feed_item_subject: '',
            precondition_notification_activity_feed_item_content: ''
        }

        // Update default input if we're editing an existing service.
        if (existingService) {
            step4Requirements = Object.assign({}, existingService.service_requirements)
            step4Ordinal = Object.keys(step4Requirements).length + 1
        } else {
            step4Requirements = {}
            step4Ordinal = 1
        }

        step4UserInput = Object.assign({}, defaultInput);

        Onkho.ActivityFeed.InitializeRecipientsPicker(activityFeedItemBuilder, 'OUTGOING_EMAIL');

        var clearPreconditionName =
            function() {
                step4Fields.precondition_name.val(step4UserInput.precondition_name).change();
                Onkho.Validator.ResetValidation([step4Fields.precondition_name]);
            };

        var clearPreconditionNotification =
            function() {
                step4Fields.precondition_notification_time_unit.val(step4UserInput.precondition_notification_time_unit).change();
                step4Fields.precondition_notification_offset.val(step4UserInput.precondition_notification_offset).change();
                step4Fields.precondition_notification_time.val(step4UserInput.precondition_notification_time).change();
                Onkho.Validator.ResetValidation([step4Fields.precondition_notification_time_unit, step4Fields.precondition_notification_offset, step4Fields.precondition_notification_time]);
            };

        var clearPreconditionReminder =
            function() {
                step4Fields.precondition_reminder.prop('checked', step4UserInput.precondition_reminder).change();
                step4Fields.precondition_reminder_time_unit.val(step4UserInput.precondition_reminder_time_unit).change();
                step4Fields.precondition_reminder_frequency.val(step4UserInput.precondition_reminder_frequency).change();
                step4Fields.precondition_reminder_time.val(step4UserInput.precondition_reminder_time).change();
                Onkho.Validator.ResetValidation([step4Fields.precondition_reminder, step4Fields.precondition_reminder_time_unit, step4Fields.precondition_reminder_frequency, step4Fields.precondition_reminder_time]);
            };

        var clearPreconditionNotificationActivityFeedItemFromMailboxId =
          function() {
              if (step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id) {
                  step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('data', {
                      id: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id,
                      address: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_address,
                      practice_name: step4UserInput.precondition_notification_activity_feed_item_from_mailbox_practice_name
                  });
              } else {
                  step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('val', '');
              }
              Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_from_mailbox_id]);
          };

        var clearPreconditionNotificationActivityFeedItemSubject =
          function() {
              step4Fields.precondition_notification_activity_feed_item_subject.val(step4UserInput.precondition_notification_activity_feed_item_subject).change();
              Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_subject]);
          };

        var clearPreconditionNotificationActivityFeedItemContent =
          function() {
              Onkho.Summernote.DestroySummernote(
                step4Fields.precondition_notification_activity_feed_item_content
              );

              Onkho.Validator.ResetValidation([step4Fields.precondition_notification_activity_feed_item_content]);
          };

        var removeRequirement =
            function() {
                var oldRequirement = Object.assign({}, step4UserInput);
                step4UserInput     = Object.assign({}, defaultInput);

                delete step4Requirements[oldRequirement.precondition_name];
                updateStep4ConditionsTable(preconditionsWrapper);
            };

        var addRequirement =
            function() {
                // Keep hold of the old requirement.
                var oldRequirement = Object.assign({}, step4UserInput);

                // Update the user input model with the updated requirement.
                updateStep4UserInput(defaultInput);
                var newRequirement = Object.assign({}, step4UserInput);
                // Re-build the requirement description.
                newRequirement.description = getStep4Description(newRequirement);
                newRequirement.ordinal     = (oldRequirement.ordinal) ? oldRequirement.ordinal : step4Ordinal++;

                // Reset the user-input.
                step4UserInput = Object.assign({}, defaultInput);

                // Remove the old requirement if the name has changed.
                // TODO: This may impact ordering. Does it matter?
                if (oldRequirement.precondition_name && oldRequirement.precondition_name !== newRequirement.precondition_name) {
                    delete step4Requirements[oldRequirement.precondition_name];
                }

                // Set the new requirement.
                step4Requirements[newRequirement.precondition_name] = newRequirement;
                updateStep4ConditionsTable(preconditionsWrapper);
            };

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                clearPreconditionName();
                clearPreconditionNotification();
                clearPreconditionReminder();
                clearPreconditionNotificationActivityFeedItemFromMailboxId();
                clearPreconditionNotificationActivityFeedItemSubject();
                clearPreconditionNotificationActivityFeedItemContent();
            }
        );

        // This event is responsible for initialising the summernote WYSIWYG editor.
        modal.on('initSummernote',
            function() {
                Onkho.Summernote.EnableSummernote(
                    step4Fields.precondition_notification_activity_feed_item_content, 'JOB'
                );
                Onkho.Summernote.UpdateSummernote(
                    step4Fields.precondition_notification_activity_feed_item_content, step4UserInput.precondition_notification_activity_feed_item_content, false
                );
            }
        );

        // Show the dialog.
        addServicePanelFormStep4.find('button[name=add-precondition]').on('click',
            function() {
                step4UserInput = Object.assign({}, defaultInput);
                modal.modal('show').trigger('clearInput').trigger('initSummernote');
            }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
            function() {
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Delete
        modal.find('div.modal-footer [name="delete"]').on('click',
            function() {
                removeRequirement();
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
            function() {
                if (Onkho.Validator.ValidateChildren(modal)) {
                    addRequirement();
                    modal.modal('hide').trigger('clearInput');
                }
            }
        );

        // Save and add another
        modal.find('div.modal-footer [name="save-add"]').on('click',
            function() {
                if (Onkho.Validator.ValidateChildren(modal)) {
                    addRequirement();
                    modal.trigger('clearInput').trigger('initSummernote');
                }
            }
        );

        // Update an existing requirement.
        addServicePanelFormStep4.on('click', 'fieldset.service-requirements section.service-requirements [name="update"]',
            function() {
                var self = $(this);
                var conditionName = self.closest('[data-precondition]').data('precondition-name');
                step4UserInput = Object.assign({}, step4Requirements[conditionName]);
                modal.modal('show').trigger('clearInput').trigger('initSummernote');
            }
        );

        // Delete an existing requirement.
        addServicePanelFormStep4.on('click', 'fieldset.service-requirements section.service-requirements [name="delete"]',
            function() {
                var self = $(this);
                var conditionName = self.closest('[data-precondition]').data('precondition-name');

                $.SmartMessageBox({
                    title :   "Delete requirement",
                    content : "Do you want to delete the requirement '<strong>" + conditionName + "</strong>' ?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress) {
                    if (ButtonPress === 'Yes') {
                        delete step4Requirements[conditionName];
                        updateStep4ConditionsTable(preconditionsWrapper);
                    }
                });
            }
        );

        // Re-order a requirement.
        addServicePanelFormStep4.on('click', 'fieldset.service-requirements section.service-requirements [name="up"]',
            function() {
                var self = $(this).closest('[data-precondition]');
                var prev = self.prev('[data-precondition]');

                var thisRequirement = step4Requirements[self.data('precondition-name')];
                var prevRequirement = step4Requirements[prev.data('precondition-name')];

                var thisOrdinal = thisRequirement.ordinal;
                var prevOrdinal = prevRequirement.ordinal;

                // Switch the ordinal values.
                thisRequirement.ordinal = prevOrdinal;
                prevRequirement.ordinal = thisOrdinal;
                updateStep4ConditionsTable(preconditionsWrapper);
            }
        );

        // Re-order a requirement.
        addServicePanelFormStep4.on('click', 'fieldset.service-requirements section.service-requirements [name="down"]',
            function() {
                var self = $(this).closest('[data-precondition]');
                var prev = self.next('[data-precondition]');

                var thisRequirement = step4Requirements[self.data('precondition-name')];
                var prevRequirement = step4Requirements[prev.data('precondition-name')];

                var thisOrdinal = thisRequirement.ordinal;
                var prevOrdinal = prevRequirement.ordinal;

                // Switch the ordinal values.
                thisRequirement.ordinal = prevOrdinal;
                prevRequirement.ordinal = thisOrdinal;
                updateStep4ConditionsTable(preconditionsWrapper);
            }
        );

        // Update the display text of each time unit.
        step4Fields.precondition_notification_offset.on('change',
            function() {
                var self = $(this);
                var offsetValue = self.val();

                step4Fields.precondition_notification_time_unit.find('option').each(function() {
                    var option = $(this);
                    var optionValue = option.val();
                    switch (optionValue) {
                        case 'DAY'   :
                            option.html((offsetValue === '1') ? 'day'   : 'days');
                            break;

                        case 'WEEK'  :
                            option.html((offsetValue === '1') ? 'week'  : 'weeks');
                            break;

                        case 'MONTH' :
                            option.html((offsetValue === '1') ? 'month' : 'months');
                            break;

                        case 'YEAR'  :
                            option.html((offsetValue === '1') ? 'year'  : 'years');
                            break;
                    }
                });

                updateAvailableReminders();
            }
        );

        // Update the valid range of reminder time units allowed depending on the selected time unit of the initial offset.
        step4Fields.precondition_notification_time_unit.on('change',
            function() {
                var self = $(this);
                var timeUnitValue = self.val();

                switch (timeUnitValue) {
                    case 'DAY'   :
                        step4Fields.precondition_reminder_time_unit.empty().append(
                            '<option value="DAY">day</option>'
                        ).val('DAY').change();
                        break;

                    case 'WEEK'  :
                        step4Fields.precondition_reminder_time_unit.empty().append(
                            '<option value="DAY">day</option>'
                        ).val('DAY').change();
                        break;

                    case 'MONTH' :
                        step4Fields.precondition_reminder_time_unit.empty().append(
                            '<option value="DAY">day</option>'     +
                            '<option value="WEEK">week</option>'
                        ).val('WEEK').change();
                        break;

                    case 'YEAR'  :
                        step4Fields.precondition_reminder_time_unit.empty().append(
                            '<option value="DAY">day</option>'     +
                            '<option value="WEEK">week</option>'   +
                            '<option value="MONTH">month</option>'
                        ).val('MONTH').change();
                        break;
                }

                updateAvailableReminders();
            }
        );

        // Only display the reminder schedule if the user opts for a recurring reminder.
        step4Fields.precondition_reminder.on('change',
            function() {
                var self = $(this);
                var shouldRemind = self.is(':checked');

                if (shouldRemind) {
                    modal.find('section.precondition-reminder').show();
                } else {
                    modal.find('section.precondition-reminder').hide();
                }
            }
        );

        // Update the display text of each time unit.
        step4Fields.precondition_reminder_frequency.on('change',
            function() {
                var self = $(this);
                var offsetValue = self.val();

                step4Fields.precondition_reminder_time_unit.find('option').each(function() {
                    var option = $(this);
                    var optionValue = option.val();
                    switch (optionValue) {
                        case 'DAY'   :
                            option.html((offsetValue === '1') ? 'day'   : 'days');
                            break;

                        case 'WEEK'  :
                            option.html((offsetValue === '1') ? 'week'  : 'weeks');
                            break;

                        case 'MONTH' :
                            option.html((offsetValue === '1') ? 'month' : 'months');
                            break;

                        case 'YEAR'  :
                            option.html((offsetValue === '1') ? 'year'  : 'years');
                            break;
                    }
                });
            }
        );

        // Update the valid frequency range allowed depending on the time unit selected.
        step4Fields.precondition_reminder_time_unit.on('change',
            function() {
                updateAvailableReminders();
            }
        );

        var updateAvailableReminders = function() {
            // The current notification offset and time-unit.
            var notificationTimeUnit = step4Fields.precondition_notification_time_unit.val();
            var notificationOffset   = step4Fields.precondition_notification_offset.val();

            // The current reminder offset and time-unit.
            var reminderTimeUnit     = step4Fields.precondition_reminder_time_unit.val();
            var reminderFrequency    = step4Fields.precondition_reminder_frequency.val();

            var max = 1;
            var min = 1;

            var maxDays   = 1;
            var maxWeeks  = 1;
            var maxMonths = 1;
            var maxYears  = 1;

            if ($.isNumeric(notificationOffset)) {
                notificationOffset = parseInt(notificationOffset);
                switch (notificationTimeUnit) {
                    case 'DAY'   :
                        maxDays   = Math.max(min, (notificationOffset -   1));
                        break;

                    case 'WEEK'  :
                        maxDays   = Math.max(min, (notificationOffset *   7) - 1);
                        maxWeeks  = Math.max(min, (notificationOffset -   1));
                        break;

                    case 'MONTH' :
                        maxDays   = Math.max(min, (notificationOffset *  30) - 1);
                        maxWeeks  = Math.max(min, (notificationOffset *   4) - 1);
                        break;

                    case 'YEAR'  :
                        maxDays   = Math.max(min, (notificationOffset * 365) - 1);
                        maxWeeks  = Math.max(min, (notificationOffset *  52) - 1);
                        maxMonths = Math.max(min, (notificationOffset *  12) - 1);
                        maxYears  = Math.max(min, (notificationOffset -   1));
                        break;
                }
            }

            switch (reminderTimeUnit) {
                case 'DAY'   :
                    step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxDays));
                    break;

                case 'WEEK'  :
                    step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxWeeks));
                    break;

                case 'MONTH' :
                    step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxMonths));
                    break;

                case 'YEAR'  :
                    step4Fields.precondition_reminder_frequency.attr('min', 1).attr('max', (max = maxYears));
                    break;
            }

            if ($.isNumeric(reminderFrequency) && parseInt(reminderFrequency) > max) {
                step4Fields.precondition_reminder_frequency.val(max).change();
            }

            if ($.isNumeric(reminderFrequency) && parseInt(reminderFrequency) < min) {
                step4Fields.precondition_reminder_frequency.val(min).change();
            }
        };

        clockPicker.clockpicker({
            placement: 'bottom',
            align:     'left',
            autoclose: true
        });

        // Manually trigger the 'clearInput' event the first time.
        modal.trigger('clearInput');
        updateStep4ConditionsTable(preconditionsWrapper)
    };

    var initStep5 = function() {
        // Reference modal once.
        var modal = addServicePanelStep5.find('div#add-step-dialog');
        var stepsWrapper = addServicePanelFormStep5.find('fieldset.service-steps section.service-steps');

        var activityFeedItemBuilder = modal.find('.activity-feed-item-builder');

        // Fields
        step5Fields = {
            step_name: modal.find('input[type="text"][name="step_name"]'),
            step_complete_activity_feed_item: modal.find('input[type="checkbox"][name="step_complete_activity_feed_item"]'),
            step_complete_activity_feed_item_from_mailbox_id : modal.find('input[name="from_mailbox_id"]'),
            step_complete_activity_feed_item_subject: modal.find('input[name="subject"]'),
            step_complete_activity_feed_item_content: modal.find('.summernote.content-area')
        };


        var defaultInput = {
            step_name: '',
            step_complete_activity_feed_item: false,
            step_complete_activity_feed_item_from_mailbox_id: null,
            step_complete_activity_feed_item_subject: '',
            step_complete_activity_feed_item_content: ''
        };

        // Update default input if we're editing an existing service.
        if (existingService) {
            step5Steps   = Object.assign({}, existingService.service_steps);
            step5Ordinal = Object.keys(step5Steps).length + 1;
        } else {
            step5Steps   = {};
            step5Ordinal = 1;
        }

        step5UserInput = Object.assign({}, defaultInput);

        Onkho.ActivityFeed.InitializeRecipientsPicker(activityFeedItemBuilder, 'OUTGOING_EMAIL');

        var clearStepName =
            function() {
                step5Fields.step_name.val(step5UserInput.step_name).change();
                Onkho.Validator.ResetValidation([step5Fields.step_name]);
            };

        var clearStepCompleteActivityFeedItem =
            function() {
                step5Fields.step_complete_activity_feed_item.prop('checked', step5UserInput.step_complete_activity_feed_item).change();
                Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item]);
            };

        var clearStepCompleteActivityFeedItemFromMailboxId =
          function() {
              if (step5UserInput.step_complete_activity_feed_item_from_mailbox_id) {
                  step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('data', {
                      id: step5UserInput.step_complete_activity_feed_item_from_mailbox_id,
                      address: step5UserInput.step_complete_activity_feed_item_from_mailbox_address,
                      practice_name: step5UserInput.step_complete_activity_feed_item_from_mailbox_practice_name
                  });
              } else {
                  step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('val', '');
              }
              Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_from_mailbox_id]);
          };

        var clearStepCompleteActivityFeedItemSubject =
          function() {
              step5Fields.step_complete_activity_feed_item_subject.val(step5UserInput.step_complete_activity_feed_item_subject).change();
              Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_subject]);
          };

        var clearStepCompleteActivityFeedItemContent =
          function() {
              Onkho.Summernote.DestroySummernote(
                step5Fields.step_complete_activity_feed_item_content
              );

              Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_content]);
          };

        var removeStep =
            function() {
                var oldStep    = Object.assign({}, step5UserInput);
                step5UserInput = Object.assign({}, defaultInput);

                delete step5Steps[oldStep.step_name];
                updateStep5StepsTable(stepsWrapper);
                modal.modal('hide').trigger('clearInput');
            };

        var addStep =
            function() {
                // Keep hold of the old step.
                var oldStep = Object.assign({}, step5UserInput);

                // Update the user input model with the updated step.
                updateStep5UserInput(defaultInput);
                var newStep = Object.assign({}, step5UserInput);
                // Re-build the step description.
                newStep.description = getStep5Description(newStep);
                newStep.ordinal     = (oldStep.ordinal) ? oldStep.ordinal : step5Ordinal++;

                // Reset the user-input.
                step5UserInput = Object.assign({}, defaultInput);

                // Remove the old step if the name has changed.
                // TODO: This may impact ordering. Does it matter?
                if (oldStep.step_name && oldStep.step_name !== newStep.step_name) {
                    delete step5Steps[oldStep.step_name];
                }

                // Set the new step.
                step5Steps[newStep.step_name] = newStep;
                updateStep5StepsTable(stepsWrapper);
            };

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                clearStepName();
                clearStepCompleteActivityFeedItem();
                clearStepCompleteActivityFeedItemFromMailboxId();
                clearStepCompleteActivityFeedItemSubject();
                clearStepCompleteActivityFeedItemContent();
            }
        );

        // This event is responsible for initialising the summernote WYSIWYG editor.
        modal.on('initSummernote',
            function() {
                if (step5UserInput.step_complete_activity_feed_item) {
                    Onkho.Summernote.EnableSummernote(
                        step5Fields.step_complete_activity_feed_item_content, 'JOB'
                    );
                    Onkho.Summernote.UpdateSummernote(
                        step5Fields.step_complete_activity_feed_item_content, step5UserInput.step_complete_activity_feed_item_content, false
                    );
                } else {
                    Onkho.Summernote.DestroySummernote(
                        step5Fields.step_complete_activity_feed_item_content
                    );
                }
            }
        );

        // Show the dialog.
        addServicePanelFormStep5.find('button[name=add-step]').on('click',
            function() {
                step5UserInput = Object.assign({}, defaultInput);
                modal.modal('show').trigger('clearInput').trigger('initSummernote');
            }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
            function() {
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Delete
        modal.find('div.modal-footer [name="delete"]').on('click',
            function() {
                removeStep();
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
            function() {
                if (Onkho.Validator.ValidateChildren(modal)) {
                    addStep();
                    modal.modal('hide').trigger('clearInput');
                }
            }
        );

        // Save and add another
        modal.find('div.modal-footer [name="save-add"]').on('click',
            function() {
                if (Onkho.Validator.ValidateChildren(modal)) {
                    addStep();
                    modal.trigger('clearInput').trigger('initSummernote');
                }
            }
        );

        // Update an existing step.
        addServicePanelFormStep5.on('click', 'fieldset.service-steps section.service-steps [name="update"]',
            function() {
                var self = $(this);
                var stepName = self.closest('[data-step]').data('step-name');
                step5UserInput = Object.assign({}, step5Steps[stepName]);
                modal.modal('show').trigger('clearInput').trigger('initSummernote');
            }
        );

        // Delete an existing step.
        addServicePanelFormStep5.on('click', 'fieldset.service-steps section.service-steps [name="delete"]',
            function() {
                var self = $(this);
                var stepName = self.closest('[data-step]').data('step-name');

                $.SmartMessageBox({
                    title :   "Delete step",
                    content : "Do you want to delete the step '<strong>" + stepName + "</strong>' ?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress) {
                    if (ButtonPress === 'Yes') {
                        delete step5Steps[stepName];
                        updateStep5StepsTable(stepsWrapper);
                    }
                });
            }
        );

        // Re-order a step.
        addServicePanelFormStep5.on('click', 'fieldset.service-steps section.service-steps [name="up"]',
            function() {
                var self = $(this).closest('[data-step]');
                var prev = self.prev('[data-step]');

                var thisStep = step5Steps[self.data('step-name')];
                var prevStep = step5Steps[prev.data('step-name')];

                var thisOrdinal = thisStep.ordinal;
                var prevOrdinal = prevStep.ordinal;

                // Switch the ordinal values.
                thisStep.ordinal = prevOrdinal;
                prevStep.ordinal = thisOrdinal;
                updateStep5StepsTable(stepsWrapper);
            }
        );

        // Re-order a step.
        addServicePanelFormStep5.on('click', 'fieldset.service-steps section.service-steps [name="down"]',
            function() {
                var self = $(this).closest('[data-step]');
                var prev = self.next('[data-step]');

                var thisStep = step5Steps[self.data('step-name')];
                var prevStep = step5Steps[prev.data('step-name')];

                var thisOrdinal = thisStep.ordinal;
                var prevOrdinal = prevStep.ordinal;

                // Switch the ordinal values.
                thisStep.ordinal = prevOrdinal;
                prevStep.ordinal = thisOrdinal;
                updateStep5StepsTable(stepsWrapper);
            }
        );

        // Show the message input if a user opts to send a notification with this step.
        step5Fields.step_complete_activity_feed_item.on('change',
            function() {
                var self = $(this);
                var isChecked  = self.is(':checked');

                if (isChecked) {
                    Onkho.Summernote.EnableSummernote(
                        step5Fields.step_complete_activity_feed_item_content, 'JOB'
                    );

                    Onkho.Summernote.UpdateSummernote(
                        step5Fields.step_complete_activity_feed_item_content, step5UserInput.step_complete_activity_feed_item_content, false
                    );

                    Onkho.Validator.ResetValidation([step5Fields.step_complete_activity_feed_item_content]);

                    activityFeedItemBuilder.slideDown(function() {
                        step5Fields.step_complete_activity_feed_item_content.summernote('focus');
                    });
                } else {
                    activityFeedItemBuilder.slideUp(function() {
                        Onkho.Summernote.DestroySummernote(step5Fields.step_complete_activity_feed_item_content);
                    });
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        modal.trigger('clearInput');
        updateStep5StepsTable(stepsWrapper)
    };

    var initStep6 = function() {
        // Reference modal once.
        var modal = addServicePanelStep6.find('div#add-service-messages-dialog')
        var startMessageWrapper = addServicePanelFormStep6.find('fieldset.service-messages').find('section.start-message')
        var completeMessageWrapper = addServicePanelFormStep6.find('fieldset.service-messages').find('section.complete-message')

        var startActivityFeedItemBuilder = modal.find('fieldset.start-message + .activity-feed-item-builder');
        var completeActivityFeedItemBuilder = modal.find('fieldset.complete-message + .activity-feed-item-builder');

        var spanOnOffSwitch = addServicePanelFormStep6.find('.onoffswitch')

        step6Fields = {
            start_notification_activity_feed_item_configuration: {
                from_mailbox_id : startActivityFeedItemBuilder.find('input[name="from_mailbox_id"]'),
                subject: startActivityFeedItemBuilder.find('input[name="subject"]'),
                content: startActivityFeedItemBuilder.find('.summernote.content-area'),
            },

            complete_notification_activity_feed_item_configuration: {
                from_mailbox_id : completeActivityFeedItemBuilder.find('input[name="from_mailbox_id"]'),
                subject: completeActivityFeedItemBuilder.find('input[name="subject"]'),
                content: completeActivityFeedItemBuilder.find('.summernote.content-area'),
            },

            sends_notifications: spanOnOffSwitch.find('input[type="checkbox"][name="sends_notifications"]')
        }

        var defaultInput = {
            start_notification_activity_feed_item_configuration: {
                from_mailbox_id: null,
                subject: '',
                content: '',
            },
            
            complete_notification_activity_feed_item_configuration: {
                from_mailbox_id: null,
                subject: '',
                content: '',
            },

            sends_notifications: 0
        }

        // Update default input if we're editing an existing service.
        if (existingService) {
            var startNotificationActivityFeedItemConfiguration = existingService.start_notification_activity_feed_item_configuration;
            defaultInput.start_notification_activity_feed_item_configuration.from_mailbox_id = startNotificationActivityFeedItemConfiguration.from_mailbox_id;
            defaultInput.start_notification_activity_feed_item_configuration.from_mailbox_address = startNotificationActivityFeedItemConfiguration.from_mailbox_address;
            defaultInput.start_notification_activity_feed_item_configuration.from_mailbox_practice_name = startNotificationActivityFeedItemConfiguration.from_mailbox_practice_name;
            defaultInput.start_notification_activity_feed_item_configuration.subject = startNotificationActivityFeedItemConfiguration.subject;
            defaultInput.start_notification_activity_feed_item_configuration.content = startNotificationActivityFeedItemConfiguration.content;

            var completeNotificationActivityFeedItemConfiguration = existingService.complete_notification_activity_feed_item_configuration;
            defaultInput.complete_notification_activity_feed_item_configuration.from_mailbox_id = completeNotificationActivityFeedItemConfiguration.from_mailbox_id;
            defaultInput.complete_notification_activity_feed_item_configuration.from_mailbox_address = completeNotificationActivityFeedItemConfiguration.from_mailbox_address;
            defaultInput.complete_notification_activity_feed_item_configuration.from_mailbox_practice_name = completeNotificationActivityFeedItemConfiguration.from_mailbox_practice_name;
            defaultInput.complete_notification_activity_feed_item_configuration.subject = completeNotificationActivityFeedItemConfiguration.subject;
            defaultInput.complete_notification_activity_feed_item_configuration.content = completeNotificationActivityFeedItemConfiguration.content;

            defaultInput.sends_notifications = existingService.sends_notifications;
        }

        step6UserInput = Object.assign({}, defaultInput);

        Onkho.ActivityFeed.InitializeRecipientsPicker(startActivityFeedItemBuilder, 'OUTGOING_EMAIL');
        Onkho.ActivityFeed.InitializeRecipientsPicker(completeActivityFeedItemBuilder, 'OUTGOING_EMAIL');

        var clearStartNotificationActivityFeedItemFromMailboxId =
          function() {
              if (step6UserInput.start_notification_activity_feed_item_configuration.from_mailbox_id) {
                  step6Fields.start_notification_activity_feed_item_configuration.from_mailbox_id.select2('data', {
                      id: step6UserInput.start_notification_activity_feed_item_configuration.from_mailbox_id,
                      address: step6UserInput.start_notification_activity_feed_item_configuration.from_mailbox_address,
                      practice_name: step6UserInput.start_notification_activity_feed_item_configuration.from_mailbox_practice_name
                  });
              } else {
                  step6Fields.start_notification_activity_feed_item_configuration.from_mailbox_id.select2('val', '');
              }
              Onkho.Validator.ResetValidation([step6Fields.start_notification_activity_feed_item_configuration.from_mailbox_id]);
          };

        var clearStartNotificationActivityFeedItemSubject =
          function() {
              step6Fields.start_notification_activity_feed_item_configuration.subject.val(step6UserInput.start_notification_activity_feed_item_configuration.subject).change();
              Onkho.Validator.ResetValidation([step6Fields.start_notification_activity_feed_item_configuration.subject]);
          };

        var clearStartNotificationActivityFeedItemContent =
          function() {
              Onkho.Summernote.DestroySummernote(
                step6Fields.start_notification_activity_feed_item_configuration.content
              );

              Onkho.Validator.ResetValidation([step6Fields.start_notification_activity_feed_item_configuration.content]);
          };

        var clearCompleteNotificationActivityFeedItemFromMailboxId =
          function() {
              if (step6UserInput.complete_notification_activity_feed_item_configuration.from_mailbox_id) {
                  step6Fields.complete_notification_activity_feed_item_configuration.from_mailbox_id.select2('data', {
                      id: step6UserInput.complete_notification_activity_feed_item_configuration.from_mailbox_id,
                      address: step6UserInput.complete_notification_activity_feed_item_configuration.from_mailbox_address,
                      practice_name: step6UserInput.complete_notification_activity_feed_item_configuration.from_mailbox_practice_name
                  });
              } else {
                  step6Fields.complete_notification_activity_feed_item_configuration.from_mailbox_id.select2('val', '');
              }
              Onkho.Validator.ResetValidation([step6Fields.complete_notification_activity_feed_item_configuration.from_mailbox_id]);
          };

        var clearCompleteNotificationActivityFeedItemSubject =
          function() {
              step6Fields.complete_notification_activity_feed_item_configuration.subject.val(step6UserInput.complete_notification_activity_feed_item_configuration.subject).change();
              Onkho.Validator.ResetValidation([step6Fields.complete_notification_activity_feed_item_configuration.subject]);
          };

        var clearCompleteNotificationActivityFeedItemContent =
          function() {
              Onkho.Summernote.DestroySummernote(
                step6Fields.complete_notification_activity_feed_item_configuration.content
              );

              Onkho.Validator.ResetValidation([step6Fields.complete_notification_activity_feed_item_configuration.content]);
          };

        var clearNotificationFlag =
            function() {
                step6Fields.sends_notifications.prop('checked', Boolean(step6UserInput.sends_notifications).valueOf()).change();
            };

        // This event is responsible for clearing all input.
        modal.on('clearInput',
            function() {
                clearStartNotificationActivityFeedItemFromMailboxId();
                clearStartNotificationActivityFeedItemSubject();
                clearStartNotificationActivityFeedItemContent();
                
                clearCompleteNotificationActivityFeedItemFromMailboxId();
                clearCompleteNotificationActivityFeedItemSubject();
                clearCompleteNotificationActivityFeedItemContent();
                
                clearNotificationFlag();
            }
        );

        // This event is responsible for initialising the summernote WYSIWYG editor.
        modal.on('initSummernote',
            function() {
                Onkho.Summernote.EnableSummernote(
                    step6Fields.start_notification_activity_feed_item_configuration.content, 'JOB'
                );

                Onkho.Summernote.UpdateSummernote(
                    step6Fields.start_notification_activity_feed_item_configuration.content,
                    step6UserInput.start_notification_activity_feed_item_configuration.content
                );

                Onkho.Summernote.EnableSummernote(
                    step6Fields.complete_notification_activity_feed_item_configuration.content, 'JOB'
                );

                Onkho.Summernote.UpdateSummernote(
                    step6Fields.complete_notification_activity_feed_item_configuration.content,
                    step6UserInput.complete_notification_activity_feed_item_configuration.content
                );
            }
        );

        // The Message Boxes Show : hide.
        step6Fields.sends_notifications.on('change',
            function() {
                if ($(this).is(':checked')) {
                    addServicePanelFormStep6.find('fieldset.service-messages').slideDown()
                    step6UserInput.sends_notifications = 1
                } else {
                    addServicePanelFormStep6.find('fieldset.service-messages').slideUp()
                    step6UserInput.start_notification_activity_feed_item_configuration.from_mailbox_id = defaultInput.start_notification_activity_feed_item_configuration.from_mailbox_id
                    step6UserInput.start_notification_activity_feed_item_configuration.subject = defaultInput.start_notification_activity_feed_item_configuration.subject
                    step6UserInput.start_notification_activity_feed_item_configuration.content = defaultInput.start_notification_activity_feed_item_configuration.content

                    step6UserInput.complete_notification_activity_feed_item_configuration.from_mailbox_id = defaultInput.complete_notification_activity_feed_item_configuration.from_mailbox_id
                    step6UserInput.complete_notification_activity_feed_item_configuration.subject = defaultInput.complete_notification_activity_feed_item_configuration.subject
                    step6UserInput.complete_notification_activity_feed_item_configuration.content = defaultInput.complete_notification_activity_feed_item_configuration.content
                    
                    step6UserInput.sends_notifications = 0
                    updateStep6Messages(startMessageWrapper, completeMessageWrapper)
                }
            }
        );

        // Show the dialog.
        addServicePanelStep6.find('button[name=add-service-messages]').on('click',
            function() {
                modal.modal('show').trigger('clearInput').trigger('initSummernote');
            }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
            function() {
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Reset
        modal.find('div.modal-footer [name="reset"]').on('click',
            function() {
                step6UserInput = Object.assign({}, defaultInput);
                updateStep6Messages(startMessageWrapper, completeMessageWrapper);
                modal.modal('hide').trigger('clearInput');
            }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
            function() {
                Onkho.LoadingTools.ShowLoading(modal.find('div.modal-footer [name="save"]'));

                if (Onkho.Validator.ValidateChildren(modal)) {
                    Onkho.ActivityFeed.ValidateItemBuilder(startActivityFeedItemBuilder, function () {
                        Onkho.ActivityFeed.ValidateItemBuilder(completeActivityFeedItemBuilder, function () {
                            updateStep6UserInput(defaultInput);
                            updateStep6Messages(startMessageWrapper, completeMessageWrapper);
                            Onkho.LoadingTools.HideLoading(modal.find('div.modal-footer [name="save"]'));
                            modal.modal('hide').trigger('clearInput');
                        }, function () {
                            Onkho.LoadingTools.HideLoading(modal.find('div.modal-footer [name="save"]'));
                        })
                    }, function () {
                        Onkho.LoadingTools.HideLoading(modal.find('div.modal-footer [name="save"]'));
                    });
                }
            }
        );

        // Manually trigger the 'clearInput' event the first time.
        modal.trigger('clearInput');
        updateStep6Messages(startMessageWrapper, completeMessageWrapper);
    };

    var initStep7 = function () {
        //Referencing the modal once
        var modal                        = addServicePanelStep7.find('div#nominate-service-manager-dialog');
        var currentServiceManagerWrapper = addServicePanelFormStep7.find('fieldset.current-service-manager');

        var currentServiceManagerDescription = currentServiceManagerWrapper.find('span.service-manager-description');

        step7Fields = {
            service_manager_id : modal.find('select[name="service_manager_id"]')
        };

        step7Fields.service_manager_id.select2({
            placeholder: 'No One',
            sortResults: Onkho.Functions.Select2Sort
        });

        var defaultInput = {
            service_manager_id   : 0,
            service_manager_name : ''
        };

        // Update the default service manager if we're editing an existing service.
        if (existingService) {
            defaultInput.service_manager_id   = existingService.service_manager_id;
            defaultInput.service_manager_name = existingServiceForm.find('input[type="hidden"][name="service_manager_id"]').data('service-manager-name');
        }

        step7UserInput = Object.assign({}, defaultInput);

        // Show modal
        addServicePanelFormStep7.find('button[name="update-service-manager"]').click(
          function () {

              var options = '';
              options += '<option value="0">No One</option>';

              $.get(
                Onkho.Variables.BaseURL + '/service/team',
                function(data) {
                    step7Fields.service_manager_id.empty().append(
                      function() {
                          $.each(data.members,
                            function(key, data) {
                                options += '<option value="' + key + '">' + data + '</option>';
                            }
                          );
                          return options;
                      }).val(step7UserInput.service_manager_id).change();
                }, 'json'
              ).fail(
                function() {
                    step7Fields.service_manager_id.empty().append(options).val('0').change();
                }
              ).always(
                function() {
                    modal.modal('show').trigger('clearInput');
                }
              );
          }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
          function() {
              modal.modal('hide').trigger('clearInput');
          }
        );

        // Reset
        modal.find('div.modal-footer [name="reset"]').on('click',
          function () {
              step7UserInput = Object.assign({}, defaultInput);
              updateStep7Description(currentServiceManagerDescription);
              modal.modal('hide').trigger('clearInput');
          }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
          function() {
              updateStep7UserInput(defaultInput);
              updateStep7Description(currentServiceManagerDescription);
              modal.modal('hide').trigger('clearInput');
          }
        );

        updateStep7Description(currentServiceManagerDescription);
    };

    var initStep8 = function () {
        // Referencing the modal once
        var modal = addServicePanelStep8.find('div#change-autoplan-dialog')
        var currentAutoplanningWrapper = addServicePanelFormStep8.find('fieldset.current-autoplanning')
        var currentAutoplanningDescription = currentAutoplanningWrapper.find('span.autoplanning-description')
        var autoplanningConfiguration = modal.find('.autoplanning-configuration')

        var spanOnOffSwitch = modal.find('.onoffswitch')

        step8Fields = {
            autoplan_jobs: spanOnOffSwitch.find('input[type="checkbox"][name="autoplan_jobs"]'),
            autoplanning_time_unit_count : modal.find('input[name="autoplanning_time_unit_count"]'),
            autoplanning_time_unit : modal.find('select[name="autoplanning_time_unit"]'),
        };

        var defaultInput = {
            autoplan_jobs: 0,
            autoplanning_time_unit_count : 1,
            autoplanning_time_unit : 'WEEK',
        };

        // Update the default autoplanning configuration if we're editing an existing service.
        if (existingService) {
            defaultInput.autoplan_jobs = existingService.autoplan_jobs
            defaultInput.autoplanning_time_unit_count = existingService.autoplanning_time_unit_count
            defaultInput.autoplanning_time_unit = existingService.autoplanning_time_unit
        }

        step8UserInput = Object.assign({}, defaultInput);

        var clearAutoplanningConfiguration =
          function() {
              step8Fields.autoplan_jobs.prop('checked', step8UserInput.autoplan_jobs).change();
              step8Fields.autoplanning_time_unit_count.val(step8UserInput.autoplanning_time_unit_count).change();
              step8Fields.autoplanning_time_unit.val(step8UserInput.autoplanning_time_unit).change();
              Onkho.Validator.ResetValidation([step8Fields.autoplan_jobs, step8Fields.autoplanning_time_unit_count, step8Fields.autoplanning_time_unit]);
          };

        // This event is responsible for clearing all input.
        modal.on('clearInput',
          function() {
              clearAutoplanningConfiguration();
          }
        );

        // Show modal
        addServicePanelFormStep8.find('button[name="change-autoplan"]').click(
          function () {
              modal.modal('show').trigger('clearInput');
          }
        );

        // Cancel
        modal.find('div.modal-footer [name="cancel"]').on('click',
          function() {
              modal.modal('hide').trigger('clearInput');
          }
        );

        // Save
        modal.find('div.modal-footer [name="save"]').on('click',
          function() {
              updateStep8UserInput(defaultInput);
              updateStep8Description(currentAutoplanningDescription);
              modal.modal('hide').trigger('clearInput');
          }
        );

        // Only display the autoplanning configuration if the user opts to enable autoplanning
        step8Fields.autoplan_jobs.on('change',
          function() {
              var self = $(this);
              var autoplanningEnabled = self.is(':checked');

              if (autoplanningEnabled) {
                  autoplanningConfiguration.show();
              } else {
                  autoplanningConfiguration.hide();
              }
          }
        );

        updateStep8Description(currentAutoplanningDescription);
    };

    var initSaveService = function() {
        // This function gathers all the persisted data and returns the resulting object to be posted to the server.
        var buildRequest = function() {
            var request = {
                _token: Onkho.Functions.GetCSRF(),
                data: {
                    service_id:           ((existingService) ? existingService.service_id : 0),
                    service_template:     {},
                    service_schedule:     {},
                    service_messages:     {},
                    service_manager :     {},
                    service_requirements: [],
                    service_steps:        [],
                    autoplanning:         {}
                }
            };

            Object.assign(request.data, step1UserInput);
            Object.assign(request.data.service_template, step2UserInput);
            Object.assign(request.data.service_schedule, step3UserInput);
            Object.assign(request.data.service_messages, step6UserInput);
            Object.assign(request.data.service_manager , step7UserInput);
            request.data.service_requirements = Object.keys(step4Requirements).map(function(key) {return step4Requirements[key];});
            request.data.service_steps        = Object.keys(step5Steps).map(function(key) {return step5Steps[key];});
            Object.assign(request.data.autoplanning , step8UserInput);

            return request;
        };

        $('button[type="submit"][name="save"]').on('click',
            function() {
                var self = $(this);
                Onkho.LoadingTools.ShowLoading(self);
                $.post(
                    Onkho.Variables.BaseURL + '/service/definitionServiceCombo',
                    buildRequest(),
                    function(data) {
                        $('div#service-saved-modal').modal('show');
                    }, 'json'
                ).fail(
                    function(jqXHR, textStatus, errorThrown) {
                        var data = jqXHR.responseJSON;
                        switch(jqXHR.status) {
                            case 400 : {
                                Onkho.Alert.SmallBox('warning', data.message);
                                break;
                            }

                            case 422 : {
                                Onkho.Alert.BigBox('danger', 'Failed to save service', 'Please check your input and try again.');
                                var firstInvalidField = null;
                                var field = null;
                                var errors = data.errors;
                                if (errors['data.service_name']) {
                                    field = step1Fields.service_name;
                                    Onkho.Validator.StyleInvalid(field, errors['data.service_name'][0]);
                                    firstInvalidField = firstInvalidField || field;
                                }

                                if (errors['data.service_template.service_template']) {
                                    field = step2Fields.service_template;
                                    Onkho.Validator.StyleInvalid(field, errors['data.service_template.service_template'][0]);
                                    firstInvalidField = firstInvalidField || field;
                                }

                                Onkho.Validator.ScrollTo(firstInvalidField);
                                break;
                            }

                            default : {
                                Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to save service', data['message'] ? data['message'] : 'We were unable to process your request.');
                                break;
                            }
                        }
                    }
                ).always(
                    function() {
                        Onkho.LoadingTools.HideLoading(self);
                    }
                );
            }
        );
    };

    var resetDateAttributes = function(select, selectedValue) {
        // The user will always be able to select at least one option, regardless of whether the call to obtain all
        // attributes fails.

        var options = '';
        options += '<optgroup label="Manually">';
        options += '<option value="0">Date I choose when I add this service to my client</option>';
        options += '</optgroup>';

        $.get(
            Onkho.Variables.BaseURL + '/service/getDateAttributes',
            function(data) {
                select.empty().append(
                    function() {
                        options += '<optgroup label="Using my client\'s ...">';
                        $.each(data,
                            function(key, data) {
                                options += '<option value="' + data.id + '">' + data.label + '</option>';
                            }
                        );
                        options += '</optgroup>';
                        return options;
                    }).val(selectedValue).change();
            }, 'json'
        ).fail(
            function() {
                select.empty().append(options).val('0').change();
            }
        );
    };

    var updateStep1UserInput = function(defaultInput) {
        var serviceName = step1Fields.service_name.val();

        step1UserInput.service_name = serviceName;
    };

    var updateStep2UserInput = function(defaultInput) {
        var existingService = step2Fields.existing_service.filter(':checked').val();
        var serviceTemplate = step2Fields.service_template.val();
        var serviceType     = step2Fields.service_template.find('option:checked').data('type');

        step2UserInput.existing_service = existingService;
        step2UserInput.service_template = (serviceTemplate) ? serviceTemplate : '';
        step2UserInput.service_type     = (serviceType)     ? serviceType : '';
    };

    var updateStep3UserInput = function(step3UserInput, defaultInput) {
        // The values pertaining to the starting reference date.
        var startingOffset           = step3Fields.starting_offset.val();
        var startingTimeUnit         = step3Fields.starting_time_unit.val();
        var startingRef              = step3Fields.starting_reference.val();

        // The ID of the attribute to use,
        // or empty if the user has opted to select a due date when assigning the service to a client.
        var referenceDateAttr        = step3Fields.reference_date_attribute.val();
        var referenceDateAttrLabel   = step3Fields.reference_date_attribute.find('option[value="' + referenceDateAttr + '"]').html();
        referenceDateAttr            = (referenceDateAttr) ? referenceDateAttr : '';
        referenceDateAttrLabel       = (referenceDateAttrLabel) ? referenceDateAttrLabel : '';

        // The values pertaining to the repeatability of the service.
        var serviceRepeats           = step3Fields.service_repeats.is(':checked');
        var serviceFrequency         = step3Fields.frequency.val();
        var serviceTimeUnit          = step3Fields.time_unit.val();

        // Parameters associated with daily occurrence.
        var frequencyDailyExcludingWeekends = step3Fields.DAY.excluding_weekends.is(':checked');

        // Parameters associated with weekly occurrence.
        var frequencyWeeklyOnMon     = step3Fields.WEEK.MON.is(':checked');
        var frequencyWeeklyOnTue     = step3Fields.WEEK.TUE.is(':checked');
        var frequencyWeeklyOnWed     = step3Fields.WEEK.WED.is(':checked');
        var frequencyWeeklyOnThu     = step3Fields.WEEK.THU.is(':checked');
        var frequencyWeeklyOnFri     = step3Fields.WEEK.FRI.is(':checked');
        var frequencyWeeklyOnSat     = step3Fields.WEEK.SAT.is(':checked');
        var frequencyWeeklyOnSun     = step3Fields.WEEK.SUN.is(':checked');

        // Parameters associated with monthly occurrence.
        var frequencyMonthlyOrdinal  = step3Fields.MONTH.ordinal.val();
        var frequencyMonthlyTimeUnit = step3Fields.MONTH.time_unit.val();

        // Parameters associated with yearly occurrence.
        var frequencyYearlyOrdinal   = step3Fields.YEAR.ordinal.val();
        var frequencyYearlyTimeUnit  = step3Fields.YEAR.time_unit.val();
        var frequencyYearlyMonth     = step3Fields.YEAR.month.val();

        // Initially reset the repeatability parameters of the service, and only overwrite the active time unit, if applicable.
        step3UserInput.DAY   = Object.assign({}, defaultInput.DAY);
        step3UserInput.WEEK  = Object.assign({}, defaultInput.WEEK);
        step3UserInput.MONTH = Object.assign({}, defaultInput.MONTH);
        step3UserInput.YEAR  = Object.assign({}, defaultInput.YEAR);

        if (serviceRepeats) {
            switch (serviceTimeUnit) {
                case 'DAY'   :
                    step3UserInput.DAY.excluding_weekends = frequencyDailyExcludingWeekends;
                    break;

                case 'WEEK'  :
                    step3UserInput.WEEK.MON         = frequencyWeeklyOnMon;
                    step3UserInput.WEEK.TUE         = frequencyWeeklyOnTue;
                    step3UserInput.WEEK.WED         = frequencyWeeklyOnWed;
                    step3UserInput.WEEK.THU         = frequencyWeeklyOnThu;
                    step3UserInput.WEEK.FRI         = frequencyWeeklyOnFri;
                    step3UserInput.WEEK.SAT         = frequencyWeeklyOnSat;
                    step3UserInput.WEEK.SUN         = frequencyWeeklyOnSun;
                    break;

                case 'MONTH' : {
                    step3UserInput.MONTH.ordinal    = frequencyMonthlyOrdinal;
                    step3UserInput.MONTH.time_unit  = frequencyMonthlyTimeUnit;
                    break;
                }

                case 'YEAR' : {
                    step3UserInput.YEAR.ordinal    = frequencyYearlyOrdinal;
                    step3UserInput.YEAR.time_unit  = frequencyYearlyTimeUnit;
                    step3UserInput.YEAR.month      = frequencyYearlyMonth;
                    break;
                }
            }
        }

        step3UserInput.starting_reference             = startingRef;
        step3UserInput.starting_offset                = startingOffset;
        step3UserInput.starting_time_unit             = startingTimeUnit;
        step3UserInput.reference_date_attribute       = referenceDateAttr;
        step3UserInput.reference_date_attribute_label = referenceDateAttrLabel;
        step3UserInput.service_repeats                = serviceRepeats;
        step3UserInput.frequency                      = serviceFrequency;
        step3UserInput.time_unit                      = serviceTimeUnit;
    };

    var updateStep3Description = function(step3UserInput, descriptionWrapper) {
        descriptionWrapper.html(getStep3Description(step3UserInput));
    };

    var getStep3Description = function(step3UserInput, genericPhrasing) {

        genericPhrasing = typeof genericPhrasing == 'undefined' ? false : genericPhrasing;

        var calendarReferenceToString = function(calendarReference) {
            switch (calendarReference) {
                case 'ON'      :
                    return 'on';

                case 'BEFORE'  :
                    return 'before';

                case 'AFTER'   :
                    return 'after';

                case 'DAY'     :
                    return 'day';

                case 'WEEKDAY' :
                    return 'weekday';

                case 'WEEK'    :
                    return 'week';

                case 'MONTH'   :
                    return 'month';

                case 'YEAR'    :
                    return 'year';

                case 'MON'     :
                    return 'Monday';

                case 'TUE'     :
                    return 'Tuesday';

                case 'WED'     :
                    return 'Wednesday';

                case 'THU'     :
                    return 'Thursday';

                case 'FRI'     :
                    return 'Friday';

                case 'SAT'     :
                    return 'Saturday';

                case 'SUN'     :
                    return 'Sunday';

                case 'JAN'     :
                    return 'January';

                case 'FEB'     :
                    return 'February';

                case 'MAR'     :
                    return 'March';

                case 'APR'     :
                    return 'April';

                case 'MAY'     :
                    return 'May';

                case 'JUN'     :
                    return 'June';

                case 'JUL'     :
                    return 'July';

                case 'AUG'     :
                    return 'August';

                case 'SEP'     :
                    return 'September';

                case 'OCT'     :
                    return 'October';

                case 'NOV'     :
                    return 'November';

                case 'DEC'     :
                    return 'December';

                case '1'       :
                case '21'      :
                case '31'      :
                case 'FIRST'   :
                    return '1st';

                case '2'       :
                case '22'      :
                case 'SECOND'  :
                    return '2nd';

                case '3'       :
                case '23'      :
                case 'THIRD'   :
                    return '3rd';

                case '4'       :
                case '24'      :
                case 'FOURTH'  :
                    return '4th';

                default :
                    return (isNaN(calendarReference)) ? calendarReference.toLowerCase() : calendarReference + 'th';
            }
        };

        var buildStartingReferenceDescription = function(genericPhrasing) {
            var startingDescription = '';
            if (step3UserInput.service_repeats) {
                startingDescription += (genericPhrasing ? 'The' : 'Your') + ' service will start';
            } else {
                startingDescription += (genericPhrasing ? 'The' : 'Your') + ' job will be due';
            }


            switch(step3UserInput.starting_reference) {
                case 'ON' :
                    if (step3UserInput.reference_date_attribute === '0') {
                        startingDescription += ' <strong>on a date you choose when you add this service to ' + (genericPhrasing ? 'the' : 'your') + ' client</strong>'
                    } else {
                        startingDescription += ' <strong>on</strong> ' + (genericPhrasing ? 'the' : 'your') + ' client\'s <strong>' + step3UserInput.reference_date_attribute_label.toLowerCase() + '</strong>';
                    }
                    break;

                default :
                    startingDescription += ' <strong>' + step3UserInput.starting_offset + ' ' + calendarReferenceToString(step3UserInput.starting_time_unit) + ((step3UserInput.starting_offset === '1') ? '' : 's') + ' ' + calendarReferenceToString(step3UserInput.starting_reference) + '</strong>';
                    if (step3UserInput.reference_date_attribute === '0') {
                        startingDescription += ' <strong>the date you choose when you add this service to ' + (genericPhrasing ? 'the' : 'your') + ' client</strong>';
                    } else {
                        startingDescription += ' ' + (genericPhrasing ? 'the' : 'your') + ' client\'s <strong>' + step3UserInput.reference_date_attribute_label.toLowerCase() + '</strong>';
                    }
            }

            return startingDescription;
        };

        var buildRepeatDescription = function() {
            var repeatDescription = '';

            if (step3UserInput.frequency === '1') {
                switch (step3UserInput.time_unit) {
                    case 'DAY'   :
                        repeatDescription += ' <strong>daily</strong>';
                        break;

                    case 'WEEK'  :
                        repeatDescription += ' <strong>weekly</strong>';
                        break;

                    case 'MONTH' :
                        repeatDescription += ' <strong>monthly</strong>';
                        break;

                    case 'YEAR'  :
                        repeatDescription += ' <strong>yearly</strong>';
                        break;
                }
            } else  {
                switch (step3UserInput.time_unit) {
                    case 'DAY'   :
                        repeatDescription += ' every <strong>' + step3UserInput.frequency + ' days</strong>';
                        break;

                    case 'WEEK'  :
                        repeatDescription += ' every <strong>' + step3UserInput.frequency + ' weeks</strong>';
                        break;

                    case 'MONTH' :
                        repeatDescription += ' every <strong>' + step3UserInput.frequency + ' months</strong>';
                        break;

                    case 'YEAR'  :
                        repeatDescription += ' every <strong>' + step3UserInput.frequency + ' years</strong>';
                        break;
                }
            }

            switch (step3UserInput.time_unit) {
                case 'DAY'   :
                    if (step3UserInput.DAY.excluding_weekends) {
                        repeatDescription += ', excluding weekends';
                    }
                    break;

                case 'WEEK'  :
                    var daysOfWeek = '';
                    if (step3UserInput.WEEK.MON) {
                        daysOfWeek += 'Monday';
                    }

                    if (step3UserInput.WEEK.TUE) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Tuesday';
                    }

                    if (step3UserInput.WEEK.WED) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Wednesday';
                    }

                    if (step3UserInput.WEEK.THU) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Thursday';
                    }

                    if (step3UserInput.WEEK.FRI) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Friday';
                    }

                    if (step3UserInput.WEEK.SAT) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Saturday';
                    }

                    if (step3UserInput.WEEK.SUN) {
                        if (daysOfWeek) {
                            daysOfWeek += ', ';
                        }
                        daysOfWeek += 'Sunday';
                    }

                    var lastSeparator = daysOfWeek.lastIndexOf(', ');
                    if (lastSeparator > 0) {
                        daysOfWeek = daysOfWeek.substring(0, lastSeparator) + '</strong> and <strong> ' + daysOfWeek.substring(lastSeparator + 2);
                    }

                    if (daysOfWeek.length > 0) {
                        repeatDescription += ' on <strong>' + daysOfWeek + '</strong>';
                    }

                    break;

                case 'MONTH' :
                    switch (step3UserInput.MONTH.ordinal) {
                        case 'SAME' :
                            break;

                        case 'LAST' :
                            repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + ' ' + calendarReferenceToString(step3UserInput.MONTH.time_unit) + '</strong> of the month';
                            break;

                        default :
                            if (step3UserInput.MONTH.time_unit === 'DAY') {
                                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + '</strong>';
                            } else {
                                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.MONTH.ordinal) + ' ' + calendarReferenceToString(step3UserInput.MONTH.time_unit) + '</strong> of the month';
                            }

                            break;
                    }

                    break;

                case 'YEAR' :
                    switch (step3UserInput.YEAR.ordinal) {
                        case 'SAME' :
                            break;

                        case 'LAST' :
                            repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + ' ' + calendarReferenceToString(step3UserInput.YEAR.time_unit) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
                            break;

                        default :
                            if (step3UserInput.YEAR.time_unit === 'DAY') {
                                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
                            } else {
                                repeatDescription += ', on the <strong>' + calendarReferenceToString(step3UserInput.YEAR.ordinal) + ' ' + calendarReferenceToString(step3UserInput.YEAR.time_unit) + '</strong> of <strong>' + calendarReferenceToString(step3UserInput.YEAR.month) + '</strong>';
                            }
                            break;
                    }

                    break;
            }

            repeatDescription += '.';
            return repeatDescription;
        };

        if (step3UserInput.service_repeats) {
            return buildStartingReferenceDescription(genericPhrasing) + ', and ' + (genericPhrasing ? 'the' : 'your') +' jobs will be due ' + buildRepeatDescription();
        } else {
            return buildStartingReferenceDescription(genericPhrasing) + '.';
        }
    };

    var updateStep4UserInput = function(defaultInput) {
        // The values pertaining to the name of the requirement.
        var requirementName               = step4Fields.precondition_name.val();

        // The values pertaining to the notification of the requirement.
        var notificationTimeUnit          = step4Fields.precondition_notification_time_unit.val();
        var notificationOffset            = step4Fields.precondition_notification_offset.val();
        var notificationTime              = step4Fields.precondition_notification_time.val();

        // The values pertaining to the reminder of the requirement.
        var notificationReminder          = step4Fields.precondition_reminder.is(':checked');
        var notificationReminderTimeUnit  = step4Fields.precondition_reminder_time_unit.val();
        var notificationReminderFrequency = step4Fields.precondition_reminder_frequency.val();
        var notificationReminderTime      = step4Fields.precondition_reminder_time.val();

        // The values pertaining to the message of the requirement.
        var fromMailboxIdValue = step4Fields.precondition_notification_activity_feed_item_from_mailbox_id.select2('data');
        var notificationActivityFeedItemFromMailboxId = fromMailboxIdValue === null ? null : fromMailboxIdValue.id;
        var notificationActivityFeedItemFromMailboxAddress = fromMailboxIdValue === null ? null : fromMailboxIdValue.address;
        var notificationActivityFeedItemFromMailboxPracticeName = fromMailboxIdValue === null ? null : fromMailboxIdValue.practice_name;
        var notificationActivityFeedItemSubject = step4Fields.precondition_notification_activity_feed_item_subject.val();
        var notificationActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step4Fields.precondition_notification_activity_feed_item_content);

        step4UserInput.precondition_name = requirementName
        step4UserInput.precondition_notification_offset = notificationOffset
        step4UserInput.precondition_notification_time_unit = notificationTimeUnit
        step4UserInput.precondition_notification_time = notificationTime
        step4UserInput.precondition_reminder = notificationReminder
        step4UserInput.precondition_reminder_frequency = notificationReminderFrequency
        step4UserInput.precondition_reminder_time_unit = notificationReminderTimeUnit
        step4UserInput.precondition_reminder_time = notificationReminderTime
        step4UserInput.precondition_notification_activity_feed_item_from_mailbox_id = notificationActivityFeedItemFromMailboxId
        step4UserInput.precondition_notification_activity_feed_item_from_mailbox_address = notificationActivityFeedItemFromMailboxAddress
        step4UserInput.precondition_notification_activity_feed_item_from_mailbox_practice_name = notificationActivityFeedItemFromMailboxPracticeName
        step4UserInput.precondition_notification_activity_feed_item_subject = notificationActivityFeedItemSubject
        step4UserInput.precondition_notification_activity_feed_item_content = notificationActivityFeedItemContent
    };

    var updateStep4ConditionsTable = function(preconditionsWrapper) {
        var createWell = function(requirement) {
            return '' +
                '<div class="well well-sm well-light margin-bottom-10" data-precondition data-precondition-name="' + requirement.precondition_name + '" data-ordinal="' + requirement.ordinal + '">' +
                    '<h5>' + requirement.precondition_name + '</h5><hr/><br/>' +
                    '<p>'  + requirement.description + '</p><br/><hr class="padding-bottom-10"/>' +
                    '<div class="footer">' +
                        '&nbsp;' +
                        '<div class="btn-group pull-right">' +
                            '<button type="button" name="update" class="btn btn-xs btn-default">Edit</i></button>' +
                            '<button type="button" name="delete" class="btn btn-xs btn-danger">Delete</button>' +
                        '</div>' +
                        '<div class="btn-group order">' +
                            '<button type="button" name="up" class="btn btn-xs btn-default"><i class="fa fa-fw fa-angle-up"></i></button>' +
                            '<button type="button" name="down" class="btn btn-xs btn-default"><i class="fa fa-fw fa-angle-down"></i></button>' +
                        '</div>' +
                    '</div>' +
                '</div>';
        };

        if ($.isEmptyObject(step4Requirements)) {
            preconditionsWrapper.empty().hide();
            preconditionsWrapper.closest('fieldset').find('.empty-requirements').html('No requirements are defined yet for this service.').parent().show();
        } else {
            var html = '';
            for (var requirementName in step4Requirements) {
                if (step4Requirements.hasOwnProperty(requirementName)) {
                    html += createWell(step4Requirements[requirementName]);
                }
            }

            // If there is only one condition, let's hide the directional arrows for ordering.
            html = $(html);
            if (html.length === 1) {
                html.find('div.footer div.btn-group.order').remove();
            } else {
                html = html.sort(function(a, b) {
                    return parseInt($(a).data('ordinal')) - parseInt($(b).data('ordinal'));
                });

                html.first()
                    .find('div.footer div.btn-group.order [name="up"]').remove();

                html.last()
                    .find('div.footer div.btn-group.order [name="down"]').remove();
            }

            preconditionsWrapper.empty().append(html).show();

            preconditionsWrapper.closest('fieldset').find('.empty-requirements').empty().parent().hide();
        }
    };

    var getStep4Description = function(requirement) {
        var description = 'Send a notification to my client <strong>' + requirement.precondition_notification_offset;

        if (requirement.precondition_notification_offset == '1') {
            switch (requirement.precondition_notification_time_unit) {
                case 'DAY'   :
                    description += ' day';
                    break;

                case 'WEEK'  :
                    description += ' week';
                    break;

                case 'MONTH' :
                    description += ' month';
                    break;
            }
        } else {
            switch (requirement.precondition_notification_time_unit) {
                case 'DAY'   :
                    description += ' days';
                    break;

                case 'WEEK'  :
                    description += ' weeks';
                    break;

                case 'MONTH' :
                    description += ' months';
                    break;
            }
        }

        description += ' before the due date</strong>, at <strong>' + requirement.precondition_notification_time + '</strong>.';

        if (requirement.precondition_reminder) {
            description += ' After that, send a reminder ';
            description += ' <strong>';

            if (requirement.precondition_reminder_frequency == '1') {
                switch (requirement.precondition_reminder_time_unit) {
                    case 'DAY'   :
                        description += 'daily';
                        break;
                    case 'WEEK'  :
                        description += 'weekly';
                        break;
                    case 'MONTH' :
                        description += 'monthly';
                        break;
                }
            } else {
                switch (requirement.precondition_reminder_time_unit) {
                    case 'DAY'   :
                        description += 'every ' + requirement.precondition_reminder_frequency + ' days';
                        break;
                    case 'WEEK'  :
                        description += 'every ' + requirement.precondition_reminder_frequency + ' weeks';
                        break;
                    case 'MONTH' :
                        description += 'every ' + requirement.precondition_reminder_frequency + ' months';
                        break;
                }
            }

            description += '</strong>, at <strong>' + requirement.precondition_reminder_time + '</strong> until this requirement is satisfied.'
        }

        return description;
    };

    var updateStep5UserInput = function(defaultInput) {
        // The values pertaining to the name of the step.
        var stepName = step5Fields.step_name.val();

        // The values pertaining to the notification of the step.
        var stepNotification = step5Fields.step_complete_activity_feed_item.is(':checked');

        // The values pertaining to the message of the step.
        var fromMailboxIdValue = step5Fields.step_complete_activity_feed_item_from_mailbox_id.select2('data');
        var stepCompleteActivityFeedItemFromMailboxId = fromMailboxIdValue === null ? null : fromMailboxIdValue.id;
        var stepCompleteActivityFeedItemFromMailboxAddress = fromMailboxIdValue === null ? null : fromMailboxIdValue.address;
        var stepCompleteActivityFeedItemFromMailboxPracticeName = fromMailboxIdValue === null ? null : fromMailboxIdValue.practice_name;
        var stepCompleteActivityFeedItemSubject = step5Fields.step_complete_activity_feed_item_subject.val();
        var stepCompleteActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step5Fields.step_complete_activity_feed_item_content);

        step5UserInput.step_name = stepName;
        step5UserInput.step_complete_activity_feed_item = stepNotification;
        step5UserInput.step_complete_activity_feed_item_from_mailbox_id = stepCompleteActivityFeedItemFromMailboxId;
        step5UserInput.step_complete_activity_feed_item_from_mailbox_address = stepCompleteActivityFeedItemFromMailboxAddress;
        step5UserInput.step_complete_activity_feed_item_from_mailbox_practice_name = stepCompleteActivityFeedItemFromMailboxPracticeName;
        step5UserInput.step_complete_activity_feed_item_subject = stepCompleteActivityFeedItemSubject;
        step5UserInput.step_complete_activity_feed_item_content = stepCompleteActivityFeedItemContent;
    };

    var updateStep5StepsTable = function(stepsWrapper) {
        var createWell = function(step) {
            return '' +
                '<div class="well well-sm well-light margin-bottom-10" data-step data-step-name="' + step.step_name + '" data-ordinal="' + step.ordinal + '">' +
                '<h5>' + step.step_name + '</h5><hr/><br/>' +
                '<p>'  + step.description + '</p><br/><hr class="padding-bottom-10"/>' +
                '<div class="footer">' +
                '&nbsp;' +
                '<div class="btn-group pull-right">' +
                '<button type="button" name="update" class="btn btn-xs btn-default">Edit</i></button>' +
                '<button type="button" name="delete" class="btn btn-xs btn-danger">Delete</button>' +
                '</div>' +
                '<div class="btn-group order">' +
                '<button type="button" name="up" class="btn btn-xs btn-default"><i class="fa fa-fw fa-angle-up"></i></button>' +
                '<button type="button" name="down" class="btn btn-xs btn-default"><i class="fa fa-fw fa-angle-down"></i></button>' +
                '</div>' +
                '</div>' +
                '</div>';
        };

        if ($.isEmptyObject(step5Steps)) {
            stepsWrapper.empty().hide();
            stepsWrapper.closest('fieldset').find('.empty-steps').html('No steps are defined yet for this service.').parent().show();
        } else {
            var html = '';
            for (var stepName in step5Steps) {
                if (step5Steps.hasOwnProperty(stepName)) {
                    html += createWell(step5Steps[stepName]);
                }
            }

            // If there is only one step, let's hide the directional arrows for ordering.
            html = $(html);
            if (html.length === 1) {
                html.find('div.footer div.btn-group.order').remove();
            } else {
                html = html.sort(function(a, b) {
                    return parseInt($(a).data('ordinal')) - parseInt($(b).data('ordinal'));
                });

                html.first()
                    .find('div.footer div.btn-group.order [name="up"]').remove();

                html.last()
                    .find('div.footer div.btn-group.order [name="down"]').remove();
            }

            stepsWrapper.empty().append(html).show();

            stepsWrapper.closest('fieldset').find('.empty-steps').empty().parent().hide();
        }
    };

    var getStep5Description = function(step) {
        var description = '';

        if (step.step_complete_activity_feed_item) {
            description += 'Send a notification to my client when I complete this step.';
        } else {
            description += 'This is an <strong>internal step</strong> for which a notification will <strong>not</strong> be sent to my client when it is completed.';
        }

        return description;
    };

    var updateStep6UserInput = function(defaultInput) {
        // The values pertaining to the start and complete messages.
        var startFromMailboxIdValue = step6Fields.start_notification_activity_feed_item_configuration.from_mailbox_id.select2('data');
        var startNotificationActivityFeedItemFromMailboxId = startFromMailboxIdValue === null ? null : startFromMailboxIdValue.id;
        var startNotificationActivityFeedItemFromMailboxAddress = startFromMailboxIdValue === null ? null : startFromMailboxIdValue.address;
        var startNotificationActivityFeedItemFromMailboxPracticeName = startFromMailboxIdValue === null ? null : startFromMailboxIdValue.practice_name;
        var startNotificationActivityFeedItemSubject = step6Fields.start_notification_activity_feed_item_configuration.subject.val();
        var startNotificationActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step6Fields.start_notification_activity_feed_item_configuration.content);

        var completeFromMailboxIdValue = step6Fields.complete_notification_activity_feed_item_configuration.from_mailbox_id.select2('data');
        var completeNotificationActivityFeedItemFromMailboxId = completeFromMailboxIdValue === null ? null : completeFromMailboxIdValue.id;
        var completeNotificationActivityFeedItemFromMailboxAddress = completeFromMailboxIdValue === null ? null : completeFromMailboxIdValue.address;
        var completeNotificationActivityFeedItemFromMailboxPracticeName = completeFromMailboxIdValue === null ? null : completeFromMailboxIdValue.practice_name;
        var completeNotificationActivityFeedItemSubject = step6Fields.complete_notification_activity_feed_item_configuration.subject.val();
        var completeNotificationActivityFeedItemContent = Onkho.Summernote.GetSummernoteValue(step6Fields.complete_notification_activity_feed_item_configuration.content);

        var notificationFlag = step6Fields.sends_notifications.is(':checked');

        step6UserInput.start_notification_activity_feed_item_configuration = {
            'from_mailbox_id': startNotificationActivityFeedItemFromMailboxId,
            'from_mailbox_address': startNotificationActivityFeedItemFromMailboxAddress,
            'from_mailbox_practice_name': startNotificationActivityFeedItemFromMailboxPracticeName,

            'subject': startNotificationActivityFeedItemSubject,
            'content': startNotificationActivityFeedItemContent
        }
        
        step6UserInput.complete_notification_activity_feed_item_configuration = {
            'from_mailbox_id': completeNotificationActivityFeedItemFromMailboxId,
            'from_mailbox_address': completeNotificationActivityFeedItemFromMailboxAddress,
            'from_mailbox_practice_name': completeNotificationActivityFeedItemFromMailboxPracticeName,

            'subject': completeNotificationActivityFeedItemSubject,
            'content': completeNotificationActivityFeedItemContent
        }

        step6UserInput.sends_notifications = notificationFlag;
    };

    var updateStep6Messages = function(startMessageWrapper, completeMessageWrapper) {
        var createWell = function(title, subject, content) {
            return '' +
                '<div class="well well-sm well-light margin-bottom-10">' +
                '<h5>' + title + '</h5><hr/><br/>' +
                '<b>' + subject + '</b><br/>' +
                content + '<br/>' +
                '</div>';
        };

        if (step6UserInput.start_notification_activity_feed_item_configuration.subject && $($.parseHTML(step6UserInput.start_notification_activity_feed_item_configuration.subject)).text().trim().length > 0) {
            startMessageWrapper.empty().append(createWell('Message to send when a job starts', step6UserInput.start_notification_activity_feed_item_configuration.subject, step6UserInput.start_notification_activity_feed_item_configuration.content));
        } else {
            startMessageWrapper.empty().append(createWell('Message to send when a job starts', 'This service will send a simplified message from your default mailbox when a job starts summarising the service name and status.', ''));
        }

        if (step6UserInput.complete_notification_activity_feed_item_configuration.subject && $($.parseHTML(step6UserInput.complete_notification_activity_feed_item_configuration.subject)).text().trim().length > 0) {
            completeMessageWrapper.empty().append(createWell('Message to send when a job completes', step6UserInput.complete_notification_activity_feed_item_configuration.subject, step6UserInput.complete_notification_activity_feed_item_configuration.content));
        } else {
            completeMessageWrapper.empty().append(createWell('Message to send when a job completes', 'This service will send a simplified message from your default mailbox when a job completes summarising the service name and status.', ''));
        }
    };

    var updateStep7UserInput = function(defaultInput) {

        var serviceManager     = step7Fields.service_manager_id.val();
        var serviceManagerName = step7Fields.service_manager_id.find('option').filter('[value="' + serviceManager + '"]').text();

        step7UserInput.service_manager_id   = serviceManager;
        step7UserInput.service_manager_name = serviceManagerName;
    };

    var updateStep7Description = function(currentServiceManagerDescription) {

        var serviceManagerId   = step7UserInput.service_manager_id;
        var serviceManagerName = step7UserInput.service_manager_name;

        if (serviceManagerId == 0) {
            currentServiceManagerDescription.html('A service manager has not been assigned to this service.');
        } else {
            var partyURL  = Onkho.Variables.BaseURL + '/party/' + serviceManagerId;
            currentServiceManagerDescription.html('<a href="' + partyURL + '" target="_blank">' + serviceManagerName + '</a> is currently assigned as the service manager for this service.');
        }
    };

    var updateStep8UserInput = function(defaultInput) {
        var autoplanJobs = step8Fields.autoplan_jobs.is(':checked');
        var timeUnitCount = step8Fields.autoplanning_time_unit_count.val();
        var timeUnit = step8Fields.autoplanning_time_unit.val();

        step8UserInput.autoplan_jobs = autoplanJobs;
        step8UserInput.autoplanning_time_unit_count = timeUnitCount;
        step8UserInput.autoplanning_time_unit = timeUnit;
    };

    var updateStep8Description = function(currentAutoplanningDescription) {
        var autoplanJobs = step8UserInput.autoplan_jobs;
        var timeUnitCount = step8UserInput.autoplanning_time_unit_count;
        var timeUnit = step8UserInput.autoplanning_time_unit;

        if (!autoplanJobs) {
            currentAutoplanningDescription.html('Autoplanning is not enabled for this service.');
        } else {
            if (timeUnitCount == 0) {
                currentAutoplanningDescription.html('This service\'s jobs will have their planned date set to <b>match their due date</b>.');
            } else {
                currentAutoplanningDescription.html('This service\'s jobs will have their planned date set to <b>' + timeUnitCount + ' ' + timeUnit.toLowerCase() + (timeUnitCount == 1 ? '' : 's') + '</b> ahead of the due date.');
            }
        }
    };

    var toBoolean = function(val) {
        if (typeof val === 'boolean') {
            return val;
        }
        if (typeof val === 'string') {
            return val === 'true';
        }
        return Boolean(val);
    };

    var DescribeServiceScheduleConfiguration = function(json, genericPhrasing) {
        genericPhrasing = typeof genericPhrasing == 'undefined' ? false : genericPhrasing;

        var schedule = {
            starting_reference             : 'ON',
            starting_offset                : 1,
            starting_time_unit             : 'MONTH',
            reference_date_attribute       : '0',
            reference_date_attribute_label : '',
            service_repeats                : false,
            frequency                      : 1,
            time_unit                      : 'MONTH',
            DAY                            : {
                excluding_weekends : false
            },
            WEEK                     : {
                MON                : false,
                TUE                : false,
                WED                : false,
                THU                : false,
                FRI                : false,
                SAT                : false,
                SUN                : false
            },
            MONTH                    : {
                ordinal            : 'SAME',
                time_unit          : 'DAY'
            },
            YEAR                     : {
                ordinal            : 'SAME',
                time_unit          : 'DAY',
                month              : 'JAN'
            }
        };

        if (typeof json === 'object') {
            schedule = Object.assign(schedule, json);
        } else if (typeof json === 'string') {
            schedule = Object.assign(schedule, JSON.parse(decodeURIComponent(json)));
        }

        schedule.service_repeats        = toBoolean(schedule.service_repeats);
        schedule.DAY.excluding_weekends = toBoolean(schedule.DAY.excluding_weekends);
        schedule.WEEK.MON               = toBoolean(schedule.WEEK.MON);
        schedule.WEEK.TUE               = toBoolean(schedule.WEEK.TUE);
        schedule.WEEK.WED               = toBoolean(schedule.WEEK.WED);
        schedule.WEEK.THU               = toBoolean(schedule.WEEK.THU);
        schedule.WEEK.FRI               = toBoolean(schedule.WEEK.FRI);
        schedule.WEEK.SAT               = toBoolean(schedule.WEEK.SAT);
        schedule.WEEK.SUN               = toBoolean(schedule.WEEK.SUN);
        return getStep3Description(schedule, genericPhrasing);
    };

    var Init = function () {

        addServicePanelStep1     = $('div.add-service-panel.step-1');
        addServicePanelFormStep1 = addServicePanelStep1.find('form[name="add-service-form"]');

        addServicePanelStep2     = $('div.add-service-panel.step-2');
        addServicePanelFormStep2 = addServicePanelStep2.find('form[name="add-service-form"]');

        addServicePanelStep3     = $('div.add-service-panel.step-3');
        addServicePanelFormStep3 = addServicePanelStep3.find('form[name="add-service-form"]');

        addServicePanelStep4     = $('div.add-service-panel.step-4');
        addServicePanelFormStep4 = addServicePanelStep4.find('form[name="add-service-form"]');

        addServicePanelStep5     = $('div.add-service-panel.step-5');
        addServicePanelFormStep5 = addServicePanelStep5.find('form[name="add-service-form"]');

        addServicePanelStep6     = $('div.add-service-panel.step-6');
        addServicePanelFormStep6 = addServicePanelStep6.find('form[name="add-service-form"]');

        addServicePanelStep7     = $('div.add-service-panel.step-7');
        addServicePanelFormStep7 = addServicePanelStep7.find('form[name="add-service-form"]');

        addServicePanelStep8     = $('div.add-service-panel.step-8');
        addServicePanelFormStep8 = addServicePanelStep8.find('form[name="add-service-form"]');

        initExistingService();

        initStep1();
        initStep2();
        initStep3();
        initStep4();
        initStep5();
        initStep6();
        initStep7();
        initStep8();

        initSaveService();

        // Display the steps that should be initially active once everything is initialised.
        $('[data-ready]').fadeIn(function() {
            $(window).scrollTop = 0;
        });

        // Enable tooltips
        $('[rel="tooltip"]').tooltip({
            html: true
        });
    };

    return {
        Init: Init,
        DescribeServiceScheduleConfiguration: DescribeServiceScheduleConfiguration
    };
}();
