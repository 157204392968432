Onkho.OnkhoDurationPicker = function ()
{
    var Init = function (inputElement, hoursOnly)
    {
        if (typeof hoursOnly === 'undefined') {
            hoursOnly = true;
        }

        var durationPicker = $('<div class="duration-picker"></div>');

        if (hoursOnly) {
            durationPicker.addClass('hours-only');
            durationPicker.append('<div class="container-hours"><input class="hours" type="number" min="0" step="0.1" tabindex="' + inputElement.attr('tabindex') + '"><span class="label-hours">hours</span></div>');
        } else {
            durationPicker.append('<div class="container-days"><input class="days" type="number" min="0" tabindex="' + inputElement.attr('tabindex') + '"><span class="label-days">days</span></div>');
            durationPicker.append('<div class="container-hours"><input class="hours" type="number" min="0" tabindex="' + inputElement.attr('tabindex') + '"><span class="label-hours">hours</span></div>');
            durationPicker.append('<div class="container-minutes"><input class="minutes" type="number" min="0" tabindex="' + inputElement.attr('tabindex') + '"><span class="label-minutes">mins</span></div>');
        }

        inputElement.after(durationPicker);

        Set(inputElement, inputElement.val(), hoursOnly, true);
        inputElement.hide();

        durationPicker.on('change', 'input', function () {
            var seconds = GetTotalSeconds(inputElement, hoursOnly);
            Set(inputElement, seconds, hoursOnly);
        });
    };

    var FormatNumber = function (number, float) {
        if (number.length == 0 || isNaN(number) || number < 0) {
            return 0;
        }

        if (!float && !Number.isInteger(number)) {
            return Math.floor(number);
        }

        return number;
    }

    var GetDays = function (inputElement)
    {
        var daysInput = inputElement.find('+ .duration-picker input.days');
        var days = daysInput.val();

        var formattedDays = FormatNumber(days);
        if (days !== formattedDays) {
            daysInput.val(formattedDays);
        }

        return daysInput.val();
    };

    var GetHours = function (inputElement)
    {
        var hoursInput = inputElement.find('+ .duration-picker input.hours');
        var hours = hoursInput.val();

        var formattedHours = FormatNumber(hours);
        if (hours !== formattedHours) {
            hoursInput.val(formattedHours);
        }

        return hoursInput.val();
    };

    var GetMinutes = function (inputElement)
    {
        var minutesInput = inputElement.find('+ .duration-picker input.minutes');
        var minutes = minutesInput.val();

        var formattedMinutes = FormatNumber(minutes);
        if (minutes !== formattedMinutes) {
            minutesInput.val(formattedMinutes);
        }

        return minutesInput.val();
    };

    var GetTotalSeconds = function (inputElement, hoursOnly)
    {
        if (hoursOnly) {
            return FormatNumber(FormatNumber(inputElement.find('+ .duration-picker input.hours').val(), true) * 3600);
        } else {
            return GetDays(inputElement) * 86400 + GetHours(inputElement) * 3600 + GetMinutes(inputElement) * 60;
        }
    };

    var Get = function (inputElement)
    {
        return inputElement.val();
    };

    var Set = function (inputElement, seconds, hoursOnly, focus)
    {
        if (hoursOnly) {
            var hours = Math.round((seconds / 3600 + Number.EPSILON) * 100) / 100;

            seconds = hours * 3600;

            inputElement.val(seconds).trigger('change');

            var durationPicker = inputElement.find('+ .duration-picker');
            durationPicker.find('input.hours').val(hours);
        } else {
            var days = Math.floor(seconds / 86400);
            var hours = Math.floor((seconds - (days * 86400)) / 3600);
            var minutes = Math.floor((seconds - (days * 86400) - (hours * 3600)) / 60);

            seconds = days * 86400 + hours * 3600 + minutes * 60;

            inputElement.val(seconds).trigger('change');

            var durationPicker = inputElement.find('+ .duration-picker');
            durationPicker.find('input.days').val(days);
            durationPicker.find('input.hours').val(hours);
            durationPicker.find('input.minutes').val(minutes);
        }

        if (focus) {
            durationPicker.find('input.hours').focus();
        }
    };



    return {
        Init: Init,
        Get: Get,
        Set: Set
    };
}();
