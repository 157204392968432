// Module containing tools to be used when displaying an activity feed items
// feed on the Inbox page
Onkho.ActivityFeedItemsForInboxFeedTools = function ()
{
  var feedContainer;
  var initialTypeFilter;
  var initialAuthorPartyIdFilter;

  var Init = function (areDefaultsUsed, typeFilter, authorPartyIdFilter)
  {
    feedContainer = $('.activity-feed-items-for-inbox-feed-container');
    initialTypeFilter = typeFilter;
    initialAuthorPartyIdFilter = authorPartyIdFilter;

    if (!areDefaultsUsed) {
      feedContainer.find('.o-feed').data('dirty', true);
    }

    var additionalData = {}

    Onkho.OnkhoFeed.Init(
      feedContainer.find('.o-feed'),
      'activity-feed-items-for-inbox',
      additionalData)

    RegisterFeedBindings()
    RegisterEventListeners()
  }

  var InitializeFilters = function ()
  {
    Onkho.EnhancedMultiselect.SetSelection(feedContainer.find('.filter-control[data-id="type"] .o-enhanced-multiselect'), initialTypeFilter)
    Onkho.EnhancedMultiselect.SetSelection(feedContainer.find('.filter-control[data-id="author_party_id"] .o-enhanced-multiselect'), initialAuthorPartyIdFilter)

    if (initialTypeFilter.length > 0 || initialAuthorPartyIdFilter.length > 0) {
      var feed = feedContainer.find('.o-feed')
      feed.find('> .toolbar > .main-row > .filters-toggle .show-filters').click()
    }
  }

  var RegisterFeedBindings = function ()
  {
    feedContainer.on('click', '.feed-actions [data-id="mark_all_read"]', function () {
      ReadAllGroups()
    })

    feedContainer.on('click', '.feed-actions [data-id="mark_all_handled"]', function () {
      ConfirmHandleAllErrors()
    })

    feedContainer.on('click', '.feed-actions [data-id="use_conversations_view"]', function () {
      UseConversationsView()
    })

    // Placeholder buttons
    feedContainer.on('click', '.content-placeholder [data-id="note"]', function ()
    {
      feedContainer.find('.feed-actions [data-id="note"] .activity-feed-item-builder-panel-trigger').click();
    });
  };

  var RegisterEventListeners = function ()
  {
    $('body').on('onkho:feed[activity-feed-items-for-inbox].setup', '.o-feed.activity-feed-items-for-inbox-feed', function ()
    {
      InitializeFilters()
    });

    $('body').on('onkho:feed[activity-feed-items-for-inbox].loaded', '.o-feed.activity-feed-items-for-inbox-feed', function ()
    {
      Onkho.ActivityFeed.InitializeItems($(this));
    });
  };

  var ConfirmHandleAllErrors = function () {
    $.SmartMessageBox(
      {
        title: 'Mark all errors as handled',
        content: 'Are you sure you want to mark all the errors as handled?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          HandleAllErrors()
        }
      }
    )
  }

  var HandleAllErrors = function () {
    Onkho.Alert.SmallBox('info', 'We are now marking all the errors as handled. This may take a little while.')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/handleAllErrors',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ReadAllGroups = function () {
    Onkho.Alert.SmallBox('info', 'We are now marking all of your items as read. This may take a little while.')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/readAllGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var UseConversationsView = function ()
  {
    Onkho.Alert.SmallBox('info', 'Switching to conversations view..')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.preferences = [
      {
        'preference': 'activity_feed_group_list_inbox_view_mode',
        'value': 'conversations',
        'context': null
      }
    ];

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/preference',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              window.location = window.location.href.split('#')[0];
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }



  return {
    Init: Init,
  };
}();
