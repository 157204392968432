Onkho.AddEditKPIFormattingPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add edit KPI formatting panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_kpi_formatting"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditKPIFormattingPanel = function (formattingId, type, kpiFunction, condition, conditionParameter1, conditionParameter2, effect, effectParameter)
  {
    var additionalData = {
      'formattingId': formattingId,
      'function': kpiFunction,
      'type': type,
      'condition': condition,
      'conditionParameter1': conditionParameter1,
      'conditionParameter2': conditionParameter2,
      'effect': effect,
      'effectParameter': effectParameter
    };

    Onkho.OnkhoPanel.Add('add_edit_kpi_formatting', additionalData, AddEditKPIFormattingPanelLoaded)
  };

  var AddEditKPIFormattingPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)
    Onkho.Validator.AddChildren(panel)

    panel.on('change', '[name="formatting_condition"]', function () {
      var formattingCondition = $(this).closest('.formatting-condition')
      formattingCondition.find('.formatting-condition-form').hide()
      formattingCondition.find('.formatting-condition-form[data-id="' + $(this).val() + '"]').show()
    })
    panel.find('[name="formatting_condition"]').trigger('change')

    panel.on('change', '[name="formatting_effect"]', function () {
      var formattingEffect = $(this).closest('.formatting-effect')
      formattingEffect.find('.formatting-effect-form').hide()
      formattingEffect.find('.formatting-effect-form[data-id="' + $(this).val() + '"]').show()
    })
    panel.find('[name="formatting_effect"]').trigger('change')

    panel.find('select').each(function (index, select) {
      $(select).select2({
        placeholder: $(select).data('placeholder')
      })
    })
    panel.find('[rel="tooltip"]').tooltip()
    Onkho.FormEnhancements.EnhanceDates()
  };

  var AddEditKPIFormatting = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      var formattingId = panel.data('formatting-id')

      var condition = panel.find('[name="formatting_condition"]').val()
      var conditionConfiguration = []
      panel.find('.formatting-condition-form[data-id="' + condition + '"] input:not(.select2-offscreen):visible, .formatting-condition-form[data-id="' + condition + '"] select:visible').each(function (index, field) {
        conditionConfiguration.push($(field).val())
      })

      var effect = panel.find('[name="formatting_effect"]').val()
      var effectConfiguration = []
      panel.find('.formatting-effect-form[data-id="' + effect + '"] input:not(.select2-offscreen):visible, .formatting-effect-form[data-id="' + effect + '"] select:visible').each(function (index, field) {
        effectConfiguration.push($(field).val())
      })

      var addEditKPIPanel = $('.o-panel[data-panel-key="add_edit_kpi"]')
      var formatting = {
        'condition': {
          'condition': condition,
          'configuration': conditionConfiguration
        },
        'effect': {
          'effect': effect,
          'configuration': effectConfiguration
        }
      }

      if (!formattingId) {
        Onkho.AddEditKPIPanel.AddFormatting(addEditKPIPanel, formatting)
      } else {
        Onkho.AddEditKPIPanel.UpdateFormatting(addEditKPIPanel, formattingId, formatting)
      }

      Close(panel)
    }
  }

  var Close = function (panel)
  {
    panel.find('input, select').select2('destroy');
    Onkho.OnkhoPanel.Remove(panel);
    Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_edit_kpi"]'))
  }

  var RegisterAddEditKPIFormattingPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      Close(panel)
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditKPIFormatting(panel);
    });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_kpi_formatting].added', '.o-panel-container', function ()
    {
      var addEditKPIFormattingPanel = $('.o-panel[data-panel-key="add_edit_kpi_formatting"]');
      RegisterAddEditKPIFormattingPanelBindings(addEditKPIFormattingPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditKPIFormattingPanel: LoadAddEditKPIFormattingPanel
  };
}();
