// Module containing tools to be used when displaying the service catalogue
Onkho.ServiceCatalogueTools = function () {

    var Init = function () {

        // Catalogue entry action buttons
        $('.catalogue').on('mouseenter', '.catalogue-entry', function ()
        {
            var buttons = $(this).find('.catalogue-entry-action-buttons');
            buttons.finish();
            buttons.fadeIn(150);
        });

        $('.catalogue').on('mouseleave', '.catalogue-entry', function ()
        {
            var buttons = $(this).find('.catalogue-entry-action-buttons');
            buttons.finish();
            buttons.fadeOut(150);
        });

        // Select all
        $('input[name="select_all"]').on('change', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectAll();
            }
            else
            {
                DeselectAll();
            }
        });

        // Selected catalogue entries counter
        $('.catalogue').on('change', 'input[type="checkbox"]', function ()
        {
            UpdateTotalSelected();
        });

        // Sort
        $('.catalogue .sort-control .dropdown li a').on('click', function ()
        {
            var current = $('.catalogue .sort-control .dropdown .current');
            current.html($(this).html());
            current.data('value', $(this).data('value'));

            ResetServiceCatalogue();
            LoadMore();
        });

        // Search
        $('.catalogue').on('keyup', '.search-control input[name="service_catalogue_search"]', function ()
        {
            Delay('ServiceCatalogueSearch', function ()
            {
                if ($('.catalogue .search-control input[name="service_catalogue_search"]').val().length > 0)
                {
                    $('.catalogue .search-control .clear-search').show();
                }
                else
                {
                    $('.catalogue .search-control .clear-search').hide();
                }

                ResetServiceCatalogue();
                LoadMore();
            }, 500);
        });

        $('.catalogue .search-control .clear-search').on('click', function ()
        {
            $('.catalogue .search-control input[name="service_catalogue_search"]').val('');
            $('.catalogue .search-control .clear-search').hide();

            ResetServiceCatalogue();
            LoadMore();
        });

        // Action
        $('body').on('click', '.catalogue .catalogue-entry .catalogue-entry-action-buttons .delete', function () {
            var button = $(this);
            $.SmartMessageBox(
                {
                    title : "Deleting service",
                    content : "Are you sure you want to delete this service from your service catalogue?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress)
                {
                    if (ButtonPress == 'Yes')
                    {
                        var catalogueEntries = button.closest('.catalogue-entry');
                        Delete(catalogueEntries);
                    }
                }
            );
        });

        $('body').on('click', '.catalogue .catalogue-entry .catalogue-entry-action-buttons .tag', function () {
            var modal    = $('div.tag-service-modal');
            var service  = $(this).closest('.catalogue-entry');
            Onkho.TagTools.ShowModalForServiceAndTag(modal,
                function(tagType, tags) {
                    Tag(modal, service, tagType, tags);
                }
            );
        });

        $('body').on('click', '.catalogue .catalogue-entry .catalogue-entry-action-buttons .associate', function () {
            var modal    = $('div.associate-service-modal');
            var service  = $(this).closest('.catalogue-entry');
            var isAutoAddedToService   = service.data('auto-assign-to-client');
            var canBeAutoAddedToClient = service.data('can-be-auto-added-to-client');
            if (canBeAutoAddedToClient == 1) {
                modal.find('form.auto-add-to-client').show();
            } else {
                modal.find('form.auto-add-to-client').hide();
            }
            if (isAutoAddedToService == 1) {
                modal.find('input[type="checkbox"][name="auto_assign_to_client"]').prop('checked', true);
            } else {
                modal.find('input[type="checkbox"][name="auto_assign_to_client"]').prop('checked', false);
            }

            Onkho.TagTools.ShowModalForServiceAndClientType(modal, service,
                function(tagType, tags) {
                    var autoAssignToClient = modal.find('input[type="checkbox"][name="auto_assign_to_client"]').is(':checked');
                    SetClientTypes(modal, service, tagType, tags, canBeAutoAddedToClient, autoAssignToClient);
                }
            );
        });

        $('body').on('click', '.catalogue .catalogue-entry .tag[data-tag-type="TAG"] .remove', function () {
            var tag             = $(this).closest('.tag');
            var tagName         = tag.data('tag-name');
            var tagType         = tag.data('tag-type');
            var tagAssignmentId = tag.data('tag-assignment-id');

            var service         = $(this).closest('.catalogue-entry');
            var serviceName     = service.find('.catalogue-entry-details-wrapper .catalogue-entry-details a.catalogue-entry-name').text();

            $.SmartMessageBox({
                title   : "Remove tag",
                content : "Do you want to remove the tag <strong>" + tagName + "</strong> from <strong>" + serviceName + "</strong>?",
                buttons : "[No][Yes]"
            }, function(ButtonPress) {
                if (ButtonPress === 'Yes') {
                    RemoveTag(service, tagAssignmentId);
                }
            });
        });

        $('body').on('click', '.catalogue .catalogue-entry .tag[data-tag-type="CLIENT_TYPE"] .remove', function () {
            var tag             = $(this).closest('.tag');
            var tagName         = tag.data('tag-name');
            var tagType         = tag.data('tag-type');
            var tagAssignmentId = tag.data('tag-assignment-id');

            var service         = $(this).closest('.catalogue-entry');
            var serviceName     = service.find('.catalogue-entry-details-wrapper .catalogue-entry-details a.catalogue-entry-name').text();

            $.SmartMessageBox({
                title   : "Disassociate Service",
                content : "Do you want to disassociate the service <strong>" + serviceName + "</strong> from clients of type <strong>" + tagName + "</strong>?<br/><strong>Note</strong>: This action will not remove this service from any existing clients.",
                buttons : "[No][Yes]"
            }, function(ButtonPress) {
                if (ButtonPress === 'Yes') {
                    RemoveTag(service, tagAssignmentId);
                }
            });
        });

        // Global actions
        $('.catalogue .global-actions').on('click', '.delete', function ()
        {
            $.SmartMessageBox(
                {
                    title : "Deleting services",
                    content : "Are you sure you want to delete these services from your service catalogue?",
                    buttons : "[No][Yes]"
                }, function(ButtonPress) {
                    if (ButtonPress === 'Yes') {
                        Delete(GetSelected());
                    }
                }
            );
        });

        // Infinity scroll
        LoadMore(true);

        $('body').on('click', '.catalogue .btn.load-more', function ()
        {
            if (!$(this).hasClass('disabled'))
            {
                LoadMore();
            }
        });

        $(window).on('scroll', function()
        {
            if ($(window).scrollTop() > ($(document).height() - $(window).height() - 200))
            {
                if ($('.catalogue .btn.load-more').length && !$('.catalogue .btn.load-more').hasClass('disabled'))
                {
                    LoadMore();
                }
            }
        }).scroll();

        // Refresh
        $('.catalogue .refresh').on('click', function ()
        {
            if (!$('.catalogue .btn.load-more').hasClass('disabled'))
            {
                ResetServiceCatalogue();
                LoadMore();
            }
        });


        $('.catalogue .add-service').click(
          function(event) {
              event.preventDefault();
              Onkho.AddEditServicePanel.LoadAddEditServicePanel()
          }
        );

        $('.catalogue').on('click', 'a.catalogue-entry-name',
          function(event) {
              event.preventDefault();
              Onkho.AddEditServicePanel.LoadAddEditServicePanel($(this).closest('.catalogue-entry').data('service-id'))
          }
        );
    };

    var Delay = function(timer, callback, delay)
    {
        clearTimeout(Onkho.Variables[timer]);
        Onkho.Variables[timer] = setTimeout(callback, delay);
    };

    var GetSelected = function ()
    {
        var catalogueEntries = [];
        $('.catalogue .catalogue-entry input[type="checkbox"]:checked').each(function ()
        {
            catalogueEntries.push($(this).closest('.catalogue-entry'));
        });

        return catalogueEntries;
    };

    var SelectAll = function ()
    {
        $('.catalogue .catalogue-entry input[type="checkbox"]').prop('checked', true);
        Onkho.Variables.SelectAllCatalogueEntries = true;
    };

    var DeselectAll = function ()
    {
        $('.catalogue .catalogue-entry input[type="checkbox"]').prop('checked', false);
        Onkho.Variables.SelectAllCatalogueEntries = false;
    };

    var UpdateTotalSelected = function ()
    {
        var globalActions = $('.catalogue .global-actions');

        var total = parseInt($('.catalogue .catalogue-entry input[type="checkbox"]:checked').length);
        globalActions.find('label').html('Selected ' + total + ' service' + (total != 1 ? 's' : ''));

        if (total > 0)
        {
            globalActions.finish();
            globalActions.fadeIn(150).css('display', 'inline-block');
        }
        else
        {
            globalActions.finish();
            globalActions.fadeOut(150);
        }
    };

    var HideControls = function (container)
    {
        container.find('.catalogue-entry-action-buttons').html('<i class="fa fa-spin fa-spinner"></i>');
    };

    var ShowControls = function (container)
    {
        if (container.hasClass('global-actions'))
        {
            container.find('.catalogue-entry-action-buttons').html(
                '<a href="javascript:void(0);" class="tag">+ Tag</a>' +
                '<a href="javascript:void(0);" class="associate">+ Associate</a>' +
                '<a href="javascript:void(0);" class="delete">+ Delete</a>'
            );

        }
        else
        {
            container.find('.catalogue-entry-action-buttons').html(
                '<a href="javascript:void(0);" class="tag">+ Tag</a>' +
                '<a href="javascript:void(0);" class="associate">+ Associate</a>' +
                '<a href="javascript:void(0);" class="delete">+ Delete</a>'
            );
        }
    };

    var Delete = function (catalogueEntries)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        formData.ids = [];
        $(catalogueEntries).each(function ()
        {
            formData.ids.push($(this).data('id'));
            HideControls($(this));
        });

        HideControls($('.catalogue .global-actions'));

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/service/delete',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    $(catalogueEntries).each(function ()
                    {
                        ShowControls($(this));
                    });

                    ShowControls($('.catalogue .global-actions'));

                    switch (data.status)
                    {
                        case 200:
                            Onkho.Alert.SmallBox('success', data.responseJSON.message);

                            ResetServiceCatalogue();
                            LoadMore();
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
    };

    var Tag = function (modal, services, tagType, tags) {

        var saveButton = modal.find('div.modal-footer .save');
        Onkho.LoadingTools.ShowLoading(saveButton);

        var formData = {};
        formData._token   = Onkho.Functions.GetCSRF();
        formData.tagType  = tagType;
        formData.tags     = tags;
        formData.services = [];

        $(services).each(function () {
            formData.services.push($(this).data('service-id'));
            HideControls($(this));
        });

        HideControls($('.catalogue .global-actions'));

        $.post(
            Onkho.Variables.BaseURL + '/assignedTags/service',
            formData,
            function(data) {
                Onkho.Alert.SmallBox('success', data.message);
                ResetServiceCatalogue();
                LoadMore();
            }, 'json'
        ).fail(
            function(jqXHR, textStatus, errorThrown) {
                var data = jqXHR.responseJSON;
                if (data && data.message) {
                    Onkho.Alert.BigBox('danger', 'Error', data.message);
                }
            }
        ).always(
            function() {
                $(services).each(function () {
                    ShowControls($(this));
                });

                ShowControls($('.catalogue .global-actions'));

                Onkho.LoadingTools.HideLoading(saveButton);
                modal.modal('hide');
            }
        );
    };

    var RemoveTag = function (service, tagAssignmentId) {

        $(service).each(function () {
            HideControls($(this));
        });

        HideControls($('.catalogue .global-actions'));

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        $.ajax({
            type: 'DELETE',
            url: Onkho.Variables.BaseURL + '/tags/service/' + tagAssignmentId,
            data: formData,
            dataType: 'json',
            complete: function (data) {
                switch (data.status) {
                    case 200:
                        ResetServiceCatalogue();
                        LoadMore();
                        break;
                    default:
                        var data = data.responseJSON;
                        if (data && data.message) {
                            Onkho.Alert.BigBox('danger', 'Error', data.message);
                        }
                        break;
                }

                $(service).each(function () {
                    ShowControls($(this));
                });

                ShowControls($('.catalogue .global-actions'));
            }
        });
    };

    var SetClientTypes = function(modal, services, tagType, tags, canBeAutoAddedToClient, autoAssignToClient) {

        var saveButton = modal.find('div.modal-footer .save');
        Onkho.LoadingTools.ShowLoading(saveButton);

        var formData = {};
        formData._token             = Onkho.Functions.GetCSRF();
        formData.autoAssignToClient = canBeAutoAddedToClient && autoAssignToClient;
        formData.clientTypes        = tags;
        formData.services           = [];

        $(services).each(function () {
            formData.services.push($(this).data('service-id'));
            HideControls($(this));
        });

        HideControls($('.catalogue .global-actions'));

        $.post(
            Onkho.Variables.BaseURL + '/service/updateClientTypes',
            formData,
            function(data) {
                Onkho.Alert.SmallBox('success', data.message);
                ResetServiceCatalogue();
                LoadMore();
            }, 'json'
        ).fail(
            function(jqXHR, textStatus, errorThrown) {
                var data = jqXHR.responseJSON;
                if (data && data.message) {
                    Onkho.Alert.BigBox('danger', 'Error', data.message);
                }
            }
        ).always(
            function() {
                $(services).each(function () {
                    ShowControls($(this));
                });

                ShowControls($('.catalogue .global-actions'));

                Onkho.LoadingTools.HideLoading(saveButton);
                modal.modal('hide');
            }
        );
    };

    var ResetServiceCatalogue = function ()
    {
        $('.catalogue table tbody').empty();
        $('.catalogue').removeData('offset');
        $('.catalogue .load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
    };

    var LoadMore = function (force)
    {
        force = typeof(force) == 'undefined' ? false : force;

        if (!force && !$('#catalogue').is(':visible'))
        {
            return;
        }

        var button = $('.catalogue .btn.load-more');
        button.html('<i class="fa fa-spin fa-spinner"></i>');
        button.addClass('disabled');

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.partyId = $('[name="partyId"]').attr('value');
        formData.offset = $('.catalogue').data('offset');

        formData.sort = $('.catalogue .sort-control .current').data('value');
        formData.filter = $('.catalogue .filter-control .current').data('value');
        formData.searchTerm = $('.catalogue .search-control input[name="service_catalogue_search"]').val();

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/service/loadMore',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            if (data.responseJSON.html.length > 0)
                            {
                                var html = $(data.responseJSON.html).each(
                                    function() {
                                        $(this).find('div.service-description').filter('[data-service-configuration]').each(
                                            function() {
                                                $(this).html(Onkho.RegisterServicePageTools.DescribeServiceScheduleConfiguration($(this).data('service-configuration')));
                                            }
                                        );
                                    }
                                );

                                $('.catalogue table tbody').append(html);
                                $('.catalogue').data('offset', data.responseJSON.offset);
                                button.html('Load more');
                                button.removeClass('disabled');
                            }
                            else
                            {
                                $('.catalogue table tbody').append('<tr><td colspan="2" class="no-more">No more service catalogue entries.</td></tr>');
                                $('.catalogue .btn.load-more').remove();
                            }
                            break;
                        default:
                            // Failed
                            break;
                    }
                }
            });

    };

    return {
        Init: Init
    };
}();
