Onkho.AddEditServicePanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_service"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditServicePanel = function (serviceId)
  {
    var additionalData = {};
    if (typeof serviceId !== 'undefined') {
      additionalData = {
        serviceId: serviceId
      };
    }

    Onkho.OnkhoPanel.Add('add_edit_service', additionalData, LoadAddEditServicePanelLoaded)
  };

  var LoadAddEditServicePanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide');

    panel.find('.panel-section[data-id="schedule"]').on('onkho:configuration.changed', function () {
      UpdateScheduleSection(panel)
    })

    panel.find('.panel-section[data-id="preconditions"]').on('onkho:configuration.changed', function () {
      UpdatePreconditionsSection(panel)
    })

    panel.find('.panel-section[data-id="checklist_items"]').on('onkho:configuration.changed', function () {
      UpdateChecklistItemsSection(panel)
    })

    panel.find('.panel-section[data-id="assignee"]').on('onkho:configuration.changed', function () {
      UpdateAssigneeSection(panel)
    })

    panel.find('.panel-section[data-id="autoplan"]').on('onkho:configuration.changed', function () {
      UpdateAutoplanSection(panel)
    })

    panel.find('.panel-section[data-id="communications"]').on('onkho:configuration.changed', function () {
      UpdateCommunicationsSection(panel)
    })

    // Set initial values
    panel.find('[name="service_name"]').val(panel.data('initialServiceName'))
    SetScheduleSectionConfiguration(panel, panel.data('initialScheduleConfiguration'))
    SetPreconditionsSectionConfiguration(panel, panel.data('initialPreconditionsConfiguration'))
    SetChecklistItemsSectionConfiguration(panel, panel.data('initialChecklistItemsConfiguration'))
    SetAssigneeSectionConfiguration(panel, panel.data('initialAssigneeConfiguration'))
    SetAutoplanSectionConfiguration(panel, panel.data('initialAutoplanConfiguration'))
    SetCommunicationsSectionConfiguration(panel, panel.data('initialCommunicationsConfiguration'))

    // Remove schedule section if schedule is not configurable
    var isScheduleConfigurable = panel.data('isScheduleConfigurable') == 1
    if (!isScheduleConfigurable) {
      RemoveScheduleSection(panel)
    }

    // Listeners
    var preconditionsSection = panel.find('.panel-section[data-id="preconditions"]')
    preconditionsSection.on('click', '.summary table .delete', function () {
      DeletePrecondition(panel, parseInt($(this).closest('tr').data('precondition-id')))
    })

    preconditionsSection.on('click', '.summary table .up', function () {
      MovePreconditionUp(panel, parseInt($(this).closest('tr').data('precondition-id')))
    })

    preconditionsSection.on('click', '.summary table .down', function () {
      MovePreconditionDown(panel, parseInt($(this).closest('tr').data('precondition-id')))
    })

    preconditionsSection.on('click', '.summary table .edit', function () {
      EditPrecondition(panel, parseInt($(this).closest('tr').data('precondition-id')))
    })

    var checklistItemsSection = panel.find('.panel-section[data-id="checklist_items"]')
    checklistItemsSection.on('click', '.summary table .delete', function () {
      DeleteChecklistItem(panel, parseInt($(this).closest('tr').data('checklist-item-id')))
    })

    checklistItemsSection.on('click', '.summary table .up', function () {
      MoveChecklistItemUp(panel, parseInt($(this).closest('tr').data('checklist-item-id')))
    })

    checklistItemsSection.on('click', '.summary table .down', function () {
      MoveChecklistItemDown(panel, parseInt($(this).closest('tr').data('checklist-item-id')))
    })

    checklistItemsSection.on('click', '.summary table .edit', function () {
      EditChecklistItem(panel, parseInt($(this).closest('tr').data('checklist-item-id')))
    })
  };

  var GetServiceName = function (panel) {
    var serviceName = panel.find('[name="service_name"]');
    if (serviceName.length) {
      return serviceName.val();
    } else {
      return 'None';
    }
  }

  var RemoveScheduleSection = function (panel) {
    panel.find('.panel-section[data-id="schedule"]').remove()
  }

  var GetScheduleSectionConfiguration = function (panel) {
    var scheduleSection = panel.find('.panel-section[data-id="schedule"]')
    if (scheduleSection.length) {
      return scheduleSection.data('configuration')
    } else {
      return {'_': 0}
    }
  }

  var SetScheduleSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="schedule"]')

    if (!configuration.description) {
      configuration.description = Onkho.AddEditServiceScheduleSubpanel.getDescription(configuration)
    }

    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateScheduleSection = function (panel) {
    var section = panel.find('.panel-section[data-id="schedule"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration) {
      summary.html(configuration.description)
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change schedule')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add schedule')
    }
  }

  var GetPreconditionsSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="preconditions"]').data('configuration')
  }

  var EditPrecondition = function (panel, preconditionId) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    var configuration = section.data('configuration') ?? {}

    Onkho.OnkhoPanel.Disable(panel);
    Onkho.AddEditServicePreconditionsSubpanel.LoadAddEditServicePreconditionsSubpanel(configuration[preconditionId]);
  }

  var DeletePrecondition = function (panel, preconditionId) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    var configuration = section.data('configuration') ?? {}
    preconditionId = parseInt(preconditionId)

    $.SmartMessageBox(
      {
        title: 'Delete requirement',
        content: 'Are you sure you want to delete requirement <strong>' + configuration[preconditionId].precondition_name + '</strong>?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          delete configuration[preconditionId]

          // Shift all subsequent preconditions up
          var currentPreconditionId = preconditionId + 1
          var foundPrecondition = typeof configuration.currentPreconditionId !== 'undefined'
          while (foundPrecondition) {
            configuration[currentPreconditionId - 1] = configuration.currentPreconditionId
            configuration[currentPreconditionId - 1].ordinal = currentPreconditionId - 1

            ++currentPreconditionId
            foundPrecondition = typeof configuration[currentPreconditionId] !== 'undefined'
          }

          section.data('configuration', configuration)
          section.trigger('onkho:configuration.changed')
        }
      }
    )
  }

  var MovePreconditionUp = function (panel, preconditionId) {
    if (preconditionId === 1) {
      return;
    }

    var section = panel.find('.panel-section[data-id="preconditions"]')
    var configuration = section.data('configuration') ?? {}

    var temp = configuration[preconditionId - 1]
    configuration[preconditionId - 1] = configuration[preconditionId]
    configuration[preconditionId] = temp

    configuration[preconditionId - 1].ordinal = preconditionId - 1
    configuration[preconditionId].ordinal = preconditionId

    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var MovePreconditionDown = function (panel, preconditionId) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    var configuration = section.data('configuration') ?? {}
    var maxPreconditionId = parseInt(Object.keys(configuration).reduce(function (maxId, currentId) { return parseInt(currentId) > parseInt(maxId) ? currentId : maxId; }, 0));
    if (preconditionId === maxPreconditionId) {
      return;
    }


    var temp = configuration[preconditionId + 1]
    configuration[preconditionId + 1] = configuration[preconditionId]
    configuration[preconditionId] = temp

    configuration[preconditionId + 1].ordinal = preconditionId + 1
    configuration[preconditionId].ordinal = preconditionId

    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var SetPreconditionsSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var SetPreconditionConfiguration = function (panel, preconditionConfiguration) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    var existingConfiguration = section.data('configuration') ?? {}

    preconditionConfiguration.ordinal = parseInt(preconditionConfiguration.ordinal)

    if (preconditionConfiguration.ordinal) {
      // This is an update to an existing precondition
      existingConfiguration[preconditionConfiguration.ordinal] = preconditionConfiguration;
    } else {
      // This is a new precondition

      // Determine next precondition ID
      var maxPreconditionId = parseInt(Object.keys(existingConfiguration).reduce(function (maxId, currentId) { return parseInt(currentId) > parseInt(maxId) ? currentId : maxId; }, 0));

      var nextPreconditionId = maxPreconditionId + 1;
      preconditionConfiguration.ordinal = nextPreconditionId;
      existingConfiguration[nextPreconditionId] = preconditionConfiguration;
    }

    section.data('configuration', existingConfiguration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdatePreconditionsSection = function (panel) {
    var section = panel.find('.panel-section[data-id="preconditions"]')
    var configuration = section.data('configuration') ?? {}
    var summary = section.find('.summary')
    var table = summary.find('table tbody')
    section.slideDown(150)

    if (Object.keys(configuration).length) {
      var tableHtml = ''
      var preconditionIds = Object.keys(configuration).sort(function(a, b) { return a - b })
      var maxPreconditionId = parseInt(preconditionIds[preconditionIds.length - 1])
      preconditionIds.forEach(function (preconditionId, index) {
        preconditionId = parseInt(preconditionId)
        tableHtml += '' +
          '<tr data-precondition-id="' + preconditionId + '">' +
          '  <td class="delete"><i class="fa fa-fw fa-times"></i></td>' +
          '  <td class="name">' + configuration[preconditionId].precondition_name + '</td>' +
          (preconditionId === 1 ? '<td></td>' : '<td class="up"><i class="fa fa-fw fa-arrow-up"></i></td>') +
          (preconditionId === maxPreconditionId ? '<td></td>' : '<td class="down"><i class="fa fa-fw fa-arrow-down"></i></td>') +
          '  <td class="edit"><i class="fa fa-fw fa-edit"></i></td>' +
          '</tr>'
      })
      table.html(tableHtml)
      summary.slideDown(150)
    } else {
      table.html('')
      summary.slideUp(150)
    }
  }

  var GetChecklistItemsSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="checklist_items"]').data('configuration')
  }

  var EditChecklistItem = function (panel, checklistItemId) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var configuration = section.data('configuration') ?? {}

    Onkho.OnkhoPanel.Disable(panel);
    Onkho.AddEditServiceChecklistItemsSubpanel.LoadAddEditServiceChecklistItemsSubpanel(configuration[checklistItemId]);
  }

  var DeleteChecklistItem = function (panel, checklistItemId) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var configuration = section.data('configuration') ?? {}
    checklistItemId = parseInt(checklistItemId)

    $.SmartMessageBox(
      {
        title: 'Delete step',
        content: 'Are you sure you want to delete step <strong>' + configuration[checklistItemId].step_name + '</strong>?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          delete configuration[checklistItemId]

          // Shift all subsequent checklist items up
          var currentChecklistItemId = checklistItemId + 1
          var foundChecklistItem = typeof configuration.currentChecklistItemId !== 'undefined'
          while (foundChecklistItem) {
            configuration[currentChecklistItemId - 1] = configuration.currentChecklistItemId
            configuration[currentChecklistItemId - 1].ordinal = currentChecklistItemId - 1

            ++currentChecklistItemId
            foundChecklistItem = typeof configuration[currentChecklistItemId] !== 'undefined'
          }

          section.data('configuration', configuration)
          section.trigger('onkho:configuration.changed')
        }
      }
    )
  }

  var MoveChecklistItemUp = function (panel, checklistItemId) {
    if (checklistItemId === 1) {
      return;
    }

    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var configuration = section.data('configuration') ?? {}

    var temp = configuration[checklistItemId - 1]
    configuration[checklistItemId - 1] = configuration[checklistItemId]
    configuration[checklistItemId] = temp

    configuration[checklistItemId - 1].ordinal = checklistItemId - 1
    configuration[checklistItemId].ordinal = checklistItemId

    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var MoveChecklistItemDown = function (panel, checklistItemId) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var configuration = section.data('configuration') ?? {}
    var maxChecklistItemId = parseInt(Object.keys(configuration).reduce(function (maxId, currentId) { return parseInt(currentId) > parseInt(maxId) ? currentId : maxId; }, 0));
    if (checklistItemId === maxChecklistItemId) {
      return;
    }


    var temp = configuration[checklistItemId + 1]
    configuration[checklistItemId + 1] = configuration[checklistItemId]
    configuration[checklistItemId] = temp

    configuration[checklistItemId + 1].ordinal = checklistItemId + 1
    configuration[checklistItemId].ordinal = checklistItemId

    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var SetChecklistItemsSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var SetChecklistItemConfiguration = function (panel, checklistItemConfiguration) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var existingConfiguration = section.data('configuration') ?? {}

    checklistItemConfiguration.ordinal = parseInt(checklistItemConfiguration.ordinal)

    if (checklistItemConfiguration.ordinal) {
      // This is an update to an existing checklist item
      existingConfiguration[checklistItemConfiguration.ordinal] = checklistItemConfiguration;
    } else {
      // This is a new checklist item

      // Determine next checklist item ID
      var maxChecklistItemId = parseInt(Object.keys(existingConfiguration).reduce(function (maxId, currentId) { return parseInt(currentId) > parseInt(maxId) ? currentId : maxId; }, 0));

      var nextChecklistItemId = maxChecklistItemId + 1;
      checklistItemConfiguration.ordinal = nextChecklistItemId;
      existingConfiguration[nextChecklistItemId] = checklistItemConfiguration;
    }

    section.data('configuration', existingConfiguration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateChecklistItemsSection = function (panel) {
    var section = panel.find('.panel-section[data-id="checklist_items"]')
    var configuration = section.data('configuration') ?? {}
    var summary = section.find('.summary')
    var table = summary.find('table tbody')
    section.slideDown(150)

    if (Object.keys(configuration).length) {
      var tableHtml = ''
      var checklistItemsIds = Object.keys(configuration).sort(function(a, b) { return a - b })
      var maxChecklistItemId = parseInt(checklistItemsIds[checklistItemsIds.length - 1])
      checklistItemsIds.forEach(function (checklistItemsId, index) {
        checklistItemsId = parseInt(checklistItemsId)
        tableHtml += '' +
          '<tr data-checklist-item-id="' + checklistItemsId + '">' +
          '  <td class="delete"><i class="fa fa-fw fa-times"></i></td>' +
          '  <td class="name">' + configuration[checklistItemsId].step_name + '</td>' +
          (checklistItemsId === 1 ? '<td></td>' : '<td class="up"><i class="fa fa-fw fa-arrow-up"></i></td>') +
          (checklistItemsId === maxChecklistItemId ? '<td></td>' : '<td class="down"><i class="fa fa-fw fa-arrow-down"></i></td>') +
          '  <td class="edit"><i class="fa fa-fw fa-edit"></i></td>' +
          '</tr>'
      })
      table.html(tableHtml)
      summary.slideDown(150)
    } else {
      table.html('')
      summary.slideUp(150)
    }
  }

  var GetAssigneeSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="assignee"]').data('configuration')
  }

  var SetAssigneeSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="assignee"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateAssigneeSection = function (panel) {
    var section = panel.find('.panel-section[data-id="assignee"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration && configuration.service_manager_id) {
      summary.html('Jobs will be assigned to ' + Onkho.Formatter.MakePartyActiveElement(configuration.service_manager_id, configuration.service_manager_name) + '.')
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change service manager')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add service manager')
    }
  }

  var GetAutoplanSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="autoplan"]').data('configuration')
  }

  var SetAutoplanSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="autoplan"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateAutoplanSection = function (panel) {
    var section = panel.find('.panel-section[data-id="autoplan"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration && configuration.autoplanning_time_unit_count !== null && configuration.autoplanning_time_unit !== null) {
      summary.html('Jobs will be automatically planned <span class="value">' + configuration.autoplanning_time_unit_count + ' ' + (parseInt(configuration.autoplanning_time_unit_count) == 1 ? configuration.autoplanning_time_unit.toLowerCase() : (configuration.autoplanning_time_unit.toLowerCase() + 's')) + '</span> ahead of the due date.')
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change autoplanning')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add autoplanning')
    }
  }

  var GetCommunicationsSectionConfiguration = function (panel) {
    return panel.find('.panel-section[data-id="communications"]').data('configuration')
  }

  var SetCommunicationsSectionConfiguration = function (panel, configuration) {
    var section = panel.find('.panel-section[data-id="communications"]')
    section.data('configuration', configuration)
    section.trigger('onkho:configuration.changed')
  }

  var UpdateCommunicationsSection = function (panel) {
    var section = panel.find('.panel-section[data-id="communications"]')
    var configuration = section.data('configuration')
    var summary = section.find('.summary')
    section.slideDown(150)

    if (configuration && configuration.sends_notifications) {
      summary.html('Client will receive automated notifications whenever a job is started or completed.')
      summary.slideDown(150)

      section.find('.panel-opener .label').html('Change notifications')
    } else {
      summary.html('')
      summary.slideUp(150)

      section.find('.panel-opener .label').html('Add notifications')
    }
  }

  var EnableLoading = function (panel, showPrimaryButtons) {
    panel.addClass('loading')
    if (showPrimaryButtons) {
      panel.addClass('show-primary-buttons')
    }
    panel.find('[name="service_id"]').select2('enable', false)
    panel.find('.panel-opener button').attr('disabled', true)
    Onkho.LoadingTools.ShowLoading(panel.find('[data-id="save"]'))
  }

  var DisableLoading = function (panel) {
    panel.removeClass('loading')
    panel.removeClass('show-save-button')
    panel.find('[name="service_id"]').select2('enable', true)
    panel.find('.panel-opener button').attr('disabled', false)
    Onkho.LoadingTools.HideLoading(panel.find('[data-id="save"]'))
  }

  var RegisterAddEditServicePanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('.panel-opener[data-id="schedule"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServiceScheduleSubpanel.LoadAddEditServiceScheduleSubpanel(GetScheduleSectionConfiguration(panel));
    });

    panel.find('.panel-opener[data-id="preconditions"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServicePreconditionsSubpanel.LoadAddEditServicePreconditionsSubpanel(null);
    });

    panel.find('.panel-opener[data-id="checklist_items"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServiceChecklistItemsSubpanel.LoadAddEditServiceChecklistItemsSubpanel(null);
    });

    panel.find('.panel-opener[data-id="assignee"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServiceAssigneeSubpanel.LoadAddEditServiceAssigneeSubpanel(GetAssigneeSectionConfiguration(panel));
    });

    panel.find('.panel-opener[data-id="autoplan"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServiceAutoplanSubpanel.LoadAddEditServiceAutoplanSubpanel(GetAutoplanSectionConfiguration(panel));
    });

    panel.find('.panel-opener[data-id="communications"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditServiceCommunicationsSubpanel.LoadAddEditServiceCommunicationsSubpanel(GetCommunicationsSectionConfiguration(panel));
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditService(panel);
    });
  };

  var AddEditService = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      EnableLoading(panel, true)

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF()
      formData.data = {}
      
      formData.data.service_id = 0
      formData.data.service_name = GetServiceName(panel);

      if (panel.data('serviceId')) {
        formData.data.service_id = panel.data('serviceId')
        formData.data.service_template = {
          existing_service: 1,
          service_template: panel.data('serviceDefinitionId'),
          service_type: panel.data('serviceType')
        }
      } else {
        formData.data.service_template = {
          existing_service: 0,
          service_template: '',
          service_type: ''
        }
      }

      formData.data.service_schedule = GetScheduleSectionConfiguration(panel)
      formData.data.service_messages = GetCommunicationsSectionConfiguration(panel)
      formData.data.service_manager = GetAssigneeSectionConfiguration(panel)
      formData.data.service_requirements = GetPreconditionsSectionConfiguration(panel)
      formData.data.service_steps = GetChecklistItemsSectionConfiguration(panel)
      formData.data.autoplanning = GetAutoplanSectionConfiguration(panel)

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/service/definitionServiceCombo',
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            DisableLoading(panel)

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.Remove(panel)
                $('.catalogue .refresh').click()

                Onkho.Alert.SmallBox('success', 'Your service has been saved!')
                break;

              default:
                Onkho.Alert.BigBox('error', data.responseJSON.title, data.responseJSON.message)
                break;
            }
          }
        });
    }
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_service].added', '.o-panel-container', function ()
    {
      var AddEditServicePanel = $('.o-panel[data-panel-key="add_edit_service"]');
      RegisterAddEditServicePanelBindings(AddEditServicePanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditServicePanel: LoadAddEditServicePanel,
    SetScheduleSectionConfiguration: SetScheduleSectionConfiguration,
    SetPreconditionsSectionConfiguration: SetPreconditionsSectionConfiguration,
    SetPreconditionConfiguration: SetPreconditionConfiguration,
    SetChecklistItemsSectionConfiguration: SetChecklistItemsSectionConfiguration,
    SetChecklistItemConfiguration: SetChecklistItemConfiguration,
    SetAssigneeSectionConfiguration: SetAssigneeSectionConfiguration,
    SetAutoplanSectionConfiguration: SetAutoplanSectionConfiguration,
    SetCommunicationsSectionConfiguration: SetCommunicationsSectionConfiguration
  };
}();
