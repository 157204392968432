Onkho.EditServiceInstanceCommunicationsSubpanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="edit_service_instance_communications"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadEditServiceInstanceCommunicationsSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    };

    Onkho.OnkhoPanel.Add('edit_service_instance_communications', additionalData, LoadEditServiceInstanceCommunicationsSubpanelLoaded)
  };

  var LoadEditServiceInstanceCommunicationsSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide');

    var sendsNotificationsToggle = panel.find('[name="sends_notifications"]')
    sendsNotificationsToggle.on('change', function () {
      if ($(this).prop('checked')) {
        panel.find('.communications-configuration').slideDown(150)
      } else {
        panel.find('.communications-configuration').slideUp(150)
      }
    })
    sendsNotificationsToggle.trigger('change')

    var initialValuesContainer = panel.find('.initial-values')

    // Start notification
    var startNotificationFromMailboxIdInputField = initialValuesContainer.find('input[type="hidden"][name="service_start_from_mailbox_id"]')
    var startNotificationAddressInputField = initialValuesContainer.find('input[type="hidden"][name="service_start_address"]')
    var startNotificationPracticeNameInputField = initialValuesContainer.find('input[type="hidden"][name="service_start_practice_name"]')
    var startNotificationSubjectInputField = initialValuesContainer.find('input[type="hidden"][name="service_start_subject"]')
    var startNotificationContentInputField = initialValuesContainer.find('input[type="hidden"][name="service_start_content"]')

    var startNotificationBuilder = panel.find('.activity-feed-item-builder.start-notification')
    var startNotificationFromMailboxId = startNotificationBuilder.find('[name="from_mailbox_id"]')
    var startNotificationSubject = startNotificationBuilder.find('[name="subject"]')
    var startNotificationContent = startNotificationBuilder.find('.summernote.content-area')

    Onkho.ActivityFeed.InitializeRecipientsPicker(startNotificationBuilder, 'OUTGOING_EMAIL')
    if (startNotificationFromMailboxIdInputField.val()) {
      startNotificationFromMailboxId.select2('data', {
        id: startNotificationFromMailboxIdInputField.val(),
        address: startNotificationAddressInputField.val(),
        practice_name: startNotificationPracticeNameInputField.val()
      });
    }

    startNotificationSubject.val(startNotificationSubjectInputField.val())

    Onkho.Summernote.EnableSummernote(startNotificationBuilder.find('.content-area'))
    Onkho.Summernote.UpdateSummernote(
      startNotificationContent,
      decodeURIComponent(startNotificationContentInputField.val())
    )

    // Complete notification
    var completeNotificationFromMailboxIdInputField = initialValuesContainer.find('input[type="hidden"][name="service_complete_from_mailbox_id"]')
    var completeNotificationAddressInputField = initialValuesContainer.find('input[type="hidden"][name="service_complete_address"]')
    var completeNotificationPracticeNameInputField = initialValuesContainer.find('input[type="hidden"][name="service_complete_practice_name"]')
    var completeNotificationSubjectInputField = initialValuesContainer.find('input[type="hidden"][name="service_complete_subject"]')
    var completeNotificationContentInputField = initialValuesContainer.find('input[type="hidden"][name="service_complete_content"]')

    var completeNotificationBuilder = panel.find('.activity-feed-item-builder.complete-notification')
    var completeNotificationFromMailboxId = completeNotificationBuilder.find('[name="from_mailbox_id"]')
    var completeNotificationSubject = completeNotificationBuilder.find('[name="subject"]')
    var completeNotificationContent = completeNotificationBuilder.find('.summernote.content-area')

    Onkho.ActivityFeed.InitializeRecipientsPicker(completeNotificationBuilder, 'OUTGOING_EMAIL')
    if (completeNotificationFromMailboxIdInputField.val()) {
      completeNotificationFromMailboxId.select2('data', {
        id: completeNotificationFromMailboxIdInputField.val(),
        address: completeNotificationAddressInputField.val(),
        practice_name: completeNotificationPracticeNameInputField.val()
      });
    }

    completeNotificationSubject.val(completeNotificationSubjectInputField.val())

    Onkho.Summernote.EnableSummernote(completeNotificationBuilder.find('.content-area'))
    Onkho.Summernote.UpdateSummernote(
      completeNotificationContent,
      decodeURIComponent(completeNotificationContentInputField.val())
    )

  }

  var RegisterEditServiceInstanceCommunicationsSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="edit_service_instance"]'))
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveCommunications(panel)
    })
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[edit_service_instance_communications].added', '.o-panel-container', function ()
    {
      var editServicesCommunicationsSubpanel = $('.o-panel[data-panel-key="edit_service_instance_communications"]');
      RegisterEditServiceInstanceCommunicationsSubpanelBindings(editServicesCommunicationsSubpanel);
    });
  };

  var SaveCommunications = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      var editServiceInstancePanel = $('.o-panel[data-panel-key="edit_service_instance"]')
      var configuration = GatherConfiguration(panel)
      Onkho.EditServiceInstancePanel.SetCommunicationsSectionConfiguration(editServiceInstancePanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = {}

    if (panel.find('[name="sends_notifications"]').prop('checked')) {
      configuration.sends_notifications = 1

      var startNotificationSender = panel.find('.start-notification input[name="from_mailbox_id"]').select2('data')
      configuration.start_notification_activity_feed_item_configuration = {
        from_mailbox_id: startNotificationSender.id,
        from_mailbox_address: startNotificationSender.address,
        from_mailbox_practice_name: startNotificationSender.practice_name,

        subject: panel.find('.start-notification input[name="subject"]').val(),
        content: Onkho.Summernote.GetSummernoteValue(panel.find('.start-notification .content-area'))
      }

      var completeNotificationSender = panel.find('.complete-notification input[name="from_mailbox_id"]').select2('data')
      configuration.complete_notification_activity_feed_item_configuration = {
        from_mailbox_id: completeNotificationSender.id,
        from_mailbox_address: completeNotificationSender.address,
        from_mailbox_practice_name: completeNotificationSender.practice_name,

        subject: panel.find('.complete-notification input[name="subject"]').val(),
        content: Onkho.Summernote.GetSummernoteValue(panel.find('.complete-notification .content-area'))
      }
    } else {
      configuration.sends_notifications = 0

      var initialValuesContainer = panel.find('.initial-values')

      configuration.start_notification_activity_feed_item_configuration = {
        from_mailbox_id: initialValuesContainer.find('input[type="hidden"][name="service_start_from_mailbox_id"]').val(),
        from_mailbox_address: initialValuesContainer.find('input[type="hidden"][name="service_start_address"]').val(),
        from_mailbox_practice_name: initialValuesContainer.find('input[type="hidden"][name="service_start_practice_name"]').val(),

        subject: initialValuesContainer.find('input[type="hidden"][name="service_start_subject"]').val(),
        content: decodeURIComponent(initialValuesContainer.find('input[type="hidden"][name="service_start_content"]').val())
      }

      configuration.complete_notification_activity_feed_item_configuration = {
        from_mailbox_id: initialValuesContainer.find('input[type="hidden"][name="service_complete_from_mailbox_id"]').val(),
        from_mailbox_address: initialValuesContainer.find('input[type="hidden"][name="service_complete_address"]').val(),
        from_mailbox_practice_name: initialValuesContainer.find('input[type="hidden"][name="service_complete_practice_name"]').val(),

        subject: initialValuesContainer.find('input[type="hidden"][name="service_complete_subject"]').val(),
        content: decodeURIComponent(initialValuesContainer.find('input[type="hidden"][name="service_complete_content"]').val())
      }
    }

    return configuration
  }



  return {
    Init: Init,
    LoadEditServiceInstanceCommunicationsSubpanel: LoadEditServiceInstanceCommunicationsSubpanel
  };
}();
