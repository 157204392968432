// Module containing tools to be used on the profile tab
Onkho.ProfileTools = function () {
  var addressSection = null
  var addressModal = null
  var addresses = null

  var telephoneSection = null
  var telephoneModal = null
  var telephones = null

  var emailSection = null
  var emailModal = null
  var emails = null

  var websiteSection = null
  var websiteModal = null
  var websites = null

  var customAttributeSection = null
  var customAttributeAddModal = null
  var customAttributeEditModal = null
  var customAttributes = null

  var Init = function () {
    Onkho.Variables.EditingParty = false

    var editPartyButton = $('.business-card-wrapper .business-card-buttons-bar .edit-party')
    var cancelEditPartyButton = $('.business-card-wrapper .business-card-buttons-bar .edit-mode-buttons .cancel')
    var savePartyButton = $('.business-card-wrapper .business-card-buttons-bar .edit-mode-buttons .save')
    var partyForm = $('form.party-edit')

    Onkho.Validator.ValidateForm(partyForm, savePartyButton)

    $('a[data-toggle="tab"]').on('click', function (event) {
      var tab = $(this)

      if (Onkho.Variables.EditingParty) {
        event.stopPropagation()
        $.SmartMessageBox(
          {
            title: '',
            content: 'You\'re currently editing ' + $('.party-name > span').html().trim() + '. You will lose any changes you have made if you leave this page without saving. Do you want to proceed?',
            buttons: '[No][Yes]'
          }, function (ButtonPress) {
            if (ButtonPress === 'Yes') {
              CancelEditParty()
              tab.tab('show')
            }
          })
      }
    })

    partyForm.find('select[name="client_manager_id"]').select2({
      placeholder: 'No one',
      sortResults: Onkho.Functions.Select2Sort
    })

    $('.core-attributes-section .core-attributes').find('select').select2({
      sortResults: Onkho.Functions.Select2Sort
    })

    // Show edit form when the button is clicked
    editPartyButton.on('click',
      function () {
        EditParty()
      }
    )

    // Cancel edit and restore the read-only view
    cancelEditPartyButton.on('click',
      function () {
        CancelEditParty()
      }
    )

    // Save the party form.
    savePartyButton.on('click', function () {
      $('form.party-edit').submit()
    })

    InitEditAddress()

    InitEditTelephone()

    InitEditEmail()

    InitEditWebSite()

    InitCustomAttributes()

    // Preview avatar
    $('input[name="avatar"]').on('change', function () {
      if (this.files && this.files[0]) {
        var reader = new FileReader()
        var previewContainer = $(this).closest('section').find('.image-preview')

        reader.onload = function (e) {
          previewContainer.css('background-image', 'url(' + e.target.result + ')')
          previewContainer.slideDown()
        }

        reader.readAsDataURL(this.files[0])
      }
    })

    // Default rules for display name and trading name
    var firstNameField = $('form.party-edit [name="first_name"]')
    var lastNameField = $('form.party-edit [name="last_name"]')
    var tradingNameField = $('form.party-edit [name="trading_name"]')
    var legalNameField = $('form.party-edit [name="legal_name"]')

    firstNameField.on('input', function () {
      ComputeDisplayName()
    })

    lastNameField.on('input', function () {
      ComputeDisplayName()
    })

    tradingNameField.on('input', function () {
      ComputeDisplayName()
    })

    legalNameField.on('input', function () {
      ComputeDisplayName()
    })


    // Primary contact
    var primaryContactPicker = $('form.party-edit [name="primary_contact_party_id"]')
    primaryContactPicker.select2({
      placeholder: 'Find a contact',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/contact/getContactSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            allowClear: true,
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return {results: data.items, more: more};
        }
      },
      formatResult: FormatContactSearchResult,
      formatSelection: FormatContactSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    });

    var initialPrimaryContactPartyId = primaryContactPicker.data('initial-id')
    if (initialPrimaryContactPartyId) {
      primaryContactPicker.select2('data', {
        'id': initialPrimaryContactPartyId,
        'name': primaryContactPicker.data('initial-name'),
        'html': ''
      });
    }
  }

  var FormatContactSearchResult = function (contact) {
    return contact.html
  }

  var FormatContactSearchResultSelection = function (contact) {
    return contact.name
  }

  var ComputeDisplayName = function () {
    var displayNameField = $('form.party-edit [name="display_name"]')
    var firstNameField = $('form.party-edit [name="first_name"]')
    var lastNameField = $('form.party-edit [name="last_name"]')
    var tradingNameField = $('form.party-edit [name="trading_name"]')
    var legalNameField = $('form.party-edit [name="legal_name"]')

    var newDisplayName = ''

    // Person
    if (firstNameField.length && lastNameField.length) {
      newDisplayName = firstNameField.val() + ' ' + lastNameField.val()

      // Sole Trader
      if (tradingNameField.length) {
        if (tradingNameField.val().length) {
          if (newDisplayName != tradingNameField.val()) {
            newDisplayName = newDisplayName + ' t/a ' + tradingNameField.val()
          }
        } else {
          newDisplayName += ' (Sole Trader)'
        }
      }
    } else
    // Organisation
    {
      // Incorporated Organisation
      if (tradingNameField.length && legalNameField.length) {
        if (legalNameField.val().length) {
          if (legalNameField.val() !== tradingNameField.val() && tradingNameField.val().length) {
            newDisplayName = legalNameField.val() + ' t/a ' + tradingNameField.val()
          } else {
            newDisplayName = legalNameField.val()
          }
        } else if (tradingNameField.val().length) {
          newDisplayName = tradingNameField.val()
        }
      } else
      // Unincorporated Organisation
      if (tradingNameField.length) {
        if (tradingNameField.val().length) {
          newDisplayName = tradingNameField.val()
        }
      }
    }

    displayNameField.val(newDisplayName)
  }

  var EditParty =
    function () {
      Onkho.Variables.EditingParty = true

      $('.profile-page .view-profile-fields').fadeOut(
        function () {
          $('.edit-profile-form').fadeIn()
        }
      )

      $('.tab-content.profile-tabs-content').find('.tab-pane.active').children().first().fadeOut()
      $('.business-card-wrapper .business-card-buttons-bar .buttons-wrapper').fadeOut(
        function () {
          $('.business-card-wrapper .business-card-buttons-bar .edit-mode-buttons').fadeIn()
        }
      )
    }

  var CancelEditParty =
    function () {
      Onkho.Variables.EditingParty = false

      $('.edit-profile-form').fadeOut(
        function () {
          $('.profile-page .view-profile-fields').fadeIn()
        }
      )

      $('.tab-content.profile-tabs-content').find('.tab-pane.active').children().first().fadeIn()
      $('.business-card-wrapper .business-card-buttons-bar .edit-mode-buttons').fadeOut(
        function () {
          $('.business-card-wrapper .business-card-buttons-bar .buttons-wrapper').fadeIn()
        }
      )

      // Undo deletions made to custom attributes
      customAttributes.find('[data-custom-attribute]').filter(':hidden').each(
        function () {
          var action = $(this).find('td.attribute-container').find('input[type="hidden"][data-type="action"]')
          var cardinality = $(this).find('td.attribute-container').find('input[type="hidden"][data-type="cardinality"]')

          if (action.data('prev-value')) {
            action.val(action.data('prev-value'))
            action.removeData('prev-value')
          }

          if (cardinality.data('prev-value')) {
            cardinality.val(cardinality.data('prev-value'))
            cardinality.removeData('prev-value')
          }
        }
      ).show()

      customAttributes.children().filter(':visible').find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
    }

  var SaveContactInfo =
    function (formData, saveButton) {

      formData._token = Onkho.Functions.GetCSRF()

      $.ajax({
        type: 'PUT',
        url: $('form.party-edit').attr('action'),
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(saveButton)
          switch (data.status) {
            case 200: {
              switch (formData.section) {
                case 'address' : {
                  Onkho.Alert.SmallBox('success', formData.type + ' address was saved successfully.')
                  UpdateAddressEntry(formData, data.responseJSON.id, data.responseJSON.isPrimary)
                  break
                }

                case 'telephone' : {
                  Onkho.Alert.SmallBox('success', formData.type + ' telephone was saved successfully.')
                  UpdateTelephoneEntry(formData, data.responseJSON.id, data.responseJSON.isPrimary)
                  break
                }

                case 'email' : {
                  Onkho.Alert.SmallBox('success', formData.type + ' email address was saved successfully.')
                  UpdateEmailEntry(formData, data.responseJSON.id, data.responseJSON.isPrimary)
                  break
                }

                case 'website' : {
                  Onkho.Alert.SmallBox('success', formData.type + ' was saved successfully.')
                  UpdateWebsiteEntry(formData, data.responseJSON.id, data.responseJSON.isPrimary)
                  break
                }
              }

              $('#content').find('[rel="tooltip"]').tooltip()
              break
            }

            case 400: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }

            default: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }
          }

          $('.modal').modal('hide')
        }
      })
    }

  var DeleteContactInfo =
    function (entry, formData) {

      formData._token = Onkho.Functions.GetCSRF()
      formData.delete = 1

      $.ajax({
        type: 'PUT',
        url: $('form.party-edit').attr('action'),
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200: {
              switch (formData.section) {
                case 'address' : {
                  Onkho.Alert.SmallBox('success', 'Address was deleted successfully.')
                  DeleteAddressEntry(entry)
                  break
                }

                case 'telephone' : {
                  Onkho.Alert.SmallBox('success', 'Telephone number was deleted successfully.')
                  DeleteTelephoneEntry(entry)
                  break
                }

                case 'email' : {
                  Onkho.Alert.SmallBox('success', 'Email address was deleted successfully.')
                  DeleteEmailEntry(entry)
                  break
                }

                case 'website' : {
                  Onkho.Alert.SmallBox('success', 'Website was deleted successfully.')
                  DeleteWebsiteEntry(entry)
                  break
                }
              }

              break
            }

            case 400: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }

            default: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }
          }
        }
      })
    }

  var MakePrimary =
    function (entry, formData) {

      formData._token = Onkho.Functions.GetCSRF();
      formData.is_primary = 1;

      $.ajax({
        type: 'PUT',
        url: $('form.party-edit').attr('action'),
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200: {
              switch (formData.section) {
                case 'address' : {
                  Onkho.Alert.SmallBox('success', 'The primary address has been updated successfully.')
                  addressSection.find('.primary').attr('data-original-title', 'Make this the primary address').removeClass('primary').addClass('make-primary')
                  entry.find('.primary-container div').attr('data-original-title', 'Primary address').removeClass('make-primary').addClass('primary')
                  break
                }

                case 'telephone' : {
                  Onkho.Alert.SmallBox('success', 'The primary telephone has been updated successfully.')
                  telephoneSection.find('.primary').attr('data-original-title', 'Make this the primary telephone').removeClass('primary').addClass('make-primary')
                  entry.find('.primary-container div').attr('data-original-title', 'Primary telephone').removeClass('make-primary').addClass('primary')
                  break
                }

                case 'email' : {
                  Onkho.Alert.SmallBox('success', 'The primary email has been updated successfully.')
                  emailSection.find('.primary').attr('data-original-title', 'Make this the primary email address').removeClass('primary').addClass('make-primary')
                  entry.find('.primary-container div').attr('data-original-title', 'Primary email address').removeClass('make-primary').addClass('primary')
                  break
                }

                case 'website' : {
                  Onkho.Alert.SmallBox('success', 'The primary website has been updated successfully.')
                  websiteSection.find('.primary').attr('data-original-title', 'Make this the primary website').removeClass('primary').addClass('make-primary')
                  entry.find('.primary-container div').attr('data-original-title', 'Primary website').removeClass('make-primary').addClass('primary')
                  break
                }
              }
              break
            }

            case 400: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }

            default: {
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
            }
          }
        }
      })
    }

  var InitEditAddress =
    function () {

      addressSection = $('.edit-profile-form .address-section')
      addressModal = addressSection.find('.modal')
      addresses = addressSection.find('.addresses')

      addresses.on('mouseenter', '[data-address] table',
        function () {
          $(this).find('.controls a').finish().fadeIn(150)
        }
      )

      addresses.on('mouseleave', '[data-address] table',
        function () {
          $(this).find('.controls a').finish().fadeOut(150)
        }
      )

      addressModal.modal({
        show: false,
        keyboard: true
      })

      addressModal.find('.select[name="country_id"]').select2({
        placeholder: 'Country'
      })

      addressModal.find('.address-form input').on('keypress',
        function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            addressModal.find('.save').click()
          }
        }
      )

      addressSection.on('click', '.add',
        function () {
          addressModal.find('input').val('')
          addressModal.find('select').select2('val', '')
          addressModal.find('.select[name="country_id"]').select2('val', 826)

          Onkho.Validator.ResetChildrenValidation(addressModal)
          addressModal.modal('show')
        }
      )

      addresses.on('click', '.edit',
        function () {
          var entry = $(this).closest('tr')

          addressModal.find('input[name="id"]')
            .val(entry.data('id'))
          addressModal.find('select[name="type"]')
            .val(entry.find('td.address-container').find('.type').text().trim())
          addressModal.find('input[name="line1"]')
            .val(entry.find('td.address-container').find('.line1').text().trim())
          addressModal.find('input[name="line2"]')
            .val(entry.find('td.address-container').find('.line2').text().trim())
          addressModal.find('input[name="line3"]')
            .val(entry.find('td.address-container').find('.line3').text().trim())
          addressModal.find('input[name="city"]')
            .val(entry.find('td.address-container').find('.city').text().trim())
          addressModal.find('input[name="county"]')
            .val(entry.find('td.address-container').find('.county').text().trim())
          addressModal.find('input[name="postcode"]')
            .val(entry.find('td.address-container').find('.postcode').text().trim())
          addressModal.find('select[name="country_id"]')
            .select2('val', entry.find('td.address-container').find('.country_id').data('id'))

          addressModal.modal('show')
        }
      )

      addressModal.on('click', '.save',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(addressModal.find('form.address-form'))) {
            var formData = {}
            formData.section = 'address'
            formData.id = addressModal.find('input[name="id"]').val()
            formData.type = addressModal.find('input[name="type"]').val()
            formData.resource = addressModal.find('input[name="address"]').val()
            formData.line1 = addressModal.find('input[name="line1"]').val()
            formData.line2 = addressModal.find('input[name="line2"]').val()
            formData.line3 = addressModal.find('input[name="line3"]').val()
            formData.county = addressModal.find('input[name="county"]').val()
            formData.city = addressModal.find('input[name="city"]').val()
            formData.postcode = addressModal.find('input[name="postcode"]').val()
            formData.country_id = addressModal.find('select[name="country_id"]').val()

            SaveContactInfo(formData, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )

      addresses.on('click', '.delete',
        function () {
          var entry = $(this).closest('tr')

          $.SmartMessageBox({
            title: 'Deleting address',
            content: 'Are you sure you want to delete this address?',
            buttons: '[No][Yes]'
          }, function (ButtonPress) {
            if (ButtonPress === 'Yes') {
              var formData = {}
              formData.section = 'address'
              formData.id = entry.data('id')

              DeleteContactInfo(entry, formData)
            }
          })
        }
      )

      addresses.on('click', '.make-primary',
        function () {
          var entry = $(this).closest('tr')

          var formData = {}
          formData.section = 'address'
          formData.id = entry.data('id')

          MakePrimary(entry, formData)
        }
      )

      addressModal.find('.find-address').on('click',
        function () {
          var formData = {}
          formData._token = Onkho.Functions.GetCSRF()
          formData.postcode = addressModal.find('input[name="find_address_at_postcode"]').val()
          formData.house = addressModal.find('input[name="find_address_at_house"]').val()

          $.ajax({
            type: 'GET',
            url: Onkho.Variables.BaseURL + '/address/get',
            data: formData,
            dataType: 'json',
            complete: function (data) {
              switch (data.status) {
                case 200: {
                  var addressSelect = addressModal.find('.address-select')

                  // Clear address select
                  addressSelect.find('option').remove()

                  // Populate with new addresses
                  addressSelect.append('<option value="" selected disabled>Select a result</option>')
                  Onkho.Variables.AddressesCache = data.responseJSON.addresses

                  for (var i = 0; i < data.responseJSON.addresses.length; i++) {
                    addressSelect.append('<option value="' + i + '">' + data.responseJSON.addresses[i].line1 + ', ' + data.responseJSON.addresses[i].city + '</option>')
                  }

                  // Show the address dropdown
                  addressSelect.closest('section').slideDown(200)

                  break
                }

                default: {
                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                  break
                }
              }
            }
          })
        }
      )

      addressModal.find('.address-select').on('change',
        function () {
          var addressData = Onkho.Variables.AddressesCache[parseInt($(this).find('option:selected').val())]

          addressModal.find('.address-form input[name="line1"]')
            .val(addressData.line1)
          addressModal.find('.address-form input[name="line2"]')
            .val(addressData.line2)
          addressModal.find('.address-form input[name="line3"]')
            .val(addressData.line3)
          addressModal.find('.address-form input[name="city"]')
            .val(addressData.city)
          addressModal.find('.address-form input[name="county"]')
            .val(addressData.county)
          addressModal.find('.address-form input[name="postcode"]')
            .val(addressData.postcode)
          addressModal.find('.address-form select[name="country_id"]')
            .select2('val', addressData.countryId)
        }
      )
    }

  var InitEditTelephone =
    function () {

      telephoneSection = $('.edit-profile-form .telephone-section')
      telephoneModal = telephoneSection.find('.modal')
      telephones = telephoneSection.find('.telephones')

      telephones.on('mouseenter', '[data-telephone] table',
        function () {
          $(this).find('.controls a').finish().fadeIn(150)
        }
      )

      telephones.on('mouseleave', '[data-telephone] table',
        function () {
          $(this).find('.controls a').finish().fadeOut(150)
        }
      )

      telephoneModal.modal({
        show: false,
        keyboard: true
      })

      telephoneModal.find('.telephone-form input').on('keypress',
        function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            telephoneModal.find('.save').click()
          }
        }
      )

      telephoneSection.on('click', '.add',
        function () {
          telephoneModal.find('input').val('')

          Onkho.Validator.ResetChildrenValidation(telephoneModal)
          telephoneModal.modal('show')
        }
      )

      telephones.on('click', '.edit',
        function () {
          var entry = $(this).closest('tr')

          telephoneModal.find('input[name="id"]')
            .val(entry.data('id'))

          telephoneModal.find('input[name="type"]')
            .val(entry.find('td.telephone-container').find('.type').text().trim())

          telephoneModal.find('input[name="number"]')
            .val(entry.find('td.telephone-container').find('.number').find('.number-value').text().trim())

          telephoneModal.find('input[name="extension"]')
            .val(entry.find('td.telephone-container').find('.number').find('.extension-value').text().trim())

          telephoneModal.modal('show')
        }
      )

      telephoneModal.find('.save').on('click',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(telephoneModal.find('form.telephone-form'))) {
            var formData = {}
            formData.section = 'telephone'
            formData.id = telephoneModal.find('input[name="id"]').val()
            formData.resource = telephoneModal.find('input[name="telephone"]').val()
            formData.type = telephoneModal.find('input[name="type"]').val()
            formData.extension = telephoneModal.find('input[name="extension"]').val()
            formData.number = telephoneModal.find('input[name="number"]').val()

            SaveContactInfo(formData, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )

      telephones.on('click', '.delete',
        function () {
          var entry = $(this).closest('tr')

          $.SmartMessageBox({
            title: 'Deleting telephone number',
            content: 'Are you sure you want to delete this telephone number?',
            buttons: '[No][Yes]'
          }, function (ButtonPress) {
            if (ButtonPress === 'Yes') {
              var formData = {}
              formData.section = 'telephone'
              formData.id = entry.data('id')

              DeleteContactInfo(entry, formData)
            }
          })
        }
      )

      telephones.on('click', '.make-primary',
        function () {
          var entry = $(this).closest('tr')

          var formData = {}
          formData.section = 'telephone'
          formData.id = entry.data('id')

          MakePrimary(entry, formData)
        }
      )
    }

  var InitEditEmail =
    function () {

      emailSection = $('.edit-profile-form .email-section')
      emailModal = emailSection.find('.modal')
      emails = emailSection.find('.emails')

      emails.on('mouseenter', '[data-email] table',
        function () {
          $(this).find('.controls a').finish().fadeIn(150)
        }
      )

      emails.on('mouseleave', '[data-email] table',
        function () {
          $(this).find('.controls a').finish().fadeOut(150)
        }
      )

      emailModal.modal({
        show: false,
        keyboard: true
      })

      emailModal.find('.email-form input').on('keypress',
        function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            emailModal.find('.save').click()
          }
        }
      )

      emailSection.on('click', '.add',
        function () {
          emailModal.find('input').val('')

          Onkho.Validator.ResetChildrenValidation(emailModal)
          emailModal.modal('show')
        }
      )

      emails.on('click', '.edit',
        function () {
          var entry = $(this).closest('tr')

          emailModal.find('input[name="id"]')
            .val(entry.data('id'))

          emailModal.find('input[name="type"]')
            .val(entry.find('td.email-container').find('.type').text().trim())

          emailModal.find('input[name="address"]')
            .val(entry.find('td.email-container').find('.address').find('a').text().trim())

          emailModal.modal('show')
        }
      )

      emailModal.find('.save').on('click',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(emailModal.find('form.email-form'))) {
            var formData = {}
            formData.section = 'email'
            formData.id = emailModal.find('input[name="id"]').val()
            formData.resource = emailModal.find('input[name="email"]').val()
            formData.type = emailModal.find('input[name="type"]').val()
            formData.address = emailModal.find('input[name="address"]').val()

            SaveContactInfo(formData, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )

      emails.on('click', '.delete',
        function () {
          var entry = $(this).closest('tr')

          $.SmartMessageBox({
            title: 'Deleting email address',
            content: 'Are you sure you want to delete this email address?',
            buttons: '[No][Yes]'
          }, function (ButtonPress) {
            if (ButtonPress === 'Yes') {
              var formData = {}
              formData.section = 'email'
              formData.id = entry.data('id')

              DeleteContactInfo(entry, formData)
            }
          })
        }
      )

      emails.on('click', '.make-primary',
        function () {
          var entry = $(this).closest('tr')

          var formData = {}
          formData.section = 'email'
          formData.id = entry.data('id')

          MakePrimary(entry, formData)
        }
      )
    }

  var InitEditWebSite =
    function () {

      websiteSection = $('.edit-profile-form .website-section')
      websiteModal = websiteSection.find('.modal')
      websites = websiteSection.find('.websites')

      websites.on('mouseenter', '[data-website] table',
        function () {
          $(this).find('.controls a').finish().fadeIn(150)
        }
      )

      websites.on('mouseleave', '[data-website] table',
        function () {
          $(this).find('.controls a').finish().fadeOut(150)
        }
      )

      websiteModal.modal({
        show: false,
        keyboard: true
      })

      websiteModal.find('.website-form input').on('keypress',
        function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            websiteModal.find('.save').click()
          }
        }
      )

      websiteSection.on('click', '.add',
        function () {
          websiteModal.find('input').val('')

          Onkho.Validator.ResetChildrenValidation(websiteModal)
          websiteModal.modal('show')
        }
      )

      websites.on('click', '.edit',
        function () {
          var entry = $(this).closest('tr')

          websiteModal.find('input[name="id"]')
            .val(entry.data('id'))

          websiteModal.find('input[name="type"]')
            .val(entry.find('td.website-container').find('.type').text().trim())

          websiteModal.find('input[name="url"]')
            .val(entry.find('td.website-container').find('.url').find('a').text().trim())

          websiteModal.modal('show')
        }
      )

      websiteModal.find('.save').on('click',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(websiteModal.find('form.website-form'))) {
            var formData = {}
            formData.section = 'website'
            formData.id = websiteModal.find('input[name="id"]').val()
            formData.resource = websiteModal.find('input[name="website"]').val()
            formData.type = websiteModal.find('input[name="type"]').val()
            formData.url = websiteModal.find('input[name="url"]').val()

            SaveContactInfo(formData, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )

      websites.on('click', '.delete',
        function () {
          var entry = $(this).closest('tr')

          $.SmartMessageBox({
            title: 'Deleting website',
            content: 'Are you sure you want to delete this website?',
            buttons: '[No][Yes]'
          }, function (ButtonPress) {
            if (ButtonPress === 'Yes') {
              var formData = {}
              formData.section = 'website'
              formData.id = entry.data('id')

              DeleteContactInfo(entry, formData)
            }
          })
        }
      )

      websites.on('click', '.make-primary',
        function () {
          var entry = $(this).closest('tr')

          var formData = {}
          formData.section = 'website'
          formData.id = entry.data('id')

          MakePrimary(entry, formData)
        }
      )
    }

  var InitCustomAttributes =
    function () {

      customAttributeSection = $('.edit-profile-form .custom-attributes-section')
      customAttributeAddModal = $('#content').find('.modal.add-custom-attribute')
      customAttributeEditModal = $('#content').find('.modal.edit-custom-attribute')
      customAttributes = customAttributeSection.find('.custom-attributes')

      customAttributeAddModal.find('select[name="attribute_type"]').select2({
        placeholder: 'Property Type',
        sortResults: Onkho.Functions.Select2Sort
      })

      customAttributes.on('mouseenter', '[data-custom-attribute] table',
        function () {
          $(this).find('.controls a').finish().fadeIn(150)
        }
      )

      customAttributes.on('mouseleave', '[data-custom-attribute] table',
        function () {
          $(this).find('.controls a').finish().fadeOut(150)
        }
      )

      customAttributeAddModal.modal({
        show: false,
        keyboard: true
      })

      customAttributeEditModal.modal({
        show: false,
        keyboard: true
      })

      customAttributeSection.on('click', '.add',
        function () {
          customAttributeAddModal.find('input[type="text"]').val('')
          customAttributeAddModal.find('select').select2('val', 'textbox')

          Onkho.Validator.ResetChildrenValidation(customAttributeAddModal)
          customAttributeAddModal.modal('show')
        }
      )

      customAttributes.on('click', '.edit',
        function () {
          var entry = $(this).closest('tr')

          customAttributeEditModal.find('input[name="attribute_id"]')
            .val(entry.find('label input').attr('name'))

          customAttributeEditModal.find('input[name="attribute_name"]')
            .val(entry.find('input[data-type="label"]').val())

          customAttributeEditModal.find('input[name="attribute_position"]')
            .val(entry.find('input[data-type="position"]').val())

          customAttributeEditModal.find('input[name="attribute_cardinality"][value="' + entry.find('input[data-type="cardinality"]').val() + '"]')
            .prop('checked', true);

          customAttributeEditModal.modal('show')
        }
      )

      customAttributes.on('click', '.delete',
        function () {
          var entry = $(this).closest('tr')

          var deleteButton = $(this)
          Onkho.LoadingTools.ShowLoading(deleteButton)

          var partyId = $('input[type="hidden"][name="partyId"]').val()
          var attributeId = entry.data('id')

          DeleteCustomAttribute(partyId, attributeId, deleteButton)
        }
      )

      customAttributeAddModal.find('.save').on('click',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(customAttributeAddModal.find('form.add-custom-attribute-form'))) {
            var partyId = $('input[type="hidden"][name="partyId"]').val()
            var attributeName = customAttributeAddModal.find('[name="attribute_name"]').val()
            var attributeType = customAttributeAddModal.find('[name="attribute_type"]').val()
            var attributePosition = parseInt(Onkho.TextTools.StripTags(customAttributeAddModal.find('[name="attribute_position"]').val()))
            var attributeCardinality = customAttributeAddModal.find('[name="attribute_cardinality"]:checked').val()

            AddCustomAttribute(partyId, attributeName, attributeType, attributePosition, attributeCardinality, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )

      customAttributeEditModal.find('.save').on('click',
        function () {

          var saveButton = $(this)
          Onkho.LoadingTools.ShowLoading(saveButton)

          if (Onkho.Validator.ValidateChildren(customAttributeEditModal.find('form.add-custom-attribute-form'))) {
            var partyId = $('input[type="hidden"][name="partyId"]').val()
            var attributeId = customAttributeEditModal.find('[name="attribute_id"]').val()
            var attributeName = customAttributeEditModal.find('[name="attribute_name"]').val()
            var attributePosition = parseInt(Onkho.TextTools.StripTags(customAttributeEditModal.find('[name="attribute_position"]').val()))
            var attributeCardinality = customAttributeEditModal.find('[name="attribute_cardinality"]:checked').val()

            UpdateCustomAttribute(partyId, attributeId, attributeName, attributePosition, attributeCardinality, saveButton)
          } else {
            Onkho.LoadingTools.HideLoading(saveButton)
          }
        }
      )
    }

  var UpdateAddressEntry =
    function (data, id, isPrimary) {

      var entry = addresses.find('[data-address] tr[data-id="' + id + '"]')
      var country = addressModal.find('select[name="country_id"]').find('option[value="' + data.country_id + '"]')

      if (entry.length === 0) {
        addresses.find('[data-address-placeholder]').remove()

        entry = $(
          '<div class="row margin-top-10" data-address>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name' + ((addresses.children().length === 0) ? '' : ' soft-hide') + '">' +
          '            Addresses' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add address">' +
          '                + Address' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '        <div class="profile-edit-section-content well well-light well-slim-padding well-square no-margin">' +
          '            <table>' +
          '                <tbody>' +
          '                <tr data-id="' + id + '">' +
          '                    <td class="primary-container">' +
          '                        <div class="' + ((isPrimary) ? 'primary' : 'make-primary') + '" title="" rel="tooltip" data-placement="bottom" data-original-title="' + ((isPrimary) ? 'Primary address' : 'Make this the primary address') + '"></div>' +
          '                    </td>' +
          '                    <td class="address-container">' +
          '                        <div class="type">' +
          '                           ' + data.type +
          '                        </div>' +
          '                        <div class="line1">' +
          '                            ' + data.line1 +
          '                        </div>' +
          '                        <div class="line2">' +
          '                            ' + data.line2 +
          '                        </div>' +
          '                        <div class="line3">' +
          '                            ' + data.line3 +
          '                        </div>' +
          '                        <div class="city">' +
          '                            ' + data.city +
          '                        </div>' +
          '                        <div class="county">' +
          '                            ' + data.county +
          '                        </div>' +
          '                        <div class="postcode">' +
          '                            ' + data.postcode +
          '                        </div>' +
          '                        <div class="country_id" data-id="' + data.country_id + '">' +
          '                            ' + country.html() +
          '                        </div>' +
          '                    </td>' +
          '                    <td class="address-action-buttons">' +
          '                        <div class="dropdown pull-right">' +
          '                            <img src="' + Onkho.Variables.BaseURL + '/img/ellipsis-dark.png' + '" data-toggle="dropdown">' +
          '                            <ul class="dropdown-menu">' +
          '                                <li><a href="javascript:void(0);" class="edit">Edit</a></li>' +
          '                                <li><a href="javascript:void(0);" class="delete">Delete</a></li>' +
          '                            </ul>' +
          '                        </div>' +
          '                    </td>' +
          '                </tr>' +
          '                </tbody>' +
          '            </table>' +
          '        </div>' +
          '    </div>' +
          '</div>'
        )

        addresses.append(entry)
      } else {
        entry.find('td.address-container div.type')
          .text(data.type)

        entry.find('td.address-container div.line1')
          .text(data.line1)

        entry.find('td.address-container div.line2')
          .text(data.line2)

        entry.find('td.address-container div.line3')
          .text(data.line3)

        entry.find('td.address-container div.city')
          .text(data.city)

        entry.find('td.address-container div.county')
          .text(data.county)

        entry.find('td.address-container div.postcode')
          .text(data.postcode)

        entry.find('td.address-container div.country_id')
          .data('id', data.country_id)
          .text(country.html())
      }

      addresses.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')

      if (isPrimary) {
        addresses.find('tr[data-id] td.primary-container div.primary').attr('data-original-title', 'Make this the primary address').removeClass('primary').addClass('make-primary')
        entry.find('.primary-container div').attr('data-original-title', 'Primary address').removeClass('make-primary').addClass('primary')
      }
    }

  var DeleteAddressEntry =
    function (entry) {

      entry.closest('[data-address]').remove()

      if (addresses.children().length > 0) {
        addresses.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
      } else {
        addresses.append($(
          '<div class="row margin-top-10" data-address-placeholder>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name">' +
          '            Addresses' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add address">' +
          '                + Address' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '    </div>' +
          '</div>'
        ))

        addresses.find('[rel="tooltip"]').tooltip()
      }
    }

  var UpdateTelephoneEntry = function (data, id, isPrimary) {
    var entry = telephones.find('[data-telephone] tr[data-id="' + id + '"]')

    if (entry.length === 0) {
      telephones.find('[data-telephone-placeholder]').remove()

      entry = $(
        '<div class="row margin-top-10" data-telephone>' +
        '    <div class="col-sm-2">' +
        '        <div class="profile-edit-section-name' + ((telephones.children().length === 0) ? '' : ' soft-hide') + '">' +
        '            Telephones' +
        '            <br />' +
        '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add telephone">' +
        '                + Telephone' +
        '            </a>' +
        '        </div>' +
        '    </div>' +
        '    <div class="col-sm-10">' +
        '        <div class="profile-edit-section-content well well-light well-slim-padding well-square no-margin">' +
        '            <table>' +
        '                <tbody>' +
        '                <tr data-id="' + id + '">' +
        '                    <td class="primary-container">' +
        '                        <div class="' + ((isPrimary) ? 'primary' : 'make-primary') + '" title="" rel="tooltip" data-placement="bottom" data-original-title="' + ((isPrimary) ? 'Primary telephone' : 'Make this the primary telephone') + '"></div>' +
        '                    </td>' +
        '                    <td class="telephone-container">' +
        '                        <div class="type">' +
        '                           ' + data.type +
        '                        </div>' +
        '                        <div class="number">' +
        '                            <a href="tel:' + data.number + ((data.extension) ? 'p' + data.extension : '') + '"><span class="number-value">' + data.number + '</span>' + ((data.extension) ? ' Extn <span class="extension-value">' + data.extension + '</span>' : '') + '</a>' +
        '                        </div>' +
        '                    </td>' +
        '                    <td class="telephone-action-buttons">' +
        '                        <div class="dropdown pull-right">' +
        '                            <img src="' + Onkho.Variables.BaseURL + '/img/ellipsis-dark.png' + '" data-toggle="dropdown">' +
        '                            <ul class="dropdown-menu">' +
        '                                <li><a href="javascript:void(0);" class="edit">Edit</a></li>' +
        '                                <li><a href="javascript:void(0);" class="delete">Delete</a></li>' +
        '                            </ul>' +
        '                        </div>' +
        '                    </td>' +
        '                </tr>' +
        '                </tbody>' +
        '            </table>' +
        '        </div>' +
        '    </div>' +
        '</div>'
      )

      telephones.append(entry)
    } else {
      entry.find('td.telephone-container div.type')
        .text(data.type)

      entry.find('td.telephone-container div.number')
        .html('<a href="tel:' + data.number + ((data.extension) ? 'p' + data.extension : '') + '"><span class="number-value">' + data.number + '</span>' + ((data.extension) ? ' Extn <span class="extension-value">' + data.extension + '</span>' : '') + '</a>')
    }

    telephones.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')

    if (isPrimary) {
      telephones.find('tr[data-id] td.primary-container div.primary').attr('data-original-title', 'Make this the primary telephone').removeClass('primary').addClass('make-primary')
      entry.find('.primary-container div').attr('data-original-title', 'Primary telephone').removeClass('make-primary').addClass('primary')
    }
  }

  var DeleteTelephoneEntry =
    function (entry) {

      entry.closest('[data-telephone]').remove()

      if (telephones.children().length > 0) {
        telephones.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
      } else {
        telephones.append($(
          '<div class="row margin-top-10" data-telephone-placeholder>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name">' +
          '            Telephones' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add telephone">' +
          '                + Telephone' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '    </div>' +
          '</div>'
        ))

        telephones.find('[rel="tooltip"]').tooltip()
      }
    }

  var UpdateEmailEntry =
    function (data, id, isPrimary) {
      var entry = emails.find('[data-email] tr[data-id="' + id + '"]')

      if (entry.length === 0) {
        emails.find('[data-email-placeholder]').remove()

        entry = $(
          '<div class="row margin-top-10" data-email>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name' + ((emails.children().length === 0) ? '' : ' soft-hide') + '">' +
          '            Emails' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add email">' +
          '                + Email' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '        <div class="profile-edit-section-content well well-light well-slim-padding well-square no-margin">' +
          '            <table>' +
          '                <tbody>' +
          '                <tr data-id="' + id + '">' +
          '                    <td class="primary-container">' +
          '                        <div class="' + ((isPrimary) ? 'primary' : 'make-primary') + '" title="" rel="tooltip" data-placement="bottom" data-original-title="' + ((isPrimary) ? 'Primary email' : 'Make this the primary email') + '"></div>' +
          '                    </td>' +
          '                    <td class="email-container">' +
          '                        <div class="type">' +
          '                           ' + data.type +
          '                        </div>' +
          '                        <div class="address">' +
          '                            <a href="mailto:' + data.address + '">' + data.address + '</a>' +
          '                        </div>' +
          '                    </td>' +
          '                    <td class="email-action-buttons">' +
          '                        <div class="dropdown pull-right">' +
          '                            <img src="' + Onkho.Variables.BaseURL + '/img/ellipsis-dark.png' + '" data-toggle="dropdown">' +
          '                            <ul class="dropdown-menu">' +
          '                                <li><a href="javascript:void(0);" class="edit">Edit</a></li>' +
          '                                <li><a href="javascript:void(0);" class="delete">Delete</a></li>' +
          '                            </ul>' +
          '                        </div>' +
          '                    </td>' +
          '                </tr>' +
          '                </tbody>' +
          '            </table>' +
          '        </div>' +
          '    </div>' +
          '</div>'
        )

        emails.append(entry)
      } else {
        entry.find('td.email-container div.type')
          .text(data.type)

        entry.find('td.email-container div.address')
          .html('<a href="mailto:' + data.address + '">' + data.address + '</a>')
      }

      emails.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')

      if (isPrimary) {
        emails.find('tr[data-id] td.primary-container div.primary').attr('data-original-title', 'Make this the primary email').removeClass('primary').addClass('make-primary')
        entry.find('.primary-container div').attr('data-original-title', 'Primary email').removeClass('make-primary').addClass('primary')
      }
    }

  var DeleteEmailEntry =
    function (entry) {

      entry.closest('[data-email]').remove()

      if (emails.children().length > 0) {
        emails.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
      } else {
        emails.append($(
          '<div class="row margin-top-10" data-email-placeholder>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name">' +
          '            Emails' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add email">' +
          '                + Email' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '    </div>' +
          '</div>'
        ))

        emails.find('[rel="tooltip"]').tooltip()
      }
    }

  var UpdateWebsiteEntry =
    function (data, id, isPrimary) {
      var entry = websites.find('[data-website] tr[data-id="' + id + '"]')

      if (entry.length === 0) {
        websites.find('[data-website-placeholder]').remove()

        entry = $(
          '<div class="row margin-top-10" data-website>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name' + ((websites.children().length === 0) ? '' : ' soft-hide') + '">' +
          '            Websites' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add website">' +
          '                + Website' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '        <div class="profile-edit-section-content well well-light well-slim-padding well-square no-margin">' +
          '            <table>' +
          '                <tbody>' +
          '                <tr data-id="' + id + '">' +
          '                    <td class="primary-container">' +
          '                        <div class="' + ((isPrimary) ? 'primary' : 'make-primary') + '" title="" rel="tooltip" data-placement="bottom" data-original-title="' + ((isPrimary) ? 'Primary website' : 'Make this the primary website') + '"></div>' +
          '                    </td>' +
          '                    <td class="website-container">' +
          '                        <div class="type">' +
          '                           ' + data.type +
          '                        </div>' +
          '                        <div class="url">' +
          '                            <a href="href:' + data.url + '" target="_blank">' + data.url + '</a>' +
          '                        </div>' +
          '                    </td>' +
          '                    <td class="website-action-buttons">' +
          '                        <div class="dropdown pull-right">' +
          '                            <img src="' + Onkho.Variables.BaseURL + '/img/ellipsis-dark.png' + '" data-toggle="dropdown">' +
          '                            <ul class="dropdown-menu">' +
          '                                <li><a href="javascript:void(0);" class="edit">Edit</a></li>' +
          '                                <li><a href="javascript:void(0);" class="delete">Delete</a></li>' +
          '                            </ul>' +
          '                        </div>' +
          '                    </td>' +
          '                </tr>' +
          '                </tbody>' +
          '            </table>' +
          '        </div>' +
          '    </div>' +
          '</div>'
        )

        websites.append(entry)
      } else {
        entry.find('td.website-container div.type')
          .text(data.type)

        entry.find('td.website-container div.url')
          .html('<a href="href:' + data.url + '" target="_blank">' + data.url + '</a>')
      }

      websites.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')

      if (isPrimary) {
        websites.find('tr[data-id] td.primary-container div.primary').attr('data-original-title', 'Make this the primary website').removeClass('primary').addClass('make-primary')
        entry.find('.primary-container div').attr('data-original-title', 'Primary website').removeClass('make-primary').addClass('primary')
      }
    }

  var DeleteWebsiteEntry =
    function (entry) {

      entry.closest('[data-website]').remove()

      if (websites.children().length > 0) {
        websites.children().find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
      } else {
        websites.append($(
          '<div class="row margin-top-10" data-website-placeholder>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name">' +
          '            Websites' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add website">' +
          '                + Website' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '    </div>' +
          '</div>'
        ))

        websites.find('[rel="tooltip"]').tooltip()
      }
    }

  var AddCustomAttribute =
    function (partyId, attributeName, attributeType, attributePosition, attributeCardinality, saveButton) {
      var newAttributeName = Onkho.TextTools.UcWords(Onkho.TextTools.StripTags(attributeName).trim())
      var newAttributeId = Onkho.TextTools.Hash(newAttributeName)

      if (Onkho.Validator.ValidateChildren(customAttributeAddModal.find('form'))) {
        var newAttribute = customAttributes.find('[data-custom-attribute] table tr[data-id="' + newAttributeId + '"]')

        // Check if the attribute already exists on the page
        if (newAttribute.length === 0) {

          // Double check with the server
          var formData = {}
          formData._token = Onkho.Functions.GetCSRF()
          formData.label = newAttributeName

          $.ajax({
            type: 'POST',
            url: Onkho.Variables.BaseURL + '/party/' + partyId + '/validateNewOnkhoAttribute',
            data: formData,
            dataType: 'json',
            complete: function (data) {
              Onkho.LoadingTools.HideLoading(saveButton)

              switch (data.status) {
                case 200:
                  if (data.responseJSON.status === 'OK') {
                    newAttribute = $(
                      '<div class="row margin-top-10" data-custom-attribute>' +
                      '    <div class="col-sm-2">' +
                      '        <div class="profile-edit-section-name' + ((addresses.children().length === 0) ? '' : ' soft-hide') + '">' +
                      '            Custom Properties' +
                      '            <br />' +
                      '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add property">' +
                      '                + Property' +
                      '            </a>' +
                      '        </div>' +
                      '    </div>' +
                      '    <div class="col-sm-10">' +
                      '        <div class="profile-edit-section-content edit-custom-attribute-content well well-light well-slim-padding well-square smart-form no-margin">' +
                      '            <table>' +
                      '                <tbody>' +
                      '                    <tr data-id="' + newAttributeId + '">' +
                      '                        <td class="attribute-container">' +
                      '                        </td>' +
                      '                        <td class="attribute-action-buttons">' +
                      '                            <div class="dropdown pull-right">' +
                      '                                <img src="' + Onkho.Variables.BaseURL + '/img/ellipsis-dark.png' + '" data-toggle="dropdown">' +
                      '                                <ul class="dropdown-menu">' +
                      '                                    <li><a href="javascript:void(0);" class="edit">Edit</a></li>' +
                      '                                    <li><a href="javascript:void(0);" class="delete">Delete</a></li>' +
                      '                                </ul>' +
                      '                            </div>' +
                      '                        </td>' +
                      '                    </tr>' +
                      '                </tbody>' +
                      '            </table>' +
                      '        </div>' +
                      '    </div>' +
                      '</div>'
                    )

                    switch (attributeType) {
                      case 'checkbox' : {
                        newAttribute.find('div.edit-custom-attribute-content table td.attribute-container').append(
                          '<label class="checkbox">' +
                          '    <input type="checkbox" name="' + newAttributeId + '" value="1" data-validation=""> <i></i> <span class="label-content">' + newAttributeName + '</span>' +
                          '</label>' +
                          '<em for="' + newAttributeId + '" class="invalid"></em>' +
                          '<em for="' + newAttributeId + '" class="explanation"></em>' +
                          '<input type="hidden" name="' + newAttributeId + '_type" value="CHECKBOX">' +
                          '<input type="hidden" name="' + newAttributeId + '_action" data-type="action" value="ADD">' +
                          '<input type="hidden" name="' + newAttributeId + '_cardinality" data-type="cardinality" value="' + attributeCardinality + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_label" data-type="label" value="' + attributeName + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_position" data-type="position" value="' + attributePosition + '">'
                        )
                        break
                      }

                      case 'textbox' : {
                        newAttribute.find('div.edit-custom-attribute-content table td.attribute-container').append(
                          '<label class="label label-content">' + newAttributeName + '</label>' +
                          '<label class="input">' +
                          '    <input type="text" name="' + newAttributeId + '" value="" data-validation="">' +
                          '</label>' +
                          '<em for="' + newAttributeId + '" class="invalid"></em>' +
                          '<em for="' + newAttributeId + '" class="explanation"></em>' +
                          '<input type="hidden" name="' + newAttributeId + '_type" value="TEXTBOX">' +
                          '<input type="hidden" name="' + newAttributeId + '_action" data-type="action" value="ADD">' +
                          '<input type="hidden" name="' + newAttributeId + '_cardinality" data-type="cardinality" value="' + attributeCardinality + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_label" data-type="label" value="' + attributeName + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_position" data-type="position" value="' + attributePosition + '">'
                        )
                        break
                      }

                      case 'date' : {
                        newAttribute.find('div.edit-custom-attribute-content table td.attribute-container').append(
                          '<label class="label label-content">' + newAttributeName + '</label>' +
                          '<label class="input">' +
                          '    <label class="input"> <i class="icon-append fa fa-calendar"></i> <input type="text" data-type="date" name="' + newAttributeId + '" value="" data-validation="" autocomplete="off"> </label>' +
                          '</label>' +
                          '<em for="' + newAttributeId + '" class="invalid"></em>' +
                          '<em for="' + newAttributeId + '" class="explanation"></em>' +
                          '<input type="hidden" name="' + newAttributeId + '_type" value="DATE">' +
                          '<input type="hidden" name="' + newAttributeId + '_action" data-type="action" value="ADD">' +
                          '<input type="hidden" name="' + newAttributeId + '_cardinality" data-type="cardinality" value="' + attributeCardinality + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_label" data-type="label" value="' + attributeName + '">' +
                          '<input type="hidden" name="' + newAttributeId + '_position" data-type="position" value="' + attributePosition + '">'
                        )
                        break
                      }
                    }

                    customAttributes.find('[data-custom-attribute-placeholder]').remove()

                    customAttributes.append(newAttribute)

                    customAttributes.children().filter(':visible').find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')

                    customAttributes.find('[rel="tooltip"]').tooltip()

                    Onkho.FormEnhancements.EnhanceDates()

                    // Hide modal and reset form
                    customAttributeAddModal.modal('hide')
                    customAttributeAddModal.find('form')[0].reset()
                  } else {
                    Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                  }
                  break

                default:
                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                  break
              }
            }
          })
        } else {
          Onkho.LoadingTools.HideLoading(saveButton)
          Onkho.Alert.BigBox('danger', 'Error', 'The attribute you are trying to add already exists.')
        }
      } else {
        Onkho.LoadingTools.HideLoading(saveButton)
      }
    }

  var UpdateCustomAttribute =
    function (partyId, attributeId, attributeName, attributePosition, attributeCardinality, saveButton) {

      var newAttributeName = Onkho.TextTools.UcWords(Onkho.TextTools.StripTags(attributeName).trim())
      var newAttributeId = Onkho.TextTools.Hash(newAttributeName)
      var newAttribute = customAttributes.find('[data-custom-attribute] table tr[data-id="' + newAttributeId + '"]')

      if ((attributeId !== newAttributeId && newAttribute.length === 0) || attributeId === newAttributeId) {

        var formData = {}
        formData._token = Onkho.Functions.GetCSRF()
        formData.label = newAttributeName

        $.ajax({
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/party/' + partyId + '/validateNewOnkhoAttribute',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(saveButton)

            switch (data.status) {
              case 200:
                if (data.responseJSON.status === 'OK' || (attributeId === newAttributeId && data.responseJSON.count === 1)) {
                  var existingAttribute = customAttributes.find('[data-custom-attribute] table tr[data-id="' + attributeId + '"]')

                  existingAttribute.find('.label-content')
                    .html(newAttributeName)
                  existingAttribute.find('input[name="' + attributeId + '_label"]')
                    .val(newAttributeName)
                  existingAttribute.find('input[data-type="position"]')
                    .val(attributePosition)
                  existingAttribute.find('input[data-type="cardinality"]')
                    .val(attributeCardinality)

                  // Only change the action to UPDATE if this isn't a freshly added attribute
                  if (existingAttribute.find('input[data-type="action"]').val() !== 'ADD') {
                    existingAttribute.find('input[data-type="action"]')
                      .val('UPDATE')
                  } else {
                    // This is an UPDATE on a freshly added attribute - update the IDs in place
                    existingAttribute.find('input[name="' + attributeId + '"]')
                      .attr('name', newAttributeId)
                    existingAttribute.find('input[name="' + attributeId + '_action"]')
                      .attr('name', newAttributeId + '_action')
                    existingAttribute.find('input[name="' + attributeId + '_label"]')
                      .attr('name', newAttributeId + '_label')
                    existingAttribute.find('input[name="' + attributeId + '_type"]')
                      .attr('name', newAttributeId + '_type')
                    existingAttribute.find('input[name="' + attributeId + '_cardinality"]')
                      .attr('name', newAttributeId + '_cardinality')
                    existingAttribute.find('input[name="' + attributeId + '_position"]')
                      .attr('name', newAttributeId + '_position')
                    existingAttribute.find('em[for="' + attributeId + '"]')
                      .attr('for', newAttributeId)
                  }

                  // Hide modal and reset form
                  customAttributeEditModal.modal('hide')
                  customAttributeEditModal.find('form')[0].reset()

                  Onkho.FormEnhancements.EnhanceDates()
                } else {
                  Onkho.Alert.BigBox('danger', 'Error', 'Changing the name of this attribute will result in a duplicate. Please choose a different name.')
                }
                break

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
      } else {
        Onkho.LoadingTools.HideLoading(saveButton)
        Onkho.Alert.BigBox('danger', 'Error', 'Changing the name of this attribute will result in a duplicate. Please choose a different name.')
      }
    }

  var DeleteCustomAttribute =
    function (partyId, attributeId, deleteButton) {

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.attribute_id = attributeId

      $.ajax({
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/party/' + partyId + '/getAppearancesCount',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(deleteButton)

          switch (data.status) {
            case 200:
              if (data.responseJSON.status === 'OK') {
                var appearances = data.responseJSON.count
                var isInTemplate = data.responseJSON.isInTemplate

                var partyType = (appearances === 1) ? Onkho.Variables.PartyTypeSingular : Onkho.Variables.PartyTypePlural
                var buttonStringNone = 'No'
                var buttonStringThis = 'Yes, only from this contact'
                var buttonStringAllType = 'Yes, from all ' + appearances + ' existing ' + partyType
                var buttonStringAllTypeAndTemplate = 'Yes, from ' + (appearances === 1 ? ('the') : ('all')) + ' ' + appearances + ' existing ' + partyType + ' as well as future ones'
                var buttonStringAll = 'Yes, from all contacts'

                var buttonsString = '[' + buttonStringNone + ']' + '[' + buttonStringThis + ']'

                if (isInTemplate) {
                  buttonsString += '[' + buttonStringAllTypeAndTemplate + ']'
                } else {
                  if (appearances > 1) {
                    buttonsString += '[' + buttonStringAllType + ']'
                  }
                }

                buttonsString +=  '[' + buttonStringAll + ']'

                $.SmartMessageBox(
                  {
                    title: 'Deleting property',
                    content: 'Are you sure you want to delete this property?',
                    buttons: buttonsString
                  }, function (ButtonPress) {
                    var attributeToDelete = customAttributes.find('[data-custom-attribute] table tr[data-id="' + attributeId + '"]')

                    var action = attributeToDelete.find('input[data-type="action"]')
                    var cardinality = attributeToDelete.find('input[data-type="cardinality"]')

                    if (ButtonPress === buttonStringThis) {
                      action.data('prev-value', action.val())
                      action.val('DELETE')
                      cardinality.data('prev-value', cardinality.val())
                      cardinality.val('this')
                      DeleteCustomAttributeEntryEntry(attributeToDelete)
                    } else if (ButtonPress === buttonStringAllType || ButtonPress === buttonStringAllTypeAndTemplate) {
                      action.data('prev-value', action.val())
                      action.val('DELETE')
                      cardinality.data('prev-value', cardinality.val())
                      cardinality.val('all-type')
                      DeleteCustomAttributeEntryEntry(attributeToDelete)
                    } else if (ButtonPress === buttonStringAll) {
                      action.data('prev-value', action.val())
                      action.val('DELETE')
                      cardinality.data('prev-value', cardinality.val())
                      cardinality.val('all')
                      DeleteCustomAttributeEntryEntry(attributeToDelete)
                    }
                  })
              } else {
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              }
              break

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
    }

  var DeleteCustomAttributeEntryEntry =
    function (entry) {

      entry.closest('[data-custom-attribute]').hide()

      if (customAttributes.children().filter(':visible').length > 0) {
        customAttributes.children().filter(':visible').find('.profile-edit-section-name').addClass('soft-hide').first().removeClass('soft-hide')
      } else {
        customAttributes.append($(
          '<div class="row margin-top-10" data-custom-attribute-placeholder>' +
          '    <div class="col-sm-2">' +
          '        <div class="profile-edit-section-name">' +
          '            Custom properties' +
          '            <br />' +
          '            <a href="javascript:void(0);" class="add padding-5" rel="tooltip" title="" data-placement="bottom" data-original-title="Add property">' +
          '                + Property' +
          '            </a>' +
          '        </div>' +
          '    </div>' +
          '    <div class="col-sm-10">' +
          '    </div>' +
          '</div>'
        ))

        customAttributes.find('[rel="tooltip"]').tooltip()
      }
    }

  return {
    Init: Init
  }
}()