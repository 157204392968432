// Module containing tools to be used when displaying an activity feed
Onkho.ActivityFeed = function () {
  var Init = function () {
    RegisterEventListeners()
    InitializeItems($('.pinned-activity-feed-items'))
    InitializeItems($('.pinned-activity-feed-items-in-group'))
  }

  var LoadGroup = function (loader) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = loader.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getGroup',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              var group = $(data.responseJSON.html)
              loader.replaceWith(group)
              InitializeGroup(group)
              InitializeItems(group)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var InitializeGroup = function (activityFeedGroupElement) {
    activityFeedGroupElement.find('> .toolbar select[name="assigned_to_party_id"]').select2({
      placeholder: 'Chose a team member'
    })

    // Stop assignee dropdown from hiding when the team members select is clicked
    activityFeedGroupElement.find('> .toolbar li[data-id="assignee_picker"]').on('click', function (event) {
      event.stopPropagation()
    })

    // Prevent ENTER from refreshing the page in the various modals
    var modals = activityFeedGroupElement.find('> .toolbar .modal')
    modals.find('input').keypress(function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        $(this).closest('.modal').find('button.save').click();
        event.preventDefault();
      }
    });

    // Initialize the organizer inside the organise group modal
    var organiseGroupModal = activityFeedGroupElement.find('> .toolbar .modal.organise-activity-feed-group-modal')
    InitializeOrganiser(organiseGroupModal, activityFeedGroupElement.data('id'))

    // Initialize the snoozer inside the snooze group modal
    var snoozeGroupModal = activityFeedGroupElement.find('> .toolbar .modal.snooze-activity-feed-group-modal')
    InitializeSnoozer(snoozeGroupModal)

    // Initialize the organizer inside the organise item modal
    var organiseItemModal = activityFeedGroupElement.find('> .toolbar .modal.organise-activity-feed-item-modal')
    InitializeOrganiser(organiseItemModal, activityFeedGroupElement.data('id'))

    // Initialize tooltips
    activityFeedGroupElement.find('.read-status[rel="tooltip"]').tooltip({
      html: true
    });

    activityFeedGroupElement.find('.info-box[rel="tooltip"]').tooltip({
      html: true
    });

    activityFeedGroupElement.find('.activity-feed-item > .body > .header > .metadata [rel="tooltip"]').tooltip({
      html: true
    });

    activityFeedGroupElement.find('.attachment [rel="tooltip"]').tooltip({
      html: true,
      container: $('body')
    });

    activityFeedGroupElement.find('> .header [rel="tooltip"]').tooltip();
    activityFeedGroupElement.find('> .toolbar [rel="tooltip"]').tooltip();

    // Scroll to item, if requested
    var pane = activityFeedGroupElement.closest('.o-pane[data-pane-key="activity_feed_group"]');
    if (pane.length > 0) {
      if (pane.data('scroll-to-item-id')) {
        Onkho.ActivityFeedGroupPane.ScrollToItemId(pane, pane.data('scroll-to-item-id'))
      }
    }
  }

  var InitializeItems = function (container) {
    container.find('.activity-feed-item').each(function (index, itemElement) {
      itemElement = $(itemElement)
      if (itemElement.find('> .body > .content').length) {
        var smallContentContainer = itemElement.find('> .body > .content > .small-content')
        var fullContentContainer = itemElement.find('> .body > .content > .full-content')
        var showMoreButton = itemElement.find('> .body > .content > .show-more')
        var showLessButton = itemElement.find('> .body > .content > .show-less')

        if (smallContentContainer[0].scrollHeight > smallContentContainer.innerHeight()) {
          // Small content is overflowing
          smallContentContainer.addClass('has-large-content')
          showMoreButton.removeClass('hidden')
          showLessButton.removeClass('hidden')
        } else if (smallContentContainer.text() !== fullContentContainer.text()) {
          // Full content has different content
          showMoreButton.removeClass('hidden')
          showLessButton.removeClass('hidden')
        }
      }
    })
  }

  var RefreshGroup = function (activityFeedGroupElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getGroupLoader',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              activityFeedGroupElement.find('input, select').select2('destroy');
              var loader = $(data.responseJSON.html)
              activityFeedGroupElement.replaceWith(loader)
              LoadGroup(loader)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var RegisterEventListeners = function () {
    // Activity feed item header
    $('body').on('click', '.activity-feed-item .header .pin', function () {
      PinItem($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item .header .unpin', function () {
      UnpinItem($(this).closest('.activity-feed-item'))
    })

    // Activity feed item actions
    $('body').on('click', '.activity-feed-item .actions [data-id="delete"]', function () {
      ConfirmDeleteItem($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item[data-type="NOTE"] .actions [data-id="edit"]', function () {
      GetCreateFormInItemContext($(this).closest('.activity-feed-item'), 'NOTE', 'edit')
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .actions [data-id="reply"], .activity-feed-item[data-type="INCOMING_EMAIL"] .actions [data-id="reply"]', function () {
      GetCreateFormInItemContext($(this).closest('.activity-feed-item'), 'OUTGOING_EMAIL', 'reply')
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .actions [data-id="send_now"], .activity-feed-item[data-type="OUTGOING_SMS"] .actions [data-id="send_now"]', function () {
      SendNow($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .actions [data-id="reply_all"], .activity-feed-item[data-type="INCOMING_EMAIL"] .actions [data-id="reply_all"]', function () {
      GetCreateFormInItemContext($(this).closest('.activity-feed-item'), 'OUTGOING_EMAIL', 'reply_all')
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .actions [data-id="forward"], .activity-feed-item[data-type="INCOMING_EMAIL"] .actions [data-id="forward"]', function () {
      GetCreateFormInItemContext($(this).closest('.activity-feed-item'), 'OUTGOING_EMAIL', 'forward')
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_SMS"] .actions [data-id="reply"], .activity-feed-item[data-type="INCOMING_SMS"] .actions [data-id="reply"]', function () {
      GetCreateFormInItemContext($(this).closest('.activity-feed-item'), 'OUTGOING_SMS', 'reply')
    })

    $('body').on('click', '.activity-feed-item .actions [data-id="make_request"]', function () {
      ConfirmMakeRequest($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item .actions [data-id="resend"]', function () {
      ConfirmResendItem($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item .actions [data-id="handle"]', function () {
      HandleItem($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item .actions [data-id="unhandle"]', function () {
      UnhandleItem($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-item.showing-less .show-more', function () {
      $(this).closest('.activity-feed-item').removeClass('showing-less').addClass('showing-more')
    })

    $('body').on('click', '.activity-feed-item.showing-more .show-less', function () {
      $(this).closest('.activity-feed-item').removeClass('showing-more').addClass('showing-less')
    })

    $('body').on('click', '.activity-feed-item.showing-related-model-less .show-related-model-more', function () {
      $(this).closest('.activity-feed-item').removeClass('showing-related-model-less').addClass('showing-related-model-more')
    })

    $('body').on('click', '.activity-feed-item.showing-related-model-more .show-related-model-less', function () {
      $(this).closest('.activity-feed-item').removeClass('showing-related-model-more').addClass('showing-related-model-less')
    })

    $('body').on('click', '.activity-feed-item > .body > .header > .metadata .read-status', function () {
      LoadReadStatusReport($(this).closest('.activity-feed-item'));
    })

    $('body').on('click', '.activity-feed-item .actions [data-id="organise"]', function () {
      ShowOrganiseItemModal($(this).closest('.activity-feed-item'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .modal.organise-activity-feed-item-modal button.save', function () {
      OrganiseItem($(this).closest('.activity-feed-group'))
    })

    // Activity feed item attachments
    $('body').on('click', '.activity-feed-item[data-type="INCOMING_EMAIL"] .attachments .buttons-bar .btn[data-id="download"]', function () {
      PrepareIncomingMessageAttachment($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    $('body').on('click', '.activity-feed-item[data-type="INCOMING_EMAIL"] .attachments .buttons-bar .btn[data-id="document_portal"]', function () {
      SaveIncomingMessageAttachmentToDocumentPortal($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .attachments .buttons-bar .btn[data-id="download"]', function () {
      PrepareOutgoingMessageAttachment($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    $('body').on('click', '.activity-feed-item[data-type="OUTGOING_EMAIL"] .attachments .buttons-bar .btn[data-id="document_portal"]', function () {
      SaveOutgoingMessageAttachmentToDocumentPortal($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    $('body').on('click', '.activity-feed-item:not([data-type="INCOMING_EMAIL"]):not([data-type="OUTGOING_EMAIL"]) .attachments .buttons-bar .btn[data-id="download"]', function () {
      PrepareActivityFeedItemAttachment($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    $('body').on('click', '.activity-feed-item:not([data-type="INCOMING_EMAIL"]):not([data-type="OUTGOING_EMAIL"]) .attachments .buttons-bar .btn[data-id="document_portal"]', function () {
      SaveActivityFeedItemAttachmentToDocumentPortal($(this).closest('.attachment'), $(this).closest('.attachment').data('id'))
    })

    // Activity feed group actions
    $('body').on('click', '.activity-feed-group > .toolbar [data-id="note"]', function () {
      GetCreateFormInGroupContext($(this).closest('.activity-feed-group'), 'NOTE')
    })

    $('body').on('click', '.activity-feed-group > .toolbar [data-id="outgoing_email"]', function () {
      GetCreateFormInGroupContext($(this).closest('.activity-feed-group'), 'OUTGOING_EMAIL')
    })

    $('body').on('click', '.activity-feed-group > .toolbar [data-id="outgoing_sms"]', function () {
      GetCreateFormInGroupContext($(this).closest('.activity-feed-group'), 'OUTGOING_SMS')
    })

    $('body').on('click', '.activity-feed-group > .toolbar [data-id="request"]', function () {
      GetCreateFormInGroupContext($(this).closest('.activity-feed-group'), 'REQUEST')
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="refresh"]', function () {
      Onkho.ActivityFeed.RefreshGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="archive"]', function () {
      ConfirmArchiveGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="snooze"]', function () {
      ShowSnoozeGroupModal($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .modal.snooze-activity-feed-group-modal button.save', function () {
      SnoozeGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="move_to_inbox"]', function () {
      ConfirmMoveToInboxGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="organise"]', function () {
      ShowOrganiseGroupModal($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .modal.organise-activity-feed-group-modal button.save', function () {
      OrganiseGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .featured-action[data-id="delete"]', function () {
      ConfirmDeleteGroup($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .status-control .dropdown li a', function () {
      SetGroupStatus($(this).closest('.activity-feed-group'), $(this))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .assignee-control .dropdown li a', function () {
      SetGroupAssignee($(this).closest('.activity-feed-group'), $(this).val(), $(this).text())
    })

    $('body').on('change', '.activity-feed-group > .toolbar .assignee-control .dropdown li[data-id="assignee_picker"] select', function () {
      var selectedOption = $(this).find('option:selected')
      SetGroupAssignee($(this).closest('.activity-feed-group'), selectedOption.val(), selectedOption.text())
    })

    $('body').on('click', '.activity-feed-group > .toolbar [data-id="change_subject"]', function () {
      ShowSetGroupSubjectModal($(this).closest('.activity-feed-group'))
    })

    $('body').on('click', '.activity-feed-group > .toolbar .modal.update-activity-feed-group-subject-modal button.save', function () {
      SetGroupSubject($(this).closest('.activity-feed-group'))
    })

    // Activity feed item builder actions
    $('body').on('click', '.activity-feed-item-builder button[name="cancel"]', function () {
      ConfirmCancelItemBuilder($(this).closest('.activity-feed-item-builder'))
    })

    $('body').on('click', '.activity-feed-item-builder button[name="save"]', function () {
      SaveItemBuilder($(this).closest('.activity-feed-item-builder'))
    })

    $('body').on('click', '.activity-feed-item-builder .buttons-bar .schedule-send', function () {
      OpenScheduleSendModal($(this).closest('.activity-feed-item-builder'))
    })

    $('body').on('click', '.activity-feed-item-builder .schedule-send-modal button.save', function () {
      SaveItemBuilder($(this).closest('.activity-feed-item-builder'))
    })

    $('body').on('click', '.activity-feed-item-builder .btn[data-id="local-upload"]', function () {
      OpenLocalUploadModal($(this).closest('.activity-feed-item-builder'))
    })

    $('body').on('click', '.activity-feed-item-builder .btn[data-id="document-portal-upload"]', function () {
      OpenDocumentPortalUploadModal($(this).closest('.activity-feed-item-builder'))
    })

    // Activity feed item composers actions
    $('body').on('click', '.activity-feed-item-builder-pane-trigger', function () {
      LoadActivityFeedItemBuilderPane($(this).data('type'))
    })

    $('body').on('click', '.activity-feed-item-builder-panel-trigger', function () {
      LoadActivityFeedItemBuilderPanel($(this).data('type'), $(this).data('contact-party-id'))
    })

    // Pinned activity feed item actions
    $('body').on('click', '.pinned-activity-feed-items > .activity-feed-item > .body > .header > .subject', function () {
      var item = $(this).closest('.activity-feed-item')
      Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane(item.data('group-id'), item.data('id'));
    })

    $('body').on('click', '.pinned-activity-feed-items > .show-more', function () {
      ShowMorePinnedItems($(this).closest('.pinned-activity-feed-items'))
    })

    $('body').on('click', '.pinned-activity-feed-items > .show-less', function () {
      ShowLessPinnedItems($(this).closest('.pinned-activity-feed-items'))
    })

    $('body').on('click', '.pinned-activity-feed-items-in-group > .show-more', function () {
      ShowMorePinnedItemsInGroup($(this).closest('.pinned-activity-feed-items-in-group'))
    })

    $('body').on('click', '.pinned-activity-feed-items-in-group > .show-less', function () {
      ShowLessPinnedItemsInGroup($(this).closest('.pinned-activity-feed-items-in-group'))
    })

    // Feed actions
    $('body').on('click', '.o-feed .activity-feed-item > .body > .header > .subject', function () {
      var item = $(this).closest('.activity-feed-item')
      Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane(item.data('group-id'), item.data('id'));
    })

    // Focus first field when showing a modal
    $('body').on('shown.bs.modal', '.activity-feed-group > .toolbar .modal', function () {
      $(this).find('input:visible:enabled:first').focus();
    })
  }

  var SendNow = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/sendNowItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              RefreshGroup(activityFeedItemElement.closest('.activity-feed-group'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var GetCreateFormInItemContext = function (activityFeedItemElement, type, action) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')
    formData.activity_feed_item_type = type

    if (typeof action !== 'undefined') {
      formData.action = action
    }

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getCreateFormInItemContext',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              SetItemBuilderOfItem(activityFeedItemElement, data.responseJSON.html)

              var missingMailboxElement = $(data.responseJSON.html).find('.missing-mailbox')
              if (missingMailboxElement.length === 0) {
                InitializeItemBuilder(
                  activityFeedItemElement.find('> .activity-feed-item-builder'),
                  type,
                  data.responseJSON.recipients,
                  data.responseJSON.subject,
                  data.responseJSON.content,
                  data.responseJSON.attachments
                )

                // When forwarding, focus the To field
                if (action === 'forward') {
                  ShowItemBuilderOfItem(activityFeedItemElement, activityFeedItemElement.find('[data-id="recipients-picker"] [data-id="to"] input.select2-input'))
                } else {
                  ShowItemBuilderOfItem(activityFeedItemElement)
                }

                // When editing, hide the original content
                if (action === 'edit') {
                  activityFeedItemElement.find('> .body > .content').hide()
                }
              }
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SetItemBuilderOfItem = function (activityFeedItemElement, itemBuilderHtml) {
    var builder = activityFeedItemElement.find('> .activity-feed-item-builder')
    builder.replaceWith(itemBuilderHtml)
  }

  var ShowItemBuilderOfItem = function (activityFeedItemElement, focusElement) {
    var builder = activityFeedItemElement.find('> .activity-feed-item-builder')
    builder.removeClass('hidden').addClass('showing')

    if (typeof focusElement === 'undefined') {
      activityFeedItemElement.find('> .activity-feed-item-builder [data-id="content"] .note-editable').trigger('focus');
    } else {
      focusElement.trigger('focus');
    }
  }

  var HideItemBuilderOfItem = function (activityFeedItemElement) {
    var builder = activityFeedItemElement.find('> .activity-feed-item-builder')
    builder.removeClass('showing').addClass('hidden')
  }

  var GetCreateFormInContactContext = function (panel, type) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.contact_party_id = panel.data('contact-party-id')
    formData.activity_feed_item_type = type

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getCreateFormInContactContext',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              panel.find('.activity-feed-item-builder-loader').replaceWith(data.responseJSON.html)

              var missingMailboxElement = $(data.responseJSON.html).find('.missing-mailbox')
              if (missingMailboxElement.length === 0) {
                InitializeItemBuilder(
                  panel.find('.activity-feed-item-builder'),
                  type,
                  data.responseJSON.recipients,
                  null,
                  null,
                  null,
                  formData.contact_party_id
                )

                panel.find('.activity-feed-item-builder [data-id="content"] .note-editable').trigger('focus');
              }
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var GetCreateFormInGroupContext = function (activityFeedGroupElement, type) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')
    formData.activity_feed_item_type = type

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getCreateFormInGroupContext',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              SetItemBuilderOfGroup(activityFeedGroupElement, data.responseJSON.html)

              var missingMailboxElement = $(data.responseJSON.html).find('.missing-mailbox')
              if (missingMailboxElement.length === 0) {
                InitializeItemBuilder(
                  activityFeedGroupElement.find('> .activity-feed-item-builder'),
                  type,
                  data.responseJSON.recipients,
                  data.responseJSON.subject
                )
                ShowItemBuilderOfGroup(activityFeedGroupElement)
              }
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SetItemBuilderOfGroup = function (activityFeedGroupElement, itemBuilderHtml) {
    var builder = activityFeedGroupElement.find('> .activity-feed-item-builder')
    builder.replaceWith(itemBuilderHtml)
  }

  var ShowItemBuilderOfGroup = function (activityFeedGroupElement) {
    var builder = activityFeedGroupElement.find('> .activity-feed-item-builder')
    builder.removeClass('hidden').addClass('showing')

    activityFeedGroupElement.find('> .activity-feed-item-builder [data-id="content"] .note-editable').trigger('focus');
  }

  var HideItemBuilderOfGroup = function (activityFeedGroupElement) {
    var builder = activityFeedGroupElement.find('> .activity-feed-item-builder')
    builder.removeClass('showing').addClass('hidden')
  }

  var InitializeItemBuilder = function (itemBuilderElement, type, recipients, subject, content, attachments, contactPartyId) {
    // Recipients
    if (itemBuilderElement.find('[data-id="recipients-picker"]').length > 0) {
      InitializeRecipientsPicker(itemBuilderElement, type, recipients)
    }

    // Group
    if (itemBuilderElement.find('[data-id="organise"]').length > 0) {
      InitializeOrganiser(itemBuilderElement, null, contactPartyId)
    }

    // Attachments
    if (itemBuilderElement.find('[data-id="attach"]').length > 0) {
      InitializeLocalAttachments(itemBuilderElement)

      if (itemBuilderElement.find('[data-id="attach"] section[data-id="document-portal-attachments"]').length > 0) {
        InitializeDocumentPortalAttachments(itemBuilderElement)
      }

      // Prepopulate existing attachments
      PopulateExistingAttachments(itemBuilderElement, attachments)

      InitializeAttachmentsToggle(itemBuilderElement)
    }

    // Schedule send
    if (itemBuilderElement.find('.schedule-send-modal').length > 0) {
      InitializeSendScheduler(itemBuilderElement)
    }

    // Character count
    var onKeyupCallback = undefined
    if (itemBuilderElement.find('[data-id="character-count"]').length > 0) {
      onKeyupCallback = InitializeCharacterCount(itemBuilderElement)
      itemBuilderElement.find('[data-id="character-count"] [rel="tooltip"]').tooltip({
        html: true
      });
    }

    // Maximize content vertically if inside a builder pane
    var pane = itemBuilderElement.closest('.o-pane[data-pane-key="activity_feed_item_builder"]')
    var height = undefined
    if (pane.length > 0) {
      height = Math.max(200, pane.height() - 527)
    }

    // Decide on toolbar based on item type
    var plainTextToolbar = false
    if (type === 'OUTGOING_SMS') {
      plainTextToolbar = true
    }

    // Initialize content
    Onkho.Summernote.EnableSummernote(itemBuilderElement.find('.summernote'), undefined, undefined, height, onKeyupCallback, plainTextToolbar)

    // Prepopulate subject
    itemBuilderElement.find('[data-id="attributes"] section[data-id="subject"] [name="subject"]').val(subject);

    // Prepopulate content
    Onkho.Summernote.UpdateSummernote(itemBuilderElement.find('.summernote'), content);
  }

  var InitializeSendScheduler = function (itemBuilderElement) {
    var customScheduledSendSection = itemBuilderElement.find('section[data-id="custom-scheduled-send"]')
    var input = itemBuilderElement.find('[name="scheduled_at"]')
    var runSelectionSideEffects = function (dp, field) {
      var datetime = moment(field.val(), 'YYYY-MM-DD HH:mm')
      itemBuilderElement.find('.scheduled-at').html(datetime.format('DD/MM/YYYY HH:mm'))
      customScheduledSendSection.addClass('active')
      itemBuilderElement.find('.quick-scheduled-send.active').removeClass('active')
    }

    input.datetimepicker({
      format: 'Y-m-d H:i',
      minDate: '0',
      allowTimes: ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      inline: true,
      onChangeDateTime: runSelectionSideEffects
    })

    itemBuilderElement.find('.quick-scheduled-send').on('click', function () {
      itemBuilderElement.find('.quick-scheduled-send.active').removeClass('active')
      customScheduledSendSection.removeClass('active')
      $(this).addClass('active')
      input.datetimepicker('reset')

      input.val($(this).data('value'))
      itemBuilderElement.find('.scheduled-at').html($(this).data('value'))
    })
  }

  var InitializeCharacterCount = function (itemBuilderElement) {
    var editor = itemBuilderElement.find('.summernote')
    var characterCount = itemBuilderElement.find('[data-id="character-count"]')
    var characters = characterCount.find('.characters')
    var smses = characterCount.find('.smses')

    return function (event) {
      var value = Onkho.Summernote.GetSummernoteValue(editor)
      var length = value.length
      var texts = Math.ceil(length / 160)
      characters.html(length + ' / 160')
      smses.html('(' + texts + (texts == 1 ? ' text' : ' texts') + ' estimated)')

      var maxLength = 160 * 5
      if (value.length > maxLength) {
        Onkho.Summernote.UpdateSummernote(editor, value.substr(0, maxLength))
        Onkho.Alert.SmallBox('warning', 'The length of an SMS is restricted to ' + maxLength + ' characters.')
      }
    }
  }

  var InitializeAttachmentsToggle = function (itemBuilderElement) {
    var attachmentsToggleContainer = itemBuilderElement.find('[data-id="attach-toggle"]')
    var attachmentsContainer = itemBuilderElement.find('[data-id="attach"]')

    // Attach
    attachmentsToggleContainer.on('click', '.attach', function () {
      $(this).addClass('hidden')
      attachmentsToggleContainer.find('.unattach').removeClass('hidden')
      attachmentsContainer.slideDown(300)
    })

    // Unattach
    attachmentsToggleContainer.on('click', '.unattach', function () {
      $(this).addClass('hidden')
      attachmentsToggleContainer.find('.attach').removeClass('hidden')
      attachmentsContainer.slideUp(300)
    })

    ShowAppropriateAttachButton(itemBuilderElement)
  }

  var ShowAppropriateAttachButton = function (itemBuilderElement) {
    var attachmentsToggleContainer = itemBuilderElement.find('[data-id="attach-toggle"]')
    var attachmentsContainer = itemBuilderElement.find('[data-id="attach"]')

    if (!attachmentsContainer.is(':visible')) {
      attachmentsToggleContainer.find('.unattach').addClass('hidden')
      attachmentsToggleContainer.find('.attach').removeClass('hidden')
    } else {
      var attachmentsCount = attachmentsContainer.find('.existing-attachment').length +
        attachmentsContainer.find('.local-attachment').length +
        attachmentsContainer.find('.document-portal-attachment').length;

      if (attachmentsCount > 0) {
        attachmentsToggleContainer.find('.attach').addClass('hidden')
        attachmentsToggleContainer.find('.unattach').addClass('hidden')
      } else {
        attachmentsToggleContainer.find('.attach').addClass('hidden')
        attachmentsToggleContainer.find('.unattach').removeClass('hidden')
      }
    }
  }

  var PopulateExistingAttachments = function (itemBuilderElement, attachments) {
    if (attachments) {
      var existingAttachmentsElement = itemBuilderElement.find('[data-id="attach"] section[data-id="existing-attachments"]')
      if (existingAttachmentsElement.length > 0) {
        $(attachments).each(function (index, attachment) {
          existingAttachmentsElement.append('' +
            '<div class="existing-attachment">' +
            '<div class="filename">' + attachment + '</div>' +
            '</div>')
        })
      }
    }
  }

  var InitializeDocumentPortalAttachments = function (itemBuilderElement) {
    Onkho.DocumentsPicker.Init(itemBuilderElement.find('.modal.document-portal-attachment-modal .o-documents-picker'), function (documentsPicker) {
      RedrawDocumentPortalAttachments(itemBuilderElement, documentsPicker)
    })

    itemBuilderElement.find('section[data-id="document-portal-attachments"]').on('click', '.document-portal-attachment .delete', function () {
      Onkho.DocumentsPicker.Deselect(itemBuilderElement.find('.modal.document-portal-attachment-modal .o-documents-picker'), $(this).closest('.document-portal-attachment').data('id'))
    })
  }

  var InitializeLocalAttachments = function (itemBuilderElement) {
    // Due to dropzone limitations, if the form includes a dropzone, it has to
    // be submitted through its init handler
    var dropzone = itemBuilderElement.find('.attach-file-local-dropzone')
    dropzone.addClass('dropzone');
    dropzone.dropzone({
      url: Onkho.Variables.BaseURL + '/activityFeed/saveItem',
      method: 'POST',
      paramName: 'files',
      parallelUploads: 20,
      uploadMultiple: true,
      addRemoveLinks: true,
      autoProcessQueue: false,
      maxFiles: 20,
      maxFilesize: 20, //MB
      init: function() {
        dropzone = this

        dropzone.on('addedfile', function (file) {
          RedrawLocalAttachments(itemBuilderElement, dropzone)
        })

        dropzone.on('removedfile', function (file) {
          RedrawLocalAttachments(itemBuilderElement, dropzone)
        })

        // Prior to submitting the files to the server we add the other form
        // data required
        dropzone.on('sendingmultiple', function (file, request, formData) {
          ShowItemBuilderLoading(itemBuilderElement)
          SetItemBuilderErrors(itemBuilderElement, [])

          ValidateFileAttachmentsMaxSize(itemBuilderElement, dropzone)
          GatherSaveItemBuilderData(itemBuilderElement, formData)
        })

        // If the server has accepted our upload - display a success message
        dropzone.on('successmultiple', function (file, response) {
          HideItemBuilderLoading(itemBuilderElement)
          Onkho.Alert.SmallBox('success', response.message)
          ItemBuilderSaved(itemBuilderElement)
        })

        // If the server (or dropzone) has rejected our upload, display an error
        // message
        dropzone.on('error', function (file, errorMessage, failure) {
          HideItemBuilderLoading(itemBuilderElement)

          if (errorMessage) {
            if (typeof errorMessage === 'string') {
              if (errorMessage.indexOf('<html>') !== -1) {
                $(file.previewElement).find('.dz-error-message').html('We were unable to attach this file.')
              }
            } else {
              $(file.previewElement).find('.dz-error-message').html('We were unable to attach this file.')
            }
          }

          // Avoid spamming the user by showing a single alert
          Onkho.Functions.DelayInterval('DropzoneErrorAlert', function () {
            if (errorMessage) {
              if (typeof errorMessage === 'string') {
                if (typeof failure === 'object') {
                  // Webserver error
                  switch (failure.status) {
                    case 413:
                      Onkho.Alert.BigBox('danger', 'Unexpected error', 'One or more of the files you tried to attach exceeded the maximum file size.')
                      break

                    default:
                      Onkho.Alert.BigBox('danger', 'Unexpected error', 'We were not able to attach your files due to an unexpected error. Please try again.')
                  }
                } else {
                  // Dropzone / frontend error
                  Onkho.Alert.BigBox('danger', 'Invalid file', errorMessage)
                }
              } else {
                // Backend error
                SetItemBuilderErrors(itemBuilderElement, errorMessage.errors)
              }
            }
          }, 250)

          $(dropzone.files).each(function (index, file) {
            file.status = 'queued'
          })
        })

        // If we have reached the maximum number of files that can be uploaded, show an alert
        dropzone.on('maxfilesreached', function (file, response) {
          Onkho.Functions.DelayInterval('DropzoneMaxfilesreachedAlert', function () {
            Onkho.Alert.BigBox('warning', 'Maximum number of files reached', 'You have reached the maximum number of files that you can attach.')
          }, 250)
        })

        // If we have exceeded the maximum number of files that can be uploaded, show an alert
        dropzone.on('maxfilesexceeded', function (file, response) {
          Onkho.Functions.DelayInterval('DropzoneMaxfilesexceededAlert', function () {
            Onkho.Alert.BigBox('error', 'Maximum number of files exceeded', 'You have exceeded the maximum number of files that you can attach.')
          }, 250)
        })
      }
    })

    itemBuilderElement.on('click', '.local-attachment .delete', function () {
      try {
        dropzone.removeFile(dropzone.getQueuedFiles()[$(this).closest('.local-attachment').data('index')])
      } catch (exception) {
        // The file must have been removed, so a redraw will show that
      }

      RedrawLocalAttachments(itemBuilderElement, dropzone)
    })
  }

  var RedrawDocumentPortalAttachments = function (itemBuilderElement, documentsPicker) {
    var documentPortalAttachmentsWrapper = itemBuilderElement.find('section[data-id="document-portal-attachments"]')
    documentPortalAttachmentsWrapper.empty()

    $(Onkho.DocumentsPicker.GetSelected(documentsPicker)).each(function (index, document) {
      documentPortalAttachmentsWrapper.append('' +
        '<div class="document-portal-attachment" data-id="' + document.data('id') + '">' +
        '<div class="filename">' + document.find('.document-details .filename').html() + '</div>' +
        '<div class="delete">×</div>' +
        '</div>')
    })

    ShowAppropriateAttachButton(itemBuilderElement)
  }

  var RedrawLocalAttachments = function (itemBuilderElement, dropzone) {
    // SetTimeout needed because dropzone seemingly pushes events before
    // updating its internal data structure
    setTimeout(function () {
      var localAttachments = itemBuilderElement.find('[data-id="attach"] [data-id="local-attachments"]')
      localAttachments.empty()

      $(dropzone.getQueuedFiles()).each(function (index, file) {
        localAttachments.append('' +
          '<div class="local-attachment" data-index="' + index + '">' +
            '<div class="filename">' + file.name + '</div>' +
            '<div class="delete">×</div>' +
          '</div>')
      })

      ShowAppropriateAttachButton(itemBuilderElement)
    }, 100)
  }

  var ValidateFileAttachmentsMaxSize = function (itemBuilderElement, dropzone) {
    var total = 0;
    $(dropzone.files).each(function (index, file) {
      total += file.size
    })

    if (total > 20 * 1024 * 2014) {
      SetItemBuilderErrors(itemBuilderElement, [
        'The total size of all attachments cannot exceed 20MB.'
      ])
    }
  }

  var InitializeRecipientsPicker = function (itemBuilderElement, type, recipients) {
    if (type === 'OUTGOING_EMAIL') {
      // Show CC/BCC
      itemBuilderElement.find('[data-id="recipients-picker"] [data-id="show-cc"]').on('click', function () {
        $(this).remove();
        itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="cc"]').removeClass('hidden')
      })

      itemBuilderElement.find('[data-id="recipients-picker"] [data-id="show-bcc"]').on('click', function () {
        $(this).remove()
        itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="bcc"]').removeClass('hidden')
      })

      // Mailbox picker
      InitializeMailboxPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="from"] [name="from_mailbox_id"]'))

      // Recipients pickers
      InitializeEmailAddressPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]'))
      InitializeEmailAddressPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="cc"] [name="cc"]'))
      InitializeEmailAddressPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="bcc"] [name="bcc"]'))

      // Prepopulate recipients
      if (typeof recipients !== 'undefined') {
        if (typeof recipients.from !== 'undefined' && recipients.from.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="from"] [name="from_mailbox_id"]').select2('data', recipients.from[0]);
        }

        if (typeof recipients.to !== 'undefined' && recipients.to.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]').select2('data', recipients.to);
        }

        if (typeof recipients.cc !== 'undefined' && recipients.cc.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] [data-id="show-cc"]').click();
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="cc"] [name="cc"]').select2('data', recipients.cc);
        }

        if (typeof recipients.bcc !== 'undefined' && recipients.bcc.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] [data-id="show-bcc"]').click();
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="bcc"] [name="bcc"]').select2('data', recipients.bcc);
        }
      }

      // Auto-organize when a To recipient is selected
      itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]').on('change', function () {
        var emailAddress = $(this).select2('data')[0]
        if (typeof emailAddress !== 'undefined') {
          AutoLinkToContact(itemBuilderElement, emailAddress.contact_party_id, emailAddress.name)
        }
      })
    } else if (type === 'OUTGOING_SMS') {
      // SMS box picker
      InitializeSMSBoxPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="from"] [name="from_smsbox_id"]'))

      // Recipients pickers
      InitializePhoneNumberPicker(itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]'))

      // Prepopulate recipients
      if (typeof recipients !== 'undefined') {
        if (typeof recipients.from !== 'undefined' && recipients.from.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="from"] [name="from_smsbox_id"]').select2('data', recipients.from[0]);
        }

        if (typeof recipients.to !== 'undefined' && recipients.to.length > 0) {
          itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]').select2('data', recipients.to);
        }
      }

      // Auto-organize when a To recipient is selected
      itemBuilderElement.find('[data-id="recipients-picker"] section[data-id="to"] [name="to"]').on('change', function () {
        var phoneNumber = $(this).select2('data')[0]
        if (typeof phoneNumber !== 'undefined') {
          AutoLinkToContact(itemBuilderElement, phoneNumber.contact_party_id, phoneNumber.name)
        }
      })
    }
  }

  var AutoLinkToContact = function (itemBuilderElement, contactPartyId, contactPartyName) {
    if (itemBuilderElement.find('[data-id="organise"]').length > 0 && parseInt(contactPartyId)) {
      var organiseButton = itemBuilderElement.find('[data-id="attributes"] .organise')
      var organiseContainer = itemBuilderElement.find('[data-id="organise"]')
      var mapToContactCheckbox = organiseContainer.find('[name="map_to_contact"]')
      var contactPicker = organiseContainer.find('[name="contact_party_id"]')
      if (contactPicker.length > 0 && !contactPicker.select2('data')) {
        // There is no selected contact, so we can automatically link to it
        var fakeContact = {
          id: contactPartyId,
          name: contactPartyName,
          html: ''
        }
        contactPicker.select2('data', fakeContact).trigger('change')

        if (!mapToContactCheckbox.is(':checked')) {
          mapToContactCheckbox.prop('checked', true).trigger('change')
        }

        organiseButton.click()
        ShowAppropriateOrganiseButton(organiseContainer)
      }
    }
  }

  var InitializeMailboxPicker = function (inputElement) {
    inputElement.select2({
      placeholder: 'Choose a mailbox',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/mailbox/getMailboxSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatResult: FormatMailboxSearchResult,
      formatSelection: FormatMailboxSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    })
  }

  var FormatMailboxSearchResult = function (mailbox) {
    return mailbox.html
  }

  var FormatMailboxSearchResultSelection = function (mailbox) {
    return mailbox.practice_name + ' &lt;' + mailbox.address + '&gt;';
  }

  var InitializeSMSBoxPicker = function (inputElement) {
    inputElement.select2({
      placeholder: 'Choose a phone number',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/smsbox/getSMSBoxSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatResult: FormatSMSBoxSearchResult,
      formatSelection: FormatSMSBoxSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    })
  }

  var FormatSMSBoxSearchResult = function (smsbox) {
    return smsbox.html
  }

  var FormatSMSBoxSearchResultSelection = function (smsbox) {
    return smsbox.practice_name + ' &lt;' + smsbox.from + '&gt;';
  }

  var InitializeEmailAddressPicker = function (inputElement) {
    inputElement.select2({
      placeholder: 'Search for email addresses',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/contact/getEmailAddressSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatResult: FormatEmailAddressSearchResult,
      formatSelection: FormatEmailAddressSearchResultSelection,
      createSearchChoice: function (term, data) {
        return false
      },
      tags: [],
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    })
  }

  var FormatEmailAddressSearchResult = function (emailAddress) {
    return emailAddress.html
  }

  var FormatEmailAddressSearchResultSelection = function (emailAddress) {
    return emailAddress.name + ' &lt;' + emailAddress.address + '&gt;';
  }

  var InitializePhoneNumberPicker = function (inputElement) {
    inputElement.select2({
      placeholder: 'Search for phone numbers',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/contact/getPhoneNumberSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return { results: data.items, more: more };
        }
      },
      formatResult: FormatPhoneNumberSearchResult,
      formatSelection: FormatPhoneNumberSearchResultSelection,
      createSearchChoice: function (term, data) {
        return false
      },
      tags: [],
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    })
  }

  var FormatPhoneNumberSearchResult = function (phoneNumber) {
    return phoneNumber.html
  }

  var FormatPhoneNumberSearchResultSelection = function (phoneNumber) {
    return phoneNumber.name + ' &lt;' + phoneNumber.phone_number + '&gt;'
  }

  var InitializeSnoozer = function (container) {
    var customSnoozeSection = container.find('section[data-id="custom-snooze"]')
    var input = container.find('[name="unsnooze_datetime"]')
    var runSelectionSideEffects = function (dp, field) {
      var datetime = moment(field.val(), 'YYYY-MM-DD HH:mm')
      container.find('.unsnooze-at').html(datetime.format('DD/MM/YYYY HH:mm'))
      customSnoozeSection.addClass('active')
      container.find('.quick-snooze.active').removeClass('active')
    }

    input.datetimepicker({
      format: 'Y-m-d H:i',
      minDate: '0',
      allowTimes: ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      inline: true,
      onChangeDateTime: runSelectionSideEffects
    })

    container.find('.quick-snooze').on('click', function () {
      container.find('.quick-snooze.active').removeClass('active')
      customSnoozeSection.removeClass('active')
      $(this).addClass('active')
      input.datetimepicker('reset')

      input.val($(this).data('value'))
      container.find('.unsnooze-at').html($(this).data('value'))
    })
  }

  var InitializeOrganiser = function (container, excludeGroupId, contactPartyId) {
    var organiseContainer = container.find('[data-id="organise"]')

    // Organise
    container.on('click', '.organise', function () {
      $(this).addClass('hidden')
      container.find('.unorganise').removeClass('hidden')
      organiseContainer.slideDown(300)
    })

    // Unorganise
    container.on('click', '.unorganise', function () {
      $(this).addClass('hidden')
      container.find('.organise').removeClass('hidden')
      organiseContainer.slideUp(300)
    })

    // Contact picker
    var contactPicker = organiseContainer.find('section[data-id="contact-picker"] [name="contact_party_id"]')
    if (contactPicker.length > 0) {
      contactPicker.select2({
        placeholder: 'Find a contact to save to',
        minimumInputLength: 0,
        ajax: {
          url: Onkho.Variables.BaseURL + '/contact/getContactSearchResults',
          dataType: 'json',
          quietMillis: 700,
          data: function (term, page) {
            return {
              searchTerm: term,
              page: page
            };
          },
          results: function (data, page) {
            var more = (page * 30) < data.totalCount;
            return {results: data.items, more: more};
          }
        },
        formatResult: FormatContactSearchResult,
        formatSelection: FormatContactSearchResultSelection,
        dropdownCssClass: 'bigdrop',
        escapeMarkup: function (m) { return m; }
      })

      // Allow document portal attachments if in the appropriate context
      contactPicker.on('change', function () {
        PointDocumentPortalAttachmentsUIToContact(container, $(this).val())
      })

      organiseContainer.find('input[type="checkbox"][name="map_to_contact"]').on('change', function (event) {
        ShowAppropriateOrganiseButton(organiseContainer)
        if ($(this).is(':checked')) {
          organiseContainer.find('input[type="checkbox"][name="map_to_group"]').prop('checked', false).trigger('change')
          contactPicker.select2('enable', true)
        } else {
          contactPicker.select2('val', '').trigger('change')
          contactPicker.select2('enable', false)
        }
      })
      organiseContainer.find('input[type="checkbox"][name="map_to_contact"]').trigger('change')
    }

    var activityFeedGroupTypeSection = organiseContainer.find('section[data-id="activity-feed-group-type"]');
    if (activityFeedGroupTypeSection.length > 0) {
      // Group type picker
      var groupTypePicker = activityFeedGroupTypeSection.find('[name="activity_feed_group_type"]')
      groupTypePicker.select2({
        placeholder: 'What conversation do you want to use?'
      })
      groupTypePicker.on('change', function () {
        ShowAppropriateGroupPickerSection(organiseContainer)
      })
      ShowAppropriateGroupPickerSection(organiseContainer)

      organiseContainer.find('input[type="checkbox"][name="map_to_group"]').on('change', function () {
        ShowAppropriateOrganiseButton(organiseContainer)
        if ($(this).is(':checked')) {
          groupTypePicker.select2('enable', true)
        } else {
          groupTypePicker.select2('val', '')
          groupTypePicker.select2('enable', false)
        }
        ShowAppropriateGroupPickerSection(organiseContainer)
      })
      organiseContainer.find('input[type="checkbox"][name="map_to_group"]').trigger('change')

      // Job group picker
      var jobGroupPicker = organiseContainer.find('section[data-activity-feed-group-type="JOB"] [name="job_id"]')
      if (jobGroupPicker.length > 0) {
        jobGroupPicker.select2({
          placeholder: 'Find a job',
          minimumInputLength: 3,
          ajax: {
            url: Onkho.Variables.BaseURL + '/activityFeed/getGroupSearchResults',
            dataType: 'json',
            quietMillis: 700,
            data: function (term, page) {
              var relevantContactPartyId = contactPartyId
              if (contactPicker.length > 0 && contactPicker.val().length > 0) {
                relevantContactPartyId = contactPicker.val()
              }
              return {
                activity_feed_group_type: 'JOB',
                exclude_group_id: excludeGroupId,
                contact_party_id: relevantContactPartyId,
                searchTerm: term,
                page: page
              };
            },
            results: function (data, page) {
              var more = (page * 30) < data.totalCount;
              return {results: data.items, more: more};
            }
          },
          formatResult: FormatGroupSearchResult,
          formatSelection: FormatGroupSearchResultSelection,
          dropdownCssClass: 'bigdrop',
          escapeMarkup: function (m) { return m; }
        })
      }

      // Service group picker
      var serviceGroupPicker = organiseContainer.find('section[data-activity-feed-group-type="SERVICE"] [name="service_id"]')
      if (serviceGroupPicker.length > 0) {
        serviceGroupPicker.select2({
          placeholder: 'Find service',
          minimumInputLength: 3,
          ajax: {
            url: Onkho.Variables.BaseURL + '/activityFeed/getGroupSearchResults',
            dataType: 'json',
            quietMillis: 700,
            data: function (term, page) {
              var relevantContactPartyId = contactPartyId
              if (contactPicker.length > 0 && contactPicker.val().length > 0) {
                relevantContactPartyId = contactPicker.val()
              }
              return {
                activity_feed_group_type: 'SERVICE',
                exclude_group_id: excludeGroupId,
                contact_party_id: relevantContactPartyId,
                searchTerm: term,
                page: page
              };
            },
            results: function (data, page) {
              var more = (page * 30) < data.totalCount;
              return {results: data.items, more: more};
            }
          },
          formatResult: FormatGroupSearchResult,
          formatSelection: FormatGroupSearchResultSelection,
          dropdownCssClass: 'bigdrop',
          escapeMarkup: function (m) { return m; }
        });
      }

      // General group picker
      var generalGroupPicker = organiseContainer.find('section[data-activity-feed-group-type="GENERAL"] [name="activity_feed_group_id"]')
      if (generalGroupPicker.length > 0) {
        generalGroupPicker.select2({
          placeholder: 'Find conversation',
          minimumInputLength: 3,
          ajax: {
            url: Onkho.Variables.BaseURL + '/activityFeed/getGroupSearchResults',
            dataType: 'json',
            quietMillis: 700,
            data: function (term, page) {
              var relevantContactPartyId = contactPartyId
              if (contactPicker.length > 0 && contactPicker.val().length > 0) {
                relevantContactPartyId = contactPicker.val()
              }
              return {
                activity_feed_group_type: 'GENERAL',
                exclude_group_id: excludeGroupId,
                contact_party_id: relevantContactPartyId,
                searchTerm: term,
                page: page
              };
            },
            results: function (data, page) {
              var more = (page * 30) < data.totalCount;
              return {results: data.items, more: more};
            }
          },
          formatResult: FormatGroupSearchResult,
          formatSelection: FormatGroupSearchResultSelection,
          dropdownCssClass: 'bigdrop',
          escapeMarkup: function (m) { return m; }
        });
      }

      // New group creator
      var newGroupCreator = organiseContainer.find('section[data-activity-feed-group-type="NEW"] [name="activity_feed_group_subject"]')
      if (newGroupCreator.length > 0) {
        // Nothing to do
      }
    }
  }

  var FormatContactSearchResult = function (contact) {
    return contact.html
  }

  var FormatContactSearchResultSelection = function (contact) {
    return contact.name
  }

  var FormatGroupSearchResult = function (group) {
    return group.html
  }

  var FormatGroupSearchResultSelection = function (group) {
    return group.subject + (typeof group.contact !== 'undefined' ? (' (' + group.contact + ')') : '');
  }

  var PointDocumentPortalAttachmentsUIToContact = function (itemBuilderElement, contactPartyId) {
    var attachmentsWrapper = itemBuilderElement.find('[data-id="attach"]')
    var documentPortalButton = attachmentsWrapper.find('[data-id="buttons-bar"] [data-id="document-portal-upload"]')
    var documentPortalAttachmentModal = itemBuilderElement.find('.modal.document-portal-attachment-modal')
    var documentsPicker = documentPortalAttachmentModal.find('.o-documents-picker')

    // Clear out existing document portal attachments
    Onkho.DocumentsPicker.DeselectAll(documentsPicker)

    // Reconfigure documents picker
    documentsPicker.find('[name="partyId"]').val(contactPartyId)

    if (contactPartyId) {
      // A contact is chosen, show document portal attachments button
      documentPortalButton.removeClass('hidden')
      Onkho.DocumentsPicker.Reload(documentsPicker)
    } else {
      // No contact is chosen, hide document portal attachments button
      documentPortalButton.addClass('hidden')
    }
  }

  var ShowAppropriateOrganiseButton = function (organiseContainer) {
    var itemBuilderElement = organiseContainer.closest('.activity-feed-item-builder')

    if (!organiseContainer.is(':visible')) {
      itemBuilderElement.find('.unorganise').addClass('hidden')
      itemBuilderElement.find('.organise').removeClass('hidden')
    } else {
      if (organiseContainer.find('input[type="checkbox"]:checked').length > 0) {
        itemBuilderElement.find('.organise').addClass('hidden')
        itemBuilderElement.find('.unorganise').addClass('hidden')
      } else {
        itemBuilderElement.find('.organise').addClass('hidden')
        itemBuilderElement.find('.unorganise').removeClass('hidden')
      }
    }
  }

  var ShowAppropriateGroupPickerSection = function (container) {
    var groupTypePicker = container.find('section[data-id="activity-feed-group-type"] [name="activity_feed_group_type"]');

    // Hide all sections
    container.find('section[data-activity-feed-group-type]').addClass('hidden')

    // Show appropriate section
    var section = container.find('section[data-activity-feed-group-type="' + groupTypePicker.val() + '"]')
    section.removeClass('hidden')

    // Clear the inputs of all sections that are still hidden
    container.find('section.hidden[data-activity-feed-group-type] .activity-feed-group-picker').each(function (index, element) {
      if ($(element).hasClass('select2-offscreen')) {
        $(element).select2('val', '')
      } else {
        $(element).val('')
      }
    })
  }

  var GetCreateFormWithoutContext = function (pane, type) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_type = type

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getCreateFormWithoutContext',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              pane.find('.activity-feed-item-builder-loader').replaceWith(data.responseJSON.html)

              var missingMailboxElement = $(data.responseJSON.html).find('.missing-mailbox')
              if (missingMailboxElement.length === 0) {
                InitializeItemBuilder(
                  pane.find('.activity-feed-item-builder'),
                  type,
                  data.responseJSON.recipients
                )

                pane.find('.activity-feed-item-builder [data-id="content"] .note-editable').trigger('focus');
              }
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var GetCreateFormWithoutContextAndRecipients = function (panel, type) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_type = type

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getCreateFormWithoutContextAndRecipients',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              panel.find('.activity-feed-item-builder-loader').replaceWith(data.responseJSON.html)

              var missingMailboxElement = $(data.responseJSON.html).find('.missing-mailbox')
              if (missingMailboxElement.length === 0) {
                InitializeItemBuilder(
                  panel.find('.activity-feed-item-builder'),
                  type,
                  data.responseJSON.recipients
                )

                panel.find('.activity-feed-item-builder [data-id="content"] .note-editable').trigger('focus');
              }
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmCancelItemBuilder = function (itemBuilderElement) {
    var content = Onkho.Summernote.GetSummernoteValue(itemBuilderElement.find('.content-area'))
    if (content.length > 0) {
      var itemType = itemBuilderElement.data('type').toLowerCase().replace(/_/g, ' ');
      $.SmartMessageBox(
        {
          title: 'Discard this ' + itemType,
          content: 'This ' + itemType + ' hasn\'t been saved. Are you sure you want to discard it?',
          buttons: '[No][Yes]'
        }, function (ButtonPress) {
          if (ButtonPress == 'Yes') {
            CancelItemBuilder(itemBuilderElement)
          }
        }
      )
    } else {
      CancelItemBuilder(itemBuilderElement)
    }
  }

  var CancelItemBuilder = function (itemBuilderElement) {
    HideItemBuilder(itemBuilderElement)
    ClearItemBuilder(itemBuilderElement)

    var pane = itemBuilderElement.closest('.o-pane[data-pane-key="activity_feed_item_builder"]')
    if (pane.length > 0) {
      Onkho.OnkhoPane.Remove(pane)
      return;
    }

    var panel = itemBuilderElement.closest('.o-panel[data-panel-key="activity_feed_item_builder"]')
    if (panel.length > 0) {
      Onkho.OnkhoPanel.Remove(panel)
      return;
    }

    var activityFeedItemElement = itemBuilderElement.closest('.activity-feed-item')
    if (activityFeedItemElement.length > 0 && itemBuilderElement.data('action') === 'edit') {
      // We were editing an activity feed item using the in-item builder so the
      // content of the item must have been previously hidden
      activityFeedItemElement.find('> .body > .content').show()
      return;
    }

    panel = itemBuilderElement.closest('.o-panel')
    if (panel.length > 0) {
      Onkho.OnkhoPanel.Remove(panel)
      return;
    }
  }

  var ShowItemBuilder = function (itemBuilderElement) {
    itemBuilderElement.removeClass('hidden').addClass('showing')
  }

  var HideItemBuilder = function (itemBuilderElement) {
    itemBuilderElement.removeClass('showing').addClass('hidden')
  }

  var ClearItemBuilder = function (itemBuilderElement) {
    itemBuilderElement.data('type', '')
    itemBuilderElement.empty()
  }

  var SaveItemBuilder = function (itemBuilderElement) {
    if (itemBuilderElement.find('[data-id="attach"]').length > 0) {
      // Builder will be saved via the dropzone's init handler
      var dropzone = itemBuilderElement.find('.attach-file-local-dropzone')[0].dropzone
      if (dropzone.files.length > 0) {
        dropzone.processQueue()
      } else {
        // Force dropzone to save the item builder even if there are no
        // attachments by attaching a fake file
        var blob = new Blob()
        blob.upload = {'chunked': dropzone.defaultOptions.chunking}
        dropzone.uploadFile(blob)
      }
    } else {
      ShowItemBuilderLoading(itemBuilderElement)
      SetItemBuilderErrors(itemBuilderElement, [])

      var formData = {}
      GatherSaveItemBuilderData(itemBuilderElement, formData)

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/saveItem',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            HideItemBuilderLoading(itemBuilderElement)

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                ItemBuilderSaved(itemBuilderElement)
                break

              case 400:
                SetItemBuilderErrors(itemBuilderElement, data.responseJSON.errors)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var GatherSaveItemBuilderData = function (itemBuilderElement, formData) {
    formData._token = Onkho.Functions.GetCSRF()

    formData.action = itemBuilderElement.data('action')

    itemBuilderElement.find('input[type="hidden"], input:not(.select2-focusser):not([type="checkbox"]):visible, input[type="checkbox"]:checked, select:visible').each(function (index, input) {
      formData[$(input).attr('name')] = $(input).val()
    })

    // Schedule send
    var scheduledSendModal = itemBuilderElement.find('.modal.schedule-send-modal')
    if (scheduledSendModal.is(':visible')) {
      formData.scheduled_at = scheduledSendModal.find('[name="scheduled_at"]').val()
    }

    formData.content = Onkho.Summernote.GetSummernoteValue(itemBuilderElement.find('.content-area'))

    if (itemBuilderElement.find('section[data-id="document-portal-attachments"] .document-portal-attachment').length > 0) {
      formData.document_portal_attachments = []
      itemBuilderElement.find('section[data-id="document-portal-attachments"] .document-portal-attachment').each(function (index, file) {
        formData.document_portal_attachments.push($(file).data('id'))
      })
    }

    var parentPanel = itemBuilderElement.closest('.o-panel')
    if (parentPanel.length > 0 && parentPanel.data('panel-key') === 'insights_action_activity_feed_item') {
      // This is the panel used to create an activity feed item as a bulk action
      // on the insights grid
      var selection = []
      $(Onkho.InsightGridPageTools.GetSelection()).each(function (index, selectedRowData) {
        selection.push(selectedRowData.contact_id)
      })

      formData.selection = selection.join(',')
    }

    // When called by the dropzone listener, formData is a FormData, not a
    // simple object
    if (formData instanceof FormData) {
      for (var key in formData) {
        if (formData.hasOwnProperty(key)) {
          formData.append(key, formData[key])
        }
      }
    }
  }

  var ItemBuilderSaved = function (itemBuilderElement) {
    var panel;
    var pane;

    Onkho.DocumentsPicker.DeselectAll(itemBuilderElement.find('.modal.document-portal-attachment-modal .o-documents-picker'))

    // If the builder used was opened in response to an insights bulk action,
    // deselect items and refresh the insight
    panel = itemBuilderElement.closest('.o-panel')
    if (panel.length > 0 && panel.data('panel-key') === 'insights_action_activity_feed_item') {
      Onkho.InsightGridPageTools.DeselectAll()
      Onkho.InsightGridPageTools.ReloadRecords()

      if (panel.length > 0) {
        Onkho.OnkhoPanel.Remove(panel)
      }

      return;
    }

    // Check if in item builder pane
    pane = itemBuilderElement.closest('.o-pane[data-pane-key="activity_feed_item_builder"]')
    if (pane.length > 0) {
      Onkho.OnkhoPane.Remove(pane)
    } else {
      // Check if in item builder panel
      panel = itemBuilderElement.closest('.o-panel[data-panel-key="activity_feed_item_builder"]')
      if (panel.length > 0) {
        Onkho.OnkhoPanel.Remove(panel)
      } else {
        // Not in pane/panel, must be viewing the group, so refresh it
        RefreshGroup(itemBuilderElement.closest('.activity-feed-group'))
      }
    }

    // If the builder used was in-item and the item pinned, reload any
    // pinned items sections on the page
    var item = itemBuilderElement.closest('.activity-feed-item')
    if (item.length > 0 && item.hasClass('pinned')) {
      ReloadPinnedItemsSections()
    }
  }

  var ShowItemBuilderLoading = function (itemBuilderElement) {
    Onkho.LoadingTools.ShowLoading(itemBuilderElement.find('button[name="cancel"]'))
    Onkho.LoadingTools.ShowLoading(itemBuilderElement.find('button[name="save"]'))
    Onkho.LoadingTools.ShowLoading(itemBuilderElement.find('.modal.schedule-send-modal button.close'))
    Onkho.LoadingTools.ShowLoading(itemBuilderElement.find('.modal.schedule-send-modal button.cancel'))
    Onkho.LoadingTools.ShowLoading(itemBuilderElement.find('.modal.schedule-send-modal button.save'))
  }

  var HideItemBuilderLoading = function (itemBuilderElement) {
    Onkho.LoadingTools.HideLoading(itemBuilderElement.find('button[name="cancel"]'))
    Onkho.LoadingTools.HideLoading(itemBuilderElement.find('button[name="save"]'))
    Onkho.LoadingTools.HideLoading(itemBuilderElement.find('.modal.schedule-send-modal button.close'))
    Onkho.LoadingTools.HideLoading(itemBuilderElement.find('.modal.schedule-send-modal button.cancel'))
    Onkho.LoadingTools.HideLoading(itemBuilderElement.find('.modal.schedule-send-modal button.save'))
  }

  var SetItemBuilderErrors = function (itemBuilderElement, errors) {
    var formErrorsElement = itemBuilderElement.find('.form-errors')
    formErrorsElement.empty()
    $(errors).each(function (index, error) {
      formErrorsElement.append('<div class="form-error">' + error + '</div>')
    })
  }

  var ConfirmArchiveGroup = function (activityFeedGroupElement) {
    ArchiveGroup(activityFeedGroupElement)
    // Confirmation dialog disabled
    /*
    $.SmartMessageBox(
      {
        title: 'Archive this conversation and its contents',
        content: 'Are you sure you want to archive all of these items? Archived conversations will not be shown in the default view of Inbox.',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          ArchiveGroup(activityFeedGroupElement)
        }
      }
    )*/
  }

  var ArchiveGroup = function (activityFeedGroupElement) {
    var archiveButton = activityFeedGroupElement.find('> .toolbar [data-id="archive"]')
    Onkho.LoadingTools.ShowLoading(archiveButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/archiveGroup',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(archiveButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              RefreshGroup(activityFeedGroupElement)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowSnoozeGroupModal = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.snooze-activity-feed-group-modal')
    ResetSnoozeGroupModal(modal)

    modal.modal('show')
  }

  var ResetSnoozeGroupModal = function (modal) {
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))
    modal.find('[name="unsnooze_datetime"]').datetimepicker('reset')
    setTimeout(function () {
      modal.find('[name="unsnooze_datetime"]').trigger('xchange.xdsoft').trigger('afterOpen.xdsoft')
    }, 100)
  }

  var SnoozeGroup = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.snooze-activity-feed-group-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.activity_feed_group_id = activityFeedGroupElement.data('id')
      formData.unsnooze_datetime = modal.find('[name="unsnooze_datetime"]').val();

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/snoozeGroup',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')

                RefreshGroup(activityFeedGroupElement)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var ConfirmMoveToInboxGroup = function (activityFeedGroupElement) {
    $.SmartMessageBox(
      {
        title: 'Show conversation in Inbox',
        content: 'Are you sure you want to show this conversation in your Inbox? This will unarchive and unsnooze it!',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          MoveToInboxGroup(activityFeedGroupElement)
        }
      }
    )
  }

  var MoveToInboxGroup = function (activityFeedGroupElement) {
    var moveToInboxButton = activityFeedGroupElement.find('> .toolbar [data-id="move_to_inbox"]')
    Onkho.LoadingTools.ShowLoading(moveToInboxButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/moveToInboxGroup',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(moveToInboxButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              RefreshGroup(activityFeedGroupElement)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowOrganiseGroupModal = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.organise-activity-feed-group-modal')
    ResetOrganiseGroupModal(modal)

    modal.modal('show')
  }

  var ResetOrganiseGroupModal = function (modal) {
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))
    modal.find('[name="map_to_contact"]').prop('checked', false).trigger('change')
    modal.find('[name="map_to_group"]').prop('checked', false).trigger('change')

    var contactPicker = modal.find('[name="contact_party_id"]')
    var initialContactPartyId = contactPicker.data('initial-id')
    if (typeof initialContactPartyId !== 'undefined') {
      modal.find('[name="map_to_contact"]').prop('checked', true).trigger('change')
      contactPicker.select2('data', {
        'id': initialContactPartyId,
        'name': contactPicker.data('initial-name'),
        'html': ''
      })
    }

    var groupTypePicker = modal.find('[name="activity_feed_group_type"]')
    var initialGroupType = groupTypePicker.data('initial-type')
    if (typeof initialGroupType !== 'undefined') {
      modal.find('[name="map_to_group"]').prop('checked', true).trigger('change')
      groupTypePicker.select2('val', initialGroupType).trigger('change')
      var generalGroupPicker = modal.find('[name="activity_feed_group_id"]')
      generalGroupPicker.select2('data', {
        'id': generalGroupPicker.data('initial-id'),
        'subject': generalGroupPicker.data('initial-subject'),
        'contact': generalGroupPicker.data('initial-contact'),
        'html': ''
      })
    }
  }

  var OrganiseGroup = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.organise-activity-feed-group-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.source_activity_feed_group_id = activityFeedGroupElement.data('id')

      modal.find('input[type="hidden"], input[type="text"]:not(.select2-focusser):not([type="checkbox"]):visible, input[type="checkbox"]:checked, select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/organiseGroup',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')

                // Open the destination group
                activityFeedGroupElement.data('id', data.responseJSON.destination_activity_feed_group_id)
                RefreshGroup(activityFeedGroupElement)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var ConfirmDeleteGroup = function (activityFeedGroupElement) {
    $.SmartMessageBox(
      {
        title: 'Delete conversation and its contents',
        content: 'Are you sure you want to delete all of these items?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteGroup(activityFeedGroupElement)
        }
      }
    )
  }

  var DeleteGroup = function (activityFeedGroupElement) {
    var deleteButton = activityFeedGroupElement.find('> .toolbar [data-id="delete"]')
    Onkho.LoadingTools.ShowLoading(deleteButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/deleteGroup',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(deleteButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              var pane = activityFeedGroupElement.closest('.o-pane')
              if (pane.length > 0) {
                // When inside a pane, close the pane
                Onkho.OnkhoPane.Remove(pane)
              } else {
                RemoveGroup(activityFeedGroupElement)
              }

              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var RemoveGroup = function (activityFeedGroupElement) {
    activityFeedGroupElement.remove()
  }

  var SetGroupStatus = function (activityFeedGroupElement, statusElement) {
    var currentStatusElement = activityFeedGroupElement.find('> .toolbar .status-control .dropdown .current')
    currentStatusElement.html(statusElement.html())
    currentStatusElement.data('id', statusElement.data('id'))
    var dropdown = currentStatusElement.closest('.dropdown')
    dropdown.attr('class', 'dropdown')
    dropdown.addClass(statusElement.data('id').toLowerCase().replace(/_/g, '-'))

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')
    formData.status = statusElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/setGroupStatus',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SetGroupAssignee = function (activityFeedGroupElement, assigneeId, assigneeLabel) {
    var currentAssigneeElement = activityFeedGroupElement.find('> .toolbar .assignee-control .dropdown .current')
    currentAssigneeElement.html(assigneeLabel)
    currentAssigneeElement.data('id', assigneeId)
    var dropdown = currentAssigneeElement.closest('.dropdown')
    dropdown.attr('class', 'dropdown')
    if (assigneeId == 0) {
      dropdown.addClass('unassigned')
    }

    // Close picker
    activityFeedGroupElement.find('> .toolbar .assignee-control .dropdown.open [data-toggle="dropdown"]').click()

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_id = activityFeedGroupElement.data('id')
    formData.assigned_to_party_id = assigneeId

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/setGroupAssignee',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmDeleteItem = function (activityFeedItemElement) {
    var itemType = activityFeedItemElement.data('type').toLowerCase().replace(/_/g, ' ');
    $.SmartMessageBox(
      {
        title: 'Delete ' + itemType,
        content: 'Are you sure you want to delete this ' + itemType + '? You will not be able to recover it.',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteItem(activityFeedItemElement)
        }
      }
    )
  }

  var DeleteItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/deleteItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              activityFeedItemElement.slideUp(300, function () {
                activityFeedItemElement.remove()
              })
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmMakeRequest = function (activityFeedItemElement) {
    var itemType = activityFeedItemElement.data('type').toLowerCase().replace(/_/g, ' ');
    $.SmartMessageBox(
      {
        title: 'Copy ' + itemType + ' as task',
        content: 'Are you sure you want to create a task based on this ' + itemType + '? Please note that the attachments will not carry over.',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          MakeRequest(activityFeedItemElement)
        }
      }
    )
  }

  var MakeRequest = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/makeRequest',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              RefreshGroup(activityFeedItemElement.closest('.activity-feed-group'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var PinItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/pinItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              $('.activity-feed-item[data-id="' + activityFeedItemElement.data('id') + '"]').addClass('pinned');

              ReloadPinnedItemsSections()

              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var UnpinItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/unpinItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              $('.activity-feed-item[data-id="' + activityFeedItemElement.data('id') + '"]').removeClass('pinned');

              SoftReloadPinnedItemsSections();

              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SoftReloadPinnedItemsSections = function () {
    var container = $('.pinned-activity-feed-items')

    // Remove all the unpinned items from the pinned items section
    var unpinnedItems = container.find('.activity-feed-item:not(.pinned)')
    unpinnedItems.slideUp(300, function () {
      unpinnedItems.remove()

      var items = container.find('.activity-feed-item')
      if (items.length <= 1) {
        container.find('> .show-more, > .show-less').remove()
      }

      if (items.length > 0) {
        var visibleItems = container.find('.activity-feed-item:visible')
        if (visibleItems.length == 0) {
          // Show first item
          container.find('.activity-feed-item:first-of-type').slideDown(200)
        }
      }
    })
  }

  var ReloadPinnedItemsSections = function () {
    // Get all the pinned items from the server
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    // For the party page, only get the pinned items in this party's feed
    if (Onkho.Variables.PageName === 'party') {
      formData.partyId = Onkho.Variables.PartyId
    }

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getPinnedItems',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              $('.pinned-activity-feed-items').each(function (index, element) {
                $(element).replaceWith(data.responseJSON.html)
              })

              InitializeItems($('.pinned-activity-feed-items'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowSetGroupSubjectModal = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.update-activity-feed-group-subject-modal')
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))

    modal.modal('show')
  }

  var SetGroupSubject = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.update-activity-feed-group-subject-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))
      Onkho.LoadingTools.ShowLoading(activityFeedGroupElement.find('> .header .subject'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.activity_feed_group_id = activityFeedGroupElement.data('id')
      formData.subject = modal.find('[name="subject"]').val()

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/setGroupSubject',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))
            Onkho.LoadingTools.HideLoading(activityFeedGroupElement.find('> .header .subject'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                activityFeedGroupElement.find('> .header .subject').html(data.responseJSON.subject)
                modal.modal('hide')
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var LoadReadStatusReport = function (activityFeedItemElement) {
    var button = activityFeedItemElement.find('> .body > .header > .metadata .read-status')
    Onkho.LoadingTools.ShowLoading(button)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/getReadStatusReport',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(button)

          switch (data.status) {
            case 200:
              button.tooltip('hide')
              button.attr('data-original-title', data.responseJSON.html)
              button.tooltip('show')
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var LoadActivityFeedItemBuilderPane = function (activityFeedItemType)
  {
    var additionalData = {
      'activityFeedItemType': activityFeedItemType
    };

    Onkho.OnkhoPane.Add('activity_feed_item_builder', additionalData, undefined, ActivityFeedItemBuilderPaneLoaded)
  }

  var ActivityFeedItemBuilderPaneLoaded = function (pane)
  {
    Onkho.OnkhoPane.Show(pane)

    pane.find('.close[data-id="close"]').on('click', function ()
    {
      PrepareRemoval(pane);
      Onkho.OnkhoPane.Remove(pane);
    });

    GetCreateFormWithoutContext(pane, pane.data('activity-feed-item-type'))
  }

  var LoadActivityFeedItemBuilderPanel = function (activityFeedItemType, partyId)
  {
    var additionalData = {
      'activityFeedItemType': activityFeedItemType,
      'partyId': partyId
    };

    Onkho.OnkhoPanel.Add('activity_feed_item_builder', additionalData, ActivityFeedItemBuilderPanelLoaded)
  };

  var ActivityFeedItemBuilderPanelLoaded = function (panel)
  {
    // This panel may be opened by a featured action on an active element
    Onkho.OnkhoActiveElement.RemoveAll();

    Onkho.OnkhoPanel.Show(panel)

    panel.find('.close[data-id="close"]').on('click', function ()
    {
      PrepareRemoval(panel);
      Onkho.OnkhoPanel.Remove(panel);
    });

    GetCreateFormInContactContext(panel, panel.data('activity-feed-item-type'))
  };

  var PrepareRemoval = function (panel)
  {
    Onkho.Summernote.DestroySummernote(panel.find('textarea.summernote'));
  };

  var ValidateItemBuilder = function (itemBuilderElement, successCallback, failureCallback)
  {
    SetItemBuilderErrors(itemBuilderElement, [])

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    itemBuilderElement.find('input[type="text"]:not(.select2-focusser), input[type="hidden"], select:visible').each(function (index, input) {
      formData[$(input).attr('name')] = $(input).val()
    })

    formData.content = Onkho.Summernote.GetSummernoteValue(itemBuilderElement.find('.content-area'))

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/validateItemBuilder',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              successCallback()
              break

            case 400:
              SetItemBuilderErrors(itemBuilderElement, data.responseJSON.errors)
              failureCallback()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowMorePinnedItems = function (pinnedItemsElement)
  {
    pinnedItemsElement.find('> .show-more').slideUp(200)
    pinnedItemsElement.find('> .activity-feed-item').slideDown(200)
    pinnedItemsElement.find('> .show-less').slideDown(200)
  }

  var ShowLessPinnedItems = function (pinnedItemsElement)
  {
    pinnedItemsElement.find('> .show-more').slideDown(200)
    pinnedItemsElement.find('> .activity-feed-item:not(:first-of-type)').slideUp(200)
    pinnedItemsElement.find('> .show-less').slideUp(200)
  }

  var ShowMorePinnedItemsInGroup = function (pinnedItemsElement)
  {
    pinnedItemsElement.find('> .show-more').slideUp(200)
    pinnedItemsElement.find('> .activity-feed-item').slideDown(200)
    pinnedItemsElement.find('> .show-less').slideDown(200)
  }

  var ShowLessPinnedItemsInGroup = function (pinnedItemsElement)
  {
    pinnedItemsElement.find('> .show-more').slideDown(200)
    pinnedItemsElement.find('> .activity-feed-item').slideUp(200)
    pinnedItemsElement.find('> .show-less').slideUp(200)
  }

  var ConfirmResendItem = function (activityFeedItemElement) {
    var itemType = activityFeedItemElement.data('type').toLowerCase().replace(/_/g, ' ');
    $.SmartMessageBox(
      {
        title: 'Resend ' + itemType,
        content: 'Are you sure you want to resend this ' + itemType + '?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          ResendItem(activityFeedItemElement)
        }
      }
    )
  }

  var ResendItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/resendItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              RefreshGroup(activityFeedItemElement.closest('.activity-feed-group'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var HandleItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/handleItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              RefreshGroup(activityFeedItemElement.closest('.activity-feed-group'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var UnhandleItem = function (activityFeedItemElement) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_item_id = activityFeedItemElement.data('id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/unhandleItem',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              RefreshGroup(activityFeedItemElement.closest('.activity-feed-group'))
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowOrganiseItemModal = function (activityFeedItemElement) {
    var modal = activityFeedItemElement.closest('.activity-feed-group').find('> .toolbar .modal.organise-activity-feed-item-modal')
    ResetOrganiseItemModal(modal)
    modal.find('[name="activity_feed_item_id"]').val(activityFeedItemElement.data('id'))

    modal.modal('show')
  }

  var ResetOrganiseItemModal = function (modal) {
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))
    modal.find('[name="map_to_contact"]').prop('checked', false).trigger('change')
    modal.find('[name="map_to_group"]').prop('checked', false).trigger('change')
    modal.find('[name="activity_feed_item_id"]').val('')

    var contactPartyIdElement = modal.find('[name="contact_party_id"]')
    var initialContactPartyId = contactPartyIdElement.data('initial-id')
    if (typeof initialContactPartyId !== 'undefined') {
      modal.find('[name="map_to_contact"]').prop('checked', true).trigger('change')
      contactPartyIdElement.select2('data', {
        'id': initialContactPartyId,
        'name': contactPartyIdElement.data('initial-name'),
        'html': ''
      })
    }
  }

  var OrganiseItem = function (activityFeedGroupElement) {
    var modal = activityFeedGroupElement.find('> .toolbar .modal.organise-activity-feed-item-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()

      modal.find('input[type="hidden"], input[type="text"]:not(.select2-focusser):not([type="checkbox"]):visible, input[type="checkbox"]:checked, select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/organiseItem',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')

                // Open the relevant group
                activityFeedGroupElement.data('id', data.responseJSON.redirect_activity_feed_group_id)
                RefreshGroup(activityFeedGroupElement)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var PrepareIncomingMessageAttachment = function (attachmentElement, incomingMessageAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/incomingMessageAttachment/download/' + incomingMessageAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.TemporaryStorage.Download(data.responseJSON.token, data.responseJSON.filename)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SaveIncomingMessageAttachmentToDocumentPortal = function (attachmentElement, incomingMessageAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/incomingMessageAttachment/saveToDocumentPortal/' + incomingMessageAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var PrepareOutgoingMessageAttachment = function (attachmentElement, outgoingMessageAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/outgoingMessageAttachment/download/' + outgoingMessageAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.TemporaryStorage.Download(data.responseJSON.token, data.responseJSON.filename)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SaveOutgoingMessageAttachmentToDocumentPortal = function (attachmentElement, outgoingMessageAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/outgoingMessageAttachment/saveToDocumentPortal/' + outgoingMessageAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var PrepareActivityFeedItemAttachment = function (attachmentElement, activityFeedItemAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/activityFeedItemAttachment/download/' + activityFeedItemAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.TemporaryStorage.Download(data.responseJSON.token, data.responseJSON.filename)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var SaveActivityFeedItemAttachmentToDocumentPortal = function (attachmentElement, activityFeedItemAttachmentId) {
    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    var filenameElement = attachmentElement.find('.filename')
    Onkho.LoadingTools.ShowLoading(filenameElement)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/communication/activityFeedItemAttachment/saveToDocumentPortal/' + activityFeedItemAttachmentId,
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(filenameElement)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var OpenLocalUploadModal = function (itemBuilderElement) {
    var modal = itemBuilderElement.find('.modal.local-attachment-modal')
    modal.modal('show')
  }

  var OpenDocumentPortalUploadModal = function (itemBuilderElement) {
    var modal = itemBuilderElement.find('.modal.document-portal-attachment-modal')
    modal.modal('show')
  }

  var OpenScheduleSendModal = function (itemBuilderElement) {
    var modal = itemBuilderElement.find('.modal.schedule-send-modal')
    modal.modal('show')
  }



  return {
    Init: Init,
    LoadGroup: LoadGroup,
    RefreshGroup: RefreshGroup,
    InitializeOrganiser: InitializeOrganiser,
    InitializeSnoozer: InitializeSnoozer,
    InitializeRecipientsPicker: InitializeRecipientsPicker,
    LoadActivityFeedItemBuilderPanel: LoadActivityFeedItemBuilderPanel,
    ValidateItemBuilder: ValidateItemBuilder,
    InitializeItems: InitializeItems,
    GetCreateFormWithoutContextAndRecipients: GetCreateFormWithoutContextAndRecipients
  }
}()
