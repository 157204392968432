Onkho.OnkhoTimeEntryActiveElement = function ()
{
    var Init = function ()
    {
        RegisterTriggerListeners();

        RegisterEventListeners();
    };

    var RegisterTriggerListeners = function ()
    {
        // --- Featured actions ---
        // Edit
        $('body').on('click', '.o-active-element[data-type="time_entry"] .featured-action[data-id="edit"]', function (event)
        {
            Onkho.OnkhoActiveElement.RemoveAll();
            Onkho.AddEditTimeEntryPanel.LoadAddEditTimeEntryPanel($(this).closest('.o-active-element').data('id'));
        });
    };

    var RegisterEventListeners = function ()
    {
        //
    };



    return {
        Init: Init
    };
}();
