// Module containing tools to be used when displaying an activity feed group
// wide list
Onkho.ActivityFeedGroupWideListTools = function ()
{
  var listContainer;
  var presetFiltersMap = {
    'all': {
      'status': 'all',
      'assignee': 'all',
      'archived': 'unarchived',
      'snoozed': 'unsnoozed',
      'group_type': 'all'
    },
    'important': {
      'status': 'action_required',
      'assignee': 'all',
      'archived': 'unarchived',
      'snoozed': 'unsnoozed',
      'group_type': 'all'
    },
    'important_me': {
      'status': 'action_required',
      'assignee': 'assigned_me',
      'archived': 'unarchived',
      'snoozed': 'unsnoozed',
      'group_type': 'all'
    },
    'needs_attention': {
      'status': 'no_action_required',
      'assignee': 'unassigned',
      'archived': 'unarchived',
      'snoozed': 'unsnoozed',
      'group_type': 'all'
    },
    'custom': {
      //
    },
  };

  var Init = function (mode, term, filters)
  {
    listContainer = $('.activity-feed-group-wide-list-container');

    Onkho.OnkhoWideList.Init(
      listContainer.find('.o-wide-list'),
      'activity-feed-group',
      [
        {
          id: 'mode',
          value: mode,
        }
      ],
      term,
      filters);

    RegisterListBindings();
    RegisterPaneBindings();

    // Initialize the organizer inside the organise groups modal
    var organiseGroupsModal = listContainer.find('.modal.organise-activity-feed-groups-modal')
    Onkho.ActivityFeed.InitializeOrganiser(organiseGroupsModal, null)

    // Initialize the datetime picker inside the snooze groups modal
    var snoozeGroupsModal = listContainer.find('.modal.snooze-activity-feed-groups-modal')
    Onkho.ActivityFeed.InitializeSnoozer(snoozeGroupsModal, null)
  };

  var RegisterListBindings = function ()
  {
    listContainer.on('click', '.activity-feed-group-wide-list-item .subtitle', function ()
    {
      Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane($(this).closest('.activity-feed-group-wide-list-item').data('id'));
    })

    listContainer.on('click', '.items-actions [data-id="delete"]', function () {
      ConfirmDeleteGroups()
    })

    listContainer.on('click', '.items-actions [data-id="archive"]', function () {
      ConfirmArchiveGroups()
    })

    listContainer.on('click', '.items-actions [data-id="snooze"]', function () {
      ShowSnoozeGroupsModal()
    })

    listContainer.on('click', '.modal.snooze-activity-feed-groups-modal button.save', function () {
      SnoozeGroups()
    })

    listContainer.on('click', '.items-actions [data-id="move_to_inbox"]', function () {
      ConfirmMoveToInboxGroups()
    })

    listContainer.on('click', '.items-actions [data-id="organise"]', function () {
      ShowOrganiseGroupsModal()
    })

    listContainer.on('click', '.modal.organise-activity-feed-groups-modal button.save', function () {
      OrganiseGroups()
    })

    listContainer.on('click', '.items-actions [data-id="mark_as_read"]', function () {
      ReadGroups()
    })

    listContainer.on('click', '.items-actions [data-id="mark_as_unread"]', function () {
      UnreadGroups()
    })

    listContainer.on('click', '.list-actions [data-id="mark_all_read"]', function () {
      ReadAllGroups()
    })

    listContainer.on('click', '.list-actions [data-id="mark_all_handled"]', function () {
      ConfirmHandleAllErrors()
    })

    listContainer.on('click', '.list-actions [data-id="use_items_view"]', function () {
      UseItemsView()
    })

    // Listeners for preset filters
    listContainer.on('click', '.filter-control[data-id="preset"] .dropdown li a', function (event) {
      ApplyPresetFilter($(this).data('id'))
    })

    listContainer.on('click', '.filter-control:not([data-id="preset"]) .dropdown li a', function (event) {
      ApplyPresetFilter('custom')
    })

    // Listeners for status chips
    listContainer.on('click', '.status-chip.status', function () {
      ApplyStatusFilter($(this))
    })

    listContainer.on('click', '.status-chip.assignee', function () {
      ApplyAssigneeFilter($(this))
    })

    listContainer.on('click', '.status-chip.archived', function () {
      ApplyArchivedFilter($(this))
    })

    listContainer.on('click', '.status-chip.snoozed', function () {
      ApplySnoozedFilter($(this))
    })

    // User preferences changes
    listContainer.on('onkho:wide-list[activity-feed-group].user_preferences_saved', '.o-wide-list[data-key="activity-feed-group"]', function (event, preferences) {
      HandleUserPreferencesChanges($(this), preferences)
    })
  };

  var HandleUserPreferencesChanges = function (list, preferences)
  {
    if (preferences['global'].hasOwnProperty('activity_feed_group_list_inbox_use_ascending_order')) {
      if (preferences['global'].activity_feed_group_list_inbox_use_ascending_order == 'yes') {
        // Use ascending order enabled
        Onkho.OnkhoWideList.SetOrder(list, list.find('> .toolbar > .main-row > .orders li a[data-id="DATE_ASC"]'), true);
      } else if (preferences['global'].activity_feed_group_list_inbox_use_ascending_order == 'no') {
        // Use ascending order disabled
        Onkho.OnkhoWideList.SetOrder(list, list.find('> .toolbar > .main-row > .orders li a[data-id="DATE_DESC"]'), true);
      }
    }

    Onkho.OnkhoWideList.Refresh(list)
  };

  var RegisterPaneBindings = function ()
  {

  };

  var ApplyPresetFilter = function (preset)
  {
    if (presetFiltersMap.hasOwnProperty(preset)) {
      var list = listContainer.find('.o-wide-list')
      Onkho.OnkhoWideList.ShowLoading(list)

      var filters = presetFiltersMap[preset]

      for (var filterId in filters) {
        var filterValue = filters[filterId]
        var filterElement = list.find('.filter-control[data-id="' + filterId + '"]');
        var filterValueElement = filterElement.find('.dropdown li a[data-id="' + filterValue + '"]')
        Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement, true)
      }

      var filterElement = list.find('.filter-control[data-id="preset"]');
      var filterValueElement = filterElement.find('.dropdown li a[data-id="' + preset + '"]')
      Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement, true)

      Onkho.OnkhoWideList.HideLoading(list)
      if (preset !== 'custom') {
        Onkho.OnkhoWideList.Refresh(list)
      }
    }
  };

  var ApplyStatusFilter = function (statusChip)
  {
    var list = listContainer.find('.o-wide-list')
    var statusTest = statusChip.text()
    var status = statusTest.toLowerCase().split(' ').join('_')
    var filterElement = list.find('.filter-control[data-id="status"]')
    var filterValueElement = filterElement.find('.dropdown li a[data-id="' + status + '"]')
    Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement)
    ApplyPresetFilter('custom')
    Onkho.OnkhoWideList.ShowFilters(list)
  };

  var ApplyArchivedFilter = function (statusChip)
  {
    var list = listContainer.find('.o-wide-list')
    var filterElement = list.find('.filter-control[data-id="archived"]')
    var filterValueElement = filterElement.find('.dropdown li a[data-id="archived"]')
    Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement)
    ApplyPresetFilter('custom')
    Onkho.OnkhoWideList.ShowFilters(list)
  };

  var ApplySnoozedFilter = function (statusChip)
  {
    var list = listContainer.find('.o-wide-list')
    var filterElement = list.find('.filter-control[data-id="snoozed"]')
    var filterValueElement = filterElement.find('.dropdown li a[data-id="snoozed"]')
    Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement)
    ApplyPresetFilter('custom')
    Onkho.OnkhoWideList.ShowFilters(list)
  };

  var ApplyAssigneeFilter = function (statusChip)
  {
    var list = listContainer.find('.o-wide-list')
    var filterElement = list.find('.filter-control[data-id="assignee"]')
    var filterValueElement = filterElement.find('.dropdown li select[data-id="assigned_to_party_id"]')
    filterValueElement.select2('data', {'id': statusChip.data('party-id'), 'text': statusChip.text()})
    Onkho.OnkhoWideList.SetFilter(list, filterElement, filterValueElement)
    ApplyPresetFilter('custom')
    Onkho.OnkhoWideList.ShowFilters(list)
  };

  var ConfirmDeleteGroups = function () {
    $.SmartMessageBox(
      {
        title: 'Delete labels and their contents',
        content: 'Are you sure you want to delete the selected labels and their contents?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteGroups()
        }
      }
    )
  }

  var DeleteGroups = function () {
    var list = listContainer.find('.o-wide-list');
    var deleteButton = list.find('.items-actions [data-id="delete"]')
    Onkho.LoadingTools.ShowLoading(deleteButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_ids = []
    $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
      formData.activity_feed_group_ids.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/deleteGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(deleteButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoWideList.Refresh(list)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmArchiveGroups = function () {
    ArchiveGroups()
    // Confirmation dialog disabled
    /*
    $.SmartMessageBox(
      {
        title: 'Archive labels and their contents',
        content: 'Are you sure you want to archive the selected labels and their contents? Archived labels will not be shown in your inbox by default.',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          ArchiveGroups()
        }
      }
    )*/
  }

  var ArchiveGroups = function () {
    var list = listContainer.find('.o-wide-list');
    var archiveButton = list.find('.items-actions [data-id="archive"]')
    Onkho.LoadingTools.ShowLoading(archiveButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_ids = []
    $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
      formData.activity_feed_group_ids.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/archiveGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(archiveButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoWideList.Refresh(list)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowSnoozeGroupsModal = function () {
    var modal = listContainer.find('.modal.snooze-activity-feed-groups-modal')
    ResetSnoozeGroupsModal(modal)

    modal.modal('show')
  }

  var ResetSnoozeGroupsModal = function (modal) {
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))
    modal.find('[name="unsnooze_datetime"]').datetimepicker('reset')
    modal.find('section[data-id="custom-snooze"]').removeClass('active')
    modal.find('section[data-id="quick-snoozes"] .quick-snooze.active').removeClass('active')
    modal.find('section[data-id="quick-snoozes"] .quick-snooze:first-of-type').addClass('active')
  }

  var SnoozeGroups = function () {
    var list = listContainer.find('.o-wide-list');
    var modal = listContainer.find('.modal.snooze-activity-feed-groups-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.activity_feed_group_ids = []
      $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
        formData.activity_feed_group_ids.push(listItem.data('id'))
      })
      formData.unsnooze_datetime = modal.find('[name="unsnooze_datetime"]').val();

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/snoozeGroups',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')

                Onkho.OnkhoWideList.Refresh(list)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var ConfirmMoveToInboxGroups = function () {
    $.SmartMessageBox(
      {
        title: 'Move labels and their contents to Inbox',
        content: 'Are you sure you want to move the selected labels and their contents to your Inbox? This will unarchive and unsnooze them!',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          MoveToInboxGroups()
        }
      }
    )
  }

  var MoveToInboxGroups = function () {
    var list = listContainer.find('.o-wide-list');
    var moveToInboxButton = list.find('.items-actions [data-id="move_to_inbox"]')
    Onkho.LoadingTools.ShowLoading(moveToInboxButton)

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_ids = []
    $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
      formData.activity_feed_group_ids.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/moveToInboxGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          Onkho.LoadingTools.HideLoading(moveToInboxButton)

          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoWideList.Refresh(list)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ShowOrganiseGroupsModal = function () {
    var modal = listContainer.find('.modal.organise-activity-feed-groups-modal')
    ResetOrganiseGroupsModal(modal)

    modal.modal('show')
  }

  var ResetOrganiseGroupsModal = function (modal) {
    Onkho.Validator.ResetChildrenValidation(modal.find('form'))
    modal.find('[name="map_to_contact"]').prop('checked', false).trigger('change')
    modal.find('[name="map_to_group"]').prop('checked', false).trigger('change')
  }

  var OrganiseGroups = function () {
    var list = listContainer.find('.o-wide-list');
    var modal = listContainer.find('.modal.organise-activity-feed-groups-modal')
    if (Onkho.Validator.ValidateChildren(modal.find('form'))) {
      Onkho.LoadingTools.ShowLoading(modal.find('button.save'))

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.source_activity_feed_group_ids = []
      $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
        formData.source_activity_feed_group_ids.push(listItem.data('id'))
      })

      modal.find('input[type="hidden"], input[type="text"]:not(.select2-focusser):not([type="checkbox"]):visible, input[type="checkbox"]:checked, select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/activityFeed/organiseGroups',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            Onkho.LoadingTools.HideLoading(modal.find('button.save'))

            switch (data.status) {
              case 200:
                Onkho.Alert.SmallBox('success', data.responseJSON.message)
                modal.modal('hide')

                Onkho.OnkhoWideList.Refresh(list)
                break

              default:
                Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var ConfirmHandleAllErrors = function () {
    $.SmartMessageBox(
      {
        title: 'Mark all errors as handled',
        content: 'Are you sure you want to mark all the errors as handled?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          HandleAllErrors()
        }
      }
    )
  }

  var HandleAllErrors = function () {
    Onkho.Alert.SmallBox('info', 'We are now marking all of your errors as handled. This may take a little while.')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/handleAllErrors',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ReadAllGroups = function () {
    Onkho.Alert.SmallBox('info', 'We are now marking all of your items as read. This may take a little while.')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/readAllGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ReadGroups = function () {
    var list = listContainer.find('.o-wide-list');

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_ids = []
    $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
      formData.activity_feed_group_ids.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/readGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoWideList.Refresh(list)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var UnreadGroups = function () {
    var list = listContainer.find('.o-wide-list');

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.activity_feed_group_ids = []
    $(Onkho.OnkhoWideList.GetSelected(list)).each(function (index, listItem) {
      formData.activity_feed_group_ids.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/activityFeed/unreadGroups',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoWideList.Refresh(list)
              Onkho.InboxLiveCount.RefreshCounts()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var UseItemsView = function ()
  {
    Onkho.Alert.SmallBox('info', 'Switching to items view..')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.preferences = [
      {
        'preference': 'activity_feed_group_list_inbox_view_mode',
        'value': 'items',
        'context': null
      }
    ];

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/preference',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              window.location = window.location.href.split('#')[0];
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }



  return {
    Init: Init,
  };
}();
