Onkho.AddEditMailboxScanningConfigurationNylasPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add mailbox secondary panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration_nylas"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  };

  var LoadAddEditMailboxScanningConfigurationNylasPanel = function (mailboxId)
  {
    var additionalData = {
      'partyId': Onkho.Variables.PartyId
    };

    if (mailboxId) {
      additionalData.mailboxId = mailboxId;
    }

    Onkho.OnkhoPanel.Add('add_edit_mailbox_scanning_configuration_nylas', additionalData, AddEditMailboxScanningConfigurationNylasPanelLoaded)
  };

  var AddEditMailboxScanningConfigurationNylasPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);
  };

  var GatherMailboxConfiguration = function ()
  {
    var configuration = {};

    var panel;
    if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
      panel = $('.o-panel[data-panel-key="add_mailbox"]');
    } else {
      panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    }

    panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      configuration[$(input).attr('name')] = $(input).val()
    })

    var scanningConfigurationPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration_nylas"]');
    if (scanningConfigurationPanel.data('mailbox-id')) {
      configuration.mailbox_id = scanningConfigurationPanel.data('mailbox-id');
    }

    return configuration;
  }

  var RegisterAddEditMailboxScanningConfigurationNylasPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
        Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_mailbox"]'));
      } else {
        Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="edit_mailbox"]'));
      }
    });
    
    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditMailbox();
    });
  };

  var AddEditMailbox = function ()
  {
    var panel;
    if ($('.o-panel[data-panel-key="add_mailbox"]').length) {
      panel = $('.o-panel[data-panel-key="add_mailbox"]');
    } else {
      panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    }

    var scanningConfigurationPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration_nylas"]');

    if (Onkho.Validator.ValidateChildren(scanningConfigurationPanel))
    {
      Onkho.LoadingTools.ShowLoading(scanningConfigurationPanel.find('button[data-id="save"]'));

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();

      var url;
      if (panel.data('panel-key') === 'add_mailbox') {
        url = Onkho.Variables.BaseURL + '/mailbox/addMailbox';
      } else {
        url = Onkho.Variables.BaseURL + '/mailbox/updateMailbox';
      }

      formData = Object.assign(formData, GatherMailboxConfiguration());

      scanningConfigurationPanel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="radio"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
        formData[$(input).attr('name')] = $(input).val()
      })

      formData.signature = panel.find('[data-name="signature"]').html()
      formData.disclaimer = panel.find('[data-name="disclaimer"]').html()


      $.ajax(
        {
          type: 'POST',
          url: url,
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            Onkho.LoadingTools.HideLoading(scanningConfigurationPanel.find('button[data-id="save"]'));

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.RemoveAll();

                Onkho.Alert.SmallBox('success', data.responseJSON.message);

                Onkho.OnkhoList.Refresh($('.o-list[data-id="mailboxes"]'));
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your mailbox', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
                break;
            }
          }
        });
    }
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_mailbox_scanning_configuration_nylas].added', '.o-panel-container', function ()
    {
      var AddEditMailboxScanningConfigurationNylasPanel = $('.o-panel[data-panel-key="add_edit_mailbox_scanning_configuration_nylas"]');
      RegisterAddEditMailboxScanningConfigurationNylasPanelBindings(AddEditMailboxScanningConfigurationNylasPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditMailboxScanningConfigurationNylasPanel: LoadAddEditMailboxScanningConfigurationNylasPanel
  };
}();
