Onkho.EditMailboxPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    $('body').on('change', '.o-panel[data-panel-key="edit_mailbox"] form [name="is_used_for_outgoing_messages"]', function ()
    {
      ToggleMakeDefaultSection($(this).is(':checked'))
    });

    // Prevent ENTER from refreshing the page in the edit mailbox panel
    $('body').on('keypress', '.o-panel[data-panel-key="edit_mailbox"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault();
      }
    });
  };

  var ToggleMakeDefaultSection = function (show)
  {
    var panel = $('.o-panel[data-panel-key="edit_mailbox"]')

    if (show) {
      panel.find('.panel-section[data-id="make-default"]').removeClass('hidden')
    } else {
      panel.find('.panel-section[data-id="make-default"]').addClass('hidden')
    }
  };

  var LoadEditMailboxPanel = function (mailboxId)
  {
    var additionalData = {
      'mailboxId': mailboxId
    };

    Onkho.OnkhoPanel.Add('edit_mailbox', additionalData, EditMailboxPanelLoaded)
  };

  var EditMailboxPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
    Onkho.Validator.AddChildren(panel);

    panel.find('select').select2();

    // Restore state
    panel.find('[name="is_used_for_outgoing_messages"]').trigger('change')
  };

  var RegisterEditMailboxPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      UpdateMailbox();
    });

    panel.find('.panel-opener[data-id="additional"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditMailboxAdditionalPanel.LoadAddEditMailboxAdditionalPanel(panel.find('[data-name="signature"]').html(), panel.find('[data-name="disclaimer"]').html());
    });
  };

  var SetSignature = function (content)
  {
    var panel = $('.o-panel[data-panel-key="edit_mailbox"]');

    var section = panel.find('.panel-section[data-id="additional"]');
    if (content.length > 0) {
      section.find('.signature').removeClass('hidden');
    } else {
      section.find('.signature').addClass('hidden');
    }

    section.find('.content[data-name="signature"]').html(content);
  };

  var SetDisclaimer = function (content)
  {
    var panel = $('.o-panel[data-panel-key="edit_mailbox"]');

    var section = panel.find('.panel-section[data-id="additional"]');
    if (content.length > 0) {
      section.find('.disclaimer').removeClass('hidden');
    } else {
      section.find('.disclaimer').addClass('hidden');
    }

    section.find('.content[data-name="disclaimer"]').html(content);
  };

  var UpdateMailbox = function ()
  {
    var panel = $('.o-panel[data-panel-key="edit_mailbox"]');

    if (Onkho.Validator.ValidateChildren(panel))
    {
      var type = panel.data('type')
      if (type === 'ONKHO') {
        // This type of mailbox doesn't need a secondary panel
        PersistMailboxChanges()
      } else {
        Onkho.OnkhoPanel.Disable(panel)


        if (type === 'MICROSOFT') {
          Onkho.AddEditMailboxScanningConfigurationNylasPanel.LoadAddEditMailboxScanningConfigurationNylasPanel(panel.data('mailbox-id'))
        } else {
          var isScannedForIncomingMessages = panel.find('[name="is_scanned_for_incoming_messages"]').is(':checked') ? 1 : 0;
          var isUsedForOutgoingMessages = panel.find('[name="is_used_for_outgoing_messages"]').is(':checked') ? 1 : 0;

          Onkho.AddEditMailboxSecondaryPanel.LoadAddEditMailboxSecondaryPanel(panel.data('mailbox-id'), type, isScannedForIncomingMessages, isUsedForOutgoingMessages)
        }
      }
    }
  };

  var PersistMailboxChanges = function ()
  {
    var panel = $('.o-panel[data-panel-key="edit_mailbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'));

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.mailbox_id = panel.data('mailbox-id');

    panel.find('input[type="text"]:visible:not(.select2-focusser), input[type="password"]:visible, input[type="checkbox"]:visible:checked, input[type="hidden"], select:visible').each(function (index, input) {
      formData[$(input).attr('name')] = $(input).val()
    })

    formData.signature = panel.find('[data-name="signature"]').html()
    formData.disclaimer = panel.find('[data-name="disclaimer"]').html()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/mailbox/updateMailbox',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

          switch (data.status)
          {
            case 200:
              Onkho.OnkhoPanel.RemoveAll();

              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              Onkho.OnkhoList.Refresh($('.o-list[data-id="mailboxes"]'));
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, 'We were not able to save your mailbox', Onkho.Functions.ConcatenateErrors(data.responseJSON.errors));
              break;
          }
        }
      });
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[edit_mailbox].added', '.o-panel-container', function ()
    {
      var editMailboxPanel = $('.o-panel[data-panel-key="edit_mailbox"]');
      RegisterEditMailboxPanelBindings(editMailboxPanel);
    });
  };



  return {
    Init: Init,
    LoadEditMailboxPanel: LoadEditMailboxPanel,
    SetSignature: SetSignature,
    SetDisclaimer: SetDisclaimer
  };
}();
