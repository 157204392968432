// Module containing tools to be used when displaying a services list
Onkho.ServicesListTools = function () {

  var servicesList = null;

  var editServiceModal = null;
  var editServiceForm  = null;
  var serviceManager   = null;

  var initEditServiceModal = function() {
    var fieldsetServiceMatter = editServiceModal.find('fieldset.service-matter')
    var fieldsetServiceMessages = editServiceModal.find('fieldset.service-messages')

    var sectionServiceNotifications = fieldsetServiceMessages.find('section.service-messages')
    var sectionUpdateNotifications = fieldsetServiceMessages.find('section.update-messages')

    var serviceInstanceId = editServiceForm.find('input[name="service_instance_id"]')
    var inputServiceMatter = fieldsetServiceMatter.find('input[type="text"][name="matter"]')
    var buttonUpdateServiceNotifications = sectionUpdateNotifications.find('button[name="update-service-messages"]')
    var buttonCancelServiceNotifications = sectionUpdateNotifications.find('button[name="cancel-service-messages"]')
    var updateServiceManagerButton = editServiceForm.find('button[name="update-service-manager"]')
    var cancelServiceManagerButton = editServiceForm.find('button[name="cancel-service-manager"]')
    var selectServiceManager = editServiceForm.find('section.update-service-manager').find('select[name="service_manager_id"]')

    var startNotificationInputWrapper = sectionServiceNotifications.find('div.start-message-input')
    var startNotificationBuilder = startNotificationInputWrapper.find('.activity-feed-item-builder')
    Onkho.ActivityFeed.InitializeRecipientsPicker(startNotificationBuilder, 'OUTGOING_EMAIL')

    var completeNotificationInputWrapper = sectionServiceNotifications.find('div.complete-message-input')
    var completeNotificationBuilder = completeNotificationInputWrapper.find('.activity-feed-item-builder')
    Onkho.ActivityFeed.InitializeRecipientsPicker(completeNotificationBuilder, 'OUTGOING_EMAIL')

    var cancelServiceManager = function () {
      editServiceModal.find('section.update-service-manager').hide()
      editServiceModal.find('section.current-service-manager').show()

      cancelServiceManagerButton.hide()
      updateServiceManagerButton.show()
    };

    var cancelServiceNotifications = function() {

      // Start message
      var startNotificationPreviewWrapper = sectionServiceNotifications.find('div.start-message-preview')
      var startNotificationInputWrapper = sectionServiceNotifications.find('div.start-message-input')
      var startNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_subject"]')
      var startNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_content"]')
      var startNotificationBuilder = startNotificationInputWrapper.find('.activity-feed-item-builder')

      // Complete message
      var completeNotificationPreviewWrapper = sectionServiceNotifications.find('div.complete-message-preview')
      var completeNotificationInputWrapper = sectionServiceNotifications.find('div.complete-message-input')
      var completeNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_subject"]')
      var completeNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_content"]')
      var completeNotificationBuilder = completeNotificationInputWrapper.find('.activity-feed-item-builder')

      Onkho.Summernote.DestroySummernote(startNotificationBuilder.find('.summernote'));
      startNotificationPreviewWrapper.find('span.service-start-subject').html(startNotificationSubjectInputField.val())
      startNotificationPreviewWrapper.find('span.service-start-content').html(decodeURIComponent(startNotificationContentInputField.val()));

      Onkho.Summernote.DestroySummernote(completeNotificationBuilder.find('.summernote'));
      completeNotificationPreviewWrapper.find('span.service-complete-subject').html(completeNotificationSubjectInputField.val())
      completeNotificationPreviewWrapper.find('span.service-complete-content').html(decodeURIComponent(completeNotificationContentInputField.val()));

      startNotificationPreviewWrapper.show();
      completeNotificationPreviewWrapper.show();

      startNotificationInputWrapper.hide();
      completeNotificationInputWrapper.hide();

      buttonUpdateServiceNotifications.show();
      buttonCancelServiceNotifications.hide();
    };

    var commitServiceNotifications = function(callback) {

      // Start message
      var startNotificationPreviewWrapper = sectionServiceNotifications.find('div.start-message-preview')
      var startNotificationInputWrapper = sectionServiceNotifications.find('div.start-message-input')
      var startNotificationFromMailboxIdInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_from_mailbox_id"]')
      var startNotificationAddressInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_address"]')
      var startNotificationPracticeNameInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_practice_name"]')
      var startNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_subject"]')
      var startNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_content"]')
      var startNotificationBuilder = startNotificationInputWrapper.find('.activity-feed-item-builder')
      var startNotificationFromMailboxId = startNotificationBuilder.find('[name="from_mailbox_id"]')
      var startNotificationSubject = startNotificationBuilder.find('[name="subject"]')
      var startNotificationContent = startNotificationBuilder.find('.summernote.content-area')

      // Complete message
      var completeNotificationPreviewWrapper = sectionServiceNotifications.find('div.complete-message-preview')
      var completeNotificationInputWrapper = sectionServiceNotifications.find('div.complete-message-input')
      var completeNotificationFromMailboxIdInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_from_mailbox_id"]')
      var completeNotificationAddressInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_address"]')
      var completeNotificationPracticeNameInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_practice_name"]')
      var completeNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_subject"]')
      var completeNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_content"]')
      var completeNotificationBuilder = completeNotificationInputWrapper.find('.activity-feed-item-builder')
      var completeNotificationFromMailboxId = completeNotificationBuilder.find('[name="from_mailbox_id"]')
      var completeNotificationSubject = completeNotificationBuilder.find('[name="subject"]')
      var completeNotificationContent = completeNotificationBuilder.find('.summernote.content-area')

      // Wait for both requests to return...
      Promise.all([
        new Promise(function(resolve, reject) {
          if (Onkho.Summernote.IsSummernoteEnabled(startNotificationContent)) {
            Onkho.Functions.Purify(Onkho.Summernote.GetSummernoteValue(startNotificationContent),
              function(data) {
                Onkho.Summernote.DestroySummernote(startNotificationContent);

                startNotificationFromMailboxIdInputField.val(startNotificationFromMailboxId.select2('data').id);
                startNotificationAddressInputField.val(startNotificationFromMailboxId.select2('data').address);
                startNotificationPracticeNameInputField.val(startNotificationFromMailboxId.select2('data').practice_name);
                startNotificationSubjectInputField.val(startNotificationSubject.val())
                startNotificationContentInputField.val(encodeURIComponent(data));

                startNotificationPreviewWrapper.find('span.service-start-subject').html(startNotificationSubject.val())
                startNotificationPreviewWrapper.find('span.service-start-content').html(data);
                resolve();
              }
            );
          } else {
            resolve();
          }
        }),
        new Promise(function(resolve, reject) {
          if (Onkho.Summernote.IsSummernoteEnabled(completeNotificationContent)) {
            Onkho.Functions.Purify(Onkho.Summernote.GetSummernoteValue(completeNotificationContent),
              function(data) {
                Onkho.Summernote.DestroySummernote(completeNotificationContent);

                completeNotificationFromMailboxIdInputField.val(completeNotificationFromMailboxId.select2('data').id);
                completeNotificationAddressInputField.val(completeNotificationFromMailboxId.select2('data').address);
                completeNotificationPracticeNameInputField.val(completeNotificationFromMailboxId.select2('data').practice_name);
                completeNotificationSubjectInputField.val(completeNotificationSubject.val())
                completeNotificationContentInputField.val(encodeURIComponent(data));

                completeNotificationPreviewWrapper.find('span.service-complete-subject').html(completeNotificationSubject.val())
                completeNotificationPreviewWrapper.find('span.service-complete-content').html(data);
                resolve();
              }
            );
          } else {
            resolve();
          }
        })
      ]).then(
        function(values) {
          if (callback && typeof callback === 'function') {
            callback();
          }
        }
      );

      startNotificationPreviewWrapper.show();
      completeNotificationPreviewWrapper.show();

      startNotificationInputWrapper.hide();
      completeNotificationInputWrapper.hide();

      buttonUpdateServiceNotifications.show();
      buttonCancelServiceNotifications.hide();
    };

    var resetForm = function() {
      Onkho.Validator.ResetChildrenValidation(editServiceForm);
      cancelServiceNotifications();
      cancelServiceManager();

      editServiceModal.find('div.tip')
        .html();

      inputServiceMatter
        .val('')
        .attr('placeholder', editServiceModal.find('input[name="service_instance_id"]').data('service_name'))
      ;
    };

    var initCancelEditServiceInstance = function() {
      var cancelButton = editServiceModal.find('div.modal-content div.modal-footer button[name="cancel"]');
      cancelButton.click(
        function() {
          editServiceModal.trigger('clearInput').modal('hide');
        }
      );
    };

    var initSaveServiceInstance = function() {

      var saveServiceInstance = function () {
        // Ensure service start and end messages have been committed prior to submitting the form.
        commitServiceNotifications(function () {
          if (Onkho.Validator.ValidateChildren(editServiceForm)) {
            var valid = Onkho.Validator.ValidateChildren(editServiceForm)
            if (valid) {
              var formData = {}
              formData._token = Onkho.Functions.GetCSRF()
              formData.id = serviceInstanceId.val()
              formData.matter = inputServiceMatter.val()
              formData.service_start_from_mailbox_id = sectionServiceNotifications.find('input[name="service_start_from_mailbox_id"]').val()
              formData.service_start_address = sectionServiceNotifications.find('input[name="service_start_address"]').val()
              formData.service_start_practice_name = sectionServiceNotifications.find('input[name="service_start_practice_name"]').val()
              formData.service_start_subject = sectionServiceNotifications.find('input[name="service_start_subject"]').val()
              formData.service_start_content = decodeURIComponent(sectionServiceNotifications.find('input[name="service_start_content"]').val())
              formData.service_complete_from_mailbox_id = sectionServiceNotifications.find('input[name="service_complete_from_mailbox_id"]').val()
              formData.service_complete_address = sectionServiceNotifications.find('input[name="service_complete_address"]').val()
              formData.service_complete_practice_name = sectionServiceNotifications.find('input[name="service_complete_practice_name"]').val()
              formData.service_complete_subject = sectionServiceNotifications.find('input[name="service_complete_subject"]').val()
              formData.service_complete_content = decodeURIComponent(sectionServiceNotifications.find('input[name="service_complete_content"]').val())
              formData.sends_notifications = fieldsetServiceMessages.find('input[type="checkbox"][name="sends_notifications"]').is(':checked')
              formData.service_manager_id = parseInt(editServiceModal.find('select[name="service_manager_id"]').val())

              $.ajax({
                type: 'PUT',
                url: Onkho.Variables.BaseURL + '/serviceInstance/' + formData.id,
                data: formData,
                dataType: 'json'
              }).done(
                function (data) {
                  Onkho.Alert.SmallBox('success', data.message)
                  editServiceModal.trigger('clearInput').modal('hide')
                  ResetServicesList()
                  LoadMore()
                }
              ).fail(
                function (jqXHR, textStatus, errorThrown) {
                  var data = jqXHR.responseJSON
                  switch (jqXHR.status) {
                    case 400 : {
                      Onkho.Alert.SmallBox('warning', data.message)
                      break
                    }

                    case 422 : {
                      Onkho.Alert.SmallBox('warning', 'Please check your input and try again.')
                      if (data['matter']) {
                        Onkho.Validator.StyleInvalid(
                          inputServiceMatter, data['matter'][0]
                        )
                      }

                      break
                    }

                    default : {
                      Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to save job', data['message'] ? data['message'] : 'We were unable to process your request.')
                      break
                    }
                  }
                }
              ).always(
                function () {
                  Onkho.LoadingTools.HideLoading(submitButton)
                }
              )
            } else {
              Onkho.LoadingTools.HideLoading(submitButton)
              Onkho.Alert.SmallBox('warning', 'Please check your input and try again.')
            }
          } else {
            Onkho.LoadingTools.HideLoading(submitButton)
            Onkho.Alert.SmallBox('warning', 'Please check your input and try again.')
          }
        });
      };

      var submitButton = editServiceModal.find('div.modal-content div.modal-footer button[name="save"]');
      submitButton.click(
        function() {
          Onkho.LoadingTools.ShowLoading(submitButton);
          if (startNotificationBuilder.is(':visible')) {
            Onkho.ActivityFeed.ValidateItemBuilder(startNotificationBuilder, function () {
              Onkho.ActivityFeed.ValidateItemBuilder(completeNotificationBuilder, function () {
                saveServiceInstance();
              }, function () {
                Onkho.LoadingTools.HideLoading(submitButton);
                Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
              })
            }, function () {
              Onkho.LoadingTools.HideLoading(submitButton);
              Onkho.Alert.SmallBox('warning', 'Please check your input and try again.');
            })
          } else {
            saveServiceInstance();
          }
        }
      );
    };

    buttonUpdateServiceNotifications.on('click',
      function() {
        var self = $(this);

        // Start message
        var startNotificationPreviewWrapper = sectionServiceNotifications.find('div.start-message-preview')
        var startNotificationInputWrapper = sectionServiceNotifications.find('div.start-message-input')
        var startNotificationFromMailboxIdInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_from_mailbox_id"]')
        var startNotificationAddressInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_address"]')
        var startNotificationPracticeNameInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_practice_name"]')
        var startNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_subject"]')
        var startNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_start_content"]')
        var startNotificationBuilder = startNotificationInputWrapper.find('.activity-feed-item-builder')
        var startNotificationFromMailboxId = startNotificationBuilder.find('[name="from_mailbox_id"]')
        var startNotificationSubject = startNotificationBuilder.find('[name="subject"]')
        var startNotificationContent = startNotificationBuilder.find('.summernote.content-area')

        // Complete message
        var completeNotificationPreviewWrapper = sectionServiceNotifications.find('div.complete-message-preview')
        var completeNotificationInputWrapper = sectionServiceNotifications.find('div.complete-message-input')
        var completeNotificationFromMailboxIdInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_from_mailbox_id"]')
        var completeNotificationAddressInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_address"]')
        var completeNotificationPracticeNameInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_practice_name"]')
        var completeNotificationSubjectInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_subject"]')
        var completeNotificationContentInputField = sectionServiceNotifications.find('input[type="hidden"][name="service_complete_content"]')
        var completeNotificationBuilder = completeNotificationInputWrapper.find('.activity-feed-item-builder')
        var completeNotificationFromMailboxId = completeNotificationBuilder.find('[name="from_mailbox_id"]')
        var completeNotificationSubject = completeNotificationBuilder.find('[name="subject"]')
        var completeNotificationContent = completeNotificationBuilder.find('.summernote.content-area')

        // Populate fields with existing values
        if (startNotificationFromMailboxIdInputField.val()) {
          startNotificationFromMailboxId.select2('data', {
            id: startNotificationFromMailboxIdInputField.val(),
            address: startNotificationAddressInputField.val(),
            practice_name: startNotificationPracticeNameInputField.val()
          });
        }
        startNotificationSubject.val(startNotificationSubjectInputField.val())
        Onkho.Summernote.UpdateSummernote(
          startNotificationContent,
          decodeURIComponent(startNotificationContentInputField.val())
        )

        if (completeNotificationFromMailboxIdInputField.val()) {
          completeNotificationFromMailboxId.select2('data', {
            id: completeNotificationFromMailboxIdInputField.val(),
            address: completeNotificationAddressInputField.val(),
            practice_name: completeNotificationPracticeNameInputField.val()
          });
        }
        completeNotificationSubject.val(completeNotificationSubjectInputField.val())
        Onkho.Summernote.UpdateSummernote(
          completeNotificationContent,
          decodeURIComponent(completeNotificationContentInputField.val())
        )

        startNotificationPreviewWrapper.hide();
        completeNotificationPreviewWrapper.hide();

        startNotificationInputWrapper.show();
        completeNotificationInputWrapper.show();

        buttonUpdateServiceNotifications.hide();
        buttonCancelServiceNotifications.show();
      }
    );

    buttonCancelServiceNotifications.on('click',
      function() {
        cancelServiceNotifications();
      }
    );

    // Slide down Service manager dropdown list
    updateServiceManagerButton.click(function () {
      editServiceModal.find('section.update-service-manager').show();
      editServiceModal.find('section.current-service-manager').hide();
      cancelServiceManagerButton.show();
      selectServiceManager.val(serviceManager.id).change();
      $(this).hide();
    });

    // Slide up Service manager dropdown list
    cancelServiceManagerButton.click(function () {
      cancelServiceManager();
    });

    // This event is responsible for clearing all input.
    editServiceModal.on('clearInput',
      function() {
        resetForm();
      }
    );

    fieldsetServiceMessages.find('input[type="checkbox"][name="sends_notifications"]').change(function () {
      if ($(this).is(':checked')){
        sectionServiceNotifications.slideDown(function() {
          sectionUpdateNotifications.show();
        });
      } else {
        sectionUpdateNotifications.hide();
        sectionServiceNotifications.slideUp(function() {
          cancelServiceNotifications();
        });
      }
    });

    initCancelEditServiceInstance();
    initSaveServiceInstance();

  };

  var Init = function () {

    servicesList     = $('.services-list');
    editServiceModal = $('.modal.edit-service-instance');
    editServiceForm  = editServiceModal.find('form.service-instance-registration-form ');

    initEditServiceModal();

    // Service action buttons
    servicesList.on('mouseenter', '.service', function ()
    {
      var buttons = $(this).find('.service-action-buttons');
      buttons.finish();
      buttons.fadeIn(150);
    });

    servicesList.on('mouseleave', '.service', function ()
    {
      var buttons = $(this).find('.service-action-buttons');
      buttons.finish();
      buttons.fadeOut(150);
    });

    // Select all
    $('input[name="select_all"]').on('change', function ()
    {
      if ($(this).is(':checked'))
      {
        SelectAll();
      }
      else
      {
        DeselectAll();
      }
    });

    // Selected services counter
    servicesList.on('change', 'input[type="checkbox"]', function ()
    {
      UpdateTotalSelected();
    });

    // Sort
    servicesList.find('.sort-control .dropdown li a').on('click', function ()
    {
      var current = servicesList.find('.sort-control .dropdown .current');
      current.html($(this).html());
      current.data('value', $(this).data('value'));

      ResetServicesList();
      LoadMore();
    });

    // Filter
    servicesList.find('.filter-control .dropdown li a').on('click', function ()
    {
      var current = servicesList.find('.filter-control .dropdown .current');
      current.html($(this).html());
      current.data('value', $(this).data('value'));

      ResetServicesList();
      LoadMore();
    });

    // Actions
    $('body').on('click', '.services-list .service .service-action-buttons .stop', function ()
    {
      var services = $(this).closest('.service');
      StopProviding(services);
    });

    // Global actions
    servicesList.find('.global-actions').on('click', '.stop', function ()
    {
      StopProviding(GetSelected());
    });

    // Infinity scroll
    LoadMore(true);

    $('body').on('click', '.services-list .btn.load-more', function ()
    {
      if (!$(this).hasClass('disabled'))
      {
        LoadMore();
      }
    });

    $('body').on('click', 'a.edit-service-definition', function () {
      editServiceModal.trigger('clearInput').modal('hide');
    });

    $(window).on('scroll', function()
    {
      if ($(window).scrollTop() > ($(document).height() - $(window).height() - 200))
      {
        if (servicesList.find('.btn.load-more').length && !servicesList.find('.btn.load-more').hasClass('disabled'))
        {
          LoadMore();
        }
      }
    }).scroll();

    // Refresh
    servicesList.find('.refresh').on('click', function ()
    {
      if (!servicesList.find('.btn.load-more').hasClass('disabled'))
      {
        ResetServicesList();
        LoadMore();
      }
    });

    // Initialize panels
    Onkho.AddServiceInstancePanel.Init()
    Onkho.AddServiceInstanceAssigneeSubpanel.Init()
    Onkho.AddServiceInstanceAutoplanSubpanel.Init()
    Onkho.AddServiceInstanceCommunicationsSubpanel.Init()

    $('.add-service').click(
      function(event) {
        event.preventDefault();
        Onkho.AddServiceInstancePanel.LoadAddServiceInstancePanel(Onkho.Variables.PartyId)
      }
    );

    Onkho.EditServiceInstancePanel.Init()
    Onkho.EditServiceInstanceAssigneeSubpanel.Init()
    Onkho.EditServiceInstanceCommunicationsSubpanel.Init()

    $('.services-list').on('click', '.service-name',
      function(event) {
        event.preventDefault();
        Onkho.EditServiceInstancePanel.LoadEditServiceInstancePanel(Onkho.Variables.PartyId, $(this).closest('.service').data('id'))
      }
    );

    Onkho.FormEnhancements.Init();

    // Edit service instance
    servicesList.on('click', '.service a.service-name', function ()
    {
      // Replaced with panel in ON-822
      //EditServiceInstance($(this).closest('.service').data('id'));
    });
  };

  var GetSelected = function ()
  {
    var services = [];
    servicesList.find('.service input[type="checkbox"]:checked').each(function ()
    {
      services.push($(this).closest('.service'));
    });

    return services;
  };

  var SelectAll = function ()
  {
    servicesList.find('.service input[type="checkbox"]').prop('checked', true);
    Onkho.Variables.SelectAllServices = true;
  };

  var DeselectAll = function ()
  {
    servicesList.find('.service input[type="checkbox"]').prop('checked', false);
    Onkho.Variables.SelectAllServices = false;
  };

  var UpdateTotalSelected = function ()
  {
    var globalActions = servicesList.find('.global-actions');

    var total = parseInt(servicesList.find('.service input[type="checkbox"]:checked').length);
    globalActions.find('label').html('Selected ' + total + ' service' + (total != 1 ? 's' : ''));

    if (total > 0)
    {
      globalActions.finish();
      globalActions.fadeIn(150).css('display', 'inline-block');
    }
    else
    {
      globalActions.finish();
      globalActions.fadeOut(150);
    }
  };

  var HideControls = function (container)
  {
    container.find('.service-action-buttons').html('<i class="fa fa-spin fa-spinner"></i>');
  };

  var ShowControls = function (container)
  {
    // Once a service has stopped being provided, there are no controls
    // left to show
    container.find('.service-action-buttons').html('');
  };

  var EditServiceInstance = function (serviceInstanceId)
  {
    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();

    formData.id = serviceInstanceId;

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/serviceInstance/getDetails',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              PopulateEditServiceInstanceModal(data.responseJSON);
              editServiceModal.trigger('clearInput').modal('show');
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });

  };

  var PopulateEditServiceInstanceModal = function (data) {
    editServiceModal.find('.name-container').text(data.name);
    editServiceModal.find('div.tip').html('To change more about how this service works, such as its schedule, requirements, and steps, click <a href="' + data.edit_service_link + '" target="_blank" class="edit-service-definition"><strong>here</strong></a>.');
    editServiceModal.find('input[name="service_instance_id"]').val(data.id).data('service_name', data.name);
    editServiceModal.find('input[name="service_start_from_mailbox_id"]').val(((data.startNotificationActivityFeedItemConfiguration.from_mailbox_id) ? data.startNotificationActivityFeedItemConfiguration.from_mailbox_id : ''));
    editServiceModal.find('input[name="service_start_address"]').val(((data.startNotificationActivityFeedItemConfiguration.address) ? data.startNotificationActivityFeedItemConfiguration.address : ''));
    editServiceModal.find('input[name="service_start_practice_name"]').val(((data.startNotificationActivityFeedItemConfiguration.practice_name) ? data.startNotificationActivityFeedItemConfiguration.practice_name : ''));
    editServiceModal.find('input[name="service_start_subject"]').val(((data.startNotificationActivityFeedItemConfiguration.subject) ? data.startNotificationActivityFeedItemConfiguration.subject : ''));
    editServiceModal.find('input[name="service_start_content"]').val(((data.startNotificationActivityFeedItemConfiguration.content) ? encodeURIComponent(data.startNotificationActivityFeedItemConfiguration.content) : ''));
    editServiceModal.find('input[name="service_complete_from_mailbox_id"]').val(((data.completeNotificationActivityFeedItemConfiguration.from_mailbox_id) ? data.completeNotificationActivityFeedItemConfiguration.from_mailbox_id : ''));
    editServiceModal.find('input[name="service_complete_address"]').val(((data.completeNotificationActivityFeedItemConfiguration.address) ? data.completeNotificationActivityFeedItemConfiguration.address : ''));
    editServiceModal.find('input[name="service_complete_practice_name"]').val(((data.completeNotificationActivityFeedItemConfiguration.practice_name) ? data.completeNotificationActivityFeedItemConfiguration.practice_name : ''));
    editServiceModal.find('input[name="service_complete_subject"]').val(((data.completeNotificationActivityFeedItemConfiguration.subject) ? data.completeNotificationActivityFeedItemConfiguration.subject : ''));
    editServiceModal.find('input[name="service_complete_content"]').val(((data.completeNotificationActivityFeedItemConfiguration.content) ? encodeURIComponent(data.completeNotificationActivityFeedItemConfiguration.content) : ''));
    editServiceModal.find('input[type="checkbox"][name="sends_notifications"]').prop('checked', data.sendsNotifications).change();
    initServiceManager(data);
  };

  // Select initial service manager and update description
  var initServiceManager = function (data) {

    serviceManager = {
      id   : 0,
      name : ''
    };

    var options = '';
    options = '<option value="0">No One</option>';
    editServiceModal.find('select[name="service_manager_id"]').select2({
      placeholder: 'No One',
      sortResults: Onkho.Functions.Select2Sort
    });

    if(data.serviceManager != null){
      serviceManager.id   = data.serviceManager.id;
      serviceManager.name = data.serviceManager.service_manager_name;
      updateServiceManagerDescription(serviceManager,editServiceModal.find('span.service-manager-description'));
    }else{
      updateServiceManagerDescription(serviceManager,editServiceModal.find('span.service-manager-description'));
    }

    editServiceModal.find('select[name="service_manager_id"]').empty().append(
      function() {
        $.each(data.members,
          function(key, data) {
            options += '<option value="' + key + '">' + data + '</option>';
          }
        );
        return options;
      }).val(serviceManager.id).change();


  };


  var updateServiceManagerDescription = function (data, element) {
    if(data.id != 0){
      var profile = Onkho.Variables.BaseURL+'/party/'+data.id;
      element.html(
        "<a target='_blank' href='"+profile+"'>"+data.name+"</a> is currently assigned as the service manager for this service."
      );
    }else{
      element.html("A service manager has not been assigned to this service.");
    }
  };


  var UpdateStatus = function (service, className, status)
  {
    var serviceStatus = service.find('.service-status');
    serviceStatus.removeClass().addClass('label service-status service-status-' + className);
    serviceStatus.html(status);
  };

  var StopProviding = function (services)
  {
    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();

    formData.services = [];
    $(services).each(function ()
    {
      formData.services.push($(this).data('id'));
      HideControls($(this));
    });

    HideControls(servicesList.find('.global-actions'));

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/serviceInstance/multiStopProviding',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              $(Object.keys(data.responseJSON.services)).each(function (key, serviceId)
              {
                var service = $('.service[data-id="' + serviceId + '"]');

                if ($.inArray(servicesList.find('.filter-control .current').data('value'), ['all', 'inactive']) != -1)
                {
                  ShowControls(service);
                  UpdateStatus(service, data.responseJSON.services[serviceId].className, data.responseJSON.services[serviceId].status);
                }
                else
                {
                  service.fadeOut(150, function ()
                  {
                    service.remove();
                  });
                }
              });

              ShowControls(servicesList.find('.global-actions'));

              break;

            default:
              $(services).each(function ()
              {
                ShowControls($(this));
              });

              ShowControls(servicesList.find('.global-actions'));

              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  };

  var ResetServicesList = function ()
  {
    servicesList.find('table tbody').empty();
    servicesList.removeData('offset');
    servicesList.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
  };

  var LoadMore = function (force)
  {
    force = typeof(force) == 'undefined' ? false : force;

    if (!force && !$('#services').is(':visible'))
    {
      return;
    }

    var button = servicesList.find('.btn.load-more');
    button.html('<i class="fa fa-spin fa-spinner"></i>');
    button.addClass('disabled');

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.partyId = $('[name="partyId"]').attr('value');
    formData.offset = servicesList.data('offset');

    formData.sort = servicesList.find('.sort-control .current').data('value');
    formData.filter = servicesList.find('.filter-control .current').data('value');

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/serviceInstance/loadMore',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          switch (data.status)
          {
            case 200:
              if (data.responseJSON.html.length > 0)
              {
                servicesList.find('table tbody').append(data.responseJSON.html);
                servicesList.data('offset', data.responseJSON.offset);
                button.html('Load more');
                button.removeClass('disabled');
              }
              else
              {
                servicesList.find('table tbody').append('<tr><td colspan="2" class="no-more">No more services.</td></tr>');
                servicesList.find('.btn.load-more').remove();
              }
              break;
            default:
              // Failed
              break;
          }
        }
      });

  };



  return {
    Init: Init
  };
}();
