// Module containing tools to be used when displaying a SMS boxes list
Onkho.SMSBoxesListTools = function () {
  var SMSBoxesContainer

  var Init = function () {
    SMSBoxesContainer = $('#smsboxes')

    Onkho.OnkhoList.Init(SMSBoxesContainer.find('.o-list'), 'smsboxes', {
      'practiceId': Onkho.Variables.PartyId
    })

    RegisterListBindings()
    RegisterPanelBindings()

    Onkho.ViewSMSBoxPanel.Init()
    Onkho.AddSMSBoxPanel.Init()
    Onkho.EditSMSBoxPanel.Init()
  }

  var RegisterListBindings = function () {
    SMSBoxesContainer.on('click', '.smsboxes-list-item .title > a', function () {
      Onkho.ViewSMSBoxPanel.LoadViewSMSBoxPanel($(this).closest('.smsboxes-list-item').data('id'))
    })

    SMSBoxesContainer.on('click', '.smsboxes-list-item .actions [data-id="delete"]', function () {
      ConfirmDeleteSMSBox($(this).closest('.smsboxes-list-item').data('id'))
    })

    SMSBoxesContainer.on('click', '.items-actions [data-id="delete"]', function () {
      ConfirmDeleteSMSBoxes()
    })

    SMSBoxesContainer.on('click', '.list-actions [data-id="add_smsbox"]', function () {
      Onkho.AddSMSBoxPanel.LoadAddSMSBoxPanel()
    })

    SMSBoxesContainer.on('click', '.smsboxes-list-item .actions [data-id="edit"]', function () {
      Onkho.EditSMSBoxPanel.LoadEditSMSBoxPanel($(this).closest('.smsboxes-list-item').data('id'))
    })

    SMSBoxesContainer.on('click', '.smsboxes-list-item .actions [data-id="make-default"]', function () {
      MakeSMSBoxDefault($(this).closest('.smsboxes-list-item').data('id'))
    })

    // Placeholder buttons
    SMSBoxesContainer.on('click', '.content-placeholder [data-id="add_smsbox"]', function () {
      SMSBoxesContainer.find('.list-actions [data-id="add_smsbox"]').click()
    })
  }

  var RegisterPanelBindings = function () {

  }

  var MakeSMSBoxDefault = function (smsboxId) {
    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.smsbox_id = smsboxId

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/makeSMSBoxDefault',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              Onkho.OnkhoList.Refresh(SMSBoxesContainer.find('.o-list'))
              break

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmDeleteSMSBoxes = function () {
    $.SmartMessageBox(
      {
        title: 'Delete phone numbers',
        content: 'Are you sure you want to delete the selected phone numbers?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteSMSBoxes()
        }
      }
    )
  }

  var DeleteSMSBoxes = function () {
    var list = SMSBoxesContainer.find('.o-list')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.smsboxIds = []
    $(Onkho.OnkhoList.GetSelected(list)).each(function (index, listItem) {
      formData.smsboxIds.push(listItem.data('id'))
    })

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/deleteSMSBoxes',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoList.Refresh(list)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var ConfirmDeleteSMSBox = function (smsboxId) {
    $.SmartMessageBox(
      {
        title: 'Delete phone number',
        content: 'Are you sure you want to delete this phone number?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteSMSBox(smsboxId)
        }
      }
    )
  }

  var DeleteSMSBox = function (smsboxId) {
    var list = SMSBoxesContainer.find('.o-list')

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.smsboxId = smsboxId

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/deleteSMSBox',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)
              Onkho.OnkhoList.Refresh(list)
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  return {
    Init: Init,
  }
}()
