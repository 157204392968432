Onkho.OnkhoActiveElementContextAwareness = function ()
{
    var Init = function ()
    {
        if (Onkho.Variables.PageName === 'accountant_dashboard')
        {
            AccountantDashboard();
        }
        else
        if (Onkho.Variables.PageName === 'party')
        {
            Party();
        }
        else
        if (Onkho.Variables.PageName === 'workflow')
        {
            Workflow();
        }
        else
        if (Onkho.Variables.PageName.includes('_insights'))
        {
            Insights();
        }
    };

    var Party = function ()
    {
        $('body').on('onkho:activeElement[job].job_status_changed', '.o-active-element', function (event, id, statusClass, status, isOverdue)
        {
            var job = $('.jobs-list .job[data-id="' + id + '"]');
            if (job.length > 0)
            {
                Onkho.JobsListTools.UpdateStatus(job, (isOverdue && status != 'COMPLETED') ? 'overdue' : statusClass, (isOverdue && status != 'COMPLETED') ? 'Overdue' : Onkho.TextTools.UcWords(status.toLowerCase()));
            }
        });
    };

    var Workflow = function ()
    {
        $('body').on('onkho:activeElement[job].job_status_changed', '.o-active-element', function (event, id, statusClass, status, isOverdue)
        {
            var jobs = $('.job.jarviswidget[data-job-id="' + id + '"]');
            if (jobs.length > 0)
            {
                jobs.each(function ()
                {
                    Onkho.WorkflowTools.UpdateJobStatus($(this), ((isOverdue && status != 'COMPLETED') ? 'overdue ' : '') + statusClass);
                });
            }
        });
    };

    var Insights = function ()
    {
        $('body').on('onkho:activeElement[job].job_status_changed', '.o-active-element', function (event, id, statusClass, status, isOverdue)
        {
            Onkho.InsightGridPageTools.ReloadRecords();
        });
    };

    var AccountantDashboard = function ()
    {
        $('body').on('onkho:activeElement[job].job_status_changed', '.o-active-element', function (event, id, statusClass, status, isOverdue)
        {
            $('.dashboard-widget-grid .jarviswidget .compact-content-wrapper .o-active-element-trigger[data-type="job"][data-id="' + id + '"]').each(function ()
            {
                Onkho.AccountantDashboardPageTools.LoadWidget($(this).closest('.jarviswidget'));
            });
        });
    };



    return {
        Init: Init
    };
}();
