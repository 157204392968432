Onkho.AddEditKPIPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page in the add edit KPI panel
    $('body').on('keypress', '.o-panel[data-panel-key="add_edit_kpi"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadAddEditKPIPanel = function (configuration)
  {
    var additionalData
    if (configuration) {
      additionalData = configuration
    } else {
      additionalData = {
        'resource': Onkho.Variables.InsightGridResource
      }
    }

    Onkho.OnkhoPanel.Add('add_edit_kpi', additionalData, AddEditKPIPanelLoaded)
  };

  var AddEditKPIPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel)
    Onkho.Validator.AddChildren(panel)

    // Mark the panel as still loading for 500ms to allow the KPI form to
    // restore when editing
    panel.data('loading', true)
    setTimeout(function () {
      panel.data('loading', false)
    }, 500)

    var columnSpecificKPIPicker = panel.find('[name="column_specific_kpi"]')
    columnSpecificKPIPicker.select2({
      placeholder: 'Choose column'
    })

    columnSpecificKPIPicker.on('change', function () {
      ResetFunctionSection(panel)
      if (columnSpecificKPIPicker.val()) {
        ShowFunctionSection(panel)
      } else {
        HideFunctionSection(panel)
      }
    })

    var functionPicker = panel.find('[name="function"]');
    functionPicker.select2({
      placeholder: 'Choose function',
      minimumResultsForSearch: -1,
      ajax: {
        url: Onkho.Variables.BaseURL + '/insight/' + Onkho.Variables.InsightGridResource + '/getKPIFunctions',
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            category: panel.find('[name="category"]:checked').val(),
            column: columnSpecificKPIPicker.val()
          };
        },
        results: function (data, page) {
          return { results: data.items, more: false }
        }
      },
      formatResult: function (result) {
        return result.name
      },
      formatSelection: function (result) {
        return result.name
      },
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m }
    });

    functionPicker.on('change', function () {
      ResetFormattingSection(panel)
      if (functionPicker.val()) {
        panel.data('kpi-type', functionPicker.select2('data').type)
        ShowFormattingSection(panel)
      } else {
        HideFormattingSection(panel)
      }
    })

    var category = panel.find('[name="category"]')
    category.on('change', function () {
      if ($(this).is(':checked')) {
        ResetFunctionSection(panel)
        ResetColumnSection(panel)
        ResetFormattingSection(panel)
        HideFunctionSection(panel)

        if ($(this).val() === 'COLUMN_SPECIFIC') {
          ShowColumnSection(panel)
        } else {
          SetFunctionSectionToCount(panel)
          HideColumnSection(panel)
          ShowFormattingSection(panel)
        }
      }
    });
    category.trigger('change');

    panel.on('click', 'table.formattings td.edit', function () {
      EditFormatting(panel, $(this).closest('tr'))
    })

    panel.on('click', 'table.formattings td.delete', function () {
      DeleteFormatting(panel, $(this).closest('tr'))
    })

    if (columnSpecificKPIPicker.data('initial-value')) {
      columnSpecificKPIPicker.val(columnSpecificKPIPicker.data('initial-value')).trigger('change')
    }

    if (functionPicker.data('initial-value')) {
      functionPicker.select2('data', {'id': functionPicker.data('initial-value'), 'name': functionPicker.data('initial-name')}).trigger('change')
    }

    // If there are any pending formattings, render them
    panel.find('table.formattings tr').each(function (index, formattingElement) {
      $(formattingElement).replaceWith(BuildFormatting($(formattingElement).data('configuration')))
    })
  };

  var ShowColumnSection = function (panel) {
    panel.find('.panel-section[data-id="column"]').slideDown(150)
  }

  var HideColumnSection = function (panel) {
    panel.find('.panel-section[data-id="column"]').slideUp(150)
  }

  var ResetColumnSection = function (panel) {
    var columnPicker = panel.find('[name="column_specific_kpi"]');

    if (!panel.data('loading')) {
      columnPicker.select2('data', '')
    }

    columnPicker.trigger('change')
  }

  var ShowFunctionSection = function (panel) {
    panel.find('.panel-section[data-id="function"]').slideDown(150)
  }

  var HideFunctionSection = function (panel) {
    panel.find('.panel-section[data-id="function"]').slideUp(150)
  }

  var SetFunctionSectionToCount = function (panel) {
    var functionPicker = panel.find('[name="function"]');
    functionPicker.select2('data', {
      id: 'COUNT',
      name: 'count',
      type: 'GENERAL'
    })
    functionPicker.trigger('change')
  }

  var ResetFunctionSection = function (panel) {
    var functionPicker = panel.find('[name="function"]');

    if (!panel.data('loading')) {
      functionPicker.select2('data', '')
    }

    functionPicker.trigger('change')
  }

  var ShowFormattingSection = function (panel) {
    panel.find('.panel-section[data-id="formatting"]').slideDown(150)
    if (panel.find('table.formattings tr').length > 0) {
      ShowFormattings(panel)
    }
  }

  var HideFormattingSection = function (panel) {
    panel.find('.panel-section[data-id="formatting"]').slideUp(150)
  }

  var ResetFormattingSection = function (panel) {
    if (!panel.data('loading')) {
      panel.find('table.formattings tr').each(function (index, formatting) {
        DeleteFormatting(panel, $(formatting))
      })
    }
  }

  var ShowFormattings = function (panel) {
    panel.find('.panel-section[data-id="formatting"] .section-title').slideDown(150)
    panel.find('.panel-section[data-id="formatting"] table.formattings').slideDown(150)
  }

  var HideFormattings = function (panel) {
    panel.find('.panel-section[data-id="formatting"] .section-title').slideUp(150)
    panel.find('.panel-section[data-id="formatting"] table.formattings').slideUp(150)
  }

  var DeleteFormatting = function (panel, formattingElement) {
    var formattings = formattingElement.closest('table')
    formattingElement.remove()

    // Hide all tooltips
    $('.tooltip').tooltip('hide');

    if (formattings.find('tr').length == 0) {
      HideFormattings(panel)
    }
  }

  var BuildConditionSummary = function (condition, conditionConfiguration)
  {
    var summary = 'If the KPI'
    switch (condition) {
      case 'GREATER_THAN_EQUAL':
        summary += ' is greater than or equal to ' + conditionConfiguration[0]
        break

      case 'GREATER_THAN':
        summary += ' is greater than ' + conditionConfiguration[0]
        break

      case 'LESS_THAN_EQUAL':
        summary += ' is less than or equal to ' + conditionConfiguration[0]
        break

      case 'LESS_THAN':
        summary += ' is less than ' + conditionConfiguration[0]
        break

      case 'EQUAL':
        summary += ' is ' + conditionConfiguration[0]
        break

      case 'IN_THE_NEXT':
        summary += ' is in the next ' + (conditionConfiguration[0] == 1 ? '' : (conditionConfiguration[0] + ' ')) + conditionConfiguration[1].toLowerCase() + (conditionConfiguration[0] == '1' ? '' : 's')
        break

      case 'IN_THE_PAST':
        summary += ' is in the past ' + (conditionConfiguration[0] == 1 ? '' : (conditionConfiguration[0] + ' ')) + conditionConfiguration[1].toLowerCase() + (conditionConfiguration[0] == '1' ? '' : 's')
        break

      case 'TODAY':
        summary += ' is today'
        break
    }

    return summary
  }

  var BuildEffectSummary = function (effect, effectConfiguration)
  {
    var summary = 'then the'
    switch (effect) {
      case 'TEXT_COLOR':
        summary += ' text color will change to <span class="color-sample" style="background-color: ' + effectConfiguration[0] + ';"></span>'
        break

      case 'BACKGROUND_COLOR':
        summary += ' background color will change to <span class="color-sample" style="background-color: ' + effectConfiguration[0] + ';"></span>'
        break

      case 'TEXT_ANIMATION':
        summary += ' text will do a ' + effectConfiguration[0].toLowerCase() + ' animation'
        break
    }

    return summary
  }

  var AddFormatting = function (panel, configuration) {
    var formattings = panel.find('table.formattings tbody')
    formattings.append(BuildFormatting(configuration))
    ShowFormattings(panel)
  }

  var UpdateFormatting = function (panel, formattingId, configuration) {
    var formatting = panel.find('table.formattings tbody tr[data-id="' + formattingId + '"]')
    formatting.replaceWith(BuildFormatting(configuration))
  }

  var BuildFormatting = function (configuration) {
    var formattingElement = $('<tr></tr>')
    formattingElement.data('configuration', configuration)
    formattingElement.attr('data-id', parseInt(Math.random() * 10000))

    formattingElement.append('<td class="delete" rel="tooltip" data-placement="top" data-original-title="Delete"><i class="fa fa-fw fa-times"></i></td>')

    formattingElement.append('<td>' + BuildConditionSummary(configuration.condition.condition, configuration.condition.configuration) + ', ' + BuildEffectSummary(configuration.effect.effect, configuration.effect.configuration) + '</td>')

    formattingElement.append('<td class="edit" rel="tooltip" data-placement="top" data-original-title="Edit"><i class="fa fa-fw fa-edit"></i></td>')

    formattingElement.find('[rel="tooltip"]').tooltip({
      'container': 'body'
    })

    return formattingElement;
  }

  var EditFormatting = function (panel, formattingElement) {
    var formattingId = formattingElement.data('id')
    var configuration = formattingElement.data('configuration')
    var kpiFunction = panel.find('[name="function"]').val()
    var condition = configuration['condition']['condition']
    var conditionParameter1 = configuration['condition']['configuration'][0]
    var conditionParameter2 = configuration['condition']['configuration'][1]
    var effect = configuration['effect']['effect']
    var effectParameter = configuration['effect']['configuration'][0]

    Onkho.OnkhoPanel.Disable(panel);
    Onkho.AddEditKPIFormattingPanel.LoadAddEditKPIFormattingPanel(formattingId, panel.data('kpi-type'), kpiFunction, condition, conditionParameter1, conditionParameter2, effect, effectParameter);
  }

  var RegisterAddEditKPIPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      AddEditKPI(panel);
    });

    panel.find('.panel-opener[data-id="add_edit_kpi_formatting"] > button:not(:disabled)').on('click', function ()
    {
      Onkho.OnkhoPanel.Disable(panel);
      Onkho.AddEditKPIFormattingPanel.LoadAddEditKPIFormattingPanel(null, panel.data('kpi-type'), panel.find('[name="function"]').val(), null, null, null, null);
    });
  };

  var GatherFormatting = function (panel)
  {
    var formatting = [];

    panel.find('table.formattings tr').each(function (index, formattingElement) {
        formatting.push($(formattingElement).data('configuration'))
    })

    return formatting;
  }

  var AddEditKPI = function (panel)
  {
    if (Onkho.Validator.ValidateChildren(panel)) {
      Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'))

      var configuration = {};

      configuration.resource = Onkho.Variables.InsightGridResource

      configuration.name = panel.find('[name="name"]').val()
      configuration.category = panel.find('[name="category"]:checked').val()
      configuration.column = panel.find('[name="column_specific_kpi"]').val()
      configuration.function = panel.find('[name="function"]').val()
      configuration.functionLabel = panel.find('[name="function"]').select2('data').name
      configuration.type = panel.data('kpi-type')
      configuration.formatting = GatherFormatting(panel)
      configuration.filters = Onkho.InsightGridPageTools.GatherFilters()
      configuration.contextFilters = Onkho.InsightGridPageTools.GatherContextFilters()

      Onkho.KPI.CreateUpdateKPI(panel.data('kpi-id') ? panel.data('kpi-id') : null, configuration)

      Onkho.OnkhoPanel.Remove(panel);

      Onkho.Alert.SmallBox('success', 'KPI Saved!')

      Onkho.InsightGridPageTools.ShowKPIContainer()
    }
  };

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_edit_kpi].added', '.o-panel-container', function ()
    {
      var addEditKPIPanel = $('.o-panel[data-panel-key="add_edit_kpi"]');
      RegisterAddEditKPIPanelBindings(addEditKPIPanel);
    });
  };



  return {
    Init: Init,
    LoadAddEditKPIPanel: LoadAddEditKPIPanel,
    AddFormatting: AddFormatting,
    UpdateFormatting: UpdateFormatting
  };
}();
