// Namespaced functions container for general use
Onkho.Functions = function () {

    var GetCSRF = function() {
        return $('meta[name="csrf_token"]').attr('content');
    };

    var Select2Sort = function (data)
    {
        return data.sort(
            function (a, b) {
                if (a.text.toLowerCase() == 'no one') {
                    return -1;
                }

                if (b.text.toLowerCase() == 'no one') {
                    return 1;
                }

                if (a.text.toLowerCase() < b.text.toLowerCase()) {
                    return -1;
                }

                if (a.text.toLowerCase() > b.text.toLowerCase()) {
                    return 1;
                }

                return 0;
            }
        );
    };

    var Purify = function (input, callback) {
        var data = {};
        data.input  = JSON.stringify(input);
        data._token = GetCSRF();
        $.ajax({
            type: 'POST',
            url: Onkho.Variables.BaseURL + '/utils/purify/html',
            data: data,
            dataType: 'json',
            complete: function (data) {
                switch (data.status) {
                    case 200:
                        callback(data.responseJSON.cleaned);
                        break;
                    default:
                        callback(input);
                        break;
                }
            }
        });
    };

    var DelayInterval = function(timer, callback, delay)
    {
        clearTimeout(Onkho.Variables[timer]);
        Onkho.Variables[timer] = setTimeout(callback, delay);
    };

    var ExecuteCallback = function (callback)
    {
        if (typeof callback === 'function')
        {
            callback.apply(null, Array.prototype.slice.call(arguments, 1));
        }
    };

    var Select2ScrollToTopFix = function (select)
    {
        select.on('select2:selecting select2:unselecting', function (event)
        {
            var parent = $('#' + event.params.args.data._resultId).parent();
            Onkho.Variables.Select2ScrollTop = parent.prop('scrollTop');
        });

        select.on('select2:select select2:unselect', function (event)
        {
            var parent = $('#' + event.params.data._resultId).parent();
            parent.prop('scrollTop', Onkho.Variables.Select2ScrollTop);
        });
    };

    var ScrollTo = function (element) {
        if ($(element).is(':visible')) {
            $('html, body').animate({
                scrollTop: (element.offset().top - 150)
            }, 500)
        }
    }

    var ConcatenateErrors = function (errors) {
        var concatenatedErrors = '';
        $.each(errors, function (index, error) {
            var errorString;
            if (typeof error == 'object') {
                errorString = ConcatenateErrors(error);
            } else {
                errorString = error;
            }

            concatenatedErrors = (concatenatedErrors.length > 0 ? ' ' : '') + errorString;
        })

        return concatenatedErrors;
    }

    return {
        GetCSRF: GetCSRF,
        Select2Sort: Select2Sort,
        Select2ScrollToTopFix: Select2ScrollToTopFix,
        Purify: Purify,
        DelayInterval: DelayInterval,
        ExecuteCallback: ExecuteCallback,
        ScrollTo: ScrollTo,
        ConcatenateErrors: ConcatenateErrors
    };
}();
