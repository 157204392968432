Onkho.OnkhoViewUploadLinkPanelContextAwareness = function ()
{
    var Init = function ()
    {
        if (Onkho.Variables.PageName === 'uploadLinks_insights')
        {
            Insights();
        }
    };

    var Insights = function ()
    {
        $('body').on('onkho:panel[view_upload_link].deleted', '.o-panel-container', function (event, id)
        {
            Onkho.InsightGridPageTools.ReloadRecords();
        });
    };



    return {
        Init: Init
    };
}();
