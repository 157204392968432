Onkho.EditJobPanel = function ()
{
    var Init = function ()
    {
        RegisterPanelBindings();

        RegisterEventListeners();
    };

    var RegisterEventListeners = function ()
    {
        $('body').on('change', '.o-panel[data-panel-key="edit_job"] .precondition input[type="checkbox"]', function ()
        {
            if ($(this).is(':checked'))
            {
                $(this).closest('.precondition').addClass('complete');
            }
            else
            {
                $(this).closest('.precondition').removeClass('complete');
            }
        });

        $('body').on('change', '.o-panel[data-panel-key="edit_job"] .checklist-item input[type="checkbox"]', function ()
        {
            if ($(this).is(':checked'))
            {
                $(this).closest('.checklist-item').addClass('complete');
            }
            else
            {
                $(this).closest('.checklist-item').removeClass('complete');
            }
        });
    };

    var LoadEditJobPanel = function (jobId)
    {
        var additionalData = {
            'jobId': jobId
        };

        Onkho.OnkhoPanel.Add('edit_job', additionalData, EditJobPanelLoaded)
    };

    var LoadEditJobAdditionalPanel = function (jobId)
    {
        var additionalData = {
            'jobId': jobId
        };

        var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');
        Onkho.OnkhoPanel.DisablePanelOpener(editJobPanel);
        Onkho.OnkhoPanel.DisableButtons(editJobPanel);

        Onkho.OnkhoPanel.Add('edit_job_additional', additionalData, EditJobAdditionalPanelLoaded)
    };

    var RegisterPanelBindings = function ()
    {
        // Edit job
        $('body').on('onkho:panel[edit_job].added', '.o-panel-container', function ()
        {
            var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');
            RegisterEditJobPanelBindings(editJobPanel);
        });

        // Edit job additional
        $('body').on('onkho:panel[edit_job_additional].added', '.o-panel-container', function ()
        {
            var editJobAdditionalPanel = $('.o-panel[data-panel-key="edit_job_additional"]');
            RegisterEditJobAdditionalPanelBindings(editJobAdditionalPanel);
        });
    };

    var PrepareRemoval = function (panel)
    {
        Onkho.Summernote.DestroySummernote(panel.find('[name="description"].summernote'));
        Onkho.Summernote.DestroySummernote(panel.find('[name="reason"].summernote'));
    };

    var RegisterEditJobPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveEditedJob();
        });

        panel.find('button[data-id="delete"]:not(:disabled)').on('click', function ()
        {
            ConfirmDeleteJob();
        });

        panel.find('.panel-opener[data-id="additional"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            LoadEditJobAdditionalPanel(panel.data('job-id'));
        });
    };

    var RegisterEditJobAdditionalPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            PrepareRemoval(panel);
            Onkho.OnkhoPanel.Remove(panel);

            var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');
            Onkho.OnkhoPanel.EnablePanelOpener(editJobPanel);
            Onkho.OnkhoPanel.Enable(editJobPanel);
            Onkho.OnkhoPanel.EnableButtons(editJobPanel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveAdditionalDetails();
        });
    };

    var EditJobPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.Validator.AddChildren(panel);

        panel.find('.select[name="assigned_to_party_id"]').select2(
        {
            placeholder: 'No one',
            sortResults: Onkho.Functions.Select2Sort
        });

        Onkho.FormEnhancements.EnhanceDates();
        Onkho.OnkhoStatus.Init(panel.find('.o-status'));
    };

    var EditJobAdditionalPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.Validator.AddChildren(panel);

        SetupEditJobAdditionalPanel(panel);
    };

    var SetupEditJobAdditionalPanel = function (panel)
    {
        var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');

        var description = editJobPanel.find('textarea[name="description"]').val();
        var reason = editJobPanel.find('textarea[name="reason"]').val();

        panel.find('textarea.summernote[name="description"]').val(description);
        panel.find('textarea.summernote[name="reason"]').val(reason);

        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="description"]'));
        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="reason"]'));
    };

    var SaveEditedJob = function ()
    {
        var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');

        if (Onkho.Validator.ValidateChildren(editJobPanel))
        {
            Onkho.LoadingTools.ShowLoading(editJobPanel.find('button[data-id="save"]'), true);

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.jobId = editJobPanel.data('job-id');
            formData.matter = editJobPanel.find('[name="matter"]').val();
            formData.due_date = editJobPanel.find('[name="date"]').val();
            formData.planned_deadline = editJobPanel.find('[name="planned_deadline"]').val();
            formData.time_entry_total = editJobPanel.find('[name="time_entry_total"]').val();
            formData.assigned_to_party_id = editJobPanel.find('[name="assigned_to_party_id"]').val();
            formData.status = Onkho.OnkhoStatus.GetStatus(editJobPanel.find('.o-status'));
            formData.description = editJobPanel.find('textarea[name="description"]').val();
            formData.reason = editJobPanel.find('textarea[name="reason"]').val();

            formData.preconditions = [];
            editJobPanel.find('.precondition input[type="checkbox"]').each(function ()
            {
                var id = $(this).attr('name');
                var status = $(this).is(':checked') ? 1 : 0;

                formData.preconditions.push({
                    id: id,
                    status: status
                });
            });

            formData.checklist = [];
            editJobPanel.find('.checklist-item input[type="checkbox"]').each(function ()
            {
                var id = $(this).attr('name');
                var status = $(this).is(':checked') ? 1 : 0;

                formData.checklist.push({
                    id: id,
                    status: status
                });
            });

            $.ajax(
                {
                    type: 'PUT',
                    url: Onkho.Variables.BaseURL + '/job/' + formData.jobId,
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                          {
                              Onkho.LoadingTools.HideLoading(editJobPanel.find('button[data-id="save"]'));

                              switch (data.status)
                              {
                                  case 200:
                                      $('.o-panel-container').trigger('onkho:panel[edit_job].saved', [formData.jobId]);

                                      Onkho.OnkhoPanel.Remove(editJobPanel);

                                      Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                      break;

                                  default:
                                      Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                      break;
                              }
                          }
                });
        }
    };

    var SaveAdditionalDetails = function ()
    {
        var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');
        var editJobAdditionalPanel = $('.o-panel[data-panel-key="edit_job_additional"]');

        if (Onkho.Validator.ValidateChildren(editJobAdditionalPanel))
        {
            Onkho.LoadingTools.ShowLoading(editJobAdditionalPanel.find('button[data-id="save"]'), true);

            var description = Onkho.Summernote.GetSummernoteValue(editJobAdditionalPanel.find('[name="description"].summernote'));
            editJobPanel.find('.panel-section .description-section .content').html(description);
            editJobPanel.find('.panel-section .description-section textarea[name="description"]').val(description);
            if (description.length > 0)
            {
                editJobPanel.find('.panel-section .description-section').show();
            }
            else
            {
                editJobPanel.find('.panel-section .description-section').hide();
            }

            var reason = Onkho.Summernote.GetSummernoteValue(editJobAdditionalPanel.find('[name="reason"].summernote'));
            editJobPanel.find('.panel-section .reason-section .content').html(reason);
            editJobPanel.find('.panel-section .reason-section textarea[name="reason"]').val(reason);
            if (reason.length > 0)
            {
                editJobPanel.find('.panel-section .reason-section').show();
            }
            else
            {
                editJobPanel.find('.panel-section .reason-section').hide();
            }

            Onkho.OnkhoPanel.EnablePanelOpener(editJobPanel);
            Onkho.OnkhoPanel.Enable(editJobPanel);
            Onkho.OnkhoPanel.EnableButtons(editJobPanel);

            PrepareRemoval(editJobAdditionalPanel);
            Onkho.OnkhoPanel.Remove(editJobAdditionalPanel);
        }
    };

    var ConfirmDeleteJob = function ()
    {
        $.SmartMessageBox(
            {
                title : "Deleting job",
                content : "Are you sure you want to delete this job?",
                buttons : "[No][Yes]"
            }, function(ButtonPress)
            {
                if (ButtonPress == 'Yes')
                {
                    DeleteJob();
                }
            }
        );
    };

    var DeleteJob = function ()
    {
        var editJobPanel = $('.o-panel[data-panel-key="edit_job"]');
        Onkho.LoadingTools.ShowLoading(editJobPanel.find('button[data-id="delete"]'), true);

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.ids = [editJobPanel.data('job-id')];

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/job/cancel',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          Onkho.LoadingTools.HideLoading(editJobPanel.find('button[data-id="save"]'));

                          switch (data.status)
                          {
                              case 200:
                                  $('.o-panel-container').trigger('onkho:panel[edit_job].deleted', [formData.ids[0]]);

                                  Onkho.OnkhoPanel.Remove(editJobPanel);

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };



    return {
        Init: Init,
        LoadEditJobPanel: LoadEditJobPanel
    };
}();
