Onkho.InsightsActionChangeServiceManagerPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="insights_action_change_service_manager"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadInsightsActionChangeServiceManagerPanel = function (buttonId, resource)
  {
    Onkho.LoadingTools.ShowLoading($('.insights-grid .selection-toolbar [data-id="' + buttonId + '"]'));

    var additionalData = {
      'buttonId': buttonId,
      'resource': resource,
      'selectedCount': Onkho.InsightGridPageTools.GetSelection().length
    };

    Onkho.OnkhoPanel.Add('insights_action_change_service_manager', additionalData, LoadInsightsActionChangeServiceManagerPanelLoaded)
  };

  var LoadInsightsActionChangeServiceManagerPanelLoaded = function (panel)
  {
    var buttonId = panel.data('button-id');

    Onkho.LoadingTools.HideLoading($('.insights-grid .selection-toolbar [data-id="' + buttonId + '"]'));

    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide');

    var servicePicker = panel.find('[name="service_id"]')
    servicePicker.select2({
      placeholder: 'Find a service',
      minimumInputLength: 0,
      ajax: {
        url: Onkho.Variables.BaseURL + '/service/getServiceSearchResults',
        dataType: 'json',
        quietMillis: 700,
        data: function (term, page) {
          return {
            searchTerm: term,
            page: page
          };
        },
        results: function (data, page) {
          var more = (page * 30) < data.totalCount;
          return {results: data.items, more: more};
        }
      },
      formatResult: FormatServiceSearchResult,
      formatSelection: FormatServiceSearchResultSelection,
      dropdownCssClass: 'bigdrop',
      escapeMarkup: function (m) { return m; }
    })

    servicePicker.on('change', function () {
      ChangeSelectedService(panel, servicePicker.val())
    })

    panel.find('[name="service_manager_id"]').select2()

    panel.find('.panel-section[data-id="assignee"]').on('onkho:configuration.changed', function () {
      UpdateAssigneeSection(panel)
    })

    panel.find('.panel-section[data-id="service"] .preconditions-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HidePreconditions(panel)
      } else {
        ShowPreconditions(panel)
      }
    })

    panel.find('.panel-section[data-id="service"] .checklist-items-container .title').on('click', function () {
      if ($(this).hasClass('showing')) {
        HideChecklistItems(panel)
      } else {
        ShowChecklistItems(panel)
      }
    })
  };

  var FormatServiceSearchResult = function (service) {
    return service.html
  }

  var FormatServiceSearchResultSelection = function (service) {
    return service.name
  }

  var HidePreconditions = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .preconditions-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    panel.find('.panel-section[data-id="service"] .preconditions-container .preconditions').slideUp(150)
  }

  var ShowPreconditions = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .preconditions-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    panel.find('.panel-section[data-id="service"] .preconditions-container .preconditions').slideDown(150)
  }

  var HideChecklistItems = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .checklist-items-container .title')
    button.removeClass('showing')
    button.find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-right')
    panel.find('.panel-section[data-id="service"] .checklist-items-container .checklist-items').slideUp(150)
  }

  var ShowChecklistItems = function (panel) {
    var button = panel.find('.panel-section[data-id="service"] .checklist-items-container .title')
    button.addClass('showing')
    button.find('.fa').removeClass('fa-chevron-right').addClass('fa-chevron-down')
    panel.find('.panel-section[data-id="service"] .checklist-items-container .checklist-items').slideDown(150)
  }

  var ChangeSelectedService = function (panel, serviceId) {
    EnableLoading(panel)

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF()
    formData.service_id = serviceId
    formData.bulk = 1

    $.ajax(
      {
        type: 'GET',
        url: Onkho.Variables.BaseURL + '/service/getForm',
        data: formData,
        dataType: 'json',
        complete: function (data)
        {
          DisableLoading(panel)
          ClearSections(panel)

          switch (data.status)
          {
            case 200:
              panel.find('.panel-section[data-id="service"]').addClass('chosen')

              SetService(panel, data.responseJSON.service)
              ShowAssigneeSection(panel)
              break;

            default:
              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
              break;
          }
        }
      });
  }

  var ClearSections = function (panel) {
    ClearService(panel)
  }

  var ClearService = function (panel) {
    SetService(panel, null)
  }

  var SetService = function (panel, service) {
    var serviceElement = panel.find('.panel-section[data-id="service"] .service')
    var name = serviceElement.find('.name')
    var description = serviceElement.find('.description')
    var preconditionsContainer = serviceElement.find('.preconditions-container')
    var preconditions = preconditionsContainer.find('.preconditions')
    var checklistItemsContainer = serviceElement.find('.checklist-items-container')
    var checklistItems = checklistItemsContainer.find('.checklist-items')

    if (service != null) {
      name.html(Onkho.Formatter.MakeServiceActiveElement(service.id, service.name))

      if (service.description) {
        description.html(service.description)
      } else {
        description.html(Onkho.RegisterServicePageTools.DescribeServiceScheduleConfiguration(service.configuration))
      }

      if (service.preconditions) {
        var preconditionsHtml = '';
        $(service.preconditions).each(function (index, precondition) {
          preconditionsHtml += RenderPrecondition(precondition)
        })

        preconditions.html(preconditionsHtml)

        if (preconditionsHtml) {
          preconditionsContainer.slideDown(150)
        } else {
          preconditionsContainer.slideUp(150)
        }
      }

      if (service.checklistItems) {
        var checklistItemsHtml = '';
        $(service.checklistItems).each(function (index, checklistItem) {
          checklistItemsHtml += RenderChecklistItem(checklistItem)
        })

        checklistItems.html(checklistItemsHtml)

        if (checklistItemsHtml) {
          checklistItemsContainer.slideDown(150)
        } else {
          checklistItemsContainer.slideUp(150)
        }
      }

      serviceElement.slideDown(150)
      serviceElement.find('[rel="tooltip"]').tooltip({
        placement: 'top',
        container: 'body'
      })
    } else {
      description.html('')
      preconditions.html('')
      preconditionsContainer.slideUp(150)
      checklistItems.html('')
      checklistItemsContainer.slideUp(150)
      serviceElement.slideUp(150)
    }

    HidePreconditions(panel)
    HideChecklistItems(panel)
  }

  var RenderPrecondition = function (precondition) {
    var notificationHtml = ''
    var notificationTooltip = ''
    if (precondition.notification_time_unit_count) {
      notificationTooltip = 'Notification sent ' + precondition.notification_time_unit_count + ' ' + precondition.notification_time_unit.toLowerCase() + (precondition.notification_time_unit_count == 1 ? '' : 's') + ' before due date'
      if (precondition.reminder_time_unit_count) {
        notificationTooltip += ' with reminders sent every ' + precondition.reminder_time_unit_count + ' ' + precondition.reminder_time_unit.toLowerCase() + (precondition.reminder_time_unit_count == 1 ? '' : 's')
      }

      notificationHtml = '<div class="notification" rel="tooltip" title="" data-original-title="' + notificationTooltip + '"><i class="fa fa-fw fa-envelope"></i></div>'
    }

    return '' +
      '<div class="precondition">' +
      '<div class="name">' + precondition.name + '</div>' +
      notificationHtml +
      '</div>'
  }

  var RenderChecklistItem = function (checklistItem) {
    var notificationHtml = ''
    var notificationTooltip = ''
    if (checklistItem.generates_activity_feed_item_on_completion) {
      notificationTooltip = 'Notification sent on completion'
      notificationHtml = '<div class="notification" rel="tooltip" title="" data-original-title="' + notificationTooltip + '"><i class="fa fa-fw fa-envelope"></i></div>'
    }

    return '' +
      '<div class="checklist-item">' +
      '<div class="name">' + checklistItem.name + '</div>' +
      notificationHtml +
      '</div>'
  }

  var GetAssigneeSectionConfiguration = function (panel) {
    var configuration = {}

    configuration.service_manager_id = panel.find('[name="service_manager_id"]').val();

    return configuration
  }

  var ShowAssigneeSection = function (panel) {
    var section = panel.find('.panel-section[data-id="assignee"]')
    section.slideDown(150)
  }

  var EnableLoading = function (panel, showPrimaryButtons) {
    panel.addClass('loading')
    if (showPrimaryButtons) {
      panel.addClass('show-primary-buttons')
    }
    panel.find('[name="service_id"]').select2('enable', false)
    Onkho.LoadingTools.ShowLoading(panel.find('[data-id="save"]'))
  }

  var DisableLoading = function (panel) {
    panel.removeClass('loading')
    panel.removeClass('show-save-button')
    panel.find('[name="service_id"]').select2('enable', true)
    Onkho.LoadingTools.HideLoading(panel.find('[data-id="save"]'))
  }

  var RegisterInsightsActionChangeServiceManagerPanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
    });

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      ChangeServiceManager(panel);
    });
  };

  var GatherClientIds = function (panel) {
    var selection = Onkho.InsightGridPageTools.GetSelection()
    var clientIds = []
    $(selection).each(function (index, client) {
      clientIds.push(client.id)
    })

    return clientIds
  }

  var ChangeServiceManager = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      EnableLoading(panel, true)

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF()

      formData.action = 'change_service_manager'

      formData.client_ids = GatherClientIds(panel)

      formData.service_id = panel.find('[name="service_id"]').val()

      formData.assignee_configuration = GetAssigneeSectionConfiguration(panel)

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/insight/' + Onkho.InsightGridPageTools.GetInsightResource() + '/executeAction',
          data: formData,
          dataType: 'json',
          complete: function (data)
          {
            DisableLoading(panel)

            switch (data.status)
            {
              case 200:
                Onkho.OnkhoPanel.Remove(panel);

                Onkho.Alert.SmallBox('success', data.responseJSON.message);
                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                break;
            }
          }
        });
    }
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[insights_action_change_service_manager].added', '.o-panel-container', function ()
    {
      var insightsActionChangeServiceManagerPanel = $('.o-panel[data-panel-key="insights_action_change_service_manager"]');
      RegisterInsightsActionChangeServiceManagerPanelBindings(insightsActionChangeServiceManagerPanel);
    });
  };



  return {
    Init: Init,
    LoadInsightsActionChangeServiceManagerPanel: LoadInsightsActionChangeServiceManagerPanel,
  };
}();
