Onkho.OnkhoList = function () {
  var Init = function (list, key, data) {
    LoadList(list, key, data)
  }

  var LoadList = function (list, key, data, callback) {
    var callbackArguments = Array.prototype.slice.call(arguments, 3)

    if (list.hasClass('o-list') && list.data('loaded') !== true) {
      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = key
      formData.data = data

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/list/getList',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                var loadedList = $(data.responseJSON.html)
                list.replaceWith(loadedList)
                loadedList.trigger('onkho:list[' + key + '].initialized')
                loadedList.data('loaded', true)
                loadedList.data('additional-data', JSON.stringify(formData.data))

                SetupList(loadedList)
                LoadItems(loadedList)

                Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                break

              default:
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var SetupList = function (list) {
    if (list.data('select-enabled')) {
      list.find('input[name="select_all"]').on('change', function () {
        if ($(this).is(':checked')) {
          SelectAll(list)
        } else {
          DeselectAll(list)
        }
      })

      list.on('change', 'input[type="checkbox"]', function () {
        UpdateTotalSelected(list)
      })

      UpdateTotalSelected(list)
    }

    if (list.data('search-enabled')) {
      list.on('keyup', 'input[name="search"]', function () {
        Onkho.Functions.DelayInterval('OnkhoListSearch' + list.data('key').replace(/[^a-zA-Z]+/g, ''), function () {
          Reset(list)
          LoadItems(list)
        }, 500)
      })

      list.on('click', '.search-control-hide', function () {
        $(this).fadeOut(150)
        list.find('.search-control-form').fadeOut(150, function () {
          var searchInput = list.find('.search-control input[name="search"]')
          if (searchInput.val().length > 0) {
            list.find('.search-control input[name="search"]').val('')

            Reset(list)
            LoadItems(list)
          }
        })
      })

      list.on('click', '.search-control-show', function () {
        list.find('.search-control-form').fadeIn(150, function () {
          list.find('.search-control-form input[name="search"]').focus()
        }).css('display', 'inline-block')
        list.find('.search-control-hide').fadeIn(150).css('display', 'inline-block')
      })

      list.on('click', '.tag-chip', function () {
        var searchInput = list.find('.search-control input[name="search"]')
        list.find('.search-control-show').click()
        searchInput.val($(this).html()).trigger('keyup')
      })
    }

    if (list.data('order-enabled')) {
      list.find('.order-control .dropdown li a').on('click', function () {
        SetOrder(list, $(this))
      })
    }

    if (list.data('filter-enabled')) {
      list.find('.filter-control .dropdown li a').on('click', function () {
        SetFilter(list, $(this))
      })
    }

    if (list.data('refresh-enabled')) {
      list.find('.refresh').on('click', function () {
        if (!list.find('.btn.load-more').hasClass('disabled')) {
          Reset(list)
          LoadItems(list)
        }
      })
    }

    // Infinity scroll
    list.on('click', '.btn.load-more', function () {
      if (!$(this).hasClass('disabled') && list.hasClass('unlocked')) {
        LoadItems(list)
      }
    })

    list.scrollParent().on('scroll', function () {
      if (NearingBottom(list) && list.hasClass('unlocked')) {
        LoadItems(list)
      }
    })
  }

  var LoadItems = function (list, callback) {
    if (list.length > 0 && !IsLoading(list)) {
      var callbackArguments = Array.prototype.slice.call(arguments, 1)

      ShowLoading(list)

      RegenerateNonce(list)

      var formData = {}
      formData._token = Onkho.Functions.GetCSRF()
      formData.key = GetKey(list)
      formData.data = JSON.parse(list.data('additional-data'))
      formData.nonce = GetCurrentNonce(list)
      formData.offset = GetOffset(list)

      if (list.data('search-enabled')) {
        formData.searchTerm = GetSearchTerm(list)
      }

      if (list.data('order-enabled')) {
        formData.order = GetOrder(list)
      }

      if (list.data('filter-enabled')) {
        formData.filter = GetFilter(list)
      }

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/list/getItems',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                if (CheckNonce(list, data.responseJSON.nonce)) {
                  SetTotalItems(list, data.responseJSON.total)
                  HideLoading(list)

                  if (GetTotalItems(list) == 0) {
                    if (formData.searchTerm.length == 0 && formData.filter == 'all') {
                      ShowContentPlaceholder(list)
                    } else {
                      HideContentPlaceholder(list)
                      ShowNoMoreItems(list)
                    }
                  } else if (data.responseJSON.html.length > 0) {
                    HideContentPlaceholder(list)
                    HideNoMoreItems(list)

                    AppendItems(list, data.responseJSON.html)

                    SetOffset(list, data.responseJSON.offset)
                  } else {
                    ShowNoMoreItems(list)
                  }

                  list.trigger('onkho:list[' + list.data('key') + '].loaded', [data.responseJSON])

                  Onkho.Functions.ExecuteCallback.apply(null, callbackArguments)
                }
                break
              default:
                ShowFailed(list)
                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message)
                break
            }
          }
        })
    }
  }

  var AppendItems = function (list, itemsHtml) {
    list.find('table tbody').append(itemsHtml)
    if (AreAllSelected(list)) {
      list.find('.o-list-item').each(function () {
        $(this).find('.checkbox-col .checkbox input').prop('checked', true)
      })
    }
  }

  var Lock = function (list) {
    if (list.hasClass('unlocked')) {
      list.removeClass('unlocked').addClass('locked')
    }
  }

  var Unlock = function (list) {
    if (list.hasClass('locked')) {
      list.removeClass('locked').addClass('unlocked')
    }
  }

  var ShowContentPlaceholder = function (list) {
    if (list.find('tbody tr').length == 0) {
      list.find('.content-placeholder').fadeIn(150)
    }
  }

  var HideContentPlaceholder = function (list) {
    list.find('.content-placeholder').fadeOut(150)
  }

  var SetTotalItems = function (list, total) {
    list.data('total', total)
  }

  var GetTotalItems = function (list) {
    return typeof list.data('total') === 'undefined' ? 0 : parseInt(list.data('total'))
  }

  var NearingBottom = function (element) {
    var viewportBottom = $(window).scrollTop() + $(window).height()
    var elementBottom = element.offset().top + element.outerHeight()

    return (elementBottom - viewportBottom) < 200
  }

  var GetKey = function (list) {
    return list.data('key')
  }

  var GetSearchTerm = function (list) {
    return list.find('.selection-control input[name="search"]').val()
  }

  var SetSearchTerm = function (list, searchTerm) {
    list.find('.selection-control input[name="search"]').val(searchTerm)

    Reset(list)
    LoadItems(list)
  }

  var GetOrder = function (list) {
    var current = list.find('.order-control .dropdown .current')
    return current.data('id')
  }

  var SetOrder = function (list, order) {
    var current = list.find('.order-control .dropdown .current')
    current.html(order.html())
    current.data('id', order.data('id'))

    Reset(list)
    LoadItems(list)
  }

  var GetFilter = function (list) {
    var current = list.find('.filter-control .dropdown .current')
    return current.data('id')
  }

  var SetFilter = function (list, filter) {
    var current = list.find('.filter-control .dropdown .current')
    current.html(filter.html())
    current.data('id', filter.data('id'))

    Reset(list)
    LoadItems(list)
  }

  var RegenerateNonce = function (list) {
    list.data('nonce', Math.floor(Math.random() * 10000 + 1))
  }

  var CheckNonce = function (list, nonce) {
    return parseInt(list.data('nonce')) === parseInt(nonce)
  }

  var GetCurrentNonce = function (list) {
    return list.data('nonce')
  }

  var GetOffset = function (list) {
    return list.data('offset')
  }

  var SetOffset = function (list, offset) {
    return list.data('offset', offset)
  }

  var ShowLoading = function (list) {
    var button = list.find('.btn.load-more')
    button.html('<i class="fa fa-spin fa-spinner"></i>')
    button.addClass('disabled')
  }

  var IsLoading = function (list) {
    var button = list.find('.btn.load-more')
    return button.hasClass('disabled')
  }

  var HideLoading = function (list) {
    var button = list.find('.btn.load-more')
    button.html('Load more')
    button.removeClass('disabled')
  }

  var ShowNoMoreItems = function (list) {
    var itemsLabel = list.data('items-label')
    HideNoMoreItems(list)
    list.find('table tbody').append('<tr class="no-more"><td colspan="2">No more ' + itemsLabel + '.</td></tr>')
  }

  var HideNoMoreItems = function (list) {
    list.find('table tbody tr.no-more').remove()
  }

  var ShowFailed = function (list) {
    var itemsLabel = list.data('items-label')
    list.find('table tbody tr.no-more').remove()
    list.find('table tbody').append('<tr class="no-more"><td colspan="2">Failed to load ' + itemsLabel + '.</td></tr>')
  }

  var GetSelected = function (list) {
    var items = []
    list.find('.o-list-item input[type="checkbox"]:checked').each(function () {
      items.push($(this).closest('.o-list-item'))
    })

    return items
  }

  var SelectAll = function (list) {
    list.find('.o-list-item input[type="checkbox"]').prop('checked', true)
  }

  var AreAllSelected = function (list) {
    return list.find('input[name="select_all"]').is(':checked')
  }

  var DeselectAll = function (list) {
    list.find('.o-list-item input[type="checkbox"]').prop('checked', false)
  }

  var UpdateTotalSelected = function (list) {
    if (list.data('select-enabled')) {
      var totalDeselected = list.find('.o-list-item .checkbox-col input[type="checkbox"]:not(:checked)').length

      if (totalDeselected > 0) {
        list.find('input[name="select_all"]').prop('checked', false)
      }

      var itemLabel = list.data('item-label')
      var itemsLabel = list.data('items-label')
      var itemsActions = list.find('.items-actions')
      var itemsSelectedCount = list.find('.items-selected-count')
      var totalAvailable = list.find('.o-list-item .checkbox-col input[type="checkbox"]').length
      var totalSelected = list.find('.o-list-item .checkbox-col input[type="checkbox"]:checked').length

      if (AreAllSelected(list)) {
        totalSelected = GetTotalItems(list)
      } else {
        list.find('.checkbox-col input[name="select_all"]').prop('checked', (totalAvailable === totalSelected && totalAvailable != 0))
      }

      itemsSelectedCount.html('(' + totalSelected + ' ' + (totalSelected !== 1 ? itemsLabel : itemLabel) + ')')

      if (totalSelected > 0) {
        list.find('.o-list-item .actions').fadeOut(150)

        if (!itemsActions.is(':visible')) {
          itemsActions.finish().hide().fadeIn(150).css('display', 'inline-block')
        }

        if (!itemsSelectedCount.is(':visible')) {
          itemsSelectedCount.finish().hide().fadeIn(150).css('display', 'inline-block')
        }
      } else {
        list.find('.o-list-item .actions').fadeIn(150)

        if (itemsActions.is(':visible')) {
          itemsActions.finish().fadeOut(150)
        }

        if (itemsSelectedCount.is(':visible')) {
          itemsSelectedCount.finish().fadeOut(150)
        }
      }
    }
  }

  var HideControls = function (container) {
    if (container.hasClass('o-list-item')) {
      Onkho.LoadingTools.ShowLoading(container.find('.actions'))
    }

    if (container.hasClass('o-list')) {
      Onkho.LoadingTools.ShowLoading(container.find('.items-actions'))
    }
  }

  var ShowControls = function (container) {
    if (container.hasClass('o-list-item')) {
      Onkho.LoadingTools.HideLoading(container.find('.actions'))
    }

    if (container.hasClass('o-list')) {
      Onkho.LoadingTools.HideLoading(container.find('.items-actions'))
    }
  }

  var Reset = function (list) {
    list.find('input[name="select_all"]').prop('checked', false).trigger('change')
    list.find('table tbody').empty()
    list.data('offset', 0)
    list.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>')
  }

  var Refresh = function (list) {
    Reset(list)
    LoadItems(list)
  }

  return {
    Init: Init,
    Lock: Lock,
    Unlock: Unlock,
    Refresh: Refresh,
    GetSelected: GetSelected,
    ShowContentPlaceholder: ShowContentPlaceholder,
    HideContentPlaceHolder: HideContentPlaceholder
  }
}()
