// Module containing tools to be used when displaying a documents picker
Onkho.DocumentsPicker = function ()
{
    var Init = function (documentsPicker, onChangeCallback)
    {
        // onChange event
        documentsPicker.on('onkho:document-picker.change', function ()
        {
            if (typeof onChangeCallback !== 'undefined')
            {
                onChangeCallback(documentsPicker);
            }
        });

        // Select all
        documentsPicker.find('input[name="select_all"]').on('change', function ()
        {
            if ($(this).is(':checked'))
            {
                SelectAll(documentsPicker);
            }
            else
            {
                DeselectAll(documentsPicker);
            }
        });

        // Selected documents counter
        documentsPicker.on('change', 'input[type="checkbox"]', function ()
        {
            UpdateTotalSelected(documentsPicker);

            documentsPicker.trigger('onkho:document-picker.change');

            if ($(this).is(':checked'))
            {
                $(this).closest('.document').addClass('selected');
            }
            else
            {
                $(this).closest('.document').removeClass('selected');
            }
        });

        // Sort
        documentsPicker.find('.sort-control .dropdown li a').on('click', function ()
        {
            var current = documentsPicker.find('.sort-control .dropdown .current');
            current.html($(this).html());
            current.data('value', $(this).data('value'));

            ResetDocumentsList(documentsPicker);
            LoadMore(documentsPicker);
        });

        // Search
        documentsPicker.on('keyup', 'input[name="documents_search"]', function ()
        {
            Delay('DocumentsPickerSearch', function ()
            {
                if (documentsPicker.find('.search-control input[name="documents_search"]').val().length > 0)
                {
                    documentsPicker.find('.search-control .clear-search').show();
                }
                else
                {
                    documentsPicker.find('.search-control .clear-search').hide();
                }

                ResetDocumentsList(documentsPicker);
                LoadMore(documentsPicker);
            }, 500);
        });

        documentsPicker.on('click', '.clear-search', function ()
        {
            documentsPicker.find('.search-control input[name="documents_search"]').val('');
            $(this).hide();

            ResetDocumentsList(documentsPicker);
            LoadMore(documentsPicker);
        });

        // Infinity scroll
        documentsPicker.on('click', '.btn.load-more', function ()
        {
            if (!$(this).hasClass('disabled'))
            {
                LoadMore(documentsPicker);
            }
        });

        $(window).on('scroll', function()
        {
            if ($(window).scrollTop() > ($(document).height() - $(window).height() - 200))
            {
                if (documentsPicker.find('.btn.load-more').length && !documentsPicker.find('.btn.load-more').hasClass('disabled'))
                {
                    LoadMore(documentsPicker);
                }
            }
        }).scroll();

        // Refresh
        documentsPicker.find('.refresh').on('click', function ()
        {
            if (!documentsPicker.find('.btn.load-more').hasClass('disabled'))
            {
                ResetDocumentsList(documentsPicker);
                LoadMore(documentsPicker);
            }
        });

        // Path
        documentsPicker.on('click', '.path-dropdown .dropdown-menu .path-bit', function ()
        {
            SetPath(documentsPicker, $(this).data('path'));
        });

        documentsPicker.on('click', '.document.directory .filename', function ()
        {
            SetPath(documentsPicker, $(this).data('path'));
        });

        SetPath(documentsPicker, '/');
    };

    var Delay = function(timer, callback, delay)
    {
        clearTimeout(Onkho.Variables[timer]);
        Onkho.Variables[timer] = setTimeout(callback, delay);
    };

    var GetSelected = function (documentsPicker)
    {
        var documents = [];
        documentsPicker.find('.document input[type="checkbox"]:checked').each(function ()
        {
            documents.push($(this).closest('.document'));
        });

        return documents;
    };

    var SelectAll = function (documentsPicker)
    {
        documentsPicker.find('.document input[type="checkbox"]').prop('checked', true);
        documentsPicker.find('.document input[type="checkbox"]:checked').closest('.document').addClass('selected');
    };

    var DeselectAll = function (documentsPicker)
    {
        documentsPicker.find('.document input[type="checkbox"]').prop('checked', false);
        documentsPicker.find('.document').removeClass('selected');
    };

    var Select = function (documentsPicker, documentId)
    {
        documentsPicker.find('.document[data-id="' + documentId + '"] input[type="checkbox"]').prop('checked', true).trigger('change');
        documentsPicker.find('.document[data-id="' + documentId + '"]').addClass('selected');
    };

    var Deselect = function (documentsPicker, documentId)
    {
        documentsPicker.find('.document[data-id="' + documentId + '"] input[type="checkbox"]').prop('checked', false).trigger('change');
        documentsPicker.find('.document[data-id="' + documentId + '"]').removeClass('selected');
    };

    var UpdateTotalSelected = function (documentsPicker)
    {
        var totalAvailable = documentsPicker.find('.document .checkbox-col input[type="checkbox"]');
        var totalSelected = totalAvailable.filter(':checked');

        documentsPicker.find('.checkbox-col input[name="select_all"]').prop('checked', (totalAvailable.length === totalSelected.length && totalAvailable.length != 0));
    };

    var Reload = function (documentsPicker)
    {
        ResetDocumentsList(documentsPicker);
        LoadMore(documentsPicker);
    };

    var ResetDocumentsList = function (documentsPicker)
    {
        documentsPicker.trigger('onkho:document-picker.change');

        documentsPicker.find('input[name="select_all"]').prop('checked', false).trigger('change');
        documentsPicker.find('table tbody').empty();
        documentsPicker.removeData('offset');
        documentsPicker.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
    };

    var LoadMore = function (documentsPicker)
    {
        var button = documentsPicker.find('.btn.load-more');
        button.html('<i class="fa fa-spin fa-spinner"></i>');
        button.addClass('disabled');

        // Regenerate nonce
        documentsPicker.data('nonce', Math.floor(Math.random() * 10000 + 1));

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.partyId = documentsPicker.find('[name="partyId"]').attr('value');
        formData.nonce = documentsPicker.data('nonce');
        formData.disallowFolderSelection = 1;
        formData.withActions = 0;

        var path = GetPath(documentsPicker);
        if (Onkho.Variables.DocumentsPath != path)
        {
            // The path has changed
            formData.offset = 0;
            Onkho.Variables.DocumentsPath = path;
        }
        else
        {
            // The path is the same, keep loading files
            formData.offset = documentsPicker.data('offset');
        }

        formData.path = path;

        var searchTerm = documentsPicker.find('input[name="documents_search"]').val();
        if (Onkho.Variables.DocumentsSearchTerm != searchTerm)
        {
            // The path has changed
            formData.offset = 0;
            Onkho.Variables.DocumentsSearchTerm = searchTerm;
        }
        else
        {
            // The path is the same, keep loading files
            formData.offset = documentsPicker.data('offset');
        }

        formData.searchTerm = searchTerm;

        formData.sort = documentsPicker.find('.sort-control .current').data('value');

        if (parseInt(formData.partyId)) {
            $.ajax(
                {
                    type: 'GET',
                    url: Onkho.Variables.BaseURL + '/storage/loadMore',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                    {
                        switch (data.status)
                        {
                            case 200:
                                if (data.responseJSON.nonce == documentsPicker.data('nonce'))
                                {
                                    if (data.responseJSON.html.length > 0)
                                    {
                                        documentsPicker.find('table tbody').append(data.responseJSON.html);
                                        documentsPicker.data('offset', data.responseJSON.offset);
                                        button.html('Load more');
                                        button.removeClass('disabled');
                                    }
                                    else
                                    {
                                        documentsPicker.find('table tbody').append('<tr><td colspan="2" class="no-more">No more documents.</td></tr>');
                                        documentsPicker.find('.btn.load-more').remove();
                                    }
                                }
                                break;
                            default:
                                // Failed
                                documentsPicker.find('table tbody').append('<tr><td colspan="2" class="no-more">Failed to load documents.</td></tr>');
                                documentsPicker.find('.btn.load-more').remove();
                                if (data.responseJSON.message)
                                {
                                    Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message);
                                }
                                break;
                        }
                    }
                });
        }
    };

    var SetPath = function (documentsPicker, path)
    {
        var pathWrapper = documentsPicker.find('.path-dropdown .dropdown-menu');
        pathWrapper.empty();

        var pathBits = path.split('/');
        var currentPath = '/';

        pathWrapper.append('\n' +
                           '<li class="path-bit-wrapper">' +
                               '<a href="javascript:void(0);" class="path-bit" data-path="' + currentPath + '">Documents</a>' +
                           '</li>');

        $(pathBits).each(function (index, pathBit)
        {
            if (pathBit.length != 0)
            {
                if (currentPath.length == 0)
                {
                    currentPath = pathBit;
                }
                else
                {
                    currentPath += '/' + pathBit;
                }

                pathWrapper.append('\n' +
                    '<li class="path-bit-wrapper">' +
                        '<a href="javascript:void(0);" class="path-bit" data-path="' + currentPath + '">' + pathBit + '</a>' +
                    '</li>');
            }
        });

        var currentDirectory = pathWrapper.find('.path-bit-wrapper:last-of-type');
        documentsPicker.find('.current-directory').html(currentDirectory.find('a').html());
        documentsPicker.find('.current-directory').data('path', currentDirectory.find('a').data('path'));
        currentDirectory.remove();

        if (pathWrapper.find('.path-bit-wrapper').length == 0)
        {
            // Root directory
            documentsPicker.find('.path-dropdown').addClass('soft-hide');
            documentsPicker.find('.current-directory').addClass('soft-hide');
        }
        else
        {
            documentsPicker.find('.path-dropdown').removeClass('soft-hide');
            documentsPicker.find('.current-directory').removeClass('soft-hide');
        }

        ResetDocumentsList(documentsPicker);
        LoadMore(documentsPicker);
    };

    var GetPath = function (documentsPicker)
    {
        return documentsPicker.find('.current-directory').data('path');
    };

    return {
        Init: Init,
        GetSelected: GetSelected,
        SelectAll: SelectAll,
        DeselectAll: DeselectAll,
        Select: Select,
        Deselect: Deselect,
        GetPath: GetPath,
        SetPath: SetPath,
        Reload: Reload
    };
}();
