Onkho.AddServiceInstanceAutoplanSubpanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings()

    RegisterEventListeners()
  }

  var RegisterEventListeners = function ()
  {
    // Prevent ENTER from refreshing the page
    $('body').on('keypress', '.o-panel[data-panel-key="add_service_instance_autoplan"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    })
  }

  var LoadAddServiceInstanceAutoplanSubpanel = function (configuration)
  {
    var additionalData = {
      configuration: configuration
    }

    Onkho.OnkhoPanel.Add('add_service_instance_autoplan', additionalData, LoadAddServiceInstanceAutoplanSubpanelLoaded)
  }

  var LoadAddServiceInstanceAutoplanSubpanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);

    $('[rel=tooltip]').tooltip('hide');

    var autoplanJobsToggle = panel.find('[name="autoplan_jobs"]')
    autoplanJobsToggle.on('change', function () {
      if ($(this).prop('checked')) {
        panel.find('.autoplan-configuration').slideDown(150)
      } else {
        panel.find('.autoplan-configuration').slideUp(150)
      }
    })
    autoplanJobsToggle.trigger('change')
  }

  var RegisterAddServiceInstanceAutoplanSubpanelBindings = function (panel)
  {
    panel.find('.close[data-id="close"], [data-id="cancel"]').on('click', function ()
    {
      panel.find('input, select').select2('destroy');
      Onkho.OnkhoPanel.Remove(panel);
      Onkho.OnkhoPanel.Enable($('.o-panel[data-panel-key="add_service_instance"]'))
    })

    panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
    {
      SaveAutoplan(panel)
    })
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[add_service_instance_autoplan].added', '.o-panel-container', function ()
    {
      var addServicesAutoplanSubpanel = $('.o-panel[data-panel-key="add_service_instance_autoplan"]');
      RegisterAddServiceInstanceAutoplanSubpanelBindings(addServicesAutoplanSubpanel);
    })
  }

  var SaveAutoplan = function (panel) {
    if (Onkho.Validator.ValidateChildren(panel)) {
      var addServiceInstancePanel = $('.o-panel[data-panel-key="add_service_instance"]')
      var configuration = GatherConfiguration(panel)
      Onkho.AddServiceInstancePanel.SetAutoplanSectionConfiguration(addServiceInstancePanel, configuration)
      panel.find('.close[data-id="close"]').click()
    }
  }

  var GatherConfiguration = function (panel) {
    var configuration = {}

    if (panel.find('[name="autoplan_jobs"]').prop('checked')) {
      configuration.autoplanning_time_unit_count = panel.find('[name="autoplanning_time_unit_count"]').val()
      configuration.autoplanning_time_unit = panel.find('[name="autoplanning_time_unit"]').val()
    } else {
      configuration.autoplanning_time_unit_count = null
      configuration.autoplanning_time_unit = null
    }

    return configuration
  }



  return {
    Init: Init,
    LoadAddServiceInstanceAutoplanSubpanel: LoadAddServiceInstanceAutoplanSubpanel
  };
}();
