// Module containing tools to be used when displaying a list of message templates
Onkho.MessageTemplateListTools = function () {

    var messageTemplatesTab  = null;
    var messageTemplatesList = null;

    var ResetMessageTemplateList =
        function () {
            messageTemplatesList.find('table tbody').empty();
            messageTemplatesList.removeData('offset');
            messageTemplatesList.find('.load-more-wrapper').html('<a href="javascript:void(0);" class="btn btn-default margin-top-10 center-block load-more">Load more</a>');
        }
    ;

    var LoadMore =
        function (force) {

            force = (typeof(force) === 'undefined') ? false : force;

            if (!force && !messageTemplatesTab.is(':visible')) {
                return;
            }

            var loadMoreButton = messageTemplatesList.find('.btn.load-more');
            loadMoreButton.html('<i class="fa fa-spin fa-spinner"></i>');
            loadMoreButton.addClass('disabled');

            var loadMoreData = {};
            loadMoreData._token = Onkho.Functions.GetCSRF();

            loadMoreData.offset = messageTemplatesList.data('offset');
            loadMoreData.sort   = messageTemplatesList.find('.sort-control .current').data('value');
            loadMoreData.filter = messageTemplatesList.find('.filter-control .current').data('value');

            $.ajax({
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/template/loadMore',
                data: loadMoreData,
                dataType: 'json',
                complete: function (data) {

                    if (data.status === 200) {
                        var offset = data.responseJSON.offset;
                        var html   = $(data.responseJSON.html);

                        messageTemplatesList.find('table tbody').append(html);
                        messageTemplatesList.data('offset', offset);

                        if (html.length > 0) {
                            loadMoreButton.html('Load more');
                            loadMoreButton.removeClass('disabled');
                        } else {
                            messageTemplatesList.find('table tbody').append('<tr><td colspan="2" class="no-more">No more templates.</td></tr>');
                            loadMoreButton.remove();
                        }
                    } else {
                        messageTemplatesList.find('table tbody').append('<tr><td colspan="2" class="no-more">Failed to load templates. Please try again later.</td></tr>');
                        loadMoreButton.remove();
                    }
                }
            });
        }
    ;

    var Delete =
        function (messageTemplates) {

            HideControls(messageTemplatesList.find('.global-actions'));

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.messageTemplateIds = [];

            $(messageTemplates).each(function () {
                formData.messageTemplateIds.push($(this).data('id'));
                HideControls($(this));
            });

            $.ajax({
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/templates/delete',
                data: formData,
                dataType: 'json'
            }).done(
                function(data) {
                    Onkho.Alert.SmallBox('success', data.message);

                    $(data.deletedMessageTemplateIds).each(
                        function (key, messageTemplateId) {
                            var messageTemplate = messageTemplatesList.find('.message-template').filter('[data-id="' + messageTemplateId + '"]');
                            messageTemplate.fadeOut(150,
                                function () {
                                    messageTemplate.remove();
                                }
                            );
                        }
                    );
                }
            ).fail(
                function(data) {
                    Onkho.Alert.BigBox(data['status'] ? data['status'] : 'danger', data['title'] ? data['title'] : 'Failed to delete templates', data['message'] ? data['message'] : 'We were unable to process your request. Please try again later.');
                }
            ).always(
                function() {
                    ShowControls(messageTemplatesList.find('.global-actions'));
                    UpdateTotalSelected();
                }
            );
        }
    ;

    var UpdateTotalSelected =
        function () {
            var totalSelected = messageTemplatesList.find('.message-template input[type="checkbox"]').filter(':checked').length;
            var globalActions = messageTemplatesList.find('.global-actions');

            globalActions.find('label').html('Selected ' + totalSelected + ' template' + (totalSelected !== 1 ? 's' : ''));

            if (totalSelected > 0) {
                globalActions.finish();
                globalActions.fadeIn(150).css('display', 'inline-block');
            } else {
                globalActions.finish();
                globalActions.fadeOut(150);
            }
        }
    ;

    var HideControls =
        function (container) {
            container.find('.message-template-action-buttons').html('<i class="fa fa-spin fa-spinner"></i>');
        }
    ;

    var ShowControls =
        function (container) {
            container.find('.message-template-action-buttons').empty();
        }
    ;

    var InitListSelection  =
        function() {

            messageTemplatesList.on(
                'mouseenter', '.message-template',
                function () {
                    var buttons = $(this).find('.message-template-action-buttons');
                    buttons.finish();
                    buttons.fadeIn(150);
                }
            );

            messageTemplatesList.on(
                'mouseleave', '.message-template',
                function () {
                    var buttons = $(this).find('.message-template-action-buttons');
                    buttons.finish();
                    buttons.fadeOut(150);
                }
            );

            messageTemplatesList.on(
                'change', 'thead input[type="checkbox"][name="select_all"]',
                function () {
                    var allChecked = $(this).is(':checked');
                    messageTemplatesList.find('tbody input[type="checkbox"]').prop('checked', allChecked);
                    Onkho.Variables.SelectAllMessageTemplates = allChecked;
                    UpdateTotalSelected();
                }
            );

            messageTemplatesList.on(
                'change', 'tbody input[type="checkbox"]',
                function () {
                    var messageTemplates = messageTemplatesList.find('.message-template input[type="checkbox"]');
                    var allChecked       = messageTemplates.length === messageTemplates.filter(':checked').length;
                    messageTemplatesList.find('thead input[type="checkbox"][name="select_all"]').prop('checked', allChecked);
                    Onkho.Variables.SelectAllMessageTemplates = allChecked;
                    UpdateTotalSelected();
                }
            );
        }
    ;

    var InitSort =
        function() {

            messageTemplatesList.find('.sort-control .dropdown li a').on(
                'click',
                function () {
                    var current = messageTemplatesList.find('.sort-control .dropdown .current');
                    current.html($(this).html());
                    current.data('value', $(this).data('value'));

                    ResetMessageTemplateList();
                    LoadMore();
                }
            );
        }
    ;

    var InitFilter =
        function() {

            messageTemplatesList.find('.filter-control .dropdown li a').on(
                'click',
                function () {
                    var current = messageTemplatesList.find('.filter-control .dropdown .current');
                    current.html($(this).html());
                    current.data('value', $(this).data('value'));

                    ResetMessageTemplateList();
                    LoadMore();
                }
            );
        }
    ;

    var InitActions =
        function() {

            messageTemplatesList.on('click',
                '.message-template .message-template-action-buttons .delete',
                function () {
                    var messageTemplates = $(this).closest('.message-template');

                    $.SmartMessageBox({
                        title   : "Deleting template " + messageTemplates.data('name'),
                        content : "Are you sure you want to delete this template?",
                        buttons : "[No][Yes]"
                    }, function(ButtonPress) {
                        if (ButtonPress === 'Yes') {
                            Delete(messageTemplates);
                        }
                    });
                }
            );

            messageTemplatesList.on('click',
                '.global-actions .delete',
                function () {
                    var messageTemplates = messageTemplatesList.find('.message-template input[type="checkbox"]').filter(':checked').closest('.message-template');

                    $.SmartMessageBox({
                        title   : (messageTemplates.length === 1) ? "Deleting template " + messageTemplates.data('name') : "Deleting " + (messageTemplates.length) + " templates",
                        content : (messageTemplates.length === 1) ? "Are you sure you want to delete this template?" : "Are you sure you want to delete these templates?",
                        buttons : "[No][Yes]"
                    }, function(ButtonPress) {
                        if (ButtonPress === 'Yes') {
                            Delete(messageTemplates);
                        }
                    });
                }
            );

            messageTemplatesList.find('.refresh').on(
                'click',
                function () {
                    var loadMoreButton = messageTemplatesList.find('.btn.load-more').filter(':not(.disabled)');
                    if (loadMoreButton.length) {
                        ResetMessageTemplateList();
                        LoadMore();
                    }
                }
            );
        }
    ;

    var InitInfinityScroll =
        function() {

            messageTemplatesList.find('.btn.load-more').on(
                'click',
                function () {
                    if (!$(this).hasClass('disabled')) {
                        LoadMore();
                    }
                }
            );

            $(window).on(
                'scroll',
                function() {
                    var loadMoreButton = messageTemplatesList.find('.btn.load-more').filter(':not(.disabled)');
                    if (loadMoreButton.length) {
                        LoadMore();
                    }
                }
            ).scroll();
        }
    ;

    var Init =
        function () {

            messageTemplatesTab  = $('#messageTemplates');
            messageTemplatesList = messageTemplatesTab.find('.message-template-list');

            InitListSelection();
            InitSort();
            InitFilter();
            InitActions();

            LoadMore(true);
            InitInfinityScroll();
    };

    return {
        Init: Init
    };
}();
