// Module containing tools to be used when displaying the quick import page
Onkho.QuickImportPageTools = function () {
  var loadingNewCompanySlot = false

  var Init = function () {
    RegisterEventListeners()

    CreateNewCompanySlot()
  }

  var RegisterEventListeners = function () {
    // Auto add new company slot
    $('.quick-import .company-slots-container').on('focus blur', '.company-slot input', function () {
      if (!loadingNewCompanySlot && !IsLastCompanySlotEmpty()) {
        CreateNewCompanySlot()
      }
    })

    // Delete and dismiss buttons
    $('.quick-import .company-slots-container').on('click', '.company-slot .delete, .company-slot .dismiss', function () {
      DeleteCompanySlot($(this).closest('.company-slot'))
    })

    // Quick import button
    $('.quick-import button[data-id="quick-import"]').on('click', function () {
      QuickImport()
    })

    // Show/hide the footer on keypress
    $('.quick-import').on('keyup keypress', function () {
      RecomputeQuickImportFooterVisibility()
    })
  }

  var RecomputeQuickImportFooterVisibility = function () {
    var foundCompanyNumber = false
    $('.quick-import .company-slot:not(.result)').each(function (index, companySlotElement) {
      if ($(companySlotElement).find('[name="incorporation_number"]').val().length > 0) {
        foundCompanyNumber = true
        return false
      }
    })

    if (foundCompanyNumber) {
      $('.quick-import .footer').slideDown(150)
    } else {
      $('.quick-import .footer').slideUp(150)
    }
  }

  var QuickImport = function () {
    Onkho.LoadingTools.ShowLoading($('.quick-import button[data-id="quick-import"]'))
    LockCompanySlots()

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.companies = GatherCompanySlotsData()

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/import/quick',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              $(data.responseJSON.data).each(function (index, result) {
                if (result.status === 'OK') {
                  $('.quick-import .company-slot[data-id="' + result.incorporation_number + '"]').replaceWith(result.html)
                } else if (result.status === 'NOT_FOUND') {
                  $('.quick-import .company-slot[data-id="' + result.incorporation_number + '"] .message').html(result.message)
                } else if (result.status === 'ERROR') {
                  $('.quick-import .company-slot[data-id="' + result.incorporation_number + '"] .message').html(result.message)
                }
              })
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }

          $('.quick-import [rel="tooltip"]').tooltip()
          Onkho.LoadingTools.HideLoading($('.quick-import button[data-id="quick-import"]'))
          UnlockCompanySlots()
        }
      })

  }

  var DeleteCompanySlot = function (companySlotElement) {
    companySlotElement.slideUp(150, function () {
      companySlotElement.remove()

      if (!IsLastCompanySlotEmpty()) {
        CreateNewCompanySlot()
      }
    })
  }

  var CreateNewCompanySlot = function () {
    if ($('.quick-import .company-slot:not(.result)').length >= 10) {
      return;
    }

    $('.quick-import .company-slot-loader').show()
    loadingNewCompanySlot = true

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()

    $.ajax(
      {
        type: 'GET',
        url: Onkho.Variables.BaseURL + '/import/quick/getNewCompanySlot',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              var companySlot = $(data.responseJSON.html)
              $('.quick-import .company-slots-container').append(companySlot)
              companySlot.find('[name="vat_return_schedule"]').select2({
                  placeholder: 'VAT Return Schedule',
              })
              $('.quick-import .company-slot-loader').hide()
              $('.quick-import [rel="tooltip"]').tooltip()
              loadingNewCompanySlot = false
              RecomputeQuickImportFooterVisibility()
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var GatherCompanySlotsData = function () {
    var data = []
    $('.quick-import .company-slot').each(function (index, companySlotElement) {
      var incorporationNumber = $(companySlotElement).find('[name="incorporation_number"]').val().trim()
      var VATReturnSchedule = $(companySlotElement).find('[name="vat_return_schedule"]').val()

      data.push({
        incorporation_number: incorporationNumber,
        vat_return_schedule: VATReturnSchedule
      })
    })

    return data
  }

  var LockCompanySlots = function () {
    $('.quick-import .company-slot').each(function (index, companySlotElement) {
      if ($(companySlotElement).hasClass('result')) {
        $(companySlotElement).remove()
      } else {
        var incorporationNumber = $(companySlotElement).find('[name="incorporation_number"]').val().trim()
        if (incorporationNumber.length > 0) {
          $(companySlotElement).addClass('locked')
          $(companySlotElement).find('input').attr('disabled', true)
          $(companySlotElement).find('select').select2('enable', false)
          $(companySlotElement).attr('data-id', $(companySlotElement).find('[name="incorporation_number"]').val())
        } else {
          $(companySlotElement).remove()
        }
      }
    })
  }

  var UnlockCompanySlots = function () {
    $('.quick-import .company-slot').each(function (index, companySlotElement) {
      $(companySlotElement).removeClass('locked')
      $(companySlotElement).find('input').attr('disabled', false)
      $(companySlotElement).find('select').select2('enable', true)
    })

    if (!IsLastCompanySlotEmpty()) {
      CreateNewCompanySlot()
    }

    RecomputeQuickImportFooterVisibility()
  }

  var IsLastCompanySlotEmpty = function (companySlotElement) {
    var lastCompanySlot = $('.quick-import .company-slot:not(.result)').last()
    return lastCompanySlot.length > 0 && lastCompanySlot.find('[name="incorporation_number"]').val().length === 0
  }



  return {
    Init: Init
  }
}();
