Onkho.ViewSMSBoxPanel = function ()
{
  var Init = function ()
  {
    RegisterPanelBindings();

    RegisterEventListeners();
  };

  var RegisterEventListeners = function ()
  {
    var panel = $('.o-panel[data-panel-key="view_smsbox"]');

    panel.find('.close[data-id="close"]').on('click', function ()
    {
      Onkho.OnkhoPanel.Remove(panel);
    });

    // Prevent ENTER from refreshing the page in the view SMS box panel
    $('body').on('keypress', '.o-panel[data-panel-key="view_smsbox"] *', function (event)
    {
      var key = event.which;
      if (key == 13)
      {
        event.preventDefault()
      }
    });
  };

  var LoadViewSMSBoxPanel = function (smsboxId)
  {
    var additionalData = {
      'smsboxId': smsboxId
    };

    Onkho.OnkhoPanel.Add('view_smsbox', additionalData, ViewSMSBoxPanelLoaded)
  };

  var ViewSMSBoxPanelLoaded = function (panel)
  {
    Onkho.OnkhoPanel.Show(panel);
  };

  var RegisterViewSMSBoxPanelBindings = function (panel)
  {
    panel.find('button[data-id="edit"]').on('click', function ()
    {
      Onkho.EditSMSBoxPanel.LoadEditSMSBoxPanel(panel.data('smsbox-id'));
      Onkho.OnkhoPanel.RemoveAll();
    });

    panel.find('button[data-id="delete"]').on('click', function ()
    {
      ConfirmDeleteSMSBox();
    });
  };

  var ConfirmDeleteSMSBox = function ()
  {
    $.SmartMessageBox(
      {
        title: 'Delete phone number',
        content: 'Are you sure you want to delete this phone number?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DeleteSMSBox()
        }
      }
    )
  }

  var DeleteSMSBox = function ()
  {
    var panel = $('.o-panel[data-panel-key="view_smsbox"]');
    Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="delete"]'));

    var formData = {}
    formData._token = Onkho.Functions.GetCSRF()
    formData.smsboxId = panel.data('smsbox-id')

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/smsbox/deleteSMSBox',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              Onkho.Alert.SmallBox('success', data.responseJSON.message)

              // Refresh SMS boxes list, if one exists
              var SMSBoxesList = $('.smsboxes-list');
              if (SMSBoxesList.length > 0) {
                Onkho.OnkhoList.Refresh(SMSBoxesList);
              }

              // Close panel
              Onkho.OnkhoPanel.RemoveAll();
              break

            default:
              Onkho.Alert.BigBox('danger', 'Error', data.responseJSON.message)
              break
          }
        }
      })
  }

  var RegisterPanelBindings = function ()
  {
    $('body').on('onkho:panel[view_smsbox].added', '.o-panel-container', function ()
    {
      var viewSMSBoxPanel = $('.o-panel[data-panel-key="view_smsbox"]');
      RegisterViewSMSBoxPanelBindings(viewSMSBoxPanel);
    });
  };



  return {
    Init: Init,
    LoadViewSMSBoxPanel: LoadViewSMSBoxPanel
  };
}();
