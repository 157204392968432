// Module containing tools to be used by the quick-actions bar
Onkho.QuickactionsTools = function ()
{
    var quickActionsBar            = null;
    var quickActionsModalsWrapper  = null;
    var quickActionAddContactModal = null;
    var quickActionAddClientModal  = null;
    var quickActionAddCommonServicesModal = null;
    var quickActionAddNote         = null;
    var quickActionAddNoteModal    = null;
    var quickActionAddMessage      = null;
    var quickActionAddMessageModal = null;

    var InitQuickActionAddContact =
        function() {

            quickActionAddContactModal = quickActionsModalsWrapper.find('.modal.add-contact');

            quickActionAddContactModal.find('form select').select2({
                sortResults: Onkho.Functions.Select2Sort
            });

            quickActionAddContactModal.on('hidden.bs.modal',
                function () {
                    quickActionAddContactModal.find('form select').select2('val', '');
                }
            );

            quickActionAddContactModal.on('click', '.save',
                function () {
                    var type = quickActionAddContactModal.find('form select[name="type"]').val();
                    if (type && type.length) {
                        window.location = Onkho.Variables.BaseURL + '/contact/create?type=' + type;
                    }
                }
            );
        };

    var InitQuickActionAddClient =
        function() {

            quickActionAddClientModal = quickActionsModalsWrapper.find('.modal.add-client');

            quickActionAddClientModal.on('hidden.bs.modal',
                function () {
                    LoadDefaultAddClientModal();
                }
            );

            quickActionAddClientModal.find('form input[name="client_create_method"]:radio').change(
                function() {
                    if ($(this).is(':checked')) {
                        if ($(this).val() === 'client-create-company-house-number') {
                            showClientCreateCompanyHouseNumber();
                        } else if ($(this).val() === 'client-create-manually') {
                            showClientCreateManually();
                        }
                    } else {
                        hideClientCreate();
                    }
                }
            );

            quickActionAddClientModal.find('form .client-create-manually input[name="type"]:radio').change(
                function() {
                    updateCreateClientOkButton(false, 'choose-common-services');
                    updateCommonServicesOkButton(false, 'create-client-manually');
                }
            );

            quickActionAddClientModal.find('form .client-create-company-house-number .btn.search-company-house').on('click',
                function () {
                    var companiesHouseSearchStringTextBox = $('#companiesHouseSearchString');
                    var addCommonServices = ReadUserSelectionOptionForAddCommonServices();
                    SearchUsingCompanyHouseAPI(companiesHouseSearchStringTextBox.val(), addCommonServices);
                }
            );

            quickActionAddCommonServicesModal = quickActionsModalsWrapper.find('.modal.add-common-services');
        };

    var InitQuickActionAddNote =
        function() {

            quickActionAddNote      = quickActionsBar.find('.add-note');
            quickActionAddNoteModal = quickActionsModalsWrapper.find('.modal.add-note-modal');

            var quickActionAddNoteForm  = quickActionAddNoteModal.find('form');

            Onkho.Validator.ValidateForm(quickActionAddNoteModal);

            quickActionAddNoteForm.find('select[name="quick_actions_client_id"]').select2({
                placeholder: 'Select client',
                sortResults: Onkho.Functions.Select2Sort
            });

            quickActionAddNote.on('click',
                function () {
                    var editor = quickActionAddNoteForm.find('div[name="activity-feed-note-editor"].summernote');
                    Onkho.Summernote.UpdateSummernote(editor, '');
                    quickActionAddNoteModal.modal('show');
                }
            );
        };

    var InitQuickActionAddMessage =
        function() {

            quickActionAddMessage      = quickActionsBar.find('.add-message');
            quickActionAddMessageModal = quickActionsModalsWrapper.find('.modal.add-message-modal');

            var quickActionAddMessageForm  = quickActionAddMessageModal.find('form');

            Onkho.Validator.ValidateForm(quickActionAddMessageModal);

            quickActionAddMessageForm.find('select[name="quick_actions_client_id"]').select2({
                placeholder: 'Select client',
                sortResults: Onkho.Functions.Select2Sort
            });

            quickActionAddMessage.on('click',
                function () {
                    var editor = quickActionAddMessageForm.find('div[name="activity-feed-message-editor"].summernote');
                    Onkho.Summernote.UpdateSummernote(editor, '');
                    quickActionAddMessageModal.modal('show');
                }
            );
        };

    var Init = function ()
    {
        Onkho.Validator.Init();
        Onkho.RegisterJobTools.Init();
        Onkho.FormEnhancements.Init();

        quickActionsBar = $('.quickactions');
        quickActionsModalsWrapper = $('.quickactions-modals');

        InitQuickActionAddContact();

        InitQuickActionAddClient();

        InitQuickActionAddNote();

        InitQuickActionAddMessage();

        ButtonBindings();
    };

    var ButtonBindings =
        function () {

            quickActionAddClientModal.find('input[name="companiesHouseSearchString"]').on('keypress', function (event)
            {
                if (event.keyCode == 13)
                {
                    event.preventDefault();
                    quickActionAddClientModal.find('.btn.search-company-house').click();
                }
            });

            quickActionAddClientModal.find('button.ok,button.yes').on('click',
                function () {

                    var action = $(this).data('action');
                    switch (action) {
                        case 'choose-common-services':
                          quickActionAddCommonServicesModal.modal('show');
                          break;

                        case 'verify-selected-company-house-api-item':
                            var companiesHouseNumber       = quickActionAddClientModal.find('.company-house-search-results-table input[name="company-house-api-item"]:checked').val();
                            var companiesHouseSearchString = $('#companiesHouseSearchString').val();
                            var addCommonServices          = ReadUserSelectionOptionForAddCommonServices();
                            FetchSelectedCompanyInformation(companiesHouseNumber, companiesHouseSearchString, addCommonServices);
                            updateCommonServicesOkButton(false, 'process-selected-company-house-api-item');
                            break;
                    }
                }
            );

            quickActionAddClientModal.find('button.cancel,button.no').on('click',
                function () {

                    var action = $(this).data('action');
                    switch (action) {
                        case 'back-company-house-search-result':
                            var companiesHouseSearchString = $('#companiesHouseSearchString').val();
                            var addCommonServices = ReadUserSelectionOptionForAddCommonServices();
                            SearchUsingCompanyHouseAPI(companiesHouseSearchString, addCommonServices);
                            break;

                        case 'back-to-default-add-to-client-modal':
                            LoadDefaultAddClientModal();
                            break;
                    }
                }
            );

            quickActionAddCommonServicesModal.find('button.ok,button.yes').on('click',
              function () {

                var action = $(this).data('action');
                switch (action) {
                  case 'create-client-manually':
                    var type              = quickActionAddClientModal.find('.client-create-manually input[name="type"]:checked').val();
                    var addCommonServices = ReadUserSelectionOptionForAddCommonServices();
                    RedirectUserForPartyCreate(type, addCommonServices, []);
                    break;

                  case 'process-selected-company-house-api-item':
                    RedirectUserWithCompanyHouseInformation();
                    break;
                }
              }
            );
        };

    var ReadUserSelectionOptionForAddCommonServices =
        function () {
            var addCommonServices = quickActionAddCommonServicesModal.find('input[name="addCommonServices"]').is(':checked');
            return addCommonServices ? 1 : 0;
        };

    var SearchResultControlBindings =
        function () {
            quickActionAddClientModal.find('.company-house-search-results-table').on('change', 'input[name="company-house-api-item"]:radio',
                function() {
                    updateCreateClientOkButton(false, 'verify-selected-company-house-api-item');
                }
            );
        };

    var showClientCreateCompanyHouseNumber =
        function () {
            updateClientTypeRadio(false);
            quickActionAddClientModal.find('.client-create-company-house-number').removeClass('hidden');
            quickActionAddClientModal.find('.client-create-manually').addClass('hidden');
            updateCreateClientOkButton(true, '');
        };

    var showClientCreateManually =
        function () {
            updateClientTypeRadio(false);
            quickActionAddClientModal.find('.client-create-company-house-number').addClass('hidden');
            quickActionAddClientModal.find('.client-create-manually').removeClass('hidden');
            updateCreateClientOkButton(true, '');
        };

    var hideClientCreate =
        function() {
            updateClientTypeRadio(false);
            quickActionAddClientModal.find('.client-create-company-house-number').addClass('hidden');
            quickActionAddClientModal.find('.client-create-manually').addClass('hidden');
            updateCreateClientOkButton(true, '');
        };

    // Careful with this method (parameter is 'true' to make button disable and vice versa)
    var updateCreateClientOkButton =
        function (enable, action) {
            quickActionAddClientModal.find('button.ok')
                .prop('disabled', enable)
                .attr('data-action', action)
            ;
        };

    // Careful with this method (parameter is 'true' to make button disable and vice versa)
    var updateCommonServicesOkButton =
      function (enable, action) {
        quickActionAddCommonServicesModal.find('button.ok')
          .prop('disabled', enable)
          .attr('data-action', action)
        ;
      };

    var updateClientTypeRadio =
        function (select) {
            quickActionAddClientModal.find('.client-create-manually input[name="type"]:radio')
                .prop('checked', select);
        };

    var RedirectUserForPartyCreate =
        function (type, addCommonServices, officers) {

            Onkho.LoadingTools.ShowLoading(quickActionAddClientModal.find('button.yes,button.ok'));

            if (officers.length > 0)
            {
                window.location = Onkho.Variables.BaseURL + '/contact/create?client=1&type=' + type + '&addCommonServices=' + addCommonServices + '&officers=' + officers.join(',');
            }
            else
            {
                window.location = Onkho.Variables.BaseURL + '/contact/create?client=1&type=' + type + '&addCommonServices=' + addCommonServices;
            }
        };

    var SearchUsingCompanyHouseAPI =
        function (companiesHouseSearchString, addCommonServices) {

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.companiesHouseSearchString = companiesHouseSearchString;
            formData.addCommonServices          = addCommonServices;

            $.ajax({
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/contact/searchCompanyWithCompanyHouseAPI',
                data: formData,
                dataType: 'json',
                complete: function (data) {
                    switch (data.status) {
                        case 200:
                            DisplayCompanyHouseAPISearchResult(data.responseJSON.html);
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
        };

    var FetchSelectedCompanyInformation =
        function (companyHouseNumber, companiesHouseSearchString, addCommonServices) {

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.companyHouseNumber         = companyHouseNumber;
            formData.companiesHouseSearchString = companiesHouseSearchString;
            formData.addCommonServices          = addCommonServices;

            $.ajax({
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/contact/fetchSelectedCompanyInformation',
                data: formData,
                dataType: 'json',
                complete: function (data) {
                    switch (data.status) {
                        case 200:
                            DisplaySelectedCompanyInformation(data.responseJSON.html);
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
        };

    var DisplayCompanyHouseAPISearchResult =
        function (html) {
            quickActionAddClientModal.html(html);

            var columns = [
                { data: 'choice', name: 'choice', 'bSearchable': false, 'bSortable': false },
                { data: 'name', name: 'name', 'bSearchable': true, 'bSortable': true },
                { data: 'registration_number', name: 'registration_number', 'bSearchable': true, 'bSortable': true }
            ];

            var table = quickActionAddClientModal.find('table').DataTable({
                'dom': "<'row'<'col-sm-6'><'col-sm-6'f>>" +
                "<'row'<'col-sm-12'<'table-wrapper'tr>>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                'iDisplayLength': 5,
                'lengthMenu': Onkho.Config.DataTablesPageLengthMenu,
                'processing': true,
                'serverSide': false,
                'columns': columns,
                'fnDrawCallback': function (oSettings) {
                    $('.modal.add-client .company-house-search-results-table input[name="company-house-api-item"]:checked').each(function ()
                    {
                        $(this).prop('checked', false);
                    });
                    updateCreateClientOkButton(true, '');
                }
            });

            SearchResultControlBindings();
            ButtonBindings();
        };

    var DisplaySelectedCompanyInformation =
        function (html) {
            quickActionAddClientModal.html(html);
            ButtonBindings();
        };

    var DisplayDefaultAddClientModal =
        function (html) {
            quickActionAddClientModal.html(html);
            InitQuickActionAddClient();
            ButtonBindings();
        };

    var RedirectUserWithCompanyHouseInformation =
        function () {

            var type = $('#companyHouseItemType').val();
            var addCommonServices = ReadUserSelectionOptionForAddCommonServices();
            var officers = [];

            $('.selected-search-result-steps .company-officer input[type="checkbox"]:checked').each(function ()
            {
                officers.push($(this).attr('name'));
            });

            RedirectUserForPartyCreate(type, addCommonServices, officers);
        };

    var LoadDefaultAddClientModal =
        function () {
            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();

            $.ajax({
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/contact/loadDefaultAddClientModal',
                data: formData,
                dataType: 'json',
                complete: function (data)
                {
                    switch (data.status)
                    {
                        case 200:
                            DisplayDefaultAddClientModal(data.responseJSON.html);
                            break;

                        default:
                            Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                            break;
                    }
                }
            });
        };

    return {
        Init: Init
    };
}();