// Module containing tools to be used by any widget
Onkho.WidgetTools = function ()
{
    var GetDateIntervalDates = function (dateInterval)
    {
        var  startDate, endDate;

        switch (dateInterval)
        {
            case 'THIS_WEEK':
                startDate = moment().weekday(1);
                endDate = moment().weekday(7);
                break;

            case 'THIS_MONTH':
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;

            case 'NEXT_MONTH':
                startDate = moment().add(1, 'month').startOf('month');
                endDate = moment().add(1, 'month').endOf('month');
                break;

            case 'NEXT_3_MONTHS':
                startDate = moment().startOf('month');
                endDate = moment().add(2, 'month').endOf('month');
                break;

            case 'NEXT_6_MONTHS':
                startDate = moment().startOf('month');
                endDate = moment().add(5, 'month').endOf('month');
                break;

            case 'NEXT_12_MONTHS':
                startDate = moment().startOf('month');
                endDate = moment().add(11, 'month').endOf('month');
                break;

            case 'PREVIOUS_TAX_YEAR':
                if (moment().month() < 3)
                {
                    startDate = moment().subtract(2, 'year').month(3).startOf('month');
                    endDate = moment().subtract(1, 'year').month(2).endOf('month');
                }
                else
                {
                    startDate = moment().subtract(1, 'year').month(3).startOf('month');
                    endDate = moment().month(2).endOf('month');
                }
                break;

            case 'CURRENT_TAX_YEAR':
                if (moment().month() < 3)
                {
                    startDate = moment().subtract(1, 'year').month(3).startOf('month');
                    endDate = moment().month(2).endOf('month');
                }
                else
                {
                    startDate = moment().month(3).startOf('month');
                    endDate = moment().add(1, 'year').month(2).endOf('month');
                }
                break;

            case 'NEXT_TAX_YEAR':
                if (moment().month() < 3)
                {
                    startDate = moment().month(3).startOf('month');
                    endDate = moment().add(1, 'year').month(2).endOf('month');
                }
                else
                {
                    startDate = moment().add(1, 'year').month(3).startOf('month');
                    endDate = moment().add(2, 'year').month(2).endOf('month');
                }
                break;

            default:
                startDate = moment('1900-01-01');
                endDate = moment('2100-01-01');
        }

        return {
            startDate: startDate,
            endDate: endDate
        }
    };

    var UpdateDateIntervalLabel = function (dateInterval, container)
    {
        var dates = Onkho.WidgetTools.GetDateIntervalDates(dateInterval);
        container.find('.date-interval-label .start-date').html(dates.startDate.format('DD MMM YYYY'));
        container.find('.date-interval-label .end-date').html(dates.endDate.format('DD MMM YYYY'));
    };



    return {
        GetDateIntervalDates: GetDateIntervalDates,
        UpdateDateIntervalLabel: UpdateDateIntervalLabel
    }
}();



// Module containing tools to be used by the job status widget
Onkho.JobStatusWidgetTools = function ()
{
    var Init = function ()
    {
        $('.jarviswidget').on('onkho:dashboard:widget[job-status].loaded', function ()
        {
            OnLoad($(this));
        });

        // Make the title adjust based on the configuration of the widget
        $('body').on('change', '.jarviswidget.job-status .configuration-form input[name="job-status"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
            DisableSelects(widget.find('.configuration-form'));
            EnableSelects(widget.find('.configuration-form input[name="job-status"]:checked').closest('label.radio'));
        });

        $('body').on('change', '.jarviswidget.job-status .configuration-form select[name="not-started-period"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
            Onkho.WidgetTools.UpdateDateIntervalLabel($(this).val(), $(this).closest('label.select'));
        });

        $('body').on('change', '.jarviswidget.job-status .configuration-form select[name="completed-period"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
            Onkho.WidgetTools.UpdateDateIntervalLabel($(this).val(), $(this).closest('label.select'));
        });
    };

    var UpdateDateIntervalLabel = function (dateInterval, container)
    {
        var dates = Onkho.WidgetTools.GetDateIntervalDates(dateInterval);
        container.find('.date-interval-label .start-date').html(dates.startDate.format('DD MMM YYYY'));
        container.find('.date-interval-label .end-date').html(dates.endDate.format('DD MMM YYYY'));
    };

    var OnLoad = function (widget)
    {
        // Refresh date interval labels
        var notStartedDateSelect = $('.jarviswidget.job-status .configuration-form select[name="not-started-period"]');
        UpdateDateIntervalLabel(notStartedDateSelect.val(), notStartedDateSelect.closest('label.select'));

        var completedDateSelect = $('.jarviswidget.job-status .configuration-form select[name="completed-period"]');
        UpdateDateIntervalLabel(completedDateSelect.val(), completedDateSelect.closest('label.select'));

        // Disable unselected selects
        DisableSelects(widget.find('.configuration-form'));
        EnableSelects(widget.find('.configuration-form input[name="job-status"]:checked').closest('label.radio'));
    };

    var DisableSelects = function (container)
    {
        container.find('select').each(function ()
        {
            $(this).prop('disabled', true);
            $(this).closest('label.select').addClass('state-disabled');
        });
    };

    var EnableSelects = function (container)
    {
        container.find('select').each(function ()
        {
            $(this).prop('disabled', false);
            $(this).closest('label.select').removeClass('state-disabled');
        });
    };

    var SuggestWidgetTitle = function (widget)
    {
        var status = widget.find('.configuration-form input[name="job-status"]:checked').val();
        var notStartedPeriod = widget.find('.configuration-form select[name="not-started-period"]').val();
        var completedPeriod = widget.find('.configuration-form select[name="completed-period"]').val();

        var title = '';

        switch (status)
        {
            case 'NOT STARTED':
                title = 'Jobs Not Started';
                widget.find('.widget-toolbar .color-select .bg-color-white').click();
                break;

            case 'STARTED':
                title = 'Started Jobs';
                widget.find('.widget-toolbar .color-select .bg-color-blue').click();
                break;

            case 'PAUSED':
                title = 'Paused Jobs';
                widget.find('.widget-toolbar .color-select .bg-color-orange').click();
                break;

            case 'COMPLETED':
                title = 'Completed Jobs';
                widget.find('.widget-toolbar .color-select .bg-color-green').click();
                break;

            case 'OVERDUE':
                title = 'Overdue Jobs';
                widget.find('.widget-toolbar .color-select .bg-color-red').click();
                break;

            default:
            //
        }

        if (status == 'NOT STARTED')
        {
            switch (notStartedPeriod)
            {
                case 'THIS_WEEK':
                    title += ' (Current Week)';
                    break;

                case 'THIS_MONTH':
                    title += ' (Current Month)';
                    break;

                case 'NEXT_MONTH':
                    title += ' (Next Month)';
                    break;

                case 'NEXT_3_MONTHS':
                    title += ' (Next 3 Months)';
                    break;

                case 'NEXT_6_MONTHS':
                    title += ' (Next 6 Months)';
                    break;

                case 'NEXT_12_MONTHS':
                    title += ' (Next 12 Months)';
                    break;

                case 'PREVIOUS_TAX_YEAR':
                    title += ' (Previous Tax Year)';
                    break;

                case 'CURRENT_TAX_YEAR':
                    title += ' (Current Tax Year)';
                    break;

                case 'NEXT_TAX_YEAR':
                    title += ' (Next Tax Year)';
                    break;

                default:
                //
            }
        }
        else
            if (status == 'COMPLETED')
            {
                switch (completedPeriod)
                {
                    case 'THIS_WEEK':
                        title += ' (Current Week)';
                        break;

                    case 'THIS_MONTH':
                        title += ' (Current Month)';
                        break;

                    case 'NEXT_MONTH':
                        title += ' (Next Month)';
                        break;

                    case 'NEXT_3_MONTHS':
                        title += ' (Next 3 Months)';
                        break;

                    case 'NEXT_6_MONTHS':
                        title += ' (Next 6 Months)';
                        break;

                    case 'NEXT_12_MONTHS':
                        title += ' (Next 12 Months)';
                        break;

                    case 'PREVIOUS_TAX_YEAR':
                        title += ' (Previous Tax Year)';
                        break;

                    case 'CURRENT_TAX_YEAR':
                        title += ' (Current Tax Year)';
                        break;

                    case 'NEXT_TAX_YEAR':
                        title += ' (Next Tax Year)';
                        break;

                    default:
                    //
                }
            }

        widget.find('.jarviswidget-editbox input').val(title);
        widget.find('.jarviswidget-editbox input').trigger('keyup');
    };



    return {
        Init: Init
    };
}();



// Module containing tools to be used by the service status widget
Onkho.ServiceStatusWidgetTools = function ()
{
    var Init = function ()
    {
        $('.jarviswidget').on('onkho:dashboard:widget[service-status].loaded', function ()
        {
            OnLoad($(this));
        });

        // Make the title adjust based on the configuration of the widget
        $('body').on('change', '.jarviswidget.service-status select[name="service_id"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
        });

        $('body').on('change', '.jarviswidget.service-status .configuration-form select[name="period"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
            Onkho.WidgetTools.UpdateDateIntervalLabel($(this).val(), $(this).closest('label.select'));
        });

        $('body').on('change', '.jarviswidget.service-status .configuration-form input[name="jobs_filter"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
        });
    };

    var OnLoad = function (widget)
    {
        // Refresh date interval labels
        var periodSelect = $('.jarviswidget.service-status .configuration-form select[name="period"]');
        Onkho.WidgetTools.UpdateDateIntervalLabel(periodSelect.val(), periodSelect.closest('label.select'));
    };

    var SuggestWidgetTitle = function (widget)
    {
        var service = widget.find('.configuration-form select[name="service_id"] option:selected').text();
        var period = widget.find('.configuration-form select[name="period"]').val();
        var jobsFilter = widget.find('.configuration-form input[name="jobs_filter"]:checked').val();

        var title = '';

        switch (jobsFilter)
        {
            case 'ALL':
                title += '';
                break;

            case 'MINE':
                title += 'My ';
                break;

            default:
            //
        }

        title += service;

        switch (period)
        {
            case 'THIS_WEEK':
                title += ' (Current Week)';
                break;

            case 'THIS_MONTH':
                title += ' (Current Month)';
                break;

            case 'NEXT_MONTH':
                title += ' (Next Month)';
                break;

            case 'NEXT_3_MONTHS':
                title += ' (Next 3 Months)';
                break;

            case 'NEXT_6_MONTHS':
                title += ' (Next 6 Months)';
                break;

            case 'NEXT_12_MONTHS':
                title += ' (Next 12 Months)';
                break;

            case 'PREVIOUS_TAX_YEAR':
                title += ' (Previous Tax Year)';
                break;

            case 'CURRENT_TAX_YEAR':
                title += ' (Current Tax Year)';
                break;

            case 'NEXT_TAX_YEAR':
                title += ' (Next Tax Year)';
                break;

            default:
            //
        }

        widget.find('.jarviswidget-editbox input').val(title);
        widget.find('.jarviswidget-editbox input').trigger('keyup');
    };



    return {
        Init: Init
    };
}();



// Module containing tools to be used by the service countdown widget
Onkho.ServiceCountdownWidgetTools = function ()
{
    var Init = function ()
    {
        $('.jarviswidget').on('onkho:dashboard:widget[service-countdown].loaded', function ()
        {
            OnLoad($(this));
        });

        // Make the title adjust based on the configuration of the widget
        $('body').on('change', '.jarviswidget.service-countdown select[name="service_id"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
        });

        $('body').on('change', '.jarviswidget.service-countdown .configuration-form select[name="period"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
            Onkho.WidgetTools.UpdateDateIntervalLabel($(this).val(), $(this).closest('label.select'));
        });

        $('body').on('change', '.jarviswidget.service-countdown .configuration-form input[name="jobs_filter"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
        });
    };

    var OnLoad = function (widget)
    {
        // Refresh date interval labels
        var periodSelect = $('.jarviswidget.service-countdown .configuration-form select[name="period"]');
        Onkho.WidgetTools.UpdateDateIntervalLabel(periodSelect.val(), periodSelect.closest('label.select'));
    };

    var SuggestWidgetTitle = function (widget)
    {
        var service = widget.find('.configuration-form select[name="service_id"] option:selected').text();
        var period = widget.find('.configuration-form select[name="period"]').val();
        var jobsFilter = widget.find('.configuration-form input[name="jobs_filter"]:checked').val();

        var title = '';

        switch (jobsFilter)
        {
            case 'ALL':
                title += '';
                break;

            case 'MINE':
                title += 'My ';
                break;

            default:
            //
        }

        title += service;

        switch (period)
        {
            case 'THIS_WEEK':
                title += ' (Current Week)';
                break;

            case 'THIS_MONTH':
                title += ' (Current Month)';
                break;

            case 'NEXT_MONTH':
                title += ' (Next Month)';
                break;

            case 'NEXT_3_MONTHS':
                title += ' (Next 3 Months)';
                break;

            case 'NEXT_6_MONTHS':
                title += ' (Next 6 Months)';
                break;

            case 'NEXT_12_MONTHS':
                title += ' (Next 12 Months)';
                break;

            case 'PREVIOUS_TAX_YEAR':
                title += ' (Previous Tax Year)';
                break;

            case 'CURRENT_TAX_YEAR':
                title += ' (Current Tax Year)';
                break;

            case 'NEXT_TAX_YEAR':
                title += ' (Next Tax Year)';
                break;

            default:
            //
        }

        widget.find('.jarviswidget-editbox input').val(title);
        widget.find('.jarviswidget-editbox input').trigger('keyup');
    };



    return {
        Init: Init
    };
}();




// Module containing tools to be used by the insights widget
Onkho.InsightsWidgetTools = function ()
{
    var Init = function ()
    {
        $('.jarviswidget').on('onkho:dashboard:widget[insights].loaded', function ()
        {
            OnLoad($(this));
        });

        // Make the title adjust based on the configuration of the widget
        $('body').on('change', '.jarviswidget.insights select[name="insight_id"]', function ()
        {
            var widget = $(this).closest('.jarviswidget');
            SuggestWidgetTitle(widget);
        });

        Onkho.Variables.InsightsWidgetIntervals = [];
        Onkho.Variables.InsightsWidgetStatuses = [];
    };

    var OnLoad = function (widget)
    {
        widget.find('.configuration-form select[name="insight_id"]').select2(
        {
            placeholder: 'Select insight'
        });

        widget.find('header .jarviswidget-fullscreen-btn').hide();

        // Refresh the widget periodically with a staggered start time

        // Temporarily disable insights widgets auto refresh
        /*setTimeout(function () {
            Onkho.Variables.InsightsWidgetIntervals[widget.attr('id')] = setInterval(function () {
                RefreshWidget(widget);
            }, 10000);
        }, parseInt(5000 + Math.random() * 10 * 1000));*/
    };

    var SuggestWidgetTitle = function (widget)
    {
        var insight = widget.find('.configuration-form select[name="insight_id"] option:selected').text();

        var title = insight;

        if (title == 'None')
        {
            title = 'Insights Widget';
        }

        widget.find('.jarviswidget-editbox input').val(title);
        widget.find('.jarviswidget-editbox input').trigger('keyup');
    };

    var RefreshWidget = function (widget)
    {
        if (widget.closest('body').length == 0)
        {
            // The widget was removed from the dashboard
            if (widget.attr('id') in Onkho.Variables.InsightsWidgetIntervals)
            {
                clearInterval(Onkho.Variables.InsightsWidgetIntervals[widget.attr('id')]);
            }

            return;
        }

        if ((widget.attr('id') in Onkho.Variables.InsightsWidgetStatuses) && Onkho.Variables.InsightsWidgetStatuses[widget.attr('id')] == 'REFRESHING')
        {
            return;
        }

        Onkho.Variables.InsightsWidgetStatuses[widget.attr('id')] = 'REFRESHING';
        widget.addClass('refreshing');

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();

        formData.widget_instance_id = widget.attr('id');

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/dashboard/loadWidget',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          switch (data.status)
                          {
                              case 200:
                                  var newWidget = $($.parseHTML(data.responseJSON.html));

                                  widget.find('.widget-body').html(newWidget.find('.widget-body').html());

                                  widget.removeClass('refreshing');
                                  Onkho.Variables.InsightsWidgetStatuses[widget.attr('id')] = 'REFRESHED';

                                  widget.find('.widget-cover').show();
                                  widget.find('.widget-cover').fadeOut(150);
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            }
        );
    };


    return {
        Init: Init
    };
}();



// Module containing tools to be used by the Youtube feed widget
Onkho.YoutubeFeedWidgetTools = function ()
{
    var Init = function ()
    {
        $('.jarviswidget').on('onkho:dashboard:widget[youtube-feed].loaded', function ()
        {
            OnLoad($(this));
        });
    };

    var OnLoad = function (widget)
    {
        widget.find('.youtube-video-thumbnail-container').lazyload({
            load: function (element) {
                element.fadeOut(0, function () { element.fadeIn(1000); });
            }
        });
        widget.find('.youtube-content').lazyload({trigger: 'click'});

        widget.find('header .jarviswidget-fullscreen-btn').hide();
    };



    return {
        Init: Init
    };
}();
