// Module containing tools to be used on the timesheets page
Onkho.TimeSheetsPageTools = function () {
  var Init = function () {
    var timesheetsContainer = $('.timesheets-container');

    InitializeGrid(timesheetsContainer);
    ApplyReferenceDate(timesheetsContainer, GetReferenceDate(timesheetsContainer));
    LoadGrid(timesheetsContainer);
  }

  var InitializeGrid = function (timesheetsContainer)
  {
    // Toolbar
    timesheetsContainer.find('.toolbar [data-id="refresh"]').on('click', function () {
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        LoadGrid(timesheetsContainer);
      });
    });

    timesheetsContainer.find('.toolbar [name="team-member-party-id"]').select2({
      placeholder: 'Choose team member'
    });

    timesheetsContainer.find('.toolbar [name="team-member-party-id"]').on('change', function (event) {
      var select = $(this);
      if (event.added) {
        ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
          LoadGrid(timesheetsContainer);
        }, function (timesheetsContainer) {
          // Rollback change
          select.val(event.removed.id).change();
        });
      }
    });

    timesheetsContainer.find('.toolbar [name="include-weekends"]').on('change', function (event) {
      var checkbox = $(this);
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        ReloadWeekends(timesheetsContainer);
        LoadGrid(timesheetsContainer);
      }, function (timesheetsContainer) {
        checkbox.prop('checked', !checkbox.prop('checked'));
      });
    });

    timesheetsContainer.find('.toolbar [data-id="save"]').on('click', function() {
      SaveGrid(timesheetsContainer);
    });

    timesheetsContainer.find('.toolbar [data-id="discard"]').on('click', function() {
      ConfirmDiscardChanges(timesheetsContainer);
    });

    // Mini-toolbar
    timesheetsContainer.find('.mini-toolbar .today').on('click', function () {
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        ApplyTodayInterval(timesheetsContainer);
        LoadGrid(timesheetsContainer);
      });
    });

    timesheetsContainer.find('.mini-toolbar .interval-selector .next').on('click', function () {
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        ApplyNextInterval(timesheetsContainer);
        LoadGrid(timesheetsContainer);
      });
    });

    timesheetsContainer.find('.mini-toolbar .interval-selector .prev').on('click', function () {
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        ApplyPrevInterval(timesheetsContainer);
        LoadGrid(timesheetsContainer);
      });
    });

    timesheetsContainer.find('.mini-toolbar .interval-selector input[name="reference-date"]').on('change', function () {
      ConfirmUnsavedChanges(timesheetsContainer, function (timesheetsContainer) {
        ApplyReferenceDate(timesheetsContainer, GetReferenceDate(timesheetsContainer));
        LoadGrid(timesheetsContainer);
      });
    });

    timesheetsContainer.find('.mini-toolbar .interval-selector .reference-datepicker').on('click', function () {
      timesheetsContainer.find('.mini-toolbar .interval-selector input[name="reference-date"]').datepicker('show');
    });

    timesheetsContainer.find('.mini-toolbar [name="search"]').on('keyup', function () {
      FilterJobs(timesheetsContainer, $(this).val());
    });

    timesheetsContainer.find('.mini-toolbar .clear-search').on('click', function () {
      ClearSearch(timesheetsContainer);
    });

    // Grid
    timesheetsContainer.on('change', 'input.duration-picker', function () {
      SetCell(timesheetsContainer, $(this).closest('td'), $(this).val());
    });
  }

  var FilterJobs = function (timesheetsContainer, term)
  {
    term = term.trim().toLowerCase();
    timesheetsContainer.find('tbody tr').each(function (index, row) {
      var searchableData = $(row).find('td:first-of-type a').text().trim().toLowerCase();
      if (term.length > 0 && searchableData.search(term) == -1) {
        $(row).fadeOut(150);
      } else {
        $(row).fadeIn(150);
      }
    });

    if (term.length > 0) {
      timesheetsContainer.find('.mini-toolbar .clear-search').fadeIn(150);
    } else {
      timesheetsContainer.find('.mini-toolbar .clear-search').fadeOut(150);
    }
  }

  var ClearSearch = function (timesheetsContainer)
  {
    timesheetsContainer.find('.mini-toolbar [name="search"]').val('').trigger('keyup');
  }

  var ReloadWeekends = function (timesheetsContainer)
  {
    if (AreWeekendsIncluded(timesheetsContainer)) {
      timesheetsContainer.addClass('weekends-included');
    } else {
      timesheetsContainer.removeClass('weekends-included');
    }

    ApplyReferenceDate(timesheetsContainer, GetStartDate(timesheetsContainer));
  }

  var ApplyTodayInterval = function (timesheetsContainer)
  {
    var newReferenceDate = moment();
    SetReferenceDate(timesheetsContainer, newReferenceDate);
    ApplyReferenceDate(timesheetsContainer, newReferenceDate);
  }

  var ApplyNextInterval = function (timesheetsContainer)
  {
    var newReferenceDate = GetStartDate(timesheetsContainer).add(7, 'days');
    SetReferenceDate(timesheetsContainer, newReferenceDate);
    ApplyReferenceDate(timesheetsContainer, newReferenceDate);
  }

  var ApplyPrevInterval = function (timesheetsContainer)
  {
    var newReferenceDate = GetStartDate(timesheetsContainer).subtract(7, 'days');
    SetReferenceDate(timesheetsContainer, newReferenceDate);
    ApplyReferenceDate(timesheetsContainer, newReferenceDate);
  }

  var GetTeamMember = function (timesheetsContainer)
  {
    return timesheetsContainer.find('.toolbar [name="team-member-party-id"]').val();
  }

  var ConfirmUnsavedChanges = function (timesheetsContainer, yesCallback, noCallback)
  {
    if (!timesheetsContainer.hasClass('dirty')) {
      if (yesCallback) {
        yesCallback(timesheetsContainer);
      }
    } else {
      $.SmartMessageBox(
        {
          title: 'You have pending changes',
          content: 'You have pending changes. Are you sure you want to reload the page?',
          buttons: '[No][Yes]'
        }, function (ButtonPress) {
          if (ButtonPress == 'Yes') {
            if (yesCallback) {
              yesCallback(timesheetsContainer);
            }
          } else {
            if (noCallback) {
              noCallback(timesheetsContainer);
            }
          }
        }
      );
    }
  }

  var LoadGrid = function (timesheetsContainer)
  {
    StartLoadingTimeSheets(timesheetsContainer);

    if (Validate(timesheetsContainer)) {
      RegenerateNonce(timesheetsContainer);

      var formData = {};
      formData._token = Onkho.Functions.GetCSRF();
      formData.nonce = GetNonce(timesheetsContainer);
      formData.teamMemberPartyId = GetTeamMember(timesheetsContainer);
      formData.startDate = GetStartDate(timesheetsContainer).format('YYYY-MM-DD');
      formData.endDate = GetEndDate(timesheetsContainer).format('YYYY-MM-DD');

      $.ajax(
        {
          type: 'POST',
          url: Onkho.Variables.BaseURL + '/timeSheets/getData',
          data: formData,
          dataType: 'json',
          complete: function (data) {
            switch (data.status) {
              case 200:
                if (data.responseJSON.nonce == GetNonce(timesheetsContainer)) {
                  var startDate = GetStartDate(timesheetsContainer);
                  var endDate = GetEndDate(timesheetsContainer);

                  ClearGrid(timesheetsContainer);
                  AddDates(timesheetsContainer, startDate, endDate);
                  AddJobs(timesheetsContainer, data.responseJSON.data.jobs, data.responseJSON.data.timeEntriesTotals, startDate, endDate);

                  SetDateTotals(timesheetsContainer, data.responseJSON.data.daysTimeEntriesTotals, startDate, endDate);
                  SetJobTotals(timesheetsContainer, data.responseJSON.data.jobsTimeEntriesTotals, data.responseJSON.data.jobs);
                  SetGrandTotal(timesheetsContainer, data.responseJSON.data.grandTimeEntriesTotal);

                  SetPristine(timesheetsContainer);
                }

                break;

              default:
                Onkho.Alert.BigBox(data.responseJSON.data.status, data.responseJSON.data.title, data.responseJSON.data.message);
                break;
            }

            StopLoadingTimeSheets(timesheetsContainer);
          }
        });
    }
  }

  var AreWeekendsIncluded = function (timesheetsContainer)
  {
      return timesheetsContainer.find('.toolbar [name="include-weekends"]').prop('checked');
  }

  var StartLoadingTimeSheets = function (timesheetsContainer)
  {
    timesheetsContainer.addClass('loading');
  }

  var StopLoadingTimeSheets = function (timesheetsContainer)
  {
    timesheetsContainer.removeClass('loading');
  }

  var SetPristine = function (timesheetsContainer, cell)
  {
    if (cell) {
      cell.addClass('pristine').removeClass('dirty');
    }

    if (timesheetsContainer.hasClass('dirty') && timesheetsContainer.find('.dirty').length == 0) {
      // No more dirty cells
      timesheetsContainer.addClass('pristine').removeClass('dirty');
      timesheetsContainer.find('.toolbar [data-id="save"]').hide();
      timesheetsContainer.find('.toolbar [data-id="discard"]').hide();
      $(window).off('beforeunload');
    }
  }

  var SetDirty = function (timesheetsContainer, cell)
  {
    if (cell) {
      cell.removeClass('pristine').addClass('dirty');
    }

    if (!timesheetsContainer.hasClass('dirty')) {
      timesheetsContainer.removeClass('pristine').addClass('dirty');
      timesheetsContainer.find('.toolbar [data-id="save"]').show();
      timesheetsContainer.find('.toolbar [data-id="discard"]').show();

      $(window).on('beforeunload', function () {
        return '!';
      });
    }
  }

  var ResetTabindex = function (timesheetsContainer)
  {
    timesheetsContainer.data('tabindex', 0);
  }

  var Validate = function (timesheetsContainer)
  {
    var startDate = timesheetsContainer.find('.mini-toolbar .interval-selector input[name="start-date"]').val();
    var endDate = timesheetsContainer.find('.mini-toolbar .interval-selector input[name="end-date"]').val();

    if (!startDate || !endDate) {
      return false;
    }

    startDate = moment(startDate);
    endDate = moment(endDate);

    var daysDifference = endDate.diff(startDate, 'days');
    if (AreWeekendsIncluded(timesheetsContainer)) {
      if (daysDifference != 6) {
        return false;
      }
    } else {
      if (daysDifference != 4) {
        return false;
      }
    }

    return true;
  }

  var GetReferenceDate = function (timesheetsContainer)
  {
    return moment(timesheetsContainer.find('.mini-toolbar .interval-selector [name="reference-date"]').val(), 'DD/MM/YYYY');
  }

  var SetReferenceDate = function (timesheetsContainer, date)
  {
    timesheetsContainer.find('.mini-toolbar .interval-selector [name="reference-date"]').val(date.format('DD/MM/YYYY'));
  }

  var ApplyReferenceDate = function (timesheetsContainer, referenceDate)
  {
    var startDate = referenceDate.clone().isoWeekday(1);
    var endDate = referenceDate.clone().isoWeekday(7);

    if (!AreWeekendsIncluded(timesheetsContainer)) {
      endDate = startDate.clone().isoWeekday(5);
    }

    timesheetsContainer.find('.mini-toolbar .interval-selector [name="start-date"]').val(startDate.format('YYYY-MM-DD'));
    timesheetsContainer.find('.mini-toolbar .interval-selector [name="end-date"]').val(endDate.format('YYYY-MM-DD'));
  }

  var GetNonce = function (timesheetsContainer)
  {
    return timesheetsContainer.data('nonce');
  }

  var RegenerateNonce = function (timesheetsContainer)
  {
    return timesheetsContainer.data('nonce', Math.floor(Math.random() * 10000 + 1));
  }

  var GetStartDate = function (timesheetsContainer)
  {
    return moment(timesheetsContainer.find('.mini-toolbar .interval-selector [name="start-date"]').val());
  }

  var GetEndDate = function (timesheetsContainer)
  {
    return moment(timesheetsContainer.find('.mini-toolbar .interval-selector [name="end-date"]').val());
  }

  var AddJobs = function (timesheetsContainer, jobs, timeEntriesTotals, startDate, endDate)
  {
    // Dates are added first, then jobs
    Object.keys(jobs).forEach(function (index) {
      AddJob(timesheetsContainer, jobs[index], timeEntriesTotals[jobs[index].id], startDate.clone(), endDate.clone())
    });
  }

  var AddJob = function (timesheetsContainer, job, timeEntriesTotal, startDate, endDate, teamMemberId)
  {
    // Dates are added first, then jobs
    var jobRow = $('<tr data-job-id="' + job.id + '"></tr>');

    jobRow.append('<td data-id-job="' + job.id + '">' + job.activeElement + job.clientActiveElement + '</td>');

    var tabindex = parseInt(timesheetsContainer.data('tabindex'));
    var currentDate = startDate.clone();
    while (endDate.diff(currentDate, 'days') >= 0) {
      var formattedDate = currentDate.format('YYYY-MM-DD');
      jobRow.append('' +
        '<td class="duration-picker pristine" data-job-id="' + job.id + '" data-date="' + formattedDate + '" data-team-member-id="' + teamMemberId + '" data-initial-value="' + timeEntriesTotal[formattedDate] + '" data-value="' + timeEntriesTotal[formattedDate] + '">' +
        '  <input class="duration-picker hours-only" tabindex="' + (++tabindex) + '" value="' + timeEntriesTotal[formattedDate] + '">' +
        '</td>');
      currentDate.add(1, 'days');
    }

    jobRow.append('<td class="total-cell" data-id-job="' + job.id + '"></td>');

    jobRow.find('input.duration-picker').each(function (index, durationPicker) {
      Onkho.OnkhoDurationPicker.Init($(durationPicker));
    });

    timesheetsContainer.find('.content tbody').append(jobRow);
    timesheetsContainer.data('tabindex', tabindex);
  }

  var ClearGrid = function (timesheetsContainer)
  {
    ResetTabindex(timesheetsContainer);
    timesheetsContainer.find('.content th[data-date]').remove();
    timesheetsContainer.find('.content tr[data-job-id]').remove();
    timesheetsContainer.find('.content td[data-date]').remove();
    timesheetsContainer.find('.mini-toolbar [name="search"]').val('');
  }

  var AddDates = function (timesheetsContainer, startDate, endDate)
  {
    // Dates are added first, then jobs
    var currentDate = startDate.clone();
    while (endDate.diff(currentDate, 'days') >= 0) {
      AddDate(timesheetsContainer, currentDate.clone());
      currentDate.add(1, 'days');
    }
  }

  var AddDate = function (timesheetsContainer, date)
  {
    // Dates are added first, then jobs
    var lastCellInHeader = timesheetsContainer.find('.content thead tr th:last-of-type');
    lastCellInHeader.before('' +
      '<th data-date="' + date.format('YYYY-MM-DD') + '">' +
      '  <div class="date">' +
      '    <div class="day-of-week">' + date.format('dddd') + '</div>' +
      '    <div class="full-date">' + date.format('MMM DD, YYYY') + '</div>' +
      '  </div>' +
      '</th>');

    var grandTotalCell = timesheetsContainer.find('.content tr.totals-row td.grand-total-cell');
    grandTotalCell.before('' +
      '<td class="total-cell" data-date="' + date.format('YYYY-MM-DD') + '">' +
      '</td>');
  }

  var SetDateTotals = function (timesheetsContainer, totals, startDate, endDate)
  {
    var currentDate = startDate.clone();
    while (endDate.diff(currentDate, 'days') >= 0) {
      var formattedDate = currentDate.format('YYYY-MM-DD');
      SetCell(timesheetsContainer, timesheetsContainer.find('.content tr.totals-row td[data-date="' + formattedDate + '"]'), totals[formattedDate]);
      currentDate.add(1, 'days');
    }
  }

  var SetJobTotals = function (timesheetsContainer, totals, jobs)
  {
    Object.keys(jobs).forEach(function (index) {
      SetCell(timesheetsContainer, timesheetsContainer.find('.content tr[data-job-id="' + jobs[index].id + '"] td.total-cell'), totals[jobs[index].id]);
    });
  }

  var SetGrandTotal = function (timesheetsContainer, grandTotal)
  {
    SetCell(timesheetsContainer, timesheetsContainer.find('.content tr.totals-row td.grand-total-cell'), grandTotal);
  }

  var SetCell = function (timesheetsContainer, cell, seconds)
  {
    if (!cell.hasClass('total-cell') && cell.parents('tbody').length > 0) {
      // It's editable
      cell.data('value', seconds);

      if (cell.data('initial-value') != seconds) {
        SetDirty(timesheetsContainer, cell);
      } else {
        SetPristine(timesheetsContainer, cell);
      }
    } else {
      // It's not editable
      cell.html(Onkho.Formatter.Duration(seconds));
      cell.data('value', seconds);
    }
  }

  var ConfirmDiscardChanges = function (timesheetsContainer)
  {
    $.SmartMessageBox(
      {
        title: 'Discard changes',
        content: 'Are you sure you want to discard your changes?',
        buttons: '[No][Yes]'
      }, function (ButtonPress) {
        if (ButtonPress == 'Yes') {
          DiscardChanges(timesheetsContainer);
        }
      }
    );
  }

  var DiscardChanges = function (timesheetsContainer)
  {
    timesheetsContainer.find('tbody td.duration-picker.dirty').each(function (index, cell) {
      Onkho.OnkhoDurationPicker.Set($(cell).find('input.duration-picker'), $(cell).data('initial-value'));
    });
  }

  var SaveGrid = function (timesheetsContainer)
  {
    StartLoadingTimeSheets(timesheetsContainer);

    var formData = {};
    formData._token = Onkho.Functions.GetCSRF();
    formData.teamMemberPartyId = GetTeamMember(timesheetsContainer);
    formData.changes = [];
    timesheetsContainer.find('tbody td.duration-picker.dirty').each(function (index, cell) {
      formData.changes.push({
        jobId: $(cell).data('job-id'),
        date: $(cell).data('date'),
        seconds: $(cell).data('value')
      });
    });

    $.ajax(
      {
        type: 'POST',
        url: Onkho.Variables.BaseURL + '/timeSheets/save',
        data: formData,
        dataType: 'json',
        complete: function (data) {
          switch (data.status) {
            case 200:
              StopLoadingTimeSheets(timesheetsContainer);
              Onkho.Alert.SmallBox('success', data.responseJSON.message);

              LoadGrid(timesheetsContainer);
              break;

            default:
              StopLoadingTimeSheets(timesheetsContainer);
              Onkho.Alert.BigBox(data.responseJSON.data.status, data.responseJSON.data.title, data.responseJSON.data.message);
              break;
          }
        }
      });
  }



  return {
    Init: Init
  }
}()
