// Module containing tools to be used when displaying a terms list
Onkho.TermsListTools = function ()
{
    var termsContainer;
    var nextPaymentPlanId = 0;

    var Init = function ()
    {
        termsContainer = $('#terms');

        Onkho.OnkhoList.Init(termsContainer.find('.o-list'), 'terms', {
            'clientId': $('input[name="partyId"]').val()
        });

        RegisterListBindings();
        RegisterPanelBindings();
    };

    var RegisterListBindings = function ()
    {
        termsContainer.on('click', '.terms-list-item .actions [data-id="delete"]', function ()
        {
            DeleteTerm($(this).closest('.terms-list-item').data('id'), 'Deleting terms', 'Are you sure you want to delete these terms?');
        });

        termsContainer.on('click', '.items-actions [data-id="delete"]', function ()
        {
            var selection = Onkho.OnkhoList.GetSelected(termsContainer.find('.o-list'));
            var termIds = [];
            $(selection).each(function (index, term)
                              {
                                  termIds.push(term.data('id'))
                              });

            DeleteTerm(termIds, 'Deleting terms', 'Are you sure you want to delete these terms?');
        });

        // Fee plans
        termsContainer.on('click', '.list-actions [data-id="add_fee_plan"]', function ()
        {
            AddFeePlan();
        });

        termsContainer.on('click', '.fee-plan-list-item .actions [data-id="edit"]', function ()
        {
            EditFeePlan($(this).closest('.fee-plan-list-item').data('id'));
        });

        termsContainer.on('click', '.fee-plan-list-item .title > a', function ()
        {
            ViewFeePlan($(this).closest('.fee-plan-list-item').data('id'));
        });

        termsContainer.on('click', '.fee-plan-list-item .actions [data-id="view"]', function ()
        {
            ViewFeePlan($(this).closest('.fee-plan-list-item').data('id'));
        });

        // Agreements
        termsContainer.on('click', '.list-actions [data-id="add_agreement"]', function ()
        {
            AddAgreement();
        });

        termsContainer.on('click', '.agreement-list-item .actions [data-id="edit"]', function ()
        {
            ViewAgreement($(this).closest('.agreement-list-item').data('id'));
        });

        termsContainer.on('click', '.agreement-list-item .actions [data-id="group"]', function ()
        {
            OpenTermGroup($(this).closest('.agreement-list-item').data('activity-feed-group-id'));
        });

        termsContainer.on('click', '.agreement-list-item .actions [data-id="duplicate"]', function ()
        {
            DuplicateAgreement($(this).closest('.agreement-list-item').data('id'));
        });

        termsContainer.on('click', '.agreement-list-item .title > a', function ()
        {
            ViewAgreement($(this).closest('.agreement-list-item').data('id'));
        });

        // Placeholder buttons
        termsContainer.on('click', '.content-placeholder [data-id="add_fee_plan"]', function ()
        {
            termsContainer.find('.list-actions [data-id="add_fee_plan"]').click();
        });
    };

    var RegisterPanelBindings = function ()
    {
        // Fee plans
        $('body').on('onkho:panel[view_fee_plan].added', '.o-panel-container', function ()
        {
            var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');
            RegisterViewFeePlanPanelBindings(viewFeePlanPanel);
        });

        $('body').on('onkho:panel[fee_plan].added', '.o-panel-container', function ()
        {
            var feePlanPanel = $('.o-panel[data-panel-key="fee_plan"]');
            RegisterFeePlanPanelBindings(feePlanPanel);
        });

        $('body').on('onkho:panel[payment_plan].added', '.o-panel-container', function ()
        {
            var paymentPlanPanel = $('.o-panel[data-panel-key="payment_plan"]');
            RegisterPaymentPlanPanelBindings(paymentPlanPanel);
        });

        $('body').on('onkho:panel[payment_method].added', '.o-panel-container', function ()
        {
            var paymentMethodPanel = $('.o-panel[data-panel-key="payment_method"]');
            RegisterPaymentMethodPanelBindings(paymentMethodPanel);
        });

        // Agreements
        $('body').on('onkho:panel[view_agreement].added', '.o-panel-container', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
            RegisterViewAgreementPanelBindings(viewAgreementPanel);
        });

        $('body').on('onkho:panel[agreement].added', '.o-panel-container', function ()
        {
            var agreementPanel = $('.o-panel[data-panel-key="agreement"]');
            RegisterAgreementPanelBindings(agreementPanel);
        });

        $('body').on('onkho:panel[terms_of_business].added', '.o-panel-container', function ()
        {
            var termsOfBusinessPanel = $('.o-panel[data-panel-key="terms_of_business"]');
            RegisterTermsOfBusinessPanelBindings(termsOfBusinessPanel);
        });

        $('body').on('onkho:panel[preview_agreement].added', '.o-panel-container', function ()
        {
            var previewAgreementPanel = $('.o-panel[data-panel-key="preview_agreement"]');
            RegisterPreviewAgreementPanelBindings(previewAgreementPanel);
        });

        $('body').on('onkho:panel[signatories].added', '.o-panel-container', function ()
        {
            var signatoriesPanel = $('.o-panel[data-panel-key="signatories"]');
            RegisterSignatoriesPanelBindings(signatoriesPanel);
        });

        $('body').on('onkho:panel[audit_log].added', '.o-panel-container', function ()
        {
            var auditLogPanel = $('.o-panel[data-panel-key="audit_log"]');
            RegisterAuditLogPanelBindings(auditLogPanel);
        });
    };

    var AddFeePlan = function ()
    {
        Onkho.OnkhoPanel.Add('fee_plan', [], AddFeePlanPanelLoaded)
    };

    var AddFeePlanPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Add fee plan for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Add fee plan for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save', 'Save');
        Onkho.Validator.AddChildren(panel);
    };

    var RegisterViewFeePlanPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="edit"]:not(:disabled)').on('click', function ()
        {
            EditFeePlan(panel.data('term-id'));
        });

        panel.find('button[data-id="delete"]:not(:disabled)').on('click', function ()
        {
            DeleteTerm(panel.data('term-id'), 'Deleting fee plan', 'Are you sure you want to delete this fee plan?');
        });
    };

    var RegisterFeePlanPanelBindings = function (panel)
    {
        panel.find('.panel-opener[data-id="payment_plan"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.Disable(panel);
            Onkho.OnkhoPanel.Add('payment_plan', [], AddPaymentPlanPanelLoaded);
        });

        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');

            if (viewFeePlanPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewFeePlanPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveFeePlan();
        });

        panel.on('click', 'table.payment-plans .delete', function ()
        {
            DeletePaymentPlan($(this).closest('tr'));
        });

        panel.on('click', 'table.payment-plans .edit', function ()
        {
            EditPaymentPlan($(this).closest('tr'));
        });

        panel.on('keyup', '[name="annual_fees"]', function ()
        {
            UpdatePaymentPlansTotal(panel);
        });
    };

    var SaveFeePlan = function ()
    {
        var panel = $('.o-panel[data-panel-key="fee_plan"]');

        if (Onkho.Validator.ValidateChildren(panel))
        {
            Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'));

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.termId = panel.data('term-id');
            formData.clientId = $('input[name="partyId"]').val();
            formData.name = panel.find('input[name="name"]').val();
            formData.annual_fees = panel.find('input[name="annual_fees"]').val();
            formData.are_annual_fees_estimated = panel.find('input[name="are_annual_fees_estimated"]:checked').length > 0 ? 1 : 0;
            formData.payment_plans = [];

            panel.find('.payment-plans tr').each(function (index, row)
            {
                var paymentPlan = {};
                paymentPlan.amount = $(row).find('input[name="amount"]').val();
                paymentPlan.frequency = $(row).find('input[name="frequency"]').val();

                if ($(row).find('input[name="payment_method"]').length > 0)
                {
                    paymentPlan.payment_method = $(row).find('input[name="payment_method"]').val();
                }

                formData.payment_plans.push(paymentPlan);
            });

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/term/feePlan/save',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                    {
                        Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

                        switch (data.status)
                        {
                            case 200:
                                Onkho.OnkhoPanel.Remove(panel);

                                var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');
                                if (viewFeePlanPanel.length > 0)
                                {
                                    Onkho.OnkhoPanel.Remove(viewFeePlanPanel);
                                }

                                Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                break;

                            default:
                                Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                break;
                        }
                    }
                });
        }
    };

    var AddPaymentPlanPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Add a payment plan');
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Add a payment plan');
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save_payment_plan', 'Add this payment plan');
        Onkho.Validator.AddChildren(panel);
    };

    var RegisterPaymentPlanPanelBindings = function (panel)
    {
        panel.find('.panel-opener[data-id="payment_method"] > button:not(:disabled)').on('click', function ()
        {
            Onkho.OnkhoPanel.DisablePanelOpener(panel);
            Onkho.OnkhoPanel.DisableButtons(panel);
            Onkho.OnkhoPanel.Add('payment_method', [], AddPaymentMethodPanelLoaded);
        });

        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var feePlanPanel = $('.o-panel[data-panel-key="fee_plan"]');

            Onkho.OnkhoPanel.Remove(panel);
            Onkho.OnkhoPanel.EnablePanelOpener(feePlanPanel);
            Onkho.OnkhoPanel.Enable(feePlanPanel);
        });

        panel.find('button[data-id="save_payment_plan"]:not(:disabled)').on('click', function ()
        {
            SavePaymentPlan();
        });
    };

    var SavePaymentPlan = function ()
    {
        var feePlanPanel = $('.o-panel[data-panel-key="fee_plan"]');
        var paymentPlanPanel = $('.o-panel[data-panel-key="payment_plan"]');
        var paymentMethodPanel = $('.o-panel[data-panel-key="payment_method"]');

        var formData = {};

        if (Onkho.Validator.ValidateChildren(paymentPlanPanel))
        {
            formData.id = paymentPlanPanel.find('input[name="id"]').val();
            formData.amount = paymentPlanPanel.find('input[name="amount"]').val();
            formData.frequency = paymentPlanPanel.find('input[name="frequency"]:checked').val();

            if (paymentMethodPanel.length > 0)
            {
                if (Onkho.Validator.ValidateChildren(paymentMethodPanel))
                {
                    formData.payment_method = paymentMethodPanel.find('input[name="payment_method"]:checked').val();
                    Onkho.OnkhoPanel.Remove(paymentMethodPanel);
                }
                else
                {
                    return;
                }
            }

            Onkho.OnkhoPanel.Remove(paymentPlanPanel);
            Onkho.OnkhoPanel.Enable(feePlanPanel);

            var paymentPlansContainer = feePlanPanel.find('.panel-section[data-id="payment-plans"] .payment-plans');
            paymentPlansContainer.show();
            var paymentPlansLabel = feePlanPanel.find('.panel-section[data-id="payment-plans"] .section-title');
            paymentPlansLabel.show();
            InsertOrUpdatePaymentPlan(paymentPlansContainer, formData);
        }
    };

    var InsertOrUpdatePaymentPlan = function (container, formData)
    {
        var readableFrequency = '';
        switch (formData.frequency.toLowerCase())
        {
            case 'weekly':
                readableFrequency = 'week';
                break;

            case 'monthly':
                readableFrequency = 'month';
                break;

            case 'quarterly':
                readableFrequency = 'quarter';
                break;

            case 'annually':
                readableFrequency = 'year';
                break;

            default:
                //
        }

        var paymentPlanString = '£' + formData.amount + ' per ' + readableFrequency + (typeof formData.payment_method == 'undefined' ? '' : (' by ' + formData.payment_method.toLowerCase()));
        var paymentPlanEncoding = '' +
            '<input type="hidden" name="amount" value="' + formData.amount + '">' +
            '<input type="hidden" name="frequency" value="' + formData.frequency + '">' +
            (typeof formData.payment_method == 'undefined' ? '' : '<input type="hidden" name="payment_method" value="' + formData.payment_method + '">');

        var paymentPlanRow = $('' +
            '<tr data-id="' + (nextPaymentPlanId++) + '">' +
                '<td class="delete"><i class="fa fa-fw fa-times"></i></td>' +
                '<td class="payment-plan">' + paymentPlanString + paymentPlanEncoding + '</td>' +
                '<td class="edit"><i class="fa fa-fw fa-edit"></i></td>' +
            '</tr>');

        container.find('tbody').append(paymentPlanRow);

        // If this is an update, remove the old one
        if (typeof formData.id != 'undefined')
        {
            DeletePaymentPlan(container.find('tbody tr[data-id="' + formData.id + '"]'));
        }

        UpdatePaymentPlansTotal(container.closest('.o-panel'));
    };

    var DeletePaymentPlan = function (paymentPlanRow)
    {
        paymentPlanRow.remove();

        var feePlanPanel = $('.o-panel[data-panel-key="fee_plan"]');
        var paymentPlansContainer = feePlanPanel.find('.panel-section[data-id="payment-plans"] .payment-plans');
        if (paymentPlansContainer.find('tbody tr').length == 0)
        {
            paymentPlansContainer.hide();
            var paymentPlansLabel = feePlanPanel.find('.panel-section[data-id="payment-plans"] .section-title');
            paymentPlansLabel.hide();
        }

        UpdatePaymentPlansTotal(feePlanPanel);
    };

    var EditPaymentPlan = function (paymentPlanRow)
    {
        var feePlanPanel = $('.o-panel[data-panel-key="fee_plan"]');
        Onkho.OnkhoPanel.Disable(feePlanPanel);

        Onkho.OnkhoPanel.Add('payment_plan', [], EditPaymentPlanPanelLoaded, paymentPlanRow);
    };

    var EditPaymentPlanPanelLoaded = function (paymentPlanRow, panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Edit payment plan');
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Edit payment plan');
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save_payment_plan', 'Save payment plan');
        Onkho.Validator.AddChildren(panel);

        panel.find('input[name="id"]').val(paymentPlanRow.data('id'));
        panel.find('input[name="amount"]').val(paymentPlanRow.find('input[name="amount"]').val());
        panel.find('input[name="frequency"][value="' + paymentPlanRow.find('input[name="frequency"]').val() + '"]').prop('checked', true);

        if (paymentPlanRow.find('input[name="payment_method"]').length > 0)
        {
            Onkho.OnkhoPanel.DisablePanelOpener(panel);
            Onkho.OnkhoPanel.DisableButtons(panel);
            Onkho.OnkhoPanel.Add('payment_method', [], EditPaymentMethodPanelLoaded, paymentPlanRow);
        }
    };

    var EditPaymentMethodPanelLoaded = function (paymentPlanRow, panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Edit payment method');
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Edit payment method');
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save_payment_plan', 'Save payment plan');
        Onkho.Validator.AddChildren(panel);

        panel.find('input[name="payment_method"][value="' + paymentPlanRow.find('input[name="payment_method"]').val() + '"]').prop('checked', true);
    };

    var AddPaymentMethodPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Add a payment method');
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Add a payment method');
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save_payment_plan', $('.o-panel[data-panel-key="payment_plan"] .footer button[data-id="save_payment_plan"]').text());
        Onkho.Validator.AddChildren(panel);
    };

    var RegisterPaymentMethodPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var paymentPlanPanel = $('.o-panel[data-panel-key="payment_plan"]');

            Onkho.OnkhoPanel.Remove(panel);
            Onkho.OnkhoPanel.EnablePanelOpener(paymentPlanPanel);
            Onkho.OnkhoPanel.EnableButtons(paymentPlanPanel);
        });

        panel.find('button[data-id="save_payment_plan"]:not(:disabled)').on('click', function ()
        {
            SavePaymentPlan();
        });
    };

    var ViewFeePlan = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('view_fee_plan', additionalData, ViewFeePlanPanelLoaded);
    };

    var ViewFeePlanPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'View fee plan for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'View fee plan for ' + $('.party-name > span').text());
        Onkho.Validator.AddChildren(panel);
    };

    var EditFeePlan = function (termId)
    {
        var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');
        if (viewFeePlanPanel.length > 0)
        {
            Onkho.LoadingTools.ShowLoading(viewFeePlanPanel.find('button[data-id="edit"]'));
        }

        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('fee_plan', additionalData, EditFeePlanPanelLoaded);
    };

    var EditFeePlanPanelLoaded = function (panel)
    {
        var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');
        if (viewFeePlanPanel.length > 0)
        {
            Onkho.OnkhoPanel.Remove(viewFeePlanPanel);
        }

        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Edit fee plan for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Edit fee plan for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetButtonLabel(panel, 'save', 'Save');
        Onkho.Validator.AddChildren(panel);
    };

    var UpdatePaymentPlansTotal = function (panel)
    {
        var paymentPlansTotal = panel.find('.payment-plans-total');

        if (panel.find('.payment-plans tr').length == 0)
        {
            paymentPlansTotal.find('> .alert').removeClass('warning').addClass('tip');
            paymentPlansTotal.hide();
        }
        else
        {
            var total = 0;
            panel.find('.payment-plans tr').each(function (index, row)
            {
                var amount = parseFloat($(row).find('input[name="amount"]').val());
                var frequency = $(row).find('input[name="frequency"]').val();

                switch (frequency)
                {
                    case 'WEEKLY':
                        total += amount * 52;
                        break;

                    case 'MONTHLY':
                        total += amount * 12;
                        break;

                    case 'QUARTERLY':
                        total += amount * 4;
                        break;

                    case 'ANNUALLY':
                        total += amount;
                        break;

                    default:
                        //
                }
            });

            var annualFees = parseFloat(panel.find('input[name="annual_fees"]').val());

            if (!isNaN(annualFees))
            {
                if (total == annualFees)
                {
                    paymentPlansTotal.find('> .alert').html('The payment plan(s) add up to the annual fees.');
                    paymentPlansTotal.find('> .alert').removeClass('warning alert-warning').addClass('tip alert-info');
                    paymentPlansTotal.show();
                }
                else
                {
                    paymentPlansTotal.find('> .alert').html('The payment plan(s) add up to ' + Onkho.Formatter.Price(total) + '. That\'s ' + (total < annualFees ? 'less' : 'more') + ' than the annual fees.');
                    paymentPlansTotal.find('> .alert').removeClass('tip alert-info').addClass('warning alert-warning');
                    paymentPlansTotal.show();
                }
            }
            else
            {
                paymentPlansTotal.hide();
            }
        }
    };

    var AddAgreement = function ()
    {
        Onkho.OnkhoPanel.Add('agreement', [], AddAgreementPanelLoaded)
    };

    var AddAgreementPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Add agreement for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Add agreement for ' + $('.party-name > span').text());
        Onkho.Validator.AddChildren(panel);

        // Build custom buttons
        var customButtons = {
            'fee_plan': Onkho.Summernote.GetCustomButtonForTokenFunction('Fee plan', 'AGREEMENT', 'fee_plan'),
            'sign_link': Onkho.Summernote.GetCustomButtonForTokenFunction('Sign', 'SIGNATORY', 'sign_link')
        };
        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="agreement_content"]'), undefined, customButtons);
    };

    var RegisterAgreementPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

            if (viewAgreementPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewAgreementPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveAgreement();
        });
    };

    var RegisterTermsOfBusinessPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

            if (viewAgreementPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewAgreementPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="save"]:not(:disabled)').on('click', function ()
        {
            SaveTermsOfBusiness();
        });
    };

    var RegisterViewAgreementPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="delete"]:not(:disabled)').on('click', function ()
        {
            DeleteTerm(panel.data('term-id'), 'Deleting agreement', 'Are you sure you want to delete this agreement?');
        });

        panel.find('button[data-id="download"]:not(:disabled)').on('click', function ()
        {
            DownloadAgreement(panel.data('term-id'));
        });

        panel.find('.panel-opener[data-id="agreement"] > button:not(:disabled)').on('click', function ()
        {
            EditAgreement(panel.data('term-id'));
        });

        panel.find('.panel-opener[data-id="terms_of_business"] > button:not(:disabled)').on('click', function ()
        {
            EditTermsOfBusiness(panel.data('term-id'));
        });

        panel.find('.panel-opener[data-id="signatories"] > button:not(:disabled)').on('click', function ()
        {
            AddSignatories(panel.data('term-id'));
        });

        panel.find('.panel-opener[data-id="preview_agreement"] > button:not(:disabled)').on('click', function ()
        {
            PreviewAgreement(panel.data('term-id'));
        });

        panel.find('.panel-section[data-id="signatories"]').on('click', '.delete',function ()
        {
            DeleteSignatory($(this).closest('tr'));
        });

        panel.find('.panel-section[data-id="signatories"]').on('click', '.send button',function ()
        {
            EmailSignatory($(this).closest('tr'));
        });

        panel.find('.panel-opener[data-id="audit_log"] > button:not(:disabled)').on('click', function ()
        {
            AuditLog(panel.data('term-id'));
        });
    };

    var OpenTermGroup = function (activityFeedGroupId)
    {
        Onkho.OnkhoActiveElement.RemoveAll();
        Onkho.OnkhoPanel.RemoveAll();
        Onkho.OnkhoPane.RemoveAll();
        Onkho.ActivityFeedGroupPane.LoadActivityFeedGroupPane(activityFeedGroupId);
    };

    var ViewAgreement = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('view_agreement', additionalData, ViewAgreementPanelLoaded);
    };

    var ViewAgreementPanelLoaded = function (panel)
    {
        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'View agreement for ' + $('.party-name > span').text());
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'View agreement for ' + $('.party-name > span').text());
    };

    var DuplicateAgreement = function (termId)
    {
        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.termId = termId;

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/term/agreement/duplicate',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          switch (data.status)
                          {
                              case 200:
                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                  Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var AuditLog = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('audit_log', additionalData, AuditLogPanelLoaded);
    };

    var AuditLogPanelLoaded = function (panel)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.OnkhoPanel.Disable(viewAgreementPanel);
        }

        Onkho.OnkhoPanel.Show(panel);
    };

    var PreviewAgreement = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('preview_agreement', additionalData, PreviewAgreementPanelLoaded);
    };

    var PreviewAgreementPanelLoaded = function (panel)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.OnkhoPanel.Disable(viewAgreementPanel);
        }

        Onkho.OnkhoPanel.Show(panel);
    };

    var RegisterSignatoriesPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

            if (viewAgreementPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewAgreementPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });

        panel.find('button[data-id="add"]:not(:disabled)').on('click', function ()
        {
            SaveSignatories();
        });

        panel.find('select[name="signatory"]').on('change', function ()
        {
            InsertStagedSignatory($(this));
        });

        panel.on('click', 'table.staged-signatories .delete', function ()
        {
            DeleteStagedSignatory($(this).closest('tr'));
        });
    };

    var RegisterAuditLogPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

            if (viewAgreementPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewAgreementPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });
    };

    var RegisterPreviewAgreementPanelBindings = function (panel)
    {
        panel.find('.close[data-id="close"]').on('click', function ()
        {
            var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

            if (viewAgreementPanel.length > 0)
            {
                Onkho.OnkhoPanel.Enable(viewAgreementPanel);
            }

            Onkho.OnkhoPanel.Remove(panel);
        });
    };

    var SaveAgreement = function ()
    {
        var panel = $('.o-panel[data-panel-key="agreement"]');

        if (Onkho.Validator.ValidateChildren(panel))
        {
            Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'));

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.termId = panel.data('term-id');
            formData.clientId = $('input[name="partyId"]').val();
            formData.name = panel.find('input[name="name"]').val();
            formData.agreement_content = panel.find('textarea[name="agreement_content"]').val();

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/term/agreement/save',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                          {
                              Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

                              switch (data.status)
                              {
                                  case 200:
                                      Onkho.OnkhoPanel.Remove(panel);

                                      var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

                                      if (viewAgreementPanel.length > 0)
                                      {
                                          Onkho.OnkhoPanel.Enable(viewAgreementPanel);

                                          viewAgreementPanel.find('.agreement-details-wrapper .title').html(data.responseJSON.agreementName);
                                      }
                                      else
                                      {
                                          ViewAgreement(data.responseJSON.termId);
                                      }

                                      Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                      Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                      break;

                                  default:
                                      Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                      break;
                              }
                          }
                });
        }
    };

    var SaveTermsOfBusiness = function ()
    {
        var panel = $('.o-panel[data-panel-key="terms_of_business"]');

        if (Onkho.Validator.ValidateChildren(panel))
        {
            Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="save"]'));

            var formData = {};
            formData._token = Onkho.Functions.GetCSRF();
            formData.termId = panel.data('term-id');
            formData.terms_of_business_content = panel.find('textarea[name="terms_of_business_content"]').val();

            $.ajax(
                {
                    type: 'POST',
                    url: Onkho.Variables.BaseURL + '/term/agreement/saveTermsOfBusiness',
                    data: formData,
                    dataType: 'json',
                    complete: function (data)
                          {
                              Onkho.LoadingTools.HideLoading(panel.find('button[data-id="save"]'));

                              switch (data.status)
                              {
                                  case 200:
                                      Onkho.OnkhoPanel.Remove(panel);

                                      var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

                                      if (viewAgreementPanel.length > 0)
                                      {
                                          Onkho.OnkhoPanel.Enable(viewAgreementPanel);
                                          Onkho.OnkhoPanel.SetPanelOpenerLabel(viewAgreementPanel, 'terms_of_business', 'Edit standard terms of business');
                                      }

                                      Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                      Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                      break;

                                  default:
                                      Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                      break;
                              }
                          }
                });
        }
    };

    var SaveSignatories = function ()
    {
        var panel = $('.o-panel[data-panel-key="signatories"]');
        Onkho.LoadingTools.ShowLoading(panel.find('button[data-id="add"]'));

        var formData = {};
        formData._token = Onkho.Functions.GetCSRF();
        formData.termId = panel.data('term-id');
        formData.clientId = $('input[name="partyId"]').val();
        formData.signatories = [];

        panel.find('table.staged-signatories tr').each(function (index, row)
        {
            formData.signatories.push($(row).data('id'));
        });

        $.ajax(
            {
                type: 'POST',
                url: Onkho.Variables.BaseURL + '/term/agreement/addSignatories',
                data: formData,
                dataType: 'json',
                complete: function (data)
                      {
                          Onkho.LoadingTools.HideLoading(panel.find('button[data-id="add"]'));

                          switch (data.status)
                          {
                              case 200:
                                  Onkho.OnkhoPanel.Remove(panel);

                                  var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

                                  if (viewAgreementPanel.length > 0)
                                  {
                                      Onkho.OnkhoPanel.Enable(viewAgreementPanel);

                                      var signatoriesContainer = viewAgreementPanel.find('table.signatories tbody');
                                      signatoriesContainer.html(data.responseJSON.signatoriesHtml);

                                      if (data.responseJSON.signatoriesHtml.length > 0)
                                      {
                                          ShowSignatoriesTable();
                                      }
                                      else
                                      {
                                          HideSignatoriesTable();
                                      }
                                  }

                                  Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                  Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                  break;

                              default:
                                  Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                  break;
                          }
                      }
            });
    };

    var ShowSignatoriesTable = function ()
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        var signatoriesPanelSection = viewAgreementPanel.find('.panel-section[data-id="signatories"]');
        signatoriesPanelSection.find('.section-title').show();
        signatoriesPanelSection.find('table.signatories').show();
    };

    var HideSignatoriesTable = function ()
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        var signatoriesPanelSection = viewAgreementPanel.find('.panel-section[data-id="signatories"]');
        signatoriesPanelSection.find('.section-title').hide();
        signatoriesPanelSection.find('table.signatories').hide();
    };

    var EditAgreement = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('agreement', additionalData, EditAgreementPanelLoaded);
    };

    var EditAgreementPanelLoaded = function (panel)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.OnkhoPanel.Disable(viewAgreementPanel);
        }

        Onkho.OnkhoPanel.Show(panel);
        Onkho.OnkhoPanel.SetTitle(panel, 'Edit the agreement');
        Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Edit the agreement');
        Onkho.Validator.AddChildren(panel);

        // Build custom buttons
        var customButtons = {
            'fee_plan': Onkho.Summernote.GetCustomButtonForTokenFunction('Fee plan', 'AGREEMENT', 'fee_plan'),
            'sign_link': Onkho.Summernote.GetCustomButtonForTokenFunction('Sign', 'SIGNATORY', 'sign_link')
        };

        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="agreement_content"]'), undefined, customButtons);
    };

    var DownloadAgreement = function (termId)
    {
        var fakeHref      = document.createElement('a');
        fakeHref.href     = Onkho.Variables.BaseURL + '/term/' + termId + '/download';
        fakeHref.target   = '_blank';

        document.body.appendChild(fakeHref);
        fakeHref.click();
        fakeHref.remove();
    };

    var DeleteTerm = function (termId, title, content)
    {
        title = typeof title == 'undefined' ? 'Deleting terms' : title;
        content = typeof content == 'undefined' ? 'Are you sure you want to delete these terms?' : content;

        $.SmartMessageBox(
            {
                title : title,
                content : content,
                buttons : "[No][Yes]"
            }, function(ButtonPress)
            {
                if (ButtonPress == 'Yes')
                {
                    var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
                    if (viewAgreementPanel.length > 0)
                    {
                        Onkho.LoadingTools.ShowLoading(viewAgreementPanel.find('button[data-id="delete"]'));
                    }

                    var viewFeePlanPanel = $('.o-panel[data-panel-key="view_fee_plan"]');

                    if (viewFeePlanPanel.length > 0)
                    {
                        Onkho.LoadingTools.ShowLoading(viewFeePlanPanel.find('button[data-id="delete"]'));
                    }

                    var formData = {};
                    formData._token = Onkho.Functions.GetCSRF();
                    formData.termId = termId;

                    $.ajax(
                        {
                            type: 'POST',
                            url: Onkho.Variables.BaseURL + '/term/delete',
                            data: formData,
                            dataType: 'json',
                            complete: function (data)
                            {
                                if (viewAgreementPanel.length > 0)
                                {
                                    Onkho.LoadingTools.HideLoading(viewAgreementPanel.find('button[data-id="delete"]'));
                                }

                                if (viewFeePlanPanel.length > 0)
                                {
                                    Onkho.LoadingTools.HideLoading(viewFeePlanPanel.find('button[data-id="delete"]'));
                                }

                                switch (data.status)
                                {
                                    case 200:
                                        if (viewAgreementPanel.length > 0)
                                        {
                                            Onkho.OnkhoPanel.Remove(viewAgreementPanel);
                                        }

                                        if (viewFeePlanPanel.length > 0)
                                        {
                                            Onkho.OnkhoPanel.Remove(viewFeePlanPanel);
                                        }

                                        Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                        break;

                                    default:
                                        Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                        break;
                                }
                            }
                        });
                }
            }
        );
    };

    var EditTermsOfBusiness = function (termId)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.LoadingTools.ShowLoading(viewAgreementPanel.find('button[data-id="edit"]'));
        }

        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('terms_of_business', additionalData, EditTermsOfBusinessPanelLoaded);
    };

    var EditTermsOfBusinessPanelLoaded = function (panel)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.OnkhoPanel.Disable(viewAgreementPanel);

            Onkho.OnkhoPanel.SetTitle(panel, Onkho.OnkhoPanel.GetPanelOpenerLabel(viewAgreementPanel, 'terms_of_business'));
            Onkho.OnkhoPanel.SetCollapsedContent(panel, Onkho.OnkhoPanel.GetPanelOpenerLabel(viewAgreementPanel, 'terms_of_business'));
        }
        else
        {
            Onkho.OnkhoPanel.SetTitle(panel, 'Edit standard terms of business');
            Onkho.OnkhoPanel.SetCollapsedContent(panel, 'Edit standard terms of business');
        }

        Onkho.OnkhoPanel.Show(panel);
        Onkho.Validator.AddChildren(panel);

        Onkho.Summernote.EnableSummernote(panel.find('textarea.summernote[name="terms_of_business_content"]'));
    };

    var AddSignatories = function (termId)
    {
        var additionalData = {
            'termId': termId
        };

        Onkho.OnkhoPanel.Add('signatories', additionalData, AddSignatoriesPanelLoaded);
    };

    var AddSignatoriesPanelLoaded = function (panel)
    {
        var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');
        if (viewAgreementPanel.length > 0)
        {
            Onkho.OnkhoPanel.Disable(viewAgreementPanel);
        }

        Onkho.OnkhoPanel.Show(panel);

        panel.find('select[name="signatory"]').select2({
            placeholder: 'Choose a signatory'
        });
        Onkho.Functions.Select2ScrollToTopFix(panel.find('select[name="signatory"]'));
    };

    var InsertStagedSignatory = function (select)
    {
        var option = select.find('option:checked');

        if (option.val().length > 0)
        {
            var signatoriesPanel = $('.o-panel[data-panel-key="signatories"]');
            $('table.staged-signatories tbody').append('' +
            '<tr data-id="' + option.val() + '">' +
                '<td class="delete"><i class="fa fa-fw fa-times"></i></td>' +
                '<td class="signatory">' + option.text() + '</td>' +
            '</tr>');

            select.find('option[value="' + option.val() + '"]').prop('disabled', true);
            select.select2('destroy').select2({
                placeholder: 'Choose a signatory'
            });
        }
    };

    var DeleteStagedSignatory = function (row)
    {
        var signatoriesPanel = $('.o-panel[data-panel-key="signatories"]');
        var select = signatoriesPanel.find('select[name="signatory"]');
        select.find('option[value="' + row.data('id') + '"]').prop('disabled', false);
        select.val('').trigger('change');
        select.select2('destroy').select2({
            placeholder: 'Choose a signatory'
        });

        row.remove();
    };

    var DeleteSignatory = function (row)
    {
        $.SmartMessageBox(
            {
                title : "Deleting signatory",
                content : "Are you sure you want to delete this signatory?",
                buttons : "[No][Yes]"
            }, function(ButtonPress)
            {
                if (ButtonPress == 'Yes')
                {
                    var panel = $('.o-panel[data-panel-key="view_agreement"]');
                    Onkho.LoadingTools.ShowLoading(row.find('td.delete'));

                    var formData = {};
                    formData._token = Onkho.Functions.GetCSRF();
                    formData.termId = panel.data('term-id');
                    formData.contactEntryId = row.data('id');

                    $.ajax(
                        {
                            type: 'POST',
                            url: Onkho.Variables.BaseURL + '/term/agreement/removeSignatory',
                            data: formData,
                            dataType: 'json',
                            complete: function (data)
                                  {
                                      Onkho.LoadingTools.HideLoading(row.find('td.delete'));

                                      switch (data.status)
                                      {
                                          case 200:
                                              var tbody = row.closest('tbody');

                                              row.remove();

                                              if (tbody.find('tr').length == 0)
                                              {
                                                  HideSignatoriesTable();
                                              }

                                              Onkho.Alert.SmallBox('success', data.responseJSON.message);

                                              Onkho.OnkhoList.Refresh(termsContainer.find('.o-list'));
                                              break;

                                          default:
                                              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                              break;
                                      }
                                  }
                        });
                }
            }
        );
    };

    var EmailSignatory = function (row)
    {
        $.SmartMessageBox(
            {
                title : "Emailing signatory",
                content : "Are you sure you want to email this signatory? We strongly advise you to preview the agreement before proceeding.",
                buttons : "[No][Yes]"
            }, function(ButtonPress)
            {
                if (ButtonPress == 'Yes')
                {
                    var panel = $('.o-panel[data-panel-key="view_agreement"]');
                    Onkho.LoadingTools.ShowLoading(row.find('td.send'));

                    var formData = {};
                    formData._token = Onkho.Functions.GetCSRF();
                    formData.termId = panel.data('term-id');
                    formData.contactEntryId = row.data('id');

                    $.ajax(
                        {
                            type: 'POST',
                            url: Onkho.Variables.BaseURL + '/term/agreement/emailSignatory',
                            data: formData,
                            dataType: 'json',
                            complete: function (data)
                                  {
                                      Onkho.LoadingTools.HideLoading(row.find('td.send'));

                                      switch (data.status)
                                      {
                                          case 200:
                                              row.find('td.send button').html('Resend');

                                              var viewAgreementPanel = $('.o-panel[data-panel-key="view_agreement"]');

                                              if (viewAgreementPanel.length > 0)
                                              {
                                                  if (data.responseJSON.reloadNeeded == 1)
                                                  {
                                                      ReloadViewAgreementPanel(viewAgreementPanel);
                                                  }
                                                  else
                                                  {
                                                      var signatoriesContainer = viewAgreementPanel.find('table.signatories tbody');
                                                      signatoriesContainer.html(data.responseJSON.signatoriesHtml);
                                                  }

                                              }

                                              Onkho.Alert.SmallBox('success', data.responseJSON.message);
                                              break;

                                          default:
                                              Onkho.Alert.BigBox(data.responseJSON.status, data.responseJSON.title, data.responseJSON.message);
                                              break;
                                      }
                                  }
                        });
                }
            }
        );
    };

    var ReloadViewAgreementPanel = function (oldViewAgreementPanel)
    {
        var termId = oldViewAgreementPanel.data('term-id');
        Onkho.OnkhoPanel.Remove(oldViewAgreementPanel);
        ViewAgreement(termId);
    };



    return {
        Init: Init,
    };
}();
