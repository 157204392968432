// Module containing tools to be used when displaying an upload link
Onkho.UploadLink = function () {
  var Init = function () {
    var form = $('form');
    var uploadButton = form.find('button.upload');
    uploadButton.hide();

    // Due to dropzone limitations, if the form includes a dropzone, it has to
    // be submitted through its init handler
    var dropzone = $('.attach-file-local-dropzone');
    dropzone.addClass('dropzone');
    dropzone.dropzone({
      url: Onkho.Variables.BaseURL + '/uploadLink/' + Onkho.Variables.Token,
      method: 'POST',
      paramName: 'files',
      parallelUploads: 20,
      uploadMultiple: true,
      addRemoveLinks: true,
      autoProcessQueue: false,
      maxFiles: 20,
      maxFilesize: 20, //MB
      init: function() {
        dropzone = this

        // Enable/disable upload button
        dropzone.on('addedfile', function (file, request, formData) {
          uploadButton.show();
        })

        dropzone.on('removedfile', function (file, request, formData) {
          if (dropzone.files.length === 0) {
            uploadButton.hide();
          }
        })

        // Prior to submitting the files to the server we add the other form
        // data required
        dropzone.on('sendingmultiple', function (file, request, formData) {
          formData.append('_token', Onkho.Functions.GetCSRF());
          Onkho.LoadingTools.ShowLoading(uploadButton);
        })

        // If the server has accepted our upload - display a success message
        dropzone.on('successmultiple', function (file, response) {
          location.reload();
          Onkho.Alert.SmallBox('success', response.message)
        })

        // If the server (or dropzone) has rejected our upload, display an error
        // message
        dropzone.on('error', function (file, errorMessage, failure) {
          if (errorMessage) {
            if (typeof errorMessage === 'string') {
              if (errorMessage.indexOf('<html>') !== -1) {
                $(file.previewElement).find('.dz-error-message').html('We were unable to attach this file.')
              }
            } else {
              $(file.previewElement).find('.dz-error-message').html('We were unable to attach this file.')
            }
          }

          // Avoid spamming the user by showing a single alert
          Onkho.Functions.DelayInterval('DropzoneErrorAlert', function () {
            Onkho.LoadingTools.HideLoading(uploadButton);
            if (errorMessage) {
              if (typeof errorMessage === 'string') {
                if (typeof failure === 'object') {
                  // Webserver error
                  switch (failure.status) {
                    case 413:
                      Onkho.Alert.BigBox('danger', 'Unexpected error', 'One or more of the files you tried to attach exceeded the maximum file size.')
                      break

                    default:
                      Onkho.Alert.BigBox('danger', 'Unexpected error', 'We were not able to attach your files due to an unexpected error. Please try again.')
                  }
                } else {
                  // Dropzone / frontend error
                  Onkho.Alert.BigBox('danger', 'Invalid file', errorMessage)
                }
              } else {
                // Backend error
                Onkho.Alert.BigBox('danger', 'Unexpected error', 'We were not able to attach your files due to an unexpected error. Please try again.')
              }
            }
          }, 250)

          $(dropzone.files).each(function (index, file) {
            file.status = 'queued'
          })
        })

        // If we have reached the maximum number of files that can be uploaded, show an alert
        dropzone.on('maxfilesreached', function (file, response) {
          Onkho.Functions.DelayInterval('DropzoneMaxfilesreachedAlert', function () {
            Onkho.Alert.BigBox('warning', 'Maximum number of files reached', 'You have reached the maximum number of files that you can attach.')
          }, 250)
        })

        // If we have exceeded the maximum number of files that can be uploaded, show an alert
        dropzone.on('maxfilesexceeded', function (file, response) {
          Onkho.Functions.DelayInterval('DropzoneMaxfilesexceededAlert', function () {
            Onkho.Alert.BigBox('error', 'Maximum number of files exceeded', 'You have exceeded the maximum number of files that you can attach.')
          }, 250)
        })
      }
    })

    form.on('submit', function () {
      dropzone.processQueue();
      return false;
    });
  }



  return {
    Init: Init,
  }
}()
